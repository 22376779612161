@import "/Users/denisarosenko/Documents/Работа/www/gryzhi-net.local/src/scss/base/_01_normalize.scss";
@import "/Users/denisarosenko/Documents/Работа/www/gryzhi-net.local/src/scss/base/_grid.scss";
@import "/Users/denisarosenko/Documents/Работа/www/gryzhi-net.local/src/scss/base/_mixins.scss";

@import "/Users/denisarosenko/Documents/Работа/www/gryzhi-net.local/src/scss/global/_fonts.scss";
@import "/Users/denisarosenko/Documents/Работа/www/gryzhi-net.local/src/scss/global/_global.scss";
@import "/Users/denisarosenko/Documents/Работа/www/gryzhi-net.local/src/scss/global/_svg.scss";
@import "/Users/denisarosenko/Documents/Работа/www/gryzhi-net.local/src/scss/global/_vars.scss";

@import "/Users/denisarosenko/Documents/Работа/www/gryzhi-net.local/src/scss/_local-fonts.scss";
@import "/Users/denisarosenko/Documents/Работа/www/gryzhi-net.local/src/scss/base/_01_normalize.scss";
@import "/Users/denisarosenko/Documents/Работа/www/gryzhi-net.local/src/scss/base/_grid.scss";
@import "/Users/denisarosenko/Documents/Работа/www/gryzhi-net.local/src/scss/base/_mixins.scss";
@import "/Users/denisarosenko/Documents/Работа/www/gryzhi-net.local/src/scss/global/_fonts.scss";
@import "/Users/denisarosenko/Documents/Работа/www/gryzhi-net.local/src/scss/global/_global.scss";
@import "/Users/denisarosenko/Documents/Работа/www/gryzhi-net.local/src/scss/global/_svg.scss";
@import "/Users/denisarosenko/Documents/Работа/www/gryzhi-net.local/src/scss/global/_vars.scss";



.video {
  position: relative;
  padding-bottom: 56.25%; //padding-top:25px;height:0;
  border-radius: 10px;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

html {
  font-family: 'Montserrat',
    sans-serif;
}

article,
.content-area {
  // font-family: $font-main;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #5B5F6E;

  p {
    margin-top: 10px;
    margin-bottom: 10px;

    &:first-child {
      margin-top: 0;
    }
  }

  a {
    color: #009688;

    &:hover {
      color: darken($color: #009688, $amount: 10%);
    }
  }

  h1 {
    display: block;
    font-size: 2em;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h2 {
    display: block;
    font-size: 1.5em;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h3 {
    display: block;
    font-size: 1.17em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h4 {
    display: block;
    margin-top: 1.33em;
    margin-bottom: 1.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h5 {
    display: block;
    font-size: .83em;
    margin-top: 1.67em;
    margin-bottom: 1.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h6 {
    display: block;
    font-size: .67em;
    margin-top: 2.33em;
    margin-bottom: 2.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  b {
    font-weight: 500;
  }

  ul {
    list-style-type: disc;
    padding-left: 1em;
  }

  ol {
    list-style-type: decimal;
    padding-left: 1em;
  }
}

.bg {
  &-primary {
    &-10 {
      background-color: $color-primary-10;
    }
  }
}

.header {
  &__address {
    font-size: 16px;
    font-weight: 700;
    color: $color-primary;
    margin-bottom: 2px;
  }

  &__phone {
    font-size: 16px;
    font-weight: 700;
    color: $color-primary;
    margin-bottom: 2px;
  }

  &__sublink {
    font-size: 12px;
    font-weight: 500;
    color: #5b5c5c;

    &--mobile {
      color: #fff;
    }
  }

  &__button {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    background-color: #f77b55;
    padding: 10px 25px;
    border-radius: 100px;
    transition: .1s ease-in-out;

    &:hover {
      background-color: darken(#f77b55, 10%);
    }
  }
}

.main-nav {
  background-color: #f5f5f5;

  &__list {
    display: flex;
  }

  &__item {}

  &__link {
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #1F354F;
    padding: 20px 0;
    transition: .1s ease-in-out;

    &--active,
    &:hover {
      color: $color-primary;
    }
  }
}

// Hero-banner
.hero-banner {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode: multiply;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    background: rgba(255, 255, 255, .8);
  }

  &__swiper {
    .swiper-pagination {
      &-bullet {
        background: #fff !important;
        width: 10px !important;
        height: 10px !important;

        &-active {
          background: $color-primary !important;
        }
      }
    }

    .swiper-button-prev,
    .swiper-button-prev-next {
      @include respond-to(medium) {
        display: none !important;
      }
    }
  }

  &__label {
    display: inline-block;
    width: max-content;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    background-color: $color-primary;
    padding: 10px 25px;
    border-radius: 100px;
    transition: .1s ease-in-out;

    @include respond-to(medium) {
      font-size: 14px;
      font-weight: 500;
      padding: 5px 15px;
    }
  }

  &__header {
    color: #1F354F;
    font-size: 42px;
    font-weight: 700;

    @include respond-to(medium) {
      font-size: 28px;
    }
  }

  &__text {
    line-height: 24px;
    color: #1f354f;

    p {
      line-height: normal;
    }

    ul {
      list-style: none;
      margin-top: 20px;
      margin-bottom: 20px;
      // padding-left: 1em;
    }

    li {
      font-size: 16px;
      line-height: 22px;
      display: flex;
      align-items: flex-start;
      margin-bottom: 15px;

      @include respond-to(medium) {
        font-size: 14px;
      }

      &:before {
        content: '';
        display: inline-block;
        flex-shrink: 0;
        height: 1.5em;
        width: 1.5em;
        position: relative;
        top: -3px;
        background-image: url('../img/accept.svg'); // Fallback PNG
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 0.5em;
      }
    }
  }

  &__button {
    display: inline-block;
    color: #fff;
    font-size: 1.7rem;
    font-weight: 600;
    background-color: $color-primary;
    padding: 15px 30px;
    border-radius: 100px;
    transition: .1s ease-in-out;

    &:hover {
      background-color: darken($color-primary, 10%);
    }

    @include respond-to(medium) {
      width: 100%;
      padding: 20px 30px;
      font-size: 1.4rem;
      text-align: center;
    }
  }

  &__disclamer {
    color: #1f354f;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
  }

  &__price {
    color: #fff;
    font-size: 42px;
    font-weight: 700;
    background-color: #f77b55;
    padding: 20px 30px;
    border-radius: 100px;

    @include respond-to(medium) {
      font-size: 2rem;
      padding: 10px 20px;
    }
  }

  &__old-price {
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 5px;
    color: #1F354F;

    @include respond-to(medium) {
      font-size: 1.7rem;
      padding: 10px 20px;
    }

    span {
      color: #1F354F;
      font-size: 1.3em;
      font-weight: 600;
      text-decoration: none;
      position: relative;

      // @include respond-to(medium) {
      // font-size: 2rem;
      // }

      &:before {
        content: " ";
        display: block;
        width: 110%;
        border-top: 4px solid rgba(red, 0.8);
        height: 12px;
        position: absolute;
        bottom: 10px;
        left: -2px;
        transform: rotate(-7deg);
      }
    }
  }
}

.tile-list {
  li {
    border-radius: 15px;
    padding: 20px 30px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  }
}

.section {
  &__header {
    color: $color-primary;
  }

  &__subtitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
  }
}

.rating-section {
  &__header {
    color: $color-primary;
  }

  &__subtitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;

    @include respond-to(medium) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.service-card {
  &__item {
    position: relative;
    background-color: #fff;
    padding: 20px 25px;
    border-radius: 15px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    transition: .2s ease-in-out;
    overflow: hidden;

    background-position: 100% bottom;
    background-size: contain;
    background-repeat: no-repeat;

    &:before {
      @include respond-to(medium) {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 1;
        background: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
      }
    }

    &--on-page {
      background-color: #f5f5f5;
      box-shadow: unset;
    }

    // Маленькая карточка
    &--small {
      background-position: 140% bottom;
      background-size: 60%;
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  &__inner {
    position: relative;
    z-index: 10;
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 15px;
    color: #1F354F;

    span {
      color: $color-primary;
    }

    @include respond-to(medium) {
      text-align: right;
    }
  }

  &__desc {
    @include respond-to(medium) {
      text-align: right;
    }
  }

  &__price {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 20px;
    color: #1F354F;

    span {
      color: $color-primary;
      font-size: 28px;
      font-weight: 700;
    }
  }

  &__old-price {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 5px;
    color: #1F354F;

    span {
      color: #1F354F;
      font-size: 20px;
      font-weight: 600;

      text-decoration: none;
      position: relative;

      &:before {
        content: " ";
        display: block;
        width: 110%;
        border-top: 2px solid rgba(red, 0.8);
        height: 12px;
        position: absolute;
        bottom: 0;
        left: -2px;
        transform: rotate(-7deg);
      }
    }
  }

  &__button {
    color: #fff;
    display: inline-block;
    border-radius: 20px;
    padding: 10px 20px;
    background-color: $color-primary;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    transition: .1s ease-in-out;

    &:hover {
      color: #fff;
      background-color: darken($color-primary, 10%);
    }
  }
}

.service-detail-page {
  &__price {
    flex-shrink: 0;
    color: #f77b55;
    border: 2px solid #f77b55;
    border-radius: 100px;
    padding: 10px 20px;
    text-align: center;
    font-size: 1.3rem;
  }

  &__button {
    display: block;
    color: #fff;
    background-color: #f77b55;
    border-radius: 100px;
    padding: 10px 20px;
    text-align: center;
    font-size: 1.3rem;
    line-height: 1.3;
  }
}

// Карточка до-после
.before-after-card {
  &__item {}

  &__img {
    border-radius: 15px;
  }

  &__title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #1F354F;

    span {
      color: $color-primary;
    }
  }

  &__button {
    color: #fff;
    display: inline-block;
    border-radius: 20px;
    padding: 10px 20px;
    background-color: $color-primary;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    transition: .1s ease-in-out;

    &:hover {
      color: #fff;
      background-color: darken($color-primary, 10%);
    }
  }
}


.conversy-block {
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    background-color: $color-primary;
    border-radius: 20px;

    @include respond-to(medium) {
      padding: 20px;
    }
  }

  &__title {
    color: #fff;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;

    @include respond-to(medium) {
      font-size: 20px;
      line-height: normal;
      margin-bottom: 10px;
    }
  }

  &__subtitle {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;
    text-align: center;

    @include respond-to(medium) {
      font-size: 16px;
      font-weight: 300;
      line-height: normal;
    }
  }

  &__button {
    font-size: 1.5rem;
    padding: 15px 30px;
    font-weight: 600;
    color: darken($color: $color-primary, $amount: 10%);
    display: inline-block;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    transition: .1s ease-in-out;

    &:hover {
      color: #fff;
      background-color: $color-secondary;
    }

    @include respond-to(medium) {
      font-size: 1.3rem;
    }
  }
}


.doctor-card {
  &__item {
    height: 480px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 15px;
    transition: .2s ease-in-out;
    position: relative;

    &:hover {
      transform: scale(1.05);
    }

    @include respond-to(medium) {
      height: 220px;
    }

  }

  &__experience {
    position: absolute;
    top: -20px;
    right: -20px;
    background-color: darken($color-secondary, 10%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 100px;
    padding: 10px;
    box-sizing: border-box;
    color: #fff;
    font-size: 16px;

    span {
      font-size: 26px;
      font-weight: 800;

    }

    @include respond-to(medium) {
      width: 50px;
      height: 50px;
      top: -10px;
      right: -10px;
      font-size: 10px;

      span {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  &__info {
    padding: 200px 30px 30px;
    width: 100%;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);

    @include respond-to(medium) {
      padding: 15px;
    }
  }

  &__name {
    color: #1f354f;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;

    @include respond-to(medium) {
      font-size: 14px;
    }
  }

  &__desc {
    color: #5b5c5c;
    font-size: 16px;
    font-weight: 400;

    @include respond-to(medium) {
      font-size: 10px;
    }
  }

}

.rating-card {
  &__item {
    background-color: #fff;
    padding: 20px 25px;
    border-radius: 15px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    transition: .2s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:hover {
      transform: scale(1.05);
    }
  }

  &__logo {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  &__value {
    color: $color-primary;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &__desc {
    color: #1F354F;
    font-size: 16px;
    font-weight: 400;
  }
}

.carousel {
  &__img {
    display: block;
    border-radius: 15px;
    overflow: hidden;

    @include respond-to(medium) {
      // height: 250px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__pagination {
    // top: 110%;
    position: relative !important;
    margin-top: 30px;
  }
}

// Кнопки для SwiperJS
.swiper {
  &-button {
    &-prev {
      display: block;
      width: 80px !important;
      height: 80px !important;
      background-color: #f5c712;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 30px;
      position: absolute;
      top: calc(50% - 85px);
      z-index: 1;
      border-radius: 100px;

      left: 0;
      background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg width='17px' height='12px' viewBox='0 0 17 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='Проект-дома' transform='translate(-785.000000, -814.000000)' fill='%231E201D'%3e%3cg id='Group-Copy' transform='translate(778.000000, 804.000000)'%3e%3cg id='right-arrow' transform='translate(15.200000, 15.600000) rotate(-180.000000) translate(-15.200000, -15.600000) translate(7.200000, 9.600000)'%3e%3cpath d='M10.5103043,0.499121739 C10.2976696,0.279052174 9.94377391,0.279052174 9.72370435,0.499121739 C9.51106957,0.711756522 9.51106957,1.06565217 9.72370435,1.2777913 L13.7122174,5.26630435 L0.550669565,5.26630435 C0.24386087,5.2668 0,5.51066087 0,5.81746957 C0,6.12427826 0.24386087,6.37606957 0.550669565,6.37606957 L13.7122174,6.37606957 L9.72370435,10.3571478 C9.51106957,10.5772174 9.51106957,10.9316087 9.72370435,11.1437478 C9.94377391,11.3638174 10.2981652,11.3638174 10.5103043,11.1437478 L15.4430348,6.21101739 C15.6631043,5.99838261 15.6631043,5.64448696 15.4430348,5.43234783 L10.5103043,0.499121739 Z' id='Path'%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e");

      &:after {
        content: none !important;
      }
    }

    &-next {
      display: block;
      width: 80px !important;
      height: 80px !important;
      background-color: #f5c712;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 30px;
      position: absolute;
      top: calc(50% - 85px);
      z-index: 1;
      border-radius: 100px;

      right: 0;
      background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg width='16px' height='12px' viewBox='0 0 16 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='Проект-дома' transform='translate(-785.000000, -813.000000)' fill='%231E201D'%3e%3cg id='Group-Copy' transform='translate(778.000000, 804.000000)'%3e%3cg id='right-arrow' transform='translate(7.200000, 9.600000)'%3e%3cpath d='M10.5103043,0.499121739 C10.2976696,0.279052174 9.94377391,0.279052174 9.72370435,0.499121739 C9.51106957,0.711756522 9.51106957,1.06565217 9.72370435,1.2777913 L13.7122174,5.26630435 L0.550669565,5.26630435 C0.24386087,5.2668 0,5.51066087 0,5.81746957 C0,6.12427826 0.24386087,6.37606957 0.550669565,6.37606957 L13.7122174,6.37606957 L9.72370435,10.3571478 C9.51106957,10.5772174 9.51106957,10.9316087 9.72370435,11.1437478 C9.94377391,11.3638174 10.2981652,11.3638174 10.5103043,11.1437478 L15.4430348,6.21101739 C15.6631043,5.99838261 15.6631043,5.64448696 15.4430348,5.43234783 L10.5103043,0.499121739 Z' id='Path'%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e");

      &:after {
        content: none !important;
      }
    }

    &-disabled {
      opacity: 0 !important;
    }
  }
}

.swiper {
  &-wrapper {}

  &-container {
    display: none;

    &.swiper-container-initialized {
      display: block;
    }
  }

  &-slide {}

  &-pagination {
    &-bullet {
      width: 10px !important;
      height: 10px !important;

      &-active {
        background: $color-primary !important;
      }
    }
  }

  &-button {
    &-prev {
      display: block;
      width: 50px !important;
      height: 50px !important;
      background-color: #fff;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: calc(50% - 15px);
      z-index: 1;
      border-radius: 100px;

      left: 20px;
      background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg width='17px' height='12px' viewBox='0 0 17 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='Проект-дома' transform='translate(-785.000000, -814.000000)' fill='%231E201D'%3e%3cg id='Group-Copy' transform='translate(778.000000, 804.000000)'%3e%3cg id='right-arrow' transform='translate(15.200000, 15.600000) rotate(-180.000000) translate(-15.200000, -15.600000) translate(7.200000, 9.600000)'%3e%3cpath d='M10.5103043,0.499121739 C10.2976696,0.279052174 9.94377391,0.279052174 9.72370435,0.499121739 C9.51106957,0.711756522 9.51106957,1.06565217 9.72370435,1.2777913 L13.7122174,5.26630435 L0.550669565,5.26630435 C0.24386087,5.2668 0,5.51066087 0,5.81746957 C0,6.12427826 0.24386087,6.37606957 0.550669565,6.37606957 L13.7122174,6.37606957 L9.72370435,10.3571478 C9.51106957,10.5772174 9.51106957,10.9316087 9.72370435,11.1437478 C9.94377391,11.3638174 10.2981652,11.3638174 10.5103043,11.1437478 L15.4430348,6.21101739 C15.6631043,5.99838261 15.6631043,5.64448696 15.4430348,5.43234783 L10.5103043,0.499121739 Z' id='Path'%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e");

      &:after {
        content: none !important;
      }
    }

    &-next {
      display: block;
      width: 50px !important;
      height: 50px !important;
      background-color: #fff;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: calc(50% - 15px);
      z-index: 1;
      border-radius: 100px;

      right: 20px;
      background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg width='16px' height='12px' viewBox='0 0 16 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='Проект-дома' transform='translate(-785.000000, -813.000000)' fill='%231E201D'%3e%3cg id='Group-Copy' transform='translate(778.000000, 804.000000)'%3e%3cg id='right-arrow' transform='translate(7.200000, 9.600000)'%3e%3cpath d='M10.5103043,0.499121739 C10.2976696,0.279052174 9.94377391,0.279052174 9.72370435,0.499121739 C9.51106957,0.711756522 9.51106957,1.06565217 9.72370435,1.2777913 L13.7122174,5.26630435 L0.550669565,5.26630435 C0.24386087,5.2668 0,5.51066087 0,5.81746957 C0,6.12427826 0.24386087,6.37606957 0.550669565,6.37606957 L13.7122174,6.37606957 L9.72370435,10.3571478 C9.51106957,10.5772174 9.51106957,10.9316087 9.72370435,11.1437478 C9.94377391,11.3638174 10.2981652,11.3638174 10.5103043,11.1437478 L15.4430348,6.21101739 C15.6631043,5.99838261 15.6631043,5.64448696 15.4430348,5.43234783 L10.5103043,0.499121739 Z' id='Path'%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e");

      &:after {
        content: none !important;
      }
    }
  }

}

.section-contacts {
  &__card {
    border-radius: 20px;
    padding: 30px 40px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);

    @include respond-to(medium) {
      padding: 25px 20px;
    }
  }

  &__header {
    font-size: 38px;
    font-weight: 700;
    margin-bottom: 20px;

    @include respond-to(medium) {
      font-size: 28px;
    }
  }

  &__phone {
    color: $color-primary;
    display: block;
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  &__address {
    display: block;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 20px;

    @include respond-to(medium) {
      font-size: 16px;
    }
  }

  &__work-time {
    display: block;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 30px;

    p {
      margin-bottom: 5px;
    }

    span {
      font-weight: 700;
    }
  }

  &__button {
    display: inline-block;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    background-color: #f77b55;
    padding: 10px 25px;
    border-radius: 100px;
    transition: .1s ease-in-out;

    &:hover {
      background-color: darken(#f77b55, 10%);
    }
  }

  &__map {
    @include respond-to(medium) {
      height: 280px;
    }

    &>ymaps {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.section-doctor {
  &__name {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  &__desc {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
  }

  &__button {
    display: inline-block;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    background-color: $color-primary;
    padding: 10px 25px;
    border-radius: 100px;
    transition: .1s ease-in-out;

    &:hover {
      background-color: darken($color-primary, 10%);
    }
  }

  .swiper-button {
    &-next {
      right: unset !important;
      left: calc(100% + 30px);
      background-color: $color-primary !important;

      @include respond-to(medium) {
        top: 220px;
        right: 10px !important;
        left: unset !important;
      }
    }

    &-prev {
      left: unset !important;
      right: calc(100% + 30px);
      background-color: $color-primary !important;

      @include respond-to(medium) {
        top: 220px;
        left: 10px !important;
        right: unset !important;
      }
    }
  }
}

.installment-banner {
  color: #1f354f;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode: multiply;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    background: rgba(255, 255, 255, .8);
  }

  &__header {
    color: #1f354f;
    font-size: 42px;
    font-weight: 700;
    text-align: center;

    @include respond-to(medium) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  &__subtitle {
    color: #1f354f;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 80px;
    text-align: center;

    @include respond-to(medium) {
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 30px;
    }
  }

  &__button {
    display: inline-block;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    background-color: #f77b55;
    padding: 10px 25px;
    border-radius: 100px;
    transition: .1s ease-in-out;

    &:hover {
      background-color: darken(#f77b55, 10%);
    }
  }
}

// Хлебные крошки
.breadcrumbs {
  display: flex;

  &__link {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    cursor: pointer;
    transition: .1s ease-in;

    &:hover {
      color: $color-primary;
    }

    @include respond-to(medium) {
      font-size: 12px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #A5A5A5;
    cursor: default;

    @include respond-to(medium) {
      font-size: 12px;
    }
  }

  &__arrow {
    margin: 0 15px;

    svg {
      max-width: 100%;
      max-height: 100%;
    }

    @include respond-to(medium) {
      height: 10px;
      width: 10px;
      margin: 0 10px;
    }
  }
}

// Футер
.footer {
  background: #F5F5F5;
  padding: 50px 0;

  &__logo {
    display: block;
    margin-bottom: 30px;

    @include respond-to(medium) {
      display: flex;
      justify-content: center;
    }
  }

  &__phone {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    color: #5B5F6E;
    margin-bottom: 5px;
    transition: .1s ease-in;
    cursor: pointer;

    &:hover {
      color: $color-primary;
    }
  }

  &__email {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #5B5F6E;
    margin-bottom: 5px;
    transition: .1s ease-in;
    cursor: pointer;

    &:hover {
      color: $color-primary;
    }
  }

  &__social-icon {
    @include respond-to(medium) {
      height: 50px;
      width: 50px;

      img {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__address {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #5B5F6E;
    margin-bottom: 10px;
    text-align: right;

    @include respond-to(medium) {
      text-align: center;
    }
  }

  &__callback {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $color-primary;
    transition: .1s ease-in;
    cursor: pointer;
    border-bottom: 1px dashed $color-primary;

    &:hover {
      color: darken($color: $color-primary, $amount: 5%);
    }
  }

  &__poor-vision-ver {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #5b5f6e;

    @include respond-to(medium) {
      font-size: 14px;
      justify-content: center;
    }

    svg {
      margin-right: 10px;

      @include respond-to(medium) {
        margin-right: 5px;
        height: 15px;
      }
    }
  }

  &__title {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #5b5f6e;
    margin-bottom: 15px;

    @include respond-to(medium) {
      font-size: 18px;
      justify-content: center;
      margin-bottom: 25px;
    }
  }

  &__disclamer {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #5B5F6E;
    margin-bottom: 5px;

    &--mobile {
      font-size: 14px;
      text-align: center;
    }
  }

  &__developer {
    height: 20px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

// Главное меню футера
.footer-main-nav {
  &__item {}

  &__link {
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    color: #5b5f6e;
    cursor: pointer;

    @include respond-to(medium) {
      margin-bottom: 10px;
    }

    &--active,
    &:hover {
      color: $color-primary;
    }
  }
}

// Второстепенное меню футера
.footer-secondary-nav {
  &__item {
    margin-bottom: 8px;
  }

  &__link {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #5b5f6e;
    cursor: pointer;

    &--active,
    &:hover {
      color: $color-primary;
    }
  }
}

// Соцсети в футере
.footer-social {
  &__link {
    cursor: pointer;

    svg:hover {
      fill: red !important;
    }
  }
}

// Месенджеры в футере
.footer-messenger {
  &__link {
    cursor: pointer;
  }
}

// Модальные окна
.modal {
  &__title {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    color: #000;
    text-transform: uppercase;

    @include respond-to(medium) {
      font-size: 16px;
    }
  }

  &__form {
    .afbf_item_pole {
      margin-bottom: 20px !important;

      @include respond-to(medium) {
        margin-bottom: 15px !important;
      }
    }
  }

  &__label {
    font-size: 16px;
    display: block;
    margin-bottom: 0.6rem;
    color: #5b5f6e;

    @include respond-to(medium) {
      font-size: 12px;
    }
  }

  &__input {
    height: 50px;
    width: 100%;
    padding: 0 15px;
    border-radius: 5px;
    border-width: 2px;
    transition: .1s ease-in-out;

    @include respond-to(medium) {
      height: 40px;
    }

    &:focus {
      border-color: $color-primary;
    }
  }

  &__button,
  &__submit {
    width: 100%;
    color: #fff;
    display: inline-block;
    border-radius: 5px;
    padding: 15px 20px;
    text-align: center !important;
    background-color: $color-primary;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    transition: .1s ease-in-out;

    &:hover {
      color: #fff;
      background-color: darken($color-primary, 10%);
    }
  }

  &__pa {
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #5B5F6E;
    margin-top: 20px;

    a {
      border-bottom: 1px dashed;
    }

    @include respond-to(medium) {
      font-size: 14px;
      line-height: 22px !important;
    }
  }

  &__success-block {
    .mess {
      @include respond-to(medium) {
        line-height: 22px;
        font-size: 16px;
      }
    }
  }
}

.afbf_feedback_poles {
  br {
    display: none;
  }
}

.promo-block {
  &__header {
    font-size: 46px;
    font-weight: 700;
    margin-bottom: 30px;

    span {
      color: $color-primary;
    }
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 20px;

    @include respond-to(medium) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__text {}

  &__button {
    color: #fff;
    display: inline-block;
    border-radius: 20px;
    padding: 10px 20px;
    background-color: $color-primary;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    transition: .1s ease-in-out;

    &:hover {
      color: #fff;
      background-color: darken($color-primary, 10%);
    }

    &--primary {}

    &--secondary {}
  }

  &__img {}
}

.step {
  &__item {
    display: flex;
    // align-items: center; 
  }

  &__label {
    background-color: $color-primary;
    padding: 1.5rem;
    width: 120px;
    color: #fff;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    flex-shrink: 0;
  }

  &__value {
    padding: 1.5rem;
  }
}

.swiper {
  &-button {
    &-prev {
      background-color: $color-primary;
      background-image: url('../img/swiper/arrow-left.svg');
    }

    &-next {
      background-color: $color-primary;
      background-image: url('../img/swiper/arrow-right.svg');
    }
  }
}

.stories {
  .swiper-button {
    &-next {
      right: unset !important;
      left: calc(100% + 30px);
      background-color: $color-primary !important;

      @include respond-to(medium) {
        display: none;
      }
    }

    &-prev {
      left: unset !important;
      right: calc(100% + 30px);
      background-color: $color-primary !important;

      @include respond-to(medium) {
        display: none;
      }
    }
  }
}

$video-card-icon-size: 50px;

.video-review-card {
  &__item {}

  &__preview {
    cursor: pointer;
    position: relative;
    display: block;
    height: 210px;
    margin-bottom: 10px;
    border-radius: 10px;
    overflow: hidden;

    transition: .2s ease-in-out;

    @include respond-to(small) {
      height: 150px;
    }

    // Иконка PLAY
    &:after {
      content: '';
      display: block;
      transition: .2s ease-in-out;
      height: $video-card-icon-size;
      width: $video-card-icon-size;
      position: absolute;
      top: calc(50% - #{$video-card-icon-size} / 2);
      left: calc(50% - #{$video-card-icon-size} / 2);
      background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg width='50px' height='50px' viewBox='0 0 50 50' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='Главная' transform='translate(-539.000000, -1322.000000)' fill='%23FFFFFF' fill-rule='nonzero'%3e%3cg id='Group-30' transform='translate(120.000000, 1157.000000)'%3e%3cg id='Group-20' transform='translate(305.000000, 112.000000)'%3e%3cg id='Group-16'%3e%3cg id='play-button' transform='translate(114.000000, 53.000000)'%3e%3cpath d='M25,0 C11.1930664,0 0,11.1928711 0,25 C0,38.8071289 11.1930664,50 25,50 C38.8069336,50 50,38.8071289 50,25 C50,11.1928711 38.8069336,0 25,0 Z M33.640625,26.3251953 L21.140625,34.1376953 C20.8876953,34.2956055 20.6000977,34.375 20.3125,34.375 C20.0519531,34.375 19.7910156,34.3101563 19.5548828,34.1789063 C19.0582031,33.9035156 18.75,33.3808594 18.75,32.8125 L18.75,17.1875 C18.75,16.6191406 19.0582031,16.0964844 19.5548828,15.8210937 C20.0515625,15.5441406 20.6588867,15.5609375 21.140625,15.8623047 L33.640625,23.6748047 C34.0972656,23.9609375 34.375,24.4614258 34.375,25 C34.375,25.5385742 34.0972656,26.0391602 33.640625,26.3251953 Z' id='Shape'%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
      background-size: contain;
      //@include respond-to(small) {
      //  height: 30px;
      //  width: 30px;
      //  top: calc(50% - 15px);
      //  left: calc(50% - 15px);
      //}
    }
  }

  &__title {
    font-size: 14px;
    font-weight: 400;
  }
}

// Промо-карточка
.promo-card {
  color: #fff;
  font-size: 1rem;
  background-position: center;
  background-size: cover;
  padding: 1.5rem;
  position: relative;
  border-radius: 1rem;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);
  }

  @include respond-from(medium) {
    padding: 3rem;
  }

  &__info {
    position: relative;
    z-index: 2;
  }

  &__badge {
    display: inline-block;
    border: 1px solid #fff;
    padding: 0.2rem 0.6rem;
    border-radius: 10rem;
    font-size: 0.8rem;
  }

  &__title {
    font-size: 1.7rem;
    font-weight: 600;
    line-height: 1.3;
  }

  &__desc {
    font-size: 0.9rem;
    line-height: 1.6;
    font-weight: 400;
  }

  &__price-title {
    font-size: 0.9rem;
    line-height: 1.4;
    margin-bottom: .5rem;
  }

  &__price {
    display: inline-block;
    font-size: 1.5rem;
    font-weight: 600;

    &--old {
      font-size: 1.3rem;
      font-weight: 400;
      position: relative;

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: red;
        position: absolute;
        top: 11px;
      }
    }
  }

  &__button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: $color-primary;
    padding: 0.8rem;
    border-radius: 10rem;
    transition: .1s ease-in-out;

    svg {
      margin-right: 10px;
    }

    &:hover {
      background-color: lighten($color-primary, 10%);
    }
  }

  &__count {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__count-number {
    background-color: #222;
    padding: 10px 7px;
    border-radius: 5px;
  }

  &__disclamer {
    font-size: 0.7rem;
    line-height: 1.5;
    font-weight: 300;
  }
}