*,
*::before,
*::after {
	box-sizing: border-box;
}

// .flex {
// 	display: flex;
// 	align-items: flex-start;
// 	justify-content: flex-start;
// }

// .--just-space {
// 	justify-content: space-between;
// }

// .--just-center {
// 	justify-content: center;
// }

// .--just-end {
// 	justify-content: flex-end;
// }

// .--align-str {
// 	align-items: stretch;
// }

// .--align-center {
// 	align-items: center;
// }

// .--align-end {
// 	align-items: flex-end;
// }

// .--dir-col {
// 	flex-direction: column;
// }

html {
	font-size: 16px;
}

html,
body {
	min-height: 100%;
	position: relative;
}

body {
	font-size: 1rem;
}

ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}