// @import "/Users/denisarosenko/Documents/Работа/www/gryzhi-net.local/src/scss/base/_01_normalize.scss";
@import "/Users/denisarosenko/Documents/Работа/www/gryzhi-net.local/src/scss/base/_grid.scss";
@import "/Users/denisarosenko/Documents/Работа/www/gryzhi-net.local/src/scss/base/_mixins.scss";

// @import "/Users/denisarosenko/Documents/Работа/www/gryzhi-net.local/src/scss/global/_fonts.scss";
@import "/Users/denisarosenko/Documents/Работа/www/gryzhi-net.local/src/scss/global/_global.scss";
@import "/Users/denisarosenko/Documents/Работа/www/gryzhi-net.local/src/scss/global/_svg.scss";
@import "/Users/denisarosenko/Documents/Работа/www/gryzhi-net.local/src/scss/global/_vars.scss";

// @import "/Users/denisarosenko/Documents/Работа/www/gryzhi-net.local/src/scss/_local-fonts.scss";
@import "/Users/denisarosenko/Documents/Работа/www/gryzhi-net.local/src/scss/base/_01_normalize.scss";
@import "/Users/denisarosenko/Documents/Работа/www/gryzhi-net.local/src/scss/base/_grid.scss";
@import "/Users/denisarosenko/Documents/Работа/www/gryzhi-net.local/src/scss/base/_mixins.scss";
@import "/Users/denisarosenko/Documents/Работа/www/gryzhi-net.local/src/scss/global/_fonts.scss";
@import "/Users/denisarosenko/Documents/Работа/www/gryzhi-net.local/src/scss/global/_global.scss";
@import "/Users/denisarosenko/Documents/Работа/www/gryzhi-net.local/src/scss/global/_svg.scss";
@import "/Users/denisarosenko/Documents/Работа/www/gryzhi-net.local/src/scss/global/_vars.scss";

// 
@import 'base/grid';

body {
  background: #fff; // для правильной работы mmenu
}

#mobile-nav:not(.mm-menu) {
  display: none;
}

.button {
  // padding: 1rem;
  background-color: $color-primary;
  border-radius: 20px;
  box-shadow: 0 0 0 0 transparent;
  color: #fff !important;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  letter-spacing: .42px;
  line-height: 42px;
  padding-left: 22px;
  padding-right: 22px;
  text-transform: uppercase;
  text-align: center;

  transition: .1s ease-in-out;
}

article,
.content-area {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #5B5F6E;

  p {
    margin-top: .8rem;
    margin-bottom: .8rem;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-top: 0;
    }
  }

  a {
    color: #009688;

    &:hover {
      color: darken($color: #009688, $amount: 10%);
    }
  }

  h1 {
    display: block;
    font-size: 2em;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: 500;
  }

  h2 {
    display: block;
    font-size: 1.5em;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    margin-left: 0;
    margin-right: 0;
    font-weight: 500;
  }

  h3 {
    display: block;
    font-size: 1.17em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    font-weight: 500;
  }

  h4 {
    display: block;
    margin-top: 1.33em;
    margin-bottom: 1.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: 500;
  }

  h5 {
    display: block;
    font-size: .83em;
    margin-top: 1.67em;
    margin-bottom: 1.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: 500;
  }

  h6 {
    display: block;
    font-size: .67em;
    margin-top: 2.33em;
    margin-bottom: 2.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: 500;
  }

  b {
    font-weight: 500;
  }

  ul {
    list-style-type: disc;
    padding-left: 1em;
  }

  ol {
    list-style-type: decimal;
    padding-left: 1em;
  }

  table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    overflow: hidden;

    thead tr {
      background-color: $color-primary;
      color: #ffffff;
      text-align: left;
    }

    & th,
    & td {
      padding: 12px 15px;
    }

    & tbody {
      tr {
        &:nth-of-type(even) {
          background-color: #f3f3f3;
        }

        &:last-of-type {
          border-bottom: 2px solid $color-primary;
        }

        &.active-row {
          font-weight: bold;
          color: $color-primary;
        }

      }
    }
  }
}

.header {
  &__top {
    padding: 20px 0;
    background-color: #f5f7fa;
  }
}

.header-top {
  &__button {
    @extend .button;
    background-color: $color-secondary;
  }

  &__phone {
    display: inline-block;
    font-size: 1.5rem;
    font-weight: 600;
  }
}

// Hero-slider
.hero-slider {
  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 80vh;
    background-color: #fafcff;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 25px 0;

    @include respond-to(medium) {
      background-image: unset !important;
    }
  }

  &__info {}

  &__header {
    color: $color-primary;
    font-size: 1.8rem;
    font-weight: 400;
    margin-bottom: 30px;
    line-height: 1.3;

    @include respond-from(medium) {
      font-size: 2.5rem;
    }
  }

  &__content {}

  &__button {
    @extend .button;
  }
}

// Блок в слайдере
.hero-slider-block {
  &__item {
    border-radius: 10px;
    padding: 1.5rem 2rem;

    &:nth-child(1) {
      background-color: #f5f9ff;
    }

    &:nth-child(2) {
      background-color: #edf2fa;
    }

    &:nth-child(3) {
      background-color: #e6edfa;
    }

    &:nth-child(4) {
      background-color: #dae4f5;
    }
  }

  &__icon {
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
  }

  &__desc {
    font-size: 1rem;
    line-height: 1.5;
  }
}

// МРТ-блок
.cta-mrt-block {
  &__item {
    position: relative;
    border-radius: 20px;
    border: 2px solid $color-secondary;
    padding: 1.7rem 2rem;
  }

  &__img {
    position: relative;
    height: 100px;
    width: 100px;

    img {
      position: absolute;
      bottom: 0;
      max-height: 100%;
      max-width: 100%;
    }
  }

  &__header {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 10px;
  }

  &__desc {
    font-size: 1.1rem;
  }

  &__button {
    @extend .button;

    background-color: #ff685a;

    &:hover {
      background-color: lighten(#ff685a, 7%);
    }
  }
}

#before-after-slider-pagination {
  bottom: unset !important;
  margin-top: 20px;
}

.before-after {

  &__section {
    background-color: #fafcff;
  }

  &__header {
    font-size: 2rem;
    margin-bottom: 20px;
  }

  &__desc {
    line-height: 1.4;
    font-size: 1rem;
  }

  &__img {
    border-radius: 15px;
  }

  &__text {
    h3 {
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 15px;
    }

    p {
      font-size: .9rem;
      line-height: 1.6;
      margin: 15px 0;
    }
  }

  &__label {
    font-size: 1rem;
    margin-bottom: 20px;
    padding: .5rem 1rem;
    background-color: $color-primary;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;

    &--before {
      background-color: #ff685a;
    }

    &--after {
      background-color: $color-secondary;
    }
  }

  &__list {
    list-style: none;

    li {
      font-size: 1rem;
      line-height: 1.5;
      display: flex;
      align-items: flex-start;
      margin-bottom: 20px;
    }

    &--before li {
      &:before {
        content: '–';
        color: #ff685a;
        font-weight: 900;
        font-size: 1.5rem;
        display: inline-block;
        flex-shrink: 0;
        height: 1em;
        width: 1em;
        position: relative;
        top: -8px;
      }
    }

    &--after li {
      &:before {
        content: '';
        display: inline-block;
        flex-shrink: 0;
        height: 1em;
        width: 1em;
        position: relative;
        top: 3px;
        background-image: url('../img/check.svg');
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 0.5em;
      }
    }
  }
}

.before-after-preview {
  &__wrapper {
    .swiper-button-prev {
      height: 40px !important;
      width: 40px !important;
      background-size: 20px;
      top: calc(50% - 25px);
      // right: 100% !important;
    }

    .swiper-button-next {
      height: 40px !important;
      width: 40px !important;
      background-size: 20px;
      top: calc(50% - 25px);
      // left: 100% !important;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__img {
    width: 110px;
    height: 110px;
    margin-bottom: 10px;
  }

  &__title {
    text-align: center;
    font-size: .9rem;
    font-weight: 400;
    line-height: 1.2;
  }
}

.info-block-1 {
  &__header {
    font-size: 1.4rem;
    line-height: 1.4;
    font-weight: 600;

    @include respond-from(medium) {
      font-size: 2rem;
      line-height: 1.4;
    }
  }

  &__text {
    font-size: 1rem;
    line-height: 1.7;

    p {
      margin-bottom: 15px;
    }

    span {
      font-weight: 600;
      color: $color-secondary;
    }
  }
}

.doctor-section {
  &__name {
    color: $color-primary;
    font-size: 2rem;
    margin-bottom: 20px;
  }

  &__status {
    font-size: 1.7em;
  }

  &__desc {
    margin-top: 20px;

    p {
      margin-bottom: 15px;
    }
  }
}

// Карточка
.card {
  position: relative;
  display: block;
  height: 100%;
  border-radius: calc(var(--curve) * 1px);
  overflow: hidden;
  text-decoration: none;
  border-radius: 15px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(to top, rgba(0, 0, 0, .7) 0%, rgba(0, 0, 0, .3) 100%);
  }

  &:hover .card__overlay {
    transform: translateY(0);
  }

  &__image {
    width: 100%;
    height: auto;
  }

  &__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    // border-radius: calc(var(--curve) * 1px);
    // background-color: var(--surface-color);
    transform: translateY(100%);
    transition: .2s ease-in-out;
  }

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    gap: 2em;
    padding: 2em;
    border-radius: calc(var(--curve) * 1px) 0 0 0;
    background-color: var(--surface-color);
    transform: translateY(-100%);
    transition: .2s ease-in-out;
  }

  &__arc {
    width: 80px;
    height: 80px;
    position: absolute;
    bottom: 100%;
    right: 0;
    z-index: 1;

    path {
      fill: var(--surface-color);
      d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
    }

    .card:hover .card__header {
      transform: translateY(0);
    }

    .card__arc .card__thumb {
      flex-shrink: 0;
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }

  &__title {
    font-size: 1.5em;
    font-weight: 500;
    margin: 0 0 1em;
    color: #fff;
  }

  &__tagline {
    display: block;
    margin: 1em 0;
    font-family: "MockFlowFont";
    font-size: .8em;
    color: #D7BDCA;
  }

  &__description {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.3;
    color: #fff;
  }

  &__button {
    padding: 0 2em 2em;
    margin: 0;
    color: #fff;
    display: -webkit-box;
    overflow: hidden;
  }
}

.card-2 {
  &__item {
    display: flex;
    flex-direction: column;
    background-color: #f5f7fa;
    border-radius: 15px;
    overflow: hidden;
  }

  &__img {}

  &__info {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 25px 30px;
  }

  &__title {
    font-size: 1.5em;
    font-weight: 500;
    margin: 0 0 .8em;
  }

  &__desc {
    font-size: 0.9rem;
    line-height: 1.5;
  }

  &__button {
    @extend .button;
    margin-top: 20px;
  }

}

.doctors-block {
  &__section {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.doctors-block-card {
  &__item {
    background-color: #fff;
    border-radius: 20px;
    // border: 1px solid $color-primary;
    padding: 20px 25px;
  }

  &__title {
    color: $color-primary;
    font-size: 1.2rem;
    margin-bottom: 20px;
  }

  &__desc {
    font-size: .9rem;
    line-height: 1.4;
  }

  &__button {
    @extend .button;
    font-size: .8rem;
    color: $color-primary !important;
    margin-top: 20px;
    background-color: #fff;
    border: 1px solid $color-primary;

    &:hover {
      color: #fff !important;
      background-color: $color-primary;
    }
  }
}

.footer {
  background-color: #303030;

  &__phone {
    display: inline-block;
    color: #fff;
    opacity: .5;
    font-size: 1.1rem;
    font-weight: 600;

    &:hover {
      color: #fff !important;
      opacity: 1;
    }
  }

  &__disclamer {
    color: #fafafa;
    font-size: 12px;
    line-height: 1.55;
    font-weight: 300;
    opacity: 0.5;
  }
}

.modal-content {
  &__caption {
    font-size: 1rem;
    margin-bottom: 15px;
  }

  &__header {
    font-size: 1.8em;
    font-weight: 500;
    margin-bottom: 20px;
  }

  &__text {
    @extend .content-area;
  }
}

.contacts-page {
  &__label {
    font-size: 1rem;
    margin-bottom: 5px;
  }

  &__value {
    font-size: 1.5em;
    line-height: 1.5;
    font-weight: 500;
  }

  &__map {
    border-radius: 15px;
    overflow: hidden;
  }
}