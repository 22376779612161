.cases-swiper-pagination {
  position: absolute !important;
  bottom: -2.5rem !important;
}

article,
aside,
footer,
header,
nav,
section {
  display: block
}

body {
  margin: 0
}

h1 {
  font-size: 2em;
  margin: .67em 0
}

figcaption,
figure,
main {
  display: block
}

figure {
  margin: 1em 40px
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible
}

nav ol,
nav ul {
  list-style: none
}

pre {
  font-family: monospace, monospace;
  font-size: 1em
}

a {
  -webkit-text-decoration-skip: objects;
  background-color: transparent
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted
}

b,
strong {
  font-weight: inherit;
  font-weight: bolder
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em
}

dfn {
  font-style: italic
}

mark {
  background-color: #ff0;
  color: #000
}

small {
  font-size: 80%
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline
}

sub {
  bottom: -.25em
}

sup {
  top: -.5em
}

::selection {
  background-color: #b3d4fc;
  color: #000;
  text-shadow: none
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle
}

audio,
video {
  display: inline-block
}

audio:not([controls]) {
  display: none;
  height: 0
}

img {
  border-style: none
}

svg {
  fill: currentColor
}

svg:not(:root) {
  overflow: hidden
}

table {
  border-collapse: collapse
}

button,
input,
optgroup,
select,
textarea {
  margin: 0
}

button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  line-height: inherit
}

button,
input {
  overflow: visible
}

button,
select {
  text-transform: none
}

[type=reset],
[type=submit],
button,
html [type=button] {
  -webkit-appearance: button
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0
}

[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted ButtonText
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal
}

progress {
  display: inline-block;
  vertical-align: baseline
}

textarea {
  overflow: auto;
  resize: vertical
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit
}

details,
menu {
  display: block
}

summary {
  display: list-item
}

canvas {
  display: inline-block
}

template {
  display: none
}

[tabindex],
a,
area,
button,
input,
label,
select,
summary,
textarea {
  touch-action: manipulation
}

[hidden] {
  display: none
}

[aria-busy=true] {
  cursor: progress
}

[aria-controls] {
  cursor: pointer
}

[aria-hidden=false][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute
}

[aria-disabled] {
  cursor: default
}

.mfp-bg {
  background: #0b0b0b;
  opacity: .8;
  overflow: hidden;
  z-index: 1042
}

.mfp-bg,
.mfp-wrap {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%
}

.mfp-wrap {
  -webkit-backface-visibility: hidden;
  outline: none !important;
  z-index: 1043
}

.mfp-container {
  box-sizing: border-box;
  height: 100%;
  left: 0;
  padding: 0 8px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%
}

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle
}

.mfp-align-top .mfp-container:before {
  display: none
}

.mfp-content {
  display: inline-block;
  margin: 0 auto;
  position: relative;
  text-align: left;
  vertical-align: middle;
  z-index: 1045
}

.mfp-ajax-holder .mfp-content,
.mfp-inline-holder .mfp-content {
  cursor: auto;
  width: 100%
}

.mfp-ajax-cur {
  cursor: progress
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: zoom-out
}

.mfp-zoom {
  cursor: pointer;
  cursor: zoom-in
}

.mfp-auto-cursor .mfp-content {
  cursor: auto
}

.mfp-arrow,
.mfp-close,
.mfp-counter,
.mfp-preloader {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.mfp-loading.mfp-figure {
  display: none
}

.mfp-hide {
  display: none !important
}

.mfp-preloader {
  color: #ccc;
  left: 8px;
  margin-top: -.8em;
  position: absolute;
  right: 8px;
  text-align: center;
  top: 50%;
  width: auto;
  z-index: 1044
}

.mfp-preloader a {
  color: #ccc
}

.mfp-preloader a:hover {
  color: #fff
}

.mfp-s-error .mfp-content,
.mfp-s-ready .mfp-preloader {
  display: none
}

button.mfp-arrow,
button.mfp-close {
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  display: block;
  outline: none;
  overflow: visible;
  padding: 0;
  touch-action: manipulation;
  z-index: 1046
}

button::-moz-focus-inner {
  border: 0;
  padding: 0
}

.mfp-close {
  color: #fff;
  font-family: Arial, Baskerville, monospace;
  font-size: 28px;
  font-style: normal;
  height: 44px;
  line-height: 44px;
  opacity: .65;
  padding: 0 0 18px 10px;
  position: absolute;
  right: 0;
  text-align: center;
  text-decoration: none;
  top: 0;
  width: 44px
}

.mfp-close:focus,
.mfp-close:hover {
  opacity: 1
}

.mfp-close:active {
  top: 1px
}

.mfp-close-btn-in .mfp-close {
  color: #333
}

.mfp-iframe-holder .mfp-close,
.mfp-image-holder .mfp-close {
  color: #fff;
  padding-right: 6px;
  right: -6px;
  text-align: right;
  width: 100%
}

.mfp-counter {
  color: #ccc;
  font-size: 12px;
  line-height: 18px;
  position: absolute;
  right: 0;
  top: 0;
  white-space: nowrap
}

.mfp-arrow {
  -webkit-tap-highlight-color: transparent;
  height: 110px;
  margin: -55px 0 0;
  opacity: .65;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 90px
}

.mfp-arrow:active {
  margin-top: -54px
}

.mfp-arrow:focus,
.mfp-arrow:hover {
  opacity: 1
}

.mfp-arrow:after,
.mfp-arrow:before {
  border: inset transparent;
  content: "";
  display: block;
  height: 0;
  left: 0;
  margin-left: 35px;
  margin-top: 35px;
  position: absolute;
  top: 0;
  width: 0
}

.mfp-arrow:after {
  border-bottom-width: 13px;
  border-top-width: 13px;
  top: 8px
}

.mfp-arrow:before {
  border-bottom-width: 21px;
  border-top-width: 21px;
  opacity: .7
}

.mfp-arrow-left {
  left: 0
}

.mfp-arrow-left:after {
  border-right: 17px solid #fff;
  margin-left: 31px
}

.mfp-arrow-left:before {
  border-right: 27px solid #3f3f3f;
  margin-left: 25px
}

.mfp-arrow-right {
  right: 0
}

.mfp-arrow-right:after {
  border-left: 17px solid #fff;
  margin-left: 39px
}

.mfp-arrow-right:before {
  border-left: 27px solid #3f3f3f
}

.mfp-iframe-holder {
  padding-bottom: 40px;
  padding-top: 40px
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  max-width: 900px;
  width: 100%
}

.mfp-iframe-holder .mfp-close {
  top: -40px
}

.mfp-iframe-scaler {
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
  width: 100%
}

.mfp-iframe-scaler iframe {
  background: #000;
  box-shadow: 0 0 8px rgba(0, 0, 0, .6);
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

img.mfp-img {
  box-sizing: border-box;
  display: block;
  height: auto;
  margin: 0 auto;
  max-width: 100%;
  padding: 40px 0;
  width: auto
}

.mfp-figure,
img.mfp-img {
  line-height: 0
}

.mfp-figure:after {
  background: #444;
  bottom: 40px;
  box-shadow: 0 0 8px rgba(0, 0, 0, .6);
  content: "";
  display: block;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 40px;
  width: auto;
  z-index: -1
}

.mfp-figure small {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 14px
}

.mfp-figure figure {
  margin: 0
}

.mfp-bottom-bar {
  cursor: auto;
  left: 0;
  margin-top: -36px;
  position: absolute;
  top: 100%;
  width: 100%
}

.mfp-title {
  word-wrap: break-word;
  color: #f3f3f3;
  line-height: 18px;
  padding-right: 36px;
  text-align: left
}

.mfp-image-holder .mfp-content {
  max-width: 100%
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer
}

@media screen and (max-height:300px),
screen and (max-width:800px) and (orientation:landscape) {
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0
  }

  .mfp-img-mobile img.mfp-img {
    padding: 0
  }

  .mfp-img-mobile .mfp-figure:after {
    bottom: 0;
    top: 0
  }

  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px
  }

  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, .6);
    bottom: 0;
    box-sizing: border-box;
    margin: 0;
    padding: 3px 5px;
    position: fixed;
    top: auto
  }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0
  }

  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px
  }

  .mfp-img-mobile .mfp-close {
    background: rgba(0, 0, 0, .6);
    height: 35px;
    line-height: 35px;
    padding: 0;
    position: fixed;
    right: 0;
    text-align: center;
    top: 0;
    width: 35px
  }
}

@media (max-width:900px) {
  .mfp-arrow {
    transform: scale(.75)
  }

  .mfp-arrow-left {
    transform-origin: 0
  }

  .mfp-arrow-right {
    transform-origin: 100%
  }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px
  }
}

.tippy-popper[x-placement^=top] .tippy-tooltip.light-theme .tippy-arrow {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #fff
}

.tippy-popper[x-placement^=bottom] .tippy-tooltip.light-theme .tippy-arrow {
  border-bottom: 7px solid #fff;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent
}

.tippy-popper[x-placement^=left] .tippy-tooltip.light-theme .tippy-arrow {
  border-bottom: 7px solid transparent;
  border-left: 7px solid #fff;
  border-top: 7px solid transparent
}

.tippy-popper[x-placement^=right] .tippy-tooltip.light-theme .tippy-arrow {
  border-bottom: 7px solid transparent;
  border-right: 7px solid #fff;
  border-top: 7px solid transparent
}

.tippy-tooltip.light-theme {
  -webkit-box-shadow: 0 0 20px 4px rgba(154, 161, 177, .15), 0 4px 80px -8px rgba(36, 40, 47, .25), 0 4px 4px -2px rgba(91, 94, 105, .15);
  box-shadow: 0 0 20px 4px rgba(154, 161, 177, .15), 0 4px 80px -8px rgba(36, 40, 47, .25), 0 4px 4px -2px rgba(91, 94, 105, .15);
  color: #26323d
}

.tippy-tooltip.light-theme .tippy-backdrop {
  background-color: #fff
}

.tippy-tooltip.light-theme .tippy-roundarrow {
  fill: #fff
}

.tippy-tooltip.light-theme[data-animatefill] {
  background-color: transparent
}

.slick-slider {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  touch-action: pan-y;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.slick-list,
.slick-slider {
  display: block;
  position: relative
}

.slick-list {
  margin: 0;
  padding: 0
}

.slick-list:focus {
  outline: none
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand
}

.slick-slider .slick-list,
.slick-slider .slick-track {
  transform: translateZ(0)
}

.slick-track {
  display: block;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 0
}

.slick-track:after,
.slick-track:before {
  content: "";
  display: table
}

.slick-track:after {
  clear: both
}

.slick-loading .slick-track {
  visibility: hidden
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px
}

[dir=rtl] .slick-slide {
  float: right
}

.slick-slide img {
  display: block
}

.slick-slide.slick-loading img {
  display: none
}

.slick-slide.dragging img {
  pointer-events: none
}

.slick-initialized .slick-slide {
  display: block
}

.slick-loading .slick-slide {
  visibility: hidden
}

.slick-vertical .slick-slide {
  border: 1px solid transparent;
  display: block;
  height: auto
}

.slick-arrow.slick-hidden {
  display: none
}

.calc__result:after,
.checkbox+label:after,
.choice-of-two__buttons:after,
.compare-prices__block__table>div:after,
.contact-ribbon:after,
.contact-us__form:after,
.counseling-answer-ribbon:after,
.counseling-answer-ribbon__employee:after,
.counselings-list__item__employee:after,
.counselings-list__item__links:after,
.header-menu__sections:after,
.header-menu__teaser:after,
.html-slider__pager-wrapper:after,
.image-slider__navigator:after,
.individually__call:after,
.info-block__employee-address__grid:after,
.memo__form:after,
.modal__content:after,
.modal__social-icons:after,
.offer-block:after,
.offer_big .offer__card:after,
.price-table__col:after,
.prices-block-inner:after,
.review__form__button:after,
.review__info:after,
.row:after,
.row_short:after,
.seo-table_price .seo-table__col:after,
.service-mini__calc__checkboxes:after,
.stroll-clinic-online__cards:after {
  clear: both;
  content: "";
  display: table
}

#vk_comments,
.about-employee-simple,
.banned-wrapper,
.banner-plus,
.best-brands__block,
.best-flex__grid__item .progressive-image-wrapper,
.better-conditions__sub-title,
.button_with-plus,
.choice-of-two__buttons,
.compare-prices__block__offer,
.compare-prices__block__title__name,
.contact-us__director>div,
.cure-teeth__icon,
.delivery-rest__number,
.delivery-rest__text,
.employee-certificates-slider__navigator>div>div,
.footer-top__booster,
.green-grid__grid__image,
.green-grid__grid__text,
.grid-info__img,
.grid-info__text,
.grid-info__title,
.h2-sub,
.hold-anti-crisis-prices__sub-title,
.info-block,
.installment-terms-modal__grid__text,
.installment-terms__block__text,
.installment-terms__text,
.main-page-header__mobile-button,
.news-slider__navigator>div>div,
.payment-page .price-formula h2,
.price-formula .h2,
.price-formula .payment-page h2,
.price-table,
.prices-tabs__advantages>:before,
.ribbon__container,
.row,
.row_short,
.seo-table_price .seo-table__table,
.service-mini__button,
.slide-tabs,
.slider-star_second__slide__info,
.slider-star_second__slide__quote,
.tabs,
.types-of-crowns-for-implants__grid-crowns__image,
.types-of-crowns-for-implants__grid-crowns__text,
.video-slider__navigator>div>div,
.what-is-included__price-button,
.wir__total {
  margin-left: auto;
  margin-right: auto
}

.circle-icon,
.compare-prices__block__cut-price,
.double-grid_calculate>:first-child,
.employee-certificates-slider__navigator__button,
.fix-cost__button,
.guest-book-slider__navigator__button,
.header-menu__dropdown_mobile__bottom>div,
.header-menu__dropdowns_mobile__tabs>*,
.header-menu_mobile__sections>*,
.html-slider__navigator__button,
.html-slider__pager .slider__item,
.html-slider__pager-wrapper>:first-child,
.ir-implantation__right__icon,
.map-addresses__close,
.metro-map__grid>*,
.mfp-inline-holder .mfp-close,
.mfp_image-popup .mfp-close,
.mfp_video-popup .mfp-close,
.news-slider__navigator__button,
.news-slider__slide__content,
.numeric-list .list-toggle>li:before,
.numeric-list>li:before,
.page-header__offer__achievement>div,
.page-header__wallpaper,
.price-table__links,
.prices-slider__navigator__button,
.scrolls>.row>*,
.scrolls>.row_short>*,
.slider-employee__slide__feature,
.therapist__block__feature,
.video-slider__navigator__button,
.warranty-list__image:before,
.why-smart__item__icon {
  align-items: center;
  display: flex;
  justify-content: center
}

.circle-icon:after,
.compare-prices__block__cut-price:after,
.double-grid_calculate>:first-child:after,
.employee-certificates-slider__navigator__button:after,
.fix-cost__button:after,
.guest-book-slider__navigator__button:after,
.header-menu__dropdown_mobile__bottom>div:after,
.header-menu__dropdowns_mobile__tabs>:after,
.header-menu_mobile__sections>:after,
.html-slider__navigator__button:after,
.html-slider__pager .slider__item:after,
.html-slider__pager-wrapper>:first-child:after,
.ir-implantation__right__icon:after,
.map-addresses__close:after,
.metro-map__grid>:after,
.mfp-inline-holder .mfp-close:after,
.mfp_image-popup .mfp-close:after,
.mfp_video-popup .mfp-close:after,
.news-slider__navigator__button:after,
.news-slider__slide__content:after,
.page-header__offer__achievement>div:after,
.page-header__wallpaper:after,
.price-table__links:after,
.prices-slider__navigator__button:after,
.scrolls>.row>:after,
.scrolls>.row_short>:after,
.slider-employee__slide__feature:after,
.therapist__block__feature:after,
.video-slider__navigator__button:after,
.why-smart__item__icon:after {
  content: "";
  font-size: 0;
  min-height: inherit
}

.accordion__tab__title:not(.accordion__tab__title_no-style),
.affiliate-info__contacts__link,
.appointment__image-wrapper,
.calc__steps,
.copyright,
.double-grid_calculate>*,
.employee-certificates-slider .progressive-image-wrapper,
.employees__employee__feature,
.footer-bottom__partners,
.footer-bottom__privacy-policy,
.footer2__callback,
.footer2__description,
.footer2__logo,
.footer2__phone,
.footer2__work-time,
.header-menu,
.header-menu__dropdown__side-menu__link,
.header-menu__link,
.header-search,
.header-search__close,
.header-search__link,
.header__awards,
.header__callback,
.header__clinics,
.header__contact-management,
.header__description,
.header__logo,
.header__phone,
.header__slinks,
.icon-links__image,
.info-block__employee-address__grid>*,
.info-block__employee-address__grid>:nth-child(3)>div,
.info-block__reviews>:last-child,
.input__label_file,
.ir-implantation__left,
.map-addresses__name>div,
.mass-media__item__icon,
.offer_big .offer__card>:last-child,
.page-header__feature,
.page-header__feature__awards,
.page-header__title__note>div,
.page-header__wallpaper-wrapper__content>div,
.review__form__button>:last-child,
.ribbon_schedule .ribbon__container,
.service-mini_implantation .service-mini__calc__checkboxes .checkbox+label,
.sign-up-now__phone,
.slider-case__item__info .employee,
.slider-case__pager,
.wir__grid-row,
.wir__grid-row>.stage .number,
.wir__grid-row>.stage-items {
  align-items: center;
  display: flex
}

.accordion__tab__title:not(.accordion__tab__title_no-style):after,
.affiliate-info__contacts__link:after,
.appointment__image-wrapper:after,
.calc__steps:after,
.copyright:after,
.double-grid_calculate>:after,
.employee-certificates-slider .progressive-image-wrapper:after,
.employees__employee__feature:after,
.footer-bottom__partners:after,
.footer-bottom__privacy-policy:after,
.footer2__callback:after,
.footer2__description:after,
.footer2__logo:after,
.footer2__phone:after,
.footer2__work-time:after,
.header-menu:after,
.header-menu__dropdown__side-menu__link:after,
.header-menu__link:after,
.header-search:after,
.header-search__close:after,
.header-search__link:after,
.header__awards:after,
.header__callback:after,
.header__clinics:after,
.header__contact-management:after,
.header__description:after,
.header__logo:after,
.header__phone:after,
.header__slinks:after,
.icon-links__image:after,
.info-block__employee-address__grid>:after,
.info-block__employee-address__grid>:nth-child(3)>div:after,
.info-block__reviews>:last-child:after,
.input__label_file:after,
.ir-implantation__left:after,
.map-addresses__name>div:after,
.mass-media__item__icon:after,
.offer_big .offer__card>:last-child:after,
.page-header__feature:after,
.page-header__feature__awards:after,
.page-header__title__note>div:after,
.page-header__wallpaper-wrapper__content>div:after,
.review__form__button>:last-child:after,
.ribbon_schedule .ribbon__container:after,
.service-mini_implantation .service-mini__calc__checkboxes .checkbox+label:after,
.sign-up-now__phone:after,
.slider-case__item__info .employee:after,
.slider-case__pager:after,
.wir__grid-row:after,
.wir__grid-row>.stage .number:after,
.wir__grid-row>.stage-items:after {
  content: "";
  font-size: 0;
  min-height: inherit
}

.row,
.row_short {
  padding-left: 70px;
  padding-right: 70px
}

.calc__result__button,
.calc__steps>*,
.checkbox+label,
.fair-prices,
.input__label_file,
.service-advantages__slider,
[data-js=tooltip],
header,
span.checkbox+label {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.affiliate-info__grid,
.appointment__bottom,
.appointment__grid,
.attainment-image-slider,
.banner-plus,
.banner-warning,
.compare__grid,
.employee-quote__grid,
.footer-bottom__ratings,
.fpv__percents,
.header-menu__dropdown__menu_grid,
.independent-ratings__grid,
.info-block__affiliates__grid,
.info-block__affiliates__grid>:first-child,
.is-suitable__grid,
.mass-media-ribbon-slider,
.mass-media-ribbon__grid,
.mmr__grid__item .icon,
.price-formula__items,
.price-formula__stage,
.prices-block__bottom,
.prices-slider__item__content,
.prices-slider__navigator>div,
.prices-table__row,
.ptt__stock,
.ptt__tab .bottom,
.ptt__tab__list__employee,
.scrolls>.row,
.scrolls>.row_short,
.search-wrapper .search__results__item__content,
.service-advantages__grid,
.service-feedback,
.service-work__flex,
.service-work_implantation .service-work__desc,
.video-holder__videos,
.virtual-tour__grid,
.why-choose-with-certificates .why-choose__grid,
.wir__total {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start
}

.affiliate-info__grid>*,
.appointment__bottom>*,
.appointment__grid>*,
.attainment-image-slider>*,
.banner-plus>*,
.banner-warning>*,
.compare__grid>*,
.employee-quote__grid>*,
.footer-bottom__ratings>*,
.fpv__percents>*,
.header-menu__dropdown__menu_grid>*,
.independent-ratings__grid>*,
.info-block__affiliates__grid>*,
.info-block__affiliates__grid>:first-child>*,
.is-suitable__grid>*,
.mass-media-ribbon-slider>*,
.mass-media-ribbon__grid>*,
.mmr__grid__item .icon>*,
.price-formula__items>*,
.price-formula__stage>*,
.prices-block__bottom>*,
.prices-slider__item__content>*,
.prices-slider__navigator>div>*,
.prices-table__row>*,
.ptt__stock>*,
.ptt__tab .bottom>*,
.ptt__tab__list__employee>*,
.scrolls>.row>*,
.scrolls>.row_short>*,
.search-wrapper .search__results__item__content>*,
.service-advantages__grid>*,
.service-feedback>*,
.service-work__flex>*,
.service-work_implantation .service-work__desc>*,
.video-holder__videos>*,
.virtual-tour__grid>*,
.why-choose-with-certificates .why-choose__grid>*,
.wir__total>* {
  flex: 1 0
}

.about-employee,
.answers-videos__full-video,
.answers-videos__grid,
.answers-videos__grid__full-video,
.answers-videos__grid__grid,
.appointment__form__grid,
.banner-confidence__grid,
.best-flex__price-grid,
.block-in-frame__grid,
.calc__option__counts-grid,
.calc__option__crowns-grid,
.calc__option__systems-grid,
.cards-with-text__grid,
.choice-of-two__grid,
.compare-price__extraction,
.compare-prices__installment,
.contact-us__form__button,
.contact-us__grid,
.delivery-rest__advantages,
.director-quote__grid,
.double-grid,
.employee-specialization__grid,
.fair-prices__items,
.footer-top__booster,
.four-banners__banners,
.guest-book__content,
.half-block__grid,
.header-menu-wrapper_with-search .header-search,
.header-menu__dropdown__menu__row,
.header-menu__dropdown__menu_half,
.header-menu__dropdowns_mobile__tabs,
.hold-anti-crisis-prices__grid,
.how-to-get__grid,
.imaged-block__grid,
.imaged-block_simple .imaged-block__grid,
.individual-advantages,
.info-quote__grid,
.ir-implantation__grid,
.ir-implantation__right,
.lead-write__grid,
.mass-media__grid,
.memo__grid,
.minus-list_flex,
.offers__grid,
.payment-form__button,
.payment-form__contacts-fields,
.plus-list_flex,
.prices-slider__item__list,
.primary-admission-form__answers-grid,
.primary-admission-form__button,
.rating-of-implants__grid,
.ribbon-banners,
.service-mini,
.service-mini__addresses,
.service-mini__items,
.service-work__result,
.side-by-side__block,
.stars-trust,
.steps-operations__item,
.steps__block,
.text-with-booster__grid,
.through-block__sub-title__grid,
.through-blocks-double,
.two-visits-slider,
.types-of-crowns-for-implants__grid,
.video-holder,
.warranty-triple .warranty__quote-block,
.warranty__grid,
.warranty__text__grid,
.what-is-included-points__blocks,
.what-is-included-points__info,
.who-suits__quote,
.why-choose-us__text__grid,
.why-choose-with-certificates .why-choose__list,
.with-image-slider,
.work-about__grid,
.work-about__grid>:first-child>div {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start
}

.about-employee>*,
.answers-videos__full-video>*,
.answers-videos__grid>*,
.answers-videos__grid__full-video>*,
.answers-videos__grid__grid>*,
.appointment__form__grid>*,
.banner-confidence__grid>*,
.best-flex__price-grid>*,
.block-in-frame__grid>*,
.calc__option__counts-grid>*,
.calc__option__crowns-grid>*,
.calc__option__systems-grid>*,
.cards-with-text__grid>*,
.choice-of-two__grid>*,
.compare-price__extraction>*,
.compare-prices__installment>*,
.contact-us__form__button>*,
.contact-us__grid>*,
.delivery-rest__advantages>*,
.director-quote__grid>*,
.double-grid>*,
.employee-specialization__grid>*,
.fair-prices__items>*,
.footer-top__booster>*,
.four-banners__banners>*,
.guest-book__content>*,
.half-block__grid>*,
.header-menu-wrapper_with-search .header-search>*,
.header-menu__dropdown__menu__row>*,
.header-menu__dropdown__menu_half>*,
.header-menu__dropdowns_mobile__tabs>*,
.hold-anti-crisis-prices__grid>*,
.how-to-get__grid>*,
.imaged-block__grid>*,
.imaged-block_simple .imaged-block__grid>*,
.individual-advantages>*,
.info-quote__grid>*,
.ir-implantation__grid>*,
.ir-implantation__right>*,
.lead-write__grid>*,
.mass-media__grid>*,
.memo__grid>*,
.minus-list_flex>*,
.offers__grid>*,
.payment-form__button>*,
.payment-form__contacts-fields>*,
.plus-list_flex>*,
.prices-slider__item__list>*,
.primary-admission-form__answers-grid>*,
.primary-admission-form__button>*,
.rating-of-implants__grid>*,
.ribbon-banners>*,
.service-mini>*,
.service-mini__addresses>*,
.service-mini__items>*,
.service-work__result>*,
.side-by-side__block>*,
.stars-trust>*,
.steps-operations__item>*,
.steps__block>*,
.text-with-booster__grid>*,
.through-block__sub-title__grid>*,
.through-blocks-double>*,
.two-visits-slider>*,
.types-of-crowns-for-implants__grid>*,
.video-holder>*,
.warranty-triple .warranty__quote-block>*,
.warranty__grid>*,
.warranty__text__grid>*,
.what-is-included-points__blocks>*,
.what-is-included-points__info>*,
.who-suits__quote>*,
.why-choose-us__text__grid>*,
.why-choose-with-certificates .why-choose__list>*,
.with-image-slider>*,
.work-about__grid>*,
.work-about__grid>:first-child>div>* {
  flex: 1 0 50%
}

.approved-clinic__grid,
.articles-list__content,
.ba-mini__grid,
.best-flex__grid,
.better-conditions__grid,
.compare-prices_prosthetics,
.disadvantages__grid,
.dream-treatment__grid,
.green-grid__grid,
.header-menu__dropdown__menu_third,
.header-menu_mobile__sections,
.honest-reviews__proofs,
.info-block__contacts__grid,
.info-block__service-work__grid,
.installment-terms-modal__grid,
.main-works__section,
.metro-map__grid,
.news-list__content,
.news-smart__items,
.payment-form__fio-fields,
.prices-tabs__advantages,
.primary-admission-form__grid,
.ptt__tab__advantages,
.services-grid,
.smart-service__grid,
.stroll-clinic-online__cards__grid,
.team-criteria,
.thank-for-trust__achievements,
.thank-for-trust__achievements_tabs,
.types-of-crowns-for-implants__grid-crowns,
.warranty-list__grid,
.works-list__grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start
}

.approved-clinic__grid>*,
.articles-list__content>*,
.ba-mini__grid>*,
.best-flex__grid>*,
.better-conditions__grid>*,
.compare-prices_prosthetics>*,
.disadvantages__grid>*,
.dream-treatment__grid>*,
.green-grid__grid>*,
.header-menu__dropdown__menu_third>*,
.header-menu_mobile__sections>*,
.honest-reviews__proofs>*,
.info-block__contacts__grid>*,
.info-block__service-work__grid>*,
.installment-terms-modal__grid>*,
.main-works__section>*,
.metro-map__grid>*,
.news-list__content>*,
.news-smart__items>*,
.payment-form__fio-fields>*,
.prices-tabs__advantages>*,
.primary-admission-form__grid>*,
.ptt__tab__advantages>*,
.services-grid>*,
.smart-service__grid>*,
.stroll-clinic-online__cards__grid>*,
.team-criteria>*,
.thank-for-trust__achievements>*,
.thank-for-trust__achievements_tabs>*,
.types-of-crowns-for-implants__grid-crowns>*,
.warranty-list__grid>*,
.works-list__grid>* {
  flex: 1 0 33.33%
}

.best-brands__grid,
.cure-teeth-grid,
.employees,
.four-time-guarantee__grid,
.grid-info__items,
.installment-terms__grid,
.links-block__grid,
.main-services__list,
.reasons__grid,
.requisites__certs,
.reviews-block__photos,
.team-criteria-iconed,
.through-block__countdown,
.types-grid__grid,
.warranty-triple .warranty__text__grid,
.what-is-included-points__grid,
.who-suits__grid,
.why-smart__items,
.why-we__grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start
}

.best-brands__grid>*,
.cure-teeth-grid>*,
.employees>*,
.four-time-guarantee__grid>*,
.grid-info__items>*,
.installment-terms__grid>*,
.links-block__grid>*,
.main-services__list>*,
.reasons__grid>*,
.requisites__certs>*,
.reviews-block__photos>*,
.team-criteria-iconed>*,
.through-block__countdown>*,
.types-grid__grid>*,
.warranty-triple .warranty__text__grid>*,
.what-is-included-points__grid>*,
.who-suits__grid>*,
.why-smart__items>*,
.why-we__grid>* {
  flex: 1 0 25%
}

.links-block__grid_fifth {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start
}

.links-block__grid_fifth>* {
  flex: 1 0 20%
}

.contact-us__divider,
.contacts__better-conditions-divider,
.contacts__contact-us-divider,
.contacts__team-divider,
.divider,
.employee__certificates_slider-divider,
.employee__employee-address-divider,
.employee__employee-specialization-divider,
.employees__slider-star-divider,
.main-works__section__divider,
.portfolio__info-block-divider,
.portfolio__team-divider,
.reviews__guest-book-divider,
.reviews__independent-ratings-divider,
.reviews__slider-star-divider,
.service__cost-of-divider,
.service__imaged-block-divider,
.service__info-quote-divider,
.service__reviews-list-divider,
.service__stages-divider,
.service__team-divider,
.service_section__imaged-block-divider,
.service_section__info-block-divider,
.service_section__warranty-divider,
.thank-for-trust-divider,
.work-section__half_block-divider,
.work-section__imaged-block-divider,
.work-section__reviews-list-divider,
.work-section__team-divider,
.work-section__works-list-divider,
.work__half-block-divider,
.work__reviews-list-divider {
  background-color: #d9e1e1;
  height: 1px;
  width: 100%
}

@media screen and (max-width:1345px) {

  .row,
  .row_short {
    padding-left: 56px;
    padding-right: 56px
  }
}

@media screen and (max-width:1023px) {

  .row,
  .row_short {
    padding-left: 26px;
    padding-right: 26px
  }
}

@media screen and (max-width:767px) {

  .row,
  .row_short {
    padding-left: 15px;
    padding-right: 15px
  }
}

* {
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  margin: 0
}

:focus {
  outline: none
}

html {
  color: #5f6877;

  font-size: 16px;
  font-style: normal;
  font-weight: 400
}

body,
html {
  overflow-x: hidden
}

.content {
  height: 100%;
  transition: -webkit-filter .2s ease-in;
  transition: filter .2s ease-in;
  transition: filter .2s ease-in, -webkit-filter .2s ease-in
}

.content.blurred {
  -webkit-filter: blur(3px);
  filter: blur(3px)
}

.emoji {
  font-style: normal
}

::selection {
  background: rgba(216, 117, 0, .5)
}

::-moz-selection {
  background: rgba(216, 117, 0, .5)
}

.right {
  float: right
}

.left {
  float: left
}

[data-js=image-popup],
[data-js=video-popup] {
  cursor: pointer
}

button,
input,
textarea {
  border: none;
  outline: none
}

button:active,
button:focus,
button:hover,
input:active,
input:focus,
input:hover,
textarea:active,
textarea:focus,
textarea:hover {
  outline: none
}

button {
  cursor: pointer;
  padding: 0
}

input:not([type=submit]):active,
input:not([type=submit]):focus,
textarea:active,
textarea:focus {
  outline: none
}

.appointment__inner,
.attainment-image-slider-wrapper_allon4 .attainment-image-slider,
.bg_dots,
.cards-with-text:before,
.director-quote__right:before,
.is-suitable.bg_dots-half:before,
.main-page-header__badge:before,
.offer_standard .offer__card:before,
.payment-form,
.through-block-short .through-block_hold-prices,
.with-image-slider-wrapper_dots,
.works-slider__item {
  background-image: url(/build/i/cd469867279222393ec9.svg);
  background-repeat: repeat;
  background-size: 21px 26px
}

:-moz-ui-invalid:not(output) {
  box-shadow: none
}

.form__error {
  bottom: 3px;
  color: #e37318;
  display: inline-block;
  font-size: 12px;
  left: 25px;
  position: absolute
}

.progressive-image-wrapper {
  border-radius: 10px
}

.center {
  text-align: center
}

.no-wrap {
  white-space: nowrap
}

.mail-to {
  display: inline-block;
  margin-top: 23px
}

.bold {
  font-weight: 700
}

.bold,
.medium {

  font-style: normal
}

.medium {
  font-weight: 500
}

@media screen and (max-width:1024px) {

  .divider,
  .pusher {
    margin-top: 104px !important
  }

  .divider {
    margin-bottom: 104px !important
  }
}

@media screen and (max-width:1023px) {

  .divider,
  .pusher {
    margin-top: 80px !important
  }

  .divider {
    margin-bottom: 80px !important
  }
}

@media screen and (max-width:700px) {
  .mobile-flex-swap {
    display: flex;
    flex-wrap: wrap
  }

  .mobile-flex-swap>* {
    flex-basis: 100%
  }

  .mobile-flex-swap>:first-child {
    order: 1
  }

  .mobile-flex-swap>:nth-child(2) {
    order: 0
  }
}

a {
  display: inline;
  outline: none;
  position: relative;
  text-decoration: none;
  transition: color .2s ease-out, border .2s ease-out, background-color .2s ease-out
}

a:active,
a:focus,
a:hover {
  outline: none;
  transition: color .2s ease-in, border .2s ease-in, background-color .2s ease-in
}

.uppercase {

  font-style: normal;
  font-weight: 500;
  text-transform: uppercase
}

.underline>span {
  border-bottom: 1px solid rgba(0, 173, 164, .3);
  transition: border-bottom .2s ease-out
}

.underline:hover>span {
  border-bottom: 1px solid transparent !important;
  transition: border-bottom .2s ease-out
}

.underline.reverse>span {
  border-bottom: 1px solid transparent;
  transition: border-bottom .2s ease-out
}

.underline.reverse:hover>span {
  border-bottom: 1px solid rgba(0, 173, 164, .3) !important;
  transition: border-bottom .2s ease-out
}

.dashed>span,
[data-js=tooltip]>span {
  border-bottom: 1px dashed rgba(0, 173, 164, .3);
  transition: border-bottom .2s ease-out
}

.dashed:hover>span,
:hover[data-js=tooltip]>span {
  border-bottom: 1px dashed transparent;
  transition: border-bottom .2s ease-out
}

.link,
.slide-tab__label,
.slider-cost-of__slide__prices>[data-js=tooltip],
.tabs__labels.tabs__labels_buttons .tab__label,
[data-js=tooltip] {
  color: #00ada4
}

.link:hover,
.slide-tab__label:hover,
.tabs__labels.tabs__labels_buttons .tab__label:hover,
:hover[data-js=tooltip] {
  color: #007973
}

.link.underline>span,
.slider-cost-of__slide__prices>.underline[data-js=tooltip]>span,
.tabs__labels.tabs__labels_buttons .underline.tab__label>span,
.underline.slide-tab__label>span,
.underline[data-js=tooltip]>span {
  border-bottom: 1px solid rgba(0, 173, 164, .3)
}

.link.underline.reverse>span,
.slider-cost-of__slide__prices>.underline.reverse[data-js=tooltip]>span,
.tabs__labels.tabs__labels_buttons .underline.reverse.tab__label>span,
.underline.reverse.slide-tab__label>span,
.underline.reverse[data-js=tooltip]>span {
  border-bottom: 1px solid transparent
}

.link_dark {
  color: #5f6877
}

.link_dark:hover {
  color: #333638
}

.link_dark.active:not(a),
.link_dark:active:not(a) {
  color: #d5973f
}

.link_dark.underline>span {
  border-bottom: 1px solid rgba(95, 104, 119, .3)
}

.link_black,
.service-mini__addresses .metro-map__affiliate .link,
.service-mini__addresses .metro-map__affiliate .slide-tab__label,
.service-mini__addresses .metro-map__affiliate .tabs__labels.tabs__labels_buttons .tab__label,
.service-mini__addresses .metro-map__affiliate [data-js=tooltip],
.tabs__labels.tabs__labels_buttons .service-mini__addresses .metro-map__affiliate .tab__label {
  color: #333638
}

.link_black:hover,
.service-mini__addresses .metro-map__affiliate .link:hover,
.service-mini__addresses .metro-map__affiliate .slide-tab__label:hover,
.service-mini__addresses .metro-map__affiliate .tabs__labels.tabs__labels_buttons .tab__label:hover,
.service-mini__addresses .metro-map__affiliate :hover[data-js=tooltip],
.tabs__labels.tabs__labels_buttons .service-mini__addresses .metro-map__affiliate .tab__label:hover {
  color: #5f6877
}

.link_black.active:not(a),
.link_black:active:not(a),
.service-mini__addresses .metro-map__affiliate .active.link:not(a),
.service-mini__addresses .metro-map__affiliate .active.slide-tab__label:not(a),
.service-mini__addresses .metro-map__affiliate .active:not(a)[data-js=tooltip],
.service-mini__addresses .metro-map__affiliate .link:active:not(a),
.service-mini__addresses .metro-map__affiliate .slide-tab__label:active:not(a),
.service-mini__addresses .metro-map__affiliate .tabs__labels.tabs__labels_buttons .active.tab__label:not(a),
.service-mini__addresses .metro-map__affiliate .tabs__labels.tabs__labels_buttons .tab__label:active:not(a),
.service-mini__addresses .metro-map__affiliate :active:not(a)[data-js=tooltip],
.tabs__labels.tabs__labels_buttons .service-mini__addresses .metro-map__affiliate .active.tab__label:not(a),
.tabs__labels.tabs__labels_buttons .service-mini__addresses .metro-map__affiliate .tab__label:active:not(a) {
  color: #d5973f
}

.link_black.underline>span,
.service-mini__addresses .metro-map__affiliate .tabs__labels.tabs__labels_buttons .underline.tab__label>span,
.service-mini__addresses .metro-map__affiliate .underline.link>span,
.service-mini__addresses .metro-map__affiliate .underline.slide-tab__label>span,
.service-mini__addresses .metro-map__affiliate .underline[data-js=tooltip]>span,
.tabs__labels.tabs__labels_buttons .service-mini__addresses .metro-map__affiliate .underline.tab__label>span {
  border-bottom: 1px solid rgba(51, 54, 56, .3)
}

.link_gray {
  color: #bdc7ca
}

.link_gray:hover {
  color: #333638
}

.link_gray.active:not(a),
.link_gray:active:not(a) {
  color: #d5973f
}

.link_gray.underline>span {
  border-bottom: 1px solid rgba(189, 199, 202, .3)
}

.link_gray.dashed>span,
.link_gray[data-js=tooltip]>span {
  border-bottom: 1px dashed rgba(189, 199, 202, .3)
}

.link_gray.dashed:hover>span,
.link_gray:hover[data-js=tooltip]>span {
  border-bottom: 1px dashed transparent
}

.link_white,
.link_white:hover {
  color: #fff
}

.link_white:hover.dashed>span,
.link_white:hover[data-js=tooltip]>span {
  border-bottom: 1px dashed transparent
}

.link_white:hover.underline>span {
  border-bottom: 1px solid transparent
}

.link_white.dashed>span,
.link_white[data-js=tooltip]>span {
  border-bottom: 1px dashed #fff
}

.link_white.underline>span {
  border-bottom: 1px solid #fff
}

.link-with-icon {
  display: inline-block;

  font-style: normal;
  font-weight: 500;
  height: 60px;
  letter-spacing: .48px;
  line-height: 22px;
  max-width: 455px;
  padding-left: 88px;
  padding-top: 20px;
  position: relative
}

.link-with-icon:hover .link-with-icon__title {
  color: #d5973f
}

.link-with-icon:active .link-with-icon__title {
  color: #e67800;
  transition: color .2s ease-in
}

.link-with-icon .circle-icon {
  left: 0;
  margin-top: -30px;
  position: absolute;
  top: 50%
}

.link-with-icon .circle-icon_big {
  left: 0;
  margin-top: -41px;
  position: absolute;
  top: 50%
}

.link-with-icon .link-with-icon__title {
  color: #333638;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  transition: color .2s ease-out
}

.link-with-icon .link-with-icon__text {
  color: #5f6877;

  font-style: normal;
  font-weight: 400
}

.link-with-icon_big {
  padding-left: 110px
}

.link-with-icon_white:hover .link-with-icon__title {
  color: #f9f197
}

.link-with-icon_white:active .link-with-icon__title {
  color: #eae289
}

.link-with-icon_white .link-with-icon__title {
  color: #fff
}

.link-with-icon_white .link-with-icon__text {
  color: #a5a5a5
}

.link_bordered,
.slide-tab__label,
.tabs__labels.tabs__labels_buttons .tab__label {
  background-color: #fff;
  border: 1px solid #e0e3e4;
  border-radius: 18px;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  padding-left: 18px;
  padding-right: 18px
}

.link_bordered:focus,
.link_bordered:hover,
.slide-tab__label:focus,
.slide-tab__label:hover,
.tabs__labels.tabs__labels_buttons .tab__label:focus,
.tabs__labels.tabs__labels_buttons .tab__label:hover {
  border: 1px solid #00beb4;
  color: #333638
}

.active.slide-tab__label:not(a),
.link_bordered.active:not(a),
.link_bordered:active:not(a),
.slide-tab__label:active:not(a),
.tabs__labels.tabs__labels_buttons .active.tab__label:not(a),
.tabs__labels.tabs__labels_buttons .tab__label:active:not(a) {
  background-color: #eef2f4;
  border: 1px solid #eef2f4;
  color: #5f6877
}

.link_small {
  font-size: 14px
}

@media screen and (max-width:767px) {
  .link-with-icon .circle-icon_big {
    margin-top: -31px
  }

  .link-with-icon__text {
    font-size: 14px
  }
}

.button,
.slide-tab__label.active,
.tabs__labels.tabs__labels_buttons .tab__label.active {
  -webkit-appearance: none;
  background-color: #00beb4;
  border-radius: 20px;
  box-shadow: 0 0 0 0 transparent;
  color: #fff;
  cursor: pointer;
  display: inline-block;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: 40px;
  letter-spacing: .42px;
  line-height: 42px;
  padding-left: 22px;
  padding-right: 22px;
  text-transform: uppercase;
  transition: background-color .15s ease-out, background-image .15s ease-out, box-shadow .15s ease-out
}

.button span,
.slide-tab__label.active span,
.tabs__labels.tabs__labels_buttons .tab__label.active span {
  pointer-events: none;
  position: relative;
  z-index: 1
}

.button:hover,
.slide-tab__label.active:hover,
.tabs__labels.tabs__labels_buttons .tab__label.active:hover {
  background-color: #00b1a8;
  box-shadow: 0 10px 36px 4px rgba(70, 81, 86, .17);
  transition: background-color .15s ease-in, background-image .15s ease-in, box-shadow .15s ease-in
}

.button:active,
.slide-tab__label.active:active,
.tabs__labels.tabs__labels_buttons .tab__label.active:active {
  background-color: #00b1a8;
  box-shadow: 0 5px 18px 2px rgba(70, 81, 86, .2);
  transition: background-color .15s ease-in, background-image .15s ease-in, box-shadow .15s ease-in
}

.button[disabled],
.slide-tab__label.active[disabled],
.tabs__labels.tabs__labels_buttons .tab__label.active[disabled] {
  cursor: default;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: .5;
  pointer-events: none
}

.button-icon {
  padding-left: 56px;
  position: relative
}

.button-icon:before {
  background-size: cover;
  content: "";
  display: block;
  height: 16px;
  left: 28px;
  margin-top: -8px;
  position: absolute;
  top: 50%;
  width: 16px;
  z-index: 1
}

.button-icon_phone:before {
  background-image: url(/build/i/7e672100e6f392a8712d.svg)
}

.button_small {
  border-radius: 13px;
  height: 26px;
  line-height: 28px
}

.button_tall {
  border-radius: 24px;
  height: 48px;
  line-height: 50px
}

.button_big {
  border-radius: 30px;
  height: 60px;
  line-height: 62px
}

.button_orange {
  background-color: #d46f00;
  background-image: linear-gradient(90deg, #d46f00, #ffba00);
  box-shadow: 0 10px 36px 4px rgba(70, 81, 86, .17);
  overflow: hidden;
  position: relative
}

.button_orange[data-js-active-button]:after,
.button_orange[data-js=active-button]:after {
  --size: 0;
  background: radial-gradient(circle 79px at center, #d46f00 0, #ffba00 56%, rgba(255, 186, 0, .9) 100%);
  border-radius: 50%;
  content: "";
  height: var(--size);
  left: var(--x);
  position: absolute;
  top: var(--y);
  transform: translate(-50%, -50%);
  transition: width .2s ease-out, height .2s ease-out;
  width: var(--size);
  z-index: 0
}

.button_orange[data-js-active-button]:hover,
.button_orange[data-js=active-button]:hover {
  background-color: #d46f00;
  background-image: radial-gradient(circle 79px at center, #d46f00 0, #ffba00 56%, #ffba00 100%)
}

.button_orange[data-js-active-button]:hover:after,
.button_orange[data-js=active-button]:hover:after {
  --size: 400px
}

.button_orange[data-js-active-button]:active,
.button_orange[data-js=active-button]:active {
  background-color: #d46f00;
  background-image: none
}

.button_orange[data-js-active-button]:active:after,
.button_orange[data-js=active-button]:active:after {
  background-image: none
}

.button_orange:active,
.button_orange:hover {
  background-color: #d46f00
}

.button_orange:active {
  background-image: none;
  box-shadow: 0 5px 18px 2px rgba(70, 81, 86, .2)
}

.button_white-bordered {
  background-color: #fff;
  border: 2px solid #00beb4;
  color: #00beb4;
  line-height: 40px;
  overflow: hidden;
  position: relative;
  transition: background-color .2s ease-in, border .2s ease-in
}

.button_white-bordered.button_small {
  line-height: 26px
}

.button_white-bordered.button_tall {
  line-height: 46px
}

.button_white-bordered.button_big {
  line-height: 60px
}

.button_white-bordered:hover {
  background-color: #fff;
  color: #5f6877;
  transition: background-color .2s ease-out, border .2s ease-out
}

.button_white-bordered:hover.button_arrow svg path {
  fill: #5f6877
}

.button_white-bordered:active {
  background-color: #eef2f4;
  border: 2px solid #eef2f4;
  color: #3c4143;
  transition: background-color .2s ease-out, border .2s ease-out
}

.button_white-bordered.button_arrow svg path {
  fill: #00beb4
}

.button_arrow>span {
  display: inline-block;
  padding-right: 20px;
  position: relative
}

.button_arrow svg {
  margin-top: -5px;
  position: absolute;
  right: 0;
  top: 50%;
  transition: trsnform .1s ease
}

.button_arrow.active svg {
  transform: rotate(-180deg)
}

.button_white {
  background-color: #fff;
  color: #3c4143
}

.button_white:active,
.button_white:hover {
  background-color: #eef1f4
}

.button_link {
  color: #00ada4;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  padding-left: 12px;
  padding-right: 12px;
  text-transform: none
}

.button_link:active,
.button_link:hover {
  background-color: #fff;
  box-shadow: none;
  color: #007973
}

.button_transparent {
  background-color: transparent;
  border: 2px solid #fff;
  box-shadow: 0 10px 36px 4px rgba(70, 81, 86, .17);
  color: #fff;
  overflow: hidden;
  position: relative
}

.button_transparent.button_tall {
  line-height: 48px
}

.button_transparent:hover {
  background-color: #fff;
  color: #5f6877
}

.button_transparent:active {
  background-color: #fff;
  box-shadow: 0 5px 18px 2px rgba(70, 81, 86, .2);
  color: #3c4143
}

.button_with-plus {
  border-radius: 10px;
  display: block;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: 50px;
  line-height: 27px;
  padding-left: 60px;
  padding-right: 30px;
  position: relative;
  text-transform: uppercase
}

.button_with-plus__icon-plus {
  background-image: url(/build/i/9f951c14ea19f8e14255.svg);
  display: block;
  height: 16px;
  left: 30px;
  margin-top: -8px;
  position: absolute;
  top: 50%;
  width: 16px
}

@media screen and (max-width:767px) {

  .button,
  .slide-tab__label.active,
  .tabs__labels.tabs__labels_buttons .tab__label.active {
    font-size: 12px
  }

  .button_with-plus {
    line-height: 16px
  }
}

h1,
h2,
h3 {
  margin: 0
}

.h1,
.h1_supplement {
  color: #333638;
  display: inline;

  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 51px
}

.h1>span:not(.h1_supplement__bold),
.h1_supplement>span:not(.h1_supplement__bold) {

  font-style: normal;
  font-weight: 400
}

.h1_main {
  font-size: 42px;
  line-height: 46px
}

.h1_main>span {
  display: block;
  font-size: 26px;
  line-height: 36px;
  margin-top: 10px
}

.h1_supplement {
  font-weight: 400
}

.h1_supplement,
.h1_supplement__bold {

  font-style: normal
}

.h1_supplement__bold {
  font-weight: 700
}

.h1_small {
  font-size: 36px;
  line-height: 48px
}

.h2,
.payment-page h2 {
  color: #333638;
  font-size: 42px;
  font-weight: 400;
  line-height: 50px;
  text-align: center
}

.h2,
.h2_main,
.payment-page h2 {

  font-style: normal
}

.h2_main {
  font-size: 46px;
  font-weight: 700;
  text-align: left
}

.h2_main>span {
  display: block;

  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 46px
}

.h2_left {
  text-align: left
}

.h2_small {
  font-size: 42px
}

.h2_white {
  color: #fff
}

.h2_secondary {

  font-size: 36px;
  font-style: normal;
  font-weight: 500
}

.h2-sub {
  color: #5f6877;
  font-size: 16px;
  line-height: 30px;
  margin-top: 32px;
  max-width: 1024px;
  text-align: center
}

.h2-sub_white {
  color: #fff
}

.h2-sub_secondary {
  margin-top: 15px
}

@media screen and (max-width:1359px) {

  .h1,
  .h1_supplement {
    font-size: 32px;
    line-height: 38px
  }

  .h1_main {
    font-size: 42px;
    line-height: 46px
  }
}

@media screen and (max-width:1024px) {

  .h2,
  .payment-page h2 {
    font-size: 36px;
    line-height: 44px
  }

  .h2_secondary {
    font-size: 30px
  }

  .h2_main {
    font-size: 42px
  }
}

@media screen and (max-width:1023px) {

  .h1,
  .h1_supplement {
    font-size: 30px;
    line-height: 36px
  }

  .h1_main {
    font-size: 42px;
    line-height: 46px
  }

  .h2-sub br {
    display: none
  }
}

@media screen and (max-width:767px) {
  .main-services__header>* {
    flex-basis: 100%
  }

  .h1,
  .h1_supplement {
    font-size: 26px;
    line-height: 32px
  }

  .h2,
  .payment-page h2 {
    font-size: 26px !important;
    line-height: normal
  }

  .h2_secondary {
    font-size: 26px !important;
    line-height: 32px
  }

  .h1_main {
    font-size: 30px;
    line-height: 30px;
    text-align: center
  }

  .h1_main>span {
    font-size: 17px;
    line-height: 23.45px;
    padding-left: 15px;
    padding-right: 15px
  }

  .h2_main {
    font-size: 30px;
    line-height: 30px;
    text-align: center
  }

  .h2_main>span {
    font-size: 26px
  }
}

@media screen and (max-width:414px) {

  .h1>br,
  .h1_supplement>br,
  .h2>br,
  .payment-page h2>br {
    display: none !important
  }
}

.input__wrapper {
  display: inline-block;
  font-size: 16px;
  margin: 0;
  max-width: 100%;
  padding: 20px 0;
  position: relative;
  width: 100%
}

.input__wrapper.focused .input {
  outline: none
}

.input__wrapper.focused .input__label {
  font-size: 12px;
  top: 0;
  visibility: visible
}

.input__wrapper_file {
  height: 48px;
  margin-bottom: 30px;
  padding: 0;
  position: relative
}

.input__wrapper_file .form__error {
  left: 62px
}

.input {
  background-color: #eff4f5;
  border: none;
  border-bottom: 1px solid #eff4f5;
  border-radius: 24px;
  color: #b0b6c0;
  display: block;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  height: 49px;
  line-height: 18px;
  margin: 0;
  padding: 10px 24px;
  text-align: left;
  width: 100%
}

.input:-webkit-autofill {
  -webkit-text-fill-color: #b5b7c0 !important;
  -webkit-box-shadow: inset 0 0 0 1000px #eff4f6
}

.input:focus {
  outline: none
}

textarea.input {
  padding-top: 15px;
  resize: none
}

.input_file {
  cursor: pointer;
  height: 48px;
  opacity: 0;
  width: 227px
}

.input__label,
.input_file {
  overflow: hidden;
  position: absolute
}

.input__label {
  bottom: 0;
  color: #b0b6c0;
  display: block;
  font-size: 16px;
  left: 0;
  line-height: 21px;
  padding-left: 24px;
  padding-right: 24px;
  pointer-events: none;
  right: 0;
  text-align: left;
  top: 33px;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  width: 100%
}

.input__label_file {
  cursor: pointer;
  font-size: 12px;
  height: 48px;
  left: 0;
  line-height: 18px;
  max-width: 227px;
  padding-left: 62px;
  position: absolute;
  top: 0
}

.input__label_file:before {
  background-color: #acdcf7;
  background-image: url(/build/i/2ea274946ea156d5e3e1.svg);
  background-position: 50%;
  background-size: 30px 30px;
  border-radius: 50%;
  content: "";
  display: block;
  height: 48px;
  left: 0;
  position: absolute;
  top: 0;
  width: 48px
}

@media screen and (max-width:767px) {
  .input__wrapper.focused .input__label {
    text-overflow: ellipsis;
    white-space: nowrap
  }
}

.checkbox {
  height: 17px;
  margin: 0 3px 0 0;
  vertical-align: top;
  width: 17px
}

.checkbox:not(checked) {
  opacity: 0;
  position: absolute
}

.checkbox:not(checked)+label {
  display: inline-block;
  line-height: 18px;
  margin-bottom: 12px;
  padding: 0 0 0 26px;
  position: relative
}

.checkbox:not(checked)+label:before {
  background-color: #eff4f5;
  border-radius: 6px;
  content: "";
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px
}

.checkbox:not(checked)+label:after {
  color: #333638;
  content: "âœ”";
  font-size: 22px;
  font-style: normal;
  left: 3px;
  opacity: 0;
  position: absolute;
  top: -2px;
  transition: all .2s ease-in
}

.checkbox:checked+label:before {
  background-color: transparent;
  border: 2px solid #00beb4;
  border-radius: 6px;
  height: 18px;
  opacity: 1;
  width: 18px
}

.checkbox:checked+label:after {
  opacity: 1
}

.radio {
  height: 20px;
  margin: 0 3px 0 0;
  vertical-align: top;
  width: 20px
}

.radio+label {
  cursor: pointer
}

.radio:not(checked) {
  opacity: 0;
  position: absolute
}

.radio:not(checked)+label {
  display: inline-block;
  margin-bottom: 20px;
  padding: 0 0 0 20px;
  position: relative
}

.radio:not(checked)+label:before {
  background: transparent;
  border: 1px solid #e0e3e4;
  border-radius: 50%;
  content: "";
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  transition: border .1s ease-out;
  width: 20px
}

.radio:not(checked)+label:after {
  background-color: #05c2bb;
  border-radius: 50%;
  content: "";
  height: 8px;
  left: 6px;
  opacity: 0;
  position: absolute;
  top: 6px;
  transition: opacity .1s ease-out;
  width: 8px
}

.radio:checked+label:after {
  opacity: 1;
  transition: opacity .1s ease-in
}

.radio:checked+label:before {
  border: 1px solid #05c2bb;
  transition: border .1s ease-in
}

.footer-sicon {
  border-radius: 50%;
  display: block;
  height: 46px;
  transition: all .2s ease-out;
  width: 46px
}

.footer-sicon:hover {
  transition: all .2s ease-in
}

.footer-sicon:before {
  background-size: cover;
  content: "";
  display: block;
  height: 100%;
  width: 100%
}

.footer-sicon_small {
  height: 30px;
  width: 30px
}

.footer-sicon_fb {
  background-color: #396ca6
}

.footer-sicon_fb:hover {
  background-color: #28568a
}

.footer-sicon_fb:before {
  background-image: url(/build/i/4d12b899f779c25e4643.svg)
}

.footer-sicon_ig {
  background-color: #b64fa4
}

.footer-sicon_ig:hover {
  background-color: #893c7c
}

.footer-sicon_ig:before {
  background-image: url(/build/i/3eb928aa70769053ef05.svg)
}

.footer-sicon_vk {
  background-color: #207ec6
}

.footer-sicon_vk:hover {
  background-color: #1a649d
}

.footer-sicon_vk:before {
  background-image: url(/build/i/0faba4986fde5d764dc2.svg)
}

.footer-sicon_ok {
  background-color: #e18c13
}

.footer-sicon_ok:hover {
  background-color: #bf7812
}

.footer-sicon_ok:before {
  background-image: url(/build/i/8e0912f1901a55248923.svg)
}

.footer-sicon_yt {
  background-color: #ff002a
}

.footer-sicon_yt:hover {
  background-color: #940119
}

.footer-sicon_yt:before {
  background-image: url(/build/i/27abef58f63fb36eb535.svg)
}

.footer-sicon_gl {
  background-color: #eb3956
}

.footer-sicon_gl:hover {
  background-color: #b62b42
}

.footer-sicon_gl:before {
  background-image: url(/build/i/6f581b603e01865ca84e.svg)
}

.header__slinks .footer-sicon_fb {
  background-color: #7698bf
}

.header__slinks .footer-sicon_fb:hover {
  background-color: #396ca6
}

.header__slinks .footer-sicon_ig {
  background-color: #ca84bd
}

.header__slinks .footer-sicon_ig:hover {
  background-color: #b64fa4
}

.header__slinks .footer-sicon_vk {
  background-color: #64a3d4
}

.header__slinks .footer-sicon_vk:hover {
  background-color: #207ec6
}

.header__slinks .footer-sicon_ok {
  background-color: #e6ac5c
}

.header__slinks .footer-sicon_ok:hover {
  background-color: #e18c13
}

.circle-icon {
  border-radius: 50%;
  height: 60px;
  width: 60px
}

.circle-icon:active {
  background-image: linear-gradient(90deg, transparent, transparent);
  transition: background-image .2s ease-in
}

.circle-icon:before {
  background-size: 20px 20px;
  content: "";
  display: block;
  height: 20px;
  margin-left: -1px;
  position: relative;
  width: 20px;
  z-index: 1
}

.circle-icon_big {
  height: 82px;
  width: 82px
}

.circle-icon_big:before {
  background-size: 28px 28px;
  height: 28px;
  margin-left: 5px;
  width: 28px
}

.circle-icon_phone:before {
  background-image: url(/build/i/7e672100e6f392a8712d.svg)
}

.circle-icon_play:before {
  background-image: url('../img/demo/icon-play-white.svg')
}

@media screen and (max-width:1023px) {
  .circle-icon:before {
    margin-left: -1px
  }

  .circle-icon_big:before {
    margin-left: 5px
  }
}

@media screen and (max-width:767px) {
  .circle-icon_big {
    height: 62px;
    width: 62px
  }

  .circle-icon_big:before {
    margin-left: -4px
  }
}

.price-table__wrapper .what-is-included-points {
  margin-bottom: 52px
}

.price-table__wrapper .what-is-included-points .what-is-included-points__blocks {
  margin-top: 0
}

.price-table {
  border-top: 1px solid #d8dfe1;
  display: table;
  margin-top: 3px;
  max-width: 1224px;
  width: 100%
}

.price-table__links {
  margin-top: 62px
}

.price-table__links .button,
.price-table__links .slide-tab__label.active,
.price-table__links .tabs__labels.tabs__labels_buttons .tab__label.active,
.tabs__labels.tabs__labels_buttons .price-table__links .tab__label.active {
  margin-right: 0
}

.price-table__links .link,
.price-table__links .slide-tab__label,
.price-table__links .tabs__labels.tabs__labels_buttons .tab__label,
.price-table__links [data-js=tooltip],
.tabs__labels.tabs__labels_buttons .price-table__links .tab__label {

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-left: 60px;
  text-transform: uppercase
}

.price-table__title_margin {
  margin-bottom: 40px
}

.price-table__sub-title {
  margin-bottom: 45px
}

.price-table__row-group {
  display: table-row-group;
  width: 100%
}

.price-table__row {
  display: table-row;
  width: 100%
}

.price-table__col,
.seo-table_price .seo-table__col {
  border-bottom: 1px solid #d8dfe1;
  display: table-cell;
  line-height: 24px;
  padding-bottom: 24px;
  padding-top: 24px;
  vertical-align: middle
}

.price-table__col:first-child,
.seo-table_price .seo-table__col:first-child {
  max-width: 596px;
  min-width: 535px;
  padding-left: 24px;
  padding-right: 124px
}

.price-table__col:last-child,
.seo-table_price .seo-table__col:last-child {
  padding-right: 24px;
  text-align: right
}

.price-table__col>a,
.seo-table_price .seo-table__col>a {
  display: inline-block
}

.price-table__col .work-info__price__tooltip,
.seo-table_price .seo-table__col .work-info__price__tooltip {
  height: 18px;
  position: relative;
  top: 3px;
  width: 16px
}

.pusher__price-table__offer {
  margin-top: 49px !important
}

.price-table__col__rate {
  float: left;
  position: relative;
  top: 4px
}

.price-table__col__price {
  font-size: 20px;
  line-height: 28px;
  position: relative;
  top: 1px
}

.price-table__col__price>.old {
  font-size: 16px;
  line-height: 26px;
  margin-right: 28px;
  position: relative;
  top: -1px
}

.price-table__col__price>.old:before {
  background-color: #df8200;
  content: "";
  display: block;
  height: 2px;
  left: 0;
  position: absolute;
  top: 8px;
  width: 100%
}

.price-table__col__price [data-js=tooltip] {
  display: inline-block
}

.price-table__col__offer {
  background-color: #fdfa9b;
  border-radius: 13px;
  color: #a5a5a5;
  font-size: 14px;
  height: 26px;
  line-height: 20px;
  margin-left: 30px;
  padding: 5px 15px;
  position: relative;
  top: -2px
}

.price-table__offer {
  margin-bottom: 20px;
  margin-top: 40px
}

@media screen and (max-width:1359px) {
  .price-table__col__rate {
    display: none
  }

  .price-table__col:first-child,
  .seo-table_price .seo-table__col:first-child {
    max-width: 560px;
    padding-right: 64px
  }
}

@media screen and (max-width:1023px) {
  .price-table__col__offer {
    display: inline-block;
    margin-top: 17px;
    top: 0
  }
}

@media screen and (max-width:767px) {
  .price-table {
    text-align: center
  }

  .price-table,
  .price-table__row,
  .price-table__row-group {
    display: block
  }

  .price-table__col,
  .seo-table_price .seo-table__col {
    display: block;
    padding-bottom: 34px;
    padding-top: 34px
  }

  .price-table__col:not(:last-child),
  .seo-table_price .seo-table__col:not(:last-child) {
    border-bottom: 1px solid transparent
  }

  .price-table__col:first-child,
  .seo-table_price .seo-table__col:first-child {
    min-width: 100%;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%
  }

  .price-table__col:last-child,
  .seo-table_price .seo-table__col:last-child {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    text-align: center
  }

  .price-table__col__offer {
    margin-left: 0
  }

  .price-table__col__price {
    display: block
  }

  .price-table__col__price>.old {
    margin-right: 18px
  }

  .price-table__links {
    flex-wrap: wrap;
    margin-top: 50px
  }

  .price-table__links .button,
  .price-table__links .slide-tab__label.active,
  .price-table__links .tabs__labels.tabs__labels_buttons .tab__label.active,
  .tabs__labels.tabs__labels_buttons .price-table__links .tab__label.active {
    margin-right: 0
  }

  .price-table__offer {
    margin-top: 40px
  }

  .pusher__price-table__offer {
    margin-top: 34px !important
  }

  .prices-wrapper .divider__page_prices {
    background-color: transparent;
    margin-bottom: 34px !important;
    margin-top: 34px !important
  }

  .price-table__title_margin {
    margin-bottom: 20px
  }
}

@media screen and (max-width:414px) {

  .price-table__links .button,
  .price-table__links .slide-tab__label.active,
  .price-table__links .tabs__labels.tabs__labels_buttons .tab__label.active,
  .tabs__labels.tabs__labels_buttons .price-table__links .tab__label.active {
    margin-bottom: 30px
  }

  .price-table__links .link,
  .price-table__links .slide-tab__label,
  .price-table__links .tabs__labels.tabs__labels_buttons .tab__label,
  .price-table__links [data-js=tooltip],
  .tabs__labels.tabs__labels_buttons .price-table__links .tab__label {
    margin-left: 0;
    text-align: center;
    width: 100%
  }
}

.selectric-wrapper {
  cursor: pointer;
  position: relative
}

.selectric-responsive {
  width: 100%
}

.selectric {
  background: #fff;
  border: 1px solid #00beb4;
  border-radius: 22px;
  position: relative
}

.selectric .label {
  color: #5f6877;
  display: block;
  font-size: 14px;
  height: 42px;
  line-height: 44px;
  margin: 0 42px 0 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.selectric .button,
.selectric .slide-tab__label.active,
.selectric .tabs__labels.tabs__labels_buttons .tab__label.active,
.tabs__labels.tabs__labels_buttons .selectric .tab__label.active {
  background: transparent;
  border-radius: 0;
  bottom: 0;
  box-shadow: none;
  color: #5f6877;
  display: block;
  font: 0/0 a;
  *font: 43px/43px Lucida Sans Unicode, Arial Unicode MS, Arial;
  height: 43px;
  margin: auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 43px
}

.selectric .button:after,
.selectric .slide-tab__label.active:after,
.selectric .tabs__labels.tabs__labels_buttons .tab__label.active:after,
.tabs__labels.tabs__labels_buttons .selectric .tab__label.active:after {
  border: 4px solid transparent;
  border-bottom: none;
  border-top-color: #5f6877;
  bottom: 0;
  content: " ";
  height: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 0
}

.selectric-focus .selectric {
  border-color: #aaa
}

.selectric-hover .selectric {
  border-color: #00a59b
}

.selectric-hover .selectric .button,
.selectric-hover .selectric .slide-tab__label.active,
.selectric-hover .selectric .tabs__labels.tabs__labels_buttons .tab__label.active,
.tabs__labels.tabs__labels_buttons .selectric-hover .selectric .tab__label.active {
  color: #00a59b
}

.selectric-hover .selectric .button:after,
.selectric-hover .selectric .slide-tab__label.active:after,
.selectric-hover .selectric .tabs__labels.tabs__labels_buttons .tab__label.active:after,
.tabs__labels.tabs__labels_buttons .selectric-hover .selectric .tab__label.active:after {
  border-top-color: #00a59b
}

.selectric-open {
  z-index: 9999
}

.selectric-open .selectric {
  background: #fff;
  border-bottom: 1px solid;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-color: #00a59b
}

.selectric-open .selectric-items {
  display: block
}

.selectric-disabled {
  cursor: default;
  filter: alpha(opacity=50);
  opacity: .5;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.selectric-hide-select {
  height: 0;
  overflow: hidden;
  position: relative;
  width: 0
}

.selectric-hide-select select {
  left: -100%;
  position: absolute
}

.selectric-hide-select.selectric-is-native {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 10
}

.selectric-hide-select.selectric-is-native select {
  border: none;
  box-sizing: border-box;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1
}

.selectric-input {
  clip: rect(0, 0, 0, 0) !important;
  background: none !important;
  border: none !important;
  *font: 0/0 a !important;
  height: 1px !important;
  left: 0 !important;
  margin: 0 !important;
  outline: none !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 1px !important
}

.selectric-temp-show {
  display: block !important;
  position: absolute !important;
  visibility: hidden !important
}

.selectric-items {
  background: #fff;
  border-bottom: 1px solid #00a59b;
  border-left: 1px solid #00a59b;
  border-right: 1px solid #00a59b;
  box-shadow: 0 0 10px -6px;
  display: none;
  left: 0;
  position: absolute;
  top: 100%;
  z-index: -1
}

.selectric-items .selectric-scroll {
  height: 100%;
  overflow: auto
}

.selectric-above .selectric-items {
  bottom: 100%;
  top: auto
}

.selectric-items li,
.selectric-items ul {
  font-size: 12px;
  line-height: 20px;
  list-style: none;
  margin: 0;
  min-height: 20px;
  padding: 0
}

.selectric-items li {
  border-bottom: 1px solid #d9e1e1;
  color: #5f6877;
  cursor: pointer;
  display: block;
  padding: 8px
}

.selectric-items li.selected {
  background: #edeff3;
  color: #444
}

.selectric-items li.highlighted,
.selectric-items li:hover {
  background: #bbf1ec;
  color: #444
}

.selectric-items .disabled {
  display: none
}

.selectric-items .selectric-group .selectric-group-label {
  background: none;
  color: #444;
  cursor: default;
  font-weight: 700;
  padding-left: 10px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.selectric-items .selectric-group.disabled li {
  filter: alpha(opacity=100);
  opacity: 1
}

.selectric-items .selectric-group li {
  padding-left: 25px
}

@media screen and (max-width:1130px) {
  .selectric .label {
    font-size: 13px
  }
}

.checkbox {
  display: none
}

.checkbox+label {
  cursor: pointer;
  position: relative
}

.checkbox+label i {
  background-color: #fff;
  border-radius: 5px;
  display: inline-block;
  font-size: 24pt;
  height: 20px;
  left: 0;
  line-height: .25em;
  position: absolute;
  text-align: center;
  top: 6px;
  vertical-align: middle;
  width: 20px
}

.checkbox+label>span {
  float: left;
  margin-left: 32px
}

.checkbox:checked+label i:after {
  color: #333638;
  content: "âœ”";
  font-size: 22px;
  font-style: normal;
  left: 2px;
  position: relative;
  top: 1px
}

span.checkbox+label {
  cursor: default;
  position: relative
}

span.checkbox+label i {
  background-color: #fff;
  border-radius: 5px;
  display: inline-block;
  font-size: 24pt;
  height: 20px;
  left: 0;
  line-height: .25em;
  position: absolute;
  text-align: center;
  top: 6px;
  vertical-align: middle;
  width: 20px
}

span.checkbox+label i:after {
  color: #333638;
  content: "âœ”";
  font-size: 22px;
  font-style: normal;
  left: 2px;
  position: relative;
  top: 1px
}

span.checkbox+label>span {
  float: left;
  margin-left: 32px
}

.ct-button-wrapper {
  zoom: 1;
  background: none;
  border: none;
  border-radius: 0;
  bottom: 0;
  box-shadow: none;
  display: none;
  -webkit-filter: none;
  filter: none;
  height: 108px;
  left: auto;
  margin: 0;
  outline: none;
  padding: 0;
  position: fixed;
  right: calc(50% - 54px);
  top: auto;
  transform: none;
  width: 108px;
  z-index: 1000000001
}

.ct-button {
  zoom: 1;
  display: block;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%
}

.ct-button,
.ct-button button {
  font-family: Arial, Helvetica, sans-serif !important
}

.ct-button button {
  animation: ct-button 1s ease 0s infinite alternate none running;
  background-image: linear-gradient(208deg, #ff6f03, #ffad00);
  border: none;
  border-color: initial;
  -o-border-image: none;
  border-image: none;
  -o-border-image: initial;
  border-image: initial;
  border-radius: 90px;
  bottom: 24px;
  cursor: pointer;
  -webkit-filter: none;
  filter: none;
  height: 64px;
  left: calc(50% - 32px);
  outline: none;
  position: absolute;
  right: auto;
  top: auto;
  transform: none;
  width: 64px
}

.ct-button button span {
  display: block;
  height: 24px;
  margin: auto;
  width: 24px
}

.ct-button button span svg {
  fill: #fff;
  display: block;
  height: 24px;
  width: 24px
}

@keyframes ct-button {
  0% {
    transform: scale(.68) translateY(25px)
  }

  to {
    transform: scale(1) translate(0)
  }
}

@media screen and (max-width:767px) {
  .ct-button-wrapper {
    display: block
  }

  #CalltouchWidgetFrame:not(.opened) {
    display: none !important;
    left: -500px !important;
    opacity: 0 !important;
    visibility: hidden !important
  }
}

.row,
.row_short {
  height: 100%;
  max-width: 1364px
}

.row_short {
  line-height: 30px;
  margin-top: 30px;
  max-width: 800px
}

.row_short p {
  margin-bottom: 20px
}

.payment-page .row_short h2,
.row_short .h2,
.row_short .payment-page h2 {
  margin-bottom: 20px;
  margin-top: 40px
}

.payment-page .row_short h2 br,
.row_short .h2 br,
.row_short .payment-page h2 br {
  display: none
}

.page_main_page .video-block .h2-sub {
  font-size: 30px;
  line-height: 48px
}

.page_employee>.about-employee-simple-wrapper,
.page_employee>.about-employee-wrapper {
  margin-top: 130px
}

.page_employee>.info-block-wrapper {
  margin-bottom: 130px;
  margin-top: 70px
}

.page_employee>.employee__portfolio-slider {
  margin-top: 140px
}

.page_employee>.employee-specialization {
  margin-top: 130px
}

.page_work>.half-block {
  margin-top: 128px
}

.page_work>.steps {
  margin-top: 134px
}

.page_work>.video-block {
  margin-bottom: 155px;
  margin-top: 155px
}

.page_work>.slider-wrapper-employee {
  margin-bottom: 235px
}

.page_counseling .full-width-text .row,
.page_counseling .full-width-text .row_short {
  padding: 0
}

.page_counseling .full-width-text .minus-list,
.page_counseling .full-width-text .plus-list {
  padding-right: 75px
}

.page_counseling .full-width-text .minus-list>li .text,
.page_counseling .full-width-text .plus-list>li .text {
  max-width: none
}

@media screen and (max-width:1359px) {
  .page_work>.video-block {
    margin-bottom: 125px;
    margin-top: 115px
  }

  .page_awards .awards-list {
    padding-bottom: 0
  }

  .page_54 .divider,
  .page_54 .pusher {
    margin-top: 80px !important
  }

  .page_54 .divider {
    margin-bottom: 80px !important
  }

  .page_54 .mobile-flex-swap+.pusher {
    display: none
  }

  .page_adv_zygoma .awards-list_border-top {
    padding-bottom: 10px
  }

  .page_adv_zygoma .anchor-links__list>li {
    margin-right: 45px
  }

  .page_adv_zygoma .anchor-links__hide-mobile {
    display: none
  }

  .page_adv_zygoma .two-visits+.pusher {
    margin-top: 0 !important
  }

  .page_adv_service_allon4_new .pusher {
    margin-top: 70px !important
  }

  .page_adv_service_allon4_new .divider {
    margin-bottom: 70px !important;
    margin-top: 70px !important
  }
}

@media screen and (max-width:1023px) {
  .page_employee>.about-employee-wrapper {
    margin-top: 70px
  }

  .page_employee>.employee-specialization {
    margin-top: 80px
  }

  .page_main_page .video-block .h2-sub {
    font-size: 24px;
    line-height: 40px
  }

  .page_article>.about-employee-wrapper .minus-list,
  .page_article>.about-employee-wrapper .plus-list {
    margin-bottom: 30px
  }

  .page_54 .divider,
  .page_54 .pusher {
    margin-top: 60px !important
  }

  .page_54 .divider {
    margin-bottom: 60px !important
  }

  .page_adv_zygoma .anchor-links__text {
    display: none
  }

  .page_adv_zygoma .anchor-links__list>li {
    margin-right: 0
  }

  .page_adv_service_allon4_new .pusher {
    margin-top: 50px !important
  }

  .page_adv_service_allon4_new .divider {
    margin-bottom: 50px !important;
    margin-top: 50px !important
  }
}

@media screen and (max-width:767px) {
  .page_employee>.about-employee-wrapper {
    margin-top: 39px
  }

  .page_employee>.info-block-wrapper {
    margin-bottom: 95px
  }

  .page_employee>.slider-wrapper-video {
    margin-top: 0
  }

  .page_main_page .video-block .h2-sub {
    font-size: 16px;
    line-height: 30px
  }

  .page_work>.half-block {
    margin-top: 85px
  }

  .page_article>.about-employee-wrapper {
    margin-bottom: 80px
  }

  .page_article>.about-employee-wrapper .minus-list,
  .page_article>.about-employee-wrapper .plus-list {
    margin-bottom: 30px
  }

  .page_article .about-employee>:last-child {
    margin-bottom: 40px
  }

  .page_prices .offers-slider-wrapper+.divider {
    display: none
  }

  .page_warranty .awards-list {
    background-color: #fff;
    padding-bottom: 0
  }

  .page_awards .awards-list {
    background-color: #fff
  }

  .page_255 .warranty+.pusher,
  .page_service_without_video .independent-ratings+.divider {
    display: none
  }

  .page_54 .divider,
  .page_54 .pusher {
    margin-top: 40px !important
  }

  .page_54 .divider {
    margin-bottom: 40px !important
  }

  .page_54 .mobile-flex-swap+.pusher {
    display: block
  }

  .page_information_page .service-advantages+.pusher {
    margin-top: 20px !important
  }

  .page_counseling .pusher {
    margin-top: 40px !important
  }

  .page_counseling .full-width-text .minus-list,
  .page_counseling .full-width-text .plus-list {
    padding-right: 0
  }

  .page_adv_zygoma .awards-list {
    background-color: #fff;
    border-top: 0
  }

  .page_adv_zygoma .awards-list+.pusher {
    margin-top: 20px !important
  }

  .page_adv_zygoma .anchor-links+.pusher {
    margin-top: 50px !important
  }

  .page_adv_zygoma .anchor-links__list {
    justify-content: center
  }

  .page_adv_zygoma .anchor-links__list>* {
    display: none;
    margin-right: 0
  }

  .page_adv_zygoma .anchor-links__list>:first-child {
    display: block
  }

  .page_adv_zygoma .banner-confidence+.pusher,
  .page_adv_zygoma .why-choose-us_compact+.pusher {
    margin-top: 0 !important
  }

  .page_adv_zygoma .sign-up-now-wrapper+.pusher {
    margin-top: 40px !important
  }

  .page_adv_zygoma .warranty__text__bg {
    padding-top: 5px
  }

  .page_adv_zygoma .dream-treatment_component .h2,
  .page_adv_zygoma .dream-treatment_component .payment-page h2,
  .payment-page .page_adv_zygoma .dream-treatment_component h2 {
    font-size: 26px !important;
    line-height: 30px
  }

  .page_adv_zygoma .two-visits+.pusher {
    margin-top: 40px !important
  }

  .page_adv_zygoma .team__title {
    font-size: 26px !important;
    line-height: 30px
  }

  .page_adv_zygoma .contacts-metro-map-wrapper+.pusher {
    margin-top: 30px !important
  }

  .page_adv_service_allon4_new .pusher {
    margin-top: 35px !important
  }

  .page_adv_service_allon4_new .divider {
    margin-bottom: 35px !important;
    margin-top: 35px !important
  }
}

.portfolio__info-block-divider {
  margin-bottom: 125px;
  margin-top: 132px
}

.portfolio__team-divider {
  margin-top: 215px
}

.contacts__team-divider {
  margin-bottom: 128px;
  margin-top: 106px
}

.thank-for-trust-divider {
  margin-bottom: 130px;
  margin-top: 2px
}

.reviews__slider-star-divider {
  margin-bottom: 133px;
  margin-top: 46px
}

.reviews__independent-ratings-divider {
  margin-bottom: 133px;
  margin-top: 130px
}

.reviews__guest-book-divider {
  margin-bottom: 19px;
  margin-top: 136px
}

.contacts__better-conditions-divider {
  margin-bottom: 119px;
  margin-top: 132px
}

.contacts__contact-us-divider {
  margin-bottom: 30px;
  margin-top: 110px
}

.employees__slider-star-divider {
  margin-bottom: 130px;
  margin-top: 47px
}

.employee__employee-address-divider {
  margin-bottom: 123px;
  margin-top: 130px
}

.employee__certificates_slider-divider {
  margin-bottom: 123px;
  margin-top: 115px
}

.employee__employee-specialization-divider {
  margin-bottom: 123px;
  margin-top: 80px
}

.work__half-block-divider {
  margin-bottom: 38px;
  margin-top: 83px
}

.work-section__imaged-block-divider {
  margin-bottom: 153px;
  margin-top: 154px
}

.work-section__works-list-divider {
  margin-bottom: 127px;
  margin-top: 140px
}

.work-section__team-divider {
  margin-bottom: 152px;
  margin-top: 150px
}

.work-section__half_block-divider {
  margin-bottom: 122px;
  margin-top: 108px
}

.work-section__reviews-list-divider,
.work__reviews-list-divider {
  margin-bottom: 32px;
  margin-top: 155px
}

.service__info-quote-divider {
  margin-bottom: 130px;
  margin-top: 110px
}

.service__imaged-block-divider,
.service_section__imaged-block-divider {
  margin-bottom: 150px;
  margin-top: 110px
}

.service__team-divider {
  margin-bottom: 150px;
  margin-top: 128px
}

.service__stages-divider {
  margin-bottom: 150px;
  margin-top: 115px
}

.service__cost-of-divider {
  margin-bottom: 115px;
  margin-top: 40px
}

.service__reviews-list-divider {
  margin-bottom: 124px;
  margin-top: 124px
}

.service_section__info-block-divider {
  margin-bottom: 130px;
  margin-top: 120px
}

.service_section__warranty-divider {
  margin-bottom: 133px;
  margin-top: 150px
}

@media screen and (max-width:1359px) {
  .portfolio__info-block-divider {
    background-color: transparent;
    margin-bottom: 0;
    margin-top: 99px
  }

  .thank-for-trust-divider {
    margin-bottom: 113px
  }

  .contacts__team-divider {
    margin-bottom: 108px
  }

  .employee__employee-address-divider {
    display: none
  }

  .work-section__imaged-block-divider {
    margin-bottom: 100px;
    margin-top: 100px
  }

  .service__imaged-block-divider,
  .service_section__imaged-block-divider {
    margin-bottom: 100px
  }
}

@media screen and (max-width:1023px) {
  .portfolio__info-block-divider {
    margin-top: 122px
  }

  .reviews__independent-ratings-divider {
    margin-bottom: 100px;
    margin-top: 50px
  }

  .reviews__guest-book-divider {
    margin-top: 119px
  }

  .contacts__contact-us-divider {
    margin-top: 45px
  }

  .employee__certificates_slider-divider {
    margin-bottom: 69px;
    margin-top: 91px
  }

  .service__stages-divider {
    margin-bottom: 0
  }

  .service__info-quote-divider {
    margin-bottom: 72px
  }

  .service__imaged-block-divider,
  .service_section__imaged-block-divider {
    margin-top: 99px
  }
}

@media screen and (max-width:767px) {
  .portfolio__team-divider {
    margin-top: 90px
  }

  .portfolio__info-block-divider {
    margin-top: 116px
  }

  .main-works__section__divider {
    margin-top: 80px
  }

  .thank-for-trust-divider {
    display: none
  }

  .reviews__guest-book-divider {
    margin-top: 126px
  }

  .contacts__better-conditions-divider {
    margin-bottom: 85px;
    margin-top: 75px
  }

  .contacts__team-divider {
    margin-bottom: 63px;
    margin-top: 53px
  }

  .employees__slider-star-divider {
    margin-bottom: 70px
  }

  .employee__certificates_slider-divider {
    margin-top: 74px
  }

  .employee__employee-specialization-divider {
    display: none
  }

  .work-section__imaged-block-divider {
    margin-bottom: 50px;
    margin-top: 50px
  }

  .service__team-divider {
    margin-bottom: 70px;
    margin-top: 70px
  }

  .service__stages-divider {
    display: none
  }

  .service__imaged-block-divider,
  .service_section__imaged-block-divider {
    margin-bottom: 64px;
    margin-top: 60px
  }

  .divider__page_article {
    margin-bottom: 60px !important;
    margin-top: 50px !important
  }
}

.burger {
  cursor: pointer;
  height: 60px;
  position: absolute;
  right: 0;
  width: 60px
}

.burger__icon {
  height: 13px;
  left: 21px;
  position: absolute;
  top: 26px;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  width: 18px;
  z-index: 2
}

.burger__icon.opened i:first-child {
  left: 50%;
  top: 18px;
  width: 0
}

.burger__icon.opened i:nth-child(2) {
  top: 3px;
  transform: rotate(45deg);
  width: 18px
}

.burger__icon.opened i:nth-child(3) {
  top: 3px;
  transform: rotate(-45deg);
  width: 17px
}

.burger__icon.opened i:nth-child(4) {
  left: 50%;
  top: 18px;
  width: 0
}

.burger__icon i {
  background: #00aca3;
  border-radius: 1px;
  display: block;
  height: 2px;
  left: 0;
  opacity: 1;
  position: absolute;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
  width: 100%
}

.burger__icon i:first-child {
  top: 0;
  width: 18px
}

.burger__icon i:nth-child(2),
.burger__icon i:nth-child(3) {
  top: 5px;
  width: 13px
}

.burger__icon i:nth-child(4) {
  top: 10px;
  width: 13px
}

.link-burger {
  height: 12px;
  margin-top: -6px;
  position: absolute;
  top: 50%;
  width: 12px
}

.link-burger i {
  background: #5f6877;
  border-radius: 1px;
  display: block;
  height: 2px;
  left: 0;
  opacity: 1;
  position: absolute;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
  width: 100%
}

.link-burger i:first-child {
  top: 0
}

.link-burger i:nth-child(2) {
  top: 5px
}

.link-burger i:nth-child(3) {
  top: 10px
}

.with-link-burger {
  position: relative
}

.with-link-burger.opened i {
  background: #d5973f
}

.with-link-burger.opened i,
.with-link-burger:hover i {
  background: #00ada4
}

.with-link-burger.opened i:nth-child(2),
.with-link-burger.opened i:nth-child(3),
.with-link-burger:hover i:nth-child(2),
.with-link-burger:hover i:nth-child(3) {
  width: 8px
}

@media screen and (max-width:1023px) {
  .link-burger {
    height: 8px;
    margin-top: -4px;
    position: absolute;
    top: 50%;
    width: 8px
  }

  .link-burger i {
    height: 1px
  }

  .link-burger i:first-child {
    top: 0
  }

  .link-burger i:nth-child(2) {
    top: 3px
  }

  .link-burger i:nth-child(3) {
    top: 6px
  }

  .with-link-burger.opened i:nth-child(2),
  .with-link-burger.opened i:nth-child(3),
  .with-link-burger:hover i:nth-child(2),
  .with-link-burger:hover i:nth-child(3) {
    width: 4px
  }
}

.header-menu__teaser_all-on-4 {
  background-image: url(/build/i/ff2aa716f53f14df0ca0.jpg)
}

.header-menu__teaser_prosthesis {
  background-image: url(/build/i/6d0f2125898cc2df5c23.jpg)
}

.header-menu__teaser_implants {
  background-image: url(/build/i/0dee1b9f0c879ba737ee.jpg)
}

.header-menu__teaser_crowns {
  background-image: url(/build/i/7c2d375636a9dd9d7380.jpg)
}

.header-menu__teaser_vinirs {
  background-image: url(/build/i/6dca1685270d6fa7dea6.jpg)
}

.header-menu__teaser_cleaning {
  background-image: url(/build/i/2e0d2fe64ff526eafbb3.jpg)
}

.header-menu__teaser_express {
  background-image: url(/build/i/ad6e0878ddcd1f36d530.jpg)
}

.header-menu__teaser_without-brackets {
  background-image: url(/build/i/687aa204fd4cb1032ab0.jpg)
}

.header_small {
  transform: translateY(-153px);
  transition: transform .1s ease-in
}

.header-services {
  border-bottom: 1px solid #d9e1e1;
  min-height: 76px;
  position: relative
}

.header-services .header__callback {
  display: none
}

.header__mobile-menu {
  display: none;
  left: 0;
  min-height: 60px;
  position: absolute;
  top: 0;
  width: 60px
}

.header__awards,
.header__clinics,
.header__contact-management,
.header__description,
.header__logo,
.header__phone,
.header__slinks {
  float: left;
  min-height: 76px
}

.header__logo {
  max-width: 189px;
  min-height: 76px
}

.header__logo:after {
  background-image: url(/build/i/2b8caf57134b76405521.svg);
  content: "";
  display: inline-block;
  height: 36px;
  min-height: inherit !important;
  min-height: auto !important;
  width: 189px
}

.header__logo:hover:after {
  background-image: url(/build/i/a5542a9fa7b23316f4e1.svg)
}

.header__description {
  color: #c2c3c5;
  float: left;
  font-size: 14px;
  margin-left: 20px;
  max-width: 93px
}

.header__awards {
  float: left;
  margin-left: 24px
}

.header__awards>a {
  display: inline-block
}

.header__clinics {
  color: #8f989a;
  float: left;
  font-size: 14px;
  margin-left: 38px
}

.header__clinics .active.slide-tab__label,
.header__clinics .active[data-js=tooltip],
.header__clinics .link.active,
.header__clinics .tabs__labels.tabs__labels_buttons .active.tab__label,
.tabs__labels.tabs__labels_buttons .header__clinics .active.tab__label {
  color: #8f989a
}

.header__contact-management {
  float: left;
  font-size: 14px;
  margin-left: 30px
}

.header__contact-management.active {
  color: #8f989a
}

.header__phone {
  color: #c2c3c5;
  float: right;
  font-size: 14px;
  text-align: right
}

.header__phone__link {
  color: #3b4041;
  display: inline-block;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin-top: 5px
}

.header__phone__link:hover {
  color: #00ada4
}

.header-menu,
.header-search,
.header__callback {
  float: left;
  min-height: 76px
}

.header-menu-wrapper .header__phone,
.header_small .header-menu-wrapper .header__callback {
  display: none
}

.header_small .header-menu-wrapper .header__phone {
  display: flex
}

.header-menu,
.header-menu__sections {
  min-height: 76px;
  position: relative
}

.header-menu__sections {
  z-index: 0
}

.header-menu__section {
  float: left;
  min-height: 76px
}

.header-menu__section:last-child .header-menu__link {
  padding-right: 0
}

.header-menu__link {
  float: left;
  height: 100%;
  min-height: 76px;
  padding-left: 30px;
  padding-right: 30px
}

.header-menu__link:not(.active):hover {
  color: #00ada4
}

.header-menu__link.opened {
  color: #00beb4
}

.header-menu__link:after {
  content: "";
  font-size: 0;
  min-height: inherit
}

.header-search {
  min-width: 98px;
  position: relative
}

.header-search:after {
  background-color: #eff4f5;
  border-radius: 0 24px 24px 0;
  content: "";
  height: 48px;
  min-height: inherit !important;
  min-height: auto !important;
  opacity: 0;
  padding-right: 20px;
  position: absolute;
  right: 0;
  top: 14px;
  width: 100%;
  z-index: 0
}

.header-search__link {
  height: 48px;
  position: absolute;
  right: 0;
  width: 50px;
  z-index: 1
}

.header-search__link:after {
  content: url(/build/i/99d7cf93827b393ef727.svg);
  opacity: 0;
  position: absolute;
  z-index: -1
}

.header-search__link:hover:before {
  background-image: url(/build/i/99d7cf93827b393ef727.svg);
  transition: all .1s ease-in
}

.header-search__link:before {
  background-image: url(/build/i/6765a357c081e635114a.svg);
  background-size: cover;
  content: "";
  display: block;
  height: 16px;
  left: 17px;
  position: relative;
  transition: all .1s ease-out;
  width: 16px;
  z-index: 1
}

.header-search__close {
  border-radius: 0 24px 24px 0;
  display: none;
  height: 48px;
  position: relative;
  z-index: 1
}

.header-search__close:hover:after {
  background-image: url(/build/i/015fdc15fd3d400de783.svg);
  transition: all .1s ease-in
}

.header-search__close:before {
  background-color: #d5e0e3;
  content: "";
  height: 18px;
  left: -4px;
  position: absolute;
  width: 1px
}

.header-search__close:after {
  background-image: url(/build/i/2f8a00b491711355ef91.svg);
  background-size: cover;
  content: "";
  display: block;
  height: 16px;
  left: 13px;
  position: relative;
  transition: all .1s ease-out;
  width: 16px;
  z-index: 1
}

.header-search__form {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: -1
}

.header-search__form:after {
  background-color: transparent;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all .1s ease-out;
  width: 100%
}

.header-search__input {
  -webkit-appearance: none;
  background-color: #eff4f5;
  border-radius: 24px 0 0 24px;
  height: 48px;
  left: 0;
  opacity: 0;
  padding-left: 20px;
  padding-right: 4px;
  position: absolute;
  top: 14px;
  width: 100%;
  z-index: 0
}

.header-search__input::-webkit-input-placeholder {
  color: #b0b6c0;

  font-size: 16px;
  font-style: normal;
  font-weight: 400
}

.header-search__input:-ms-input-placeholder {
  color: #b0b6c0;

  font-size: 16px;
  font-style: normal;
  font-weight: 400
}

.header-search__input::placeholder {
  color: #b0b6c0;

  font-size: 16px;
  font-style: normal;
  font-weight: 400
}

.header__callback {
  float: right
}

.header-menu-wrapper_with-search .header-search__form {
  transition: all .1s ease-in;
  z-index: 1
}

.header-menu-wrapper_with-search .header-search__form:after {
  background-color: #fff;
  transition: all .1s ease-in
}

.header-menu-wrapper_with-search .header-search__input {
  opacity: 1;
  transition: all .1s ease-in;
  z-index: 1
}

.header-menu-wrapper_with-search .header-search:after {
  opacity: 1;
  transition: all .1s ease-in
}

.header-menu-wrapper_with-search .header-search__link {
  position: relative;
  right: inherit !important;
  right: auto !important
}

.header-menu-wrapper_with-search .header-search__link:before {
  left: 12px
}

.header-menu-wrapper_with-search .header-search__close {
  display: flex
}

.header-menu__overlay {
  background: hsla(0, 0%, 6%, .5);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transform: scaleY(0);
  transform-origin: top;
  z-index: 101
}

.header-menu__overlay_open {
  transform: scaleY(1);
  transform-origin: top;
  transition: transform .2s ease-in
}

.header-menu__dropdowns {
  background-color: #eef3f6;
  box-shadow: 0 0 53px rgba(70, 81, 86, .15);
  max-height: 785px;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 152px;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform .2s ease-out, top .1s ease-out;
  width: 100%;
  z-index: 102
}

.header-menu__dropdowns.shown {
  transform: scaleY(1);
  transform-origin: top;
  transition: transform .2s ease-in, top .1s ease-in
}

.header-menu__dropdowns.upper-dropdowns {
  top: 76px
}

.header-menu__dropdown {
  display: none;
  flex-wrap: wrap;
  justify-content: flex-start;
  min-height: 785px
}

.header-menu__dropdown>:first-child {
  flex: 0 0 248px
}

.header-menu__dropdown>:nth-child(2) {
  flex: 1 0 auto;
  padding-left: 64px
}

.header-menu__dropdown.shown {
  display: flex
}

.header-menu__dropdown__left {
  overflow-y: auto;
  padding-bottom: 44px;
  padding-top: 44px
}

.header-menu__dropdown__right {
  background-color: #fff;
  box-shadow: 0 7px 43px rgba(0, 0, 0, .05);
  padding-bottom: 53px;
  padding-top: 54px;
  position: relative
}

.header-menu__dropdown__right:after {
  background-color: #fff;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  right: -1000px;
  top: 0;
  width: 1000px
}

.header-menu__dropdown__side-menu .link_dark.active:not(a),
.header-menu__dropdown__side-menu .link_dark:active:not(a) {
  color: #5f6877
}

.header-menu__dropdown__side-menu__link {
  height: 42px;
  padding-left: 13px;
  position: relative;
  width: 100%;
  z-index: 1
}

.header-menu__dropdown__side-menu__link:not(.no-action).active,
.header-menu__dropdown__side-menu__link:not(.no-action):hover {
  background-color: #fff;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  cursor: default
}

.header-menu__dropdown__side-menu__link:not(.no-action):hover:not(.active) {
  background-color: hsla(0, 0%, 100%, .5);
  cursor: pointer
}

.header-menu__dropdown__side-menu-pusher {
  height: 21px
}

.header-menu__dropdown__menu {
  float: left
}

.header-menu__dropdown__menu-pusher {
  height: 28px
}

.header-menu__dropdown__menu__group {
  color: #c2c3c5;
  font-size: 14px;
  margin-bottom: 20px
}

.header-menu__dropdown__menu_third {
  max-width: 912px;
  width: 100%
}

.header-menu__dropdown__menu_third>:first-child,
.header-menu__dropdown__menu_third>:nth-child(2) {
  padding-right: 30px
}

.header-menu__dropdown__menu_grid {
  margin-top: 5px;
  max-width: 912px;
  width: 100%
}

.header-menu__dropdown__menu_grid>:first-child {
  flex: 1 1 100%;
  max-width: 624px
}

.header-menu__dropdown__menu_grid>:last-child {
  margin-top: 20px
}

.header-menu__dropdown__menu__row {
  flex-wrap: nowrap;
  margin-bottom: 22px;
  width: 100%
}

.header-menu__dropdown__menu__row>* {
  margin-bottom: 20px;
  max-width: 50%;
  padding-right: 24px
}

.header-menu__dropdown__menu__row>* .header-menu__dropdown__menu-pusher:last-child {
  display: none
}

.header-menu__dropdown__menu__row .header-menu__dropdown__link_main:last-child {
  margin-bottom: 0
}

.header-menu__dropdown__menu__row_buttons {
  align-items: center;
  margin-top: 15px
}

.header-menu__dropdown__menu__row_buttons .header-menu__dropdown__link {
  margin-bottom: 0
}

.header-menu__dropdown__menu_half {
  max-width: 912px;
  width: 100%
}

.header-menu__dropdown__menu_half>:first-child {
  flex-basis: 65%;
  padding-right: 30px
}

.header-menu__dropdown__menu_half>:nth-child(2) {
  flex-basis: 35%
}

.header-menu__dropdown__right__menus nav {
  display: none
}

.header-menu__dropdown__right__menus nav.showed {
  display: block
}

.header-menu__dropdown__link {
  display: inline-block;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 20px;
  position: relative
}

.header-menu__dropdown__link:before {
  content: "";
  display: none;
  height: 14px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 14px
}

.header-menu__dropdown__link:hover {
  color: #d5973f
}

.header-menu__dropdown__link_star {
  padding-left: 30px
}

.header-menu__dropdown__link_star:before {
  background-image: url(/build/i/2604f880d43fea3aad6d.svg);
  display: block
}

.header-menu__dropdown__link_percent {
  padding-left: 30px
}

.header-menu__dropdown__link_percent:before {
  background-image: url(/build/i/9c0c5a056c76beb8082d.svg);
  display: block
}

.header-menu__dropdown__link_info {
  padding-left: 30px
}

.header-menu__dropdown__link_info:before {
  background-image: url(/build/i/d1a8238295150b51364d.svg);
  display: block
}

.header-menu__dropdown__link_main {

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 32px
}

.header-menu__teaser {
  background-color: #d1d2d7;
  background-position: bottom;
  border-radius: 10px;
  float: right;
  min-height: 548px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 43px;
  text-align: center;
  transition: -webkit-filter .2s ease-out;
  transition: filter .2s ease-out;
  transition: filter .2s ease-out, -webkit-filter .2s ease-out;
  width: 288px
}

.header-menu__teaser:hover {
  -webkit-filter: saturate(150%);
  filter: saturate(150%);
  transition: -webkit-filter .2s ease-in;
  transition: filter .2s ease-in;
  transition: filter .2s ease-in, -webkit-filter .2s ease-in
}

.header-menu__teaser .button,
.header-menu__teaser .slide-tab__label.active,
.header-menu__teaser .tabs__labels.tabs__labels_buttons .tab__label.active,
.tabs__labels.tabs__labels_buttons .header-menu__teaser .tab__label.active {
  max-width: 230px;
  text-align: center;
  width: 100%
}

.header-menu__teaser .header-menu__teaser__button_short {
  max-width: none;
  width: auto
}

.header-menu__teaser__title {
  color: #3c4143;

  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 15px
}

.header-menu__teaser__title>span {
  color: #df8200
}

.header-menu__teaser__text {
  color: #5f6877;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 30px
}

.header-menu_mobile {
  display: none
}

.header-menu_mobile__sections>* {
  color: #c2c3c5;
  font-size: 14px;
  min-height: 53px
}

.header-menu__dropdowns_mobile-wrapper {
  position: fixed;
  top: 114px;
  transform: scaleY(0);
  transform-origin: top;
  width: 100%;
  z-index: 103
}

.header-menu__dropdowns_mobile-wrapper.shown {
  transform: scaleY(1);
  transform-origin: top;
  transition: transform .2s ease-in
}

.header-menu__dropdowns_mobile__tabs {
  background-color: #fff;
  border-bottom: 1px solid #d9e1e1;
  border-top: 1px solid #d9e1e1;
  position: relative;
  z-index: 1
}

.header-menu__dropdowns_mobile__tabs>* {
  color: #c2c3c5;
  cursor: pointer;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: 54px;
  transition: background-color .2s ease-out
}

.header-menu__dropdowns_mobile__tabs>.active {
  background-color: #00beb4;
  color: #fff;
  transition: background-color .2s ease-in
}

.header-menu__dropdowns_mobile {
  height: auto
}

.header-menu__dropdown_mobile {
  background-color: #fff;
  box-shadow: 0 0 53px rgba(70, 81, 86, .15);
  display: none;
  padding-top: 44px;
  text-align: center
}

.header-menu__dropdown_mobile.opened {
  display: block
}

.header-menu__dropdown_mobile__links>div {
  margin-bottom: 26px
}

.header-menu__dropdown_mobile__links>div>* {
  font-size: 14px
}

.header-menu__dropdown_mobile__bottom {
  margin-top: 44px;
  min-height: 1px
}

.header-menu__dropdown_mobile__bottom>div {
  border-top: 1px solid #d9e1e1;
  height: 54px
}

.header-menu__dropdown_mobile__bottom>div>* {
  color: #c2c3c5;
  font-size: 14px
}

.header-menu__dropdown__menu_close {
  color: #c2c3c5;
  font-size: 14px;
  padding-right: 30px;
  position: absolute;
  right: 0;
  top: 33px
}

.header-menu__dropdown__menu_close:before {
  content: url(/build/i/2f8a00b491711355ef91.svg);
  opacity: 0;
  position: absolute;
  z-index: -1
}

.header-menu__dropdown__menu_close:hover {
  color: #8e9799
}

.header-menu__dropdown__menu_close:hover:after {
  content: url(/build/i/2f8a00b491711355ef91.svg);
  transition: all .2s ease-in
}

.header-menu__dropdown__menu_close:after {
  content: url(/build/i/e7ec2a3b84401537e190.svg);
  display: block;
  height: 16px;
  position: absolute;
  right: 0;
  top: 1px;
  transition: all .2s ease-out;
  width: 16px
}

.header__slinks {
  display: flex;
  flex-wrap: wrap;
  float: left;
  justify-content: flex-start;
  margin-left: 45px;
  margin-right: -5px
}

.header__slinks>* {
  flex: 0 1 30px;
  margin-left: 5px;
  margin-right: 5px
}

@media screen and (max-width:1380px) {
  .header-menu__link {
    padding-left: 28px;
    padding-right: 28px
  }
}

@media screen and (max-width:1365px) {
  .header-menu__dropdown__menu_close {
    top: 37px
  }

  .header-menu__dropdown__menu_third>* {
    flex-basis: 50%
  }

  .header-menu__dropdown__menu_third>:last-child {
    height: 0;
    width: 0
  }

  .header-menu__dropdown__left {
    padding-top: 25px
  }

  .header-menu__dropdown__right {
    padding-top: 37px
  }

  .header-menu__teaser {
    align-items: center;
    background-image: none;
    border-radius: 0;
    bottom: 0;
    display: flex;
    left: -304px;
    min-height: 52px;
    padding-left: 56px;
    padding-right: 56px;
    padding-top: 0;
    position: absolute;
    width: 100vw;
    z-index: 1
  }

  .header-menu__teaser:after {
    content: "";
    font-size: 0;
    min-height: inherit
  }

  .header-menu__teaser>* {
    float: left
  }

  .header-menu__teaser br {
    display: none
  }

  .header-menu__teaser .button,
  .header-menu__teaser .slide-tab__label.active,
  .header-menu__teaser .tabs__labels.tabs__labels_buttons .tab__label.active,
  .tabs__labels.tabs__labels_buttons .header-menu__teaser .tab__label.active {
    border-radius: 16px;
    height: 32px;
    line-height: 36px;
    position: absolute;
    right: 56px;
    top: 10px
  }

  .header-menu__teaser__title {
    font-size: 24px;
    margin-bottom: 0;
    margin-right: 82px
  }

  .header-menu__teaser__text {
    display: none
  }

  .header-menu__dropdown__menu_grid>:first-child {
    max-width: 824px
  }
}

@media screen and (max-width:1345px) {
  .header-services .header__callback {
    display: flex
  }

  .header-menu-wrapper>.row,
  .header-menu-wrapper>.row_short {
    width: 1026px
  }

  .header-menu-wrapper .header__callback,
  .header__clinics,
  .header__contact-management {
    display: none
  }

  .header__slinks {
    margin-left: 45px
  }

  .header__phone {
    float: left;
    margin-left: 30px
  }

  .header-menu__link {
    padding-left: 23px;
    padding-right: 23px
  }

  .header__awards {
    margin-left: 19px
  }

  .header-search__link:before {
    left: 34px
  }

  .header_small .header-menu-wrapper .header__phone {
    display: none
  }
}

@media screen and (max-width:1174px) {
  .header__slinks {
    margin-left: 23px
  }
}

@media screen and (max-width:1160px) {
  .header__slinks {
    display: none
  }

  .header__phone {
    margin-left: 5%
  }
}

@media screen and (max-width:1130px) {
  .header-search {
    min-width: 71px
  }

  .header-search__link {
    height: 41px
  }

  .header-menu-wrapper_with-search .header-search__link:before {
    left: 3px;
    top: -1px
  }

  .header-search__close:after {
    left: 7px;
    top: -1px
  }
}

@media screen and (max-width:1024px) {
  .header-menu__dropdowns.upper-dropdowns {
    top: 53px
  }

  .header-search {
    min-width: 54px
  }

  .header-menu-wrapper>.row,
  .header-menu-wrapper>.row_short {
    width: 1024px
  }

  .header__phone {
    margin-left: 40px
  }
}

@media screen and (max-width:1023px) {
  .header-menu__teaser {
    left: -274px;
    padding-left: 26px;
    padding-right: 26px
  }

  .header-menu__teaser .button,
  .header-menu__teaser .slide-tab__label.active,
  .header-menu__teaser .tabs__labels.tabs__labels_buttons .tab__label.active,
  .tabs__labels.tabs__labels_buttons .header-menu__teaser .tab__label.active {
    right: 26px
  }

  .header-menu__teaser__title {
    font-size: 18px;
    line-height: inherit !important;
    line-height: normal !important;
    margin-right: 35px
  }

  .header-menu-wrapper>.row,
  .header-menu-wrapper>.row_short {
    width: 763px
  }

  .header-menu__dropdowns {
    top: 130px
  }

  .header-menu__dropdown__right__menus nav {
    padding-bottom: 30px
  }

  .header-menu__dropdown__right__menus nav>:first-child {
    margin-bottom: 20px
  }

  .header-menu__dropdown__menu-pusher {
    height: 20px
  }

  .header-menu__dropdown__menu_third {
    width: 100%
  }

  .header-menu__dropdown__menu_third>* {
    flex-basis: 100%
  }

  .header-menu__dropdown__menu_grid>:first-child {
    max-width: 100%
  }

  .header-menu__dropdown__menu__row>* {
    flex-basis: 100%
  }

  .header-menu__dropdown__menu_half {
    width: 100%
  }

  .header-menu__dropdown__menu_half>* {
    flex-basis: 100% !important
  }

  .header-menu__dropdown__link {
    margin-bottom: 13px
  }

  .header-menu__dropdown__link_main {
    margin-bottom: 18px
  }

  .header-menu,
  .header-menu__link,
  .header-menu__section,
  .header-menu__sections,
  .header-search {
    min-height: 53px
  }

  .header-menu__link {
    font-size: 14px;
    padding-left: 16px;
    padding-right: 16px
  }

  .header-search:after,
  .header-search__input {
    height: 41px;
    top: 6px
  }

  .header-search__link {
    height: 41px
  }

  .header__awards,
  .header__description {
    display: none
  }

  .header__phone {
    margin-left: 18%
  }
}

@media screen and (max-width:970px) {
  .header-menu__dropdown__menu__row {
    margin-bottom: 30px
  }

  .header-menu__dropdown__menu__row>* {
    flex-basis: 100%;
    max-width: 100%
  }

  .header-menu__dropdown__menu__row>* .header-menu__dropdown__menu-pusher:last-child {
    display: block
  }

  .header-menu__dropdown__menu__row_buttons {
    margin-top: 0
  }

  .header-menu__dropdown__menu-button {
    margin-bottom: 20px
  }

  .header-menu__dropdown>:nth-child(2) {
    flex: 1 1 50%
  }
}

@media screen and (max-width:880px) {

  .header-menu-wrapper:not(.header-menu-wrapper_with-search)>.row,
  .header-menu-wrapper:not(.header-menu-wrapper_with-search)>.row_short {
    width: 100%
  }

  .header__phone {
    margin-left: 10%
  }
}

@media screen and (max-width:768px) {
  .header__phone {
    margin-left: 76px;
    text-align: center
  }
}

@media screen and (max-width:767px) {
  .header-menu-wrapper .link_dark {
    color: #333638
  }

  .header-menu-wrapper .link_dark:hover {
    color: #000
  }

  .header_small {
    transform: translateY(-114px)
  }

  .header_small .header-menu-wrapper {
    background-color: #00beb4
  }

  .header_small .header-menu-wrapper .link_dark,
  .header_small .header-menu-wrapper .link_dark:hover {
    color: #fff
  }

  .header__mobile-menu {
    display: block
  }

  .header-menu,
  .header-search {
    display: none
  }

  .header-services {
    min-height: 60px
  }

  .header__logo {
    display: flex;
    float: none;
    height: 20px;
    justify-content: center;
    left: 50%;
    margin-left: -58px;
    min-height: 20px;
    position: absolute;
    top: 14px;
    width: 116px
  }

  .header__logo:after {
    height: 20px;
    width: 93px
  }

  .header__description {
    display: block;
    font-size: 12px;
    left: 50%;
    margin-left: -59px;
    max-width: inherit !important;
    max-width: none !important;
    min-height: inherit !important;
    min-height: auto !important;
    position: absolute;
    top: 37px
  }

  .header__phone,
  .header__phone__link {
    font-size: 0
  }

  .header__phone {
    float: right;
    margin-left: 0 !important;
    min-height: 60px
  }

  .header__phone__link {
    background-image: url(/build/i/a0e751072ffb92197cda.svg);
    background-position: 50%;
    background-size: 18px 18px;
    margin-top: 0 !important;
    min-height: 60px;
    position: absolute;
    right: 0;
    top: 0;
    width: 60px
  }

  .header-services .header__callback {
    display: none
  }

  .header-menu_mobile {
    display: block
  }

  .header-menu__dropdowns {
    display: none !important
  }
}

.header__logo_new-year {
  position: relative
}

.header__logo_new-year:before {
  background-image: url(/build/i/ac1e1f0025c17fd9662c.svg);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 100% auto;
  content: "";
  display: block;
  height: 30px;
  left: -13px;
  position: absolute;
  top: 6px;
  width: 30px
}

.header__phone_new-year {
  margin-left: 30px;
  position: relative
}

.header__phone_new-year:before {
  background-image: url(/build/i/c5cb9a695083e9bafd66.svg);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 100% auto;
  content: "";
  display: block;
  height: 36px;
  left: -30px;
  position: absolute;
  top: 19px;
  width: 26px
}

.header-menu-wrapper_new-year {
  background-image: url(/build/i/91d79a54b09d709e1af6.svg);
  background-position: 0 0;
  background-repeat: repeat
}

@media screen and (max-width:1345px) {
  .header__phone_new-year {
    margin-left: 45px
  }

  .header__phone_new-year:before {
    left: -35px
  }
}

@media screen and (max-width:1160px) {
  .header__phone_new-year {
    margin-left: 10%
  }
}

@media screen and (max-width:1023px) {
  .header__phone_new-year {
    margin-left: 15%
  }
}

@media screen and (max-width:767px) {
  .header__logo_new-year {
    position: absolute
  }

  .header__logo_new-year:before {
    height: 20px;
    left: -10px;
    top: -11px;
    width: 20px
  }

  .header__phone_new-year {
    position: inherit !important;
    position: static !important
  }

  .header__phone_new-year:before {
    display: none
  }

  .header-menu-wrapper_new-year {
    background-position: 6px -9px
  }
}

footer {
  margin-top: 60px
}

.footer-top {
  position: relative
}

.footer-top__booster {
  justify-content: space-between;
  max-width: 1014px
}

.footer-top__booster .booster-block:before {
  background-color: #00d5ca
}

.footer-top__booster .booster-block:after {
  display: block !important;
  height: 60px !important;
  margin-top: -30px !important;
  position: absolute !important;
  right: 55px !important;
  top: 50% !important;
  width: 60px !important
}

.footer-services {
  background-color: #00beb4;
  bottom: 0;
  left: 0;
  margin-top: 60px;
  position: relative;
  width: 100%;
  z-index: 1
}

.footer-menu {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin: 58px -12px 40px
}

.footer-menu>* {
  flex: 0 1 33.33%;
  margin-left: 12px;
  margin-right: 12px
}

.footer-menu>:nth-child(2) {
  margin-left: 120px
}

.footer-menu>:nth-child(3) {
  flex-basis: 40%;
  max-width: 285px
}

.footer-menu__title {

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 25px;
  text-transform: uppercase
}

.footer-menu__text {
  color: #898989;
  font-size: 14px;
  line-height: 24px
}

.footer-menu__link {
  color: #898989;
  display: inline-block;
  font-size: 14px;
  margin-bottom: 22px
}

.footer-menu__link:hover:not(.active) {
  color: #3c4143
}

.footer-menu__link.active {
  color: #d5973f
}

.footer-menu__link.underline {

  font-style: normal;
  font-weight: 500
}

.footer-menu__email {
  font-size: 14px;
  margin-top: 23px
}

.footer-menu__flex {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin-left: -12px;
  margin-right: -12px
}

.footer-menu__flex>* {
  flex: 0 1 50%;
  margin-left: 12px;
  margin-right: 12px
}

.footer2__logo {
  max-width: 200px;
  min-height: 132px;
  position: relative
}

.footer2__logo:before {
  background-image: url('https://fantasy-smile.ru/bitrix/templates/ortodont/img/logo-header.png');
  content: "";
  display: inline-block;
  height: 70px;
  width: 200px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.footer2__logo:hover:before {
  background-image: url(/build/i/ec2dec3883a62ac95d79.svg)
}

.footer2__callback,
.footer2__description,
.footer2__logo,
.footer2__phone,
.footer2__work-time {
  float: left;
  min-height: 132px
}

.footer2__description {
  color: #fff;
  font-size: 14px;
  margin-left: 22px
}

.footer2__work-time {
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  margin-right: 47px
}

.footer2__phone {
  color: #fff;
  font-size: 14px;
  margin-right: 28px
}

.footer2__phone__link {
  color: #fff;
  display: inline-block;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin-top: 5px;
  text-transform: uppercase
}

.footer2__phone__link:hover {
  color: #ace8e5
}

.footer-menu__slinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 28px -6px 20px;
  max-width: 300px
}

.footer-menu__slinks>* {
  flex: 0 1 46px;
  margin-bottom: 20px;
  margin-left: 6px;
  margin-right: 6px
}

.footer-bottom {
  border-top: 1px solid #d9e1e1;
  min-height: 104px
}

.footer-bottom>* {
  align-items: center;
  display: flex
}

.footer-bottom .row,
.footer-bottom .row_short {
  display: grid;
  grid-template-areas: "copyright . rating . sinergium";
  grid-template-columns: auto 1fr auto 1fr auto;
  grid-template-rows: auto
}

.footer-bottom .row>*,
.footer-bottom .row_short>* {
  display: flex;
  height: 100%;
  margin: 40px 0
}

.copyright-wrapper {
  align-items: center;
  grid-area: copyright
}

.rating-wrapper {
  align-items: flex-start;
  flex-direction: column;
  grid-area: rating;
  justify-content: center
}

.rating-wrapper .footer2__questionnaire {
  font-size: 12px;
  line-height: 18px
}

.copyright-sinergium-wrapper {
  align-items: center;
  grid-area: sinergium
}

.sinergium-copyright {
  color: #2e3946;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  position: relative;
  width: 300px;
  width: auto
}

.sinergium-copyright>div {
  padding-left: 41px;
  position: relative
}

.sinergium-copyright>div:before {
  background-image: url(/build/i/affefe1624ca7b4333b5.svg);
  background-position: 50%;
  background-size: contain;
  content: "";
  height: 34px;
  left: 0;
  margin-top: -17px;
  position: absolute;
  top: 50%;
  width: 27px
}

.sinergium-copyright a {
  color: #81c400
}

.sinergium-copyright a>span {
  border-bottom: 1px solid #81c400;
  transition: border .2s ease
}

.sinergium-copyright a:hover>span {
  border-bottom: 1px solid transparent
}

.copyright,
.footer-bottom__partners,
.footer-bottom__privacy-policy {
  color: #898989;
  font-size: 12px;
  line-height: 18px
}

.copyright {
  position: relative
}

.copyright:before {
  background-image: url(/build/i/8315bcff913ce5fd9c2d.svg);
  content: "";
  display: block;
  float: left;
  height: 35px;
  margin-right: 15px;
  width: 35px
}

.copyright a {
  width: 100%
}

.copyright a,
.footer-bottom__partners a,
.footer-bottom__privacy-policy a {
  color: #898989
}

.footer-bottom__partners a:hover,
.footer-bottom__privacy-policy a:hover {
  color: #3c4143
}

.footer-bottom__partners {
  justify-content: flex-end;
  margin-left: 60px;
  text-align: right
}

.footer-bottom__ratings {
  align-items: center;
  color: #898989;
  font-size: 12px;
  justify-content: center;
  line-height: 18px
}

.footer-bottom__ratings .rate {
  display: inline-block;
  margin-bottom: 4px;
  margin-left: 10px;
  max-width: 100px
}

.footer-mobile-bottom {
  display: flex;
  display: none;
  justify-content: center;
  padding-bottom: 58px;
  padding-top: 55px;
  text-align: center
}

.footer-mobile-bottom:after {
  content: "";
  font-size: 0;
  min-height: inherit
}

.footer-mobile-bottom .footer-menu__email {
  margin-top: 15px
}

@media screen and (max-width:1200px) {
  .footer-menu__flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -12px;
    margin-right: -12px
  }

  .footer-menu__flex>* {
    flex: 0 1 100%;
    margin-left: 12px;
    margin-right: 12px
  }

  .footer-menu {
    padding-bottom: 10px
  }

  .footer-menu>:nth-child(2) {
    margin-left: 0
  }
}

@media screen and (max-width:1122px) {
  .footer2__description {
    display: inline-block;
    font-size: 13px;
    left: 56px;
    margin-left: 0;
    min-height: auto;
    position: absolute;
    top: 86px
  }

  .footer2__description br {
    display: none
  }

  .footer-top__booster {
    max-width: 1364px;
    padding-left: 56px;
    padding-right: 56px
  }

  .footer-top__booster .booster-block {
    max-width: calc(50% - 10px)
  }
}

@media screen and (max-width:1066px) {
  .footer2__work-time {
    display: none
  }

  .footer2__phone {
    margin-right: 186px;
    text-align: center
  }

  .footer-bottom .row,
  .footer-bottom .row_short {
    grid-template-areas: "copyright . sinergium""rating    . sinergium";
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto auto;
    padding-bottom: 20px;
    padding-top: 20px
  }

  .footer-bottom .row>*,
  .footer-bottom .row_short>* {
    margin: 10px 0
  }

  .copyright-sinergium-wrapper {
    align-items: flex-start;
    padding-top: 10px
  }
}

@media screen and (max-width:1023px) {
  .footer2__description {
    left: 26px
  }

  .footer2__phone {
    margin-right: 67px
  }

  .footer-top__booster {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-width: 495px;
    padding-left: 0;
    padding-right: 0
  }

  .footer-top__booster>* {
    flex: 0 1 100%
  }

  .footer-top__booster>:first-child {
    margin-bottom: 38px
  }

  .footer-top__booster .booster-block {
    max-width: 100%
  }

  .footer-menu__text br {
    display: none
  }

  .footer-bottom__partners,
  .footer-bottom__privacy-policy {
    float: none;
    margin-left: 0 !important;
    min-height: inherit !important;
    min-height: auto !important
  }

  .footer-bottom__partners>*,
  .footer-bottom__privacy-policy>* {
    margin-left: auto
  }

  .footer-bottom .right {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 104px
  }

  .footer-bottom .right:after {
    content: "";
    font-size: 0;
    min-height: inherit
  }

  .footer-menu__slinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -14px;
    margin-right: -14px
  }

  .footer-menu__slinks>* {
    flex: 0 1 46px;
    margin-left: 14px;
    margin-right: 14px
  }

  .copyright:before {
    padding-right: 35px
  }
}

@media screen and (max-width:767px) {
  .footer-mobile-bottom {
    display: flex
  }

  footer {
    margin-top: 0
  }

  .footer-menu {
    display: none
  }

  .footer-top__booster {
    margin-bottom: 38px;
    margin-top: 20px;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px
  }

  .footer-top__booster>:first-child {
    margin-bottom: 0
  }

  .footer-top__booster .booster-block {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 10px 29px rgba(70, 81, 86, .18);
    justify-content: flex-start;
    margin-top: 15px;
    max-width: 100%;
    min-height: 74px;
    padding-left: 20px;
    padding-right: 60px;
    width: 100%
  }

  .footer-top__booster .booster-block:before {
    content: "";
    height: 56px;
    margin-top: -28px;
    right: -8px;
    width: 56px
  }

  .footer-top__booster .booster-block:after {
    background-size: contain;
    content: "";
    height: 40px !important;
    margin-top: -20px !important;
    right: 0 !important;
    width: 40px !important
  }

  .footer-top__booster .booster-block_mini .booster-block__title {
    font-size: 14px;
    line-height: 20px;
    text-align: left
  }

  .footer-services {
    display: flex;
    height: 405px;
    justify-content: center;
    margin-top: 0
  }

  .footer-services:after {
    content: "";
    font-size: 0;
    min-height: inherit
  }

  .footer-services .right {
    float: none
  }

  .footer2__work-time {
    display: block
  }

  .footer2__callback,
  .footer2__logo,
  .footer2__phone,
  .footer2__work-time {
    float: none;
    margin-left: 0 !important;
    margin-right: 0 !important;
    min-height: inherit !important;
    min-height: auto !important
  }

  .footer2__logo {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    min-width: 215px
  }

  .footer2__logo:after {
    content: "";
    font-size: 0;
    min-height: inherit
  }

  .footer2__description {
    font-size: 14px;
    left: calc(50% - 101px);
    top: 92px
  }

  .footer2__work-time {
    margin-top: 31px;
    text-align: center
  }

  .footer2__phone {
    display: flex;
    justify-content: center;
    margin-top: 21px;
    text-align: center
  }

  .footer2__phone:after {
    content: "";
    font-size: 0;
    min-height: inherit
  }

  .footer2__phone__link {
    margin-top: 5px
  }

  .footer2__callback {
    display: flex;
    justify-content: center;
    margin-top: 44px
  }

  .footer2__callback:after {
    content: "";
    font-size: 0;
    min-height: inherit
  }

  .copyright {
    display: flex;
    float: none;
    justify-content: center;
    min-height: inherit !important;
    min-height: auto !important
  }

  .copyright:after {
    content: "";
    font-size: 0;
    min-height: inherit
  }

  .copyright:before {
    content: none
  }

  .footer-bottom {
    margin-bottom: 75px
  }

  .footer-bottom .row,
  .footer-bottom .row_short {
    grid-template-areas: "copyright""rating   ""sinergium";
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
    padding-bottom: 17px;
    padding-top: 17px
  }

  .footer-bottom .row>*,
  .footer-bottom .row_short>* {
    margin: 0 auto
  }

  .footer-bottom .row .copyright-wrapper,
  .footer-bottom .row_short .copyright-wrapper {
    text-align: center
  }

  .footer-bottom .row .rating-wrapper,
  .footer-bottom .row_short .rating-wrapper {
    margin-top: 8px
  }

  .footer-bottom .row .rating-wrapper .footer2__questionnaire,
  .footer-bottom .row_short .rating-wrapper .footer2__questionnaire {
    display: flex;
    justify-content: center;
    width: 100%
  }

  .footer-bottom .row .copyright-sinergium-wrapper,
  .footer-bottom .row_short .copyright-sinergium-wrapper {
    margin-top: 12px
  }

  .sinergium-copyright {
    margin-top: 40px;
    max-width: 100%
  }

  .sinergium-copyright>div {
    padding-left: 0;
    text-align: center
  }

  .sinergium-copyright>div:before {
    left: 50%;
    margin-left: -17px;
    top: -40%
  }

  .footer-bottom__partners>*,
  .footer-bottom__privacy-policy>* {
    margin-left: auto;
    margin-right: auto
  }

  .footer-bottom__ratings {
    display: none
  }

  .footer-menu__slinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -6px;
    margin-right: -6px
  }

  .footer-menu__slinks>* {
    flex: 0 1 46px;
    margin-left: 6px;
    margin-right: 6px
  }
}

@media screen and (max-width:361px) {
  .footer-top__booster .booster-block {
    margin-left: 0 !important;
    margin-right: 0 !important
  }
}

@media screen and (max-width:360px) {
  .footer-menu__slinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -4px;
    margin-right: -4px
  }

  .footer-menu__slinks>* {
    flex: 0 1 46px;
    margin-left: 4px;
    margin-right: 4px
  }
}

.booster-block {
  background-color: #fff;
  background-position: right -24px center;
  border-radius: 10px;
  box-shadow: 0 10px 29px rgba(70, 81, 86, .18);
  display: inline-block;
  max-width: 495px;
  min-height: 260px;
  padding: 43px 44px;
  position: relative;
  width: 100%;
  z-index: 1
}

.booster-block:before {
  background-color: #00beb4;
  background-position: 50%;
  background-size: 60px 60px;
  border-radius: 50%;
  height: 80px;
  margin-top: -40px;
  right: 45px;
  width: 80px
}

.booster-block:after,
.booster-block:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%
}

.booster-block:after {
  height: 60px;
  margin-top: -30px;
  right: 55px;
  width: 60px
}

.booster-block__title {
  color: #333638;

  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  position: relative;
  text-transform: uppercase
}

.booster-block__text {
  color: #5f6877;
  margin-top: 30px;
  max-width: 245px
}

.booster-block__link {
  bottom: 43px;

  font-style: normal;
  font-weight: 500;
  position: absolute;
  text-transform: uppercase
}

@media screen and (max-width:767px) {
  .booster-block {
    background-image: none !important;
    border-radius: 0;
    display: flex;
    justify-content: center;
    min-height: 217px;
    text-align: center
  }

  .booster-block:after {
    content: "";
    font-size: 0;
    min-height: inherit
  }

  .booster-block:after,
  .booster-block:before {
    content: none
  }

  .booster-block__title {
    line-height: 28px
  }

  .booster-block__link {
    font-size: 14px
  }
}

.booster-block_map {
  background-image: url(/build/i/a601f82819aae69ecf3f.png)
}

.booster-block_map:after {
  background-image: url(/build/i/93031c16824b17dadedf.svg)
}

.booster-block_review {
  background-image: url(/build/i/3adb184cc8d212c9c8ec.png)
}

.booster-block_review:after {
  background-image: url(/build/i/b3dfdf921c23e43145e4.svg)
}

.booster-block_trained {
  background-image: url(/build/i/12a7d171055fe4938e05.png)
}

.booster-block_trained .booster-block__title {
  color: #00ada4;

  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-transform: uppercase
}

.booster-block_trained:after {
  background-image: url(/build/i/b112c15a694b1bb49d20.svg)
}

.booster-block_laboratory {
  background-image: url(/build/i/dbb2a97e5e641059b788.png)
}

.booster-block_laboratory .booster-block__link {
  bottom: 0;
  position: relative
}

.booster-block_laboratory .booster-block__title {
  color: #5f6877;

  font-style: italic;
  font-weight: 400;
  line-height: 30px;
  margin-top: 28px;
  max-width: 210px;
  text-transform: none
}

.booster-block_laboratory:after {
  background-image: url(/build/i/689fa4a764ee693bd9bf.svg)
}

.booster-block_implantation-systems {
  background-image: url(/build/i/a12ea126b0c8d4fb5b9b.png)
}

.booster-block_implantation-systems:after {
  background-image: url(/build/i/660ddb44a996a940fdd4.svg)
}

.booster-block_implantation-systems .booster-block__text {
  bottom: 51px;
  max-width: 100%;
  position: absolute
}

.booster-block_implantation-systems a {
  display: inline-block;

  font-style: normal;
  font-weight: 500;
  letter-spacing: .42px;
  margin-right: 50px;
  text-transform: uppercase
}

@media screen and (max-width:767px) {
  .booster-block_implantation-systems {
    padding: 34px 15px
  }

  .booster-block_implantation-systems .booster-block__text {
    bottom: 0;
    position: relative;
    width: 100%
  }

  .booster-block_implantation-systems a {
    margin-bottom: 20px;
    margin-left: 25px;
    margin-right: 25px
  }
}

.booster-block_rating-of-implants {
  background-image: url(/build/i/a12ea126b0c8d4fb5b9b.png);
  background-position-y: 16px
}

.booster-block_rating-of-implants:before {
  top: 124px
}

.booster-block_rating-of-implants:after {
  background-image: url(/build/i/41216caeec7746bbbe0a.svg);
  right: 57px;
  top: 123px
}

.booster-block_rating-of-implants .booster-block__title {
  color: #5f6877;

  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-transform: uppercase
}

.booster-block_rating-of-implants a {

  font-style: italic;
  font-weight: 400
}

@media screen and (max-width:767px) {
  .booster-block_rating-of-implants {
    display: block;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative !important;
    text-align: left;
    width: 100vw
  }

  .booster-block_rating-of-implants .booster-block__title {
    text-align: center
  }
}

.booster-block_manual {
  background-image: url(/build/i/a12ea126b0c8d4fb5b9b.png);
  flex-wrap: wrap;
  height: 262px
}

.booster-block_manual:after {
  content: none
}

.booster-block_manual img {
  display: block;
  height: 60px;
  margin-top: -30px;
  position: absolute;
  right: 55px;
  top: 50%;
  width: 60px
}

.booster-block_manual .booster-block__text {
  bottom: 51px;
  max-width: 100%;
  position: absolute
}

.booster-block_manual .link,
.booster-block_manual .slide-tab__label,
.booster-block_manual .tabs__labels.tabs__labels_buttons .tab__label,
.booster-block_manual [data-js=tooltip],
.tabs__labels.tabs__labels_buttons .booster-block_manual .tab__label {
  display: inline-block;

  font-style: normal;
  font-weight: 500;
  letter-spacing: .42px;
  margin-right: 50px;
  text-transform: uppercase
}

.booster-block_hover {
  overflow: hidden
}

.booster-block_hover:before {
  transition: transform .3s ease
}

.booster-block_hover:hover:before {
  transform: scale(2.7)
}

@media screen and (max-width:767px) {
  .booster-block_manual {
    display: block;
    padding: 34px 15px;
    width: 100vw
  }

  .booster-block_manual .booster-block__text {
    bottom: 0;
    position: relative;
    width: 100%
  }

  .booster-block_manual a {
    margin-bottom: 20px;
    margin-left: 25px;
    margin-right: 25px
  }

  .booster-block_manual img {
    display: none
  }

  .booster-block_hover {
    height: auto
  }

  .booster-block_hover .booster-block__text {
    text-align: center
  }

  .booster-block_hover .booster-block__text>span {
    margin-right: 0
  }
}

@media screen and (max-width:525px) {
  .booster-block_hover {
    margin-left: -15px !important;
    margin-right: -15px !important
  }
}

@media screen and (max-width:361px) {
  .booster-block_manual {
    margin-left: -15px !important;
    margin-right: -15px !important
  }
}

.booster-block_mini {
  align-items: center;
  background-image: url(/build/i/a12ea126b0c8d4fb5b9b.png);
  display: flex !important;
  flex-wrap: wrap;
  height: auto;
  min-height: 120px;
  overflow: hidden;
  padding-bottom: 0 !important;
  padding-left: 43px;
  padding-top: 0 !important
}

.booster-block_mini:before {
  background-color: #faaa4c;
  transition: transform .2s ease-out
}

.booster-block_mini:after {
  content: "";
  display: inherit !important;
  display: initial !important;
  font-size: 0;
  height: inherit !important;
  height: auto !important;
  margin-top: 0 !important;
  min-height: inherit;
  position: inherit !important;
  position: static !important;
  right: inherit !important;
  right: auto !important;
  top: inherit !important;
  top: auto !important;
  width: inherit !important;
  width: auto !important
}

.booster-block_mini:hover:before {
  transform: scale(2.7);
  transition: transform .2s ease-in
}

.booster-block_mini .booster-block__title {
  font-size: 16px;
  line-height: 28px;
  max-width: 250px;
  text-transform: none
}

.booster-block_mini img {
  display: block;
  height: 60px;
  margin-top: -30px;
  position: absolute;
  right: 55px;
  top: 50%;
  width: 60px
}

@media screen and (max-width:767px) {
  .booster-block_mini {
    display: block;
    padding: 34px 15px;
    width: 100vw
  }

  .booster-block_mini img {
    display: none
  }
}

.page-header {
  background-position-y: 2px;
  min-height: 565px;
  padding-top: 27px
}

.page-header>.row,
.page-header>.row_short {
  position: relative
}

.page-header+.scrolls {
  border-top: 1px solid #d9e1e1
}

.page-header_auto {
  min-height: auto
}

.page-header_auto .page-header__title {
  padding-bottom: 25px
}

.page-header__pre-h1 {
  color: #333638;

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 12px
}

.page-header_adv .breadcrumbs {
  margin-bottom: 20px
}

.page-header_wv .page-header__wallpaper-wrapper:after {
  content: none
}

.page-header_wv .page-header__wallpaper-wrapper__content {
  left: 60px;
  top: 232px
}

.page-header_wv .page-header__wallpaper-wrapper__content>.progressive-image-wrapper {
  margin-bottom: 49px
}

.page-header_wv .page-header__wallpaper-wrapper__content_bottom {
  left: 130px;
  top: auto
}

.page-header_warranty .breadcrumbs {
  margin-bottom: 25px
}

.page-header_warranty .page-header__title__description {
  max-width: 560px
}

.page-header_warranty .page-header__title__button-wrapper {
  align-items: center;
  margin-top: 45px
}

.page-header_warranty .button_orange {
  text-align: center;
  width: 236px
}

.page-header_warranty .page-header__title__button-quote {
  padding-top: 20px;
  position: relative
}

.page-header_warranty .page-header__title__button-quote:before {
  color: #5f6877;
  content: "\201C";

  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  left: 0;
  position: absolute;
  top: 0
}

.page-header_warranty .page-header__title__button-quote>div:first-child {
  color: #5f6877;
  line-height: 22px;
  margin-bottom: 15px
}

.page-header_warranty .page-header__title__button-quote>div:first-child strong {
  display: block;

  font-style: normal;
  font-weight: 500
}

.page-header_warranty .page-header__title__button-quote>div:nth-child(2) {
  color: #a0abbe;
  font-size: 14px
}

.page-header__title {
  margin-top: 20px;
  max-width: 580px;
  padding-bottom: 80px
}

.page_employee .page-header__title .page-header__wallpaper-wrapper {
  display: none
}

.page-header__title_wide {
  margin-top: 0;
  max-width: 705px
}

.page-header__title_wide .page-header__title__description {
  max-width: 100%
}

.page-header__wallpaper-wrapper {
  border-radius: 300px;
  height: 600px;
  position: absolute;
  right: -75px;
  top: -135px;
  width: 600px
}

.page-header__wallpaper-wrapper:after {
  border: 50px solid #f7f8f9;
  border-radius: 50%;
  content: "";
  display: block;
  height: 456px;
  left: 50%;
  margin-left: -228px;
  margin-top: -228px;
  opacity: .3;
  position: absolute;
  top: 50%;
  width: 456px
}

.page-header__wallpaper-wrapper>.progressive-image-wrapper {
  border-radius: 300px;
  height: 600px;
  width: 600px
}

.page-header__wallpaper-wrapper_awards:after {
  background-image: url(/build/i/f946c1be827710ca6e8b.svg);
  background-repeat: no-repeat;
  background-size: contain;
  border: 0;
  height: 100%;
  left: 0;
  margin-left: 0;
  margin-top: 30px;
  opacity: 1;
  top: 0;
  width: 100%
}

.page-header__wallpaper__play {
  display: inline-block;
  height: 80px;
  left: -45px;
  position: absolute;
  top: 218px;
  width: 80px;
  z-index: 1
}

.page-header__wallpaper__play .circle-icon_big {
  align-items: center;
  display: flex;
  height: 80px;
  justify-content: center;
  width: 80px
}

.page-header__wallpaper__play .circle-icon_big:before {
  background-size: 32px 32px;
  height: 32px;
  margin-left: 0;
  width: 32px
}

.page-header__wallpaper__play_bottom {
  bottom: 65px;
  height: 85px;
  left: 30px;
  top: auto;
  width: 85px
}

.page-header__wallpaper__play_bottom .circle-icon_big {
  height: 85px;
  width: 85px
}

.page-header__wallpaper-wrapper__content {
  color: #a0abbe;
  font-size: 14px;
  left: 76px;
  line-height: 20px;
  max-width: 200px;
  position: absolute;
  top: 196px;
  z-index: 1
}

.page-header__wallpaper-wrapper__content>.progressive-image-wrapper {
  display: block;
  margin-bottom: 20px
}

.page-header__wallpaper-wrapper__content>div>.progressive-image-wrapper {
  margin-right: 12px;
  min-width: 66px
}

.page-header__wallpaper-wrapper__content>div b {
  color: #333638;

  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase
}

.page-header__wallpaper-wrapper__content_bottom {
  bottom: 75px;
  left: 130px;
  text-shadow: 0 0 18px #fff
}

.page-header__wallpaper-wrapper__content_bottom>div {
  color: #5f6877;
  font-weight: 500
}

.page-header__wallpaper-wrapper__content_wide {
  max-width: 240px
}

.page-header_article {
  min-height: 600px;
  position: relative
}

.page-header_article .page-header__wallpaper-wrapper {
  border-radius: 10px;
  height: 443px;
  left: 0;
  margin-bottom: 90px;
  position: relative;
  top: 0;
  width: 100%
}

.page-header_article .page-header__wallpaper-wrapper:after,
.page-header_article .page-header__wallpaper-wrapper:before {
  content: none
}

.page-header_article .page-header__wallpaper {
  background-position: top;
  border-radius: 10px
}

.page-header_article .page-header__title {
  max-width: 900px;
  padding-bottom: 60px
}

.page-header_article .breadcrumbs {
  max-width: 900px
}

.page-header_article .article__employee {
  margin-bottom: 90px
}

.page-header__bg {
  background-position-y: 10px;
  height: 600px;
  left: 0;
  position: absolute;
  top: -27px;
  width: 100%
}

.page-header__section {
  color: #333638;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 20px
}

.page-header__description {
  color: #333638;

  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: 30px;
  margin: 0 auto;
  max-width: 750px;
  text-align: center
}

.page-header__wallpaper {
  background-size: cover;
  -webkit-filter: blur(0);
  filter: blur(0);
  height: 100% !important;
  transition: -webkit-filter .2s ease-out;
  transition: filter .2s ease-out;
  transition: filter .2s ease-out, -webkit-filter .2s ease-out;
  width: 100% !important
}

.page-header__wallpaper_blurred {
  -webkit-filter: blur(2vw);
  filter: blur(2vw);
  transition: -webkit-filter 0s ease-in;
  transition: filter 0s ease-in;
  transition: filter 0s ease-in, -webkit-filter 0s ease-in
}

.page-header__title-wrapper {
  max-width: 580px
}

.page-header__title-wrapper .h1 {
  display: inline;
  margin-bottom: 20px;
  margin-right: 25px
}

.page-header__price {
  background-color: #fdfa9b;
  border-radius: 20px;
  bottom: 6px;
  color: #3c4143;
  cursor: pointer;
  display: inline-block;
  font-size: 24px;
  line-height: 28px;
  margin-top: 15px;
  padding: 6px 18px;
  position: relative;
  transition: background-color .2s ease-out
}

.page-header__price:hover {
  background-color: #f2ee73;
  transition: background-color .2s ease-in
}

.page-header__title__allure_small {
  font-size: 36px;
  line-height: 48px
}

.page-header__title__description {
  color: #a5a5a5;
  font-size: 18px;
  line-height: 28px;
  margin-top: 45px;
  max-width: 445px
}

.page-header__title__description .minus-list,
.page-header__title__description .plus-list {
  color: #5f6877;
  font-size: 16px;
  line-height: 24px
}

.page-header__title__description .minus-list>li,
.page-header__title__description .plus-list>li {
  margin-bottom: 12px
}

.page-header__title__allure-button {
  margin-top: 61px
}

.page-header__title__allure-phone {
  margin-top: 65px;
  max-width: 455px;
  padding-top: 9px;
  position: relative
}

.page-header__title__allure-phone_mobile {
  display: none
}

.page-header__title__button-wrapper {
  display: flex;
  margin-top: 61px
}

.page-header__title__button-wrapper .service-mini__play {
  position: relative
}

.page-header__title__button-wrapper__price {
  color: #a5a5a5;
  font-size: 14px;
  line-height: 24px;
  margin-left: 48px;
  padding-top: 3px
}

.page-header__title__button-wrapper__price .link,
.page-header__title__button-wrapper__price .slide-tab__label,
.page-header__title__button-wrapper__price .tabs__labels.tabs__labels_buttons .tab__label,
.page-header__title__button-wrapper__price [data-js=tooltip],
.tabs__labels.tabs__labels_buttons .page-header__title__button-wrapper__price .tab__label {
  display: inline-block;

  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px
}

.wrapper-price__flex {
  align-items: center;
  display: flex;
  margin-left: 26px
}

.wrapper-price__flex>:nth-child(2) {
  padding-left: 56px;
  position: relative
}

.wrapper-price__flex>:nth-child(2):before {
  bottom: 4px;
  color: #a5a5a5;
  content: "+";

  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  left: 19px;
  line-height: 24px;
  position: absolute
}

.page-header__title__button-wrapper__scroll-link {
  align-items: center;
  display: flex;
  margin-left: 48px
}

.page-header__title__button-wrapper__scroll-link .link,
.page-header__title__button-wrapper__scroll-link .slide-tab__label,
.page-header__title__button-wrapper__scroll-link .tabs__labels.tabs__labels_buttons .tab__label,
.page-header__title__button-wrapper__scroll-link [data-js=tooltip],
.tabs__labels.tabs__labels_buttons .page-header__title__button-wrapper__scroll-link .tab__label {
  display: inline-block;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase
}

.page-header_awards .button_orange {
  max-width: 236px;
  text-align: center;
  width: 100%
}

.page-header_information-page .page-header__title,
.page-header_information-page .page-header__title__description {
  max-width: 610px
}

.page-header__title__description_winners {
  color: #5f6877;
  font-size: 16px;
  line-height: 30px;
  padding-left: 54px;
  position: relative
}

.page-header__title__description_winners:before {
  background-image: url(/build/i/46b2b7e0bf7e7bd4d5df.svg);
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 35px;
  left: 0;
  margin-top: -18px;
  position: absolute;
  top: 50%;
  width: 39px
}

.service-mini__competence {
  background-color: #daf5fa;
  border-radius: 10px;
  color: #5f6877;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-top: -20px;
  max-width: 585px;
  padding: 18px 35px
}

.service-mini__competence>div {
  padding-left: 60px;
  position: relative
}

.service-mini__competence>div:before {
  background-image: url(/build/i/cbae54da58643aafd079.svg);
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 45px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 45px
}

.page_251 .page-header__title__description {
  margin-top: 25px
}

.page_251 .service-mini__list {
  margin-top: 35px
}

.page_251 .page-header__title__button-wrapper {
  margin-top: 40px
}

.page-header_zygoma .h1 br {
  display: none
}

.page-header_zygoma .page-header__wallpaper-wrapper {
  right: 0
}

.page-header_zygoma .page-header__wallpaper-wrapper:after {
  content: none
}

.page-header_zygoma .page-header__wallpaper-wrapper__content {
  bottom: 25px;
  left: 50%;
  position: absolute;
  top: auto;
  transform: translateX(-50%)
}

.page-header_zygoma .breadcrumbs {
  margin-bottom: 20px
}

.page-header_zygoma .page-header__title {
  margin-top: 0;
  max-width: 620px
}

.page-header_zygoma .page-header__title__description {
  margin-top: 42px;
  max-width: 100%
}

.page-header_zygoma .page-header__title__button-wrapper {
  margin-top: 40px
}

.page-header__title__note {

  font-style: normal;
  font-weight: 500;
  margin-top: 28px;
  position: relative
}

.page-header__title__note>div {
  background-color: #daf5fa;
  border-radius: 15px;
  box-shadow: 0 10px 21px 2px rgba(70, 81, 86, .13);
  color: #5c6b6b;
  font-size: 22px;
  margin-left: 43px;
  max-width: 515px;
  min-height: 46px;
  overflow: hidden;
  padding: 10px 30px 10px 110px;
  position: relative
}

.page-header__title__note>div:before {
  background-color: #b9eff6;
  border-radius: 0 50px 50px 0;
  height: 100px;
  margin-top: -50px;
  top: 50%;
  width: 88px
}

.page-header__title__note:before,
.page-header__title__note>div:before {
  background-position: 50%;
  background-repeat: no-repeat;
  content: "";
  left: 0;
  position: absolute
}

.page-header__title__note:before {
  background-image: url(/build/i/54f2511846e1bb95754e.svg);
  background-size: contain;
  height: 48px;
  top: -17px;
  width: 24px
}

.page-header__title__note_zygoma>div:before {
  background-image: url(/build/i/321eacf80a72e9fb31e1.png);
  background-size: 59px auto
}

.page-header_allon4 .breadcrumbs {
  margin-bottom: 30px
}

.page-header_allon4 .page-header__title_wide {
  padding-bottom: 50px
}

.page-header_allon4 .h1_supplement {
  display: block;

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 8px
}

.page-header_allon4 .service-mini__list {
  margin-top: 28px
}

.page-header__feature {
  background-color: #00beb4;
  background-image: url(/build/i/644e06c9f8c33a2c9f67.png);
  background-position: right bottom -15px;
  background-repeat: no-repeat;
  border-radius: 10px;
  color: #fff;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin-top: 40px;
  max-width: 500px;
  min-height: 130px;
  overflow: hidden;
  padding: 15px 180px 15px 24px;
  position: relative
}

.page-header__feature__image {
  bottom: 0;
  position: absolute;
  right: 15px
}

.page-header__feature__image .progressive-image-wrapper {
  border-radius: 0;
  display: block
}

.page-header__feature__awards {
  margin-bottom: 18px
}

.page-header__feature__awards>*,
.page-header__feature__awards>.progressive-image-wrapper {
  margin-right: 14px
}

.page-header__feature__awards>:last-child {
  margin-right: 0
}

.page-header_vacancies_page .page-header__title {
  max-width: 630px
}

.page-header_vacancies_page .page-header__title__description {
  margin-top: 20px
}

.page-header_vacancies_page .vacancies-advantages {
  display: flex;
  margin: 30px -6px
}

.page-header_vacancies_page .vacancies-advantages>div {
  background: #eaf2f5;
  border-radius: 8px;
  color: #5f6877;
  font-size: 16px;
  margin: 0 6px;
  padding: 13px
}

.page-header_vacancies_page .vacancies-advantages>div span {
  display: block;

  font-style: normal;
  font-weight: 700
}

.page-header_vacancies_page .page-header__title__button-wrapper {
  margin-top: 35px
}

.page-header_vacancies_page .page-header__title__button-wrapper .button,
.page-header_vacancies_page .page-header__title__button-wrapper .slide-tab__label.active,
.page-header_vacancies_page .page-header__title__button-wrapper .tabs__labels.tabs__labels_buttons .tab__label.active,
.tabs__labels.tabs__labels_buttons .page-header_vacancies_page .page-header__title__button-wrapper .tab__label.active {
  border-radius: 100px;
  height: 66px;
  line-height: 66px;
  text-align: center;
  width: 276px
}

.page-header_vacancies_page .page-header__title__button-wrapper .button_orange {
  line-height: 68px;
  margin-right: 25px
}

@media screen and (max-width:1359px) {
  .page-header {
    min-height: inherit !important;
    min-height: auto !important
  }

  .page-header_adv {
    overflow: hidden
  }

  .page-header_wv .page-header__wallpaper-wrapper__content {
    left: 48px;
    top: 37%
  }

  .page-header_wv .page-header__wallpaper-wrapper__content>.progressive-image-wrapper {
    margin-bottom: 30px
  }

  .page-header_wv .page-header__wallpaper-wrapper__content_bottom {
    left: 130px;
    top: auto
  }

  .page-header__wallpaper__play {
    top: 34.5%
  }

  .page-header__wallpaper__play_bottom {
    left: 30px;
    top: auto
  }

  .page-header__wallpaper-wrapper {
    height: 42vw;
    right: -10vw;
    top: -10vw;
    width: 42vw
  }

  .page-header__wallpaper-wrapper:after {
    border: 4vw solid #f7f8f9;
    height: 36vw;
    margin-left: -18vw;
    margin-top: -18vw;
    width: 36vw
  }

  .page-header__wallpaper-wrapper_awards:after {
    border: 0;
    height: 100%;
    margin-left: 0;
    margin-top: 30px;
    width: 100%
  }

  .page-header__wallpaper-wrapper__content {
    left: 55px;
    top: 140px
  }

  .page-header__title__allure-phone {
    margin-top: 48px
  }

  .page-header__title-wrapper {
    max-width: 530px
  }

  .page-header__title {
    max-width: 570px
  }

  .page-header_warranty .button_orange {
    min-width: 220px
  }

  .page-header_warranty .page-header__title__button-quote>div:first-child {
    margin-bottom: 5px
  }

  .page-header_information-page .page-header__title br {
    display: none
  }

  .page_251 .page-header__wallpaper-wrapper {
    height: 52vw;
    max-height: 540px;
    max-width: 540px;
    width: 52vw
  }

  .page_251 .page-header__title__description {
    margin-top: 30px
  }

  .page_251 .service-mini__competence {
    font-size: 16px;
    line-height: 22px;
    max-width: 450px;
    padding: 20px 10px 20px 24px
  }

  .page_251 .service-mini__list {
    margin-top: 20px;
    padding-right: 85px
  }

  .page_251 .page-header__title__button-wrapper {
    margin-top: 30px
  }

  .page-header_zygoma .page-header__title {
    max-width: 570px
  }

  .page-header_zygoma .page-header__wallpaper-wrapper__content>.progressive-image-wrapper {
    margin-bottom: 0
  }

  .page-header__title__note>div {
    font-size: 16px;
    line-height: 22px;
    max-width: 430px
  }

  .wrapper-price__flex {
    margin-left: 20px
  }

  .wrapper-price__flex.page-header__title__button-wrapper__price {
    font-size: 13px
  }

  .tabs__labels.tabs__labels_buttons .wrapper-price__flex .tab__label,
  .wrapper-price__flex .link,
  .wrapper-price__flex .slide-tab__label,
  .wrapper-price__flex .tabs__labels.tabs__labels_buttons .tab__label,
  .wrapper-price__flex [data-js=tooltip] {
    font-size: 22px
  }

  .wrapper-price__flex>:nth-child(2) {
    padding-left: 40px
  }

  .wrapper-price__flex>:nth-child(2):before {
    left: 13px
  }

  .page-header_allon4 .h1_supplement {
    line-height: 26px;
    max-width: 450px
  }

  .page-header_allon4 .breadcrumbs {
    margin-bottom: 20px
  }

  .page-header_allon4 .service-mini__list>li {
    margin-bottom: 6px
  }

  .page-header_allon4 .page-header__title__description {
    margin-bottom: 25px;
    margin-top: 25px
  }

  .page-header_allon4 .page-header__title_wide {
    padding-bottom: 45px
  }

  .page-header__feature {
    font-size: 18px;
    margin-top: 28px;
    max-width: 445px;
    min-height: 122px;
    padding: 10px 150px 10px 22px
  }

  .page-header__feature__image {
    bottom: -20px;
    right: -24px
  }

  .page-header__feature__awards {
    margin-bottom: 10px
  }

  .page-header_vacancies_page .vacancies-advantages>div {
    font-size: 15px
  }

  .page-header_vacancies_page .page-header__title__button-wrapper .button,
  .page-header_vacancies_page .page-header__title__button-wrapper .slide-tab__label.active,
  .page-header_vacancies_page .page-header__title__button-wrapper .tabs__labels.tabs__labels_buttons .tab__label.active,
  .tabs__labels.tabs__labels_buttons .page-header_vacancies_page .page-header__title__button-wrapper .tab__label.active {
    border-radius: 70px;
    font-size: 12px;
    height: 48px;
    line-height: 46px;
    width: 210px
  }

  .page-header_vacancies_page .page-header__title__button-wrapper .button_orange {
    line-height: 48px;
    margin-right: 20px;
    width: 200px
  }
}

@media screen and (max-width:1024px) {
  .page-header_article .page-header__wallpaper-wrapper {
    height: auto
  }
}

@media screen and (max-width:1023px) {

  .page-header__title__button-wrapper .button,
  .page-header__title__button-wrapper .slide-tab__label.active,
  .page-header__title__button-wrapper .tabs__labels.tabs__labels_buttons .tab__label.active,
  .tabs__labels.tabs__labels_buttons .page-header__title__button-wrapper .tab__label.active {
    font-size: 12px
  }

  .page-header__title__button-wrapper__price {
    margin-left: 35px
  }

  .page-header__wallpaper__play {
    left: 36px;
    top: 32.5%
  }

  .page-header__wallpaper__play_bottom {
    bottom: 25px;
    left: 30px;
    top: auto
  }

  .page-header__wallpaper-wrapper {
    height: 45vw;
    right: -10vw;
    top: -10vw;
    width: 45vw
  }

  .page-header__wallpaper-wrapper:after {
    border: 30px solid #f7f8f9;
    height: 260px;
    margin-left: -130px;
    margin-top: -130px;
    width: 260px
  }

  .page-header__wallpaper-wrapper_awards:after {
    border: 0;
    height: 100%;
    margin-left: 0;
    margin-top: 15px;
    width: 100%
  }

  .page-header__wallpaper-wrapper__content {
    display: none
  }

  .page-header__title-wrapper {
    max-width: 485px
  }

  .page-header__title {
    max-width: 445px
  }

  .page-header_warranty .page-header__title__button-wrapper__price {
    display: none
  }

  .page_awards .service-mini__list {
    padding-right: 30px
  }

  .page-header_information-page .page-header__title {
    max-width: 480px
  }

  .service-mini__competence {
    font-size: 18px;
    line-height: 24px
  }

  .page_251 .page-header__wallpaper-wrapper {
    height: 45vw;
    width: 45vw
  }

  .page-header_zygoma .page-header__title {
    max-width: 445px
  }

  .page-header_zygoma .page-header__wallpaper-wrapper__content {
    display: block;
    max-width: 120px
  }

  .page-header_zygoma .page-header__wallpaper-wrapper__content>.progressive-image-wrapper {
    max-width: 100%
  }

  .page-header_zygoma .page-header__wallpaper-wrapper {
    height: 45vw;
    right: -10vw;
    width: 45vw
  }

  .page-header__title__note>div {
    font-size: 18px;
    line-height: 22px;
    min-height: 50px;
    padding-left: 90px
  }

  .page-header__title__note>div:before {
    background-size: 49px auto;
    width: 70px
  }

  .page_251 .page-header__title__button-wrapper {
    flex-wrap: wrap
  }

  .page_251 .page-header__title__button-wrapper .wrapper-price__flex {
    margin-left: 0;
    margin-top: 15px
  }

  .page-header_allon4 .service-mini__list {
    padding-right: 0
  }

  .page-header_allon4 .page-header__title__description {
    margin-bottom: 25px;
    margin-top: 25px
  }

  .page-header__feature {
    font-size: 18px;
    max-width: 400px;
    padding-right: 130px
  }

  .page-header_vacancies_page .vacancies-advantages>div {
    font-size: 14px
  }

  .page-header_vacancies_page .page-header__title__button-wrapper .button,
  .page-header_vacancies_page .page-header__title__button-wrapper .slide-tab__label.active,
  .page-header_vacancies_page .page-header__title__button-wrapper .tabs__labels.tabs__labels_buttons .tab__label.active,
  .tabs__labels.tabs__labels_buttons .page-header_vacancies_page .page-header__title__button-wrapper .tab__label.active {
    border-radius: 60px;
    height: 42px;
    line-height: 40px
  }

  .page-header_vacancies_page .page-header__title__button-wrapper .button_orange {
    line-height: 44px;
    margin-right: 15px;
    width: 165px
  }
}

@media screen and (max-width:767px) {
  .page-header__title {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    padding-bottom: 20px
  }

  .page-header__title>* {
    flex: 1 1 100%
  }

  .page-header__title .h1,
  .page-header__title .h1_supplement {
    margin-left: auto;
    margin-right: auto
  }

  .page_employee .page-header__title .page-header__wallpaper-wrapper {
    display: block;
    margin-top: 18px
  }

  .page-header__price {
    bottom: 3px;
    font-size: 20px;
    line-height: 22px;
    margin-top: 6px;
    padding: 4px 15px
  }

  .page-header__title__description {
    margin-left: auto;
    margin-right: auto
  }

  .page-header__title__allure {
    line-height: 36px
  }

  .page-header {
    padding-bottom: 5px;
    padding-top: 0;
    text-align: center
  }

  .page-header>.row,
  .page-header>.row_short {
    display: flex;
    flex-direction: column
  }

  .page-header__title__description {
    color: #898989;
    font-size: 14px;
    line-height: 20px;
    margin-top: 20px
  }

  .page-header__title__description .minus-list,
  .page-header__title__description .plus-list {
    text-align: left
  }

  .page-header__title__description .minus-list .title,
  .page-header__title__description .plus-list .title {
    line-height: 24px
  }

  .page-header__title__description .minus-list .list-toggle>li,
  .page-header__title__description .minus-list>li,
  .page-header__title__description .plus-list .list-toggle>li,
  .page-header__title__description .plus-list>li {
    color: #898989;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
    padding-left: 25px;
    position: relative
  }

  .page-header__title__description .minus-list .list-toggle>li:before,
  .page-header__title__description .minus-list>li:before,
  .page-header__title__description .plus-list .list-toggle>li:before,
  .page-header__title__description .plus-list>li:before {
    background-size: 10px 10px;
    height: 10px;
    top: 4px;
    width: 10px
  }

  .page-header__title__description .minus-list .list-toggle>li .text,
  .page-header__title__description .minus-list>li .text,
  .page-header__title__description .plus-list .list-toggle>li .text,
  .page-header__title__description .plus-list>li .text {
    color: #989ca6;
    display: block;

    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-top: 15px;
    max-width: 464px;
    text-transform: none
  }

  .page-header__title__allure-button {
    font-size: 12px;
    margin-bottom: 15px;
    margin-top: 30px;
    max-width: 250px
  }

  .page-header__wallpaper-wrapper {
    height: 286px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -46px;
    position: relative;
    right: inherit !important;
    right: auto !important;
    top: inherit !important;
    top: auto !important;
    width: 286px
  }

  .page-header__wallpaper-wrapper:after {
    border: 20px solid #f7f8f9;
    height: 218px;
    margin-left: -109px;
    margin-top: -109px;
    width: 218px
  }

  .page-header_article .page-header__wallpaper-wrapper {
    margin-bottom: 50px;
    margin-top: 0
  }

  .page-header_article .article__employee {
    margin-bottom: 40px
  }

  .page-header__title__allure-phone {
    display: none;
    max-width: 280px;
    padding-left: 80px;
    text-align: left
  }

  .circle-icon_phone:before {
    background-image: url(/build/i/7e672100e6f392a8712d.svg)
  }

  .page-header__title__allure-phone_mobile {
    display: inline-block;
    font-size: 12px;
    margin-bottom: 20px;
    margin-top: 30px;
    max-width: 290px
  }

  .page-header__title__allure-phone_mobile>span {
    align-items: center;
    display: flex;
    position: relative
  }

  .page-header__title__allure-phone_mobile>span:before {
    background-image: url(/build/i/7e672100e6f392a8712d.svg);
    background-size: contain;
    content: "";
    display: inline-block;
    height: 25px;
    margin-right: 15px;
    margin-top: -2px;
    width: 25px
  }

  .page-header+.pusher {
    margin-top: 30px !important
  }

  .page-header__wallpaper-wrapper {
    display: none
  }

  .page-header__title__button-wrapper {
    display: block;
    margin-top: 20px
  }

  .page-header__title__button-wrapper .button,
  .page-header__title__button-wrapper .slide-tab__label.active,
  .page-header__title__button-wrapper .tabs__labels.tabs__labels_buttons .tab__label.active,
  .tabs__labels.tabs__labels_buttons .page-header__title__button-wrapper .tab__label.active {
    font-size: 12px
  }

  .page-header__title__button-wrapper .page-header__title__button-wrapper__price {
    margin-left: auto;
    margin-right: auto;
    padding-top: 15px
  }

  .page_251 .page-header__title__button-wrapper .wrapper-price__flex {
    display: flex;
    justify-content: center;
    margin-top: 0
  }

  .page-header__title__button-wrapper__scroll-link {
    justify-content: center;
    margin-bottom: 10px;
    margin-left: 0;
    margin-top: 25px
  }

  .page-header_information-page .page-header__title__description_winners {
    padding-left: 0;
    padding-top: 50px
  }

  .page-header_information-page .page-header__title__description_winners:before {
    left: 50%;
    margin-left: -20px;
    margin-top: 0;
    top: 0
  }

  .service-mini__competence {
    margin: -5px 0 20px;
    text-align: left
  }

  .page_251 .page-header__title__description {
    margin-top: 20px
  }

  .page_251 .service-mini__competence {
    font-size: 13px;
    line-height: 18px;
    padding: 13px 12px
  }

  .page_251 .service-mini__list {
    margin-top: 20px;
    padding-right: 0
  }

  .page_251 .page-header__title__button-wrapper {
    margin-top: 0
  }

  .page-header_zygoma .page-header__title {
    max-width: 445px
  }

  .page-header_zygoma .page-header__wallpaper-wrapper__content {
    display: block;
    max-width: 120px
  }

  .page-header_zygoma .page-header__wallpaper-wrapper__content>.progressive-image-wrapper {
    max-width: 100%
  }

  .page-header_zygoma .page-header__wallpaper-wrapper {
    height: 45vw;
    right: -10vw;
    width: 45vw
  }

  .page-header_zygoma .page-header__title__description {
    margin-top: 15px
  }

  .page-header_zygoma .page-header__title__button-wrapper {
    margin-top: 5px
  }

  .page-header__title__note {
    margin-top: 20px
  }

  .page-header__title__note:before {
    height: 30px;
    left: 40px;
    top: -36px;
    width: 20px
  }

  .page-header__title__note>div {
    margin-left: 0;
    padding-left: 86px;
    padding-right: 20px;
    text-align: left
  }

  .page-header_allon4 .breadcrumbs-holder-wrapper {
    height: 65px
  }

  .page-header_allon4 .h1_supplement {
    font-size: 14px;
    line-height: 20px
  }

  .page-header_allon4 .service-mini__list {
    padding-right: 0
  }

  .page-header_allon4 .service-mini__list>li {
    margin-bottom: 12px
  }

  .page-header_allon4 .page-header__title__description {
    margin-bottom: 0;
    margin-top: 0
  }

  .page-header_allon4 .page-header__title_wide {
    padding-bottom: 40px
  }

  .page-header__feature {
    font-size: 15px;
    line-height: 18px;
    margin-top: 20px;
    padding-left: 16px;
    padding-right: 100px;
    text-align: left
  }

  .page-header__feature__image {
    bottom: -7px;
    max-width: 150px;
    right: -35px
  }

  .page-header__feature__image .progressive-image-wrapper {
    max-width: 100%
  }

  .page-header__feature__awards>.progressive-image-wrapper {
    margin-right: 5px
  }

  .page-header_vacancies_page+.pusher {
    margin-top: 60px !important
  }

  .page-header_vacancies_page .vacancies-advantages {
    justify-content: center
  }

  .page-header_vacancies_page .page-header__title__button-wrapper {
    margin-bottom: 10px;
    margin-top: 5px
  }
}

@media screen and (max-width:549px) {
  .page-header_vacancies_page .vacancies-advantages {
    flex-wrap: wrap;
    margin: 15px 0 10px
  }

  .page-header_vacancies_page .vacancies-advantages>div {
    margin: 10px 0 0;
    padding: 11px;
    width: 100%
  }

  .page-header_vacancies_page .vacancies-advantages>div span {
    display: inline
  }

  .page-header_vacancies_page .page-header__title__button-wrapper .button,
  .page-header_vacancies_page .page-header__title__button-wrapper .slide-tab__label.active,
  .page-header_vacancies_page .page-header__title__button-wrapper .tabs__labels.tabs__labels_buttons .tab__label.active,
  .tabs__labels.tabs__labels_buttons .page-header_vacancies_page .page-header__title__button-wrapper .tab__label.active {
    border-radius: 75px;
    display: block;
    font-size: 12px;
    height: 53px;
    line-height: 53px;
    margin: 12px auto 0;
    width: 210px
  }

  .page-header_vacancies_page .page-header__title__button-wrapper .button_orange {
    line-height: 55px
  }
}

@media screen and (max-width:445px) {
  .page-header__title {
    margin-left: 0 !important;
    margin-right: 0 !important
  }

  .service-mini__competence {
    font-size: 16px;
    line-height: 20px;
    padding: 15px
  }

  .page-header_zygoma .h1 br {
    display: block !important
  }

  .page-header__title__note {
    margin-left: auto;
    margin-right: auto;
    max-width: 320px
  }
}

@media screen and (max-width:414px) {
  .page-header_article .page-header__wallpaper-wrapper {
    height: 250px
  }
}

.breadcrumbs {
  color: #a5a5a5;
  margin-bottom: 49px;
  max-width: 580px
}

.breadcrumbs a+i {
  font-size: 12px;
  font-style: normal;
  padding-left: 2px;
  padding-right: 2px
}

.breadcrumbs__link {
  font-size: 14px;
  line-height: 28px
}

.breadcrumbs__link_current {
  color: #a5a5a5
}

@media screen and (max-width:1363px) {
  .breadcrumbs {
    max-width: 485px
  }
}

@media screen and (max-width:767px) {
  .breadcrumbs {
    margin: 0 auto
  }

  .breadcrumbs>:first-child {
    margin-left: 5px
  }

  .breadcrumbs>:last-child {
    margin-right: 20px
  }

  .breadcrumbs-wrapper {
    position: relative
  }

  .breadcrumbs-wrapper:before {
    left: -15px
  }

  .breadcrumbs-wrapper:after,
  .breadcrumbs-wrapper:before {
    background-color: #fff;
    content: "";
    display: block;
    height: 70px;
    position: absolute;
    top: 0;
    width: 7px;
    z-index: 2
  }

  .breadcrumbs-wrapper:after {
    right: -15px
  }

  .breadcrumbs-wrapper.only-left .breadcrumbs-holder-wrapper:after,
  .breadcrumbs-wrapper.only-right .breadcrumbs-holder-wrapper:before {
    opacity: 0;
    transition: opacity .2s ease-out
  }

  .breadcrumbs-holder-wrapper {
    height: 70px;
    margin-left: -15px;
    margin-right: -15px;
    overflow: hidden;
    position: relative;
    z-index: 1
  }

  .breadcrumbs-holder-wrapper:before {
    left: -74px
  }

  .breadcrumbs-holder-wrapper:after,
  .breadcrumbs-holder-wrapper:before {
    background-image: radial-gradient(circle 45px at center, #000 0, transparent 100%);
    content: "";
    display: block;
    height: 89px;
    opacity: 1;
    position: absolute;
    top: -9px;
    transition: opacity .2s ease-in;
    width: 89px;
    z-index: 1
  }

  .breadcrumbs-holder-wrapper:after {
    right: -74px
  }

  .breadcrumbs-holder {
    overflow-x: auto;
    padding-bottom: 60px;
    padding-top: 23px;
    white-space: nowrap
  }

  .breadcrumbs-holder.scrolling .breadcrumbs {
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px
  }
}

.info-quote__grid>* {
  flex: 1 1 50%
}

.info-quote__grid>:first-child {
  padding-right: 55px
}

.info-quote__grid_left>:first-child {
  order: 2;
  padding-left: 85px;
  padding-right: 0
}

.info-quote__grid_left>:last-child {
  order: 1
}

.info-quote__title {
  color: #333638;
  font-size: 46px;
  line-height: 52px;
  margin-bottom: 48px;
  margin-top: 25px
}

.info-quote__title,
.info-quote__title span {

  font-style: normal;
  font-weight: 400
}

.info-quote__text {
  line-height: 30px
}

.info-quote__text p {
  margin-bottom: 30px
}

.info-quote__text strong {

  font-style: normal;
  font-weight: 500
}

.info-quote__quote-block {
  background-color: #c2e9f3;
  border-radius: 10px;
  box-shadow: 0 10px 29px rgba(70, 81, 86, .18);
  float: right;
  height: 468px;
  max-width: 600px;
  padding: 62px 60px;
  position: relative;
  width: 100%
}

.info-quote__quote-block:before {
  background-image: url(/build/i/289614f9869ba4b363d5.svg);
  background-size: 47px 47px;
  content: "";
  display: block;
  height: 47px;
  left: -6px;
  opacity: .5;
  position: relative;
  top: -15px;
  width: 47px
}

.info-quote__quote-block .progressive-image-bg {
  right: -150px;
  top: 0;
  width: 540px
}

.info-quote__quote {
  color: #5f6877;

  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: 28px;
  max-width: 290px;
  position: relative;
  z-index: 1
}

.info-quote__play {
  display: inline-block;
  height: 60px;
  left: 0;
  position: absolute;
  width: 60px
}

.info-quote__play .circle-icon_play:before {
  left: 50%;
  margin-left: -10px;
  margin-top: -10px;
  top: 50%
}

.info-quote__video {
  align-items: center;
  display: flex;
  height: 60px;
  margin-top: 15px;
  padding-left: 73px;
  position: relative
}

.info-quote__video span {
  color: #fff;
  font-size: 14px
}

.info-quote__person {
  background-color: #fff;
  border-radius: 10px;
  bottom: 62px;
  color: #a5a5a5;
  font-size: 14px;
  line-height: 24px;
  max-width: 325px;
  padding: 14px 22px 12px;
  position: absolute
}

.info_quote__under-image-text {
  color: #5f6877;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  position: relative;
  top: 39px
}

@media screen and (max-width:1359px) {
  .info-quote__title {
    font-size: 40px;
    line-height: 44px
  }

  .info-quote__grid:not(.info-quote__grid_left)>:first-child {
    padding-right: 15px
  }

  .info-quote__grid_left>:first-child {
    padding-left: 0
  }

  .info-quote__grid_left .info-quote__quote-block {
    float: left
  }

  .info-quote__quote-block {
    height: auto;
    padding: 48px 48px 325px
  }

  .info-quote__quote-block .progressive-image-bg {
    bottom: 45px;
    height: 370px;
    left: 50%;
    margin-left: -150px;
    right: inherit !important;
    right: auto !important;
    top: inherit !important;
    top: auto !important;
    width: 500px
  }

  .info-quote__person {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding: 20px 48px;
    width: 100%
  }
}

@media screen and (max-width:1199px) {
  .info-quote__quote-block {
    max-width: 500px
  }

  .info-quote__quote-block .progressive-image-bg {
    background-size: contain;
    bottom: 88px;
    height: 230px;
    left: 50%;
    margin-left: 0;
    max-width: 80%;
    transform: translateX(-50%)
  }

  .info-quote__grid:not(.info-quote__grid_left)>:first-child {
    padding-right: 25px
  }
}

@media screen and (max-width:1023px) {
  .info-quote__grid:not(.info-quote__grid_left)>* {
    max-width: 100%
  }

  .info-quote__grid:not(.info-quote__grid_left)>:first-child {
    padding-right: 0
  }

  .info-quote .minus-list,
  .info-quote .plus-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -26px;
    margin-right: -26px
  }

  .info-quote .minus-list>*,
  .info-quote .plus-list>* {
    flex: 1 1 50%;
    flex-basis: calc(50% - 52px);
    margin-left: 26px;
    margin-right: 26px
  }

  .info-quote__title {
    font-size: 36px;
    margin-bottom: 20px
  }

  .info-quote__title br {
    display: none
  }

  .info-quote__grid>* {
    flex-basis: calc(100% - 24px)
  }

  .info-quote__grid>:last-child {
    margin-top: 30px
  }

  .info-quote__grid_left>:first-child {
    order: 1;
    padding-left: 0
  }

  .info-quote__grid_left>:last-child {
    order: 2
  }

  .info-quote__grid_left .info-quote__quote-block {
    float: none
  }

  .info-quote__quote-block {
    background-position: bottom right -150px;
    background-size: inherit !important;
    background-size: initial !important;
    border-radius: 0;
    float: none;
    height: 468px;
    margin-left: -26px;
    margin-right: -26px;
    max-width: none;
    padding: 50px 60px;
    width: auto
  }

  .info-quote__quote-block .progressive-image-bg {
    bottom: 0;
    height: 400px;
    left: inherit !important;
    left: auto !important;
    margin-left: 0 !important;
    right: 0 !important;
    top: inherit !important;
    top: auto !important;
    transform: none
  }

  .info-quote__person {
    border-radius: 10px;
    bottom: 62px;
    left: 48px;
    max-width: 325px;
    padding: 14px 22px 12px
  }
}

@media screen and (max-width:767px) {

  .info-quote .minus-list>*,
  .info-quote .plus-list>* {
    flex-basis: 100%
  }

  .info-quote__title {
    font-size: 30px;
    line-height: 30px
  }

  .info-quote__grid {
    margin-left: -15px;
    margin-right: -15px
  }

  .info-quote__grid>* {
    flex-basis: calc(100% - 30px);
    margin-left: 15px;
    margin-right: 15px
  }

  .info-quote__quote-block {
    background-position: bottom 62px center;
    background-size: 300px auto !important;
    height: auto;
    padding: 48px 48px 280px;
    width: auto
  }

  .info-quote__quote-block .progressive-image-bg {
    bottom: 64px;
    right: -20px !important
  }

  .info-quote__person {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding: 20px 48px;
    width: 100%
  }
}

@media screen and (max-width:620px) {
  .info-quote__quote-block .progressive-image-bg {
    height: 200px;
    left: 50% !important;
    right: auto !important;
    transform: translateX(-50%)
  }

  .info-quote__quote {
    font-size: 16px;
    max-width: 400px
  }
}

@media screen and (max-width:500px) {
  .info-quote__quote-block .progressive-image-bg {
    bottom: 80px
  }
}

@media screen and (max-width:361px) {
  .info-quote__quote-block {
    padding: 48px 48px 300px
  }

  .info-quote__quote-block .progressive-image-bg {
    bottom: 100px;
    height: 200px;
    width: 330px
  }

  .info-quote__person {
    min-height: 100px
  }
}

.icon-links {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -13px
}

.icon-links__item {
  margin-bottom: 26px;
  padding: 0 13px;
  width: 33.33333%
}

.icon-links__item>div {
  align-items: center;
  background-color: #fff;
  background-image: url(/build/i/a12ea126b0c8d4fb5b9b.png);
  background-position: left -44px center;
  background-size: 124px;
  border-radius: 10px;
  box-shadow: 0 10px 29px rgba(70, 81, 86, .18);
  display: flex;
  flex-wrap: wrap;
  height: auto;
  min-height: 120px;
  overflow: hidden;
  padding: 0 44px 0 104px;
  position: relative;
  z-index: 1
}

.icon-links__item>div:before {
  background-color: #faaa4c;
  background-position: 50%;
  border-radius: 50%;
  content: "";
  display: block;
  height: 56px;
  left: -9px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: transform .2s ease-in;
  width: 56px
}

.icon-links__item>div:after {
  content: "";
  font-size: 0;
  min-height: inherit
}

.icon-links__item>div img,
.icon-links__item>div svg {
  display: block;
  height: 40px;
  left: 0;
  position: absolute;
  transform: translateY(-50%);
  width: 40px
}

.icon-links__item>div:hover:before {
  transform: translateY(-50%) scale(2.21429)
}

.icon-links__item:last-child {
  margin-bottom: 0
}

.icon-links__title {
  color: #333638;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  width: 100%;
  z-index: 10
}

.icon-links_single {
  margin-left: 0;
  margin-right: 0;
  margin-top: 30px
}

.icon-links_single .icon-links__item {
  flex-basis: 100%;
  max-width: 392px;
  padding: 0
}

.icon-links_single .icon-links__item .icon-links__image {
  transition: .2s ease
}

.icon-links_single .icon-links__item:hover .icon-links__image {
  transform: translateY(-50%) scale(1.1)
}

.icon-links_single .progressive-image-wrapper img {
  height: auto;
  position: relative;
  transform: none;
  width: auto
}

.icon-links__item_with-image>div {
  background-image: none;
  padding-left: 164px
}

.icon-links__image,
.icon-links__item_with-image:hover>div:before {
  transform: translateY(-50%)
}

.icon-links__image {
  background-color: #f4f4f4;
  border-radius: 50%;
  height: 239px;
  justify-content: flex-end;
  left: -101px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  width: 239px
}

@media screen and (max-width:1199px) {
  .icon-links__item {
    width: 50%
  }
}

@media screen and (max-width:1023px) {
  .icon-links__item>div {
    min-height: 86px;
    padding: 0 20px 0 104px
  }

  .icon-links__title {
    font-size: 15px;
    line-height: 22px
  }

  .icon-links_single {
    display: block
  }

  .icon-links_single .icon-links__item {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
    width: 100%
  }

  .icon-links_single .icon-links__item>div {
    padding-left: 160px
  }
}

@media screen and (max-width:767px) {
  .icon-links_single .icon-links__item {
    margin-top: 26px
  }

  .icon-links_single .icon-links__item>div {
    padding-left: 67px
  }

  .icon-links_single .icon-links__item>div:before {
    content: none
  }

  .icon-links_single .icon-links__image {
    border-radius: 50%;
    height: 56px;
    left: -8px;
    width: 56px
  }

  .icon-links_single .icon-links__image .progressive-image-wrapper {
    margin-left: 5px;
    max-width: 100%;
    transform: scale(1.28)
  }

  .icon-links_single .icon-links__image .progressive-image-wrapper img {
    width: 100%
  }
}

@media screen and (max-width:667px) {
  .icon-links {
    display: block;
    margin: initial
  }

  .icon-links__item {
    display: block;
    margin-bottom: 8px;
    padding: initial;
    width: 100%
  }

  .icon-links__item>div {
    background-image: none;
    padding: 0 30px 0 67px
  }
}

.tag-links {
  background-color: #fff;
  border: 10px solid #e9ecee;
  border-radius: 10px;
  padding: 42px 47px 27px;
  position: relative
}

.tag-links_long .tag-links__inner {
  padding-right: 100px
}

.tag-links_long .tag-links__button {
  display: block
}

.tag-links_long.active .tag-links__button {
  transform: rotate(180deg)
}

.tag-links__list {
  height: 51px;
  overflow: hidden;
  transition: height .2s ease-in
}

.tag-links__list>div {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap
}

.tag-links__list>div a {
  align-items: center;
  background-color: #fff;
  border: 1px solid #e0e3e4;
  border-radius: 18px;
  color: #00beb4;
  display: inline-flex;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 15px;
  margin-right: 15px;
  min-height: 36px;
  padding: 4px 22px
}

.tag-links__list>div a:last-child {
  margin-right: 0
}

.tag-links__list>div a:hover {
  border: 1px solid #00beb4;
  color: #333638
}

.tag-links__button {
  background-color: #00beb3;
  border-radius: 50%;
  display: none;
  height: 36px;
  position: absolute;
  right: 47px;
  top: 42px;
  transition: background-color .2s, transform .2s;
  width: 36px
}

.tag-links__button svg {
  width: 39%
}

.tag-links__button:hover {
  background-color: #00a69d
}

@media screen and (max-width:1023px) {
  .tag-links {
    padding: 32px 30px 17px
  }

  .tag-links_long .tag-links__inner {
    padding-right: 50px
  }

  .tag-links__button {
    right: 30px;
    top: 31px
  }
}

@media screen and (max-width:567px) {
  .tag-links {
    padding: 32px 15px 17px
  }

  .tag-links_long .tag-links__inner {
    padding-right: 0
  }

  .tag-links__button {
    margin: 0 auto;
    position: relative;
    right: auto;
    top: auto
  }
}

.minus-list,
.plus-list {
  line-height: 28px;
  list-style: none;
  padding-left: 0
}

.minus-list .title,
.plus-list .title {
  color: #5f6877;

  font-style: normal;
  font-weight: 500;
  text-transform: uppercase
}

.minus-list .list-toggle>li,
.minus-list>li,
.plus-list .list-toggle>li,
.plus-list>li {
  margin-bottom: 9px;
  padding-left: 31px;
  position: relative
}

.minus-list .list-toggle>li:before,
.minus-list>li:before,
.plus-list .list-toggle>li:before,
.plus-list>li:before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAxNiI+PGxpbmVhckdyYWRpZW50IGlkPSJhIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjQuODUiIHkxPSI4IiB4Mj0iMTEuMTQ5IiB5Mj0iOCI+PHN0b3Agb2Zmc2V0PSIwIiBzdG9wLWNvbG9yPSIjZDQ2ZjAwIi8+PHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjZmZiYTAwIi8+PC9saW5lYXJHcmFkaWVudD48cGF0aCBkPSJNOCAxNmMtMS43IDAtMy4xLTEuNC0zLjEtMy4xVjMuMUM0LjggMS40IDYuMyAwIDggMHMzLjEgMS40IDMuMSAzLjF2OS43YzAgMS44LTEuNCAzLjItMy4xIDMuMnoiIGZpbGw9InVybCgjYSkiLz48bGluZWFyR3JhZGllbnQgaWQ9ImIiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4MT0iLS4wMDEiIHkxPSI4IiB4Mj0iMTYuMDAxIiB5Mj0iOCI+PHN0b3Agb2Zmc2V0PSIwIiBzdG9wLWNvbG9yPSIjZDQ2ZjAwIi8+PHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjZmZiYTAwIi8+PC9saW5lYXJHcmFkaWVudD48cGF0aCBkPSJNMTIuOSAxMS4xSDMuMUMxLjQgMTEuMSAwIDkuNyAwIDhzMS40LTMuMSAzLjEtMy4xaDkuN0MxNC42IDQuOSAxNiA2LjMgMTYgOHMtMS40IDMuMS0zLjEgMy4xeiIgZmlsbD0idXJsKCNiKSIvPjwvc3ZnPg==);
  content: "";
  display: block;
  height: 16px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 16px
}

.minus-list .list-toggle>li.minus:before,
.minus-list>li.minus:before,
.plus-list .list-toggle>li.minus:before,
.plus-list>li.minus:before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAxNiI+PGxpbmVhckdyYWRpZW50IGlkPSJhIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeTE9IjgiIHgyPSIxNiIgeTI9IjgiPjxzdG9wIG9mZnNldD0iMCIgc3RvcC1jb2xvcj0iIzcyM2U5NCIvPjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iIzUxYTRmZiIvPjwvbGluZWFyR3JhZGllbnQ+PHBhdGggZD0iTTEyLjkgMTFIMy4xQzEuNCAxMSAwIDkuNyAwIDhzMS40LTMgMy4xLTNoOS43QzE0LjYgNSAxNiA2LjMgMTYgOHMtMS40IDMtMy4xIDN6IiBmaWxsPSJ1cmwoI2EpIi8+PC9zdmc+)
}

.minus-list .list-toggle>li .text,
.minus-list>li .text,
.plus-list .list-toggle>li .text,
.plus-list>li .text {
  color: #989ca6;
  display: block;

  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  max-width: 464px;
  text-transform: none
}

.minus-list .list-toggle>li>a,
.minus-list>li>a,
.plus-list .list-toggle>li>a,
.plus-list>li>a {
  color: #00ada4
}

.minus-list_small .title,
.plus-list_small .title {
  line-height: 24px
}

.minus-list_small .list-toggle>li,
.minus-list_small>li,
.plus-list_small .list-toggle>li,
.plus-list_small>li {
  color: #898989;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
  padding-left: 25px;
  position: relative
}

.minus-list_small .list-toggle>li:before,
.minus-list_small>li:before,
.plus-list_small .list-toggle>li:before,
.plus-list_small>li:before {
  background-size: 10px 10px;
  height: 10px;
  top: 4px;
  width: 10px
}

.minus-list_small .list-toggle>li .text,
.minus-list_small>li .text,
.plus-list_small .list-toggle>li .text,
.plus-list_small>li .text {
  color: #989ca6;
  display: block;

  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 15px;
  max-width: 464px;
  text-transform: none
}

.minus-list_flex .list-toggle>li,
.minus-list_flex>li,
.plus-list_flex .list-toggle>li,
.plus-list_flex>li {
  margin-bottom: 30px;
  padding-right: 30px
}

.minus-list_flex .list-toggle>li .text,
.minus-list_flex>li .text,
.plus-list_flex .list-toggle>li .text,
.plus-list_flex>li .text {
  max-width: 540px
}

.plus-list_compact>li {
  margin-bottom: 19px
}

@media screen and (max-width:767px) {

  .minus-list_flex>*,
  .plus-list_flex>* {
    flex: 1 0 100%
  }

  .minus-list_flex .list-toggle>li,
  .minus-list_flex>li,
  .plus-list_flex .list-toggle>li,
  .plus-list_flex>li {
    padding-right: 0
  }

  .minus-list_flex .list-toggle>li .text,
  .minus-list_flex>li .text,
  .plus-list_flex .list-toggle>li .text,
  .plus-list_flex>li .text {
    max-width: none
  }

  .minus-list>li,
  .plus-list>li {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 14px
  }

  .minus-list>li:before,
  .plus-list>li:before {
    top: 1px
  }
}

.plus-list-with-link {
  background-color: #00bfb5;
  padding: 55px 0 35px
}

.plus-list-with-link h2 {
  color: #fff
}

.plus-list-with-link .minus-list,
.plus-list-with-link .plus-list {
  margin-top: 45px
}

.plus-list-with-link .plus-list__list {
  display: block;
  margin-bottom: 20px
}

.plus-list-with-link .title {
  border-bottom: 1px dashed hsla(0, 0%, 100%, .6);
  color: #fff
}

.plus-list-with-link .text {
  color: #ddf3f1 !important
}

.plus-list-advantages {
  padding: 35px 0 40px
}

.plus-list-advantages__grid {
  align-items: center;
  display: flex;
  justify-content: space-between
}

.plus-list-advantages__title {
  color: #2e3335;

  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  width: 270px
}

.plus-list-advantage {
  font-size: 15px;
  line-height: 22px;
  max-width: 280px;
  padding-left: 36px;
  position: relative;
  width: 100%
}

.plus-list-advantage a {
  color: #5f6877
}

.plus-list-advantage span {
  border-bottom: 1px dashed rgba(95, 104, 119, .6)
}

.plus-list-advantage:before {
  background-image: url(/build/i/add7465a8509bad027bf.svg);
  content: "";
  height: 18px;
  left: 0;
  margin-top: -9px;
  position: absolute;
  top: 50%;
  width: 22px
}

@media screen and (max-width:1300px) {
  .plus-list-advantages__grid {
    flex-wrap: wrap
  }

  .plus-list-advantages__title {
    margin-bottom: 30px;
    text-align: center;
    width: 100%
  }

  .plus-list-advantages {
    padding: 35px 0 50px
  }

  .plus-list-advantages+.divider {
    margin-top: 0 !important
  }
}

@media screen and (max-width:950px) {
  .plus-list-advantage {
    max-width: 230px
  }
}

@media screen and (max-width:767px) {
  .plus-list-advantage {
    margin-bottom: 35px;
    max-width: 100%
  }

  .plus-list-advantage:last-child {
    margin-bottom: 0
  }
}

@media screen and (max-width:480px) {

  .plus-list-with-link .minus-list>li,
  .plus-list-with-link .plus-list>li {
    margin-bottom: 30px
  }

  .plus-list-with-link .plus-list__list {
    margin-bottom: 5px
  }

  .plus-list-advantages__title {
    font-size: 24px;
    line-height: 30px
  }
}

.minus-list .list-toggle>li:before,
.minus-list>li:before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAxNiI+PGxpbmVhckdyYWRpZW50IGlkPSJhIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeTE9IjgiIHgyPSIxNiIgeTI9IjgiPjxzdG9wIG9mZnNldD0iMCIgc3RvcC1jb2xvcj0iIzcyM2U5NCIvPjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iIzUxYTRmZiIvPjwvbGluZWFyR3JhZGllbnQ+PHBhdGggZD0iTTEyLjkgMTFIMy4xQzEuNCAxMSAwIDkuNyAwIDhzMS40LTMgMy4xLTNoOS43QzE0LjYgNSAxNiA2LjMgMTYgOHMtMS40IDMtMy4xIDN6IiBmaWxsPSJ1cmwoI2EpIi8+PC9zdmc+)
}

.minus-list .list-toggle>li.plus:before,
.minus-list>li.plus:before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAxNiI+PGxpbmVhckdyYWRpZW50IGlkPSJhIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjQuODUiIHkxPSI4IiB4Mj0iMTEuMTQ5IiB5Mj0iOCI+PHN0b3Agb2Zmc2V0PSIwIiBzdG9wLWNvbG9yPSIjZDQ2ZjAwIi8+PHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjZmZiYTAwIi8+PC9saW5lYXJHcmFkaWVudD48cGF0aCBkPSJNOCAxNmMtMS43IDAtMy4xLTEuNC0zLjEtMy4xVjMuMUM0LjggMS40IDYuMyAwIDggMHMzLjEgMS40IDMuMSAzLjF2OS43YzAgMS44LTEuNCAzLjItMy4xIDMuMnoiIGZpbGw9InVybCgjYSkiLz48bGluZWFyR3JhZGllbnQgaWQ9ImIiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4MT0iLS4wMDEiIHkxPSI4IiB4Mj0iMTYuMDAxIiB5Mj0iOCI+PHN0b3Agb2Zmc2V0PSIwIiBzdG9wLWNvbG9yPSIjZDQ2ZjAwIi8+PHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjZmZiYTAwIi8+PC9saW5lYXJHcmFkaWVudD48cGF0aCBkPSJNMTIuOSAxMS4xSDMuMUMxLjQgMTEuMSAwIDkuNyAwIDhzMS40LTMuMSAzLjEtMy4xaDkuN0MxNC42IDQuOSAxNiA2LjMgMTYgOHMtMS40IDMuMS0zLjEgMy4xeiIgZmlsbD0idXJsKCNiKSIvPjwvc3ZnPg==)
}

@media screen and (max-width:767px) {
  .minus-list_flex>* {
    flex: 1 0 100%
  }

  .minus-list_flex .list-toggle>li,
  .minus-list_flex>li {
    padding-right: 0
  }

  .minus-list_flex .list-toggle>li .text,
  .minus-list_flex>li .text {
    max-width: none
  }
}

.count-list {
  list-style: none;
  padding-left: 0
}

.count-list .list-toggle>li,
.count-list>li {
  counter-increment: count-list;
  margin-bottom: 23px;
  padding-left: 0;
  position: relative
}

.count-list .title {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 24px
}

.count-list .title:before {
  content: counter(count-list) "."
}

.count-list .text {
  color: #989ca6;
  line-height: 28px
}

.diff-icons-list {
  list-style: none;
  padding-left: 0
}

.diff-icons-list .list-toggle>li,
.diff-icons-list>li {
  color: #819292;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 27px;
  padding-left: 30px;
  position: relative
}

.diff-icons-list .list-toggle>li:before,
.diff-icons-list>li:before {
  content: "";
  display: block;
  height: 16px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 16px
}

.diff-icons-list .list-toggle>li.li-clock:before,
.diff-icons-list>li.li-clock:before {
  background-image: url(/build/i/8e4e1686c6c76efae7cc.svg)
}

.diff-icons-list .list-toggle>li.li-face:before,
.diff-icons-list>li.li-face:before {
  background-image: url(/build/i/09c87db841476636cf1a.svg)
}

.diff-icons-list .list-toggle>li.li-daw:before,
.diff-icons-list>li.li-daw:before {
  background-image: url(/build/i/c1efc79d47a430d1eab8.svg);
  height: 18px;
  width: 18px
}

.numeric-list {
  line-height: 28px;
  list-style: none;
  padding-left: 0
}

.numeric-list .list-toggle>li,
.numeric-list>li {
  counter-increment: numeric-list;
  height: 30px;
  line-height: 32px;
  margin-bottom: 12px;
  padding-left: 55px;
  position: relative
}

.numeric-list .list-toggle>li:nth-child(-n+3):before,
.numeric-list>li:nth-child(-n+3):before {
  background-color: #faaa4c
}

.numeric-list .list-toggle>li:before,
.numeric-list>li:before {
  background-color: #b7c0c4;
  border-radius: 50%;
  color: #fff;
  content: counter(numeric-list);

  font-style: normal;
  font-weight: 500;
  height: 30px;
  left: 0;
  line-height: 30px;
  padding-top: 2px;
  position: absolute;
  width: 30px
}

.dash-list {
  color: #989ca6;
  font-size: 14px;
  line-height: 22px;
  list-style: none;
  padding-left: 15px
}

.dash-list .list-toggle>li,
.dash-list>li {
  margin-bottom: 10px;
  position: relative
}

.dash-list .list-toggle>li:before,
.dash-list>li:before {
  content: "â€”";
  display: block;
  left: -16px;
  position: absolute;
  top: -1px
}

.dash-list+h3 {
  margin-top: 30px
}

.dash-list_dark {
  color: #5f6877
}

.sign-up-now-wrapper {
  margin-bottom: 10px
}

.attainment-image-slider-wrapper_allon4 .sign-up-now-wrapper .attainment-image-slider,
.offer_standard .sign-up-now-wrapper .offer__card:before,
.sign-up-now,
.sign-up-now-wrapper .appointment__inner,
.sign-up-now-wrapper .attainment-image-slider-wrapper_allon4 .attainment-image-slider,
.sign-up-now-wrapper .bg_dots,
.sign-up-now-wrapper .cards-with-text:before,
.sign-up-now-wrapper .director-quote__right:before,
.sign-up-now-wrapper .is-suitable.bg_dots-half:before,
.sign-up-now-wrapper .main-page-header__badge:before,
.sign-up-now-wrapper .offer_standard .offer__card:before,
.sign-up-now-wrapper .payment-form,
.sign-up-now-wrapper .through-block-short .through-block_hold-prices,
.sign-up-now-wrapper .with-image-slider-wrapper_dots,
.sign-up-now-wrapper .works-slider__item,
.through-block-short .sign-up-now-wrapper .through-block_hold-prices {
  border-radius: 10px
}

.sign-up-now {
  box-shadow: 0 10px 29px rgba(70, 81, 86, .18);
  min-height: 495px;
  overflow: hidden;
  padding: 82px 100px 30px 496px;
  position: relative
}

.sign-up-now__bg {
  border-radius: 50%;
  height: 850px;
  left: -430px;
  overflow: hidden;
  position: absolute;
  top: -154px;
  width: 850px
}

.sign-up-now__bg>div {
  height: 542px;
  margin-top: -271px;
  position: absolute;
  right: 0;
  top: 50%;
  width: 420px
}

.sign-up-now__title {
  color: #3c4143;
  font-size: 36px;
  font-weight: 500
}

.sign-up-now__title,
.sign-up-now__title span {

  font-style: normal
}

.sign-up-now__title span {
  display: block;
  font-size: 30px;
  font-weight: 300
}

.sign-up-now__title [data-js=tooltip] {
  color: #3c4143;
  display: inline
}

.sign-up-now__title [data-js=tooltip]>span {
  border-bottom: 1px dashed #3c4143;
  display: inline;

  font-style: normal;
  font-weight: 500
}

.sign-up-now__title [data-js=tooltip]>span:hover {
  border-bottom: 1px dashed transparent
}

.sign-up-now__phone {
  margin-top: 57px
}

.sign-up-now__phone__link {
  color: #3c4143;

  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin-left: 34px
}

.sign-up-now__phone__link:hover {
  color: #00ada4
}

.sign-up-now__phone__link .phone>span {
  color: #819292
}

.sign-up-now__phone__link .notice {
  color: #819292;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-left: 17px
}

.sign-up-now__phone__link .notice strong {

  font-style: normal;
  font-weight: 500
}

.sign-up-now__phone__link br {
  display: none
}

.sign-up-now__notes {
  margin-top: 59px
}

.sign-up-now__notes_zygoma {
  max-width: 600px
}

.sign-up-now_changeling {
  padding-bottom: 40px !important
}

.sign-up-now_changeling .sign-up-now__title {
  font-size: 30px
}

.sign-up-now_changeling .sign-up-now__title span {
  font-size: 26px
}

.sign-up-now_changeling .sign-up-now__title [data-js=tooltip],
.sign-up-now_changeling .sign-up-now__title [data-js=tooltip]>span {
  font-size: 30px
}

.sign-up-now_changeling .sign-up-now__phone {
  margin-top: 37px
}

.sign-up-now_changeling .sign-up-now__notes {
  margin-top: 35px
}

@media screen and (max-width:1359px) {
  .page_251 .sign-up-now-wrapper+.pusher {
    margin-top: 60px !important
  }
}

@media screen and (max-width:1320px) {
  .sign-up-now__phone__link .notice {
    margin-left: 0
  }

  .sign-up-now__phone__link br {
    display: block
  }
}

@media screen and (max-width:1270px) {
  .sign-up-now_changeling {
    padding-top: 50px
  }

  .sign-up-now_changeling .sign-up-now__title {
    font-size: 24px
  }

  .sign-up-now_changeling .sign-up-now__title span {
    font-size: 22px
  }

  .sign-up-now_changeling .sign-up-now__title [data-js=tooltip],
  .sign-up-now_changeling .sign-up-now__title [data-js=tooltip]>span {
    font-size: 24px
  }
}

@media screen and (max-width:1215px) {
  .sign-up-now {
    padding-left: 400px;
    padding-right: 53px
  }

  .sign-up-now__bg {
    left: -500px
  }

  .sign-up-now__title {
    font-size: 30px
  }

  .sign-up-now__title span {
    font-size: 24px
  }

  .sign-up-now_changeling {
    min-height: 455px;
    padding-left: 390px;
    padding-right: 35px;
    padding-top: 70px
  }

  .sign-up-now_changeling .sign-up-now__bg {
    top: -185px
  }

  .sign-up-now_changeling .sign-up-now__title {
    font-size: 24px;
    line-height: 32px
  }

  .sign-up-now_changeling .sign-up-now__title span {
    font-size: 20px
  }

  .sign-up-now_changeling .sign-up-now__title [data-js=tooltip],
  .sign-up-now_changeling .sign-up-now__title [data-js=tooltip]>span {
    font-size: 24px
  }

  .sign-up-now_changeling .sign-up-now__phone {
    margin-top: 30px
  }
}

@media screen and (max-width:1023px) {
  .sign-up-now-wrapper {
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0
  }

  .sign-up-now {
    border-radius: 0;
    padding-left: 50px;
    padding-right: 50px
  }

  .sign-up-now__bg {
    display: none
  }

  .sign-up-now_changeling {
    padding-top: 60px
  }

  .sign-up-now_changeling .sign-up-now__notes,
  .sign-up-now_changeling .sign-up-now__phone {
    margin-top: 25px
  }

  .sign-up-now__phone__link .notice {
    margin-left: 17px
  }

  .sign-up-now__phone__link br {
    display: none
  }
}

@media screen and (max-width:700px) {
  .sign-up-now__phone__link .notice {
    margin-left: 0
  }

  .sign-up-now__phone__link br {
    display: block
  }
}

@media screen and (max-width:570px) {
  .sign-up-now {
    min-height: inherit !important;
    min-height: auto !important;
    padding-bottom: 22px;
    padding-top: 52px;
    text-align: center
  }

  .sign-up-now__title {
    font-size: 24px
  }

  .sign-up-now__title span {
    font-size: 14px;
    margin-top: 10px
  }

  .sign-up-now__phone {
    display: flex;
    flex-direction: column;
    margin-top: 40px
  }

  .sign-up-now__phone__link {
    margin-left: auto;
    margin-right: auto;
    order: 1
  }

  .sign-up-now__phone__button {
    margin-top: 27px;
    order: 2
  }

  .sign-up-now__notes {
    margin-top: 50px
  }

  .sign-up-now__notes li {
    padding-left: 0;
    padding-top: 30px
  }

  .sign-up-now__notes li:before {
    left: 50%;
    margin-left: -8px;
    top: 0
  }

  .sign-up-now__notes li:not(.li-clock) {
    display: none;
    display: inherit
  }

  .sign-up-now_changeling {
    padding-bottom: 30px;
    padding-top: 30px
  }

  .sign-up-now_changeling .sign-up-now__title {
    font-size: 18px;
    line-height: 26px
  }

  .sign-up-now_changeling .sign-up-now__title span {
    font-size: 18px;
    margin-bottom: 0
  }

  .sign-up-now_changeling .sign-up-now__title [data-js=tooltip],
  .sign-up-now_changeling .sign-up-now__title [data-js=tooltip]>span {
    font-size: 18px
  }

  .sign-up-now_changeling .sign-up-now__notes {
    margin-top: 15px
  }

  .sign-up-now_changeling .sign-up-now__notes li {
    line-height: 22px;
    margin-bottom: 10px
  }

  .sign-up-now_changeling .sign-up-now__phone {
    margin-top: 20px
  }

  .page_251 .sign-up-now-wrapper {
    position: relative;
    z-index: 1
  }

  .page_251 .sign-up-now-wrapper+.pusher {
    margin-top: 0 !important
  }
}

@media screen and (max-width:414px) {
  .sign-up-now {
    padding-left: 15px;
    padding-right: 15px
  }
}

.filter-links {
  align-content: space-between;
  align-items: flex-start;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: -6px;
  margin-right: -6px;
  margin-top: 110px
}

.filter-links>* {
  margin-bottom: 17px;
  margin-left: 6px;
  margin-right: 6px
}

.filter-links_prices {
  margin-top: 60px
}

.filter-links_prices .link,
.filter-links_prices .slide-tab__label,
.filter-links_prices .tabs__labels.tabs__labels_buttons .tab__label,
.filter-links_prices [data-js=tooltip],
.tabs__labels.tabs__labels_buttons .filter-links_prices .tab__label {
  margin-bottom: 15px;
  margin-right: 15px
}

.filter-links_prices_additional {
  margin-bottom: 25px;
  margin-top: 50px
}

.filter-links_prices_empty {
  margin-bottom: 50px
}

.filter-links_employees {
  margin-top: 0
}

.filter-links_employees .link,
.filter-links_employees .slide-tab__label,
.filter-links_employees .tabs__labels.tabs__labels_buttons .tab__label,
.filter-links_employees [data-js=tooltip],
.tabs__labels.tabs__labels_buttons .filter-links_employees .tab__label {
  margin-bottom: 15px;
  margin-right: 15px
}

.filter-links-helper {
  color: #a5a5a5;
  font-size: 16px;
  line-height: 26px
}

.filter-links-button {
  display: none
}

.filter-links[data-js=option-menu] {
  margin-left: 0;
  margin-right: 0;
  position: relative;
  width: 100%
}

.filter-links[data-js=option-menu] select {
  background-image: none;
  border: none;
  box-shadow: none;
  display: none;
  height: 36px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%
}

.filter-links[data-js=option-menu] select:focus {
  outline: none
}

.filter-links[data-js=option-menu] select::-ms-expand {
  display: none
}

.filter-links__faker {
  background-color: #eef2f4;
  border-radius: 18px;
  display: none;
  height: 36px;
  overflow: hidden;
  padding-top: 9px;
  pointer-events: none;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 1
}

.filter-links__faker>span {
  padding-left: 22px;
  position: relative
}

.filter-links__faker__burger {
  height: 12px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 12px;
  z-index: 2
}

.filter-links__faker__burger i {
  background: #5f6877;
  border-radius: 1px;
  display: block;
  height: 2px;
  left: 0;
  opacity: 1;
  position: absolute;
  width: 100%
}

.filter-links__faker__burger i:first-child {
  top: 0;
  width: 12px
}

.filter-links__faker__burger i:nth-child(2),
.filter-links__faker__burger i:nth-child(3) {
  top: 5px;
  width: 12px
}

.filter-links__faker__burger i:nth-child(4) {
  top: 10px;
  width: 12px
}

@media screen and (max-width:767px) {
  .filter-links-helper {
    display: none
  }

  .filter-links {
    margin-left: 0;
    margin-right: 0;
    margin-top: 50px
  }

  .filter-links_prices_empty {
    margin-bottom: 0
  }

  .filter-links_prices_additional {
    margin-bottom: 50px
  }

  .filter-links[data-js=option-menu]>* {
    display: none
  }

  .filter-links[data-js=option-menu] select,
  .filter-links__faker {
    display: block
  }

  .filter-links_prices {
    background-color: #eef2f4;
    border-radius: 18px;
    height: 36px;
    overflow: hidden;
    transition: height .3s ease-out;
    width: 100%
  }

  .filter-links_prices.opened {
    height: auto;
    transition: height .3s ease-in
  }

  .filter-links-button {
    background-color: #eef2f4;
    border-radius: 18px;
    color: #5f6877;
    display: block;
    line-height: 24px;
    margin-left: auto;
    margin-right: auto;
    padding: 6px 10px;
    text-align: center;
    width: 100%
  }

  .filter-links-button__burger {
    display: inline-block;
    height: 12px;
    margin-right: 14px;
    width: 12px
  }

  .filter-links-button__burger>* {
    background-color: #5f6877;
    display: block;
    height: 2px;
    margin-bottom: 3px;
    width: 12px
  }

  .filter-links-button__burger>:last-child {
    margin-bottom: 0
  }

  .filter-links__list {
    margin-left: auto;
    margin-right: auto
  }

  .filter-links__list .link,
  .filter-links__list .slide-tab__label,
  .filter-links__list .tabs__labels.tabs__labels_buttons .tab__label,
  .filter-links__list [data-js=tooltip],
  .tabs__labels.tabs__labels_buttons .filter-links__list .tab__label {
    background-color: transparent !important;
    border: 0;
    margin-bottom: 5px;
    text-align: center;
    width: 100%
  }
}

.main-works .employees>*,
.main-works>.row,
.main-works>.row_short {
  margin-bottom: 0
}

.main-works__section {
  flex-wrap: nowrap;
  margin-left: -18px;
  margin-right: -18px;
  padding-bottom: 82px;
  padding-top: 90px
}

.main-works__section>* {
  flex-basis: calc(33.33% - 54px);
  margin-left: 18px;
  margin-right: 18px
}

.main-works__section>*>span {
  display: block
}

.main-works__section__title {
  background-position: 0 0;
  background-size: 129px 129px;
  color: #333638;

  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
  min-height: 129px;
  padding-top: 30px
}

.main-works__section__info {
  color: #898989;
  font-size: 14px;
  line-height: 22px;
  margin-top: 26px;
  max-width: 330px
}

.main-works__section__more {
  margin-top: 37px
}

.main-works__section__more_mobile {
  display: none
}

.main-works__section__image {
  border-radius: 10px;
  box-shadow: 0 10px 29px rgba(70, 81, 86, .18);
  height: 290px;
  overflow: hidden;
  position: relative;
  width: 390px
}

.main-works__section__image:after {
  background-color: transparent;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: all .1s ease-out;
  width: 100%
}

.main-works__section__image:hover:after {
  background-color: #e59700;
  content: "";
  opacity: .1;
  transition: all .1s ease-in
}

.main-works__section__text {
  color: #5f6877;
  font-size: 16px;
  line-height: 26px;
  margin-top: 24px;
  padding-left: 20px;
  padding-right: 20px
}

.main-works__icon_all-on-4 {
  background-image: url(/build/i/5fc4dfad8d74ad848c52.svg)
}

.main-works__icon_implantsiya {
  background-image: url(/build/i/e84d1cbbf2f243624d5c.svg)
}

.main-works__icon_ispravlenie-prikusa {
  background-image: url(/build/i/b744ab81674b90da6747.svg)
}

.main-works__icon_koronki {
  background-image: url(/build/i/8ae9f39d5d3d78bb2e31.svg)
}

.main-works__icon_lechenie {
  background-image: url(/build/i/0404dab65a4546ff7196.svg)
}

.main-works__icon_otbelivanie {
  background-image: url(/build/i/c87584503e91189cd7b7.svg)
}

.main-works__icon_profgigiena {
  background-image: url(/build/i/e90c03f9549942615f24.svg)
}

.main-works__icon_protezy {
  background-image: url(/build/i/b7f9caa5dfc3adc4403a.svg)
}

.main-works__icon_restavratsiya {
  background-image: url(/build/i/8ac06b13e3d3eee5878c.svg)
}

.main-works__icon_viniry {
  background-image: url(/build/i/c9179dbc358e0f920e08.svg)
}

@media screen and (max-width:1359px) {
  .main-works__section {
    flex-wrap: wrap
  }

  .main-works__section__title {
    font-size: 30px;
    line-height: 40px
  }

  .main-works__section__info {
    margin-top: 0
  }

  .main-works__section__text {
    margin-top: 19px;
    padding-left: 10px;
    padding-right: 10px
  }

  .main-works__section__image {
    max-height: 283px;
    max-width: 380px
  }

  .main-works__section__image .corner_play button {
    height: 88px;
    width: 88px
  }

  .main-works__section__image .corner_play button span {
    background-position: 36px 20px;
    height: 88px;
    width: 88px
  }
}

@media screen and (max-width:1363px) {
  .main-works .employees__tile {
    background-position: 45px 0;
    padding-left: 360px
  }
}

@media screen and (max-width:1314px) {
  .main-works__section__image {
    max-height: 280px;
    max-width: 375px
  }
}

@media screen and (max-width:1300px) {
  .main-works__section__image {
    max-height: 265px;
    max-width: 355px
  }
}

@media screen and (max-width:1232px) {
  .main-works__section__image {
    max-height: 235px;
    max-width: 315px
  }
}

@media screen and (max-width:1125px) {
  .main-works__section__image {
    max-height: 211px;
    max-width: 283px
  }

  .main-works__section__text {
    font-size: 14px;
    line-height: 22px
  }
}

@media screen and (max-width:1023px) {
  .main-works .divider {
    margin-bottom: 1px !important;
    margin-top: 1px !important
  }

  .main-works__section__image {
    max-height: 164px;
    max-width: 220px
  }

  .main-works__section__title {
    font-size: 24px;
    line-height: 36px
  }

  .main-works__section__more {
    font-size: 12px
  }

  .main-works__section {
    flex-wrap: nowrap;
    margin-left: -14px;
    margin-right: -14px
  }

  .main-works__section>* {
    flex-basis: calc(33.33% - 42px);
    margin-left: 14px;
    margin-right: 14px
  }

  .main-works__section>*>span {
    display: block;
    margin-left: auto;
    margin-right: auto
  }

  .main-works .employees__tile {
    background-position: top right 25px;
    background-size: 145px 121px;
    padding-left: 32px;
    padding-right: 32px
  }
}

@media screen and (max-width:767px) {
  .main-works__section__image {
    max-height: 216px;
    max-width: 100%;
    text-align: left
  }

  .main-works>.row,
  .main-works>.row_short {
    margin-bottom: 65px;
    margin-top: 15px
  }

  .main-works .employees__best-dentistry,
  .main-works .employees__champions,
  .main-works .employees__european-quality {
    background-position: top;
    display: block;
    height: auto;
    padding-top: 175px;
    text-align: center
  }

  .main-works__more {
    display: block;
    text-align: center
  }

  .main-works__section__title {
    background-image: none;
    margin-bottom: 30px;
    min-height: inherit !important;
    min-height: auto !important
  }

  .main-works__section__text {
    margin-top: 13px
  }

  .main-works__section__info,
  .main-works__section__more {
    display: none
  }

  .main-works__section__more_mobile {
    display: inline-block
  }

  .main-works__section {
    display: block;
    padding-bottom: 33px;
    padding-top: 0;
    text-align: center
  }

  .main-works__section>* {
    display: none
  }

  .main-works__section>:first-child,
  .main-works__section>:nth-child(2) {
    display: block
  }
}

.reviews-list {
  margin-bottom: 85px
}

.reviews-list .info-block-wrapper {
  margin-bottom: 2px;
  margin-top: 48px
}

.reviews__title {
  margin-top: 129px
}

.reviews {
  padding-top: 62px
}

.reviews>* {
  border: 1px solid #e0e3e4;
  border-radius: 10px;
  display: flex;
  margin-bottom: 22px;
  padding: 45px 42px
}

.reviews>*>:first-child {
  flex-basis: 80px
}

.review__person {
  text-align: center
}

.review__person__image {
  background-color: #e9e6ec;
  background-size: cover;
  border-radius: 50%;
  height: 80px;
  position: relative;
  width: 80px
}

.review__person__image:before {
  border-radius: 50%
}

.review__person__image:after,
.review__person__image:before {
  bottom: -20px;
  content: "";
  display: block;
  height: 40px;
  left: 50%;
  margin-left: -20px;
  position: absolute;
  width: 40px
}

.review__person__image.ok:before {
  background-color: #e18c13
}

.review__person__image.ok:after {
  background-image: url(/build/i/8e0912f1901a55248923.svg)
}

.review__person__image.fb:before {
  background-color: #396ca6
}

.review__person__image.fb:after {
  background-image: url(/build/i/4d12b899f779c25e4643.svg)
}

.review__person__image.vk:before {
  background-color: #207ec6
}

.review__person__image.vk:after {
  background-image: url(/build/i/0faba4986fde5d764dc2.svg)
}

.review__person__image.gl:before {
  background-color: #f12a59
}

.review__person__image.gl:after {
  background-image: url(/build/i/6f581b603e01865ca84e.svg)
}

.review__person__image__link {
  display: inline-block;
  font-size: 14px;
  margin-top: 28px
}

.review__content {
  padding-left: 28px;
  width: 100%
}

.review__info>span {
  color: #819292;
  font-size: 14px;
  line-height: 24px
}

.review__info>span:first-child {
  float: left
}

.review__info>span:last-child {
  float: right
}

.review__text {
  color: #5f6877;
  font-size: 14px;
  line-height: 24px;
  margin-top: 16px
}

.review__response {
  align-items: center;
  color: #819292;
  display: flex;
  font-size: 14px;
  line-height: 24px;
  margin-top: 18px;
  min-height: 50px;
  padding-left: 55px;
  position: relative
}

.review__response:before {
  background-color: #00beb4;
  background-image: url(/build/i/a136b0fce2ad6f6c14c7.svg);
  background-position: center 6px;
  background-size: 30px auto;
  border-radius: 50%;
  content: "";
  display: block;
  height: 40px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 40px
}

.review__leave-response {
  font-size: 14px;
  margin-top: 18px
}

.reviews__load-more {
  margin-top: 56px;
  text-align: center
}

@media screen and (max-width:1359px) {
  .reviews__load-more {
    margin-top: 52px
  }

  .page_251 .info-block-wrapper+.pusher {
    margin-top: 70px !important
  }
}

@media screen and (max-width:1023px) {
  .reviews__title {
    margin-top: 108px
  }
}

@media screen and (max-width:770px) {
  .reviews-list .h2-sub br {
    display: none
  }
}

@media screen and (max-width:767px) {
  .reviews-list {
    margin-bottom: 70px
  }

  .reviews__title {
    margin-top: 88px
  }

  .review {
    display: block;
    padding-left: 22px;
    padding-right: 22px
  }

  .review__info>span:first-child {
    float: none
  }

  .review__info>span:last-child {
    display: block;
    float: none;
    margin-top: 10px
  }

  .review__person {
    text-align: center
  }

  .review__person__image {
    margin-left: auto;
    margin-right: auto
  }

  .review__content {
    padding-left: 0;
    padding-top: 26px
  }

  .page_251 .info-block-wrapper+.pusher {
    margin-top: 30px !important
  }
}

.info-block {
  background-color: #fff;
  border: 10px solid #e9ecee;
  border-radius: 10px;
  position: relative
}

.info-block__text_under {
  color: #5f6877;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  max-width: 685px;
  text-align: center
}

@media screen and (max-width:1115px) {

  .info-block-wrapper>.row,
  .info-block-wrapper>.row_short {
    padding-left: 0;
    padding-right: 0
  }

  .info-block-wrapper_recommend>.row,
  .info-block-wrapper_recommend>.row_short {
    padding-left: 56px;
    padding-right: 56px
  }

  .info-block-wrapper_recommend .info-block {
    border-left: 10px solid #e9ecee;
    border-radius: 10px;
    border-right: 10px solid #e9ecee
  }

  .info-block {
    border-left: none;
    border-radius: 0;
    border-right: none;
    max-width: 100% !important
  }
}

@media screen and (max-width:767px) {

  .info-block,
  .info-block__text_under {
    padding-left: 15px;
    padding-right: 15px
  }

  .info-block__text_under {
    font-size: 14px;
    line-height: 26px
  }
}

.info-block__reviews {
  background-image: url(/build/i/da3ca3668c48df46e440.svg);
  background-position: 53px;
  background-size: 86px 86px;
  max-width: 1017px;
  min-height: 217px;
  padding: 40px 50px 40px 190px
}

.info-block__reviews>:first-child {
  max-width: 500px
}

.info-block__reviews>:last-child {
  height: 100%;
  position: absolute;
  right: 50px;
  top: 0
}

.info-block__reviews__title {
  color: #3c4143;

  font-size: 18px;
  font-style: normal;
  font-weight: 500
}

.info-block__reviews__text {
  color: #a5a5a5;
  font-size: 16px;
  line-height: 28px;
  margin-top: 10px
}

@media screen and (max-width:1023px) {
  .info-block__reviews {
    background-position: 26px 80px;
    padding-left: 149px
  }

  .info-block__reviews>:first-child {
    max-width: 330px
  }

  .info-block__reviews>:last-child {
    right: 26px
  }
}

@media screen and (max-width:767px) {
  .info-block__reviews__title {
    text-align: center
  }

  .info-block__reviews {
    background-image: none;
    padding-left: 15px;
    padding-right: 15px
  }

  .info-block__reviews>:first-child {
    max-width: inherit !important;
    max-width: none !important
  }

  .info-block__reviews>:last-child {
    display: block;
    margin-top: 40px;
    position: inherit !important;
    position: static !important;
    text-align: center
  }
}

.info-block__contacts {
  max-width: 1223px;
  min-height: 304px;
  padding: 63px 68px
}

.info-block__contacts__title {
  color: #333638;

  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
  text-align: center
}

.info-block__contacts__grid {
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 48px
}

.info-block__contacts__grid>* {
  background-size: 40px 40px;
  color: #5f6877;
  flex: 1 0 auto;
  line-height: 28px;
  max-width: 33.33%;
  min-height: 48px;
  padding-left: 15px;
  padding-right: 15px
}

.info-block__contacts__grid>*>div {
  background-position: 0;
  font-size: 16px;
  line-height: 28px;
  padding-left: 76px
}

.info-block__contacts__grid>*>div>div {
  color: #a5a5a5
}

.info-block__contacts__grid>:first-child>div {
  background-image: url(/build/i/08b6ad553ed774cb96e0.svg)
}

.info-block__contacts__grid>:first-child>div a {
  color: #5f6877;
  font-size: 20px;
  line-height: 24px
}

.info-block__contacts__grid>:nth-child(2) {
  max-width: 390px
}

.info-block__contacts__grid>:nth-child(2)>div {
  background-image: url(/build/i/a472eed3b4116eb43d59.svg)
}

.info-block__contacts__grid>:nth-child(3)>div {
  background-image: url(/build/i/617dc96f8b49c7f05693.svg)
}

.info-block__contacts__grid>:nth-child(3)>div a {

  font-style: normal;
  font-weight: 500
}

@media screen and (max-width:1359px) {
  .info-block__contacts {
    padding-left: 56px;
    padding-right: 56px
  }

  .info-block__contacts__grid>*>div {
    background-position: 0 8px
  }
}

@media screen and (max-width:1199px) {

  .info-block__contacts__grid>*,
  .info-block__contacts__grid>:nth-child(2) {
    max-width: 33.33%
  }
}

@media screen and (max-width:1023px) {
  .info-block__contacts {
    padding-left: 26px;
    padding-right: 26px
  }

  .info-block__contacts__grid {
    flex-wrap: wrap
  }

  .info-block__contacts__grid>* {
    max-width: 100%
  }

  .info-block__contacts__grid>*>div {
    margin-left: auto;
    margin-right: auto;
    padding-left: 58px;
    width: 352px
  }

  .info-block__contacts__grid>:first-child {
    flex: 0 0 100%;
    margin-bottom: 52px
  }

  .info-block__contacts__grid>:first-child>div {
    width: 242px
  }

  .info-block__contacts__grid>:nth-child(2),
  .info-block__contacts__grid>:nth-child(3) {
    flex: 0 0 50%
  }
}

@media screen and (max-width:767px) {
  .info-block__contacts {
    padding-bottom: 17px;
    padding-top: 66px
  }

  .info-block__contacts__grid>* {
    flex-basis: 100% !important;
    margin-bottom: 48px !important
  }

  .info-block__contacts__grid>*>div {
    background-position: 0 8px !important;
    width: 100% !important
  }

  .info-block__contacts__grid>:nth-child(2),
  .info-block__contacts__grid>:nth-child(3) {
    flex: 0 0 100%;
    max-width: 100%
  }

  .info-block__contacts__grid>:nth-child(3)>div {
    display: inline-block;
    width: inherit !important;
    width: auto !important
  }
}

.info-block__discount {
  background-image: url(/build/i/3886998e886c08878fd7.svg);
  background-position: 80px;
  background-size: 140px 140px;
  max-width: 1017px;
  min-height: 217px;
  padding: 57px 180px 57px 300px
}

.info-block__discount__title {
  color: #3c4143;

  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px
}

.info-block__discount__text {
  line-height: 26px;
  margin-top: 25px
}

.info-block__discount__button {
  margin-top: 40px
}

.info-block__discount-small {
  background-image: url(/build/i/3886998e886c08878fd7.svg);
  background-position: 80px;
  background-size: 100px 100px;
  max-width: 1017px;
  min-height: 156px;
  padding: 41px 106px 41px 304px
}

.info-block__discount-small__title {
  color: #3c4143;
  float: left;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  max-width: 245px
}

.info-block__discount-small__button {
  float: right;
  margin-top: 3px
}

.double-grid .info-block-wrapper .row,
.double-grid .info-block-wrapper .row_short {
  padding-left: 0;
  padding-right: 0
}

.double-grid .info-block__discount-small {
  padding: 41px
}

.info-block__discount-standard {
  background-image: url(/build/i/3886998e886c08878fd7.svg);
  background-position: 80px;
  background-size: 100px 100px;
  max-width: 1017px;
  min-height: 168px;
  padding: 41px 106px 41px 304px
}

.info-block__discount-standard__title {
  color: #3c4143;
  float: left;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-top: 5px;
  max-width: 300px
}

.info-block__discount-standard__text {
  line-height: 26px;
  margin-top: 25px
}

.info-block__discount-standard__button {
  float: right;
  margin-top: 9px
}

@media screen and (max-width:1363px) {
  .double-grid .info-block__discount-small {
    padding: 41px 106px 41px 304px
  }
}

@media screen and (max-width:1023px) {
  .info-block__discount {
    background-position: 44px;
    padding-left: 235px;
    padding-right: 26px
  }

  .info-block__discount-small {
    background-position: 15px;
    padding: 41px 26px 41px 200px !important
  }

  .info-block__discount-standard {
    background-position: 15px;
    padding: 41px 26px 41px 200px
  }
}

@media screen and (max-width:767px) {
  .info-block__discount {
    background-position: center 32px;
    background-size: 74px 74px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 132px;
    text-align: center
  }

  .info-block__discount__title {
    font-size: 24px;
    line-height: 30px
  }

  .info-block__discount-small {
    background-position: center 30px;
    background-size: 120px 120px;
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-top: 167px !important;
    text-align: center
  }

  .info-block__discount-small>* {
    flex-basis: 100%;
    float: none
  }

  .info-block__discount-small__title {
    margin-left: auto;
    margin-right: auto
  }

  .info-block__discount-small__button {
    margin-top: 52px
  }

  .info-block__discount-standard {
    background-position: center 30px;
    background-size: 120px 120px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 167px;
    text-align: center
  }

  .info-block__discount-standard>* {
    flex-basis: 100%;
    float: none
  }

  .info-block__discount-standard__title {
    font-size: 16px;
    line-height: 30px;
    margin-left: auto;
    margin-right: auto
  }

  .info-block__discount-standard__button {
    margin-top: 52px
  }
}

.info-block__employee-address {
  background-size: 140px 140px;
  max-width: 1024px;
  min-height: 156px;
  padding: 42px
}

.info-block__employee-address__text {
  line-height: 26px;
  margin-top: 25px
}

.info-block__employee-address__button {
  margin-top: 40px
}

.info-block__employee-address__icon {
  margin-right: 2px;
  position: relative;
  top: -2px
}

.info-block__employee-address__grid>* {
  background-size: 40px 40px;
  color: #5f6877;
  line-height: 28px;
  min-height: 56px
}

.info-block__employee-address__grid>:first-child {
  min-width: 275px
}

.info-block__employee-address__grid>*>div {
  background-position: 0;
  font-size: 16px;
  line-height: 28px
}

.info-block__employee-address__grid>*>div>div {
  color: #a5a5a5
}

.info-block__employee-address__grid>:first-child {
  float: left
}

.info-block__employee-address__grid>:first-child>div {
  background-image: url(/build/i/efc2a03a41f1ae7c66b6.svg);
  background-position: 0;
  padding-left: 73px
}

.info-block__employee-address__grid>:first-child>div a {

  font-style: normal;
  font-weight: 500;
  text-transform: uppercase
}

.info-block__employee-address__grid>:nth-child(2) {
  float: left;
  margin-left: 75px
}

.info-block__employee-address__grid>:nth-child(2)>div {
  background-image: url(/build/i/769f64f3fc42889276c4.svg);
  background-position: 0;
  padding-left: 73px
}

.info-block__employee-address__grid>:nth-child(2)>div a {
  color: #5f6877;
  font-size: 14px;
  line-height: 24px
}

.info-block__employee-address__grid>:nth-child(3) {
  float: right
}

.info-block__employee-address__grid>:nth-child(3)>div {
  height: 100%;
  justify-content: flex-end
}

.info-block__employee-address__grid_flex {
  align-items: center;
  display: flex;
  flex-wrap: wrap
}

.info-block__employee-address__grid_flex:after {
  content: none
}

.info-block__employee-address__grid_flex>:nth-child(3) {
  margin-left: auto;
  margin-right: 0
}

@media screen and (max-width:1023px) {
  .info-block__employee-address {
    padding-left: 26px;
    padding-right: 26px
  }

  .info-block__employee-address__grid>:first-child,
  .info-block__employee-address__grid>:nth-child(2) {
    float: none
  }

  .info-block__employee-address__grid>:nth-child(2) {
    margin-left: 0;
    margin-top: 20px
  }

  .info-block__employee-address__grid>:nth-child(3) {
    float: none;
    margin-top: -29px;
    position: absolute;
    right: 26px;
    top: 50%
  }

  .info-block__employee-address__grid_flex {
    display: block
  }
}

@media screen and (max-width:767px) {
  .info-block__employee-address__grid>* {
    justify-content: center
  }

  .info-block__employee-address__grid>:first-child {
    min-width: auto
  }

  .info-block__employee-address__grid>:first-child,
  .info-block__employee-address__grid>:nth-child(2) {
    float: none
  }

  .info-block__employee-address__grid>:nth-child(2) {
    margin-left: 0;
    margin-top: 20px
  }

  .info-block__employee-address__grid>:nth-child(3) {
    margin-top: 20px;
    position: inherit !important;
    position: static !important
  }

  .info-block__employee-address__grid>:nth-child(3)>* {
    margin-left: auto;
    margin-right: auto
  }
}

.info-block__service-work {
  padding: 28px 70px
}

.info-block__service-work__grid {
  align-items: center
}

.info-block__service-work__grid>* {
  flex-grow: 0
}

.info-block__service-work__grid>:nth-child(2) {
  padding-left: 65px
}

.info-block__service-work__grid>:nth-child(2)>div {
  background-image: url(/build/i/08b6ad553ed774cb96e0.svg);
  background-position-y: 10px;
  padding-left: 70px
}

.info-block__service-work__grid>:nth-child(2)>div>div {
  color: #a5a5a5;
  line-height: 28px;
  padding-bottom: 7px
}

.info-block__service-work__grid>:nth-child(2)>div a {
  color: #5f6877;
  font-size: 20px;
  line-height: 24px
}

.info-block__service-work__grid>:nth-child(3) {
  text-align: right
}

.info-block__service-work__employee {
  display: flex
}

.info-block__service-work__employee>* {
  flex: 1 0
}

.info-block__service-work__employee>:first-child {
  max-width: 114px
}

.info-block__service-work__employee .progressive-image-wrapper {
  border-radius: 50%;
  height: 114px !important;
  width: 114px !important
}

.info-block__service-work__employee .progressive-image {
  height: 114px;
  width: 114px
}

.info-block__service-work__employee__info {
  align-items: center;
  color: #989ca6;
  display: flex;
  font-size: 14px;
  line-height: 22px;
  margin-left: 26px
}

.info-block__service-work__employee__info .link,
.info-block__service-work__employee__info .slide-tab__label,
.info-block__service-work__employee__info .tabs__labels.tabs__labels_buttons .tab__label,
.info-block__service-work__employee__info [data-js=tooltip],
.tabs__labels.tabs__labels_buttons .info-block__service-work__employee__info .tab__label {
  font-size: 14px
}

.info-block__service-work__employee__title {
  color: #a5a5a5;
  font-size: 16px;
  margin-bottom: 12px
}

.info-block__service-work__employee__profession {
  color: #989ca6;
  font-size: 14px;
  line-height: 22px
}

@media screen and (max-width:1359px) {
  .info-block__service-work {
    padding: 28px 56px
  }

  .info-block__service-work__grid>:first-child {
    padding-right: 16px
  }

  .info-block__service-work__grid>:nth-child(2) {
    padding-left: 30px
  }

  .info-block__service-work__grid>:nth-child(3) {
    max-width: 270px
  }
}

@media screen and (max-width:1023px) {
  .info-block__service-work {
    height: 236px;
    padding: 28px 26px
  }

  .info-block__service-work__grid {
    height: 160px
  }

  .info-block__service-work__grid>:nth-child(2) {
    padding-left: 15px
  }
}

@media screen and (max-width:992px) {
  .info-block__service-work__grid {
    justify-content: space-between
  }

  .info-block__service-work__grid>* {
    flex-basis: 50%
  }

  .info-block__service-work__grid>:nth-child(2) {
    left: 0;
    position: relative;
    top: -46px
  }

  .info-block__service-work__grid>:nth-child(2)>div {
    float: right;
    padding-right: 20px
  }

  .info-block__service-work__grid>:nth-child(3) {
    position: absolute;
    right: 26px;
    top: 123px
  }
}

@media screen and (max-width:700px) {
  .info-block__service-work {
    height: auto;
    padding-bottom: 35px;
    padding-left: 15px;
    padding-right: 15px
  }

  .info-block__service-work__grid {
    height: auto;
    justify-content: center;
    margin: 0 auto;
    max-width: 300px
  }

  .info-block__service-work__grid>* {
    flex-basis: 100%
  }

  .info-block__service-work__grid>:first-child {
    margin-top: 17px;
    max-width: 250px;
    padding-right: 0
  }

  .info-block__service-work__grid>:nth-child(2) {
    margin-top: 44px;
    max-width: 240px;
    padding-left: 0;
    position: inherit !important;
    position: static !important
  }

  .info-block__service-work__grid>:nth-child(2)>div {
    float: none;
    padding-left: 60px;
    padding-right: 0
  }

  .info-block__service-work__grid>:nth-child(3) {
    margin-top: 48px;
    position: inherit !important;
    position: static !important;
    text-align: center
  }

  .info-block__service-work__employee {
    align-items: center
  }

  .info-block__service-work__employee>:first-child {
    max-width: 62px
  }

  .info-block__service-work__employee .progressive-image-wrapper {
    border-radius: 50%;
    height: 62px !important;
    width: 62px !important
  }

  .info-block__service-work__employee .progressive-image {
    height: 62px;
    width: 62px
  }

  .info-block__service-work__employee__info {
    margin-left: 14px
  }
}

.info-block__installments {
  background-image: url(/build/i/e980660a19fb315de9ed.svg);
  background-position: 80px;
  background-size: 100px 100px;
  max-width: 1017px;
  min-height: 168px;
  padding: 41px 106px 41px 304px
}

.info-block__installments__title {
  color: #3c4143;
  float: left;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-top: -9px;
  max-width: 300px
}

.info-block__installments__text {
  line-height: 26px;
  margin-top: 25px
}

.info-block__installments__button {
  float: right;
  margin-top: 9px
}

@media screen and (max-width:1115px) {

  .info-block-wrapper_installments-compact>.row,
  .info-block-wrapper_installments-compact>.row_short {
    padding-left: 56px;
    padding-right: 56px
  }

  .info-block-wrapper_installments-compact .info-block {
    border-left: 10px solid #e9ecee;
    border-radius: 10px;
    border-right: 10px solid #e9ecee
  }

  .info-block-wrapper_installments-compact .info-block__installments {
    background-position: 45px;
    padding: 41px 90px 41px 237px
  }
}

@media screen and (max-width:1023px) {

  .info-block-wrapper_installments-compact>.row,
  .info-block-wrapper_installments-compact>.row_short {
    padding-left: 26px;
    padding-right: 26px
  }

  .info-block-wrapper_installments-compact .info-block__installments,
  .info-block__installments {
    background-position: 15px;
    padding: 41px 26px 41px 200px
  }
}

@media screen and (max-width:900px) {
  .info-block-wrapper_installments-compact .info-block__installments {
    align-items: flex-start;
    background-position: 45px;
    display: flex;
    flex-direction: column;
    padding: 35px 25px 35px 175px
  }

  .info-block-wrapper_installments-compact .info-block__installments__title {
    max-width: 420px
  }

  .info-block-wrapper_installments-compact .info-block__installments__button {
    margin-top: 20px
  }
}

@media screen and (max-width:767px) {
  .info-block-wrapper_installments-compact .info-block__installments {
    align-items: center;
    background-position: center 20px;
    background-size: 80px auto;
    padding: 118px 35px 35px
  }

  .info-block-wrapper_installments-compact .info-block__installments__button {
    margin-top: 15px
  }

  .info-block-wrapper_installments-compact>.row,
  .info-block-wrapper_installments-compact>.row_short,
  .info-block__installments {
    padding-left: 15px;
    padding-right: 15px
  }

  .info-block__installments {
    background-position: center 30px;
    background-size: 120px 120px;
    padding-top: 167px;
    text-align: center
  }

  .info-block__installments>* {
    flex-basis: 100%;
    float: none
  }

  .info-block__installments__title {
    font-size: 16px;
    line-height: 30px;
    margin-left: auto;
    margin-right: auto
  }

  .info-block__installments__button {
    margin-top: 52px
  }
}

.info-block__recommend {
  background-image: url(/build/i/80fcf949d82f34299c66.svg);
  background-position: 60px;
  background-size: 180px 180px;
  max-width: 1017px;
  min-height: 217px;
  padding: 52px 170px 52px 300px
}

@media screen and (max-width:1115px) {
  .info-block__recommend {
    padding-right: 100px
  }
}

@media screen and (max-width:767px) {
  .info-block__recommend {
    background-position: center 32px;
    background-size: 74px 74px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 132px;
    text-align: center
  }
}

.info-block__affiliates {
  padding: 42px 52px
}

.affiliates-block-title {
  color: #5f6877;

  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 36px;
  text-align: center
}

.info-block__affiliates {
  max-width: 1024px;
  min-height: 156px;
  padding: 42px
}

.info-block__affiliates__grid {
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between
}

.info-block__affiliates__grid>* {
  flex: 0 1 auto
}

.info-block__affiliates__grid>:first-child {
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 74px;
  padding-right: 20px;
  position: relative
}

.info-block__affiliates__grid>:first-child:before {
  background-image: url(/build/i/efc2a03a41f1ae7c66b6.svg);
  content: "";
  height: 40px;
  left: 0;
  margin-top: -20px;
  position: absolute;
  top: 50%;
  width: 40px
}

.info-block__affiliates__grid>:first-child>* {
  flex: 0 1 auto
}

.info-block__affiliates__address:first-child {
  margin-right: 75px
}

.info-block__affiliates__address>a {

  font-style: normal;
  font-weight: 500;
  text-transform: uppercase
}

@media screen and (max-width:1023px) {
  .affiliates-block-title {
    padding: 0 26px
  }
}

@media screen and (max-width:900px) {
  .info-block__affiliates__grid>:first-child>* {
    flex-basis: 100%
  }

  .info-block__affiliates__address {
    margin-bottom: 15px
  }

  .info-block__affiliates__address:first-child {
    margin-right: 0
  }

  .info-block__affiliates__address:last-child {
    margin-bottom: 0
  }
}

@media screen and (max-width:767px) {
  .info-block__affiliates {
    padding: 42px 25px
  }

  .affiliates-block-title {
    padding: 0 15px
  }

  .info-block__affiliates__grid {
    flex-wrap: wrap;
    justify-content: center
  }

  .info-block__affiliates__grid>* {
    flex-basis: auto;
    max-width: 440px
  }

  .info-block__affiliates__grid>:first-child {
    margin-bottom: 30px;
    padding-left: 65px
  }

  .info-block__affiliates__grid>:first-child:before {
    margin-top: 0;
    top: 0
  }

  .info-block__affiliates__grid>:first-child>* {
    flex-basis: auto
  }

  .info-block__affiliates__grid>:last-child {
    padding: 0 60px
  }
}

@media screen and (max-width:500px) {
  .info-block__affiliates__grid {
    flex-wrap: wrap;
    justify-content: center
  }

  .info-block__affiliates__grid>:first-child {
    padding-right: 0
  }

  .info-block__affiliates__grid>:last-child {
    padding: 0 20px
  }
}

.info-block__preset {
  background-position: 60px;
  background-size: 180px 180px;
  max-width: 1017px;
  min-height: 217px;
  padding: 52px 100px
}

.info-block__with-icon {
  padding-left: 300px
}

@media screen and (max-width:767px) {
  .info-block__preset {
    background-position: center 32px;
    background-size: 74px 74px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center
  }

  .info-block__with-icon {
    padding-top: 132px
  }
}

.info-block__insurance {
  background-image: url(/build/i/3d066d9ed77ca086c811.jpg);
  background-position: 0 0;
  max-width: 1016px;
  padding-bottom: 51px;
  padding-left: 280px;
  padding-top: 45px;
  position: relative
}

.info-block__insurance:after {
  background-image: url(/build/i/5ce11b5e30e98d556927.png);
  background-size: cover;
  content: "";
  display: block;
  height: 100px;
  margin-top: -50px;
  position: absolute;
  right: 50px;
  top: 50%;
  width: 100px
}

.info-block__insurance__text {
  color: #5f6877;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 6px
}

.info-block__insurance__price {
  color: #3c4143;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px
}

@media screen and (max-width:1023px) {
  .info-block__insurance {
    padding-bottom: 36px;
    padding-left: 235px;
    padding-top: 34px
  }

  .info-block__insurance:after {
    right: 30px
  }

  .info-block__insurance__container {
    max-width: 366px
  }
}

@media screen and (max-width:767px) {
  .info-block__insurance {
    background-image: none;
    padding-bottom: 110px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center
  }

  .info-block__insurance:after {
    bottom: 20px;
    height: 70px;
    left: 50%;
    margin-left: -35px;
    right: inherit !important;
    right: auto !important;
    top: inherit !important;
    top: auto !important;
    width: 70px
  }

  .info-block__insurance__text {
    font-size: 14px;
    line-height: 20px
  }

  .info-block__insurance__price {
    font-size: 16px;
    line-height: 20px
  }

  .info-block__insurance__container {
    margin-left: auto;
    margin-right: auto
  }
}

.team__text {
  max-width: 983px
}

.team__text .text-warning {
  display: inline-block;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 23px
}

.team-criteria {
  align-items: flex-start;
  counter-reset: item;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 75px
}

.team-criteria>* {
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  text-align: center
}

.team-criteria>:before {
  color: #00afa6;
  content: counter(item);
  counter-increment: item;
  display: block;

  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 25px
}

.team-criteria__title {
  color: #333638;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 10px
}

.team-criteria__text {
  color: #5f6877;
  font-size: 14px;
  line-height: 26px;
  margin: 0 auto;
  max-width: 280px
}

.team__slider {
  margin-top: 88px;
  padding-bottom: 105px
}

.team__slider .slider__item_single .slider-employee__slide__image {
  z-index: 0
}

.team__slider .slider__item_single .slider-employee__slide__button {
  position: relative;
  z-index: 1
}

.team-criteria-iconed {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 50px
}

.team-criteria-iconed>* {
  padding-left: 15px;
  padding-right: 15px
}

.team-criteria-iconed_triple {
  justify-content: center;
  padding-left: 150px
}

.team-criteria-iconed_triple>* {
  flex: 0 1 25%
}

.team-criteria-iconed__item>div {
  color: #3c4143;

  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  transition: color .2s ease-out
}

a.team-criteria-iconed__item:hover>div {
  color: #29adae;
  transition: color .2s ease-in
}

.team-criteria__icon {
  margin-bottom: 15px;
  width: 66px
}

.page_251 .team__slider {
  margin-top: 62px
}

@media screen and (max-width:1359px) {
  .page_251 .team+.divider {
    margin-bottom: 70px !important;
    margin-top: 75px !important
  }
}

@media screen and (max-width:1199px) {
  .team-criteria-iconed_triple {
    padding-left: 0
  }

  .team-criteria-iconed_triple>* {
    flex: 0 1 30%
  }
}

@media screen and (max-width:1023px) {
  .team__slider {
    margin-top: 40px
  }

  .team-criteria {
    margin-top: 50px
  }

  .team-criteria-iconed>:last-child,
  .team-criteria>* br {
    display: none
  }

  .team-criteria-iconed_triple {
    justify-content: center
  }

  .team-criteria-iconed_triple>* {
    flex: 0 1 50%
  }

  .page_251 .team+.divider {
    margin-bottom: 45px !important
  }
}

@media screen and (max-width:900px) {
  .team-criteria__title {
    font-size: 16px
  }
}

@media screen and (max-width:767px) {
  .team__slider {
    margin-top: 35px
  }

  .team__text {
    display: none
  }

  .team-criteria {
    margin-bottom: 0;
    margin-top: 0
  }

  .team-criteria>* {
    flex-basis: 100%;
    margin-top: 15px
  }

  .team-criteria>:before {
    font-size: 54px;
    margin-bottom: 0
  }

  .team-criteria__title {
    margin-bottom: 5px
  }

  .team-criteria__text {
    line-height: 22px
  }

  .team-criteria-iconed__item>div {
    font-size: 14px;
    line-height: 20px
  }

  a.team-criteria-iconed__item>div {
    color: #3c4143
  }

  a.team-criteria-iconed__item span {
    text-decoration: underline
  }

  .page_251 .team__slider {
    margin-top: 24px
  }

  .page_251 .team__title {
    margin-top: 45px
  }

  .team__slider {
    padding-bottom: 0
  }

  .team__slider .slider-wrapper-employee {
    background-color: transparent;
    height: auto;
    position: relative
  }

  .team__slider .html-slider__navigator__link {
    display: none
  }

  .team__slider .slick-dots {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%
  }

  .team__slider .html-slider__navigator__counter {
    display: none
  }

  .team__slider .html-slider__pager-wrapper {
    bottom: 42px;
    height: auto;
    position: absolute;
    top: auto
  }

  .team__slider .html-slider__pager-wrapper>:first-child {
    top: 0
  }

  .team__slider .html-slider__navigator>div>div {
    background-color: #01d8cd;
    border-radius: 29px;
    height: 49px;
    width: 100px
  }

  .team__slider .html-slider__navigator__button {
    background-color: #00beb4;
    border-radius: 0;
    border-bottom-right-radius: 50%;
    border-top-right-radius: 50%;
    height: 49px;
    transition: background-color .2s ease-out;
    width: 49px
  }

  .team__slider .html-slider__navigator__button>svg path {
    fill: #fff
  }

  .team__slider .html-slider__navigator__button:hover {
    background-color: #00b1a8;
    transition: background-color .2s ease-in
  }

  .team__slider .html-slider__navigator__button:hover>svg path {
    fill: #fff
  }

  .team__slider .html-slider__navigator__button[disabled] {
    opacity: .7
  }

  .team__slider .html-slider {
    height: auto;
    padding-bottom: 62px;
    position: relative
  }

  .team__slider .html-slider .slick-dots {
    display: flex !important
  }

  .team__slider .html-slider .slider__item {
    height: auto
  }

  .team__slider .slider-employee__slide {
    background-color: #edf2f5;
    background-image: url(/build/i/cd469867279222393ec9.svg);
    background-position: top -10px center;
    background-repeat: repeat;
    background-size: 21px 26px;
    height: auto;
    padding-bottom: 54px
  }

  .team__slider .slider-employee__slide>.row,
  .team__slider .slider-employee__slide>.row_short {
    display: flex;
    flex-direction: column;
    padding-top: 0
  }

  .team__slider .slider-employee__slide__profession {
    display: block;
    font-size: 13px;
    line-height: 18px
  }

  .team__slider .slider-employee__slide__feature {
    align-items: center;
    background-size: 45px auto;
    display: flex;
    font-size: 13px;
    height: 45px;
    line-height: 18px;
    min-height: 45px;
    order: -1;
    padding-left: 54px
  }

  .team__slider .slider-employee__slide__fio {
    font-size: 18px;
    margin-top: 20px;
    width: 100%
  }

  .team__slider .slider-employee__slide__image {
    background-color: #eef3f6;
    background-image: url(/build/i/c539136ecd89e17c0137.png);
    background-size: cover;
    border-radius: 0;
    height: 160px !important;
    margin-left: -15px;
    margin-right: -15px;
    order: -2;
    position: relative;
    right: auto;
    width: calc(100% + 30px) !important;
    z-index: 1
  }

  .team__slider .slider-employee__slide__image .progressive-image {
    margin-left: auto;
    margin-right: auto;
    margin-top: -10px;
    max-width: 270px;
    position: relative;
    z-index: 2
  }

  .team__slider .slider-employee__slide__image:before {
    border: 30px solid hsla(0, 0%, 100%, .5);
    border-radius: 50%;
    content: "";
    height: 267px;
    left: 50%;
    margin-left: -133px;
    position: absolute;
    top: -20px;
    width: 267px;
    z-index: 0
  }

  .team__slider .slider-employee__slide__buttons {
    justify-content: center;
    margin-top: 18px
  }

  .team__slider .slider-employee__slide__button {
    height: 40px;
    line-height: 36px;
    margin-right: 0
  }

  .team__slider .slider-employee__slide__video-link {
    left: calc(50% + 80px);
    position: absolute;
    top: 22px;
    z-index: 3
  }

  .team__slider .slider-employee__slide_team {
    height: 217px
  }
}

@media screen and (max-width:540px) {
  .team-criteria-iconed {
    margin-left: -13px;
    margin-right: -13px;
    margin-top: 25px
  }

  .team-criteria-iconed>* {
    padding-left: 13px;
    padding-right: 13px
  }

  .team-criteria-iconed>:nth-child(3) {
    display: none
  }

  .team-criteria__icon {
    width: 50px
  }
}

.thank-for-trust__title {
  margin-bottom: 20px
}

.thank-for-trust__achievements__title {
  color: #3c4143;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px
}

.thank-for-trust__achievements__title>span {
  color: #df8200
}

.thank-for-trust__achievements__text {
  color: #5f6877;
  font-size: 14px;
  line-height: 24px;
  margin-top: 23px
}

.thank-for-trust__achievements>* {
  background-position: center 55px;
  border-radius: 10px;
  box-shadow: 0 10px 29px transparent;
  flex-basis: calc(33.33% - 138px);
  padding: 199px 46px 60px;
  text-align: center;
  transition: box-shadow .2s ease-out
}

.thank-for-trust__achievements>:hover {
  box-shadow: 0 10px 29px rgba(70, 81, 86, .18);
  transition: box-shadow .2s ease-in
}

.tabs__labels.tabs__labels_buttons .thank-for-trust__achievements>:hover .tab__label.active,
.thank-for-trust__achievements>:hover .button,
.thank-for-trust__achievements>:hover .slide-tab__label.active,
.thank-for-trust__achievements>:hover .tabs__labels.tabs__labels_buttons .tab__label.active {
  opacity: 1;
  transition: opacity .2s ease-in
}

.thank-for-trust__achievements>:first-child {
  background-image: url(/build/i/eec0f265fe819e7c2b6f.png)
}

.thank-for-trust__achievements>:nth-child(2) {
  background-image: url(/build/i/2d55662be226c400237d.png)
}

.thank-for-trust__achievements>:nth-child(3) {
  background-image: url(/build/i/869ea5f81d1f72dda33f.png)
}

.tabs__labels.tabs__labels_buttons .thank-for-trust__achievements>* .tab__label.active,
.thank-for-trust__achievements>* .button,
.thank-for-trust__achievements>* .slide-tab__label.active,
.thank-for-trust__achievements>* .tabs__labels.tabs__labels_buttons .tab__label.active {
  display: inline-block;
  margin-top: 30px;
  opacity: 0;
  transition: opacity .2s ease-out
}

.thank-for-trust__achievements_tabs {
  display: none
}

.thank-for-trust__achievements_tabs>* {
  text-align: center
}

.thank-for-trust__achievements_tabs>*>* {
  background-position: 50%;
  border: 1px solid transparent;
  border-radius: 50%;
  display: inline-block;
  height: 58px;
  width: 58px
}

.thank-for-trust__achievements_tabs>*>.active,
.thank-for-trust__achievements_tabs>*>:hover {
  border: 1px solid #00aca3
}

.thank-for-trust__achievements_tabs>:first-child>* {
  background-image: url(/build/i/3a85f57fba9cf71a396e.png)
}

.thank-for-trust__achievements_tabs>:nth-child(2)>* {
  background-image: url(/build/i/3139aa33061c185716e8.png)
}

.thank-for-trust__achievements_tabs>:nth-child(3)>* {
  background-image: url(/build/i/5ce1010466435afe3e6d.png)
}

@media screen and (max-width:1359px) {
  .thank-for-trust__achievements>* {
    flex-basis: calc(33.33% - 60px);
    padding-left: 20px;
    padding-right: 20px
  }

  .thank-for-trust__achievements__title {
    font-size: 18px;
    line-height: 26px
  }

  .thank-for-trust__achievements__text {
    margin-top: 15px
  }
}

@media screen and (max-width:1023px) {
  .thank-for-trust__achievements {
    margin-left: -20px;
    margin-right: -20px
  }
}

@media screen and (max-width:767px) {
  .thank-for-trust__achievements_tabs {
    display: flex
  }

  .thank-for-trust {
    padding-bottom: 0
  }

  .thank-for-trust__title {
    margin-bottom: 35px
  }

  .thank-for-trust__achievements>* {
    background-image: none !important;
    padding-bottom: 30px;
    padding-top: 30px
  }

  .thank-for-trust__achievements>:hover {
    box-shadow: 0 10px 29px transparent
  }

  .tabs__labels.tabs__labels_buttons .thank-for-trust__achievements>* .tab__label.active,
  .thank-for-trust__achievements>* .button,
  .thank-for-trust__achievements>* .slide-tab__label.active,
  .thank-for-trust__achievements>* .tabs__labels.tabs__labels_buttons .tab__label.active {
    opacity: 1
  }

  .thank-for-trust__achievements__text {
    display: none
  }

  .thank-for-trust__achievements__link-mobile {
    display: inline-block
  }

  .thank-for-trust+.pusher {
    margin-top: 30px !important
  }
}

.honest-reviews {
  margin-top: 128px
}

.honest-reviews__proofs {
  margin-left: -30px;
  margin-right: -30px;
  margin-top: 84px
}

.honest-reviews__proofs>* {
  color: #5f6877;
  font-size: 14px;
  line-height: 26px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 115px;
  position: relative;
  text-align: center
}

.honest-reviews__proofs>*>a {
  display: block;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 25px
}

.honest-reviews__proofs>:before {
  content: "";
  display: block;
  height: 80px;
  left: 50%;
  margin-left: -40px;
  position: absolute;
  top: 0;
  width: 80px
}

.honest-reviews__proofs>:first-child:before {
  background-image: url(/build/i/ccfa41446d4be4d8f888.svg)
}

.honest-reviews__proofs>:nth-child(2):before {
  background-image: url(/build/i/59303db3be7ddd9209a3.svg)
}

.honest-reviews__proofs>:nth-child(3):before {
  background-image: url(/build/i/1db6a5417e5ca5c5c00d.svg)
}

@media screen and (max-width:1359px) {
  .honest-reviews__proofs {
    margin-left: -15px;
    margin-right: -15px
  }

  .honest-reviews__proofs>* {
    padding-left: 15px;
    padding-right: 15px
  }

  .honest-reviews__proofs>*>a {
    font-size: 16px;
    margin-bottom: 23px
  }
}

@media screen and (max-width:767px) {
  .honest-reviews {
    margin-top: 80px
  }

  .honest-reviews__proofs {
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 45px
  }

  .honest-reviews__proofs>* {
    flex: 1 0 100%;
    font-size: 0;
    padding: 5px 0 0
  }

  .honest-reviews__proofs>:before {
    content: none
  }

  .honest-reviews__proofs>*>a {
    margin-bottom: 0
  }
}

.independent-ratings__grid {
  margin-top: 82px
}

.independent-ratings__grid>* {
  color: #c2c3c5;
  display: block;
  font-size: 14px;
  padding-top: 62px;
  position: relative;
  text-align: center
}

.independent-ratings__grid>:before {
  background-position: 50%;
  background-size: 184px 40px;
  content: "";
  display: block;
  height: 40px;
  position: absolute;
  top: 0;
  transition: all .1s ease-out;
  width: 100%
}

.independent-ratings__grid>:hover:before {
  transition: all .1s ease-in
}

.independent-ratings__grid>:after {
  opacity: 0;
  position: absolute;
  z-index: -1
}

.independent-ratings__grid>:first-child:before {
  background-image: url('../img/demo/rating-stomatologia.png')
}

.independent-ratings__grid>:first-child:hover:before {
  background-image: url('../img/demo/rating-stomatologia.png')
}

.independent-ratings__grid>:first-child:after {
  content: url(/build/i/6171b1100ef8de59c4c2.png)
}

.independent-ratings__grid>:nth-child(2):before {
  background-image: url('../img/demo/rating-yandex.png')
}

.independent-ratings__grid>:nth-child(2):hover:before {
  background-image: url('../img/demo/rating-yandex.png')
}

.independent-ratings__grid>:nth-child(2):after {
  content: url(/build/i/52d3fb3e42fe1fbb3ed6.png)
}

.independent-ratings__grid>:nth-child(3):before {
  background-image: url('../img/demo/rating-zoon.png')
}

.independent-ratings__grid>:nth-child(3):hover:before {
  background-image: url('../img/demo/rating-zoon.png')
}

.independent-ratings__grid>:nth-child(3):after {
  content: url(/build/i/d25bf9888845ef91ee56.png)
}

.independent-ratings__grid>:nth-child(4):before {
  background-image: url('../img/demo/rating-yell.png')
}

.independent-ratings__grid>:nth-child(4):hover:before {
  background-image: url('../img/demo/rating-yell.png')
}

.independent-ratings__grid>:nth-child(4):after {
  content: url(/build/i/70fac0c0297840c9be21.png)
}

.independent-ratings__grid>:nth-child(5):before {
  background-image: url('../img/demo/rating-spr.png')
}

.independent-ratings__grid>:nth-child(5):hover:before {
  background-image: url('../img/demo/rating-spr.png')
}

.independent-ratings__grid>:nth-child(5):after {
  content: url(/build/i/7b58fa4f587f9e04abf3.png)
}

.independent-ratings__grid>:nth-child(6):before {
  background-image: url('../img/demo/rating-kleos.png')
}

.independent-ratings__grid>:nth-child(6):hover:before {
  background-image: url('../img/demo/rating-kleos.png')
}

.independent-ratings__grid>:nth-child(6):after {
  content: url(/build/i/6a4bc2959693bb494b95.png)
}

.independent-ratings__grid>:nth-child(7):before {
  background-image: url('../img/demo/rating-like-doctor.png')
}

.independent-ratings__grid>:nth-child(7):hover:before {
  background-image: url('../img/demo/rating-like-doctor.png')
}

.independent-ratings__grid>:nth-child(7):after {
  content: url(/build/i/70590d9632a948d1055d.png)
}

.independent-ratings__grid__int {
  color: #5f6877;
  font-size: 20px;
  line-height: 26px;
  margin-top: 11px
}

.independent-ratings__grid__int span {
  font-size: 16px
}

.independent-ratings__grid__star {
  margin-top: 12px
}

.independent-ratings_zygoma .h2,
.independent-ratings_zygoma .payment-page h2,
.payment-page .independent-ratings_zygoma h2 {

  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left
}

.independent-ratings_zygoma .h2-sub {
  margin-left: 0;
  margin-top: 20px;
  text-align: left
}

.independent-ratings_zygoma .independent-ratings__grid {
  margin-top: 60px
}

.independent-ratings_allon4 .h2,
.independent-ratings_allon4 .payment-page h2,
.payment-page .independent-ratings_allon4 h2 {
  text-align: left
}

.independent-ratings_allon4 .h2-sub {
  margin-left: 0;
  margin-top: 25px;
  text-align: left
}

.independent-ratings_allon4 .independent-ratings__grid {
  margin-top: 55px
}

@media screen and (max-width:1359px) {
  .page_251 .independent-ratings+.pusher {
    margin-top: 10px !important
  }

  .page_251 .independent-ratings_allon4+.pusher {
    margin-top: 40px !important
  }

  .independent-ratings__grid>:before {
    background-size: contain
  }

  .independent-ratings_allon4 .h2-sub {
    margin-top: 15px
  }

  .independent-ratings_allon4 .independent-ratings__grid {
    margin-top: 35px
  }
}

@media screen and (max-width:1023px) {
  .independent-ratings__grid>* {
    flex: 1 0 33.33%;
    margin-bottom: 55px
  }

  .independent-ratings__grid>:nth-child(n+7) {
    display: none
  }

  .page_251 .independent-ratings_allon4+.pusher {
    margin-top: 0 !important
  }
}

@media screen and (max-width:767px) {
  .independent-ratings__grid {
    flex-wrap: nowrap;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 20px;
    overflow-x: scroll;
    overflow-y: hidden
  }

  .independent-ratings__grid>* {
    flex: 1 0 30%;
    font-size: 10px;
    margin-bottom: 20px;
    margin-left: 15px;
    margin-right: 15px;
    padding-top: 45px
  }

  .independent-ratings__grid>:before {
    background-size: auto 25px
  }

  .independent-ratings__grid>:after {
    content: none !important
  }

  .independent-ratings__grid>:nth-child(n+7) {
    display: block
  }

  .independent-ratings__grid__int {
    font-size: 14px;
    margin-top: 4px
  }

  .independent-ratings__grid__int span {
    font-size: 11px
  }

  .independent-ratings__grid__star {
    margin-top: 7px
  }

  .independent-ratings .h2-sub {
    display: none
  }

  .independent-ratings_zygoma .h2,
  .independent-ratings_zygoma .payment-page h2,
  .payment-page .independent-ratings_zygoma h2 {

    font-size: 26px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal
  }

  .independent-ratings_zygoma .h2-sub {
    display: block;
    font-size: 14px;
    line-height: 22px
  }

  .independent-ratings_zygoma .independent-ratings__grid {
    margin-top: 30px
  }

  .independent-ratings_zygoma+.pusher {
    margin-top: 40px !important
  }

  .independent-ratings_allon4 .h2-sub {
    display: block;
    font-size: 14px;
    line-height: 22px
  }

  .independent-ratings_allon4 .independent-ratings__grid {
    margin-top: 20px
  }
}

.guest-book {
  padding-bottom: 139px;
  position: relative
}

.guest-book__title {
  text-align: left
}

.guest-book__sub-title {
  margin-left: 0;
  max-width: 510px;
  text-align: left
}

.guest-book__image {
  background-size: cover;
  display: block;
  height: 400px;
  position: relative;
  width: 600px
}

.guest-book__image>.progressive-image {
  border-radius: 10px
}

.guest-book__image:before {
  background-image: url(/build/i/4a7604512f0214d27c6f.svg);
  bottom: 8px;
  content: "";
  display: block;
  height: 36px;
  position: absolute;
  right: 193px;
  width: 36px;
  z-index: 1
}

.guest-book__image:after {
  background-color: #baf7f4;
  border-radius: 50%;
  bottom: -14px;
  content: "";
  display: block;
  height: 80px;
  position: absolute;
  right: 172px;
  width: 80px
}

.slider-wrapper-guest-book {
  bottom: 0;
  position: absolute;
  width: 910px
}

.guest-book-slider {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 29px rgba(70, 81, 86, .18);
  min-height: 288px;
  width: 910px
}

.guest-book-slider__slide {
  min-height: 288px;
  padding: 50px 60px 50px 232px;
  position: relative
}

.guest-book-slider__scan {
  box-shadow: 0 10px 29px rgba(70, 81, 86, .12);
  cursor: pointer;
  height: 160px;
  left: 65px;
  top: 54px;
  width: 110px
}

.guest-book-slider__scan,
.guest-book-slider__scan img {
  background-color: #fff;
  border-radius: 5px;
  position: absolute
}

.guest-book-slider__scan img {
  box-shadow: 0 10px 29px rgba(70, 81, 86, .1);
  height: 100%;
  left: -7px;
  top: -7px;
  width: 100%
}

.guest-book-slider__scan div {
  bottom: -25px;
  color: #00beb4;
  font-size: 14px;
  padding-left: 24px;
  position: absolute
}

.guest-book-slider__scan div:before {
  background-image: url(/build/i/dfc3f35ae5fa194fe953.svg);
  content: "";
  display: block;
  height: 16px;
  left: 0;
  position: absolute;
  width: 16px
}

.guest-book-slider__fio {
  color: #333638;

  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin-top: 5px;
  text-transform: uppercase
}

.guest-book-slider__text {
  color: #5f6877;

  font-style: italic;
  font-weight: 400;
  line-height: 30px;
  margin-top: 14px
}

.guest-book-slider__navigator {

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-top: 4px;
  text-transform: uppercase
}

.guest-book-slider__navigator>div>div {
  height: 46px;
  position: relative;
  width: 100px
}

.guest-book-slider__navigator__button {
  background-color: transparent;
  border-radius: 50%;
  height: 46px;
  position: absolute;
  top: 0;
  transition: background-color .2s ease-out;
  width: 46px;
  z-index: 1
}

.guest-book-slider__navigator__button svg {
  height: 16px;
  transition: fill .2s ease-in;
  width: 24px
}

.guest-book-slider__navigator__button:hover {
  transition: background-color .2s ease-in
}

.guest-book-slider__navigator__button:hover svg path {
  fill: #959ba5;
  transition: fill .2s ease-in
}

.guest-book-slider__navigator__button.prev {
  left: 0;
  transform: rotateY(180deg)
}

.guest-book-slider__navigator__button.next {
  right: 0
}

@media screen and (max-width:1359px) {
  .guest-book__sub-title {
    max-width: 405px
  }

  .guest-book-slider,
  .slider-wrapper-guest-book {
    width: 895px
  }

  .guest-book {
    padding-bottom: 235px
  }

  .guest-book__image {
    height: 298px;
    width: 447px
  }

  .guest-book__image:after,
  .guest-book__image:before {
    content: none
  }
}

@media screen and (max-width:1023px) {
  .guest-book__image {
    height: 233px;
    width: 349px
  }

  .guest-book-slider,
  .slider-wrapper-guest-book {
    width: 699px
  }

  .guest-book {
    padding-bottom: 355px
  }
}

@media screen and (max-width:767px) {
  .guest-book {
    padding-bottom: 0
  }

  .guest-book__title {
    text-align: center
  }

  .guest-book__content>:last-child {
    display: none
  }

  .guest-book-slider__navigator>div>div {
    margin-left: auto;
    margin-right: auto
  }

  .slider-wrapper-guest-book {
    margin-top: 21px;
    position: relative;
    width: 100%
  }

  .guest-book-slider {
    width: 100%
  }

  .guest-book-slider__slide {
    padding-bottom: 32px;
    padding-left: 32px;
    padding-right: 32px
  }

  .guest-book-slider__review {
    margin-bottom: 260px
  }

  .guest-book-slider__scan {
    bottom: 58px;
    left: 50%;
    margin-left: -55px;
    top: inherit !important;
    top: auto !important
  }
}

.quote-panel {
  color: #5f6877;

  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: 28px;
  position: relative;
  z-index: 1
}

.quote-panel>div {
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0 70px;
  width: 100%
}

.quote-panel>div>* {
  flex: 0 1 50%
}

.quote-panel .quote-panel__img {
  bottom: 0;
  height: 202px !important;
  right: 15px;
  top: auto;
  transform: scaleX(-1);
  width: 213px;
  z-index: 1
}

.quote-panel .quote-panel__img_mobile {
  display: none
}

.quote-panel__author {
  height: 215px;
  position: relative
}

.quote-panel__quote {
  padding-left: 58px;
  position: relative
}

.quote-panel__quote:before {
  background-image: url(/build/i/289614f9869ba4b363d5.svg);
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 36px;
  left: 0;
  position: absolute;
  top: -10px;
  width: 36px
}

.quote-panel__button {
  box-shadow: 0 0 14px 1px rgba(0, 0, 0, .22);
  display: block;
  left: 15px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 280px;
  position: absolute;
  text-align: center;
  top: 50px
}

.quote-panel__name {
  background-color: #fff;
  border-radius: 10px;
  bottom: 25px;
  color: #a5a5a5;
  font-size: 14px;
  left: 10px;
  line-height: 24px;
  max-width: 395px;
  padding: 8px 18px 7px;
  position: absolute;
  width: 100%
}

@media (max-width:1359px) {
  .quote-panel>div {
    padding-left: 30px;
    padding-right: 30px
  }

  .quote-panel__button {
    left: 0
  }

  .quote-panel__img {
    left: 50%;
    margin-left: 55px
  }

  .quote-panel__name {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-size: 13px;
    left: 0
  }

  .quote-panel__quote {
    padding-left: 75px
  }

  .quote-panel__quote:before {
    left: 20px
  }
}

@media (max-width:1023px) {
  .quote-panel>div {
    align-items: flex-start;
    border-radius: 10px;
    min-height: 285px;
    padding: 80px 22px 100px 248px;
    position: relative;
    width: auto
  }

  .quote-panel>div>* {
    flex: 1 0 100%
  }

  .quote-panel .quote-panel__img {
    left: 0;
    margin: 0;
    right: auto
  }

  .quote-panel__author {
    align-self: flex-end;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%
  }

  .quote-panel__button {
    left: 50%;
    max-width: 270px;
    padding: 0 15px;
    top: 30px;
    transform: translateX(-50%)
  }

  .quote-panel__name {
    bottom: 20px;
    left: 115px;
    padding: 8px 18px 7px 95px
  }

  .quote-panel__quote {
    align-self: center;
    font-size: 16px;
    left: 0;
    line-height: 24px;
    max-width: none;
    padding-left: 56px;
    padding-top: 10px
  }

  .quote-panel__quote:before {
    left: 0;
    opacity: 1;
    top: 0
  }
}

@media (max-width:767px) {
  .quote-panel {
    padding: 0
  }

  .quote-panel>div {
    border-radius: 0;
    height: auto;
    min-height: auto;
    padding: 0 15px 75px
  }

  .quote-panel .quote-panel__img {
    display: none
  }

  .quote-panel .quote-panel__img_mobile {
    bottom: 0;
    display: block;
    left: auto !important;
    position: absolute;
    right: 0;
    transform: scale(1);
    z-index: -1
  }

  .quote-panel__quote {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    justify-content: center;
    left: 0;
    line-height: 22px;
    max-width: 100%;
    min-height: 210px;
    padding: 28px 160px 25px 11px;
    position: relative;
    z-index: 1
  }

  .quote-panel__quote:before {
    display: block;
    margin-bottom: 18px;
    position: static
  }

  .quote-panel__button {
    background-color: transparent;
    box-shadow: none;
    color: #4bbdb4;
    font-size: 15px;
    left: 50%;
    margin-bottom: 0;
    max-width: 100%;
    text-align: center;
    top: -65px;
    transform: translateX(-50%);
    width: 290px
  }

  .quote-panel__name {
    border-bottom: 1px solid #d8dfe1;
    border-radius: 0;
    bottom: 0;
    font-style: normal;
    left: 0;
    max-width: 100%;
    padding: 15px;
    z-index: 2
  }
}

@media (max-width:450px) {
  .quote-panel .quote-panel__img_mobile {
    right: -40px
  }

  .quote-panel__quote {
    padding: 28px 120px 25px 11px
  }
}

.scrolls {
  border-bottom: 1px solid #d9e1e1;
  height: 81px;
  z-index: 5
}

.scrolls>.row,
.scrolls>.row_short {
  justify-content: space-around
}

.scrolls>.row:after,
.scrolls>.row_short:after {
  content: none
}

.scrolls>.row>*,
.scrolls>.row_short>* {
  flex: inherit !important;
  flex: initial !important
}

.scrolls>.row>* a,
.scrolls>.row_short>* a {

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase
}

.scrolls_space-between>.row,
.scrolls_space-between>.row_short {
  justify-content: space-between
}

.scrolls_wihour-border-bottom {
  border-bottom: none
}

.sticky-anchors-wrapper {
  border-top: 1px solid #d9e1e1;
  height: 81px;
  position: relative;
  z-index: 15
}

.sticky-anchors-wrapper.fixed .scrolls {
  background-color: #fff;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%
}

@media screen and (max-width:1023px) {

  .scrolls_visible .row>.scrolls__hide_tablet,
  .scrolls_visible .row_short>.scrolls__hide_tablet {
    display: none
  }

  .sticky-anchors-wrapper,
  .sticky-anchors-wrapper .scrolls {
    height: 50px
  }

  .sticky-anchors-wrapper .scrolls>.row>* a,
  .sticky-anchors-wrapper .scrolls>.row_short>* a {
    font-size: 12px
  }
}

@media screen and (max-width:767px) {
  .scrolls:not(.scrolls_visible) {
    display: none
  }

  .scrolls_wihour-border-bottom {
    border-bottom: 1px solid #d9e1e1
  }

  .scrolls_visible .row>:not(.scrolls__show),
  .scrolls_visible .row_short>:not(.scrolls__show) {
    display: none
  }

  .scrolls_visible .row>.scrolls__show_mobile,
  .scrolls_visible .row_short>.scrolls__show_mobile {
    display: flex
  }

  .scrolls_visible .hide-mobile {
    display: none
  }
}

@media screen and (max-width:700px) {
  .mobile-flex-swap .scrolls {
    border-top: 1px solid #d9e1e1
  }
}

.video-block {
  min-height: 600px;
  overflow: hidden;
  position: relative
}

.video-block__play {
  border-radius: 50%;
  box-shadow: 0 6px 29px rgba(0, 0, 0, .27);
  display: block;
  height: 80px;
  left: 50%;
  margin-left: -40px;
  position: absolute;
  top: 160px;
  width: 80px
}

.video-block__play:hover:before {
  height: 200px;
  margin-left: -100px;
  margin-top: -100px;
  transition: all .2s ease-in;
  width: 200px
}

.video-block__play:before {
  display: block;
  height: 186px;
  margin-left: -93px;
  margin-top: -93px;
  transition: all .2s ease-out;
  width: 186px
}

.video-block__play:after,
.video-block__play:before {
  background-color: #fff;
  border-radius: 50%;
  content: "";
  left: 50%;
  opacity: .08;
  position: absolute;
  top: 50%;
  z-index: 0
}

.video-block__play:after {
  height: 570px;
  margin-left: -285px;
  margin-top: -285px;
  pointer-events: none;
  width: 570px
}

.video-block__content {
  position: relative;
  top: 320px
}

@media screen and (max-width:1359px) {
  .video-block {
    margin-bottom: 125px;
    margin-top: 115px
  }
}

@media screen and (max-width:1023px) {
  .video-block {
    margin-bottom: 98px;
    margin-top: 104px
  }
}

@media screen and (max-width:767px) {
  .video-block {
    min-height: 400px
  }

  .video-block .h2-sub {
    line-height: 28px
  }

  .video-block .h2-sub br {
    display: none
  }

  .video-block__play {
    height: 62px;
    margin-left: -31px;
    top: 80px;
    width: 62px
  }

  .video-block__content {
    top: 230px
  }
}

.better-conditions {
  text-align: center
}

.attainment-image-slider-wrapper_allon4 .better-conditions.attainment-image-slider,
.better-conditions.appointment__inner,
.better-conditions.bg_dots,
.better-conditions.cards-with-text:before,
.better-conditions.director-quote__right:before,
.better-conditions.is-suitable.bg_dots-half:before,
.better-conditions.main-page-header__badge:before,
.better-conditions.payment-form,
.better-conditions.with-image-slider-wrapper_dots,
.better-conditions.works-slider__item,
.offer_standard .better-conditions.offer__card:before,
.through-block-short .better-conditions.through-block_hold-prices {
  background-position: top 2px left 2px;
  padding-bottom: 130px;
  padding-top: 60px
}

.better-conditions__sub-title {

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 90px;
  max-width: 775px;
  text-align: center
}

.better-conditions__grid {
  margin-left: -12px;
  margin-right: -12px;
  margin-top: 95px
}

.better-conditions__grid>* {
  background-position: center 0;
  flex-basis: calc(33.33% - 36px);
  margin-left: 12px;
  margin-right: 12px;
  min-height: 330px;
  padding-left: 26px;
  padding-right: 26px
}

.better-conditions__grid__item {
  margin-top: 80px
}

.better-conditions__image {
  border-radius: 50%;
  margin-bottom: 45px;
  text-align: left
}

.better-conditions__title {
  color: #5f6877;

  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-top: 33px;
  text-transform: uppercase
}

.better-conditions__text {
  color: #989ca6;
  font-size: 14px;
  line-height: 26px;
  margin-top: 16px
}

.better-conditions__text a:not(.link):not(.slide-tab__label):not([data-js=tooltip]) {
  color: #5f6877;

  font-style: normal;
  font-weight: 500
}

.better-conditions__button {
  margin-top: 77px
}

@media screen and (max-width:1359px) {
  .better-conditions__grid>* {
    padding-left: 16px;
    padding-right: 16px
  }

  .better-conditions__title {
    font-size: 14px
  }

  .attainment-image-slider-wrapper_allon4 .better-conditions.attainment-image-slider,
  .better-conditions.appointment__inner,
  .better-conditions.bg_dots,
  .better-conditions.cards-with-text:before,
  .better-conditions.director-quote__right:before,
  .better-conditions.is-suitable.bg_dots-half:before,
  .better-conditions.main-page-header__badge:before,
  .better-conditions.payment-form,
  .better-conditions.with-image-slider-wrapper_dots,
  .better-conditions.works-slider__item,
  .offer_standard .better-conditions.offer__card:before,
  .through-block-short .better-conditions.through-block_hold-prices {
    padding-bottom: 90px;
    padding-top: 90px
  }

  .better-conditions__grid_with-bg {
    margin-top: 75px
  }
}

@media screen and (max-width:1023px) {
  .better-conditions__grid {
    margin-left: -14px;
    margin-right: -14px
  }

  .better-conditions__grid>* {
    flex-basis: calc(33.33% - 42px);
    margin-bottom: 80px;
    margin-left: 14px;
    margin-right: 14px;
    padding-left: 0;
    padding-right: 0
  }

  .better-conditions__title {
    font-size: 14px
  }

  .better-conditions__grid_with-bg {
    justify-content: center
  }

  .better-conditions__grid_with-bg>* {
    flex-basis: calc(50% - 42px);
    margin-bottom: 80px;
    margin-top: 0;
    max-width: 370px
  }

  .better-conditions__button {
    margin-top: 0
  }
}

@media screen and (max-width:767px) {

  .attainment-image-slider-wrapper_allon4 .better-conditions.attainment-image-slider,
  .better-conditions.appointment__inner,
  .better-conditions.bg_dots,
  .better-conditions.cards-with-text:before,
  .better-conditions.director-quote__right:before,
  .better-conditions.is-suitable.bg_dots-half:before,
  .better-conditions.main-page-header__badge:before,
  .better-conditions.payment-form,
  .better-conditions.with-image-slider-wrapper_dots,
  .better-conditions.works-slider__item,
  .offer_standard .better-conditions.offer__card:before,
  .through-block-short .better-conditions.through-block_hold-prices {
    padding-bottom: 70px;
    padding-top: 90px
  }

  .better-conditions+.pusher {
    margin-top: 50px !important
  }

  .better-conditions__grid {
    margin-left: -15px;
    margin-right: -15px
  }

  .better-conditions__grid>* {
    flex-basis: calc(100% - 30px);
    margin-bottom: 35px;
    min-height: auto;
    padding-left: 15px;
    padding-right: 15px
  }

  .better-conditions__sub-title {
    margin-bottom: 0
  }

  .better-conditions__grid_with-bg {
    margin-top: 30px
  }

  .better-conditions__grid_with-bg>* {
    flex-basis: calc(50% - 30px)
  }

  .better-conditions__grid_with-bg .progressive-image-wrapper {
    height: 120px !important;
    width: 120px !important
  }

  .better-conditions__title {
    margin-top: 10px
  }

  .better-conditions__grid__item {
    margin-top: 0
  }

  .better-conditions__text {
    display: none
  }

  .better-conditions__button {
    margin-top: 0
  }

  .better-conditions__title {
    font-size: 16px
  }

  .better-conditions__image {
    margin-bottom: 38px
  }
}

@media screen and (max-width:600px) {
  .better-conditions__grid_with-bg>* {
    flex-basis: calc(100% - 30px)
  }
}

.contact-us {
  margin-bottom: 20px
}

.contact-us__title {
  color: #333638;

  font-size: 50px;
  font-style: normal;
  font-weight: 400
}

.contact-us__text {
  line-height: 30px;
  margin-top: 40px;
  max-width: 546px
}

.contact-us__form {
  margin-top: 14px;
  max-width: 597px
}

.contact-us__form textarea {
  height: 160px
}

.contact-us__form__left-input {
  float: left;
  width: 287px
}

.contact-us__form__right-input {
  float: right;
  width: 287px
}

.contact-us__form__full-input .g-recaptcha {
  bottom: -16px;
  position: absolute;
  right: -30px;
  transform: scale(.8)
}

.contact-us__form__full-input .g-recaptcha .form__error {
  bottom: -15px
}

.contact-us__form__button {
  align-items: center;
  margin-top: 16px
}

.contact-us__form__button>:first-child {
  flex-basis: 135px;
  margin-right: 24px;
  max-width: 135px
}

.contact-us__form__button>:last-child {
  color: #bdc7ca;
  flex: 1 1 50%;
  font-size: 12px;
  max-width: 430px
}

.contact-us__director {
  padding-bottom: 203px;
  position: relative;
  top: -40px
}

.contact-us__director>div {
  max-width: 448px
}

.contact-us__director__image {
  display: inline-block;
  padding: 110px;
  position: relative
}

.contact-us__director__image:before {
  border: 50px solid #f7f8f9;
  border-radius: 50%;
  content: "";
  display: block;
  height: 448px;
  left: 50%;
  margin-left: -224px;
  margin-top: -224px;
  position: absolute;
  top: 50%;
  width: 448px
}

.contact-us__director__image .progressive-image-wrapper {
  border-radius: 50%;
  box-shadow: 20px -1px 51px rgba(0, 0, 0, .11)
}

.contact-us__director__name {
  bottom: 46px;
  color: #819292;
  font-size: 14px;
  left: 138px;
  line-height: 22px;
  position: absolute;
  z-index: 1
}

.contact-us__director__content {
  left: 220px;
  max-width: 260px;
  position: absolute;
  top: 440px
}

.contact-us__director__title {
  color: #333638;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-transform: uppercase
}

.contact-us__director__text {
  font-size: 14px;
  line-height: 24px;
  margin-top: 20px
}

.contact-us__director__button {
  margin-top: 27px
}

.contact-us__divider {
  display: none;
  margin-bottom: 35px;
  margin-top: 123px
}

@media screen and (max-width:1359px) {
  .contact-us__form {
    max-width: 497px
  }

  .contact-us__grid>* {
    flex-basis: 100%
  }

  .contact-us__grid>:first-child {
    flex-basis: 516px
  }

  .contact-us__grid>:last-child {
    flex-basis: 379px
  }

  .contact-us__director {
    padding-left: 78px;
    top: 70px
  }

  .contact-us__director>div {
    margin-left: 0 !important;
    margin-right: 0 !important
  }

  .contact-us__form__left-input,
  .contact-us__form__right-input {
    width: 239px
  }

  .contact-us__director__image {
    padding: 0
  }

  .contact-us__director__image:before {
    content: none
  }

  .contact-us__director__image .progressive-image-wrapper {
    box-shadow: 0 20px 51px rgba(0, 0, 0, .11)
  }

  .contact-us__director__name {
    bottom: -64px;
    left: 25px;
    width: 258px
  }

  .contact-us__director__content {
    left: 103px;
    top: 330px
  }

  .contact-us__form__button>:last-child {
    max-width: 340px
  }
}

@media screen and (max-width:1023px) {

  .contact-us__text,
  .contact-us__title {
    text-align: center
  }

  .contact-us__form,
  .contact-us__text {
    max-width: 100%
  }

  .contact-us__form__left-input,
  .contact-us__form__right-input {
    width: 345px
  }

  .contact-us__form__button>:last-child {
    max-width: 440px
  }

  .contact-us__divider {
    display: block;
    margin-left: -26px;
    margin-right: -26px
  }

  .contact-us__grid>*,
  .contact-us__grid>:first-child,
  .contact-us__grid>:last-child {
    flex-basis: 100%
  }

  .contact-us__director {
    margin-left: -13px;
    margin-right: -13px;
    padding-left: 0
  }

  .contact-us__director>div {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-width: 100%
  }

  .contact-us__director>div:after {
    content: "";
    font-size: 0;
    min-height: inherit
  }

  .contact-us__director>div>br {
    display: none
  }

  .contact-us__director>div>* {
    display: flex;
    flex: 0 0 50%;
    justify-content: center;
    padding-left: 13px;
    padding-right: 13px
  }

  .contact-us__director>div>:after {
    content: "";
    font-size: 0;
    min-height: inherit
  }

  .contact-us__director__name {
    left: 50%;
    margin-left: -129px;
    text-align: center
  }

  .contact-us__director__content {
    align-items: center;
    display: flex;
    left: inherit !important;
    left: auto !important;
    max-width: inherit !important;
    max-width: none !important;
    position: inherit !important;
    position: static !important
  }

  .contact-us__director__content:after {
    content: "";
    font-size: 0;
    min-height: inherit
  }

  .contact-us__director__title {
    max-width: 175px
  }
}

@media screen and (max-width:767px) {
  .contact-us__title {
    font-size: 30px
  }

  .contact-us__form__left-input,
  .contact-us__form__right-input {
    width: 100%
  }

  .contact-us__form__button>:last-child {
    max-width: 100%
  }

  .contact-us__divider {
    margin-bottom: 0;
    margin-top: 104px
  }

  .contact-us__director {
    padding-bottom: 129px
  }

  .contact-us__director>div>* {
    flex-basis: 100%
  }

  .contact-us__director>div>:last-child {
    margin-top: 97px;
    text-align: center
  }

  .contact-us__director__title {
    margin-left: auto;
    margin-right: auto
  }
}

@media screen and (max-width:530px) {
  .input__wrapper_file {
    height: 140px
  }

  .contact-us__form__full-input .g-recaptcha {
    bottom: 0;
    left: -30px;
    right: inherit !important;
    right: auto !important
  }
}

@media screen and (max-width:520px) {
  .contact-us__form__button>:last-child {
    flex-basis: 100%;
    margin-top: 20px;
    max-width: 100%
  }
}

.review__form {
  min-width: 750px
}

.review__form textarea {
  height: 160px
}

.review__form__left-input {
  float: left;
  width: 287px
}

.review__form__button>:first-child {
  float: left
}

.review__form__button>:last-child {
  color: #bdc7ca;
  float: left;
  font-size: 12px;
  line-height: 17.99px;
  max-width: 475px;
  min-height: 48px;
  padding-left: 23px;
  text-align: left
}

.review__form__counter {
  color: #a5a5a5;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 19px;
  padding-left: 50px;
  position: relative;
  text-align: left
}

.review__form__counter>div:before {
  background-image: url(/build/i/09c87db841476636cf1a.svg);
  content: "";
  display: block;
  height: 16px;
  left: 24px;
  position: absolute;
  top: 2px;
  width: 16px
}

.review__form__counter>div span {
  color: #e28f32
}

.review__form__counter>div span.disabled {
  color: #a5a5a5
}

@media screen and (max-width:1023px) {
  .review__form {
    min-width: 100%
  }

  .modal__pluses {
    left: inherit !important;
    left: auto !important;
    right: 54px
  }
}

@media screen and (max-width:767px) {
  .review__form__button>:first-child {
    float: none;
    margin-bottom: 30px
  }

  .review__form__button>:last-child {
    float: none;
    padding-left: 0
  }
}

.employees-list {
  margin-top: 43px
}

.employees__employee {
  background-color: #eef2f4;
  color: #5f6877;
  overflow: hidden;
  position: relative;
  transition: color .2s ease-out, border .2s ease-out, background-color .1s ease-out, box-shadow .1s ease-out
}

.employees__employee>* {
  position: relative;
  z-index: 1
}

.employees__employee:hover {
  background-color: #dce5e9;
  box-shadow: 0 10px 29px rgba(70, 81, 86, .18);
  transition: color .2s ease-in, border .2s ease-in, background-color .1s ease-in, box-shadow .1s ease-in
}

.employees__employee__image {
  bottom: 0;
  height: auto !important;
  overflow: visible;
  position: absolute;
  right: -85px;
  z-index: 0
}

.employees {
  margin-left: -12px;
  margin-right: -12px
}

.employees>* {
  border-radius: 10px;
  display: flex;
  flex-basis: calc(25% - 24px);
  flex-direction: column;
  flex-grow: 0;
  margin-bottom: 24px;
  margin-left: 12px;
  margin-right: 12px;
  max-width: 291px;
  min-height: 494px;
  padding: 45px 30px
}

.employees>* .employees__employee__image {
  height: 242px !important;
  left: 50% !important;
  margin-left: -139px !important;
  position: absolute;
  width: 278px !important
}

.employees>* .employees__employee__profession,
.employees>* .employees__employee__teaser {
  display: none
}

.employees>* .employees__employee__specialisations {
  order: 1
}

.employees>* .employees__employee__specialisations>* {
  margin-bottom: 11px
}

.employees>* .employees__employee__specialisations>:not(:first-child) {
  display: none
}

.employees>* .employees__employee__activity {
  margin-bottom: 16px;
  order: 2 !important
}

.employees>* .employees__employee__fio {
  margin-bottom: 12px;
  order: 3 !important
}

.employees>* .employees__employee__feature {
  background-size: 0 0;
  order: 4 !important;
  padding-left: 0
}

.employees>* .employees__employee__video {
  bottom: 24px;
  left: 50%;
  margin-left: -30px;
  min-height: 60px;
  order: 5 !important;
  position: absolute
}

.employees>* .employees__employee__video__link button {
  margin-right: 0
}

.employees>* .employees__employee__video__link div {
  display: none
}

.employees_first-page>:first-child,
.employees_first-page>:nth-child(2),
.employees_first-page>:nth-child(8),
.employees_first-page>:nth-child(9) {
  flex-basis: calc(50% - 24px);
  max-width: inherit !important;
  max-width: none !important;
  padding-left: 45px;
  padding-right: 45px
}

.employees_first-page>:first-child .employees__employee__image,
.employees_first-page>:nth-child(2) .employees__employee__image,
.employees_first-page>:nth-child(8) .employees__employee__image,
.employees_first-page>:nth-child(9) .employees__employee__image {
  height: 397px !important;
  left: inherit !important;
  left: auto !important;
  margin-left: 0 !important;
  position: absolute;
  width: 456px !important
}

.employees_first-page>:first-child .employees__employee__feature,
.employees_first-page>:nth-child(2) .employees__employee__feature,
.employees_first-page>:nth-child(8) .employees__employee__feature,
.employees_first-page>:nth-child(9) .employees__employee__feature {
  background-size: 60px 60px;
  margin-bottom: 30px;
  order: inherit !important;
  order: 0 !important;
  padding-left: 78px
}

.employees_first-page>:first-child .employees__employee__profession,
.employees_first-page>:first-child .employees__employee__teaser,
.employees_first-page>:nth-child(2) .employees__employee__profession,
.employees_first-page>:nth-child(2) .employees__employee__teaser,
.employees_first-page>:nth-child(8) .employees__employee__profession,
.employees_first-page>:nth-child(8) .employees__employee__teaser,
.employees_first-page>:nth-child(9) .employees__employee__profession,
.employees_first-page>:nth-child(9) .employees__employee__teaser {
  display: block
}

.employees_first-page>:first-child .employees__employee__fio,
.employees_first-page>:nth-child(2) .employees__employee__fio,
.employees_first-page>:nth-child(8) .employees__employee__fio,
.employees_first-page>:nth-child(9) .employees__employee__fio {
  margin-bottom: 7px
}

.employees_first-page>:first-child .employees__employee__profession,
.employees_first-page>:nth-child(2) .employees__employee__profession,
.employees_first-page>:nth-child(8) .employees__employee__profession,
.employees_first-page>:nth-child(9) .employees__employee__profession {
  margin-bottom: 19px
}

.employees_first-page>:first-child .employees__employee__teaser,
.employees_first-page>:nth-child(2) .employees__employee__teaser,
.employees_first-page>:nth-child(8) .employees__employee__teaser,
.employees_first-page>:nth-child(9) .employees__employee__teaser {
  margin-bottom: 17px;
  min-height: 156px
}

.employees_first-page>:first-child .employees__employee__video,
.employees_first-page>:nth-child(2) .employees__employee__video,
.employees_first-page>:nth-child(8) .employees__employee__video,
.employees_first-page>:nth-child(9) .employees__employee__video {
  bottom: 0;
  left: 0;
  margin-bottom: 15px;
  margin-left: 0;
  position: relative
}

.employees_first-page>:first-child .employees__employee__video__link,
.employees_first-page>:nth-child(2) .employees__employee__video__link,
.employees_first-page>:nth-child(8) .employees__employee__video__link,
.employees_first-page>:nth-child(9) .employees__employee__video__link {
  align-items: center;
  color: #a5a5a5;
  display: flex;

  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 22px
}

.employees_first-page>:first-child .employees__employee__video__link button,
.employees_first-page>:nth-child(2) .employees__employee__video__link button,
.employees_first-page>:nth-child(8) .employees__employee__video__link button,
.employees_first-page>:nth-child(9) .employees__employee__video__link button {
  margin-right: 15px !important
}

.employees_first-page>:first-child .employees__employee__video__link div,
.employees_first-page>:nth-child(2) .employees__employee__video__link div,
.employees_first-page>:nth-child(8) .employees__employee__video__link div,
.employees_first-page>:nth-child(9) .employees__employee__video__link div {
  display: block !important
}

.employees_first-page>:first-child .employees__employee__specialisations>*,
.employees_first-page>:nth-child(2) .employees__employee__specialisations>*,
.employees_first-page>:nth-child(8) .employees__employee__specialisations>*,
.employees_first-page>:nth-child(9) .employees__employee__specialisations>* {
  margin-bottom: 10px;
  margin-right: 10px
}

.employees_first-page>:first-child .employees__employee__specialisations>:not(:first-child),
.employees_first-page>:nth-child(2) .employees__employee__specialisations>:not(:first-child),
.employees_first-page>:nth-child(8) .employees__employee__specialisations>:not(:first-child),
.employees_first-page>:nth-child(9) .employees__employee__specialisations>:not(:first-child) {
  display: inline-block
}

.employees_first-page>:first-child .employees__employee__activity,
.employees_first-page>:nth-child(2) .employees__employee__activity,
.employees_first-page>:nth-child(8) .employees__employee__activity,
.employees_first-page>:nth-child(9) .employees__employee__activity {
  margin-bottom: 15px
}

.employees_first-page>:first-child .employees__employee__activity,
.employees_first-page>:first-child .employees__employee__fio,
.employees_first-page>:first-child .employees__employee__specialisations,
.employees_first-page>:first-child .employees__employee__video,
.employees_first-page>:nth-child(2) .employees__employee__activity,
.employees_first-page>:nth-child(2) .employees__employee__fio,
.employees_first-page>:nth-child(2) .employees__employee__specialisations,
.employees_first-page>:nth-child(2) .employees__employee__video,
.employees_first-page>:nth-child(8) .employees__employee__activity,
.employees_first-page>:nth-child(8) .employees__employee__fio,
.employees_first-page>:nth-child(8) .employees__employee__specialisations,
.employees_first-page>:nth-child(8) .employees__employee__video,
.employees_first-page>:nth-child(9) .employees__employee__activity,
.employees_first-page>:nth-child(9) .employees__employee__fio,
.employees_first-page>:nth-child(9) .employees__employee__specialisations,
.employees_first-page>:nth-child(9) .employees__employee__video {
  order: inherit !important;
  order: 0 !important
}

.employees__employee__feature {
  font-size: 14px;
  height: 60px;
  line-height: 20px;
  max-width: 320px
}

.employees__employee__fio {
  color: #3c4143;

  font-size: 20px;
  font-style: normal;
  font-weight: 500
}

.employees__employee__profession {
  font-size: 14px;
  line-height: 24px;
  max-width: 370px
}

.employees__employee__profession_mobile {
  display: none !important
}

.employees__employee__teaser {
  font-size: 14px;
  line-height: 26px;
  max-width: 255px
}

.employees__employee__activity {
  color: #a5a5a5;
  font-size: 14px;
  line-height: 26px
}

.employees__employee__activity span {
  margin-right: 16px
}

.employees__employee__specialisations>* {
  display: inline-block
}

.employees__tile {
  background-position: 62px 0;
  display: flex;
  flex-basis: calc(100% - 24px);
  height: 262px;
  justify-content: center;
  max-width: 100%;
  min-height: 262px;
  padding-left: 415px
}

.employees__tile .employees__tile__title {
  margin-top: 0
}

.employees__tile__title {

  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  margin-top: 22px
}

.employees__tile__text {
  line-height: 26px;
  margin-top: 23px
}

.employees__european-quality {
  background-color: #a591b6;
  background-image: url(/build/i/a388d748a623a86cde1d.png);
  color: #fff;
  transition: color .2s ease-out, border .2s ease-out, background-color .1s ease-out, box-shadow .1s ease-out
}

.employees__european-quality:hover {
  background-color: #8c72a2;
  box-shadow: 0 10px 29px rgba(70, 81, 86, .18);
  transition: color .2s ease-in, border .2s ease-in, background-color .1s ease-in, box-shadow .1s ease-in
}

.employees__best-dentistry {
  background-color: #f1e8c4;
  background-image: url(/build/i/1f5c3fe5b38621636fea.png);
  transition: color .2s ease-out, border .2s ease-out, background-color .1s ease-out, box-shadow .1s ease-out
}

.employees__best-dentistry:hover {
  background-color: #e7d89b;
  box-shadow: 0 10px 29px rgba(70, 81, 86, .18);
  transition: color .2s ease-in, border .2s ease-in, background-color .1s ease-in, box-shadow .1s ease-in
}

.employees__education-center {
  background-color: #9e7eb9;
  background-image: url(/build/i/9c3b309a7964bd129ba0.png);
  color: #fff;
  transition: color .2s ease-out, border .2s ease-out, background-color .1s ease-out, box-shadow .1s ease-out
}

.employees__education-center:hover {
  background-color: #855da7;
  box-shadow: 0 10px 29px rgba(70, 81, 86, .18);
  transition: color .2s ease-in, border .2s ease-in, background-color .1s ease-in, box-shadow .1s ease-in
}

.employees__education-center__title {
  max-width: 465px
}

.employees__education-center__text {
  max-width: 490px
}

.employees__prodoctorov {
  background-color: #d3ebf7;
  background-image: url(/build/i/4aaac44002a57020e276.png);
  color: #333638;
  transition: color .2s ease-out, border .2s ease-out, background-color .1s ease-out, box-shadow .1s ease-out
}

.employees__prodoctorov:hover {
  background-color: #a8d7ef;
  box-shadow: 0 10px 29px rgba(70, 81, 86, .18);
  transition: color .2s ease-in, border .2s ease-in, background-color .1s ease-in, box-shadow .1s ease-in
}

.employees__prodoctorov__text {
  max-width: 660px
}

.employees__best-dentistry__title {
  color: #3c4143
}

.employees__best-dentistry__title>span {
  color: #df8200
}

.employees__best-dentistry__text {
  color: #5f6877
}

.employees__champions {
  background-color: #60b9b8;
  background-image: url(/build/i/6dd078919ce01f12b5e3.png);
  transition: color .2s ease-out, border .2s ease-out, background-color .1s ease-out, box-shadow .1s ease-out
}

.employees__champions:hover {
  background-color: #46a09f;
  box-shadow: 0 10px 29px rgba(70, 81, 86, .18);
  transition: color .2s ease-in, border .2s ease-in, background-color .1s ease-in, box-shadow .1s ease-in
}

.employees__champions__title {
  color: #fff
}

.employees__champions__title>span {
  color: #3c4143
}

.employees__champions__text {
  color: #fff
}

.employees-list__more {
  margin-top: 20px;
  text-align: center
}

@media screen and (max-width:1363px) {
  .employees__employee__image {
    right: -125px
  }

  .employees__employee__fio {
    max-width: 192px
  }

  .employees>*,
  .employees>:not(.employees__tile) {
    max-width: inherit !important;
    max-width: none !important
  }

  .employees>:not(.employees__tile) {
    flex-basis: calc(50% - 24px);
    padding-left: 45px;
    padding-right: 45px
  }

  .employees>:not(.employees__tile) .employees__employee__image {
    height: 397px !important;
    left: inherit !important;
    left: auto !important;
    margin-left: 0 !important;
    position: absolute;
    width: 456px !important
  }

  .employees>:not(.employees__tile) .employees__employee__feature {
    background-size: 60px 60px;
    margin-bottom: 30px;
    order: inherit !important;
    order: 0 !important;
    padding-left: 78px
  }

  .employees>:not(.employees__tile) .employees__employee__profession,
  .employees>:not(.employees__tile) .employees__employee__teaser {
    display: block
  }

  .employees>:not(.employees__tile) .employees__employee__fio {
    margin-bottom: 7px
  }

  .employees>:not(.employees__tile) .employees__employee__profession {
    margin-bottom: 19px
  }

  .employees>:not(.employees__tile) .employees__employee__teaser {
    margin-bottom: 17px;
    min-height: 156px
  }

  .employees>:not(.employees__tile) .employees__employee__video {
    bottom: 0;
    left: 0;
    margin-bottom: 15px;
    margin-left: 0;
    position: relative
  }

  .employees>:not(.employees__tile) .employees__employee__video__link {
    align-items: center;
    color: #a5a5a5;
    display: flex;

    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 22px
  }

  .employees>:not(.employees__tile) .employees__employee__video__link button {
    margin-right: 15px !important
  }

  .employees>:not(.employees__tile) .employees__employee__video__link div {
    display: block !important
  }

  .employees>:not(.employees__tile) .employees__employee__specialisations>* {
    margin-bottom: 10px;
    margin-right: 10px
  }

  .employees>:not(.employees__tile) .employees__employee__specialisations>:not(:first-child) {
    display: inline-block
  }

  .employees>:not(.employees__tile) .employees__employee__activity {
    margin-bottom: 15px
  }

  .employees>:not(.employees__tile) .employees__employee__activity,
  .employees>:not(.employees__tile) .employees__employee__fio,
  .employees>:not(.employees__tile) .employees__employee__specialisations,
  .employees>:not(.employees__tile) .employees__employee__video {
    order: inherit !important;
    order: 0 !important
  }

  .employees_first-page>:not(.employees__tile) {
    flex-basis: calc(50% - 24px);
    max-width: inherit !important;
    max-width: none !important;
    padding-left: 45px;
    padding-right: 45px
  }

  .employees_first-page>:not(.employees__tile) .employees__employee__image {
    height: 397px !important;
    left: inherit !important;
    left: auto !important;
    margin-left: 0 !important;
    position: absolute;
    width: 456px !important
  }

  .employees_first-page>:not(.employees__tile) .employees__employee__feature {
    background-size: 60px 60px;
    margin-bottom: 30px;
    order: inherit !important;
    order: 0 !important;
    padding-left: 78px
  }

  .employees_first-page>:not(.employees__tile) .employees__employee__profession,
  .employees_first-page>:not(.employees__tile) .employees__employee__teaser {
    display: block
  }

  .employees_first-page>:not(.employees__tile) .employees__employee__fio {
    margin-bottom: 7px
  }

  .employees_first-page>:not(.employees__tile) .employees__employee__profession {
    margin-bottom: 19px
  }

  .employees_first-page>:not(.employees__tile) .employees__employee__teaser {
    margin-bottom: 17px;
    min-height: 156px
  }

  .employees_first-page>:not(.employees__tile) .employees__employee__video {
    bottom: 0;
    left: 0;
    margin-bottom: 15px;
    margin-left: 0;
    position: relative
  }

  .employees_first-page>:not(.employees__tile) .employees__employee__video__link {
    align-items: center;
    color: #a5a5a5;
    display: flex;

    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 22px
  }

  .employees_first-page>:not(.employees__tile) .employees__employee__video__link button {
    margin-right: 15px !important
  }

  .employees_first-page>:not(.employees__tile) .employees__employee__video__link div {
    display: block !important
  }

  .employees_first-page>:not(.employees__tile) .employees__employee__specialisations>* {
    margin-bottom: 10px;
    margin-right: 10px
  }

  .employees_first-page>:not(.employees__tile) .employees__employee__specialisations>:not(:first-child) {
    display: inline-block
  }

  .employees_first-page>:not(.employees__tile) .employees__employee__activity {
    margin-bottom: 15px
  }

  .employees_first-page>:not(.employees__tile) .employees__employee__activity,
  .employees_first-page>:not(.employees__tile) .employees__employee__fio,
  .employees_first-page>:not(.employees__tile) .employees__employee__specialisations,
  .employees_first-page>:not(.employees__tile) .employees__employee__video {
    order: inherit !important;
    order: 0 !important
  }

  .employees__employee__teaser {
    max-width: 240px
  }

  .employees__tile {
    background-position: 45px 0;
    padding-left: 360px
  }

  .employees__tile .employees__tile__title {
    margin-top: 0
  }
}

@media screen and (max-width:1023px) {
  .employees__employee__profession_mobile {
    display: block !important;
    margin-bottom: 0 !important;
    order: 3
  }

  .employees>:not(.employees__tile) {
    border-radius: 10px;
    display: flex;
    flex-basis: calc(25% - 24px);
    flex-basis: calc(50% - 24px);
    flex-direction: column;
    flex-grow: 0;
    margin-bottom: 24px;
    margin-left: 12px;
    margin-right: 12px;
    max-width: 291px;
    min-height: 494px;
    padding: 45px 30px
  }

  .employees>:not(.employees__tile) .employees__employee__image {
    height: 242px !important;
    left: 50% !important;
    margin-left: -139px !important;
    position: absolute;
    width: 278px !important
  }

  .employees>:not(.employees__tile) .employees__employee__profession,
  .employees>:not(.employees__tile) .employees__employee__teaser {
    display: none
  }

  .employees>:not(.employees__tile) .employees__employee__specialisations {
    order: 1
  }

  .employees>:not(.employees__tile) .employees__employee__specialisations>* {
    margin-bottom: 11px
  }

  .employees>:not(.employees__tile) .employees__employee__specialisations>:not(:first-child) {
    display: none
  }

  .employees>:not(.employees__tile) .employees__employee__activity {
    margin-bottom: 16px;
    order: 2 !important
  }

  .employees>:not(.employees__tile) .employees__employee__fio {
    margin-bottom: 12px;
    order: 3 !important
  }

  .employees>:not(.employees__tile) .employees__employee__feature {
    background-size: 0 0;
    order: 4 !important;
    padding-left: 0
  }

  .employees>:not(.employees__tile) .employees__employee__video {
    bottom: 24px;
    left: 50%;
    margin-left: -30px;
    min-height: 60px;
    order: 5 !important;
    position: absolute
  }

  .employees>:not(.employees__tile) .employees__employee__video__link button {
    margin-right: 0
  }

  .employees>:not(.employees__tile) .employees__employee__video__link div {
    display: none
  }

  .employees>:not(.employees__tile) .employees__employee__video {
    margin-bottom: 0
  }

  .employees>:not(.employees__tile) .employees__employee__video__link button {
    margin-right: 0 !important
  }

  .employees>:not(.employees__tile) .employees__employee__video__link div {
    display: none !important
  }

  .employees_first-page>* {
    max-width: inherit !important;
    max-width: none !important;
    padding-left: 32px;
    padding-right: 32px
  }

  .employees_first-page>:not(.employees__tile) {
    border-radius: 10px;
    display: flex;
    flex-basis: calc(25% - 24px);
    flex-basis: calc(50% - 24px);
    flex-direction: column;
    flex-grow: 0;
    margin-bottom: 24px;
    margin-left: 12px;
    margin-right: 12px;
    max-width: 291px;
    min-height: 494px;
    padding: 45px 30px
  }

  .employees_first-page>:not(.employees__tile) .employees__employee__image {
    height: 242px !important;
    left: 50% !important;
    margin-left: -139px !important;
    position: absolute;
    width: 278px !important
  }

  .employees_first-page>:not(.employees__tile) .employees__employee__profession,
  .employees_first-page>:not(.employees__tile) .employees__employee__teaser {
    display: none
  }

  .employees_first-page>:not(.employees__tile) .employees__employee__specialisations {
    order: 1
  }

  .employees_first-page>:not(.employees__tile) .employees__employee__specialisations>* {
    margin-bottom: 11px
  }

  .employees_first-page>:not(.employees__tile) .employees__employee__specialisations>:not(:first-child) {
    display: none
  }

  .employees_first-page>:not(.employees__tile) .employees__employee__activity {
    margin-bottom: 16px;
    order: 2 !important
  }

  .employees_first-page>:not(.employees__tile) .employees__employee__fio {
    margin-bottom: 12px;
    order: 3 !important
  }

  .employees_first-page>:not(.employees__tile) .employees__employee__feature {
    background-size: 0 0;
    order: 4 !important;
    padding-left: 0
  }

  .employees_first-page>:not(.employees__tile) .employees__employee__video {
    bottom: 24px;
    left: 50%;
    margin-left: -30px;
    min-height: 60px;
    order: 5 !important;
    position: absolute
  }

  .employees_first-page>:not(.employees__tile) .employees__employee__video__link button {
    margin-right: 0
  }

  .employees_first-page>:not(.employees__tile) .employees__employee__video__link div {
    display: none
  }

  .employees__tile {
    background-position: top right 25px;
    background-size: 145px 121px
  }
}

@media screen and (max-width:767px) {
  .employees>* {
    flex-basis: calc(100% - 24px) !important;
    margin-bottom: 15px;
    padding-left: 25px !important;
    padding-right: 25px !important
  }

  .employees__best-dentistry,
  .employees__champions,
  .employees__education-center,
  .employees__european-quality,
  .employees__prodoctorov {
    display: none
  }
}

.with-image-slider-wrapper {
  position: relative
}

.with-image-slider-wrapper .image-slider {
  max-width: 600px
}

.with-image-slider-wrapper .image-slider .slick-list {
  border-radius: 10px;
  overflow: hidden
}

.with-image-slider-wrapper_circle:before {
  border: 50px solid #eaf1f3;
  border-radius: 50%;
  content: "";
  display: block;
  height: 326px;
  left: -95px;
  position: absolute;
  top: -100px;
  width: 326px;
  z-index: 0
}

.with-image-slider-wrapper_tabs .tabs__labels {
  margin-bottom: 30px
}

.with-image-slider-wrapper_tabs .tabs__labels.tabs__labels_buttons .tab__label.active {
  background-color: #eef2f4;
  border-color: #eef2f4;
  color: #5f6877
}

.with-image-slider-wrapper_tabs .with-image-slider {
  align-items: flex-start
}

.with-image-slider-wrapper_tabs .with-image-slider .tab {
  margin-top: 0
}

.with-image-slider-wrapper_tabs .with-image-slider__title {
  margin-bottom: 45px
}

.with-image-slider-wrapper_tabs .with-image-slider__text {
  margin-top: 45px
}

.with-image-slider {
  margin-left: -12px;
  margin-right: -12px;
  position: relative;
  z-index: 1
}

.with-image-slider .strong,
.with-image-slider b {

  font-style: normal;
  font-weight: 500
}

.with-image-slider>* {
  flex-basis: calc(50% - 24px);
  margin-left: 12px;
  margin-right: 12px;
  max-width: calc(50% - 24px)
}

.with-image-slider>:first-child {
  margin-top: 15px;
  padding-top: 18px
}

.with-image-slider .slider__item__image {
  height: auto;
  width: 100%
}

.with-image-slider_left>:first-child {
  order: 1;
  padding-left: 80px;
  padding-top: 12px
}

.with-image-slider_left>:nth-child(2) {
  order: 0
}

.with-image-slider_left .image-slider__navigator {
  left: auto;
  right: -61px
}

.with-image-slider_left .with-image-slider__text {
  margin-top: 50px
}

.with-image-slider-wrapper_compact .with-image-slider>:first-child {
  padding-top: 0
}

.payment-page .with-image-slider-wrapper_compact h2,
.with-image-slider-wrapper_compact .h2,
.with-image-slider-wrapper_compact .payment-page h2 {
  font-size: 46px;
  line-height: 52px
}

.with-image-slider-wrapper_compact .with-image-slider__text {
  margin-top: 35px
}

.with-image-slider-wrapper_compact .with-image-slider__button {
  margin-top: 40px
}

.with-image-slider_mar.with-image-slider_left>:first-child {
  margin-top: 0;
  padding-top: 0
}

.with-image-slider_mar .with-image-slider__text {
  margin-top: 40px
}

.with-image-slider__title {
  color: #333638;
  display: inline-block;
  text-align: left
}

.with-image-slider__text {
  line-height: 30px;
  margin-top: 35px;
  max-width: 520px
}

.with-image-slider__text p:not(:last-child) {
  margin-bottom: 17px
}

.with-image-slider__text .strong,
.with-image-slider__text strong {

  font-style: normal;
  font-weight: 500
}

.with-image-slider__text .minus-list,
.with-image-slider__text .plus-list {
  text-align: left
}

.with-image-slider__text .minus-list .text,
.with-image-slider__text .plus-list .text {
  max-width: none
}

.with-image-slider__button {
  margin-top: 70px
}

.education-slider__persons {
  display: flex;
  justify-content: space-between;
  margin-top: 40px
}

.education-slider__persons>div {
  text-align: center
}

.education-slider__persons .progressive-image-wrapper {
  background-color: #ffe7a6;
  border-radius: 50%;
  height: 90px !important;
  margin-bottom: 15px;
  position: relative;
  width: 90px !important
}

.education-slider__persons .progressive-image-wrapper img {
  bottom: -10px;
  left: 50%;
  margin-left: -60px;
  position: absolute;
  width: 120px
}

.education-slider__experience {
  color: #a5a5a5;
  font-size: 13px;
  margin-top: 5px
}

.with-image-slider__title_mobile {
  display: none
}

.with-image-slider-wrapper_dots {
  background-position: top 5px center;
  padding-bottom: 55px;
  padding-top: 130px
}

.with-image-slider-wrapper_dots .with-image-slider>:first-child {
  margin-top: 0;
  padding-top: 0
}

.with-image-slider-wrapper_dots .with-image-slider__title {
  font-size: 42px
}

.with-image-slider-wrapper_dots .page-header__feature__awards {
  margin-bottom: 25px
}

.with-image-slider-wrapper_dots .with-image-slider__text {
  margin-top: 25px
}

.with-image-slider__title__image {
  margin-bottom: 20px;
  max-width: 190px
}

.with-image-slider-wrapper_allon4 .with-image-slider_left>:first-child {
  padding-top: 0
}

.payment-page .with-image-slider-wrapper_allon4 h2,
.with-image-slider-wrapper_allon4 .h2,
.with-image-slider-wrapper_allon4 .payment-page h2 {
  font-size: 42px
}

.with-image-slider-wrapper_allon4 .with-image-slider__text {
  margin-top: 20px
}

.with-image-slider-wrapper_allon4 .minus-list>li,
.with-image-slider-wrapper_allon4 .plus-list>li {
  margin-bottom: 14px
}

@media screen and (max-width:1363px) {
  .with-image-slider-wrapper_circle:before {
    content: none
  }

  .with-image-slider:not(.with-image-slider_left)>:first-child {
    padding-right: 54px
  }

  .with-image-slider-wrapper .image-slider__navigator {
    top: 220px
  }

  .with-image-slider-wrapper_dots {
    padding-top: 105px
  }

  .with-image-slider-wrapper_dots .with-image-slider__title {
    font-size: 36px
  }

  .with-image-slider-wrapper_dots+.pusher {
    margin-top: 50px !important
  }

  .slider__item__image-wrapper .progressive-image-wrapper {
    max-width: 100%
  }

  .with-image-slider_left .image-slider__navigator {
    right: -53px
  }

  .with-image-slider_left>:first-child {
    padding-left: 54px
  }

  .payment-page .with-image-slider-wrapper_allon4 h2,
  .with-image-slider-wrapper_allon4 .h2,
  .with-image-slider-wrapper_allon4 .payment-page h2 {
    font-size: 36px
  }
}

@media screen and (max-width:1100px) {
  .with-image-slider_mar.with-image-slider_left>:first-child {
    padding-left: 55px
  }
}

@media screen and (max-width:1023px) {
  .with-image-slider {
    text-align: center
  }

  .with-image-slider>* {
    max-width: none
  }

  .with-image-slider>:first-child {
    padding-left: 0
  }

  .with-image-slider:not(.with-image-slider_left)>:first-child,
  .with-image-slider>:nth-child(2) {
    padding-right: 0
  }

  .with-image-slider__title {
    display: block;
    text-align: center
  }

  .with-image-slider__title br {
    display: none
  }

  .with-image-slider__text {
    max-width: 100%
  }

  .with-image-slider-wrapper .image-slider {
    margin-left: auto;
    margin-right: auto;
    max-width: 600px
  }

  .with-image-slider-wrapper .slider__item__image {
    height: 415px;
    width: auto
  }

  .with-image-slider-wrapper .image-slider__navigator {
    bottom: 0;
    left: 50%;
    margin-left: -60px;
    top: inherit !important;
    top: auto !important
  }

  .slider-wrapper-image {
    float: none;
    margin-bottom: 50px;
    padding-bottom: 22px
  }

  .slider__item__image-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 30px
  }

  .slider__item__image-wrapper>* {
    flex: 0 0 100%
  }

  .slider__item__image-wrapper .slider__item__image {
    max-width: 100%;
    order: 2
  }

  .slider__item__image-wrapper .slider__item__image-description {
    margin-bottom: 21px;
    margin-left: 0 !important;
    order: 1;
    text-align: center
  }

  .with-image-slider__button {
    margin-top: 40px
  }

  .education-slider__persons {
    margin: 40px auto 0;
    max-width: 550px
  }

  .with-image-slider_mar.with-image-slider_left>:first-child {
    padding-left: 0
  }

  .education-slider__persons>div {
    width: 32%
  }

  .with-image-slider-wrapper_dots {
    margin-top: 55px;
    padding-bottom: 0;
    padding-top: 60px
  }

  .with-image-slider-wrapper_dots .with-image-slider__title {
    text-align: left
  }

  .with-image-slider-wrapper_dots .page-header__feature__awards {
    margin-bottom: 25px
  }

  .with-image-slider-wrapper_dots .with-image-slider__text {
    margin-top: 25px
  }

  .with-image-slider-wrapper_dots .slider-wrapper-image {
    margin-bottom: 0
  }

  .with-image-slider-wrapper_allon4 .with-image-slider>:first-child {
    margin-top: 0
  }

  .with-image-slider-wrapper_allon4 .with-image-slider_left>:nth-child(2) {
    order: 2
  }
}

@media screen and (max-width:767px) {

  .with-image-slider,
  .with-image-slider>* {
    margin-left: 0;
    margin-right: 0
  }

  .with-image-slider>* {
    flex-basis: 100%
  }

  .with-image-slider>:first-child {
    margin-bottom: 55px
  }

  .with-image-slider-wrapper .image-slider,
  .with-image-slider>:last-child {
    max-width: 100%
  }

  .with-image-slider-wrapper .slider__item__image {
    border-radius: 0;
    height: auto;
    width: 100%
  }

  .with-image-slider-wrapper .image-slider__navigator {
    bottom: -20px;
    margin-left: -50px
  }

  .slider-wrapper-image {
    margin-bottom: 20px;
    margin-left: -15px;
    margin-right: -15px
  }

  .slider-wrapper-image .slider__item__image {
    margin: auto;
    order: 1
  }

  .slider-wrapper-image .slider__item__image-description {
    margin-bottom: 0;
    margin-top: 9px;
    order: 2
  }

  .with-image-slider-wrapper .image-slider .slick-list {
    border-radius: 0
  }

  .with-image-slider_mar.with-image-slider_left>:first-child {
    margin-bottom: 0
  }

  .with-image-slider_zygoma .with-image-slider__title_mobile {
    display: block;
    font-size: 26px !important;
    line-height: 30px
  }

  .with-image-slider_zygoma .with-image-slider__title {
    display: none
  }

  .with-image-slider_zygoma .with-image-slider__text {
    margin-top: 0;
    text-align: left
  }

  .with-image-slider_zygoma+.pusher {
    margin-top: 50px !important
  }

  .with-image-slider-wrapper_dots .with-image-slider>:first-child {
    margin-bottom: 0
  }

  .with-image-slider-wrapper_dots .with-image-slider__title__image {
    margin-left: auto;
    margin-right: auto
  }

  .with-image-slider-wrapper_dots .with-image-slider__title {
    font-size: 26px !important;
    line-height: normal;
    text-align: center
  }

  .with-image-slider-wrapper_dots .page-header__feature__awards {
    justify-content: center
  }

  .with-image-slider-wrapper_dots .page-header__feature__awards .progressive-image-wrapper {
    margin-right: 10px
  }

  .with-image-slider-wrapper_dots .page-header__feature__awards .progressive-image-wrapper:last-child {
    margin-right: 0
  }

  .with-image-slider-wrapper_dots .slider__item__image-wrapper {
    margin-bottom: -5px;
    margin-top: 15px
  }

  .with-image-slider-wrapper_dots+.pusher {
    margin-top: 62px !important
  }

  .slider-wrapper-image {
    padding-bottom: 0
  }

  .with-image-slider-wrapper_allon4 .with-image-slider>:first-child {
    margin-bottom: 0
  }

  .with-image-slider-wrapper_allon4 .slider__item__image-wrapper {
    margin-top: 20px
  }
}

@media screen and (max-width:480px) {
  .education-slider__persons {
    flex-wrap: wrap;
    justify-content: center;
    margin: 40px auto 0;
    max-width: 200px
  }

  .education-slider__persons>div {
    margin-bottom: 35px;
    width: 100%
  }

  .education-slider__persons>div:last-child {
    margin-bottom: 0
  }
}

@media screen and (max-width:450px) {
  .with-image-slider>:first-child {
    margin-top: 0;
    padding-top: 0
  }
}

.offers__grid {
  justify-content: flex-start;
  margin-left: -12px;
  margin-right: -12px
}

.offers__grid>* {
  flex: 0 0 calc(50% - 24px);
  margin-bottom: 24px;
  margin-left: 12px;
  margin-right: 12px
}

.offers-main-offer {
  margin-bottom: 122px;
  margin-top: 200px
}

@media screen and (max-width:1023px) {
  .offers-main-offer {
    margin-bottom: 56px;
    margin-top: 100px
  }

  .offers__grid>* {
    flex-basis: calc(100% - 24px);
    margin-bottom: 24px;
    margin-left: 12px;
    margin-right: 12px
  }
}

@media screen and (max-width:767px) {
  .offers-main-offer {
    margin-top: 0
  }

  .offers-main-offer .offer__card:before {
    background-position: 0 9px;
    border-radius: 0
  }

  .offers-list .row,
  .offers-list .row_short,
  .offers-main-offer .row,
  .offers-main-offer .row_short {
    padding-left: 0;
    padding-right: 0
  }

  .offers__grid,
  .offers__grid>* {
    margin-left: 0;
    margin-right: 0
  }

  .offers__grid>* {
    flex: 1 0 100%
  }
}

.offer_standard .offer__card {
  border-radius: 10px;
  display: block;
  height: 328px;
  -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
  overflow: hidden;
  padding: 60px;
  position: relative;
  width: 100%
}

.offer_standard .offer__card:before {
  background-position: 4px 2px;
  bottom: 0;
  content: "";
  display: block;
  height: 168px;
  position: absolute;
  right: 0;
  width: 272px
}

.offer_standard .offer__card:after {
  background-size: cover;
  bottom: 30px;
  content: "";
  display: block;
  height: 180px;
  position: absolute;
  right: 52px;
  width: 180px
}

.offer_standard .offer__title {
  color: #3c4143;

  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 10px
}

.offer_standard .offer__title__mark {
  color: #df8200
}

.offer_standard .offer__old-price_title {

  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  text-decoration: line-through
}

.offer_standard .offer__price {
  color: #df8200;

  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px
}

.offer_standard .offer__time {
  color: #5f6877;
  line-height: 26px;
  margin-top: 21px
}

.offer_standard .offer__button {
  margin-top: 42px;
  z-index: 1
}

.offer_standard .offer__details {
  margin-bottom: 90px;
  margin-top: 54px;
  padding-left: 60px;
  padding-right: 40px
}

.offer_standard .offer__details .minus-list li,
.offer_standard .offer__details .plus-list li {
  margin-bottom: 23px
}

.offer_standard .offer__details .minus-list li a,
.offer_standard .offer__details .plus-list li a {
  font-size: 16px
}

.offer_standard .offer__details__list-title {
  line-height: 26px;
  margin-bottom: 33px
}

.offer_standard .offer__card_all-on-4 {
  background-color: #daf8ff
}

.offer_standard .offer__card_all-on-4:not(div):hover {
  background-color: #b8f2ff
}

.offer_standard .offer__card_all-on-4:after {
  background-image: url(/build/i/3c322462c8fa65663915.svg)
}

.offer_standard .offer__card_all-on-4_article {
  background-color: #f5f6db
}

.offer_standard .offer__card_all-on-4_article:not(div):hover {
  background-color: #eff0c9
}

.offer_standard .offer__card_all-on-4_article:after {
  background-image: url(/build/i/3c322462c8fa65663915.svg)
}

.offer_standard .offer__card_implantation {
  background-color: #daf8ff
}

.offer_standard .offer__card_implantation:not(div):hover {
  background-color: #b8f2ff
}

.offer_standard .offer__card_implantation:after {
  background-image: url(/build/i/73b4993696ecf87b926a.svg)
}

.offer_standard .offer__card_dentures {
  background-color: #f3f1be
}

.offer_standard .offer__card_dentures:not(div):hover {
  background-color: #e9e7b2
}

.offer_standard .offer__card_dentures:after {
  background-image: url(/build/i/047ebdb39e523ca45c7f.svg)
}

.offer_standard .offer__card_crowns {
  background-color: #f2f4fb
}

.offer_standard .offer__card_crowns:not(div):hover {
  background-color: #e2e5f9
}

.offer_standard .offer__card_crowns:after {
  background-image: url(/build/i/6bdeb059202cdb6dfc3f.svg)
}

.offer_standard .offer__card_veneers {
  background-color: #f6ebdb
}

.offer_standard .offer__card_veneers:not(div):hover {
  background-color: #edd8bb
}

.offer_standard .offer__card_veneers:after {
  background-image: url(/build/i/351f5bd60c2d1b2f246b.svg)
}

.offer_standard .offer__card_whitening {
  background-color: #c6fbee
}

.offer_standard .offer__card_whitening:not(div):hover {
  background-color: #a9ecdc
}

.offer_standard .offer__card_whitening:after {
  background-image: url(/build/i/7d29f7fad0f25dc2b349.svg)
}

.offer_standard .offer__card_mouth-guard {
  background-color: #dedef2
}

.offer_standard .offer__card_mouth-guard:not(div):hover {
  background-color: #c6c6ea
}

.offer_standard .offer__card_mouth-guard:after {
  background-image: url(/build/i/a9dc64932a13f525aeb9.svg)
}

.offer_standard .offer__card_hygiene {
  background-color: #dbeaf6
}

.offer_standard .offer__card_hygiene:not(div):hover {
  background-color: #c0daee
}

.offer_standard .offer__card_hygiene:after {
  background-image: url(/build/i/85640666e2caa4707121.svg)
}

.offer_standard .offer__card_hygiene-each3 {
  background-color: #f3f1be
}

.offer_standard .offer__card_hygiene-each3:not(div):hover {
  background-color: #eeeb96
}

.offer_standard .offer__card_hygiene-each3:after {
  background-image: url(/build/i/5927c1c44b7fad305c61.svg)
}

.offer_standard .offer__card_hygiene-free {
  background-color: #f3f1be
}

.offer_standard .offer__card_hygiene-free:not(div):hover {
  background-color: #eeeb96
}

.offer_standard .offer__card_hygiene-free:after {
  background-image: url(/build/i/ddbb0d94af410c2d8593.svg)
}

.offer_standard .offer__card_braces {
  background-color: #dedef2
}

.offer_standard .offer__card_braces:not(div):hover {
  background-color: #c6c6ea
}

.offer_standard .offer__card_braces:after {
  background-image: url(/build/i/5f17a4a99e598d151ebf.svg)
}

.offers-list_hold-price .h2,
.offers-list_hold-price .payment-page h2,
.payment-page .offers-list_hold-price h2 {
  margin-bottom: 55px
}

@media screen and (max-width:1363px) {
  .offer_standard .offer__card {
    height: 278px;
    padding: 30px
  }

  .offer_standard .offer__card:before {
    height: 114px;
    width: 190px
  }

  .offer_standard .offer__card:after {
    bottom: 20px;
    height: 142px;
    right: 30px;
    width: 142px
  }

  .offer_standard .offer__price,
  .offer_standard .offer__title {
    font-size: 24px
  }

  .offer_standard .offer__details {
    margin-bottom: 78px;
    padding-left: 40px
  }

  .offer_standard .offer__details__list-title {
    margin-bottom: 28px
  }
}

@media screen and (max-width:1023px) {
  .offer_standard .offer__details {
    margin-bottom: 34px;
    margin-top: 30px
  }

  .offer_standard .offer__card:before {
    height: 168px;
    width: 272px
  }

  .offer_standard .offer__card:after {
    bottom: 30px;
    height: 180px;
    right: 52px;
    width: 180px
  }
}

@media screen and (max-width:767px) {
  .offer_standard {
    margin-bottom: 0
  }

  .offer_standard .offer__card {
    border-radius: 0;
    height: 300px;
    padding-left: 15px;
    padding-right: 15px
  }

  .offer_standard .offer__card:before {
    height: 114px;
    width: 105px
  }

  .offer_standard .offer__card:after {
    bottom: 13px;
    height: 142px;
    right: -65px;
    width: 142px
  }

  .offer_standard .offer__details {
    display: none
  }

  .offer_standard .offer__price,
  .offer_standard .offer__title {
    display: inline
  }

  .offer_standard .offer__button {
    bottom: 40px;
    position: absolute
  }

  .offers-list_hold-price .h2,
  .offers-list_hold-price .payment-page h2,
  .payment-page .offers-list_hold-price h2 {
    margin-bottom: 35px
  }
}

.offer_big {
  background-color: #eef3f6;
  border-radius: 10px;
  position: relative
}

.offer_big .offer__card {
  background-position: 4px 2px;
  display: block;
  height: 342px;
  padding: 60px;
  position: relative;
  width: 100%;
  z-index: 1
}

.offer_big .offer__card:not(div):hover {
  background-color: #dcecf5
}

.offer_big .offer__card:before {
  background-image: url(/build/i/20bca40c4197f90e27b2.svg);
  background-size: cover;
  content: "";
  display: block;
  height: 310px;
  opacity: .4;
  position: absolute;
  right: 0;
  top: -110px;
  width: 200px
}

.offer_big .offer__card>* {
  position: relative;
  z-index: 1
}

.offer_big .offer__card>:first-child {
  float: left
}

.offer_big .offer__card>:last-child {
  float: right;
  height: 100%
}

.offer_big .offer__card>:last-child>div {
  max-width: 310px
}

.offer_big .offer__card .progressive-image-wrapper {
  overflow: visible
}

.offer_big .offer__title {
  color: #3c4143;

  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 10px
}

.offer_big .offer__title nobr {
  margin-top: 10px
}

.offer_big .offer__old-price_title {

  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  text-decoration: line-through
}

.offer_big .offer__price {
  color: #df8200;

  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 10px
}

.offer_big .offer__time {
  color: #3c4143;
  line-height: 26px
}

.offer_big .offer__button {
  font-size: 13px;
  margin-top: 42px;
  z-index: 1
}

.offer_big .offer__employee {
  color: #3c4143;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px
}

.offer_big .offer__employee-professions {
  color: #aab5b9;
  font-size: 14px;
  line-height: 22px
}

.offer_big .offer__employee-experience {
  color: #3c4143;
  font-size: 14px;
  line-height: 22px;
  margin-top: 11px
}

.offer_big .offer__employee-info {
  color: #3c4143;
  line-height: 26px;
  margin-top: 29px;
  max-width: 308px
}

.offer_big .offer__employee-photo {
  bottom: 0;
  left: 50%;
  max-height: 450px;
  min-width: 500px;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  width: auto !important
}

.offer_big .offer__employee-photo img {
  height: 100%;
  width: auto
}

.offer_big__icon {
  fill: #fff;
  content: "";
  display: block;
  height: 68px;
  position: absolute;
  right: 46px;
  top: 47px;
  width: 68px
}

@media screen and (max-width:1199px) {
  .offer_big .offer__card {
    padding: 60px
  }

  .offer_big .offer__card>:last-child {
    display: none
  }

  .offer_big .offer__price,
  .offer_big .offer__title {
    font-size: 26px
  }

  .offer_big .offer__employee-photo {
    left: auto;
    right: 50px;
    transform: none
  }
}

@media screen and (max-width:1023px) {
  .offer_big {
    -webkit-mask-image: -webkit-radial-gradient(#fff, #000)
  }

  .offer_big .offer__employee-photo {
    height: 100% !important;
    min-width: 390px;
    width: auto !important
  }
}

@media screen and (max-width:900px) {
  .offer_big .offer__employee-photo {
    right: 0
  }
}

@media screen and (max-width:767px) {
  .offer_big {
    border-radius: 0;
    min-height: 400px;
    overflow: hidden
  }

  .offer_big .offer__price,
  .offer_big .offer__title {
    font-size: 24px
  }

  .offer_big .offer__card {
    background-position: 0 22px;
    height: 500px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 70px;
    text-align: center
  }

  .offer_big .offer__card:before {
    content: none
  }

  .offer_big .offer__card>:first-child {
    float: none
  }

  .offer_big .offer__card:before {
    background-position: 0 9px;
    border-radius: 0
  }

  .offer_big .row,
  .offer_big .row_short {
    padding-left: 0;
    padding-right: 0
  }

  .offer_big .offer__employee-photo {
    bottom: -5px;
    height: auto !important;
    left: 50%;
    margin-left: -130px;
    min-width: auto;
    width: 260px !important
  }

  .offer_big .offer__employee-photo img {
    height: auto;
    width: 100%
  }

  .offer_big__icon {
    height: 100%;
    left: 0;
    opacity: .5;
    padding-left: 15px;
    padding-right: 15px;
    right: 0;
    top: 0;
    width: 100%
  }
}

@media screen and (max-width:570px) {
  .offer_big .offer__card {
    height: auto;
    padding-top: 60px
  }

  .offer_big .offer__employee-photo {
    display: none
  }
}

.fair-prices {
  background-color: #f1edec;
  height: 694px;
  margin-bottom: 125px
}

.fair-prices__content {
  height: auto;
  position: relative;
  width: 100%;
  z-index: 1
}

.fair-prices__content>* {
  max-width: 510px
}

.fair-prices__title {
  color: #333638;
  font-size: 55px;
  margin-top: 132px;
  text-align: left
}

.fair-prices__sub-title {

  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-top: 46px
}

.fair-prices__text {
  line-height: 30px;
  margin-top: 24px
}

.fair-prices__play {
  display: inline-block;
  margin-top: 44px;
  padding-left: 90px;
  position: relative
}

.fair-prices__play_text {
  margin-top: 25px
}

.fair-prices__play_text span {
  display: inline-block
}

.fair-prices__play__button {
  left: 0;
  position: absolute;
  top: 7px
}

.fair-prices__play__title {
  color: #989ca6;
  font-size: 14px;
  font-style: italic;
  line-height: 30px
}

.fair-prices__play__person {
  color: #5f6877;
  font-size: 14px;
  font-style: italic;
  line-height: 22px
}

.fair-prices__items {
  margin-left: -40px;
  margin-right: -40px
}

.fair-prices__items>* {
  flex-basis: calc(50% - 80px);
  margin-left: 40px;
  margin-right: 40px
}

@media screen and (max-width:1363px) {
  .fair-prices .progressive-image {
    background-position-x: -350px
  }
}

@media screen and (max-width:1023px) {
  .fair-prices {
    height: 675px;
    margin-bottom: 50px
  }

  .fair-prices .progressive-image {
    background-position-x: -430px
  }

  .fair-prices__text {
    max-width: 400px
  }

  .fair-prices__text br {
    display: none
  }

  .fair-prices__play {
    padding-left: 68px;
    text-align: left
  }

  .fair-prices__play__button {
    height: 50px;
    top: 12px;
    width: 50px
  }

  .fair-prices__play__button:before {
    margin-left: -6px
  }

  .fair-prices__items {
    margin-left: -20px;
    margin-right: -20px
  }

  .fair-prices__items>* {
    flex-basis: calc(50% - 40px);
    margin-left: 20px;
    margin-right: 20px
  }
}

@media screen and (max-width:767px) {
  .fair-prices {
    display: flex;
    flex-direction: column;
    height: inherit !important;
    height: auto !important
  }

  .fair-prices>* {
    flex-basis: 100%
  }

  .fair-prices>:first-child {
    order: 2
  }

  .fair-prices>:last-child {
    order: 1
  }

  .fair-prices .progressive-image-bg {
    background-position-x: 65%;
    height: 283px;
    position: relative
  }

  .fair-prices__title {
    font-size: 30px;
    margin-top: 85px;
    text-align: center
  }

  .fair-prices__sub-title {
    margin-top: 25px
  }

  .fair-prices__play__person br {
    display: none
  }

  .fair-prices__play {
    margin-bottom: 30px;
    margin-top: 35px;
    padding-left: 70px
  }

  .fair-prices__items {
    display: none
  }

  .fair-prices__play__button {
    top: 8px
  }
}

.about-employee {
  margin-left: -12px;
  margin-right: -12px;
  position: relative
}

.about-employee>* {
  flex-basis: calc(50% - 24px);
  margin-left: 12px;
  margin-right: 12px
}

.about-employee>:last-child {
  order: 1;
  padding-left: 80px
}

.about-employee p {
  line-height: 30px;
  margin-bottom: 30px
}

.about-employee-wrapper {
  position: relative
}

.about-employee-wrapper .minus-list,
.about-employee-wrapper .plus-list {
  margin-bottom: 40px
}

.about-employee-wrapper_image-right .about-employee>div:nth-child(2) {
  order: -1;
  padding-left: 0;
  padding-right: 80px
}

.about-employee-wrapper_champion .about-employee>div b {

  font-style: normal;
  font-weight: 500
}

.about-employee-wrapper_champion .minus-list>li,
.about-employee-wrapper_champion .plus-list>li {
  margin-bottom: 22px
}

.about-employee-wrapper_champion .minus-list>li .text,
.about-employee-wrapper_champion .plus-list>li .text {
  max-width: 480px
}

.about-employee__media-wrapper {
  margin: 0 auto;
  max-width: 600px
}

.about-employee__media {
  border-radius: 10px;
  height: 0;
  margin-bottom: 54px;
  overflow: hidden;
  padding-top: 73.33%;
  width: 100%
}

.about-employee__media .progressive-image-bg {
  left: 0;
  top: 0
}

.about-employee__media .progressive-image-wrapper {
  left: 0;
  position: absolute;
  top: 0;
  width: 100% !important
}

.about-employee__text {
  line-height: 30px
}

.about-employee__text strong {

  font-style: normal;
  font-weight: 500
}

.about-employee__play {
  height: 82px;
  left: 50%;
  margin-left: -41px;
  margin-top: -41px;
  position: absolute;
  top: 50%;
  width: 82px
}

.about-employee__title {
  color: #333638;
  display: inline-block;
  margin-bottom: 44px;
  margin-top: 26px;
  text-align: left
}

.about-employee__list {
  max-width: 520px
}

.about-employee_no-media>:first-child {
  order: 2
}

.about-employee_no-media>:last-child {
  order: 1;
  padding-left: 0
}

.about-employee_no-media .about-employee__title {
  margin-top: 0
}

.about-employee__training {
  margin-top: 49px
}

.about-employee__training .about-employee__list li {

  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 23px;
  text-transform: none
}

.about-employee__training .about-employee__list li:before {
  top: 4px
}

.about-employee__training__title {

  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 28px;
  text-transform: uppercase
}

.about_employee__booster-block {
  min-height: 260px;
  position: relative;
  width: 100%
}

.about_employee__booster-block>* {
  position: absolute;
  right: 0
}

.about-employee-simple {
  line-height: 30px;
  max-width: 800px
}

.about-employee-simple p {
  margin-bottom: 29px
}

.about-employee-simple strong {

  font-style: normal;
  font-weight: 500
}

@media screen and (max-width:1359px) {
  .about-employee>:first-child {
    padding-right: 20px
  }

  .about-employee>:last-child {
    padding-left: 0
  }

  .about-employee .booster-block {
    background-size: 163px 163px
  }

  .about-employee .booster-block:before {
    right: 18px
  }

  .about-employee .booster-block:after {
    right: 28px
  }
}

@media screen and (max-width:1023px) {

  .about-employee,
  .about-employee>* {
    margin-left: 0;
    margin-right: 0
  }

  .about-employee>* {
    flex-basis: 100%
  }

  .about-employee>:first-child {
    order: 2;
    padding-right: 0
  }

  .about-employee>:last-child {
    order: 1
  }

  .about-employee .booster-block {
    background-size: 214px 214px;
    display: block;
    margin-left: auto;
    margin-right: auto
  }

  .about-employee .booster-block:before {
    right: 45px
  }

  .about-employee .booster-block:after {
    right: 55px
  }

  .about_employee__booster-block>* {
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative
  }

  .about-employee__title {
    display: block;
    text-align: center
  }

  .about-employee__title br {
    display: none
  }
}

@media screen and (max-width:767px) {
  .about-employee-wrapper_image-right .about-employee>div:nth-child(2) {
    padding-right: 0
  }

  .about-employee-wrapper_image-right .about-employee__text {
    text-align: center
  }
}

@media screen and (max-width:450px) {
  .about-employee__media {
    border-radius: 0;
    margin-bottom: 0;
    overflow: visible !important;
    width: 100%
  }

  .about-employee__media .progressive-image {
    width: 100%
  }

  .about_employee__booster-block .booster-block {
    left: calc(-50vw + 50%);
    position: absolute;
    width: 100vw
  }

  .about-employee__media-wrapper {
    margin-bottom: 40px;
    margin-left: -15px;
    margin-right: -15px
  }

  .about-employee__text {
    padding-left: 15px;
    padding-right: 15px
  }

  .about-employee>:first-child {
    margin-left: -15px;
    margin-right: -15px
  }

  .about-employee .booster-block {
    display: block;
    margin-left: -15px;
    margin-top: -40px;
    padding-left: 15px;
    padding-right: 15px;
    width: calc(100% + 30px)
  }

  .about-employee .booster-block>* {
    flex-basis: 100%
  }

  .about-employee .booster-block .booster-block__text {
    max-width: none
  }
}

.employee-certificates-slider-wrapper {
  margin-top: 32px
}

.employee-certificates-slider,
.slider-wrapper-employee-certificates {
  position: relative
}

.employee-certificates-slider:before {
  background-color: #70e6df;
  content: "";
  display: block;
  height: 494px;
  margin-top: -262px;
  position: absolute;
  top: 50%;
  width: 100%
}

.employee-certificates-slider [data-js-image-popup] {
  background-color: rgba(255, 0, 0, .2);
  max-height: 490px;
  max-width: 344px;
  overflow: hidden
}

.employee-certificates-slider .progressive-image-wrapper {
  max-height: 490px;
  max-width: 344px;
  padding-left: 27px;
  padding-right: 27px
}

.employee-certificates-slider .progressive-image.preview {
  left: 50%;
  margin-left: -110px;
  max-width: 220px
}

.employee-certificates-slider .progressive-image:not(.preview) {
  border: 20px solid #fff;
  border-radius: 10px;
  box-shadow: 0 10px 57px rgba(70, 81, 86, .18)
}

.employee-certificates-slider .slick-slide {
  transform: scale(1);
  transition: all .2s ease-out
}

.employee-certificates-slider .slick-slide.dummy-current,
.employee-certificates-slider .slick-slide.slick-current {
  transform: scale(1.15);
  transition: all .2s ease-in
}

.employee-certificates-slider__navigator {

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-top: 2px;
  text-transform: uppercase
}

.employee-certificates-slider__navigator>div {
  text-align: center
}

.employee-certificates-slider__navigator>div>div {
  height: 46px;
  position: relative;
  width: 168px
}

.employee-certificates-slider__navigator__counter {
  color: #c2c3c5;

  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  position: relative;
  text-align: center;
  top: 6px;
  z-index: 0
}

.employee-certificates-slider__navigator__counter span:first-child {
  font-size: 20px;
  position: relative;
  top: -1px
}

.employee-certificates-slider__navigator__counter span:last-child {
  font-size: 14px;
  position: relative;
  top: -2px
}

.employee-certificates-slider__navigator__button {
  background-color: transparent;
  border-radius: 50%;
  height: 46px;
  position: absolute;
  top: 0;
  transition: background-color .2s ease-out;
  width: 46px;
  z-index: 1
}

.employee-certificates-slider__navigator__button svg {
  height: 13px;
  transition: fill .2s ease-in;
  width: 17px
}

.employee-certificates-slider__navigator__button:hover {
  background-color: #f1f5f6;
  transition: background-color .2s ease-in
}

.employee-certificates-slider__navigator__button:hover svg path {
  fill: #959ba5;
  transition: fill .2s ease-in
}

.employee-certificates-slider__navigator__button.prev {
  left: 0;
  transform: rotateY(180deg)
}

.employee-certificates-slider__navigator__button.next {
  right: 0
}

@media screen and (max-width:1023px) {
  .employee-certificates-slider:before {
    margin-top: -245px
  }
}

@media screen and (max-width:767px) {

  .employee-certificates-slider .slick-slide.dummy-current,
  .employee-certificates-slider .slick-slide.slick-current {
    transform: scale(1)
  }

  .employee-certificates-slider:before {
    content: none
  }

  .employee-certificates-slider .progressive-image-wrapper {
    max-height: 450px
  }

  .slider__item__image-wrapper {
    margin-top: 0
  }
}

.employee-specialization__grid {
  margin-left: -12px;
  margin-right: -12px;
  position: relative
}

.employee-specialization__grid>* {
  flex-basis: calc(50% - 24px);
  margin-left: 12px;
  margin-right: 12px
}

.employee-specialization__grid>:first-child p {
  line-height: 30px
}

.employee-specialization__media {
  border-radius: 10px;
  height: 0;
  margin-bottom: 50px;
  padding-top: 73.33%;
  width: 100%
}

.employee-specialization__media .progressive-image-bg {
  left: 0;
  top: 0
}

.employee-specialization__title {
  color: #333638;
  display: inline-block;
  margin-bottom: 27px;
  margin-top: 26px;
  text-align: left
}

.employee-specialization__list {
  margin-top: 41px
}

.employee-specialization__list li:before {
  top: 5px
}

.employee-specialization__list li .employee-specialization__list__link-holder,
.employee-specialization__list li a {

  font-style: normal;
  font-weight: 500;
  text-transform: uppercase
}

@media screen and (max-width:1023px) {
  .employee-specialization__title {
    display: block;
    text-align: center
  }

  .employee-specialization__grid>* {
    flex-basis: calc(100% - 36px)
  }

  .employee-specialization__grid>:last-child {
    margin: 50px auto 0;
    max-width: 600px
  }
}

@media screen and (max-width:450px) {
  .employee-specialization__grid>:last-child {
    margin-left: -3px;
    margin-right: -3px
  }

  .employee-specialization__media {
    border-radius: 0;
    overflow: visible !important
  }

  .employee-specialization__media .progressive-image {
    left: 0;
    width: 100%
  }
}

.new-year-through-block {
  background-color: #ffa800;
  min-height: 285px;
  overflow: hidden;
  position: relative;
  z-index: 1
}

.new-year-through-block .row,
.new-year-through-block .row_short {
  min-height: 285px;
  padding-top: 60px;
  position: relative
}

.new-year-through-block .row:before,
.new-year-through-block .row_short:before {
  background-image: url(/build/i/ff75c69b1ed435c9e71e.png);
  bottom: 0;
  content: "";
  display: block;
  height: 285px;
  position: absolute;
  right: 70px;
  width: 414px
}

.new-year-through-block__close {
  background-image: url(/build/i/6e0c3d794f35f31c4f83.svg);
  background-position: 50%;
  background-size: 20px 20px;
  display: block;
  height: 40px;
  opacity: 1;
  position: absolute;
  right: 20px;
  top: 20px;
  transition: opacity .2s ease-out;
  width: 40px;
  z-index: 2
}

.new-year-through-block__close:hover {
  opacity: .7;
  transition: opacity .2s ease-in
}

.new-year-through-block__title {
  color: #fff;

  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 8px
}

.new-year-through-block__sub-title {
  color: #fff;

  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 23px;
  max-width: 440px
}

.new-year-through-block__button {
  position: relative;
  z-index: 2
}

@media screen and (max-width:1044px) {

  .new-year-through-block .row:before,
  .new-year-through-block .row_short:before {
    right: -15px
  }
}

@media screen and (max-width:1023px) {

  .new-year-through-block .row:before,
  .new-year-through-block .row_short:before {
    bottom: -50px;
    right: -170px
  }
}

@media screen and (max-width:767px) {
  .new-year-through-block {
    text-align: center
  }

  .new-year-through-block .row,
  .new-year-through-block .row_short {
    padding-bottom: 40px;
    padding-top: 40px
  }

  .new-year-through-block .row:before,
  .new-year-through-block .row_short:before {
    display: none
  }

  .new-year-through-block__title {
    font-size: 30px
  }

  .new-year-through-block__sub-title {
    font-size: 16px;
    margin-left: auto;
    margin-right: auto
  }

  .new-year-through-block__close {
    right: 10px;
    top: 10px
  }
}

.callback-form {
  max-width: 400px
}

.callback-form__notice {
  color: #bdc7ca;
  font-size: 12px;
  margin-top: 20px
}

.callback-form__captcha {
  position: relative;
  transform: scale(.8)
}

.callback-form__captcha .g-recaptcha>div {
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto
}

.callback-form__captcha .g-recaptcha .form__error {
  bottom: -22px;
  left: calc(50% - 90px)
}

.steps__title {
  color: #333638;
  margin-bottom: 80px
}

.steps__block {
  margin-left: -12px;
  margin-right: -12px
}

.steps__block>* {
  flex-basis: calc(50% - 24px);
  margin-left: 12px;
  margin-right: 12px
}

.steps__block.steps__block_solo>* {
  margin-left: auto;
  margin-right: auto;
  max-width: 808px
}

.steps__block.align-right:not(.steps__block_solo)>:first-child {
  order: 2;
  padding-left: 104px
}

.steps__block.align-right:not(.steps__block_solo)>:last-child {
  order: 1
}

.steps__block.align-left:not(.steps__block_solo)>:first-child {
  padding-right: 104px
}

.steps__block__image {
  border-radius: 10px;
  height: 431px
}

.steps__block__title {
  color: #333638;

  font-size: 36px;
  font-style: normal;
  font-weight: 500
}

.steps__block__text {
  line-height: 30px;
  margin-top: 48px
}

.steps__block__text p {
  margin-top: 29px
}

.steps__block__image-desc {
  color: #a5abb5;
  font-size: 14px;
  line-height: 26px;
  margin-left: 26px;
  margin-top: 21px
}

@media screen and (max-width:1359px) {
  .steps__block__image {
    height: 307px;
    max-width: 393px
  }

  .steps__block__image-desc {
    max-width: 350px
  }

  .steps__block.align-right:not(.steps__block_solo) .steps__block__image {
    margin-right: auto
  }

  .steps__block.align-right:not(.steps__block_solo)>:first-child {
    padding-left: 0
  }

  .steps__block.align-left:not(.steps__block_solo) .steps__block__image {
    margin-left: auto
  }

  .steps__block.align-left:not(.steps__block_solo) .steps__block__image-desc {
    margin-left: 78px
  }

  .steps__block.align-left:not(.steps__block_solo)>:first-child {
    padding-right: 0
  }
}

@media screen and (max-width:1023px) {
  .steps__block>* {
    flex-basis: calc(100% - 24px)
  }

  .steps__block.align-right:not(.steps__block_solo)>:first-child {
    order: 1;
    padding-left: 0
  }

  .steps__block.align-right:not(.steps__block_solo)>:last-child {
    order: 2
  }

  .steps__block.align-left:not(.steps__block_solo)>:first-child {
    padding-right: 0
  }

  .steps__block__image {
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px
  }

  .steps__block__image-desc {
    margin-left: auto !important;
    margin-right: auto !important
  }
}

@media screen and (max-width:767px) {
  .steps__block.steps__block_solo .steps__block__image {
    margin-left: auto !important;
    margin-right: auto !important
  }

  .steps__block__title {
    font-size: 26px
  }

  .steps__block__text {
    margin-top: 38px
  }

  .steps__block__image {
    border-radius: 0;
    height: 250px;
    margin-left: -15px !important;
    margin-right: -15px !important;
    max-width: 100vw;
    width: 100vw
  }

  .steps__block__image .progressive-image {
    left: calc(-50vw + 50%);
    width: 100vw
  }
}

.side-by-side__block {
  margin-left: -12px;
  margin-right: -12px
}

.side-by-side__block>* {
  flex-basis: calc(50% - 24px);
  margin-left: 12px;
  margin-right: 12px
}

.side-by-side__block.side-by-side__block_solo>* {
  margin-left: auto;
  margin-right: auto;
  max-width: 808px
}

.side-by-side__block.align-right:not(.side-by-side__block_solo)>:first-child {
  order: 2;
  padding-left: 104px
}

.side-by-side__block.align-right:not(.side-by-side__block_solo)>:last-child {
  order: 1
}

.side-by-side__block.align-left:not(.side-by-side__block_solo)>:first-child {
  padding-right: 104px
}

.side-by-side__block__image {
  border-radius: 10px;
  height: 431px
}

.side-by-side__block__title {
  color: #333638;

  font-size: 36px;
  font-style: normal;
  font-weight: 500
}

.side-by-side__block__text {
  line-height: 30px;
  margin-top: 48px
}

.side-by-side__block__text p {
  margin-top: 29px
}

.side-by-side__block__image-desc {
  color: #a5abb5;
  font-size: 14px;
  line-height: 26px;
  margin-left: 26px;
  margin-top: 21px
}

@media screen and (max-width:1364px) {
  .side-by-side__block__image .progressive-image-wrapper {
    width: 598px !important
  }
}

@media screen and (max-width:1359px) {
  .side-by-side__block__image {
    height: 307px;
    max-width: 393px
  }

  .side-by-side__block__image .progressive-image-wrapper {
    width: 393px !important
  }

  .side-by-side__block__image .progressive-image {
    height: 307px !important
  }

  .side-by-side__block__image-desc {
    max-width: 350px
  }

  .side-by-side__block.align-right:not(.side-by-side__block_solo) .side-by-side__block__image {
    margin-right: auto
  }

  .side-by-side__block.align-right:not(.side-by-side__block_solo)>:first-child {
    padding-left: 0
  }

  .side-by-side__block.align-left:not(.side-by-side__block_solo) .side-by-side__block__image {
    margin-left: auto
  }

  .side-by-side__block.align-left:not(.side-by-side__block_solo) .side-by-side__block__image-desc {
    margin-left: 78px
  }

  .side-by-side__block.align-left:not(.side-by-side__block_solo)>:first-child {
    padding-right: 0
  }
}

@media screen and (max-width:1023px) {
  .side-by-side__block>* {
    flex-basis: calc(100% - 24px)
  }

  .side-by-side__block.align-right:not(.side-by-side__block_solo)>:first-child {
    order: 1;
    padding-left: 0
  }

  .side-by-side__block.align-right:not(.side-by-side__block_solo)>:last-child {
    order: 2
  }

  .side-by-side__block.align-left:not(.side-by-side__block_solo)>:first-child {
    padding-right: 0
  }

  .side-by-side__block__image {
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px
  }

  .side-by-side__block__image-desc {
    margin-left: auto !important;
    margin-right: auto !important
  }
}

@media screen and (max-width:767px) {
  .side-by-side__block.side-by-side__block_solo .side-by-side__block__image {
    margin-left: auto !important;
    margin-right: auto !important
  }

  .side-by-side__block__title {
    font-size: 26px
  }

  .side-by-side__block__text {
    margin-top: 38px
  }

  .side-by-side__block__image {
    border-radius: 0;
    height: 250px;
    margin-left: -15px !important;
    margin-right: -15px !important;
    max-width: 100vw;
    width: 100vw
  }

  .side-by-side__block__image .progressive-image-wrapper {
    border-radius: 0;
    height: 250px !important;
    width: 100% !important
  }

  .side-by-side__block__image .progressive-image {
    left: calc(-50vw + 50%);
    width: 100vw
  }
}

.therapist__block {
  background-color: #eef3f6;
  background-image: url(/build/i/c539136ecd89e17c0137.png);
  background-position: 100%;
  background-size: auto 100%;
  height: 547px;
  margin-top: 73px
}

.therapist__block>.row,
.therapist__block>.row_short {
  padding-top: 81px;
  position: relative
}

.therapist__block__circle {
  height: 547px;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: 0
}

.therapist__block__circle>.row,
.therapist__block__circle>.row_short {
  position: relative
}

.therapist__block__circle>.row:before,
.therapist__block__circle>.row_short:before {
  border: 70px solid #fff;
  border-radius: 50%;
  content: "";
  display: block;
  height: 628px;
  opacity: .5;
  position: absolute;
  right: 0;
  top: -15px;
  width: 628px
}

.therapist__block__fio {
  color: #333638;

  font-size: 36px;
  font-style: normal;
  font-weight: 500
}

.therapist__block__profession {
  color: #959ba5;
  font-size: 14px;
  line-height: 24px;
  margin-top: 9px;
  max-width: 475px
}

.therapist__block__feature {
  background-position: 0;
  color: #5f6877;
  font-size: 14px;
  line-height: 20px;
  margin-top: 24px;
  max-width: 320px;
  min-height: 60px;
  padding-left: 80px
}

.therapist__block__teaser {
  color: #5f6877;
  font-size: 16px;
  line-height: 26px;
  margin-top: 47px;
  max-width: 490px
}

.therapist__block__button {
  margin-top: 54px;
  z-index: 1
}

.therapist__block__image {
  bottom: 0;
  height: 547px;
  position: absolute;
  right: 0;
  width: 629px
}

@media screen and (max-width:1024px) {

  .therapist__block>.row,
  .therapist__block>.row_short {
    padding-top: 49px
  }

  .therapist__block__image {
    right: -70px
  }

  .therapist__block__fio {
    font-size: 30px;
    max-width: 400px
  }

  .therapist__block__circle>.row:after,
  .therapist__block__circle>.row_short:after {
    right: -70px
  }
}

@media screen and (max-width:1023px) {
  .therapist__block__image {
    right: -180px
  }

  .therapist__block__teaser {
    margin-top: 47px;
    max-width: 396px
  }

  .therapist__block__circle>.row:after,
  .therapist__block__circle>.row_short:after {
    right: -180px
  }
}

@media screen and (max-width:767px) {

  .therapist__block,
  .therapist__block__circle {
    height: 217px
  }

  .therapist__block {
    margin-top: 40px
  }

  .therapist__block>.row,
  .therapist__block>.row_short {
    padding-top: 38px
  }

  .therapist__block__feature,
  .therapist__block__profession,
  .therapist__block__teaser {
    display: none
  }

  .therapist__block__image {
    height: 216px;
    right: -65px;
    width: 248px
  }

  .therapist__block__fio {
    font-size: 16px;
    width: 160px
  }
}

.half-block__grid {
  margin-left: -12px;
  margin-right: -12px
}

.half-block__grid>* {
  flex-basis: calc(50% - 24px);
  margin-left: 12px;
  margin-right: 12px
}

.half-block__grid>:first-child {
  flex-basis: calc(100% - 24px);
  margin-bottom: 46px
}

.half-block__title {
  color: #333638;

  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  width: 50%
}

.half-block__text {
  line-height: 30px;
  margin-bottom: 40px;
  padding-right: 104px
}

.half-block__text p {
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 22px
}

@media screen and (max-width:1366px) {
  .half-block__title {
    font-size: 40px
  }
}

@media screen and (max-width:1023px) {
  .half-block__title {
    font-size: 36px;
    width: 100%
  }

  .half-block__grid>:nth-child(2) {
    flex-basis: calc(100% - 24px)
  }

  .half-block__grid>:nth-child(3) {
    display: none
  }
}

@media screen and (max-width:767px) {
  .half-block__title {
    font-size: 30px;
    width: 100%
  }

  .half-block__title br {
    display: none
  }

  .half-block__text {
    padding-right: 0
  }
}

.image-block-list-left .minus-list,
.image-block-list-left .plus-list {
  text-align: left
}

.imaged-block__media {
  border-radius: 10px;
  overflow: hidden;
  width: 100%
}

.imaged-block__media .progressive-image-wrapper {
  left: 0;
  top: 0;
  width: 100% !important
}

.imaged-block__media_with-video {
  position: relative
}

.imaged-block__media_with-video .progressive-image-wrapper {
  display: block
}

.imaged-block__media_with-video:after {
  background-image: linear-gradient(0deg, #000004, transparent);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  bottom: 0;
  content: "";
  display: block;
  height: 154px;
  left: 0;
  opacity: .5;
  position: absolute;
  width: 100%
}

.imaged-block__grid {
  margin-left: -12px;
  margin-right: -12px
}

.imaged-block__grid>* {
  flex-basis: calc(50% - 24px);
  margin-left: 12px;
  margin-right: 12px
}

.imaged-block__grid>:first-child {
  padding-right: 50px
}

.imaged-block__grid:not(.imaged-block__grid_left) .imaged-block__media {
  margin-left: auto
}

.imaged-block__grid .imaged-block__media {
  max-width: 600px
}

.imaged-block__grid .booster-block {
  margin-top: 54px
}

.imaged-block__grid_left>:first-child {
  order: 2;
  padding-left: 50px;
  padding-right: 0
}

.imaged-block__grid_left>:last-child {
  order: 1
}

.imaged-block__grid_left .imaged-block__media {
  margin-right: auto
}

.imaged-block__title {
  color: #333638;
  font-size: 46px;
  line-height: 52px;
  margin-bottom: 25px;
  text-align: left
}

.imaged-block__title,
.imaged-block__title span {

  font-style: normal;
  font-weight: 400
}

.imaged_block__title__svg {
  display: block;
  margin-bottom: 20px;
  margin-top: 20px
}

.imaged-block__text {
  line-height: 30px
}

.imaged-block__text p {
  margin-bottom: 25px;
  margin-top: 25px
}

.imaged-block__text strong {

  font-style: normal;
  font-weight: 500
}

.imaged-block__text .button,
.imaged-block__text .slide-tab__label.active,
.imaged-block__text .tabs__labels.tabs__labels_buttons .tab__label.active,
.tabs__labels.tabs__labels_buttons .imaged-block__text .tab__label.active {
  margin-top: 45px
}

.imaged-block__text .link_compare {
  display: inline-block;
  font-size: 14px;
  margin-top: 16px
}

.imaged-block__media-desc {
  color: #a5abb5;
  font-size: 14px;
  line-height: 26px;
  margin-left: 26px;
  margin-top: 21px
}

.imaged-block__booster-block {
  margin-top: 70px;
  min-height: 260px;
  position: relative;
  width: 100%
}

.imaged-block__booster-block>* {
  position: absolute;
  right: 0
}

.imaged-block__under-image {
  color: #5f6877;
  font-size: 16px;
  line-height: 30px;
  margin-top: 50px
}

.imaged-block__under-image strong {

  font-style: normal;
  font-weight: 500
}

.imaged-block_simple .imaged-block__grid {
  margin-left: -15px;
  margin-right: -15px
}

.imaged-block_simple .imaged-block__grid_left>* {
  margin-left: 0;
  margin-right: 0;
  padding-left: 15px;
  padding-right: 15px
}

.imaged-block_simple .imaged-block__grid_left>:first-child {
  padding-left: 15px
}

.imaged-block_simple .imaged-block__media {
  border-radius: 0;
  max-width: 575px;
  padding-right: 40px
}

.imaged-block_simple .imaged-block__media .progressive-image-wrapper {
  border-radius: 0
}

.imaged-block_simple .h2,
.imaged-block_simple .payment-page h2,
.payment-page .imaged-block_simple h2 {
  font-size: 42px;
  line-height: 47px
}

.imaged-block__text-wrapper_small .imaged-block__title {
  font-size: 40px
}

.imaged-block__text-wrapper_small .imaged-block__text {
  padding-right: 30px
}

.imaged-block__text-wrapper_small .imaged-block__text .button,
.imaged-block__text-wrapper_small .imaged-block__text .slide-tab__label.active,
.imaged-block__text-wrapper_small .imaged-block__text .tabs__labels.tabs__labels_buttons .tab__label.active,
.tabs__labels.tabs__labels_buttons .imaged-block__text-wrapper_small .imaged-block__text .tab__label.active {
  margin-top: 10px
}

.imaged-block__text-wrapper_small .imaged-block__text .imaged_block__button {
  border-radius: 100px;
  display: block;
  height: 66px;
  line-height: 64px;
  margin-top: 20px;
  text-align: center;
  width: 276px
}

.imaged_block__text_vcenter {
  display: flex;
  flex-direction: column;
  justify-content: center
}

.imaged_block__link {
  color: #5f6877;

  font-size: 20px;
  font-style: normal;
  font-weight: 500
}

.imaged_block__link:hover {
  color: #00ada4
}

.imaged_block__video_loop {
  border-radius: 20px;
  width: 100%
}

@media screen and (max-width:1359px) {
  .imaged-block__grid>:first-child {
    padding-right: 15px
  }

  .imaged-block__grid_left>:first-child {
    padding-left: 15px
  }

  .page_251 .imaged-block+.divider {
    margin-bottom: 75px !important;
    margin-top: 45px !important
  }

  .imaged-block__text-wrapper_small .imaged-block__text .imaged_block__button {
    border-radius: 60px;
    font-size: 12px;
    height: 54px;
    line-height: 52px;
    width: 220px
  }
}

@media screen and (max-width:1023px) {
  .imaged-block__title {
    display: block;
    font-size: 40px;
    text-align: center
  }

  .imaged-block__title br {
    display: none
  }

  .imaged-block__grid {
    justify-content: center;
    margin-left: -26px;
    margin-right: -26px
  }

  .imaged-block__grid>* {
    flex-basis: calc(100% - 52px);
    margin-left: 26px;
    margin-right: 26px
  }

  .imaged-block__grid>:last-child {
    margin-top: 35px;
    max-width: 600px
  }

  .imaged-block__grid_left>:first-child {
    order: 1;
    padding-left: 0
  }

  .imaged-block__grid_left>:last-child {
    order: 2
  }

  .imaged-block__media-desc {
    width: 392px
  }

  .imaged-block__media,
  .imaged-block__media-desc {
    margin-left: auto;
    margin-right: auto
  }

  .imaged-block__booster-block {
    margin-top: 50px
  }

  .imaged-block__booster-block>* {
    position: relative
  }

  .imaged-block .booster-block,
  .imaged-block__booster-block>* {
    display: block;
    margin-left: auto;
    margin-right: auto
  }

  .imaged-block__under-image {
    text-align: center
  }

  .imaged-block_simple .imaged-block__grid {
    justify-content: center
  }

  .imaged-block_simple .imaged-block__grid>* {
    flex-basis: 100%
  }

  .imaged-block_simple .imaged-block__media {
    max-width: 535px;
    padding-right: 0
  }

  .imaged-block_simple .h2,
  .imaged-block_simple .payment-page h2,
  .payment-page .imaged-block_simple h2 {
    font-size: 40px
  }

  .imaged-block__text-wrapper_small .imaged-block__text {
    padding-right: 0
  }

  .imaged_block__text_vcenter {
    display: block
  }

  .imaged_block__title__svg {
    margin: 0 auto 20px
  }

  .imaged_block__link {
    display: block;
    font-size: 18px;
    margin-top: -10px;
    width: 175px
  }
}

@media screen and (max-width:767px) {
  .imaged-block__title {
    font-size: 30px;
    line-height: 30px
  }

  .imaged-block__media-desc {
    margin-top: 10px;
    text-align: center;
    width: 100%
  }

  .imaged-block__grid,
  .imaged-block__grid>*,
  .imaged-block__media-desc {
    margin-left: 0;
    margin-right: 0
  }

  .imaged-block__grid>* {
    flex-basis: 100%;
    max-width: 100%
  }

  .imaged-block__grid>:first-child {
    padding-right: 0
  }

  .imaged-block__grid>:last-child {
    margin-top: 45px
  }

  .imaged-block__grid .imaged-block__media {
    width: 100%
  }

  .imaged-block__booster-block {
    margin-top: 0
  }

  .imaged-block__text {
    text-align: center
  }

  .imaged-block__text p,
  .imaged-block__text ul {
    text-align: left
  }

  .imaged-block__text .button,
  .imaged-block__text .slide-tab__label.active,
  .imaged-block__text .tabs__labels.tabs__labels_buttons .tab__label.active,
  .tabs__labels.tabs__labels_buttons .imaged-block__text .tab__label.active {
    margin-top: 20px
  }

  .imaged-block__text strong {
    display: inline-block;
    line-height: 24px
  }

  .imaged-block_simple .imaged-block__grid>:first-child {
    padding-right: 15px
  }

  .imaged-block_simple .imaged-block__grid>:last-child {
    margin-top: 10px;
    max-width: 100%
  }

  .imaged-block_simple .h2,
  .imaged-block_simple .payment-page h2,
  .payment-page .imaged-block_simple h2 {
    line-height: 30px
  }

  .imaged-block_simple .imaged-block__media {
    padding: 0 20px
  }

  .imaged-block_simple .imaged-block__media .progressive-image-wrapper {
    left: 0 !important;
    max-width: 100% !important
  }

  .imaged-block__text-wrapper_small .imaged-block__text .imaged_block__button {
    margin: 20px auto 0
  }

  .imaged_block__link {
    margin: -10px auto 0
  }
}

@media screen and (max-width:500px) {
  .imaged-block__media {
    border-radius: 0;
    min-height: 250px;
    overflow: visible !important
  }

  .imaged-block__media .progressive-image-wrapper {
    border-radius: 0;
    left: calc(-50vw + 50%);
    width: 100vw !important
  }

  .imaged-block__media_with-video:after {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
  }

  .imaged_block__video_loop {
    border-radius: 0;
    width: 100vw
  }
}

@media screen and (max-width:450px) {
  .imaged-block__grid .booster-block {
    margin-top: 0
  }

  .imaged-block__booster-block .booster-block {
    left: calc(-50vw + 50%);
    position: absolute;
    width: 100vw
  }
}

@media screen and (max-width:320px) {
  .imaged-block__media {
    height: 234px
  }

  .imaged-block__grid .imaged-block__media {
    height: auto;
    min-height: auto
  }
}

.imaged-block-with-quote .imaged-block__title {
  color: #333638;

  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  font-weight: 500;
  line-height: 48px;
  padding-left: 70px;
  position: relative
}

.imaged-block-with-quote .imaged-block__title:before {
  background-image: url(/build/i/050c8e029d00467c69be.svg);
  background-size: 48px 48px;
  content: "";
  display: block;
  height: 48px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 48px
}

.imaged-block-with-quote .imaged_block__text_ital {
  color: #333638;

  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: 28px
}

.imaged-block-with-quote .imaged-block__media {
  max-width: 520px
}

.imaged-block-with-quote .imaged-block__grid_left>:last-child {
  max-width: 520px;
  padding-top: 25px
}

.imaged-block-with-quote .imaged-block-doctor__grid {
  align-items: center;
  display: flex
}

.imaged-block-with-quote .imaged-block-doctor__grid .progressive-image-wrapper {
  background-color: #b1dfd5;
  border-radius: 50%;
  height: 90px !important;
  position: relative;
  width: 90px !important
}

.imaged-block-with-quote .imaged-block-doctor__grid .progressive-image-wrapper img {
  bottom: -10px;
  left: 50%;
  margin-left: -60px;
  position: absolute;
  width: 120px
}

.imaged-block-with-quote .imaged-block-doctor__name {
  color: #a5a5a5;
  font-size: 14px;
  line-height: 24px;
  margin-left: 30px;
  max-width: 255px
}

.imaged-block-with-quote .imaged-block-doctor__name a {
  display: block
}

.imaged-block-with-quote .imaged-block-doctor__link {
  margin-left: auto;
  text-align: center;
  width: 160px
}

@media screen and (max-width:1200px) {
  .imaged-block-with-quote .imaged-block__grid {
    justify-content: center
  }

  .imaged-block-with-quote .imaged-block__grid>* {
    flex-basis: calc(100% - 52px)
  }

  .imaged-block-with-quote .imaged-block__grid>:first-child {
    order: 1;
    padding-left: 0
  }

  .imaged-block-with-quote .imaged-block__grid .imaged-block__media {
    margin: auto
  }
}

@media screen and (max-width:767px) {
  .imaged-block-with-quote .imaged-block__title {
    line-height: 30px;
    text-align: left
  }
}

@media screen and (max-width:570px) {
  .imaged-block-with-quote .imaged-block-doctor__grid {
    flex-wrap: wrap
  }

  .imaged-block-with-quote .imaged-block-doctor__link {
    flex-basis: 100%;
    margin: 30px auto 0;
    max-width: 160px
  }

  .imaged-block-with-quote .imaged-block-doctor__name {
    flex-basis: calc(100% - 120px);
    max-width: none
  }
}

@media screen and (max-width:400px) {
  .imaged-block-with-quote .imaged-block-doctor__grid {
    justify-content: center
  }

  .imaged-block-with-quote .imaged-block-doctor__name {
    flex-basis: 100%;
    margin: 15px 0 0;
    text-align: center
  }
}

.works-list__title {
  color: #333638;
  margin-bottom: 48px
}

.works-list__grid {
  margin-left: -12px;
  margin-right: -12px
}

.works-list__grid>* {
  flex-basis: calc(33.33% - 24px);
  flex-grow: 0;
  margin-left: 12px;
  margin-right: 12px
}

.works-list__block {
  margin-bottom: 38px;
  position: relative
}

.works-list__block__media {
  border-radius: 10px;
  box-shadow: 0 10px 29px rgba(70, 81, 86, .18);
  height: 293px;
  -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
  overflow: hidden;
  position: relative
}

.works-list__block__media:after {
  background-color: transparent;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: all .1s ease-out;
  width: 100%
}

.works-list__block__media:hover:after {
  background-color: #e59700;
  content: "";
  opacity: .1;
  transition: all .1s ease-in
}

.works-list__block__media .progressive-image {
  height: 293px
}

.works-list__block__name {
  color: #5f6877;
  line-height: 26px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 24px
}

.works-list__block__name:active,
.works-list__block__name:focus,
.works-list__block__name:hover {
  color: #5f6877
}

.works-list__more {
  margin-top: 20px;
  text-align: center
}

@media screen and (max-width:1359px) {

  .works-list__block__media,
  .works-list__block__media .progressive-image {
    height: 195px
  }

  .works-list__block__name {
    font-size: 14px;
    line-height: 20px;
    margin-top: 14px
  }

  .page_251 .works-list+.pusher {
    margin-top: 80px !important
  }
}

@media screen and (max-width:1023px) {

  .works-list__block__media,
  .works-list__block__media .progressive-image {
    height: 130px
  }

  .works-list__block__name {
    font-size: 12px;
    line-height: 20px;
    margin-top: 14px
  }

  .page_251 .works-list+.pusher {
    margin-top: 70px !important
  }
}

@media screen and (max-width:767px) {
  .works-list__grid {
    margin-left: auto;
    margin-right: auto;
    max-width: 320px
  }

  .works-list__grid>* {
    flex-basis: calc(100% - 24px)
  }

  .works-list__grid>:nth-child(n+3) {
    display: none
  }

  .works-list__grid[data-js-load-more-shown]>:nth-child(n+3) {
    display: inline;
    display: initial
  }

  .works-list__block__media,
  .works-list__block__media .progressive-image {
    height: 212px
  }

  .works-list__title {
    margin-bottom: 30px
  }

  .works-list__block__name {
    margin-left: 0;
    margin-right: 0;
    margin-top: 15px;
    text-align: center
  }
}

@media screen and (max-width:450px) {
  .works-list__block__media {
    height: 180px
  }

  .works-list__block__media .corner_play {
    right: -15px
  }

  .works-list__block__media .corner_play>* span {
    background-position: 29px 19px
  }

  .works-list__block__media .progressive-image {
    height: 180px;
    left: calc(-50vw + 50%);
    width: 100vw
  }
}

.service-work {
  margin-bottom: 130px
}

.service-work .info-block-wrapper>.row,
.service-work .info-block-wrapper>.row_short {
  padding-left: 0;
  padding-right: 0
}

.service-work_no-margin {
  margin-bottom: 0
}

.service-work__title {
  margin-bottom: 111px
}

.service-work__flex {
  flex-wrap: nowrap;
  margin-bottom: 50px
}

.service-work__flex>:first-child {
  max-width: 470px;
  padding-right: 75px
}

.service-work__media {
  border-radius: 10px;
  box-shadow: 0 10px 29px rgba(70, 81, 86, .18);
  height: 289px;
  width: 393px
}

.service-work__desc {
  color: #5f6877;
  line-height: 26px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 18px
}

.service-work__desc:active,
.service-work__desc:focus,
.service-work__desc:hover {
  color: #5f6877
}

.service-work__result {
  margin-left: -12px;
  margin-right: -12px;
  margin-top: 37px
}

.service-work__result>* {
  flex-basis: calc(50% - 24px);
  margin-left: 12px;
  margin-right: 12px;
  position: relative
}

.service-work__result__image {
  border-radius: 10px;
  border-bottom-left-radius: 15px;
  height: 130px;
  overflow: hidden;
  width: 184px
}

.service-work__result__desc {
  background-color: #f2f0d6;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  bottom: 0;
  font-size: 14px;
  left: 0;
  line-height: 22px;
  padding: 2px 12px;
  position: absolute;
  z-index: 1
}

.service-work__content {
  background-position: 2px 2px;
  padding: 55px;
  position: relative;
  width: 100%
}

.service-work__content__circle {
  border: 30px solid #f4f7f8;
  border-radius: 50%;
  display: block;
  height: 216px;
  position: absolute;
  right: -133px;
  top: -10px;
  width: 216px
}

.service-work__content__circle:before {
  background-color: #baf7f4;
  border-radius: 50%;
  content: "";
  height: 84px;
  left: 50%;
  margin-left: -42px;
  margin-top: -42px;
  position: absolute;
  top: 50%;
  width: 84px
}

.service-work__content__circle:after {
  background-image: url(/build/i/289614f9869ba4b363d5.svg);
  background-size: 40px 40px;
  content: "";
  display: block;
  height: 40px;
  left: 50%;
  margin-left: -20px;
  margin-top: -20px;
  position: absolute;
  top: 50%;
  width: 40px
}

.service-work__text {
  max-width: 440px;
  position: relative;
  z-index: 1
}

.service-work__text p {

  font-style: italic;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 50px
}

.service-work__text .title {
  color: #333638;

  font-size: 26px;
  font-style: italic;
  font-weight: 500;
  line-height: 36px
}

.service-work__buttons {
  align-items: center;
  display: flex
}

.service-work__buttons .button,
.service-work__buttons .slide-tab__label.active,
.service-work__buttons .tabs__labels.tabs__labels_buttons .tab__label.active,
.tabs__labels.tabs__labels_buttons .service-work__buttons .tab__label.active {
  margin-right: 30px
}

.service-work__buttons .link,
.service-work__buttons .slide-tab__label,
.service-work__buttons .tabs__labels.tabs__labels_buttons .tab__label,
.service-work__buttons [data-js=tooltip],
.tabs__labels.tabs__labels_buttons .service-work__buttons .tab__label {

  font-style: normal;
  font-weight: 500;
  text-transform: uppercase
}

.service-work_implantation,
.service-work_implantation .service-work__flex {
  margin-bottom: 0
}

.service-work_implantation .service-work__desc {
  align-items: center
}

.service-work_implantation .service-work__desc .button,
.service-work_implantation .service-work__desc .slide-tab__label.active,
.service-work_implantation .service-work__desc .tabs__labels.tabs__labels_buttons .tab__label.active,
.tabs__labels.tabs__labels_buttons .service-work_implantation .service-work__desc .tab__label.active {
  margin-left: 20px;
  max-width: 193px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center
}

.service-work_implantation .service-work__result {
  margin-top: 55px
}

.service-work_implantation .service-work__content {
  margin-bottom: 20px;
  margin-top: -50px;
  padding-bottom: 30px;
  padding-top: 45px
}

.service-work_implantation .service-work__text .title {
  margin-bottom: 30px
}

.service-work_implantation .service-work__text p {
  margin-bottom: 25px
}

.service-work__others__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px
}

.service-work__others__title {
  color: #989ca6;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px
}

.service-work__others__grid {
  align-items: center;
  display: flex;
  margin-right: -30px;
  max-width: 754px
}

.service-work__others__item {
  margin-right: 10px;
  position: relative
}

.service-work__others__item:last-child:after {
  background-image: linear-gradient(270deg, #fff, #f1f2f2 50%, hsla(180, 3%, 92%, .75) 71%, hsla(180, 2%, 89%, 0));
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2
}

.service-work__others__item .progressive-image-wrapper {
  display: block
}

.service-work__others__item .service-work__result__desc {
  color: #5f6877;
  font-size: 12px;
  line-height: 20px
}

@media screen and (max-width:1359px) {
  .service-work__flex>:first-child {
    max-width: 416px;
    padding-right: 24px
  }

  .service-work__content__circle {
    right: 0
  }

  .service-work_implantation .service-work__content {
    padding-left: 30px;
    padding-right: 30px
  }

  .service-work_implantation .service-work__flex>div:nth-child(2) {
    max-width: 54.5%
  }
}

@media screen and (max-width:1100px) {
  .service-work__others__item {
    margin-right: 10px;
    position: relative
  }

  .service-work__others__item:nth-child(3),
  .service-work__others__item:nth-child(4) {
    display: none
  }
}

@media screen and (max-width:1023px) {
  .service-work__flex {
    flex-wrap: wrap;
    margin-bottom: 70px;
    margin-left: -26px;
    margin-right: -26px;
    padding-bottom: 70px
  }

  .service-work__flex>* {
    flex-basis: calc(100% - 52px);
    margin-left: 26px;
    margin-right: 26px
  }

  .service-work__flex>:first-child {
    max-width: inherit !important;
    max-width: none !important;
    padding-right: 0
  }

  .service-work__content__circle,
  .service-work__result {
    display: none
  }

  .service-work__desc,
  .service-work__media {
    margin-left: auto;
    margin-right: auto
  }

  .service-work__desc {
    max-width: 393px
  }

  .service-work__content {
    margin-left: -26px;
    margin-right: -26px;
    margin-top: 20px;
    padding: 26px;
    width: auto
  }

  .service-work__text {
    max-width: inherit !important;
    max-width: none !important;
    text-align: center
  }

  .service-work__text .title {
    margin-bottom: 0
  }

  .service-work__text .title br,
  .service-work__text p:not(.title) {
    display: none
  }

  .service-work__buttons {
    left: 50%;
    margin-left: -163px;
    max-width: 326px;
    position: absolute;
    top: 110px
  }

  .service-work__title {
    margin-bottom: 50px
  }

  .service-work_implantation .service-work__flex>div:nth-child(2) {
    max-width: 100%
  }

  .service-work_implantation .service-work__content {
    margin-top: 0
  }

  .service-work__others__item:last-child {
    margin-right: 0
  }

  .service-work__others__item:last-child:after {
    content: none
  }

  .service-work__others__grid {
    justify-content: center
  }
}

@media screen and (max-width:767px) {
  .service-work__media {
    height: 214px;
    margin-left: auto;
    margin-right: auto;
    width: 290px
  }

  .service-work__desc {
    text-align: center
  }

  .service-work__buttons {
    display: flex;
    flex-wrap: wrap;
    left: 0;
    margin: 38px auto 20px;
    position: relative;
    text-align: center;
    top: 0
  }

  .service-work__buttons>* {
    flex-basis: 210px;
    margin-left: auto;
    margin-right: auto
  }

  .service-work__buttons .button,
  .service-work__buttons .slide-tab__label.active,
  .service-work__buttons .tabs__labels.tabs__labels_buttons .tab__label.active,
  .tabs__labels.tabs__labels_buttons .service-work__buttons .tab__label.active {
    margin-bottom: 37px;
    margin-right: auto
  }

  .service-work__flex {
    margin-bottom: 0
  }

  .service-work_implantation .service-work__content {
    padding-bottom: 15px
  }

  .service-work_implantation .service-work__flex {
    padding-bottom: 10px
  }

  .service-work__others__header {
    flex-wrap: wrap
  }

  .service-work__others__grid {
    margin-right: 0
  }

  .service-work__others__item {
    display: none
  }

  .service-work__others__item:first-child,
  .service-work__others__item:nth-child(2) {
    display: block
  }

  .service-work__others__item:nth-child(2) {
    margin-right: 0
  }

  .service-work__others__title {
    flex-basis: 100%;
    font-size: 14px;
    margin-top: 10px;
    order: 1
  }
}

.service-video {
  background-color: #7acad6;
  height: 602px;
  position: relative
}

.service-video__image {
  left: 50%;
  margin-left: -285px;
  position: absolute
}

.service-video__play {
  border-radius: 50%;
  box-shadow: 0 6px 29px rgba(0, 0, 0, .27);
  display: block;
  left: 50%;
  margin-left: -40px;
  position: absolute;
  top: 160px
}

.service-video__play,
.service-video__play .button,
.service-video__play .slide-tab__label.active,
.service-video__play .tabs__labels.tabs__labels_buttons .tab__label.active,
.tabs__labels.tabs__labels_buttons .service-video__play .tab__label.active {
  height: 80px;
  width: 80px
}

.service-video__play:hover:before {
  height: 200px;
  margin-left: -100px;
  margin-top: -100px;
  transition: all .2s ease-in;
  width: 200px
}

.service-video__play:before {
  display: block;
  height: 186px;
  margin-left: -93px;
  margin-top: -93px;
  transition: all .2s ease-out;
  width: 186px
}

.service-video__play:after,
.service-video__play:before {
  background-color: #fff;
  border-radius: 50%;
  content: "";
  left: 50%;
  opacity: .08;
  position: absolute;
  top: 50%;
  z-index: 0
}

.service-video__play:after {
  height: 570px;
  margin-left: -285px;
  margin-top: -285px;
  pointer-events: none;
  width: 570px
}

.service-video__content {
  left: 50%;
  margin-left: -340px;
  position: absolute;
  text-align: center;
  top: 320px;
  width: 680px
}

.service-video__content .title {
  color: #fff;

  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 25px
}

.service-video__content .text {
  color: #fff;
  line-height: 28px
}

@media screen and (max-width:767px) {
  .service-video {
    height: auto;
    padding-bottom: 50px
  }

  .service-video__image {
    left: 0;
    margin-left: 0;
    max-width: 100%;
    position: relative;
    text-align: center;
    top: 50px
  }

  .service-video__image .progressive-image-wrapper {
    height: auto !important;
    max-width: 100% !important;
    width: 320px !important
  }

  .service-video__play {
    margin-left: -31px;
    top: 105px
  }

  .service-video__play,
  .service-video__play .button,
  .service-video__play .slide-tab__label.active,
  .service-video__play .tabs__labels.tabs__labels_buttons .tab__label.active,
  .tabs__labels.tabs__labels_buttons .service-video__play .tab__label.active {
    height: 62px;
    width: 62px
  }

  .service-video__content {
    margin-left: 0;
    max-width: 100%;
    position: inherit !important;
    position: static !important
  }

  .service-video__content .title {
    font-size: 25px
  }

  .service-video__content .title br {
    display: none
  }
}

.types-of-crowns-for-implants__grid>:first-child {
  padding-right: 60px
}

.types-of-crowns-for-implants__grid>:last-child {
  max-width: 498px;
  padding-top: 100px
}

.types-of-crowns-for-implants__grid-crowns {
  margin-left: -12px;
  margin-right: -12px;
  margin-top: 90px
}

.types-of-crowns-for-implants__grid-crowns>* {
  flex-basis: calc(33.33% - 36px);
  margin-left: 12px;
  margin-right: 12px;
  text-align: center
}

.tabs__labels.tabs__labels_buttons .types-of-crowns-for-implants__grid-crowns .tab__label,
.types-of-crowns-for-implants__grid-crowns .link,
.types-of-crowns-for-implants__grid-crowns .slide-tab__label,
.types-of-crowns-for-implants__grid-crowns .tabs__labels.tabs__labels_buttons .tab__label,
.types-of-crowns-for-implants__grid-crowns [data-js=tooltip] {

  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-transform: uppercase
}

.types-of-crowns-for-implants__grid-crowns__image {
  background-color: #ecf3f5;
  border-radius: 50%;
  height: 170px;
  margin-bottom: 35px;
  overflow: hidden;
  text-align: left;
  width: 172px
}

.types-of-crowns-for-implants__title {
  color: #333638;

  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
  margin-bottom: 47px;
  text-align: left
}

.types-of-crowns-for-implants__text {
  line-height: 30px;
  max-width: 580px
}

.types-of-crowns-for-implants__text p {
  margin-bottom: 30px
}

.types-of-crowns-for-implants__text strong {

  font-style: normal;
  font-weight: 500
}

.types-of-crowns-for-implants__grid-crowns__text {
  color: #989ca6;
  font-size: 14px;
  line-height: 26px;
  margin-top: 20px;
  max-width: 230px
}

@media screen and (max-width:1023px) {
  .types-of-crowns-for-implants__title {
    text-align: center
  }

  .types-of-crowns-for-implants__grid {
    margin-left: -26px;
    margin-right: -26px
  }

  .types-of-crowns-for-implants__grid>* {
    flex-basis: calc(100% - 52px);
    margin-left: 26px;
    margin-right: 26px
  }

  .types-of-crowns-for-implants__grid>:first-child {
    order: 1;
    padding-right: 0;
    padding-top: 100px
  }

  .types-of-crowns-for-implants__grid>:last-child {
    max-width: 100%;
    order: 0;
    padding-top: 0
  }

  .types-of-crowns-for-implants__grid>:last-child .booster-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 498px
  }

  .types-of-crowns-for-implants__text {
    max-width: 100%
  }
}

@media screen and (max-width:767px) {
  .types-of-crowns-for-implants__title {
    font-size: 30px;
    line-height: 36px
  }

  .types-of-crowns-for-implants__grid>:first-child {
    padding-top: 60px
  }

  .types-of-crowns-for-implants__grid>:last-child {
    flex-basis: 100%;
    margin-left: 0;
    margin-right: 0
  }

  .types-of-crowns-for-implants__grid>:last-child .booster-block .booster-block__title {
    margin-left: auto;
    margin-right: auto
  }

  .types-of-crowns-for-implants__grid-crowns>* {
    margin-bottom: 40px
  }
}

.stages {
  position: relative
}

.stages:before {
  border: 50px solid #eaf1f3;
  border-radius: 50%;
  content: "";
  display: block;
  height: 326px;
  left: -95px;
  position: absolute;
  top: -40px;
  width: 326px;
  z-index: -1
}

@media screen and (max-width:1023px) {
  .stages:before {
    content: none
  }
}

@media screen and (max-width:767px) {
  .stages {
    display: none
  }
}

.rate>* {
  height: 16px;
  width: 16px
}

.rate>* path {
  fill: #eff0f1
}

.rate.rate_1>:first-child path,
.rate.rate_2>:first-child path,
.rate.rate_2>:nth-child(2) path,
.rate.rate_3>:first-child path,
.rate.rate_3>:nth-child(2) path,
.rate.rate_3>:nth-child(3) path,
.rate.rate_4>:first-child path,
.rate.rate_4>:nth-child(2) path,
.rate.rate_4>:nth-child(3) path,
.rate.rate_4>:nth-child(4) path,
.rate.rate_5>:first-child path,
.rate.rate_5>:nth-child(2) path,
.rate.rate_5>:nth-child(3) path,
.rate.rate_5>:nth-child(4) path,
.rate.rate_5>:nth-child(5) path {
  fill: #f5e100
}

.rate_small>* {
  height: 14px;
  width: 14px
}

.rate_big>* {
  height: 22px;
  margin-right: 3px;
  width: 22px
}

.rate_transparent>* path {
  fill: transparent
}

.rate_hidden>* {
  display: none
}

.rate_hidden.rate_1>:first-child,
.rate_hidden.rate_2>:first-child,
.rate_hidden.rate_2>:nth-child(2),
.rate_hidden.rate_3>:first-child,
.rate_hidden.rate_3>:nth-child(2),
.rate_hidden.rate_3>:nth-child(3),
.rate_hidden.rate_4>:first-child,
.rate_hidden.rate_4>:nth-child(2),
.rate_hidden.rate_4>:nth-child(3),
.rate_hidden.rate_4>:nth-child(4),
.rate_hidden.rate_5>:first-child,
.rate_hidden.rate_5>:nth-child(2),
.rate_hidden.rate_5>:nth-child(3),
.rate_hidden.rate_5>:nth-child(4),
.rate_hidden.rate_5>:nth-child(5) {
  display: inline-block
}

.why-we {
  background-color: #eef2f4
}

.why-we__grid {
  margin-left: -12px;
  margin-right: -12px;
  padding-bottom: 125px;
  padding-top: 135px
}

.why-we__grid>* {
  flex-basis: calc(25% - 48px);
  margin-left: 12px;
  margin-right: 12px
}

.why-we__grid>:first-child {
  background-image: url(/build/i/8315bcff913ce5fd9c2d.svg);
  background-size: 126px 126px;
  padding-top: 162px;
  position: relative;
  top: -19px
}

.why-we__grid>:nth-child(2) {
  background-image: url(/build/i/b1a832eb9801fcb036d9.svg);
  background-position-x: -7px;
  padding-right: 30px;
  padding-top: 92px
}

.why-we__grid>:nth-child(3) {
  background-image: url(/build/i/491597adea673afb979c.svg);
  background-position-x: -7px;
  padding-right: 30px;
  padding-top: 92px
}

.why-we__grid>:nth-child(4) {
  background-image: url(/build/i/a3025cf0ffa0b5ab03d9.svg);
  background-position-x: -7px;
  background-position-y: 6px;
  padding-right: 30px;
  padding-top: 92px
}

.why-we__h2 {
  font-size: 36px;
  line-height: 48px
}

.why-we__h2,
.why-we__title {
  color: #333638;

  font-style: normal;
  font-weight: 500
}

.why-we__title {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 11px
}

.why-we__text {
  color: #989ca6;
  font-size: 14px;
  line-height: 26px
}

@media screen and (max-width:1359px) {
  .why-we__grid {
    padding-bottom: 41px;
    padding-top: 50px
  }

  .why-we__grid>* {
    text-align: center
  }

  .why-we__grid>:first-child {
    background-position: top;
    background-size: 100px 100px;
    flex-basis: calc(100% - 24px);
    margin-bottom: 50px;
    padding-top: 105px;
    position: inherit !important;
    position: static !important
  }

  .why-we__grid>:nth-child(2),
  .why-we__grid>:nth-child(3),
  .why-we__grid>:nth-child(4) {
    background-position: top;
    flex-basis: calc(33.33% - 36px);
    padding-right: 0
  }
}

@media screen and (max-width:767px) {
  .why-we__grid {
    margin-left: -15px;
    margin-right: -15px
  }

  .why-we__grid>* {
    margin-left: 15px;
    margin-right: 15px
  }

  .why-we__grid>:nth-child(2),
  .why-we__grid>:nth-child(3),
  .why-we__grid>:nth-child(4) {
    flex-basis: calc(100% - 30px);
    margin-bottom: 40px
  }
}

.helpful-advice {
  background-color: #f7f9fb;
  padding-bottom: 140px;
  padding-top: 140px
}

.helpful-advice__title {
  color: #333638;

  font-size: 36px;
  font-style: normal;
  font-weight: 300;
  line-height: 48px;
  margin-bottom: 39px;
  padding-left: 72px;
  position: relative
}

.helpful-advice__title:before {
  background-image: url(/build/i/050c8e029d00467c69be.svg);
  background-size: 48px 48px;
  content: "";
  display: block;
  height: 48px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 48px
}

.helpful-advice__title strong {

  font-style: normal;
  font-weight: 500
}

.helpful-advice__grid {
  align-items: flex-start;
  display: flex;
  justify-content: space-between
}

.helpful-advice__grid_left>:first-child {
  order: 2
}

.helpful-advice__grid_left>:last-child {
  order: 1
}

.helpful-advice__text {
  line-height: 30px;
  max-width: 820px
}

.helpful-advice__text p {
  margin-bottom: 29px
}

.helpful-advice__text p:last-child {
  margin-bottom: 0
}

.helpful-advice__text i {
  color: #333638;

  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: 28px
}

.helpful-advice__employee__info {
  max-width: 300px
}

.helpful-advice__employee__image {
  border-radius: 50%;
  box-shadow: 0 9px 21px rgba(70, 81, 86, .09);
  height: 300px;
  margin-bottom: 32px;
  position: relative;
  top: 0;
  transition: box-shadow .1s ease-out, top .1s ease-out;
  width: 300px
}

.helpful-advice__employee__image:hover {
  box-shadow: 0 15px 76px rgba(70, 81, 86, .11);
  top: -2px;
  transition: box-shadow .1s ease-in, top .1s ease-in
}

.helpful-advice__employee__text {
  color: #a5a5a5;
  font-size: 14px;
  line-height: 24px;
  text-align: center
}

@media screen and (max-width:1363px) {
  .helpful-advice {
    padding-bottom: 100px;
    padding-top: 100px
  }
}

@media screen and (max-width:1300px) {
  .helpful-advice__text {
    padding-right: 40px
  }

  .helpful-advice__grid_left .helpful-advice__text {
    padding-left: 40px;
    padding-right: 0
  }
}

@media screen and (max-width:1023px) {
  .helpful-advice__employee__info {
    margin-left: auto;
    margin-right: auto
  }

  .helpful-advice {
    padding-bottom: 90px;
    padding-top: 90px
  }

  .helpful-advice__text {
    max-width: 100%;
    padding-right: 0
  }

  .helpful-advice__grid {
    flex-wrap: wrap;
    justify-content: space-around
  }

  .helpful-advice__grid>* {
    flex-basis: 100%
  }

  .helpful-advice__grid:not(.helpful-advice__grid_left) .helpful-advice__employee__info {
    margin-top: 97px
  }

  .helpful-advice__grid_left {
    flex-wrap: wrap;
    justify-content: space-around
  }

  .helpful-advice__grid_left>:first-child {
    order: 2 !important
  }

  .helpful-advice__grid_left>:last-child {
    margin-bottom: 87px;
    order: 1 !important
  }

  .helpful-advice__grid_left .helpful-advice__text {
    padding-left: 0
  }
}

@media screen and (max-width:767px) {
  .helpful-advice__grid:not(.helpful-advice__grid_left) {
    padding-top: 42px
  }

  .helpful-advice__grid:not(.helpful-advice__grid_left) .helpful-advice__employee__info {
    margin-top: 39px
  }

  .helpful-advice__employee__image {
    height: 150px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 150px
  }

  .helpful-advice__title {
    font-size: 30px;
    line-height: 36px;
    padding-left: 0;
    text-align: center
  }

  .helpful-advice__title:before {
    height: 48px;
    left: 50%;
    margin-left: -24px;
    top: -50px;
    width: 48px
  }
}

.rating-of-implants {
  padding-bottom: 85px;
  padding-top: 115px
}

.rating-of-implants__grid>:first-child {
  padding-right: 24px;
  padding-top: 18px
}

.rating-of-implants__grid>:first-child p {
  line-height: 30px;
  margin-bottom: 30px
}

.rating-of-implants__grid>:last-child {
  position: relative
}

.rating-of-implants__grid>:last-child>.booster-block {
  position: absolute;
  right: 0
}

.rating-of-implants__grid p strong {

  font-style: normal;
  font-weight: 500
}

.rating-of-implants__title {
  color: #333638;

  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 47px;
  text-align: left
}

@media screen and (max-width:767px) {
  .rating-of-implants__grid>:first-child {
    padding-right: 0
  }

  .rating-of-implants {
    padding-top: 50px
  }
}

.text-with-booster {
  padding-bottom: 85px;
  padding-top: 115px
}

.text-with-booster_without-bg {
  background-image: none;
  padding: 0
}

.text-with-booster_without-bg .text-with-booster__grid>:first-child {
  padding-top: 0
}

.text-with-booster_without-bg .text-with-booster__grid>:last-child>.booster-block {
  margin-top: 0
}

.text-with-booster_without-bg .text-with-booster__title {
  font-size: 48px
}

.text-with-booster__grid>:first-child {
  padding-right: 24px;
  padding-top: 18px
}

.text-with-booster__grid>:first-child p {
  line-height: 30px;
  margin-bottom: 30px
}

.text-with-booster__grid>:last-child {
  position: relative
}

.text-with-booster__grid>:last-child>.booster-block {
  float: right;
  margin-top: 144px
}

.text-with-booster__grid p strong {

  font-style: normal;
  font-weight: 500
}

.text-with-booster__title {
  color: #333638;

  font-style: normal;
  font-weight: 400;
  margin-bottom: 47px;
  text-align: left
}

@media screen and (max-width:1023px) {
  .text-with-booster__title {
    text-align: center
  }

  .text-with-booster__grid>* {
    flex-basis: 100%
  }

  .text-with-booster__grid>:first-child {
    padding-right: 0
  }

  .text-with-booster__grid>:last-child>.booster-block {
    display: block;
    float: none;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    position: relative
  }

  .text-with-booster {
    padding-top: 50px
  }
}

@media screen and (max-width:767px) {
  .text-with-booster__grid>:first-child {
    padding-right: 0
  }

  .text-with-booster__grid>:last-child>.booster-block {
    display: block;
    float: none;
    margin-top: 70px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    text-align: left;
    width: 100vw
  }

  .text-with-booster__grid>:last-child>.booster-block .booster-block__title {
    text-align: center
  }

  .text-with-booster_without-bg {
    padding-top: 0
  }
}

@media screen and (max-width:361px) {
  .text-with-booster__grid>:first-child {
    padding-right: 0
  }

  .text-with-booster__grid>:last-child>.booster-block {
    margin-left: -15px !important;
    margin-right: -15px !important;
    margin-top: 26px
  }
}

.best-brands {
  padding-bottom: 103px;
  padding-top: 96px
}

.best-brands,
.best-brands .button_white-bordered {
  background-color: #f7f9fb
}

.best-brands__grid {
  margin-left: -12px;
  margin-right: -12px
}

.best-brands__grid>* {
  flex-basis: calc(25% - 24px);
  margin-left: 12px;
  margin-right: 12px
}

.best-brands__title {
  color: #333638;

  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
  margin-bottom: 32px
}

.best-brands__text {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 44px
}

.best-brands__block {
  text-align: center;
  width: 180px
}

.best-brands__block__image {
  border-radius: 50%;
  height: 170px;
  margin-bottom: 33px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  text-align: inherit !important;
  text-align: initial !important;
  width: 170px
}

.best-brands__block__title {

  font-style: normal;
  font-weight: 500;
  text-transform: uppercase
}

.best-brands__block__text {
  font-size: 14px;
  line-height: 24px;
  margin-top: 18px
}

@media screen and (max-width:1023px) {
  .best-brands__grid>:first-child {
    flex-basis: calc(100% - 12px);
    margin-bottom: 90px;
    text-align: center
  }
}

@media screen and (max-width:767px) {
  .best-brands__grid {
    margin-left: -15px;
    margin-right: -15px
  }

  .best-brands__grid>* {
    flex-basis: calc(100% - 30px) !important;
    margin-bottom: 60px
  }

  .best-brands__grid>:first-child {
    margin-bottom: 60px
  }
}

.warranty {
  padding-bottom: 50px
}

.warranty__grid>:first-child {
  padding-right: 12px
}

.warranty__title {
  color: #333638;
  font-size: 46px;
  line-height: 52px;
  margin-bottom: 20px;
  margin-top: 25px
}

.warranty__title,
.warranty__title span {

  font-style: normal;
  font-weight: 400
}

.warranty__button {
  margin-bottom: 30px
}

.warranty__text {
  line-height: 30px;
  position: relative
}

.warranty__text__bg {
  background-color: #fff;
  background-position-y: 2px;
  height: 396px;
  padding-top: 50px;
  position: absolute;
  right: -200px;
  width: 1920px
}

.warranty__text__grid {
  margin-left: -12px;
  margin-right: -12px;
  position: relative;
  z-index: 1
}

.warranty__text__grid>* {
  flex-basis: calc(50% - 24px);
  margin-left: 12px;
  margin-right: 12px;
  padding-right: 40px;
  padding-top: 100px
}

.warranty__text__block {
  background-position: top 8px left -8px;
  background-size: 80px 80px
}

.warranty__text__block_life {
  background-image: url(/build/i/4b762799775f02b2149a.svg)
}

.warranty__text__block_fifteen-years {
  background-image: url(/build/i/45806c810926d75c5272.svg)
}

.warranty__text__block_twenty-years {
  background-image: url(/build/i/f6128fb67cfaf8466ed3.svg);
  background-position: top 22px left 0;
  background-size: 58px 54px
}

.warranty__text__block_denture {
  background-image: url(/build/i/ce49c906a5ba8e728456.svg);
  background-position: top 22px left 0;
  background-size: 58px 54px
}

.warranty__text__block_dentist {
  background-image: url(/build/i/b74507e97ad273c9b33f.svg);
  background-position: top 22px left 0;
  background-size: 58px 54px
}

.warranty__text__block_lock {
  background-image: url(/build/i/84b637f195de054317b4.svg)
}

.warranty__text__block_lock,
.warranty__text__block_quote {
  background-position: top 22px left 0;
  background-size: 59px 54px
}

.warranty__text__block_quote {
  background-image: url(/build/i/824c623b3271945ce4ec.svg)
}

.warranty__text__block_text {
  background-image: url(/build/i/f57ce3c4263b6107caf6.svg);
  background-position: top 22px left 0;
  background-size: 59px 54px
}

.warranty__text__title {
  color: #333638;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 24px
}

.warranty__text__text {
  color: #989ca6;
  font-size: 14px;
  line-height: 26px
}

.warranty__quote-block {
  background-color: #c3c4bf;
  border-radius: 10px;
  box-shadow: 0 10px 29px rgba(70, 81, 86, .18);
  float: right;
  height: 468px;
  padding: 62px 60px;
  position: relative;
  width: 600px
}

.warranty__quote-block:before {
  background-image: url(/build/i/289614f9869ba4b363d5.svg);
  background-size: 47px 47px;
  content: "";
  display: block;
  height: 47px;
  left: -6px;
  opacity: .5;
  position: relative;
  top: -15px;
  width: 47px
}

.warranty__quote-block .progressive-image-bg {
  right: -150px;
  top: 0;
  width: 540px
}

.warranty__quote {
  color: #5f6877;

  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: 28px;
  max-width: 290px;
  position: relative;
  z-index: 1
}

.warranty__person {
  background-color: #fff;
  border-radius: 10px;
  bottom: 62px;
  color: #a5a5a5;
  font-size: 14px;
  line-height: 24px;
  max-width: 325px;
  padding: 14px 22px 12px;
  position: absolute
}

.warranty-triple {
  padding-bottom: 0
}

.warranty-triple .warranty__title {
  margin-bottom: 35px
}

.warranty-triple .warranty__text__bg {
  height: auto;
  padding-bottom: 70px;
  position: relative;
  right: auto;
  width: 100%
}

.warranty-triple .warranty__text__grid {
  margin-left: -12px;
  margin-right: -12px;
  padding-top: 5px
}

.warranty-triple .warranty__text__grid>div {
  flex: 0 1 25%;
  margin: 0;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 0
}

.warranty-triple .warranty__text__grid .warranty__text__block {
  background-position: top 8px left 32px;
  margin-bottom: 40px;
  padding-left: 40px;
  padding-top: 100px
}

.warranty-triple .warranty__text__grid .warranty__text__block_twenty-years {
  background-position: top 22px left 40px
}

.warranty-triple .warranty__text__grid .warranty__text__block_million {
  background-position: top;
  padding: 165px 16px 28px
}

.warranty-triple .warranty__text__grid .warranty__text__block_million .warranty__text__title {
  margin-bottom: 10px;
  max-width: 100%
}

.warranty-triple .warranty__text__grid .warranty__text__title {
  max-width: 200px
}

.warranty-triple .warranty__quote {
  max-width: 100%
}

.warranty-triple .warranty__button {
  box-shadow: 0 0 14px 1px rgba(0, 0, 0, .22);
  left: 15px;
  position: absolute;
  top: 50px
}

.warranty-triple .warranty__quote-block {
  align-items: center;
  background-color: #f2f2f2;
  box-shadow: none;
  float: none;
  height: auto;
  padding: 0 70px;
  width: 100%
}

.warranty-triple .warranty__quote-block:before {
  content: none
}

.warranty-triple .warranty__quote-block>* {
  flex: 0 1 50%
}

.warranty-triple .warranty__quote-block>:first-child {
  height: 215px;
  position: relative
}

.warranty-triple .warranty__quote-block .progressive-image-bg {
  bottom: 0;
  height: 202px;
  right: 15px;
  top: auto;
  transform: scaleX(-1);
  width: 213px;
  z-index: 1
}

.warranty-triple .warranty__quote-block .warranty__quote__img_mobile {
  display: none
}

.warranty-triple .warranty__quote-block .warranty__person {
  bottom: 25px;
  left: 10px;
  max-width: 395px;
  padding: 8px 18px 7px;
  width: 100%
}

.warranty-triple .warranty__quote-block .warranty__quote {
  padding-left: 58px
}

.warranty-triple .warranty__quote-block .warranty__quote:before {
  background-image: url(/build/i/289614f9869ba4b363d5.svg);
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 36px;
  left: 0;
  position: absolute;
  top: -10px;
  width: 36px
}

.warranty__text__block_million {
  background-color: #fff;
  background-image: url(/build/i/fb856838815a03318808.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  border: 14px solid #f2f2f2;
  border-radius: 10px
}

@media screen and (max-width:1359px) {
  .warranty__title {
    font-size: 36px;
    line-height: 48px
  }

  .warranty__grid>:first-child {
    padding-right: 0
  }

  .warranty__quote-block {
    height: auto;
    padding: 48px 48px 325px;
    width: 394px
  }

  .warranty__quote-block .progressive-image-bg {
    bottom: 45px;
    height: 290px;
    left: 50%;
    margin-left: -150px;
    right: inherit !important;
    right: auto !important;
    top: inherit !important;
    top: auto !important;
    width: 300px
  }

  .warranty__person {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding: 20px 48px;
    width: 100%
  }

  .warranty-triple .warranty__text__grid>div {
    flex-basis: 50%
  }

  .warranty-triple .warranty__text__grid .warranty__text__block_million {
    background-image: url(/build/i/f132f7a60cbca56cc8a8.jpg);
    margin-left: 40px;
    max-width: 405px
  }

  .warranty-triple .warranty__button {
    left: 0
  }

  .warranty-triple .warranty__quote-block {
    padding-left: 30px;
    padding-right: 30px
  }

  .warranty-triple .warranty__quote-block .progressive-image-bg {
    margin-left: 55px
  }

  .warranty-triple .warranty__quote-block .warranty__person {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-size: 13px;
    left: 0
  }

  .warranty-triple .warranty__quote-block .warranty__quote {
    padding-left: 75px
  }

  .warranty-triple .warranty__quote-block .warranty__quote:before {
    left: 20px
  }
}

@media screen and (max-width:1024px) {
  .warranty__text__grid>* {
    padding-right: 0
  }
}

@media screen and (max-width:1023px) {
  .warranty__title {
    font-size: 40px;
    margin-bottom: 15px;
    text-align: center
  }

  .warranty__title br {
    display: none
  }

  .warranty__grid>* {
    flex-basis: calc(100% - 24px)
  }

  .warranty__grid>:last-child {
    margin-top: 30px
  }

  .warranty__quote-block {
    background-size: inherit !important;
    background-size: initial !important;
    border-radius: 0;
    float: none;
    height: 468px;
    margin-left: -26px;
    margin-right: -26px;
    padding: 26px 60px;
    width: auto
  }

  .warranty__quote-block .progressive-image-bg {
    bottom: 0;
    left: inherit !important;
    left: auto !important;
    margin-left: 0 !important;
    right: 0 !important;
    top: inherit !important;
    top: auto !important
  }

  .warranty__person {
    border-radius: 10px;
    bottom: 62px;
    left: 48px;
    max-width: 325px;
    padding: 14px 22px 12px
  }

  .warranty__text {
    text-align: center
  }

  .warranty__text__block {
    background-position: top
  }

  .warranty__button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 280px;
    text-align: center
  }

  .warranty-triple .warranty__title {
    text-align: left
  }

  .warranty-triple .warranty__quote {
    padding-left: 26px;
    padding-right: 26px;
    padding-top: 60px
  }

  .warranty-triple .warranty__text__bg {
    padding-bottom: 60px
  }

  .warranty-triple .warranty__text__grid .warranty__text__block {
    background-position: top 8px left -8px;
    padding-left: 0
  }

  .warranty-triple .warranty__text__grid .warranty__text__block_twenty-years {
    background-position: top 22px left 0
  }

  .warranty-triple .warranty__text__grid .warranty__text__text,
  .warranty-triple .warranty__text__grid .warranty__text__title {
    text-align: left
  }

  .warranty-triple .warranty__text__grid .warranty__text__block_million {
    background-position: top;
    margin-left: 0;
    padding-left: 10px;
    padding-right: 10px
  }

  .warranty-triple .warranty__text__grid .warranty__text__block_lock,
  .warranty-triple .warranty__text__grid .warranty__text__block_quote,
  .warranty-triple .warranty__text__grid .warranty__text__block_text {
    background-position: top 22px left 0
  }

  .warranty-triple .warranty__button {
    left: 248px;
    max-width: 270px;
    padding: 0 15px;
    position: absolute;
    top: 30px
  }

  .warranty-triple .warranty__quote-block {
    align-items: flex-start;
    border-radius: 10px;
    float: none;
    min-height: 285px;
    padding: 80px 22px 100px 248px;
    position: relative;
    width: auto
  }

  .warranty-triple .warranty__quote-block>* {
    flex: 1 0 100%
  }

  .warranty-triple .warranty__quote-block>:first-child {
    align-self: flex-end;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%
  }

  .warranty-triple .warranty__quote-block .progressive-image-bg {
    bottom: 0;
    left: 0 !important;
    right: auto;
    top: auto
  }

  .warranty-triple .warranty__quote-block .warranty__person {
    bottom: 20px;
    left: 115px;
    padding: 8px 18px 7px 95px
  }

  .warranty-triple .warranty__quote-block .warranty__quote {
    align-self: center;
    font-size: 16px;
    left: 0;
    line-height: 24px;
    max-width: none;
    padding-left: 56px;
    padding-top: 10px
  }

  .warranty-triple .warranty__quote-block .warranty__quote:before {
    left: 0;
    opacity: 1;
    top: 0
  }

  .warranty-excerption .warranty__quote {
    padding-top: 0
  }
}

@media screen and (max-width:767px) {
  .warranty__title {
    font-size: 30px;
    line-height: 30px
  }

  .warranty__grid {
    margin-left: -15px;
    margin-right: -15px
  }

  .warranty__grid>* {
    flex-basis: calc(100% - 30px);
    margin-left: 15px;
    margin-right: 15px
  }

  .warranty__quote-block {
    height: auto;
    padding: 48px 48px 325px;
    width: auto
  }

  .warranty__quote-block .progressive-image-bg {
    bottom: 55px;
    left: 50% !important;
    right: inherit !important;
    right: auto !important;
    transform: translateX(-50%)
  }

  .warranty__person {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding: 20px 48px;
    width: 100%
  }

  .warranty-triple .warranty__title {
    font-size: 26px;
    margin-bottom: 25px
  }

  .warranty-triple .warranty__text__bg {
    padding-bottom: 0
  }

  .warranty-triple .warranty__quote {
    padding-left: 0;
    padding-right: 0;
    padding-top: 50px
  }

  .warranty-triple .warranty__text__grid>div {
    flex-basis: 100%
  }

  .warranty-triple .warranty__text__grid>div:first-child .warranty__text__block,
  .warranty-triple .warranty__text__grid>div:nth-child(2) .warranty__text__block,
  .warranty-triple .warranty__text__grid>div:nth-child(3) .warranty__text__block {
    background-position: top -5px left 0;
    background-size: auto 45px;
    margin-bottom: 15px;
    padding-left: 68px;
    padding-top: 0
  }

  .warranty-triple .warranty__text__grid>div:first-child .warranty__text__block.opened .warranty__text__title:after,
  .warranty-triple .warranty__text__grid>div:nth-child(2) .warranty__text__block.opened .warranty__text__title:after,
  .warranty-triple .warranty__text__grid>div:nth-child(3) .warranty__text__block.opened .warranty__text__title:after {
    transform: rotate(-90deg);
    transition: transform .2s ease-out
  }

  .warranty-triple .warranty__text__grid>div:first-child .warranty__text__block.opened .warranty__text__text,
  .warranty-triple .warranty__text__grid>div:nth-child(2) .warranty__text__block.opened .warranty__text__text,
  .warranty-triple .warranty__text__grid>div:nth-child(3) .warranty__text__block.opened .warranty__text__text {
    height: auto;
    transform: scaleY(1);
    transform-origin: top;
    transition: transform .1s ease-in, height .05s ease-in
  }

  .warranty-triple .warranty__text__grid>div:first-child .warranty__text__block_lock,
  .warranty-triple .warranty__text__grid>div:first-child .warranty__text__block_quote,
  .warranty-triple .warranty__text__grid>div:first-child .warranty__text__block_text,
  .warranty-triple .warranty__text__grid>div:first-child .warranty__text__block_twenty-years,
  .warranty-triple .warranty__text__grid>div:nth-child(2) .warranty__text__block_lock,
  .warranty-triple .warranty__text__grid>div:nth-child(2) .warranty__text__block_quote,
  .warranty-triple .warranty__text__grid>div:nth-child(2) .warranty__text__block_text,
  .warranty-triple .warranty__text__grid>div:nth-child(2) .warranty__text__block_twenty-years,
  .warranty-triple .warranty__text__grid>div:nth-child(3) .warranty__text__block_lock,
  .warranty-triple .warranty__text__grid>div:nth-child(3) .warranty__text__block_quote,
  .warranty-triple .warranty__text__grid>div:nth-child(3) .warranty__text__block_text,
  .warranty-triple .warranty__text__grid>div:nth-child(3) .warranty__text__block_twenty-years {
    background-position: top 3px left 5px;
    background-size: auto 30px
  }

  .warranty-triple .warranty__text__grid>div:first-child .warranty__text__title,
  .warranty-triple .warranty__text__grid>div:nth-child(2) .warranty__text__title,
  .warranty-triple .warranty__text__grid>div:nth-child(3) .warranty__text__title {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 5px;
    margin-left: 0;
    max-width: 145px;
    min-height: 35px;
    position: relative;
    text-align: left
  }

  .warranty-triple .warranty__text__grid>div:first-child .warranty__text__title:after,
  .warranty-triple .warranty__text__grid>div:nth-child(2) .warranty__text__title:after,
  .warranty-triple .warranty__text__grid>div:nth-child(3) .warranty__text__title:after {
    content: "â€º";
    margin-top: -10px;
    position: absolute;
    right: -30px;
    top: 50%;
    transform: rotate(90deg);
    transition: transform .2s ease-in
  }

  .warranty-triple .warranty__text__grid>div:first-child .warranty__text__text,
  .warranty-triple .warranty__text__grid>div:nth-child(2) .warranty__text__text,
  .warranty-triple .warranty__text__grid>div:nth-child(3) .warranty__text__text {
    height: 0;
    line-height: 18px;
    text-align: left;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0s ease-out, height 0s ease-out
  }

  .warranty-triple .warranty__text__grid .warranty__text__block_million {
    background-image: url(/build/i/fb856838815a03318808.jpg);
    margin-left: auto;
    margin-right: auto;
    max-width: 290px
  }

  .warranty-triple .warranty__button {
    background-color: transparent;
    box-shadow: none;
    color: #4bbdb4;
    font-size: 15px;
    max-width: 100%;
    text-align: center;
    top: -65px;
    width: 290px
  }

  .warranty-triple .warranty__button>span {
    border-bottom: 1px solid #4bbdb4
  }

  .warranty-triple .warranty__quote-block {
    background-color: transparent;
    border-radius: 0;
    height: auto;
    margin-left: -15px;
    margin-right: -15px;
    min-height: auto;
    padding: 0 0 75px
  }

  .warranty-triple .warranty__quote-block .warranty__person {
    border-bottom: 1px solid #d8dfe1;
    border-radius: 0;
    bottom: 0;
    font-style: normal;
    left: 0;
    max-width: 100%;
    padding: 15px
  }

  .warranty-triple .warranty__quote-block .warranty__quote__img {
    display: none
  }

  .warranty-triple .warranty__quote-block .warranty__button {
    left: 50%;
    margin-bottom: 0;
    transform: translateX(-50%)
  }

  .warranty-triple .warranty__quote-block .warranty__quote__img_mobile {
    bottom: 0;
    display: block;
    left: auto !important;
    position: absolute;
    right: 0 !important;
    transform: scale(1);
    z-index: -1
  }

  .warranty-triple .warranty__quote-block .warranty__quote {
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    justify-content: center;
    left: 0;
    line-height: 22px;
    max-width: 100%;
    min-height: 210px;
    padding: 28px 160px 25px 11px;
    position: relative
  }

  .warranty-triple .warranty__quote-block .warranty__quote:before {
    display: block;
    margin-bottom: 18px;
    position: static
  }

  .page_251 .warranty+.pusher {
    margin-top: 30px !important
  }

  .warranty-excerption .warranty__quote-block .warranty__person {
    border-bottom: none
  }
}

@media screen and (max-width:450px) {
  .warranty__text__bg {
    height: 574px
  }

  .warranty__text__grid {
    margin-left: -15px;
    margin-right: -15px
  }

  .warranty__text__grid>* {
    background-position: center top 23px;
    flex-basis: calc(100% - 30px);
    margin-left: 15px;
    margin-right: 15px;
    padding-right: 0
  }

  .warranty-triple .warranty__quote-block .warranty__quote__img_mobile {
    right: -40px !important
  }

  .warranty-triple .warranty__quote-block .warranty__quote {
    padding: 28px 120px 25px 11px
  }
}

.why-choose-us__title {
  color: #333638;

  font-size: 50px;
  font-style: normal;
  font-weight: 400
}

.why-choose-us__text {
  line-height: 30px;
  position: relative
}

.why-choose-us__text__bg {
  background-color: #fff;
  background-position-y: 2px;
  height: 396px;
  position: absolute;
  right: -200px;
  width: 1920px
}

.why-choose-us__text__grid {
  margin-left: -12px;
  margin-right: -12px;
  padding-top: 38px;
  position: relative;
  z-index: 1
}

.why-choose-us__text__grid>* {
  flex-basis: calc(50% - 24px);
  margin-bottom: 54px;
  margin-left: 12px;
  margin-right: 12px
}

.why-choose-us__text__title {
  color: #333638;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 24px
}

.why-choose-us__text__text {
  color: #989ca6;
  font-size: 14px;
  line-height: 26px
}

.why-choose-us__button {
  margin-top: 30px
}

.why-choose-with-certificates .why-choose__grid {
  align-items: center;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 75px
}

.why-choose-with-certificates .why-choose__grid>* {
  flex-basis: 41.66%;
  max-width: 41.66%;
  padding-left: 15px;
  padding-right: 15px
}

.why-choose-with-certificates .why-choose__grid>:nth-child(2) {
  flex-basis: 58.33%;
  max-width: 58.33%
}

.why-choose-with-certificates .slider-wrapper-image {
  margin-right: 80px;
  max-width: 386px
}

.why-choose-with-certificates .slider-wrapper-image .image-slider__navigator {
  bottom: 60px;
  left: auto;
  right: -61px;
  top: auto
}

.why-choose-with-certificates .slider__item__image-wrapper .progressive-image-wrapper {
  border-radius: 0;
  max-width: 100%
}

.why-choose-with-certificates .why-choose__list {
  margin-left: -15px;
  margin-right: -15px
}

.why-choose-with-certificates .why-choose__list>* {
  margin-top: 70px;
  padding-left: 15px;
  padding-right: 15px
}

.why-choose-with-certificates .why-choose__list>:first-child,
.why-choose-with-certificates .why-choose__list>:nth-child(2) {
  margin-top: 0
}

.why-choose-with-certificates .why-choose__item {
  text-align: center
}

.why-choose-with-certificates .why-choose__icon {
  display: block;
  height: 50px;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  width: 50px
}

.why-choose-with-certificates .why-choose__icon>svg {
  max-height: 100%;
  max-width: 100%
}

.why-choose-with-certificates .why-choose__icon>svg path {
  fill: #00afa6
}

.why-choose-with-certificates .why-choose__title {
  color: #00afa6;
  display: inline-block;

  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 24px
}

.why-choose-with-certificates .why-choose__title>span {
  border-bottom: 1px dashed rgba(0, 175, 166, .7);
  transition: border .2s ease-out
}

.why-choose-with-certificates .why-choose__title:hover>span {
  border-bottom: 1px dashed transparent;
  transition: border .2s ease-in
}

.why-choose-with-certificates .why-choose__text {
  color: #5f6877;
  font-size: 14px;
  line-height: 26px
}

.why-choose-us_compact .with-image-slider__text {
  margin-top: 0
}

@media screen and (max-width:1363px) {
  .why-choose-us .with-image-slider>:last-child {
    padding-top: 150px
  }

  .why-choose-with-certificates .why-choose__grid {
    margin-top: 45px
  }

  .why-choose-with-certificates .why-choose__list>* {
    margin-top: 55px
  }

  .why-choose-with-certificates .why-choose__text {
    margin-left: auto;
    margin-right: auto;
    max-width: 200px
  }

  .why-choose-with-certificates .why-choose__text br {
    display: none
  }
}

@media screen and (max-width:1199px) {
  .why-choose-us__text__grid>* {
    flex-basis: 100%;
    margin-bottom: 25px
  }

  .why-choose-us__text__title {
    margin-bottom: 15px
  }
}

@media screen and (max-width:1023px) {
  .why-choose-us .with-image-slider>:last-child {
    padding-top: 50px
  }

  .why-choose-us__text__grid>* {
    flex-basis: calc(50% - 24px);
    margin-bottom: 50px
  }

  .why-choose-with-certificates .slider-wrapper-image {
    margin-bottom: 0;
    padding-bottom: 0
  }

  .why-choose-with-certificates .slider__item__image-wrapper {
    margin-top: 0
  }

  .with-image-slider-wrapper__show_all .with-image-slider>div {
    margin-bottom: 0
  }

  .with-image-slider-wrapper__show_all .with-image-slider>div:nth-child(2) {
    display: block
  }

  .with-image-slider-wrapper__show_all .with-image-slider>div:nth-child(2) .slider-wrapper-image {
    margin-bottom: 0;
    padding-bottom: 25px
  }
}

@media screen and (max-width:900px) {

  .why-choose-with-certificates .why-choose__grid>*,
  .why-choose-with-certificates .why-choose__grid>:nth-child(2) {
    flex-basis: 100%;
    max-width: 100%
  }

  .why-choose-with-certificates .slider-wrapper-image {
    margin-left: auto;
    margin-right: auto;
    max-width: 290px;
    padding-bottom: 80px
  }

  .why-choose-with-certificates .slider-wrapper-image .image-slider__navigator {
    bottom: 0;
    left: 50%;
    margin-left: -60px;
    right: auto
  }

  .why-choose-with-certificates .why-choose__list>:first-child,
  .why-choose-with-certificates .why-choose__list>:nth-child(2) {
    margin-top: 45px
  }
}

@media screen and (max-width:767px) {
  .why-choose-us__text__grid {
    margin-left: -15px;
    margin-right: -15px
  }

  .why-choose-us__text__grid>* {
    flex-basis: calc(100% - 30px);
    margin-left: 15px;
    margin-right: 15px
  }

  .why-choose-us__text__bg {
    height: 100%
  }

  .why-choose-us .with-image-slider .slick-list {
    border-radius: 0
  }

  .why-choose-us .with-image-slider>:last-child {
    padding-top: 0
  }

  .why-choose-us__text__grid>div:nth-child(3),
  .why-choose-us__text__grid>div:nth-child(4) {
    display: none
  }

  [data-js-scroll-target=why-choose-us] .with-image-slider>div {
    margin-bottom: 0
  }

  [data-js-scroll-target=why-choose-us] .with-image-slider>div:nth-child(2) {
    display: none
  }

  .why-choose-with-certificates .slider-wrapper-image {
    margin-left: auto;
    margin-right: auto;
    max-width: 290px;
    padding-bottom: 60px
  }

  .with-image-slider-wrapper__show_all .with-image-slider__text {
    margin-top: 19px
  }

  .with-image-slider-wrapper__show_all .why-choose-us__text__grid {
    padding-top: 19px
  }

  .with-image-slider-wrapper__show_all .why-choose-us__text__grid>div {
    margin-bottom: 30px
  }

  .with-image-slider-wrapper__show_all .why-choose-us__text__grid>div:nth-child(3),
  .with-image-slider-wrapper__show_all .why-choose-us__text__grid>div:nth-child(4) {
    display: block
  }

  .with-image-slider-wrapper__show_all .with-image-slider>div {
    margin-bottom: 0
  }

  .with-image-slider-wrapper__show_all .with-image-slider>div:nth-child(2) {
    display: block
  }

  .with-image-slider-wrapper__show_all .with-image-slider>div:nth-child(2) .slider-wrapper-image {
    margin-left: 0;
    margin-right: 0
  }

  .with-image-slider-wrapper__show_all .with-image-slider>div:nth-child(2) .progressive-image-wrapper {
    border-radius: 10px
  }

  .with-image-slider-wrapper__show_all .with-image-slider>div:nth-child(2) .slider-wrapper-image {
    padding-bottom: 60px
  }

  .why-choose-us_with-accordion .why-choose-us__text__grid {
    margin-top: 15px;
    padding-top: 15px
  }

  .why-choose-us_with-accordion .why-choose-us__text__grid>div {
    margin-bottom: 20px
  }

  .why-choose-us_with-accordion .why-choose-us__text__grid>div:nth-child(3),
  .why-choose-us_with-accordion .why-choose-us__text__grid>div:nth-child(4) {
    display: block
  }

  .why-choose-us_with-accordion .why-choose-us__text__grid>div.opened .why-choose-us__text__text {
    height: auto;
    transform: scaleY(1);
    transition: transform .1s ease-in, height .05s ease-in
  }

  .why-choose-us_with-accordion .why-choose-us__text__grid>div.opened .why-choose-us__text__title:after {
    transform: rotate(-90deg)
  }

  .why-choose-us_with-accordion .why-choose-us__text__title {
    display: inline-block;
    margin-bottom: 8px;
    position: relative
  }

  .why-choose-us_with-accordion .why-choose-us__text__title:after {
    content: "â€º";
    margin-top: -14px;
    position: absolute;
    right: -20px;
    top: 50%;
    transform: rotate(90deg);
    transition: transform .2s ease-in
  }

  .why-choose-us_with-accordion .why-choose-us__text__text {
    height: 0;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0s ease-out, height 0s ease-out
  }
}

@media screen and (max-width:540px) {
  .why-choose-with-certificates .why-choose__grid {
    margin-top: 35px
  }

  .why-choose-with-certificates .why-choose__list>* {
    flex-basis: 100%;
    margin-top: 75px
  }

  .why-choose-with-certificates .why-choose__list>:first-child {
    margin-top: 45px
  }

  .why-choose-with-certificates .why-choose__list>:nth-child(2) {
    margin-top: 75px
  }

  .why-choose-with-certificates .why-choose__text {
    max-width: 290px
  }

  .with-image-slider-wrapper__show_all .with-image-slider__text {
    margin-top: 11px
  }

  .with-image-slider-wrapper__show_all .why-choose-us__text__grid {
    padding-top: 11px
  }

  .with-image-slider-wrapper__show_all .why-choose-us__text__grid>div {
    margin-bottom: 20px
  }

  .with-image-slider-wrapper__show_all .with-image-slider .slider__item__image-wrapper {
    margin-top: 15px
  }
}

.disadvantages__title {
  margin-bottom: 53px;
  text-align: left
}

.disadvantages__grid {
  margin-left: -12px;
  margin-right: -12px
}

.disadvantages__grid>* {
  padding-left: 12px;
  padding-right: 12px
}

.disadvantages__grid>:before {
  background-image: url(/build/i/daf84b42eccf6819e676.svg);
  content: "";
  display: block;
  height: 30px;
  margin-bottom: 23px;
  width: 30px
}

.disadvantages__grid__title {
  color: #333638;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 12px
}

@media screen and (max-width:1359px) {
  .disadvantages__grid>* {
    padding-right: 12px
  }
}

@media screen and (max-width:767px) {
  .disadvantages__title {
    margin-bottom: 20px;
    text-align: center
  }

  .disadvantages__grid>* {
    flex: 1 0 100%;
    padding-right: 12px
  }

  .disadvantages__grid>:before {
    content: none
  }
}

.types .tab {
  margin-top: 51px
}

.types .tab__imaged-block>:last-child {
  padding-right: 78px;
  padding-top: 25px
}

.tabs__labels.tabs__labels_buttons .types .tab__imaged-block>*>.uppercase.tab__label,
.types .tab__imaged-block>*>.link.uppercase,
.types .tab__imaged-block>*>.uppercase.slide-tab__label,
.types .tab__imaged-block>*>.uppercase[data-js=tooltip],
.types .tab__imaged-block>.slider-cost-of__slide__prices>.uppercase[data-js=tooltip],
.types .tabs__labels.tabs__labels_buttons .tab__imaged-block>*>.uppercase.tab__label {
  display: inline-block;
  margin-top: 29px
}

.types__accordion_mobile {
  display: none
}

@media screen and (max-width:1359px) {
  .types .tab__imaged-block>* {
    flex: 1 1 auto
  }

  .types .tab__imaged-block>:last-child {
    flex: 1 1;
    flex-basis: calc(50% - 80px);
    padding-right: 0
  }

  .types .tab__imaged-block .progressive-image-wrapper {
    height: auto !important;
    width: auto !important
  }
}

@media screen and (max-width:1199px) {
  .types .tab__imaged-block {
    margin-left: -20px;
    margin-right: -20px
  }

  .types .tab__imaged-block>* {
    flex: 0 0 313px;
    margin-left: 20px;
    margin-right: 20px
  }

  .types .tab__imaged-block>:last-child {
    flex-basis: calc(50% - 48px)
  }

  .types .tab__imaged-block .progressive-image-wrapper {
    max-width: 100%
  }
}

@media screen and (max-width:800px) {
  .types .tab__imaged-block>* {
    flex-basis: auto !important
  }

  .types .tab__imaged-block .progressive-image-wrapper {
    width: 220px !important
  }
}

@media screen and (max-width:767px) {
  .types .tabs {
    margin-top: 50px
  }

  .types .tabs__labels {
    text-align: center
  }

  .types .tabs__labels .tab__label {
    margin-left: 6px;
    margin-right: 6px
  }

  .types .tab__imaged-block {
    justify-content: center
  }

  .types .tab__imaged-block>* {
    flex-basis: 100%
  }

  .types .tab__imaged-block>:last-child {
    padding-right: 0
  }

  .tabs__labels.tabs__labels_buttons .types .tab__imaged-block>*>.uppercase.tab__label,
  .types .tab__imaged-block>*>.link.uppercase,
  .types .tab__imaged-block>*>.uppercase.slide-tab__label,
  .types .tab__imaged-block>*>.uppercase[data-js=tooltip],
  .types .tab__imaged-block>.slider-cost-of__slide__prices>.uppercase[data-js=tooltip],
  .types .tabs__labels.tabs__labels_buttons .tab__imaged-block>*>.uppercase.tab__label {
    display: block;
    text-align: center
  }

  .types .tab__imaged-block .progressive-image-wrapper {
    max-width: 100%;
    width: 320px !important
  }

  .types .tabs {
    display: none
  }

  .types__accordion_mobile {
    display: block
  }

  .types__accordion_mobile .tab__imaged-block {
    padding-bottom: 30px
  }

  .tabs__labels.tabs__labels_buttons .types__accordion_mobile .tab__imaged-block>*>.uppercase.tab__label,
  .types__accordion_mobile .tab__imaged-block>*>.link.uppercase,
  .types__accordion_mobile .tab__imaged-block>*>.uppercase.slide-tab__label,
  .types__accordion_mobile .tab__imaged-block>*>.uppercase[data-js=tooltip],
  .types__accordion_mobile .tab__imaged-block>.slider-cost-of__slide__prices>.uppercase[data-js=tooltip],
  .types__accordion_mobile .tabs__labels.tabs__labels_buttons .tab__imaged-block>*>.uppercase.tab__label {
    font-size: 14px;
    margin-top: 15px
  }

  .types__accordion_mobile .tab__imaged-block .tab__title {
    font-size: 14px;
    margin-bottom: 15px
  }

  .types__accordion_mobile .tab__imaged-block p {
    font-size: 15px;
    margin-bottom: 20px
  }

  .types__accordion_mobile .tab__imaged-block br {
    display: none
  }
}

@media screen and (max-width:320px) {
  .types .tab__imaged-block .progressive-image-wrapper {
    border-radius: 0
  }
}

.installment-terms__grid {
  margin-left: -12px;
  margin-right: -12px
}

.installment-terms__grid>* {
  flex-basis: calc(25% - 24px);
  margin-left: 12px;
  margin-right: 12px
}

.installment-terms__text {
  margin-top: 53px;
  max-width: 1024px;
  text-align: center
}

.installment-terms__text p {
  font-size: 16px;
  line-height: 32px;
  margin-bottom: 22px
}

.installment-terms__block {
  margin-top: 43px;
  text-align: center
}

.installment-terms__block__title {
  color: #333638;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-top: 16px
}

.installment-terms__block__text {
  font-size: 14px;
  line-height: 26px;
  margin-top: 8px;
  max-width: 205px
}

.installment-terms__button-wrapper {
  margin-top: 93px;
  text-align: center
}

@media screen and (max-width:1120px) {
  .installment-terms__grid>* {
    flex-basis: calc(50% - 24px)
  }
}

@media screen and (max-width:660px) {
  .installment-terms__grid {
    margin-left: -15px;
    margin-right: -15px
  }

  .installment-terms__grid>* {
    flex-basis: calc(100% - 30px);
    margin-left: 15px;
    margin-right: 15px
  }
}

.individually {
  background-color: #d5dbdb;
  background-image: url(/build/i/b1df309103d056a36e71.png);
  background-position: bottom left 24px;
  border-radius: 10px;
  position: relative
}

.individually:after {
  background-image: url(/build/i/3886998e886c08878fd7.svg);
  background-size: contain;
  content: "";
  display: inline-block;
  height: 100px;
  position: absolute;
  right: 143px;
  top: 62px;
  width: 106px
}

.individually__content {
  padding: 85px 100px 94px 518px
}

.individually__title {
  color: #3c4143;

  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: 36px
}

.individually__title strong {

  font-size: 36px;
  font-style: normal;
  font-weight: 500
}

.individually__text {
  color: #5f6877;
  font-size: 16px;
  line-height: 30px;
  margin-top: 38px
}

.individually__text p {
  margin-bottom: 11px
}

.individually__text p strong {

  font-style: normal;
  font-weight: 500
}

.individually__text ul {
  margin-bottom: 30px
}

.individually__text li {
  color: #5f6877;
  font-size: 16px;
  margin-bottom: 14px
}

.individually__call {
  margin-top: 53px
}

.individually__call .button,
.individually__call .slide-tab__label.active,
.individually__call .tabs__labels.tabs__labels_buttons .tab__label.active,
.tabs__labels.tabs__labels_buttons .individually__call .tab__label.active {
  float: left
}

.individually__call .button_white:hover {
  background-color: #fff
}

.individually__call__title {
  color: #5f6877;
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 15px;
  padding-left: 35px;
  position: relative
}

.individually__call__title:before {
  background-color: #01ca6e;
  background-image: url(/build/i/497fc56f0aef400009b3.svg);
  background-position: 50%;
  background-size: 16px 16px;
  border-radius: 50%;
  content: "";
  display: block;
  height: 26px;
  left: 0;
  position: absolute;
  width: 26px
}

.individually__call__buttons br {
  display: none
}

.individually__call__phone-link {
  color: #3c4143;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin-right: 27px
}

.individually__call__phone-link:hover {
  color: #00ada4
}

.individually__call__phone-link span {
  color: #819292
}

@media screen and (max-width:1359px) {
  .individually:after {
    right: 60px
  }
}

@media screen and (max-width:1280px) {

  .individually-wrapper>.row,
  .individually-wrapper>.row_short {
    padding: 0;
    width: 100%
  }

  .individually {
    background-color: #d5dbdb;
    border-radius: 0;
    position: relative
  }
}

@media screen and (max-width:1142px) {
  .individually {
    background-position: -75px bottom
  }

  .individually__content {
    padding-left: 418px
  }
}

@media screen and (max-width:1023px) {
  .individually:after {
    height: 56px;
    left: 50%;
    margin-left: -30px;
    top: 34px;
    width: 60px
  }

  .individually {
    background-color: #5fe0e2;
    background-image: none
  }

  .individually:after {
    top: 26px
  }

  .individually__content {
    padding: 85px 26px 66px
  }

  .individually__content p {
    line-height: 20px;
    text-align: center
  }

  .individually__title {
    font-size: 18px;
    line-height: 26px;
    text-align: center
  }

  .individually__title strong {
    font-size: 24px
  }

  .individually__call {
    margin-top: 40px;
    text-align: center
  }

  .individually__call .button,
  .individually__call .slide-tab__label.active,
  .individually__call .tabs__labels.tabs__labels_buttons .tab__label.active,
  .tabs__labels.tabs__labels_buttons .individually__call .tab__label.active {
    float: none
  }

  .individually__call__buttons br {
    display: block
  }

  .individually__call__phone-link {
    margin-bottom: 38px;
    margin-right: 0
  }

  .individually__text {
    margin-top: 25px;
    text-align: center
  }

  .individually__text ul {
    display: inline-block
  }

  .individually__text li {
    font-size: 14px;
    text-align: left
  }
}

@media screen and (max-width:767px) {
  .individually__content {
    padding-left: 15px;
    padding-right: 15px
  }
}

.service-mini-wrapper {
  border-bottom: 1px solid #d9e1e1
}

.service-mini {
  position: relative
}

.service-mini>div {
  position: relative;
  z-index: 1
}

.service-mini:after {
  background-color: #00beb4;
  content: "";
  display: block;
  height: 100%;
  left: 670px;
  position: absolute;
  top: 0;
  width: 1000px;
  z-index: 0
}

.service-mini__buttons {
  position: relative
}

.service-mini__play {
  display: inline-block;
  height: 60px;
  margin-left: 15px;
  position: absolute;
  width: 60px
}

.service-mini__play .circle-icon_play:before {
  left: 50%;
  margin-left: -10px;
  margin-top: -10px;
  top: 50%
}

.service-mini_common .service-mini__calc {
  padding-bottom: 156px
}

.service-mini_common .service-mini__calc:before {
  background-image: url(/build/i/671dd7c15445d199c7d1.png);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 1000px;
  z-index: 0
}

.service-mini__text {
  padding-top: 27px;
  z-index: 2 !important
}

.service-mini__text .breadcrumbs {
  margin-bottom: 20px
}

.service-mini__text .scrolls {
  background-color: #fff;
  border-bottom: 1px solid #d9e1e1;
  border-top: 1px solid #d9e1e1;
  display: none;
  height: 66px;
  margin-top: 36px
}

.service-mini__text .h1,
.service-mini__text .h1_supplement {
  font-size: 42px;
  line-height: 51px;
  margin-top: 0
}

.service-mini__list {
  color: #5f6877;
  font-size: 16px;
  line-height: 24px;
  list-style: none;
  margin-bottom: 21px;
  margin-top: 30px;
  padding-left: 0
}

.service-mini__list>li {
  margin-bottom: 10px;
  padding-left: 26px;
  position: relative
}

.service-mini__list>li:before {
  content: "";
  display: block;
  height: 16px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 16px
}

.service-mini__list>li strong {
  color: #5f6877;

  font-style: normal;
  font-weight: 500
}

.service-mini__list_warranty:before {
  background-image: url(/build/i/477f220b84930966526f.svg)
}

.service-mini__list_clock:before {
  background-image: url(/build/i/cfbbdbc84bb4896e1e0c.svg)
}

.service-mini__list_ruble:before {
  background-image: url(/build/i/4a76fe220e30e22f76f3.svg)
}

.service-mini__list_smile:before {
  background-image: url(/build/i/b74e097a2c16dda471ab.svg)
}

.service-mini__list_thumbs-up:before {
  background-image: url(/build/i/7e5a02d8bf9715372dfc.svg)
}

.service-mini__list_ambulance:before {
  background-image: url(/build/i/a7c6a395e91003ea3b34.svg)
}

.service-mini__list_doctor:before {
  background-image: url(/build/i/ef56692682bc8915d1be.svg)
}

.service-mini__list_crown:before {
  background-image: url(/build/i/28aef1962fcd03a5423d.svg)
}

.service-mini__list_flag:before {
  background-image: url(/build/i/76e838e23c489eb5898b.svg)
}

.service-mini__list_swiss:before {
  background-image: url(/build/i/37be169ec0ace7eac209.svg)
}

.service-mini__price-note {
  color: #5f6877;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 30px
}

.service-mini__calc {
  background-color: #00beb4;
  padding-bottom: 60px;
  padding-left: 79px;
  padding-top: 87px;
  position: relative
}

.service-mini__calc .checkbox:checked+label:before {
  background-color: #fff;
  border: 2px solid #fff;
  height: 20px;
  width: 20px
}

.service-mini__calc .checkbox:not(checked)+label:after {
  top: 3px
}

.service-mini__calc__title {
  color: #fff;

  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px
}

.service-mini__calc__selects {
  margin-top: 27px
}

.service-mini__calc__selects .selectric-wrapper {
  margin-bottom: 18px
}

.service-mini__calc__selects_with-quantity {
  display: flex;
  justify-content: space-between
}

.service-mini__calc__selects_with-quantity>div:first-child {
  flex-basis: 314px
}

.service-mini__calc__selects_with-quantity>div:last-child {
  flex-basis: 184px
}

.service-mini__calc__checkboxes-wrapper {
  min-height: 99px
}

.service-mini__calc__checkboxes {
  margin-top: 19px;
  position: relative;
  z-index: 1
}

.service-mini__calc__checkboxes>div:first-child {
  float: left
}

.service-mini__calc__checkboxes>div:last-child {
  float: right;
  min-width: 200px
}

.service-mini__calc__checkboxes .checkbox+label {
  color: #fff;
  display: inline-block;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px
}

.service-mini__calc__checkboxes .checkbox+label:before {
  top: 6px
}

.service-mini__calc__checkboxes_one-line label span {
  position: relative;
  top: 7px
}

.service-mini_implantation .service-mini__calc__checkboxes .checkbox+label {

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  height: 32px;
  line-height: 16px
}

.service-mini_implantation .service-mini__calc__checkboxes .checkbox+label>span {
  margin-left: 35px
}

.service-mini__divider {
  background-color: #00ada4;
  height: 1px;
  left: 0;
  margin-top: 33px;
  position: absolute;
  width: 1000px
}

.service-mini__items {
  color: #333638;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 54px;
  margin-top: 57px
}

.service-mini__items>div>div:first-child {
  margin-bottom: 6px
}

.service-mini__items>div>div:last-child {
  font-size: 20px
}

.service-mini__items .new {
  font-size: 20px;
  line-height: 26px
}

.service-mini__items .old {
  text-decoration: line-through
}

.service-mini__items .old-wrapper {
  display: none;
  font-size: 14px;
  margin-right: 10px
}

.service-mini__items .old-wrapper.old-wrapper_visible {
  display: inline !important
}

.service-mini__items .service-mini__items__first_row {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  min-height: 52px
}

.service-mini__items .service-mini__items__first_row>div:first-child {
  margin-bottom: 0;
  min-width: 230px
}

.service-mini__calc__tab {
  display: none
}

.service-mini__calc__tab.active {
  display: block
}

.service-mini__button {
  background-color: #f9fd98;
  color: #3c4143;
  display: block;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
  text-transform: uppercase;
  z-index: 1
}

.service-mini__button:hover {
  background-color: #f7fc6a
}

.service-mini__button .strong,
.service-mini__button strong {

  font-style: normal;
  font-weight: 500
}

.service-mini__doctor {
  bottom: 0;
  left: -110px;
  position: absolute !important;
  z-index: 0
}

.service-mini__doctor_common {
  left: -144px
}

.service-mini__price-note_mobile {
  display: none
}

.service-mini__addresses {
  position: relative;
  z-index: 1
}

.service-mini__items__crowns .service-mini__items__first>div:first-child {
  font-size: 20px
}

.service-mini__items__crowns .service-mini__items__first>div:last-child {
  color: #333638;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px
}

.service-mini_implantation .service-mini__list {
  margin-bottom: 35px
}

.service-mini_implantation .service-mini__price-note {
  color: #5f6877;

  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 35px
}

.service-mini_implantation .service-mini__price-note span {
  color: #a5a5a5;
  display: inline-block;
  font-size: 13px;
  line-height: 22px;
  margin-top: 5px
}

.service-mini_implantation .service-mini__doctor {
  border-radius: 0
}

.service-mini_implantation .service-mini__doctor_common {
  left: -270px
}

.service-mini_implantation .service-mini__items {
  margin-bottom: 15px
}

.service-mini_implantation .service-mini__button {
  margin-left: 0
}

.service-mini_implantation .service-mini__calc {
  padding-bottom: 50px
}

.service-mini_implantation .service-mini__items {
  position: relative;
  z-index: 2
}

.service-mini_implantation .service-mini__items>div {
  min-height: 110px
}

.service-mini_implantation .service-mini__items>div>div:first-child {
  margin-bottom: 12px
}

.service-mini_implantation .service-mini__items>div>div:first-child span {
  color: #01938b;
  display: block;

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 3px
}

.service-mini_implantation .service-mini__items>div>div:last-child {
  color: #fff;
  display: block;
  line-height: 26px
}

.service-mini_implantation .service-mini__items>div>div:last-child .service-mini__item_tooltip {
  color: #fff;
  font-size: 28px
}

.service-mini_implantation .service-mini__items>div>div:last-child .service-mini__item_tooltip .new {
  font-size: 28px
}

.service-mini_implantation .service-mini__items .old-wrapper {
  color: #01938b;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-decoration: line-through
}

.service-mini_implantation .service-mini__items .old-wrapper .old {
  text-decoration: none
}

.service-mini_implantation .service-mini__item_tooltip {
  cursor: pointer;
  display: inline-block;
  padding-right: 31px;
  position: relative
}

.service-mini_implantation .service-mini__item_tooltip:after {
  background-image: url(/build/i/a2772a1ee88648391376.svg);
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 22px;
  margin-top: -12px;
  position: absolute;
  right: 0;
  top: 50%;
  width: 22px
}

@media screen and (max-width:1312px) {
  .service-mini__list {
    padding-right: 60px
  }

  .service-mini_implantation .service-mini__doctor_common {
    left: -190px;
    max-width: 300px
  }
}

@media screen and (max-width:1130px) {
  .service-mini__items__crowns {
    padding-left: 20px
  }

  .service-mini__calc__selects_with-quantity>div:first-child {
    flex-basis: 250px
  }

  .service-mini__calc__selects_with-quantity>div:last-child {
    flex-basis: 150px
  }

  .service-mini__text .h1,
  .service-mini__text .h1_supplement {
    font-size: 32px;
    line-height: 38px
  }

  .service-mini__list {
    font-size: 15px
  }

  .service-mini__price-note {
    font-size: 14px
  }

  .service-mini__calc__title {
    font-size: 24px
  }

  .service-mini__calc {
    padding-left: 39px
  }

  .service-mini__calc__checkboxes label {
    font-size: 13px !important
  }

  .service-mini__items>div>div:first-child {
    font-size: 13px
  }

  .service-mini__doctor {
    left: -145px
  }

  .service-mini__button {
    padding-left: 20px;
    padding-right: 20px
  }

  .service-mini__list {
    padding-right: 95px
  }
}

@media screen and (max-width:1023px) {
  .service-mini__text {
    z-index: 1 !important
  }

  .service-mini__items__crowns {
    padding-left: 0
  }

  .service-mini__calc__selects_with-quantity {
    justify-content: flex-start
  }

  .service-mini__calc__selects_with-quantity>div:first-child {
    flex-basis: 250px;
    margin-right: 20px
  }

  .service-mini__calc__selects_with-quantity>div:last-child {
    flex-basis: 150px
  }

  .service-mini__calc__checkboxes {
    max-width: 500px
  }

  .service-mini_common .service-mini__calc {
    padding-bottom: 40px
  }

  .service-mini .scrolls {
    display: block;
    margin-left: -26px;
    margin-right: -26px
  }

  .service-mini:after {
    content: none
  }

  .service-mini>div {
    flex-basis: 100%
  }

  .service-mini__price-note {
    display: none
  }

  .service-mini__price-note_mobile {
    background-color: #fff;
    display: block;
    margin-left: -26px;
    margin-right: -26px;
    padding-bottom: 25px;
    padding-top: 25px;
    text-align: center
  }

  .service-mini__calc {
    margin-left: -26px;
    margin-right: -26px;
    margin-top: 75px;
    padding-left: 26px;
    padding-right: 26px;
    padding-top: 33px
  }

  .service-mini__doctor {
    bottom: inherit !important;
    bottom: auto !important;
    height: 124px !important;
    left: 50%;
    margin-left: -58px !important;
    top: -124px;
    width: 148px !important
  }

  .service-mini__divider {
    width: 100%
  }

  .service-mini_implantation .service-mini__price-note_mobile {
    background-color: transparent;
    margin-bottom: 0;
    position: relative;
    text-align: left
  }

  .service-mini_implantation .service-mini__doctor_mobile {
    border-radius: 0;
    bottom: 0;
    position: absolute;
    right: 15px
  }

  .service-mini_implantation .service-mini__calc,
  .service-mini_implantation .service-mini__text .scrolls {
    margin-top: 0
  }
}

@media screen and (max-width:767px) {
  .service-mini>div {
    width: 100%
  }

  .service-mini__list {
    font-size: 14px;
    line-height: 20px;
    margin-top: 5px;
    padding-right: 0;
    text-align: left
  }

  .service-mini__text {
    padding-top: 0;
    text-align: center
  }

  .service-mini__text .h1,
  .service-mini__text .h1_supplement {
    font-size: 26px;
    line-height: 32px;
    margin-left: auto;
    margin-right: auto
  }

  .service-mini__text .button,
  .service-mini__text .slide-tab__label.active,
  .service-mini__text .tabs__labels.tabs__labels_buttons .tab__label.active,
  .tabs__labels.tabs__labels_buttons .service-mini__text .tab__label.active {
    font-size: 12px
  }

  .service-mini__price-note_mobile br {
    display: none
  }

  .service-mini__calc__title {
    text-align: center
  }

  .service-mini__calc__checkboxes:nth-child(2) {
    margin-top: 0
  }

  .service-mini__calc__checkboxes>div {
    height: 45px
  }

  .service-mini__calc__checkboxes>div:first-child {
    float: none
  }

  .service-mini__calc__checkboxes>div:last-child {
    float: none;
    min-width: 100%
  }

  .service-mini__calc__checkboxes label {
    flex-basis: 100%;
    font-size: 12px !important;
    height: 20px;
    margin-bottom: 5px;
    padding-top: 7px;
    width: 100%
  }

  .service-mini__calc__checkboxes label br {
    display: none
  }

  .service-mini__calc__checkboxes label span {
    position: relative;
    top: 6px
  }

  .service-mini__calc__checkboxes_one-line {
    margin-top: 0
  }

  .service-mini__mobile-hide {
    display: none
  }

  .service-mini__items>div {
    flex-basis: 100%;
    text-align: center;
    width: 100%
  }

  .service-mini__items>div>div:first-child {
    font-size: 14px;
    margin-bottom: 0
  }

  .service-mini__items>div>div:first-child br {
    display: none
  }

  .service-mini__items__first_row {
    justify-content: center
  }

  .service-mini__items__first_row>div:first-child {
    width: 100%
  }

  .service-mini__items {
    margin-bottom: 33px
  }

  .service-mini__items__first {
    margin-bottom: 12px
  }

  .service-mini__divider {
    margin-top: 20px
  }

  .service-mini_implantation .service-mini__price-note_mobile {
    font-size: 13px;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 0;
    overflow: hidden
  }

  .service-mini_implantation .service-mini__price-note_mobile .row,
  .service-mini_implantation .service-mini__price-note_mobile .row_short {
    margin-left: 0;
    max-width: calc(100% - 85px)
  }

  .service-mini_implantation .service-mini__price-note_mobile span {
    display: block
  }

  .service-mini_implantation .service-mini__doctor_mobile {
    bottom: -25px;
    max-width: 156px;
    right: -20px
  }

  .service-mini_implantation .service-mini__list {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin: 10px -7px 22px
  }

  .service-mini_implantation .service-mini__list>li {
    flex: 0 1 33.33%;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 25px;
    text-align: center
  }

  .service-mini_implantation .service-mini__list>li strong {
    display: block
  }

  .service-mini_implantation .service-mini__list>li:before {
    background-position: 50%;
    height: 18px;
    left: 50%;
    top: 0;
    transform: translateX(-50%)
  }

  .service-mini_implantation .service-mini__calc__checkboxes .checkbox+label {
    height: 16px
  }

  .service-mini_implantation .service-mini__calc {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 40px
  }

  .service-mini_implantation .service-mini__calc>* {
    flex-basis: calc(100% + 30px)
  }

  .service-mini_implantation .service-mini__calc .service-mini__calc__title {
    order: 0
  }

  .service-mini_implantation .service-mini__calc .service-mini__calc__selects {
    max-width: 290px;
    order: 1;
    width: 100%
  }

  .service-mini_implantation .service-mini__calc .service-mini__calc__checkboxes {
    order: 3
  }

  .service-mini_implantation .service-mini__calc .service-mini__calc__checkboxes_one-line {
    order: 4
  }

  .service-mini_implantation .service-mini__calc .service-mini__calc__checkboxes_second {
    margin-top: 0;
    order: 4
  }

  .service-mini_implantation .service-mini__calc .service-mini__items {
    align-items: flex-start;
    display: flex;
    margin: 10px -7px 0;
    order: 2
  }

  .service-mini_implantation .service-mini__calc .service-mini__items:after {
    background-color: #00ada4;
    bottom: 0;
    content: "";
    height: 1px;
    left: -8px;
    position: absolute;
    width: calc(100% + 30px)
  }

  .service-mini_implantation .service-mini__calc .service-mini__items>div {
    flex: 0 1 50%;
    min-height: 115px;
    padding-left: 7px;
    padding-right: 7px;
    text-align: left
  }

  .service-mini_implantation .service-mini__calc .service-mini__items>div>div:first-child {
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 8px
  }

  .service-mini_implantation .service-mini__calc .service-mini__items>div>div:last-child .service-mini__item_tooltip,
  .service-mini_implantation .service-mini__calc .service-mini__items>div>div:last-child .service-mini__item_tooltip .new {
    font-size: 20px
  }

  .service-mini_implantation .service-mini__calc .service-mini__items .old-wrapper {
    font-size: 10px
  }

  .service-mini_implantation .service-mini__calc .service-mini__button {
    height: 50px;
    margin-top: 15px;
    max-width: 290px;
    order: 5;
    width: 100%
  }

  .service-mini_implantation .service-mini__calc .service-mini__divider {
    display: none
  }
}

@media screen and (max-width:665px) {
  .service-mini__play {
    display: none
  }
}

@media screen and (max-width:465px) {
  .service-mini__calc__selects_with-quantity {
    flex-wrap: wrap
  }

  .service-mini__calc__selects_with-quantity>div:first-child,
  .service-mini__calc__selects_with-quantity>div:last-child {
    flex-basis: 100%;
    margin-right: 0
  }
}

.what-is-included__title {
  color: #333638;

  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 38px;
  text-align: center
}

.what-is-included__sub-title {
  margin-bottom: 40px
}

.what-is-included__grid {
  display: flex;
  margin-bottom: 25px;
  margin-left: -15px;
  margin-right: -15px
}

.what-is-included__grid>* {
  background-position: 0 0;
  background-size: 105px 100px;
  flex: 0 1 25%;
  margin-left: 15px;
  margin-right: 15px;
  padding-bottom: 75px;
  padding-top: 140px;
  position: relative
}

.what-is-included__grid>:first-child {
  background-image: url(/build/i/e8aa62c19c2b5d193ac1.png)
}

.what-is-included__grid>:nth-child(2) {
  background-image: url(/build/i/b45e730a15a544f06db9.png)
}

.what-is-included__grid>:nth-child(3) {
  background-image: url(/build/i/5eb6d35afc0a7e0eecc9.png)
}

.what-is-included__grid>:nth-child(4) {
  background-image: url(/build/i/8b643960ae66d5e90ff3.png)
}

.what-is-included__grid__title {
  color: #5f6877;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
  text-transform: uppercase
}

.what-is-included__grid__text {
  color: #5f6877;
  font-size: 14px;
  line-height: 22px
}

.what-is-included__grid__price {
  align-items: center;
  border-bottom: 1px solid #eee2e2;
  border-top: 1px solid #eee2e2;
  bottom: 0;
  color: #3c4143;
  display: flex;
  font-size: 16px;
  height: 60px;
  line-height: 29px;
  position: absolute;
  width: 100%
}

.what-is-included__grid__price [data-js=tooltip] {
  position: relative;
  top: 4px
}

.what-is-included__grid__price__old {
  color: #a5a5a5;
  font-size: 13px;
  line-height: 22px;
  margin-right: 10px;
  text-decoration: line-through
}

.what-is-included__grid__price__new {
  color: #3c4143;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px
}

.what-is-included__additional {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 30px;
  text-align: center
}

.what-is-included__notice {
  align-items: center;
  background-color: #e8f0f3;
  border-radius: 10px;
  display: flex;
  min-height: 92px;
  padding-bottom: 25px;
  padding-left: 115px;
  padding-top: 25px;
  position: relative;
  width: 100%
}

.what-is-included__icon-alert {
  background-image: url(/build/i/0d566945ced24696fc15.svg);
  display: block;
  height: 30px;
  left: 60px;
  margin-top: -15px;
  position: absolute;
  top: 50%;
  width: 30px
}

.what-is-included__notice__title {
  color: #5f6877;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px
}

.what-is-included__notice__text {
  color: #5f6877;
  font-size: 16px;
  line-height: 28px
}

.what-is-included__price-button {
  display: block;
  margin-top: 47px
}

.what-is-included__button {
  margin-bottom: 70px
}

.what-is-included__mobile {
  display: none
}

@media screen and (max-width:1279px) {
  .what-is-included__grid {
    flex-wrap: wrap;
    margin-bottom: 0
  }

  .what-is-included__grid>* {
    flex: 0 1 calc(50% - 30px);
    margin-bottom: 70px
  }

  .what-is-included__additional br {
    display: none
  }

  .what-is-included__notice {
    border-radius: 0;
    margin-left: -70px;
    margin-right: -70px;
    padding-right: 70px;
    width: auto
  }
}

@media screen and (max-width:767px) {
  .what-is-included__notice {
    padding-left: 138px
  }

  .what-is-included__icon-alert {
    left: 78px
  }

  .service-mini__calc__checkboxes_one-line>div:last-child,
  .what-is-included__grid {
    display: none
  }

  .what-is-included__button {
    margin-bottom: 40px
  }

  .what-is-included__mobile {
    display: block;
    margin-bottom: 10px
  }

  .what-is-included__mobile .tabs__labels {
    align-items: flex-end;
    border-bottom: 1px solid #e3e9e9 !important;
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px
  }

  .what-is-included__mobile .what-is-included__tab {
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    height: 50px;
    opacity: .52;
    width: 52px
  }

  .what-is-included__mobile .what-is-included__tab.active {
    opacity: 1;
    transform: scale(1.35);
    transform-origin: bottom
  }

  .what-is-included__mobile .what-is-included__tab.active:after {
    background-color: #fff;
    background-image: url(/build/i/44cce92f1670fd91ff8e.svg);
    background-repeat: no-repeat;
    bottom: -16px;
    content: "";
    height: 10px;
    left: 50%;
    margin-left: -9px;
    position: absolute;
    width: 18px
  }

  .what-is-included__mobile .what-is-included__tab_first {
    background-image: url(/build/i/e8aa62c19c2b5d193ac1.png)
  }

  .what-is-included__mobile .what-is-included__tab_first.active {
    transform-origin: bottom left
  }

  .what-is-included__mobile .what-is-included__tab_second {
    background-image: url(/build/i/b45e730a15a544f06db9.png)
  }

  .what-is-included__mobile .what-is-included__tab_third {
    background-image: url(/build/i/5eb6d35afc0a7e0eecc9.png)
  }

  .what-is-included__mobile .what-is-included__tab_fourth {
    background-image: url(/build/i/8b643960ae66d5e90ff3.png)
  }

  .what-is-included__mobile .what-is-included__tab_fourth.active {
    transform-origin: bottom right
  }

  .what-is-included__mobile .tabs__contents {
    margin-top: 25px
  }

  .what-is-included__mobile .what-is-included__grid__text {
    margin-bottom: 20px
  }

  .what-is-included__mobile .what-is-included__grid__price {
    border-bottom: 0;
    justify-content: flex-start;
    position: relative
  }

  .what-is-included__mobile .what-is-included__price__note {
    color: #5f6877;
    font-size: 13px;
    line-height: 18px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%)
  }

  .what-is-included__additional,
  .what-is-included__price-button {
    display: none
  }
}

@media screen and (max-width:550px) {
  .what-is-included__sub-title {
    font-size: 14px;
    line-height: 24px
  }

  .what-is-included__grid>* {
    background-position: top;
    background-size: 83px 80px;
    flex: 0 1 calc(100% - 30px);
    margin-bottom: 40px;
    padding-top: 102px;
    text-align: center
  }

  .what-is-included__title {
    font-size: 26px
  }

  .what-is-included__button {
    border-radius: 0;
    font-size: 12px;
    line-height: 16px;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 50px;
    text-align: left;
    width: 100vw
  }

  .what-is-included__button .button_with-plus__icon-plus {
    left: 20px
  }

  .what-is-included__grid__price {
    justify-content: center
  }

  .what-is-included__icon-alert {
    left: 50%;
    margin-left: -15px;
    top: 38px
  }

  .what-is-included__notice {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 68px;
    text-align: center
  }

  .what-is-included__notice__title {
    font-size: 13px;
    line-height: 20px
  }

  .what-is-included__notice__text {
    font-size: 13px;
    line-height: 20px;
    margin-top: 5px
  }
}

.compare-prices-wrapper_ceramic .h2-sub {
  max-width: 1100px
}

.compare-prices-wrapper_ceramic .what-is-included-points__blocks {
  margin-bottom: 40px
}

.compare-prices {
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  margin-left: -24px;
  margin-right: -24px;
  margin-top: 42px
}

.compare-prices .slick-list {
  width: 100%
}

.compare-prices_prosthetics .compare-prices__block__title__name {
  padding-left: 30px;
  padding-right: 30px
}

.compare-prices_prosthetics .compare-prices__block__title {
  align-items: center;
  display: flex;
  min-height: 101px;
  padding-bottom: 20px
}

.compare-prices_prosthetics .compare-prices__block {
  margin-bottom: 38px
}

.compare-prices_prosthetics_center {
  justify-content: center
}

.compare-prices__block {
  border-radius: 10px;
  box-shadow: 0 7px 21px 3px rgba(70, 81, 86, .1);
  flex: 0 1 calc(33.33% - 48px);
  margin-bottom: 6px;
  margin-left: 24px;
  margin-right: 24px;
  padding-bottom: 5px;
  position: relative
}

.compare-prices__block_crowns .compare-prices__block__title {
  padding-bottom: 27px;
  padding-top: 27px
}

.compare-prices__block__bubble {
  background-color: #e58c00;
  border-radius: 12px;
  color: #fff;
  font-size: 13px;
  height: 24px;
  left: 50%;
  line-height: 23px;
  min-width: 82px;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  text-align: center;
  top: -12px;
  transform: translateX(-50%);
  white-space: nowrap
}

.compare-prices__block__bubble_grey {
  background-color: #eff3f6;
  color: #3c4143
}

.compare-prices__show-tables {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 28px;
  margin-top: 58px;
  text-transform: uppercase
}

.compare-prices__notice,
.compare-prices__show-tables {

  font-style: normal;
  font-weight: 500;
  text-align: center
}

.compare-prices__notice {
  color: #5f6877;
  font-size: 18px;
  line-height: 28px;
  margin-top: 35px
}

.compare-prices__notice_cost-of {
  margin-top: 55px
}

.compare-prices_hidden {
  display: none
}

.compare-prices__block__title {
  padding-bottom: 11px;
  padding-top: 25px;
  text-align: center
}

.compare-prices__block__title__name {
  color: #3c4143;
  display: inline-block;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  position: relative
}

.compare-prices__block__title__with-flag .compare-prices__block__title__name {
  padding-left: 42px
}

.compare-prices__block__title__flag {
  background-image: url(/build/i/17346ebd0dd807c943e9.png);
  background-size: 180px 22px;
  display: block;
  height: 22px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 30px
}

.compare-prices__block__title__flag_israel {
  background-position: 0
}

.compare-prices__block__title__flag_south_korea {
  background-position: -30px
}

.compare-prices__block__title__flag_switzerland {
  background-position: -60px
}

.compare-prices__block__title__flag_usa {
  background-position: -90px
}

.compare-prices__block__title__flag_sweden {
  background-position: -120px
}

.compare-prices__block__title__flag_brazil {
  background-position: -150px
}

.compare-prices__block__title__country {
  color: #a5a5a5;
  font-size: 13px;
  line-height: 18px
}

.compare-prices__block__divider {
  align-items: center;
  background-color: #00beb4;
  color: #fff;
  display: flex;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  justify-content: center;
  line-height: 21px;
  min-height: 40px;
  text-transform: uppercase
}

.compare-prices__block__divider_small {
  font-size: 16px;
  line-height: 22px;
  text-transform: none
}

.compare-prices__block__table {
  color: #a5a5a5;
  font-size: 13px;
  line-height: 16px;
  padding-left: 16px;
  padding-right: 16px
}

.compare-prices__block__table>div {
  align-items: center;
  border-bottom: 1px solid #d8dfe1;
  display: flex;
  padding: 15px 18px 16px
}

.compare-prices__block__table>div:last-child {
  align-items: start;
  border-bottom: 1px solid transparent
}

.compare-prices__block__table>div:last-child .new {

  font-style: normal;
  font-weight: 500
}

.compare-prices__block__table>div>span:first-child {
  flex: 0 1 68%
}

.compare-prices__block__table>div>span:last-child {
  flex: 0 1 32%
}

.compare-prices__block__table>div.without-border {
  border-bottom: 0
}

.compare-prices__block__table>div.compare-price__note {
  border-bottom: 0;
  display: block;
  margin-bottom: 6px;
  margin-left: 4px;
  margin-right: 4px;
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: center
}

.compare-prices__block__table>div.compare-prices__block__accordion {
  display: block
}

.compare-prices__best-label {
  background-color: #fcfb9b;
  border-radius: 8px;
  color: #3c4143;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: 44px;
  justify-content: center;
  margin-top: 18px
}

.compare-prices__best-label_arrow:before {
  background-image: url(/build/i/c1efc79d47a430d1eab8.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  content: "";
  display: inline-block;
  height: 19px;
  margin-bottom: 2px;
  margin-right: 5px;
  width: 19px
}

.compare-prices__block__full {
  flex-basis: 100% !important
}

.compare-prices__block__text {
  color: #5f6877;
  font-size: 15px;
  line-height: 24px;
  text-align: center
}

.compare-prices__block__right {
  color: #5f6877;
  font-size: 13px;
  position: relative;
  text-align: right
}

.compare-prices__block__right.text-red {
  color: #d6322e
}

.compare-prices__block__right.text-grey {
  color: #5f6877
}

.compare-prices__block__right.text-green {
  color: #61a10f
}

.compare-prices__block__right .old {
  color: #a5a5a5;
  font-size: 13px;
  line-height: 22px;
  text-decoration: line-through
}

.compare-prices__block__right .new {
  color: #3c4143;
  font-size: 16px;
  line-height: 22px
}

.compare-prices__block__right .from {
  color: #a5a5a5;
  font-size: 13px
}

.compare-prices__block__right .service-prices-table__minus,
.compare-prices__block__right .service-prices-table__plus {
  position: relative;
  top: 3px
}

.compare-prices__block__right [data-js=tooltip] {
  position: absolute;
  right: 0;
  top: 12px
}

.compare-prices__block__right__step-text {
  color: #a5a5a5;
  display: inline-block;
  font-size: 13px;
  margin-bottom: 8px
}

.compare-prices__block__right_with-i {
  padding-right: 30px
}

.compare-prices__block__offer {
  background-color: #fcfb9b;
  border-radius: 15px;
  color: #3c4143;
  display: block;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 22px;
  margin-top: 6px;
  min-height: 30px;
  padding: 5px 20px
}

.compare-prices__block__offer:hover {
  background-color: #f7fc6a
}

.compare-prices__block__list-title {
  display: inline-block;
  font-size: 14px;
  margin-bottom: 6px
}

.compare-prices__total-info {
  padding-left: 16px;
  padding-top: 5px;
  position: relative;
  text-align: left
}

.compare-prices__total-info:before {
  background-color: #d9dfe1;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  right: 0;
  top: 0;
  width: 98px
}

.compare-prices__total-info__title {
  color: #a5a5a5;

  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  position: relative
}

.compare-prices__total-info__title [data-js=tooltip] {
  line-height: inherit;
  right: -43px;
  top: 7px
}

.compare-prices__total-info__tooltip {
  background-image: url(/build/i/a799d327dceeaf944bbd.svg);
  display: inline-block;
  height: 22px;
  position: relative;
  top: -2px;
  transition: background .2s ease-out;
  width: 18px
}

.compare-prices__total-info__tooltip:hover {
  background-image: url(/build/i/f281dafa07660268752b.svg);
  transition: background .2s ease-in
}

.compare-price__note {
  align-items: center;
  background-color: #e58c00;
  border-radius: 15px;
  color: #fff;
  display: block;
  font-size: 13px;
  line-height: 17px
}

.compare-price__note>div {

  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 4px
}

.compare-price__note>div.accent {

  font-size: 16px;
  font-style: normal;
  font-weight: 700
}

.compare-price__text {
  color: #3c4143;
  font-size: 15px;
  line-height: 24px
}

.compare-prices__block__cut-price {
  background-color: #e38a00;
  border-radius: 19px;
  color: #fff;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: 38px;
  line-height: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  padding-left: 14px;
  padding-right: 14px
}

.compare-price__extraction {
  align-items: center;
  background-color: #d8dfe1;
  border-radius: 8px;
  justify-content: space-between;
  margin-left: 18px;
  margin-right: 18px;
  padding: 18px
}

.compare-price__extraction>* {
  line-height: 20px
}

.compare-price__extraction>:first-child {
  color: #3c4143;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px
}

.compare-price__extraction>:last-child {
  color: #a5a5a5;
  font-size: 13px;
  line-height: 20px
}

.compare-price__extraction>* strong {
  color: #3c4143;

  font-size: 16px;
  font-style: normal;
  font-weight: 500
}

.compare-prices__installment {
  align-items: center !important;
  background-color: #e58c00;
  border-radius: 8px;
  margin-bottom: 20px;
  margin-top: 20px
}

.compare-prices__installment>:first-child {
  color: #fff;
  flex: 1 0 50% !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px
}

.compare-prices__installment>:last-child {
  flex: 1 0 50% !important
}

.compare-prices__installment>* .new {
  color: #fff;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px
}

.compare-prices__installment>* .new .month {

  font-size: .8em;
  font-style: normal;
  font-weight: 400
}

.compare-prices__block__implants {
  align-items: center !important
}

.compare-prices__block-installment .compare-prices__block__list-title {
  color: #3c4143
}

.compare-prices__block__accordion .accordion__tab__title:not(.accordion__tab__title_no-style) {
  border: 0;
  color: #a5a5a5;
  cursor: pointer;
  padding: 0
}

.compare-prices__block__accordion .accordion__tab__title:not(.accordion__tab__title_no-style):after {
  background-image: url(/build/i/af7f5fc8428807b17015.svg);
  right: 0
}

.compare-prices__block__accordion .accordion__tab__title:not(.accordion__tab__title_no-style) a {
  display: inline-block;
  margin-bottom: 3px
}

.compare-prices__block__accordion .accordion__tab__title:not(.accordion__tab__title_no-style).active a {
  color: #3c4143
}

.compare-prices__block__accordion .accordion__tab__title:not(.accordion__tab__title_no-style).active a>span {
  border-bottom: 1px dashed transparent
}

.compare-prices__block__accordion .accordion__tab__content {
  align-items: center;
  border: 0;
  margin-top: 5px;
  padding: 0
}

.compare-prices__block__accordion .accordion__tab__content.active {
  display: flex
}

.compare-prices__block__accordion .accordion__tab__content>span:first-child {
  flex: 0 1 68%
}

.compare-prices__block__accordion .accordion__tab__content>span:nth-child(2) {
  flex: 0 1 32%
}

.page_251 .price-table__offer_zygoma {
  margin-top: 0
}

.page_251 .price-table__offer_zygoma .pusher {
  display: none
}

@media screen and (max-width:1317px) {
  .compare-prices_prosthetics .compare-prices__block__title {
    min-height: 75px;
    padding-bottom: 10px;
    padding-top: 10px
  }

  .compare-prices_prosthetics .compare-prices__block__title__name {
    font-size: 15px;
    line-height: 22px
  }
}

@media screen and (max-width:1279px) {
  .compare-prices {
    margin-left: -12px;
    margin-right: -12px
  }

  .compare-prices__block {
    flex: 0 1 calc(33.33% - 24px);
    margin-left: 12px;
    margin-right: 12px
  }

  .compare-prices__block__divider {
    font-size: 13px
  }

  .compare-prices__block__title__name {
    font-size: 18px
  }

  .compare-prices__block__table {
    padding-left: 0;
    padding-right: 0
  }

  .compare-prices__block__right .new {
    font-size: 14px
  }

  .compare-prices__installment {
    flex-direction: column;
    margin-left: 18px;
    margin-right: 18px
  }

  .compare-prices__installment br {
    display: none
  }

  .compare-prices__installment>:first-child {
    margin-bottom: 5px;
    text-align: center
  }

  .compare-prices__installment>:last-child {
    text-align: center
  }

  .compare-prices__installment .new {
    color: #fff;

    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px
  }

  .compare-prices__best-label_arrow:before,
  .price-table__offer_zygoma+.pusher {
    display: none
  }
}

@media screen and (max-width:1118px) {
  .compare-prices_prosthetics>* {
    flex-basis: calc(50% - 24px)
  }

  .compare-prices__block__divider {
    font-size: 10px
  }

  .compare-prices__block__table {
    text-align: center
  }

  .compare-prices__block__table>div {
    display: block
  }

  .compare-prices__block__table>div:last-child {
    text-align: left
  }

  .compare-prices__block__table>div>span {
    display: block
  }

  .compare-prices__block__right {
    margin-top: 10px;
    text-align: center
  }

  .compare-prices__block__right .old {
    margin-right: 10px
  }

  .compare-prices__block__right br {
    display: none
  }

  .compare-prices__block__tablet-hidden {
    display: none !important
  }

  .compare-prices__block__right [data-js=tooltip] {
    right: 10px;
    top: -2px
  }

  .compare-prices__total-info {
    display: flex;
    justify-content: center;
    margin-top: 6px;
    padding-left: 0;
    padding-top: 10px
  }

  .compare-prices__total-info:before {
    right: auto;
    width: 70%
  }

  .compare-prices__total-info__title {
    margin-left: -27px
  }

  .compare-prices__total-info__title [data-js=tooltip] {
    right: -27px
  }

  .compare-price__text {
    font-size: 13px;
    line-height: 16px
  }

  .compare-price__extraction {
    border-radius: 0;
    flex-direction: column;
    margin-left: 0;
    margin-right: 0
  }

  .compare-price__extraction>:first-child,
  .compare-price__extraction>:last-child {
    font-size: 13px;
    line-height: 18px;
    text-align: center
  }

  .compare-price__extraction>* strong {
    display: block;
    font-size: 14px;
    line-height: 20px
  }

  .compare-price__extraction>* br {
    display: none
  }

  .compare-prices__block__implants>:first-child {
    text-align: center
  }
}

@media screen and (max-width:910px) {
  .compare-prices__notice_cost-of {
    margin-bottom: 25px
  }

  .compare-prices__block__cut-price {
    font-size: 15px
  }
}

@media screen and (max-width:767px) {
  .compare-prices {
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px
  }

  .compare-prices__block {
    flex: 0 1 calc(100% - 30px);
    margin-bottom: 35px;
    margin-left: 15px;
    margin-right: 15px
  }

  .compare-prices__block:last-child {
    margin-bottom: 0
  }

  .compare-prices__block__table {
    text-align: left
  }

  .compare-prices__block__table>div {
    display: flex
  }

  .compare-prices__block__table>div>span {
    display: inline
  }

  .compare-prices__block__right {
    margin-top: 0;
    text-align: right
  }

  .compare-prices__block__right .old {
    margin-right: 0
  }

  .compare-prices__block__right br {
    display: block
  }

  .compare-prices_hidden {
    margin-top: -7px
  }

  .compare-prices__notice {
    font-size: 14px;
    line-height: 22px
  }

  .compare-prices__block__right [data-js=tooltip] {
    right: 0;
    top: 12px
  }

  .compare-prices__total-info {
    margin-left: auto;
    margin-top: 0;
    max-width: 98px;
    padding-top: 5px
  }

  .compare-prices__total-info:before {
    width: 100%
  }

  .compare-prices__total-info__title [data-js=tooltip] {
    right: -30px;
    top: 7px
  }

  .compare-prices__block__cut-price {
    font-size: 16px
  }

  .compare-price__extraction {
    flex-direction: row
  }

  .compare-price__extraction>:first-child {
    text-align: left
  }

  .compare-price__extraction>:last-child {
    text-align: right
  }

  .compare-prices__block__implants>:first-child {
    text-align: left
  }
}

@media screen and (max-width:370px) {
  .compare-prices__total-info {
    display: flex;
    justify-content: center;
    margin-left: 0;
    margin-top: 6px;
    max-width: 100%;
    padding-left: 0;
    padding-top: 10px
  }

  .compare-prices__total-info:before {
    right: auto;
    width: 70%
  }

  .compare-prices__total-info__title {
    margin-left: -27px
  }

  .compare-prices__total-info__title [data-js=tooltip] {
    right: -27px
  }

  .compare-price__extraction {
    flex-direction: column
  }

  .compare-price__extraction>:first-child,
  .compare-price__extraction>:last-child {
    text-align: center
  }

  .compare-prices__installment {
    border-radius: 0;
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0
  }

  .compare-prices__installment>:first-child {
    margin-bottom: 5px;
    text-align: center
  }

  .compare-prices__block__implants>:first-child,
  .compare-prices__installment>:last-child {
    text-align: center
  }
}

.what-is-included-points__blocks {
  flex-wrap: nowrap;
  margin-left: -11px;
  margin-right: -11px;
  margin-top: 57px
}

.what-is-included-points__blocks>* {
  border: 10px solid #e9ecee;
  border-radius: 10px;
  flex-basis: calc(50% - 22px);
  margin-left: 11px;
  margin-right: 11px;
  padding: 26px;
  position: relative
}

.what-is-included-points__blocks__text {
  color: #333638;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  margin-left: 200px
}

.what-is-included-points__blocks__image {
  border-radius: 0;
  bottom: -10px;
  left: 26px;
  position: absolute !important
}

.what-is-included-points__grid {
  justify-content: space-between;
  margin-top: 57px
}

.what-is-included-points__grid>* {
  counter-increment: what-is-included-points-grid;
  margin-bottom: 49px;
  padding-left: 48px;
  position: relative
}

.what-is-included-points__grid>:before {
  color: #00beb4;
  content: counter(what-is-included-points-grid);

  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  left: 0;
  line-height: 28px;
  position: absolute;
  text-transform: uppercase
}

.what-is-included-points__grid_third>* {
  flex: 1 0 33.33% !important
}

.what-is-included-points__grid_third .what-is-included-points__grid__text {
  max-width: 330px
}

.what-is-included-points__grid__title {

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-transform: uppercase
}

.what-is-included-points__grid__text {
  font-size: 14px;
  line-height: 22px;
  max-width: 193px
}

.what-is-included-points__info {
  font-size: 14px;
  line-height: 22px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 53px;
  max-width: 933px
}

.what-is-included-points__info>:first-child {
  flex-basis: 182px;

  font-style: normal;
  font-weight: 500
}

.what-is-included-points__info>:last-child {
  flex-basis: 725px;
  padding-left: 12px
}

@media screen and (max-width:1279px) {
  .what-is-included-points__grid_third .what-is-included-points__grid__text {
    max-width: 280px
  }
}

@media screen and (max-width:1250px) {
  .what-is-included-points__blocks__image {
    height: 110px !important;
    left: 15px;
    width: 120px !important
  }

  .what-is-included-points__blocks__text {
    font-size: 18px;
    line-height: 27px;
    margin-left: 125px
  }

  .what-is-included-points__grid {
    justify-content: flex-start
  }

  .what-is-included-points__grid>* {
    flex: 0 0 33.33%
  }

  .what-is-included-points__grid>div:nth-child(6) .what-is-included-points__grid__text {
    max-width: 221px
  }
}

@media screen and (max-width:1170px) {
  .what-is-included-points__grid_third .what-is-included-points__grid__text {
    max-width: 200px !important
  }
}

@media screen and (max-width:1056px) {
  .what-is-included-points__info>* {
    flex-basis: 100% !important;
    padding-left: 0 !important
  }

  .what-is-included-points__info>* br {
    display: none
  }

  .what-is-included-points__blocks__text {
    font-size: 15px
  }
}

@media screen and (max-width:928px) {
  .what-is-included-points__blocks {
    flex-wrap: wrap;
    justify-content: space-around
  }

  .what-is-included-points__blocks>* {
    flex-basis: 480px;
    max-width: 480px
  }

  .what-is-included-points__blocks>:first-child {
    margin-bottom: 20px
  }

  .what-is-included-points__blocks__text {
    font-size: 18px
  }

  .what-is-included-points__grid>* {
    display: flex;
    flex: 0 0 50%;
    justify-content: center;
    padding-left: 0
  }

  .what-is-included-points__grid>:before {
    font-size: 80px;
    left: 80px;
    opacity: .2;
    top: 45px
  }

  .what-is-included-points__grid>div:nth-child(6) .what-is-included-points__grid__text,
  .what-is-included-points__grid__text {
    max-width: 250px
  }
}

@media screen and (max-width:800px) {
  .what-is-included-points__grid_third>* {
    flex: 1 0 50% !important
  }

  .what-is-included-points__grid_third .what-is-included-points__grid__text {
    max-width: 300px !important
  }
}

@media screen and (max-width:670px) {
  .what-is-included-points__grid_third .what-is-included-points__grid__text {
    max-width: 200px !important
  }
}

@media screen and (max-width:600px) {
  .what-is-included-points__grid>* {
    flex: 0 0 100%
  }
}

@media screen and (max-width:560px) {
  .what-is-included-points__grid_third {
    justify-content: space-around
  }

  .what-is-included-points__grid_third>* {
    flex: 1 0 100% !important
  }

  .what-is-included-points__grid_third .what-is-included-points__grid__text {
    max-width: 300px !important
  }
}

@media screen and (max-width:525px) {
  .what-is-included-points__blocks {
    margin-left: -15px;
    margin-right: -15px
  }

  .what-is-included-points__blocks>* {
    border-left: none;
    border-radius: 0;
    border-right: none;
    flex-basis: 100%;
    margin-left: 0;
    margin-right: 0;
    max-width: 100%
  }
}

@media screen and (max-width:460px) {
  .what-is-included-points__blocks {
    margin-top: 35px
  }

  .what-is-included-points__blocks>* {
    height: 225px;
    padding-left: 15px;
    padding-right: 15px
  }

  .what-is-included-points__blocks__image {
    bottom: 0;
    left: 50%;
    margin-left: -60px !important
  }

  .what-is-included-points__blocks__text {
    font-size: 16px;
    line-height: 25px;
    margin-left: 0;
    text-align: center
  }

  .what-is-included-points__blocks__text br {
    display: none
  }
}

.dental-technician-ribbon {
  background-color: #00beb4;
  display: block;
  height: 126px;
  width: 100%
}

.dental-technician-ribbon:hover {
  background-color: #00b1a8
}

.dental-technician-ribbon__grid {
  align-items: center;
  background-image: url(/build/i/d91082e9fa9c01a9e3f2.png);
  background-position: center 3px;
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between
}

.dental-technician-ribbon__left {
  color: #fff;
  flex-basis: 480px;

  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px
}

.dental-technician-ribbon__right {
  color: #fff;
  flex-basis: 439px;
  font-size: 16px;
  line-height: 28px
}

@media screen and (max-width:1220px) {
  .dental-technician-ribbon__left {
    flex-basis: 375px;
    font-size: 20px
  }

  .dental-technician-ribbon__right {
    font-size: 14px;
    line-height: 26px;
    text-align: right
  }
}

@media screen and (max-width:960px) {
  .dental-technician-ribbon {
    height: auto;
    padding-bottom: 20px;
    padding-top: 20px
  }

  .dental-technician-ribbon__grid {
    background-position: top;
    display: block;
    padding-top: 125px
  }

  .dental-technician-ribbon__grid>* {
    margin-left: auto;
    margin-right: auto;
    text-align: center
  }

  .dental-technician-ribbon__left {
    margin-bottom: 10px;
    max-width: 442px
  }

  .dental-technician-ribbon__right {
    max-width: 500px
  }
}

@media screen and (max-width:767px) {
  .dental-technician-ribbon__left {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 15px
  }
}

.answers-videos {
  background-color: #f4f7f8;
  padding-bottom: 72px;
  padding-top: 93px
}

.answers-videos+.team .team__slider {
  margin-top: 0
}

.answers-videos__grid {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 50px
}

.answers-videos__grid>* {
  flex-basis: calc(50% - 30px);
  margin-left: 15px;
  margin-right: 15px
}

.answers-videos__grid__video {
  max-height: 430px;
  max-width: 562px;
  position: relative
}

.answers-videos__grid__video:after {
  background-image: linear-gradient(0deg, #000004, transparent);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  bottom: 0;
  content: "";
  display: block;
  height: 154px;
  opacity: .5;
  position: absolute;
  width: 100%
}

.answers-videos__grid__video_with-preview:after {
  content: none
}

.answers-videos__grid__video_with-preview .answers-videos__grid__play {
  display: none
}

.answers-videos__grid__video-wrapper {
  border-radius: 10px;
  display: block;
  overflow: hidden;
  position: relative
}

.answers-videos__grid__video-wrapper:after {
  background-image: linear-gradient(0deg, #000004, transparent);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  bottom: 0;
  content: "";
  display: block;
  height: 154px;
  opacity: .5;
  position: absolute;
  width: 100%
}

.answers-videos__grid__video-wrapper video {
  max-width: 100%
}

.answers-videos__grid__video-small {
  height: 64px;
  left: 40px;
  position: absolute
}

.answers-videos__grid__grid__title {
  color: #333638;
  font-size: 14px;
  line-height: 20px;
  transition: color .2s
}

.answers-videos__grid__video__doctor {
  bottom: 100px;
  color: #fdfeff;
  font-size: 14px;
  left: 30px;
  position: absolute;
  z-index: 2
}

.answers-videos__grid__video__title {
  bottom: 25px;
  color: #fdfeff;

  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  left: 30px;
  padding-right: 30px;
  position: absolute;
  z-index: 2
}

.answers-videos__grid__play {
  display: inline-block;
  height: 80px;
  left: 50%;
  margin-left: -40px;
  margin-top: -40px;
  position: absolute;
  top: 50%;
  width: 80px;
  z-index: 2
}

.answers-videos__grid__play .circle-icon_big {
  align-items: center;
  display: flex;
  height: 80px;
  justify-content: center;
  width: 80px
}

.answers-videos__grid__play .circle-icon_big:before {
  background-size: 32px 32px;
  height: 32px;
  margin-left: 0;
  width: 32px
}

.answers-videos__grid__play-small {
  display: inline-block;
  height: 40px;
  left: 50%;
  margin-left: -20px;
  margin-top: -20px;
  position: absolute;
  top: 50%;
  width: 40px;
  z-index: 2
}

.answers-videos__grid__play-small .circle-icon {
  height: 40px;
  padding-left: 0;
  padding-right: 0;
  width: 40px
}

.answers-videos__grid__play-small .circle-icon:before {
  background-size: 20px 20px;
  height: 20px;
  left: 50%;
  margin-left: -10px;
  margin-top: -10px;
  top: 50%;
  width: 20px
}

.answers-videos__grid__play-small .button_orange {
  background-color: #db882f;
  background-image: none
}

.answers-videos__grid__grid {
  flex-basis: calc(50% - 26px);
  height: 100%;
  justify-content: flex-start;
  margin-left: -13px;
  margin-right: -13px
}

.answers-videos__grid__grid>a:not(.button):not(.slide-tab__label.active) {
  align-items: center;
  background-color: #fafeff;
  border-radius: 6px;
  box-shadow: 0 0 0 0 transparent;
  cursor: pointer;
  display: flex;
  flex: 0 0 calc(50% - 26px);
  flex-wrap: nowrap;
  margin-bottom: 28px;
  margin-left: 13px;
  margin-right: 13px;
  min-height: 64px;
  padding: 12px 20px 12px 80px;
  transition: box-shadow .2s
}

.answers-videos__grid__grid>a:not(.button):not(.slide-tab__label.active).active {
  display: flex !important
}

.answers-videos__grid__grid>a:not(.button):not(.slide-tab__label.active)>* {
  z-index: 1
}

.answers-videos__grid__grid>a:not(.button):not(.slide-tab__label.active):before {
  background-color: #db882f;
  border-radius: 6px;
  bottom: 0;
  box-shadow: 0 10px 36px 4px rgba(70, 81, 86, 0);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  transform: scaleX(0);
  transform-origin: left;
  transition: all .3s;
  width: 100%;
  z-index: 0
}

.answers-videos__grid__grid>a:not(.button):not(.slide-tab__label.active):hover {
  box-shadow: 0 10px 36px 4px rgba(70, 81, 86, .17)
}

.answers-videos__grid__grid>a:not(.button):not(.slide-tab__label.active):hover:before {
  transform: scaleX(1)
}

.answers-videos__grid__grid>a:not(.button):not(.slide-tab__label.active):hover .button_orange {
  box-shadow: none
}

.answers-videos__grid__grid>a:not(.button):not(.slide-tab__label.active):hover .answers-videos__grid__grid__title {
  color: #fff
}

.answers-videos__grid__grid>.button,
.answers-videos__grid__grid>.slide-tab__label.active,
.tabs__labels.tabs__labels_buttons .answers-videos__grid__grid>.tab__label.active {
  border-radius: 64px;
  flex: 0 0 calc(50% - 26px);
  height: 64px;
  line-height: 64px;
  margin-bottom: 28px;
  margin-left: 13px;
  margin-right: 13px;
  text-align: center
}

.answers-videos__grid__full-video {
  align-items: center;
  flex-basis: 100%;
  justify-content: flex-start;
  margin-left: 0;
  margin-right: 0
}

.answers-videos__grid__full-video>* {
  flex: 0 0 calc(50% - 26px);
  margin-left: 13px;
  margin-right: 13px
}

.answers-videos__grid__full-video>.button,
.answers-videos__grid__full-video>.slide-tab__label.active,
.tabs__labels.tabs__labels_buttons .answers-videos__grid__full-video>.tab__label.active {
  text-align: center
}

.answers-videos__button {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 28px;
  text-align: center
}

.answers-videos__button>* {
  margin-left: 13px;
  margin-right: 13px
}

.answers-videos__more-link {
  color: #333638;
  font-size: 16px;
  font-weight: 400;
  margin-top: 40px;
  text-align: center
}

.answers-videos__full-video {
  color: #5f6877;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  max-width: 255px;
  text-align: left
}

.answers-videos__full-video:before {
  background-image: url(/build/i/b143955ee6e455a8f586.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  display: block;
  height: 36px;
  margin-right: 15px;
  transition: all .2s ease-in;
  width: 52px
}

.answers-videos__full-video .uppercase {
  border-bottom: 1px solid;
  font-weight: 400;
  transition: all .2s ease-in
}

.answers-videos__full-video:hover:before {
  opacity: .8;
  transition: all .2s ease-out
}

.answers-videos__full-video:hover .uppercase {
  border-bottom: 1px solid transparent;
  transition: all .2s ease-out
}

.answers-videos__more-questions {
  display: none;
  font-size: 14px;
  margin-bottom: 23px;
  text-align: center
}

@media screen and (max-width:1359px) {
  .page_251 .answers-videos+.pusher {
    margin-top: 70px !important
  }
}

@media screen and (max-width:1275px) {
  .answers-videos__grid>:first-child {
    flex-basis: calc(50% - 72px);
    margin-bottom: 50px
  }

  .answers-videos__grid__video {
    margin-left: auto;
    margin-right: auto
  }

  .answers-videos__grid__video__title {
    font-size: 18px
  }

  .answers-videos__grid__video__doctor {
    bottom: 80px;
    font-size: 13px
  }

  .answers-videos__grid__grid {
    justify-content: flex-start;
    margin-left: -5px
  }

  .answers-videos__grid__grid>* {
    flex: 0 0 25%
  }

  .answers-videos__grid__grid>a:not(.button):not(.slide-tab__label.active) {
    margin-bottom: 13px;
    padding: 8px 13px 8px 73px
  }

  .answers-videos__grid__full-video {
    flex: 1 0 100% !important
  }

  .answers-videos__grid__grid__title {
    font-size: 13px
  }
}

@media screen and (max-width:1161px) {
  .answers-videos__button {
    justify-content: center;
    margin-top: 0
  }

  .answers-videos__button>* {
    flex: 1 0 calc(50% - 26px);
    max-width: calc(50% - 26px)
  }

  .answers-videos__button .answers-videos__full-video {
    margin-right: auto;
    max-width: 255px
  }
}

@media screen and (max-width:1080px) {
  .answers-videos__grid__grid>* {
    flex: 0 0 33.33%
  }
}

@media screen and (max-width:1023px) {
  .answers-videos__grid>:first-child {
    flex-basis: 100%
  }

  .answers-videos__grid__video_with-preview .answers-videos__grid__play {
    display: inline-block
  }

  .answers-videos__grid__video__doctor {
    bottom: 60px
  }

  .answers-videos__grid__grid {
    justify-content: space-between;
    margin-left: 0;
    margin-right: 0
  }

  .answers-videos__grid__full-video {
    flex: 1 0 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important
  }

  .page_251 .answers-videos+.pusher {
    margin-top: 55px !important
  }
}

@media screen and (max-width:767px) {
  .answers-videos__grid__video {
    max-width: 562px
  }

  .answers-videos__grid__grid>* {
    flex: 0 0 50%
  }

  .page_251 .answers-videos+.pusher {
    margin-top: 35px !important
  }
}

@media screen and (max-width:600px) {
  .answers-videos__grid {
    display: block
  }

  .answers-videos__grid__video {
    max-height: 100%;
    max-width: 100%
  }

  .answers-videos__grid__video:after {
    bottom: 4px
  }

  .answers-videos__grid__video .progressive-image-wrapper {
    width: 100% !important
  }

  .answers-videos__grid__grid>div {
    flex-wrap: wrap;
    justify-content: center
  }

  .answers-videos__grid__grid__title {
    font-size: 14px;
    max-width: 150px;
    padding-left: 0;
    padding-right: 0
  }
}

@media screen and (max-width:577px) {
  .answers-videos {
    padding-bottom: 40px;
    padding-top: 40px
  }

  .page_251 .answers-videos {
    padding-bottom: 12px
  }

  .answers-videos__grid {
    margin-top: 30px
  }

  .answers-videos__grid__grid {
    justify-content: center
  }

  .answers-videos__grid__grid>* {
    flex-basis: calc(100% - 30px) !important;
    margin-left: 15px !important;
    margin-right: 15px !important
  }

  .answers-videos__grid__grid>a:not(.button):not(.slide-tab__label.active) {
    display: none
  }

  .answers-videos__grid__grid>a:not(.button):not(.slide-tab__label.active):nth-child(-n+3) {
    display: flex
  }

  .answers-videos__grid__grid>.button,
  .answers-videos__grid__grid>.slide-tab__label.active,
  .tabs__labels.tabs__labels_buttons .answers-videos__grid__grid>.tab__label.active {
    height: 60px;
    line-height: 60px;
    margin-left: auto;
    margin-right: auto;
    max-width: 236px
  }

  .answers-videos__button {
    margin-left: auto;
    margin-right: auto;
    max-width: 236px
  }

  .answers-videos__grid__video__title {
    left: 0;
    padding-left: 24px
  }

  .page_251 .answers-videos__more-questions {
    display: none
  }

  .answers-videos__button {
    margin-top: 0
  }

  .answers-videos__grid__full-video {
    justify-content: center
  }

  .answers-videos__grid__full-video>* {
    flex-basis: 100% !important
  }

  .answers-videos__grid__full-video>.answers-videos__full-video {
    margin-top: 28px
  }

  .answers-videos__button {
    flex-wrap: wrap;
    justify-content: center
  }

  .answers-videos__button>* {
    flex-basis: calc(100% - 15px) !important;
    margin-left: 0;
    margin-right: 0;
    max-width: calc(100% - 15px)
  }

  .answers-videos__button>.answers-videos__full-video {
    margin-right: 0;
    margin-top: 28px
  }

  .answers-videos__more-questions {
    display: block
  }
}

@media screen and (max-width:560px) {
  .answers-videos__grid__video__doctor {
    display: none
  }

  .answers-videos__grid__video__title {
    font-size: 20px
  }
}

@media screen and (max-width:455px) {
  .answers-videos__grid__grid__title {
    max-width: 100%
  }
}

@media screen and (max-width:460px) {
  .answers-videos__grid__video__doctor {
    bottom: 52px;
    max-width: 220px
  }

  .answers-videos__grid__video__title {
    bottom: 25px;
    font-size: 16px;
    max-width: 320px
  }
}

@media screen and (max-width:359px) {
  .answers-videos__grid>:first-child {
    margin-bottom: 28px
  }

  .answers-videos__grid__video__title {
    bottom: 20px;
    padding-right: 15px
  }
}

.employees-videos .recommendations__title span {
  color: #333638
}

.employees-videos .progressive-image-wrapper {
  background-color: #fff;
  height: 90px !important;
  width: 90px !important
}

.employees-videos .progressive-image {
  bottom: -10px;
  left: 50%;
  margin-left: -60px;
  position: absolute;
  width: 120px
}

.recommendations__persons>div.active .progressive-image-wrapper {
  background-color: #ef9e00
}

.memo {
  min-height: 533px;
  padding-bottom: 20px;
  padding-top: 90px
}

.memo__h1 {
  font-size: 42px;
  font-weight: 700;
  line-height: 46px;
  margin-bottom: 24px
}

.memo__description,
.memo__h1 {
  color: #333638;

  font-style: normal
}

.memo__description {
  font-size: 26px;
  font-weight: 400;
  line-height: 36px;
  margin-bottom: 10px
}

.memo__form {
  margin-bottom: 28px
}

.memo__form .g-recaptcha {
  float: left;
  left: -5px;
  position: relative;
  transform: scale(.8)
}

.memo__form .g-recaptcha .form__error {
  bottom: -17px
}

.memo__form__left-input {
  float: left;
  width: 287px
}

.memo__form__right-input {
  float: right;
  width: 287px
}

.memo__form__submit {
  align-items: center;
  display: flex;
  float: left;
  justify-content: space-between;
  position: relative;
  width: 100%
}

.memo__form__submit>* {
  width: 287px
}

.memo__form__notice {
  color: #bdc7ca;
  float: left;
  font-size: 12px;
  margin-top: 10px
}

@media screen and (max-width:1290px) {
  .memo__grid>:first-child {
    flex-basis: 45%
  }

  .memo__grid>:last-child {
    flex-basis: 55%
  }
}

@media screen and (max-width:1180px) {

  .memo__form__left-input,
  .memo__form__right-input {
    float: none;
    max-width: 400px;
    width: 100%
  }

  .memo__grid>:first-child {
    flex-basis: 35%;
    position: relative
  }

  .memo__grid>:first-child>.progressive-image-wrapper {
    left: -116px;
    position: absolute
  }

  .memo__grid>:last-child {
    flex-basis: 65%
  }

  .memo__form__submit {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    float: left;
    justify-content: space-between;
    width: 100%
  }

  .memo__form__submit>:first-child {
    flex-basis: 100%;
    left: -10%;
    margin-bottom: 19px
  }

  .memo__form__notice {
    margin-top: 20px
  }
}

@media screen and (max-width:912px) {
  .memo__grid>:first-child .progressive-image-wrapper {
    left: -44px;
    width: 150px !important
  }

  .memo__grid>:first-child {
    flex-basis: 20%
  }

  .memo__grid>:last-child {
    flex-basis: 80%
  }
}

@media screen and (max-width:767px) {
  .memo {
    padding-bottom: 30px;
    padding-top: 30px
  }

  .memo__form,
  .memo__h1 {
    text-align: center
  }

  .memo__h1 {
    font-size: 28px;
    line-height: 30px;
    margin-bottom: 10px
  }

  .memo__description {
    font-size: 18px;
    line-height: 24px;
    text-align: center
  }

  .memo__grid>:first-child {
    flex-basis: 100%;
    height: 142px;
    margin-bottom: 20px;
    width: 100%
  }

  .memo__grid>:first-child .progressive-image-wrapper {
    left: 50%;
    margin-left: -98px;
    width: 150px !important
  }

  .memo__grid>:last-child {
    flex-basis: 100%;
    width: 100%
  }

  .memo__form__submit {
    justify-content: space-around
  }

  .memo__form__submit>:first-child {
    left: 0;
    margin-left: 0
  }

  .memo__form__submit>:first-child>div {
    margin-left: auto;
    margin-right: auto
  }

  .memo__answer {
    text-align: center
  }

  .memo+.pusher__page_memo {
    display: none
  }
}

.we-are-social {
  background-color: #ecf2f6;
  padding: 60px 0 75px
}

.we-are-social__h2-sub {
  color: #333638;
  font-size: 14px;
  line-height: 24px;
  max-width: 600px
}

.social-link-wrapper {
  display: flex;
  justify-content: center;
  margin: 30px 0 40px
}

.social-link-wrapper>* {
  margin-left: 10px;
  margin-right: 10px
}

.social-button {
  background-color: #f3f8fa;
  border-radius: 10px;
  box-shadow: 0 7px 29px rgba(0, 0, 0, .1);
  color: #333638;
  display: block;
  font-size: 16px;
  font-weight: 500;
  height: 70px;
  line-height: 24px;
  overflow: hidden;
  padding: 10px 0 0 125px;
  position: relative;
  width: 288px
}

.social-button:after,
.social-button:before {
  background-color: #cedee9;
  border-radius: 50%;
  content: "";
  display: block;
  height: 130px;
  left: -30px;
  position: absolute;
  top: -30px;
  transition: all .2s ease;
  width: 130px
}

.social-button:after {
  transform: translateX(-100%)
}

.social-button:hover:after {
  transform: translateX(0)
}

.social-button_ig:after {
  background-color: #b64fa4
}

.social-button_vk:after {
  background-color: #207ec6
}

.social-button_fb:after {
  background-color: #396ca6
}

.social-button_ok:after {
  background-color: #e18c13
}

.social-button__icon {
  height: 40px;
  left: 27px;
  pointer-events: none;
  position: absolute;
  top: 16px;
  width: 40px;
  z-index: 2
}

.social-button__subscribers {
  color: #819292;
  display: block;
  font-size: 14px;
  line-height: 24px;
  margin-top: 4px;
  padding-left: 20px;
  position: relative
}

.social-button__subscribers:before {
  background-image: url(/build/i/38c0f83210f420d59fe4.svg);
  content: "";
  height: 15px;
  left: 0;
  position: absolute;
  top: 0;
  width: 13px
}

@media screen and (max-width:1345px) {
  .we-are-social {
    padding: 35px 0 60px
  }

  .social-link-wrapper {
    flex-wrap: wrap;
    margin: 30px auto 10px;
    max-width: 610px
  }

  .social-link-wrapper>* {
    margin-left: 0;
    margin-right: 0
  }

  .social-button {
    margin-bottom: 30px
  }
}

@media screen and (max-width:630px) {
  .social-link-wrapper {
    justify-content: center;
    margin: 30px auto 20px;
    max-width: 300px
  }
}

.guarantee-package__grid {
  display: flex;
  justify-content: space-between;
  margin-top: 65px
}

.guarantee-package__img {
  position: relative
}

.guarantee-package__img:before {
  background-image: url(/build/i/469aca86eb458ff219f0.svg);
  background-size: 29px 34px;
  color: #fff;
  content: "1";
  font-size: 24px;
  font-weight: 500;
  height: 34px;
  left: 50%;
  line-height: 34px;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: -15px;
  transform: translate(-50%);
  width: 29px;
  z-index: 1
}

.guarantee-package_item {
  max-width: 365px;
  position: relative;
  text-align: center;
  width: 100%
}

.guarantee-package_item:before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAxNiI+PGxpbmVhckdyYWRpZW50IGlkPSJhIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjQuODUiIHkxPSI4IiB4Mj0iMTEuMTQ5IiB5Mj0iOCI+PHN0b3Agb2Zmc2V0PSIwIiBzdG9wLWNvbG9yPSIjZDQ2ZjAwIi8+PHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjZmZiYTAwIi8+PC9saW5lYXJHcmFkaWVudD48cGF0aCBkPSJNOCAxNmMtMS43IDAtMy4xLTEuNC0zLjEtMy4xVjMuMUM0LjggMS40IDYuMyAwIDggMHMzLjEgMS40IDMuMSAzLjF2OS43YzAgMS44LTEuNCAzLjItMy4xIDMuMnoiIGZpbGw9InVybCgjYSkiLz48bGluZWFyR3JhZGllbnQgaWQ9ImIiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4MT0iLS4wMDEiIHkxPSI4IiB4Mj0iMTYuMDAxIiB5Mj0iOCI+PHN0b3Agb2Zmc2V0PSIwIiBzdG9wLWNvbG9yPSIjZDQ2ZjAwIi8+PHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjZmZiYTAwIi8+PC9saW5lYXJHcmFkaWVudD48cGF0aCBkPSJNMTIuOSAxMS4xSDMuMUMxLjQgMTEuMSAwIDkuNyAwIDhzMS40LTMuMSAzLjEtMy4xaDkuN0MxNC42IDQuOSAxNiA2LjMgMTYgOHMtMS40IDMuMS0zLjEgMy4xeiIgZmlsbD0idXJsKCNiKSIvPjwvc3ZnPg==);
  content: "";
  height: 16px;
  position: absolute;
  right: -36px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px
}

.guarantee-package_item:nth-child(2) .guarantee-package__img:before {
  content: "2"
}

.guarantee-package_item:nth-child(3) .guarantee-package__img:before {
  content: "3"
}

.guarantee-package_item:nth-child(3):before {
  content: none
}

.guarantee-package__title {
  color: #333638;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  margin: 45px 0 30px
}

.guarantee-package__title span {
  display: block;
  font-size: 26px
}

.guarantee-package__title .uppercase {
  text-transform: uppercase
}

.guarantee-package__text {
  color: #989ca6;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px
}

@media screen and (max-width:1300px) {
  .guarantee-package_item {
    padding: 0 15px
  }
}

@media screen and (max-width:1200px) {
  .guarantee-package_item:before {
    content: none
  }
}

@media screen and (max-width:900px) {
  .guarantee-package__text {
    display: none
  }
}

@media screen and (max-width:767px) {
  .guarantee-package__grid {
    flex-wrap: wrap;
    justify-content: center
  }

  .guarantee-package__title {
    margin: 20px 0 50px
  }

  .guarantee-package_item:last-child .guarantee-package__title {
    margin-bottom: 0
  }
}

// Блок через (?)
.through-block {
  background-color: #3cbfb5;
  min-height: 316px;
  overflow: hidden;
  position: relative;
  z-index: 1;

  .row {
    min-height: 316px;
    padding-top: 40px;
    position: relative;

    &:before {
      bottom: 0;
      content: "";
      display: block;
      position: absolute;
      right: 0
    }
  }

  .row_short {
    min-height: 316px;
    padding-top: 40px;
    position: relative;

    &:before {
      bottom: 0;
      content: "";
      display: block;
      position: absolute;
      right: 0
    }
  }

  &_tooth-on-scale {
    .row {
      &:before {
        background-image: url(/build/i/1a211e9e5097335c4672.png),
          url(/build/i/2804d3191656690310eb.png);
        background-position: center 35px,
          bottom;
        content: "";
        height: 316px;
        width: 632px
      }
    }

    .row_short {
      &:before {
        background-image: url(/build/i/1a211e9e5097335c4672.png),
          url(/build/i/2804d3191656690310eb.png);
        background-position: center 35px,
          bottom;
        content: "";
        height: 316px;
        width: 632px
      }
    }
  }

  &__second-button__video {
    border-radius: 10px;
    display: inline-block;
    height: 28px;
    margin-right: 14px;
    overflow: hidden;
    width: 42px;

    i {
      align-items: center;
      border-radius: 40px 40px 40px 40px/10px 10px 10px 10px;
      box-shadow: none;
      display: flex;
      height: 28px;
      justify-content: center;
      padding: 0;
      width: 42px;

      &:before {
        border-color: transparent transparent transparent #fff;
        border-style: solid;
        border-width: 6px 0 6px 10px;
        content: "";
        display: block;
        height: 0;
        left: 1px;
        position: relative;
        width: 0;
        z-index: 1
      }
    }
  }

  &_online-consultation {
    background-image: linear-gradient(-6deg, #28a8ea, #9069ae 72%);
    min-height: 350px;

    .row,
    .row_short {
      min-height: 350px;

      &:before {
        background-image: url(/build/i/5e63f527b558847d3278.png);
        background-position: 50%;
        background-size: contain;
        content: "";
        height: 267px;
        top: calc(50% - 131px);
        width: 470px;
      }
    }

    .through-block__pre-title {
      color: #e3d9eb
    }

    .through-block__title {
      max-width: 624px;

      a {
        color: #fff;
        text-decoration: underline
      }

      .through-block_online-consultation .through-block__title a:hover {
        text-decoration: none
      }

      .through-block_online-consultation .through-block__sub-title {
        max-width: 725px
      }

      .through-block_online-consultation .through-block__sub-title__list div {
        font-size: 18px;
        margin-bottom: 11px
      }

      .through-block_online-consultation .through-block__second-button {
        align-items: center;
        color: #caebe7;
        display: flex;
        font-size: 14px;
        line-height: 16px;
        margin-left: 16px
      }
    }
  }
}

.through-block_online-consultation .through-block__title .through-block-short .through-block_online-consultation,
.through-block-short .through-block_online-consultation .row,
.through-block-short .through-block_online-consultation .row_short {
  min-height: 265px
}

.through-block-short .through-block_online-consultation .row:before,
.through-block-short .through-block_online-consultation .row_short:before {
  height: 220px;
  right: 24px;
  top: calc(50% - 107px);
  width: 388px
}

.through-block-short .through-block_online-consultation .through-block__pre-title {
  margin-bottom: 20px
}

.through-block-short .through-block_online-consultation .through-block__title {
  margin-bottom: 30px
}

.through-block_coronavirus {
  background-color: #e28700;
  min-height: 350px
}

.through-block_coronavirus .through-block__pre-title {
  color: #f5e1cf
}

.through-block_coronavirus .through-block__title {
  margin-bottom: 28px
}

.through-block_coronavirus .through-block__sub-title {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 34px;
  max-width: 630px
}

.through-block_coronavirus .row,
.through-block_coronavirus .row_short {
  min-height: 350px;
  padding-top: 38px
}

.through-block_coronavirus .row:before,
.through-block_coronavirus .row_short:before {
  background-image: url(/build/i/360636f6b36c4dd2eb00.png), url(/build/i/8f1d4a0ae03b44248275.png);
  background-position: bottom, bottom;
  content: "";
  height: 350px;
  right: -156px;
  width: 1080px
}

.through-block__close {
  background-image: url(/build/i/6e0c3d794f35f31c4f83.svg);
  background-position: 50%;
  background-size: 20px 20px;
  display: block;
  height: 40px;
  opacity: 1;
  position: absolute;
  right: 20px;
  top: 20px;
  transition: opacity .2s ease-out;
  width: 40px;
  z-index: 2
}

.through-block__close:hover {
  opacity: .7;
  transition: opacity .2s ease-in
}

.through-block__pre-title {
  color: #cceeeb;
  font-size: 24px;
  margin-bottom: 10px
}

.through-block__pre-title,
.through-block__title {
  font-style: normal;
  font-weight: 700
}

.through-block__title {
  color: #fff;
  font-size: 34px;
  margin-bottom: 22px
}

.through-block__sub-title {
  color: #fff;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 32px;
  max-width: 592px
}

.through-block__sub-title__grid>div {
  padding-left: 44px;
  position: relative
}

.through-block__sub-title__grid>div:first-child {
  padding-right: 30px
}

.through-block__sub-title__grid>div:before {
  background-image: url(/build/i/b9f5382e51f7ad0ee6e0.svg);
  content: "";
  display: block;
  height: 34px;
  left: 0;
  position: absolute;
  top: calc(50% - 17px);
  width: 34px
}

.through-block__sub-title__list>div {
  font-size: 20px;
  margin-bottom: 13px;
  padding-left: 27px;
  position: relative
}

.through-block__sub-title__list>div:last-child {
  margin-bottom: 0
}

.through-block__sub-title__list>div:before {
  background-image: url(/build/i/9f951c14ea19f8e14255.svg);
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 16px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 16px
}

.through-block__buttons {
  display: flex;
  height: 40px
}

.through-block__button,
.through-block__second-button {
  border-radius: 20px;
  height: 40px;
  line-height: 42px;
  position: relative;
  z-index: 2
}

.through-block__second-button {
  left: 11px
}

.through-block__second-button.button_transparent {
  line-height: 40px
}

.through-block-short .through-block {
  border-radius: 10px;
  min-height: 265px
}

.through-block-short .through-block .row,
.through-block-short .through-block .row_short {
  min-height: 265px;
  padding-top: 38px
}

.through-block-short .through-block__title {
  margin-bottom: 12px
}

.through-block-short .through-block__sub-title {
  margin-bottom: 15px
}

.through-block-short .through-block_tooth-on-scales .row:before,
.through-block-short .through-block_tooth-on-scales .row_short:before {
  background-position: bottom, bottom;
  background-size: contain;
  height: 222px;
  right: 34px;
  width: 444px
}

.through-block-short .through-block_coronavirus .through-block__pre-title {
  font-size: 22px
}

.through-block-short .through-block_coronavirus .through-block__sub-title {
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 28px
}

.through-block-short .through-block_coronavirus .through-block__sub-title__grid>div:before {
  height: 26px;
  top: calc(50% - 13px);
  width: 26px
}

.through-block-short .through-block_coronavirus .row:before,
.through-block-short .through-block_coronavirus .row_short:before {
  background-size: contain, cover;
  height: 265px;
  right: -111px;
  width: 772px
}

.through-block_safety {
  background-image: linear-gradient(-10deg, #57bab0, #11bf46);
  min-height: 360px
}

.through-block_safety .through-block__pre-title {
  color: #fff;
  opacity: .75
}

.through-block_safety .link_white>span {
  border-bottom: 1px solid #fff;
  transition: border .2s ease
}

.through-block_safety .link_white:hover>span {
  border-bottom: 1px solid transparent
}

.through-block_safety .through-block__title {
  margin-bottom: 28px
}

.through-block_safety .through-block__sub-title {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 34px;
  max-width: 800px
}

.through-block_safety .row,
.through-block_safety .row_short {
  min-height: 360px;
  padding-bottom: 40px;
  padding-top: 38px
}

.through-block_safety .row:before,
.through-block_safety .row_short:before {
  background-image: url(/build/i/604f40292e49feb9459e.png), url(/build/i/6f4e73448a3a5bfc9c1d.png);
  background-position: bottom, bottom;
  content: "";
  height: 380px;
  right: -200px;
  width: 960px
}

.through-block_safety .through-block__sub-title__list>div {
  font-size: 18px
}

.through-block_safety a.through-block__second-button {
  margin-right: 22px;
  max-width: 184px;
  text-align: center;
  width: 100%
}

.through-block_safety .through-block__second-button__video {
  color: #fff
}

.through-block_safety .through-block__video-button {
  align-items: center;
  color: #fff;
  display: flex;

  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left
}

.through-block_safety-double .row:before,
.through-block_safety-double .row_short:before {
  background-image: url(/build/i/ed85bc0ff77f7cf5518d.png);
  height: 252px;
  right: -30px;
  width: 540px
}

.through-block_prices-double {
  background-color: #e08f03
}

.through-block_prices-double .row:before,
.through-block_prices-double .row_short:before {
  background-image: url(/build/i/5580e61fbeee43996e6e.png);
  height: 315px;
  right: -100px;
  width: 566px
}

.through-block_prices-double .through-block__pre-title {
  color: #fff
}

.through-block_prices-double .link_white>span {
  border-bottom: 1px solid #fff;
  transition: border .2s ease
}

.through-block_prices-double .link_white:hover>span {
  border-bottom: 1px solid transparent
}

.through-block_prices-double .link_white.dashed>span,
.through-block_prices-double .link_white[data-js=tooltip]>span {
  border-bottom: 1px dashed #fff
}

.through-block_prices-double .link_white.dashed:hover>span,
.through-block_prices-double .link_white:hover[data-js=tooltip]>span {
  border-bottom: 1px dashed transparent
}

.through-block_prices-double .through-block__second-button {
  background-color: #57bab2;
  border: 2px solid transparent;
  color: #fff;
  text-align: center;
  width: 184px
}

.through-block_prices-double .through-block__second-button:hover {
  background-color: #00b1a8;
  box-shadow: 0 10px 36px 4px rgba(70, 81, 86, .17)
}

.through-block__countdown-wrapper {
  color: #fff;
  margin-bottom: 5px;
  margin-left: 60px
}

.through-block__countdown__title {
  color: hsla(0, 0%, 100%, .5);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 12px
}

.through-block__countdown {
  margin-left: -12px;
  margin-right: -12px;
  max-width: 260px
}

.through-block__countdown>* {
  padding-left: 12px;
  padding-right: 12px
}

.through-block__countdown>div {
  color: #edd9b6;
  font-size: 12px;
  position: relative;
  text-align: center
}

.through-block__countdown>div span {
  align-items: center;
  border: 1px solid #fff;
  border-radius: 50%;
  display: flex;
  font-size: 20px;
  height: 40px;
  justify-content: center;
  margin-bottom: 6px;
  width: 40px
}

.through-block__countdown>div span,
.through-block__countdown>div:before {
  color: #fff;

  font-style: normal;
  font-weight: 500
}

.through-block__countdown>div:before {
  content: ":";
  font-size: 24px;
  left: -3px;
  line-height: 9px;
  opacity: 0;
  position: absolute;
  top: 14px
}

.through-block__countdown>div:first-child:before {
  content: none
}

.through-block__countdown>div.blink:before {
  opacity: 1
}

.through-block_cut-prices {
  align-items: center;
  background-color: #e08f03;
  border-radius: 6px;
  display: flex;
  margin-bottom: 40px;
  margin-top: 40px;
  min-height: 238px;
  position: relative
}

.through-block_cut-prices:before {
  background-image: url(/build/i/eb5b8041318a473a6455.png);
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 0;
  content: "";
  height: 205px;
  left: 0;
  position: absolute;
  width: 296px
}

.through-block_cut-prices .row,
.through-block_cut-prices .row_short {
  flex-basis: 100%;
  min-height: auto;
  padding: 0 300px;
  position: relative
}

.through-block_cut-prices .through-block__buttons {
  display: block;
  height: auto;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 285px
}

.through-block_cut-prices .through-block__countdown-wrapper {
  margin-left: 0
}

.through-block_cut-prices .through-block__title {
  font-size: 26px
}

.through-block_cut-prices .through-block__sub-title {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0;
  max-width: 520px
}

.through-block_hold-prices {
  background-color: #ee8525;
  min-height: 250px
}

.through-block_hold-prices .row,
.through-block_hold-prices .row_short {
  min-height: 250px
}

.through-block_hold-prices .row:before,
.through-block_hold-prices .row_short:before {
  background-image: url(/build/i/ef8ec6f5392c25c3d205.png);
  background-position: bottom;
  background-size: contain;
  content: "";
  height: 244px;
  width: 612px
}

.through-block_hold-prices .row>*,
.through-block_hold-prices .row_short>* {
  position: relative;
  z-index: 1
}

.through-block_hold-prices .through-block__title {

  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 12px;
  max-width: 550px
}

.through-block_hold-prices .through-block__sub-title {

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 15px;
  max-width: 680px;
  opacity: .75
}

.through-block_hold-prices .through-block__button {
  max-width: 270px;
  width: 100%
}

.through-block_hold-prices .through-block__second-button {
  left: 36px;
  max-width: 170px;
  text-align: center;
  width: 100%
}

.through-block-short .through-block_hold-prices {
  background-color: #f1e5d3;
  background-position: 12px 12px;
  min-height: 250px
}

.through-block-short .through-block_hold-prices .row,
.through-block-short .through-block_hold-prices .row_short {
  min-height: 250px
}

.through-block-short .through-block_hold-prices .row:before,
.through-block-short .through-block_hold-prices .row_short:before {
  right: -40px
}

.through-block-short .through-block_hold-prices .through-block__sub-title,
.through-block-short .through-block_hold-prices .through-block__title {
  color: #3c4143
}

.through-block-short .through-block_hold-prices .through-block__second-button {
  border: 2px solid #00bdb4;
  color: #00bdb4
}

.through-block_zygoma {
  background-color: #e08f03
}

.through-block_zygoma .through-block__pre-title {
  color: hsla(0, 0%, 100%, .75)
}

.through-block_zygoma .through-block__sub-title p strong {

  font-style: normal;
  font-weight: 500
}

.through-block_zygoma .through-block__sub-title ul {
  list-style: none;
  margin-top: 10px;
  padding-left: 0
}

.through-block_zygoma .through-block__sub-title ul li {
  font-size: 16px;
  line-height: 24px;
  margin-top: 4px;
  padding-left: 12px;
  position: relative
}

.through-block_zygoma .through-block__sub-title ul li:before {
  background-color: #fff;
  border-radius: 50%;
  content: "";
  height: 5px;
  left: 0;
  position: absolute;
  top: 9px;
  width: 5px
}

.through-block_zygoma .through-block__button {
  color: #f3b813
}

.through-block_zygoma .through-block__button .mobile {
  display: none
}

.through-block_zygoma .row,
.through-block_zygoma .row_short {
  padding-bottom: 40px;
  position: relative
}

.through-block_zygoma .row>*,
.through-block_zygoma .row_short>* {
  position: relative;
  z-index: 1
}

.through-block_zygoma .row:before,
.through-block_zygoma .row_short:before {
  background-image: url(/build/i/35b700f36fbe9b76b845.png);
  background-position: top;
  background-size: contain;
  content: "";
  height: 282px;
  top: 0;
  width: 524px;
  z-index: 0
}

.through-block_continue-to-work .row,
.through-block_continue-to-work .row_short {
  padding-bottom: 26px
}

.through-block_continue-to-work .row:before,
.through-block_continue-to-work .row_short:before {
  background-image: url(/build/i/98815e088f4a6af16b88.png), url(/build/i/2804d3191656690310eb.png);
  background-position: bottom, bottom;
  content: "";
  height: 100%;
  width: 590px
}

@media screen and (max-width:1215px) {

  .through-block_online-consultation .row:before,
  .through-block_online-consultation .row_short:before {
    height: 220px;
    right: 24px;
    top: calc(50% - 107px);
    width: 388px
  }

  .through-block-short .through-block_online-consultation .row:before,
  .through-block-short .through-block_online-consultation .row_short:before {
    height: 170px;
    right: 24px;
    top: calc(50% - 85px);
    width: 300px
  }
}

@media screen and (max-width:1228px) {

  .through-block_tooth-on-scales .row:before,
  .through-block_tooth-on-scales .row_short:before {
    background-position: center 1px, bottom;
    background-size: contain;
    height: 225px;
    right: 30px;
    width: 450px
  }

  .through-block_safety .row:before,
  .through-block_safety .row_short:before {
    background-image: url(/build/i/604f40292e49feb9459e.png);
    background-position: bottom;
    background-size: contain;
    height: 220px;
    right: -111px;
    width: 600px
  }

  .through-block_safety-double .row:before,
  .through-block_safety-double .row_short:before {
    background-image: url(/build/i/ed85bc0ff77f7cf5518d.png);
    height: 252px;
    right: -30px;
    width: 540px
  }

  .through-block_continue-to-work .row:before,
  .through-block_continue-to-work .row_short:before {
    left: 570px
  }
}

@media screen and (max-width:1200px) {

  .through-block-short .through-block_tooth-on-scales .row:before,
  .through-block-short .through-block_tooth-on-scales .row_short:before {
    background-image: url(/build/i/1a211e9e5097335c4672.png);
    background-position: bottom;
    bottom: calc(50% - 80px);
    height: 160px;
    right: 34px;
    width: 320px
  }

  .through-block_coronavirus .row:before,
  .through-block_coronavirus .row_short:before {
    background-image: url(/build/i/360636f6b36c4dd2eb00.png);
    background-position: bottom;
    background-size: contain;
    height: 259px;
    right: -111px;
    width: 800px
  }

  .through-block_safety-double .row:before,
  .through-block_safety-double .row_short:before {
    left: 50%;
    margin-left: -270px;
    right: auto;
    width: 540px
  }

  .through-block_prices-double .row:before,
  .through-block_prices-double .row_short:before {
    left: 50%;
    margin-left: -283px;
    right: auto;
    width: 566px
  }

  .through-block_prices-double .through-block__second-button {
    width: 170px
  }

  .through-block__countdown-wrapper {
    margin-left: 40px
  }

  .through-block__countdown {
    margin-left: -8px;
    margin-right: -8px
  }

  .through-block__countdown>div {
    padding-left: 8px;
    padding-right: 8px
  }

  .through-block_cut-prices:before {
    background-image: url(/build/i/eb5b8041318a473a6455.png);
    background-repeat: no-repeat;
    background-size: contain;
    bottom: 0;
    content: "";
    height: 138px;
    left: 0;
    position: absolute;
    width: 200px
  }

  .through-block_cut-prices .row,
  .through-block_cut-prices .row_short {
    flex-basis: 100%;
    min-height: auto;
    padding-left: 200px;
    padding-right: 270px
  }

  .through-block_cut-prices .through-block__buttons {
    width: 255px
  }

  .through-block_cut-prices .through-block__title {
    font-size: 24px
  }

  .through-block_zygoma .row:before,
  .through-block_zygoma .row_short:before {
    width: 340px
  }
}

@media screen and (max-width:1153px) {

  .through-block_online-consultation .row:before,
  .through-block_online-consultation .row_short:before {
    height: 170px;
    right: 24px;
    top: calc(50% - 85px);
    width: 300px
  }
}

@media screen and (max-width:1082px) {

  .through-block_coronavirus .row:before,
  .through-block_coronavirus .row_short:before {
    height: 226px;
    right: -90px;
    width: 700px
  }

  .through-block_safety .row:before,
  .through-block_safety .row_short:before {
    background-image: url(/build/i/604f40292e49feb9459e.png);
    background-position: bottom;
    background-size: contain;
    height: 200px;
    right: -111px;
    width: 550px
  }

  .through-block_safety-double .row:before,
  .through-block_safety-double .row_short:before {
    background-image: url(/build/i/ed85bc0ff77f7cf5518d.png);
    height: 252px;
    right: -30px;
    width: 540px
  }
}

@media screen and (max-width:1180px) {

  .through-block-short .through-block_online-consultation .row:before,
  .through-block-short .through-block_online-consultation .row_short:before,
  .through-block_online-consultation .row:before,
  .through-block_online-consultation .row_short:before {
    content: none
  }
}

@media screen and (max-width:1170px) {

  .through-block-short .through-block_coronavirus .row:before,
  .through-block-short .through-block_coronavirus .row_short:before {
    background-image: url(/build/i/360636f6b36c4dd2eb00.png), url(/build/i/92ba481a35468f3fa534.png);
    background-position: bottom, bottom;
    background-size: contain;
    height: 175px;
    right: -26px;
    width: 509px
  }
}

@media screen and (max-width:1060px) {

  .through-block-short .through-block_coronavirus .row:before,
  .through-block-short .through-block_coronavirus .row_short:before {
    height: 150px;
    right: -40px;
    width: 436px
  }
}

@media screen and (max-width:1122px) {

  .through-block_tooth-on-scales .row:before,
  .through-block_tooth-on-scales .row_short:before {
    background-position: bottom, bottom;
    background-size: contain;
    height: 200px;
    right: 22px;
    width: 400px
  }
}

@media screen and (max-width:1112px) {

  .through-block-short .through-block_tooth-on-scales .row:before,
  .through-block-short .through-block_tooth-on-scales .row_short:before {
    bottom: calc(50% - 65px);
    height: 130px;
    width: 260px
  }

  .through-block_hold-prices .row:before,
  .through-block_hold-prices .row_short:before {
    right: -60px
  }
}

@media screen and (max-width:1023px) {

  .through-block_coronavirus .row:before,
  .through-block_coronavirus .row_short:before,
  .through-block_tooth-on-scales .row:before,
  .through-block_tooth-on-scales .row_short:before {
    content: none
  }

  .through-block_safety .row,
  .through-block_safety .row_short {
    padding-bottom: 35px
  }

  .through-block_safety .row:before,
  .through-block_safety .row_short:before {
    content: none
  }

  .through-block_safety-double .row:before,
  .through-block_safety-double .row_short:before {
    background-image: url(/build/i/ed85bc0ff77f7cf5518d.png);
    content: "";
    height: 252px;
    right: -30px;
    width: 540px
  }

  .through-block__countdown-wrapper {
    display: none
  }

  .through-block_cut-prices:before {
    content: none
  }

  .through-block_cut-prices .row,
  .through-block_cut-prices .row_short {
    padding-left: 30px
  }

  .through-block_cut-prices .through-block__countdown-wrapper {
    display: block
  }

  .through-block_hold-prices .row:before,
  .through-block_hold-prices .row_short:before {
    height: 150px;
    width: 370px
  }

  .through-block_hold-prices .row .through-block__sub-title,
  .through-block_hold-prices .row_short .through-block__sub-title {
    max-width: 520px
  }

  .through-block-short .through-block_hold-prices .row:before,
  .through-block-short .through-block_hold-prices .row_short:before {
    right: -100px
  }

  .through-block_zygoma .row:before,
  .through-block_zygoma .row_short:before {
    opacity: .5
  }

  .through-block_continue-to-work .row:before,
  .through-block_continue-to-work .row_short:before {
    display: none
  }
}

@media screen and (max-width:767px) {
  .through-block {
    min-height: 410px;
    text-align: center
  }

  .through-block .row,
  .through-block .row_short {
    min-height: 410px;
    padding-bottom: 40px;
    padding-top: 40px
  }

  .through-block-short .through-block_tooth-on-scales .through-block__buttons {
    justify-content: center
  }

  .through-block__buttons {
    justify-content: space-around
  }

  .through-block_tooth-on-scales .row:before,
  .through-block_tooth-on-scales .row_short:before {
    background-image: url(/build/i/1a211e9e5097335c4672.png);
    background-position: bottom;
    bottom: 16px;
    content: "";
    height: 116px;
    left: calc(50% - 115px);
    width: 230px
  }

  .through-block-short .through-block_coronavirus .through-block__pre-title {
    font-size: 20px
  }

  .through-block_coronavirus {
    min-height: 445px;
    text-align: left
  }

  .through-block_coronavirus .through-block__sub-title {
    text-align: left
  }

  .through-block_coronavirus .through-block__title {
    margin-bottom: 20px
  }

  .through-block_coronavirus .row,
  .through-block_coronavirus .row_short {
    min-height: 445px
  }

  .through-block_coronavirus .row:before,
  .through-block_coronavirus .row_short:before {
    background-image: url(/build/i/b172c378f98d3de3be32.png), url(/build/i/92ba481a35468f3fa534.png);
    background-position: bottom, bottom;
    background-size: 248px 148px, contain;
    content: "";
    height: 194px;
    left: calc(50% - 160px);
    width: 320px
  }

  .through-block-short .through-block {
    border-radius: 0;
    margin-left: -15px;
    margin-right: -15px
  }

  .through-block-short .through-block_coronavirus .row:before,
  .through-block-short .through-block_coronavirus .row_short:before,
  .through-block-short .through-block_tooth-on-scales .row:before,
  .through-block-short .through-block_tooth-on-scales .row_short:before {
    content: none
  }

  .through-block__pre-title {
    font-size: 20px
  }

  .through-block__title {
    font-size: 30px
  }

  .through-block__sub-title {
    font-size: 16px;
    margin-left: auto;
    margin-right: auto
  }

  .through-block__sub-title__list>div {
    font-size: 16px;
    margin-bottom: 5px
  }

  .through-block__sub-title__list>div:before {
    height: 14px;
    width: 14px
  }

  .through-block__close {
    right: 10px;
    top: 10px
  }

  .through-block_online-consultation {
    min-height: 330px;
    text-align: left
  }

  .through-block_online-consultation .row,
  .through-block_online-consultation .row_short {
    min-height: 330px
  }

  .through-block_online-consultation .through-block__buttons {
    justify-content: flex-start
  }

  .through-block_online-consultation .through-block__sub-title__list div {
    font-size: 16px;
    margin-bottom: 5px
  }

  .through-block_safety {
    min-height: 445px;
    text-align: left
  }

  .through-block_safety .through-block__sub-title {
    text-align: left
  }

  .through-block_safety .through-block__title {
    margin-bottom: 20px
  }

  .through-block_safety .row,
  .through-block_safety .row_short {
    min-height: 445px;
    padding-bottom: 160px
  }

  .through-block_safety .row:before,
  .through-block_safety .row_short:before {
    background-size: 248px 148px;
    content: "";
    height: 194px;
    left: calc(50% - 160px);
    width: 320px
  }

  .through-block_safety .through-block__sub-title {
    margin-bottom: 20px
  }

  .through-block_safety .through-block__sub-title__list>div {
    font-size: 16px
  }

  .through-block_prices-double:before {
    background-image: url(/build/i/5580e61fbeee43996e6e.png);
    background-position: bottom;
    background-size: 400px auto;
    bottom: 0;
    content: "";
    height: 180px;
    left: 50%;
    margin-left: -200px;
    position: absolute;
    width: 400px
  }

  .through-block_prices-double .row:before,
  .through-block_prices-double .row_short:before {
    content: none
  }

  .through-block_safety-double:before {
    background-image: url(/build/i/ed85bc0ff77f7cf5518d.png);
    background-position: bottom;
    background-size: 360px auto;
    bottom: 0;
    content: "";
    height: 160px;
    left: 50%;
    margin-left: -180px;
    position: absolute;
    width: 360px
  }

  .through-block_safety-double .row:before,
  .through-block_safety-double .row_short:before {
    content: none
  }

  .through-block_cut-prices {
    border-radius: 0;
    margin-left: -15px;
    margin-right: -15px;
    min-height: auto;
    text-align: left
  }

  .through-block_cut-prices .row,
  .through-block_cut-prices .row_short {
    min-height: auto;
    padding-left: 15px;
    padding-right: 15px
  }

  .through-block_cut-prices .through-block__countdown-wrapper {
    display: none
  }

  .through-block_cut-prices .through-block__sub-title {
    margin-left: 0
  }

  .through-block_hold-prices {
    min-height: auto
  }

  .through-block_hold-prices .row,
  .through-block_hold-prices .row_short {
    min-height: auto;
    padding-bottom: 180px
  }

  .through-block_hold-prices .row:before,
  .through-block_hold-prices .row_short:before {
    margin-right: -195px;
    right: 50%
  }

  .through-block_hold-prices .row .through-block__title,
  .through-block_hold-prices .row_short .through-block__title {
    font-size: 24px;
    text-align: center
  }

  .through-block_hold-prices .row .through-block__sub-title,
  .through-block_hold-prices .row_short .through-block__sub-title {
    margin-left: auto;
    margin-right: auto;
    opacity: 1;
    text-align: center
  }

  .through-block_hold-prices .row .through-block__buttons,
  .through-block_hold-prices .row_short .through-block__buttons {
    flex-wrap: wrap;
    height: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 400px
  }

  .through-block_hold-prices .row .through-block__button,
  .through-block_hold-prices .row_short .through-block__button {
    font-size: 14px
  }

  .through-block_hold-prices .row .through-block__second-button,
  .through-block_hold-prices .row_short .through-block__second-button {
    font-size: 14px;
    left: 0;
    margin-top: 20px
  }

  .through-block-short .through-block_hold-prices .row:before,
  .through-block-short .through-block_hold-prices .row_short:before {
    margin-right: -195px;
    right: 50%
  }

  .through-block-short+.pusher {
    margin-top: 56px !important
  }

  .through-block_zygoma {
    text-align: left
  }

  .through-block_zygoma .row,
  .through-block_zygoma .row_short {
    padding-bottom: 50px
  }

  .through-block_zygoma .row:before,
  .through-block_zygoma .row_short:before {
    content: none
  }

  .through-block_zygoma .through-block__pre-title,
  .through-block_zygoma .through-block__title {
    margin-bottom: 15px
  }

  .through-block_zygoma .through-block__button {
    color: #e08f03;
    font-size: 14px;
    padding-left: 14px;
    padding-right: 14px;
    text-transform: uppercase
  }

  .through-block_zygoma .through-block__button .mobile {
    display: inline
  }

  .through-block_zygoma .through-block__button .mobile+span {
    display: none
  }

  .through-block_zygoma .through-block__second-button {
    font-size: 14px;
    padding-left: 9px;
    padding-right: 9px;
    text-transform: uppercase
  }

  .through-block_zygoma .through-block__buttons {
    margin-top: 35px
  }

  .through-block_zygoma .through-block__sub-title ul {
    margin-top: 15px
  }

  .through-block_zygoma .through-block__sub-title ul li {
    line-height: 20px;
    margin-top: 8px
  }

  .through-block_zygoma .through-block__sub-title ul li:before {
    top: 7px
  }

  .through-block_continue-to-work .row,
  .through-block_continue-to-work .row_short {
    padding-bottom: 100px
  }

  .through-block_continue-to-work .row:after,
  .through-block_continue-to-work .row_short:after {
    background-image: url(/build/i/98815e088f4a6af16b88.png);
    background-size: contain;
    bottom: 0;
    content: "";
    height: 100px;
    left: 50%;
    position: absolute;
    transform: translate(-50%);
    width: 200px
  }
}

@media screen and (max-width:670px) {
  .through-block_coronavirus .through-block__sub-title {
    font-size: 16px;
    line-height: 20px
  }
}

@media screen and (max-width:580px) {
  .through-block_online-consultation .through-block__second-button {
    font-size: 12px
  }

  .through-block_safety .through-block__video-button span {
    display: none
  }
}

@media screen and (max-width:518px) {
  .through-block_online-consultation .through-block__second-button span {
    display: none
  }
}

@media screen and (max-width:526px) {
  .through-block__title {
    font-size: 28px
  }
}

@media screen and (max-width:500px) {
  .through-block_online-consultation .through-block__pre-title {
    font-size: 14px;
    line-height: 24px
  }

  .through-block_online-consultation .through-block__title {
    font-size: 26px
  }

  .through-block_online-consultation .through-block__sub-title__list div {
    font-size: 15px;
    line-height: 20px
  }

  .through-block_online-consultation,
  .through-block_online-consultation .row,
  .through-block_online-consultation .row_short {
    min-height: 450px
  }

  .through-block_online-consultation .row:before,
  .through-block_online-consultation .row_short:before {
    bottom: 15px;
    content: "";
    height: 128px;
    left: calc(50% - 113px);
    right: inherit !important;
    right: auto !important;
    top: inherit !important;
    top: auto !important;
    width: 227px
  }

  .through-block-short .through-block_online-consultation {
    min-height: 325px
  }

  .through-block-short .through-block_online-consultation .through-block__pre-title {
    margin-bottom: 2px
  }

  .through-block-short .through-block_online-consultation .through-block__title {
    margin-bottom: 20px
  }

  .through-block-short .through-block_online-consultation .row,
  .through-block-short .through-block_online-consultation .row_short {
    min-height: 325px;
    padding-top: 18px
  }

  .through-block-short .through-block_online-consultation .row:before,
  .through-block-short .through-block_online-consultation .row_short:before {
    bottom: 15px;
    content: "";
    height: 128px;
    left: calc(50% - 113px);
    right: inherit !important;
    right: auto !important;
    top: inherit !important;
    top: auto !important;
    width: 227px
  }
}

@media screen and (max-width:492px) {

  .through-block,
  .through-block .row,
  .through-block .row_short {
    min-height: 450px
  }

  .through-block_online-consultation,
  .through-block_online-consultation .row,
  .through-block_online-consultation .row_short {
    min-height: 498px
  }

  .through-block-short .through-block_online-consultation,
  .through-block-short .through-block_online-consultation .row,
  .through-block-short .through-block_online-consultation .row_short {
    min-height: 375px
  }

  .through-block__button span span {
    display: none
  }

  .through-block_coronavirus,
  .through-block_coronavirus .row,
  .through-block_coronavirus .row_short {
    min-height: 490px
  }

  .through-block_coronavirus .through-block__sub-title {
    margin-bottom: 20px
  }

  .through-block_coronavirus .through-block__sub-title__grid>div {
    flex-basis: 100%
  }

  .through-block_coronavirus .through-block__sub-title__grid>div:first-child {
    margin-bottom: 19px;
    padding-right: 0
  }

  .through-block_cut-prices {
    margin-bottom: 35px;
    margin-top: 35px;
    min-height: auto
  }

  .through-block_cut-prices .row,
  .through-block_cut-prices .row_short {
    min-height: auto;
    padding-bottom: 30px;
    padding-top: 30px
  }

  .through-block_cut-prices .through-block__title {
    font-size: 20px
  }

  .through-block_cut-prices .through-block__sub-title {
    font-size: 14px;
    line-height: 20px
  }
}

@media screen and (max-width:462px) {
  .through-block__close {
    height: 30px;
    width: 30px
  }

  .through-block__pre-title {
    margin-bottom: 9px
  }

  .through-block__sub-title,
  .through-block__title {
    margin-bottom: 12px
  }

  .through-block_continue-to-work .through-block__buttons {
    flex-direction: column;
    height: 90px
  }

  .through-block_continue-to-work .through-block__buttons>* {
    left: 0
  }
}

@media screen and (max-width:372px) {

  .through-block_online-consultation,
  .through-block_online-consultation .row,
  .through-block_online-consultation .row_short {
    min-height: 519px
  }

  .through-block_online-consultation .through-block__second-button {
    padding-left: 0;
    padding-right: 0
  }
}

@media screen and (max-width:360px) {
  .through-block__button {
    float: left
  }

  .through-block__second-button {
    float: right;
    left: inherit !important;
    left: auto !important
  }

  .through-block__button,
  .through-block__second-button {
    padding-left: 20px;
    padding-right: 20px
  }

  .through-block-short .through-block__button {
    float: none
  }
}

@media screen and (max-width:320px) {

  .through-block_coronavirus .row,
  .through-block_coronavirus .row_short {
    padding-top: 25px
  }
}

.through-blocks-double-wrapper {
  position: relative
}

.through-blocks-double-wrapper .through-block {
  height: auto;
  min-height: 200px
}

.through-blocks-double-wrapper .through-block .row,
.through-blocks-double-wrapper .through-block .row_short {
  max-width: 600px;
  min-height: 200px;
  padding-bottom: 110px
}

.through-blocks-double-wrapper .through-block .through-block__buttons {
  align-items: center;
  bottom: 26px;
  height: 82px;
  position: absolute
}

.through-blocks-double-wrapper .through-block .through-block__sub-title {
  margin-bottom: 10px
}

.through-blocks-double-wrapper .through-block:first-child .row,
.through-blocks-double-wrapper .through-block:first-child .row_short {
  margin-right: 0;
  max-width: 685px;
  padding-right: 79px
}

.through-blocks-double-wrapper .through-block:nth-child(2) .row,
.through-blocks-double-wrapper .through-block:nth-child(2) .row_short {
  margin-left: 0;
  max-width: 685px;
  padding-left: 79px
}

.through-blocks-double .through-block__pre-title {
  font-size: 18px;
  line-height: 24px
}

.through-blocks-double .through-block__title {
  font-size: 30px;
  margin-bottom: 20px
}

.through-blocks-double .through-block__sub-title__list>div {
  font-size: 16px
}

@media screen and (max-width:1199px) {
  .through-blocks-double .through-block__title {
    font-size: 28px;
    margin-bottom: 15px
  }

  .through-blocks-double .through-block__title br {
    display: none
  }

  .through-blocks-double .through-block__sub-title__list>div {
    font-size: 16px
  }
}

@media screen and (max-width:1130px) {

  .through-blocks-double-wrapper .through-block:first-child .row,
  .through-blocks-double-wrapper .through-block:first-child .row_short {
    padding-right: 39px
  }

  .through-blocks-double-wrapper .through-block:nth-child(2) .row,
  .through-blocks-double-wrapper .through-block:nth-child(2) .row_short {
    padding-left: 39px
  }
}

@media screen and (max-width:1023px) {
  .through-blocks-double-wrapper.through-block {
    min-height: 300px
  }

  .through-blocks-double .through-block__pre-title {
    padding-right: 15px
  }

  .through-blocks-double .through-block__sub-title,
  .through-blocks-double .through-block__video-button span {
    display: none
  }
}

@media screen and (max-width:767px) {
  .through-blocks-double {
    display: none;
    position: relative
  }

  .through-blocks-double.slick-initialized {
    display: block
  }

  .through-blocks-double .next,
  .through-blocks-double .prev {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 18px;
    margin-top: -9px;
    position: absolute;
    top: 50%;
    width: 14px;
    z-index: 10
  }

  .through-blocks-double .next:hover svg path,
  .through-blocks-double .prev:hover svg path {
    fill: #5f6877
  }

  .through-blocks-double .next svg,
  .through-blocks-double .prev svg {
    height: auto;
    position: relative;
    width: 100%;
    z-index: 1
  }

  .through-blocks-double .next:before,
  .through-blocks-double .prev:before {
    background-color: #fff;
    border-radius: 50%;
    content: "";
    height: 43px;
    left: -10px;
    position: absolute;
    width: 43px
  }

  .through-blocks-double .prev {
    left: 0;
    transform: rotate(-180deg);
    transform-origin: center center
  }

  .through-blocks-double .next {
    right: 0
  }

  .through-blocks-double .slick-dots {
    align-items: center;
    bottom: 20px;
    display: flex;
    justify-content: center;
    left: 0;
    list-style: none;
    padding: 0;
    position: absolute;
    width: 100%
  }

  .through-blocks-double .slick-dots li {
    background-color: hsla(0, 0%, 100%, .5);
    border-radius: 50%;
    color: transparent;
    height: 8px;
    margin-left: 4px;
    margin-right: 4px;
    transition: background-color .1s ease;
    width: 8px
  }

  .through-blocks-double .slick-dots li.slick-active {
    background-color: #fff
  }

  .through-blocks-double-wrapper.through-block {
    min-height: auto
  }

  .through-blocks-double-wrapper .through-block {
    display: block !important;
    min-height: 360px
  }

  .through-blocks-double-wrapper .through-block:first-child .row,
  .through-blocks-double-wrapper .through-block:first-child .row_short {
    padding-right: 15px
  }

  .through-blocks-double-wrapper .through-block:nth-child(2) .row,
  .through-blocks-double-wrapper .through-block:nth-child(2) .row_short {
    padding-left: 15px
  }

  .through-blocks-double-wrapper .through-block .through-block__pre-title,
  .through-blocks-double-wrapper .through-block .through-block__title {
    text-align: center
  }

  .through-blocks-double-wrapper .through-block .through-block__buttons {
    left: 50%;
    transform: translateX(-50%)
  }
}

.progressive-image-wrapper {
  display: inline-block;
  margin: 0;
  outline: none;
  overflow: hidden;
  position: relative
}

.progressive-image {
  border: 0;
  display: inline-block;
  height: auto;
  max-width: none;
  width: 100%
}

.progressive-image.preview {
  animation: reveal 1s ease-out;
  -webkit-filter: blur(2vw);
  filter: blur(2vw);
  position: absolute
}

.progressive-image__no-script {
  display: inline-block;
  height: 100%;
  left: 0;
  margin: auto;
  position: absolute;
  top: 0;
  width: 100%
}

.progressive-image-bg-wrapper {
  overflow: hidden;
  position: relative
}

.progressive-image-bg {
  background-position: 50%;
  background-size: cover;
  height: 100%;
  position: absolute;
  width: 100%
}

@keyframes reveal {
  0% {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

.scroll-to-top {
  background-color: #dee6ea;
  border-radius: 20px;
  bottom: 195px;
  box-shadow: 0 5px 18px rgba(0, 0, 0, .09);
  height: 40px;
  opacity: 0;
  position: fixed;
  right: 30px;
  transition: all .2s ease-out;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  visibility: hidden;
  width: 40px;
  z-index: 100
}

.scroll-to-top:before {
  background-image: url(/build/i/a586c3dacbe9f3e8e7aa.svg);
  content: "";
  display: block;
  height: 16px;
  left: 12px;
  position: absolute;
  top: 12px;
  transition: color .2s ease-out;
  width: 16px
}

.scroll-to-top:hover {
  background-color: #00beb4;
  transition: all .2s ease-in
}

.scroll-to-top:hover:before {
  color: #fff;
  transition: color .2s ease-in
}

.scroll-to-top_shown {
  opacity: 1;
  visibility: visible
}

@media screen and (max-width:767px) {
  .scroll-to-top {
    bottom: 15px;
    right: 15px
  }
}

.mfp-bg {
  background: #27514f;
  opacity: .5;
  z-index: 60000
}

.mfp-wrap {
  overflow-y: auto;
  z-index: 60001
}

.mfp-content {
  text-align: center
}

.mfp-3d-unfold .mfp-with-anim {
  opacity: 0;
  transform: rotateY(-60deg);
  transform-style: preserve-3d;
  transition: all .3s ease-in-out
}

.mfp-3d-unfold.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: rotateY(0deg)
}

.mfp-3d-unfold.mfp-ready.mfp-bg {
  opacity: .5
}

.mfp-3d-unfold.mfp-removing .mfp-with-anim {
  opacity: 0;
  transform: rotateY(60deg)
}

.mfp-inline-holder .mfp-close {
  color: transparent;
  padding-right: 0;
  right: -10px;
  top: -54px;
  width: 44px
}

.mfp-inline-holder .mfp-close:hover:before {
  transform: rotate(-180deg) scale(1.2);
  transition: all .2s ease-out
}

.mfp-inline-holder .mfp-close:before {
  background-image: url(/build/i/e215aa7999a2d72ef744.svg);
  content: "";
  display: block;
  height: 18px;
  position: absolute;
  transform: rotate(0deg) scale(1);
  transition: all .2s ease-in;
  width: 18px
}

.modal {
  background-color: #fff;
  border: 10px solid #e9ecee;
  border-radius: 10px;
  box-shadow: 0 10px 57px rgba(70, 81, 86, .31);
  display: inline-block;
  max-width: 1017px;
  padding: 50px;
  position: relative
}

.modal p {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding-bottom: 20px
}

.modal_with-padding {
  padding-left: 190px
}

.modal__pluses {
  left: 54px;
  position: absolute;
  top: 44px
}

.modal_error .modal__title {
  text-align: center
}

.modal_error .modal__social-icons {
  display: flex;
  float: none;
  justify-content: center
}

.modal_error__icon {
  margin-bottom: 26px
}

.modal_error__text {
  color: #a5a5a5;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 9px
}

.modal_success .modal__title {
  text-align: center
}

.modal_success__icon {
  margin-bottom: 26px
}

.modal_success__text {
  color: #a5a5a5;
  font-size: 14px;
  line-height: 24px
}

.modal__title {
  color: #3c4143;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 30px;
  text-align: left
}

.modal__social-icons {
  float: left;
  position: relative;
  z-index: 5
}

.modal__social-icons>* {
  float: left;
  margin-right: 24px
}

.modal__social-icons>:last-child {
  margin-right: 0
}

.modal__auth-text {
  color: #a5a5a5;
  font-size: 14px;
  line-height: 24px;
  padding-left: 246px;
  position: relative;
  text-align: left
}

@media screen and (max-width:1023px) {
  .modal_with-padding {
    padding-left: 50px
  }

  .mfp-container {
    padding-left: 0;
    padding-right: 0
  }
}

@media screen and (max-width:767px) {
  .modal {
    border-left: 0;
    border-radius: 0;
    border-right: 0;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%
  }

  .modal__title {
    text-align: center
  }

  .modal__pluses {
    display: none
  }

  .mfp-inline-holder .mfp-close {
    right: 0
  }

  .modal__social-icons {
    float: none
  }

  .modal__auth-text {
    margin-top: 28px;
    padding-left: 0
  }
}

.mfp_video-popup .mfp-close {
  color: transparent;
  padding-right: 0;
  right: 0;
  top: -44px;
  width: 44px
}

.mfp_video-popup .mfp-close:hover:before {
  transform: rotate(-180deg) scale(1.2);
  transition: all .2s ease-out
}

.mfp_video-popup .mfp-close:before {
  background-image: url(/build/i/e215aa7999a2d72ef744.svg);
  content: "";
  display: block;
  height: 18px;
  position: absolute;
  transform: rotate(0deg) scale(1);
  transition: all .2s ease-in;
  width: 18px
}

.mfp_image-popup .mfp-close {
  color: transparent;
  padding-right: 0;
  right: 0;
  top: -4px;
  width: 44px
}

.mfp_image-popup .mfp-close:hover:before {
  transform: rotate(-180deg) scale(1.2);
  transition: all .2s ease-out
}

.mfp_image-popup .mfp-close:before {
  background-image: url(/build/i/e215aa7999a2d72ef744.svg);
  content: "";
  display: block;
  height: 18px;
  position: absolute;
  transform: rotate(0deg) scale(1);
  transition: all .2s ease-in;
  width: 18px
}

.body_banned {
  background-color: #ecf0f5
}

.banned-wrapper {
  text-align: center
}

#ya-site-results {
  height: 0;
  overflow: hidden;
  visibility: hidden
}

.search__query {
  color: #999;

  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: 32px
}

.search__query b {
  color: #555;
  font-size: 18px;
  line-height: 32px
}

.search__results__placeholder {
  font-size: 16px;
  position: relative;
  top: 19px
}

.search__results__load-more {
  display: none
}

.search__results__no-result {
  position: relative;
  top: 10px
}

.search__results__no-result p {
  margin-top: 15px
}

@media screen and (max-width:767px) {
  [data-js-tabs=tabs]>.hidden {
    display: none !important
  }

  [data-js-tabs=tabs]>.shown {
    display: block !important
  }
}

.html-slider {
  height: 547px;
  position: relative;
  z-index: 0
}

.html-slider .slider__item {
  min-height: 547px
}

.html-slider__pager-wrapper {
  height: 188px;
  overflow: hidden;
  position: relative;
  top: -65px;
  z-index: 1
}

.html-slider__pager-wrapper>:first-child {
  float: left;
  position: relative;
  top: 85px;
  width: 288px
}

.html-slider__pager-wrapper>:last-child {
  left: 370px;
  position: absolute;
  width: 938px
}

.html-slider__pager .slider__item>div {
  padding: 13px
}

.html-slider__pager .slider__item>div>div {
  background-color: #fff;
  background-position: 100% 100%;
  background-size: 150px auto;
  border-radius: 10px;
  box-shadow: 0 7px 15px rgba(0, 0, 0, .05);
  cursor: pointer;
  height: 157px;
  overflow: hidden;
  padding-left: 35px;
  padding-top: 37px;
  position: relative;
  top: 0;
  transition: all .1s ease-in;
  width: 285px
}

.html-slider__pager .slider__item>div>div:hover {
  box-shadow: 0 7px 35px rgba(0, 0, 0, .05);
  top: -5px;
  transition: all .1s ease-in
}

.html-slider__pager .dummy-current .slider__item>div>div,
.html-slider__pager .slick-current .slider__item>div>div {
  background-color: #e8edf1;
  cursor: default
}

.html-slider__pager .dummy-current .slider__item>div>div:hover,
.html-slider__pager .slick-current .slider__item>div>div:hover {
  box-shadow: 0 7px 15px rgba(0, 0, 0, .05);
  top: 0;
  transition: all .1s ease-in
}

.html-slider__pager_long .slider__item>div>div {
  width: 445px
}

.html-slider__navigator {

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase
}

.html-slider__navigator>div {
  text-align: center
}

.html-slider__navigator>div>div {
  height: 46px;
  position: relative;
  width: 168px
}

.html-slider__navigator__counter {
  color: #c2c3c5;

  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  position: relative;
  text-align: center;
  top: 6px;
  z-index: 0
}

.html-slider__navigator__counter span:first-child {
  font-size: 20px;
  position: relative;
  top: -1px
}

.html-slider__navigator__counter span:last-child {
  font-size: 14px;
  position: relative;
  top: -2px
}

.html-slider__navigator__button {
  background-color: transparent;
  border-radius: 50%;
  height: 46px;
  position: absolute;
  top: 0;
  transition: background-color .2s ease-out;
  width: 46px;
  z-index: 1
}

.html-slider__navigator__button[disabled] {
  opacity: .3
}

.html-slider__navigator__button svg {
  height: 13px;
  transition: fill .2s ease-in;
  width: 17px
}

.html-slider__navigator__button:hover {
  background-color: #f1f5f6;
  transition: background-color .2s ease-in
}

.html-slider__navigator__button:hover svg path {
  fill: #959ba5;
  transition: fill .2s ease-in
}

.html-slider__navigator__button.prev {
  left: 0;
  transform: rotateY(180deg)
}

.html-slider__navigator__button.next {
  right: 0
}

.html-slider__navigator__link {
  position: relative;
  top: 11px
}

@media screen and (max-width:1024px) {
  .html-slider__pager-wrapper>:last-child {
    left: 355px
  }
}

@media screen and (max-width:1023px) {
  .html-slider__pager-wrapper>:last-child {
    left: 320px
  }
}

@media screen and (max-width:767px) {
  .html-slider {
    height: 217px
  }

  .html-slider .slider__item {
    min-height: 217px
  }

  .html-slider__pager-wrapper {
    height: 126px;
    max-width: inherit !important;
    max-width: none !important;
    top: 0;
    width: 100%
  }

  .html-slider__pager-wrapper>:first-child {
    float: inherit !important;
    float: none !important;
    top: 30px;
    width: 100%
  }

  .html-slider__pager-wrapper>:last-child {
    display: none
  }

  .slider-wrapper-html .slick-dots {
    display: none !important
  }
}

.image-slider,
.slider-wrapper-image {
  position: relative
}

.image-slider {
  z-index: 0
}

.image-slider__navigator {
  background-color: #01d8cd;
  border-radius: 50%;
  box-shadow: 0 10px 36px 4px rgba(70, 81, 86, .17);
  left: -61px;
  position: absolute;
  top: 327px;
  width: 121px
}

.image-slider__navigator__button {
  background-color: #00beb4;
  height: 60px;
  transition: background-color .2s ease-out;
  width: 60px
}

.image-slider__navigator__button>svg path {
  fill: #fff
}

.image-slider__navigator__button:hover {
  background-color: #00b1a8;
  transition: background-color .2s ease-in
}

.image-slider__navigator__button.next {
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
  float: right
}

.image-slider__navigator__button.prev {
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  float: left
}

.image-slider__navigator__button.prev>svg {
  transform: rotate(180deg)
}

.slider__item__image {
  border-radius: 10px
}

.slider__item__image-description {
  color: #989ca6;
  font-size: 14px;
  line-height: 28px;
  margin-left: 30px;
  margin-top: 21px
}

@media screen and (max-width:1359px) {
  .image-slider__navigator {
    left: -53px;
    width: 105px
  }

  .image-slider__navigator__button {
    height: 52px;
    width: 52px
  }
}

@media screen and (max-width:767px) {
  .image-slider__navigator {
    bottom: -20px;
    left: 50%;
    margin-left: -50px;
    width: 100px
  }

  .image-slider__navigator__button {
    height: 49px;
    width: 49px
  }
}

.slider-wrapper-employee {
  background-color: #eef3f6;
  background-image: url(/build/i/c539136ecd89e17c0137.png);
  background-position: 100%;
  background-size: auto 100%;
  height: 547px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1920px
}

.slider-employee__manual-title {
  margin-bottom: 60px
}

.slider-employee__circle {
  height: 547px;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: 0
}

.slider-employee__circle>.row,
.slider-employee__circle>.row_short {
  position: relative
}

.slider-employee__circle>.row:before,
.slider-employee__circle>.row_short:before {
  border: 70px solid #fff;
  border-radius: 50%;
  content: "";
  display: block;
  height: 628px;
  opacity: .5;
  position: absolute;
  right: 0;
  top: -15px;
  width: 628px
}

.slider-employee__slider_pager__fio {
  font-size: 14px;
  line-height: 20px
}

.slider-employee__slider_pager__fio>span {

  font-size: 16px;
  font-style: normal;
  font-weight: 500
}

.slider-employee__slider_pager__fio__image {
  border-radius: 0;
  bottom: 0;
  overflow: visible;
  position: absolute;
  right: 0
}

.slider-employee__slide {
  height: 547px;
  overflow: hidden;
  position: relative
}

.slider-employee__slide .row,
.slider-employee__slide .row_short {
  padding-top: 64px;
  position: relative
}

.slider-employee__slide__fio {
  color: #3c4143;

  font-size: 36px;
  font-style: normal;
  font-weight: 500
}

.slider-employee__slide__profession {
  color: #959ba5;
  font-size: 14px;
  line-height: 24px;
  margin-top: 9px;
  max-width: 500px
}

.slider-employee__slide__feature {
  background-position: 0;
  color: #5f6877;
  font-size: 14px;
  line-height: 20px;
  margin-top: 24px;
  max-width: 320px;
  min-height: 60px;
  padding-left: 80px;
  background-repeat: no-repeat;
}

.slider-employee__slide__teaser {
  color: #5f6877;
  font-size: 16px;
  line-height: 26px;
  margin-top: 47px;
  max-width: 490px
}

.slider-employee__slide__buttons {
  display: flex;
  margin-top: 54px
}

.slider-employee__slide__button {
  margin-right: 20px
}

.slider-employee__slide__video-link a {
  align-items: center;
  color: #a5a5a5;
  display: flex;

  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 22px
}

.slider-employee__slide__video-link a button {
  margin-right: 15px
}

.slider-employee__slide__image {
  bottom: 0;
  height: 547px;
  position: absolute;
  right: 0;
  width: 629px;
  z-index: -1
}

@media screen and (max-width:1024px) {

  .slider-employee__circle>.row:after,
  .slider-employee__circle>.row_short:after,
  .slider-employee__slide__image {
    right: -70px
  }

  .slider-employee__slide>.row,
  .slider-employee__slide>.row_short {
    padding-top: 49px
  }

  .slider-employee__slide__fio {
    font-size: 30px;
    max-width: 400px
  }
}

@media screen and (max-width:1023px) {
  .slider-employee__slide__image {
    height: 491px !important;
    right: -120px;
    width: 565px !important
  }

  .slider-employee__slide__teaser {
    margin-top: 47px;
    max-width: 396px
  }

  .slider-employee__circle>.row:after,
  .slider-employee__circle>.row_short:after {
    right: -180px
  }

  .slider-employee__circle>.row:before,
  .slider-employee__circle>.row_short:before {
    right: -170px;
    top: 15px
  }

  .slider-wrapper-employee {
    background-image: none
  }
}

@media screen and (max-width:767px) {

  .slider-employee__slide__feature,
  .slider-employee__slide__profession,
  .slider-employee__slide__teaser {
    display: none
  }

  .slider-employee__circle,
  .slider-employee__slide,
  .slider-wrapper-employee {
    height: 217px
  }

  .slider-employee__slide__image {
    height: 216px !important;
    right: -35px;
    width: 248px !important
  }

  .slider-employee__circle>.row:before,
  .slider-employee__circle>.row_short:before {
    border: 25px solid #fff;
    height: 248px;
    right: -35px;
    width: 248px
  }

  .slider-employee__slide__fio {
    font-size: 16px;
    width: 160px
  }

  .slider-employee__slide>.row,
  .slider-employee__slide>.row_short {
    padding-top: 38px
  }

  .slider-employee__slide__buttons {
    margin-top: 45px
  }
}

@media screen and (max-width:604px) {
  .slider-employee__slide__video-link a div {
    display: none
  }
}

@media screen and (max-width:450px) {
  .slider-employee__slide__video-link {
    position: absolute;
    right: 0
  }
}

.slider-wrapper-star {
  color: #fff
}

.slider-star {
  height: 702px
}

.slider-star_second {
  color: #3c4143;
  display: none;
  height: 340px;
  position: relative;
  text-align: center
}

.slider-star_second .slider__item {
  height: 340px
}

.slider-star_second__slide__name {

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin-top: 46px
}

.slider-star_second__slide__info {
  color: #989ca6;
  font-size: 14px;
  line-height: 26px;
  margin-top: 19px;
  max-width: 255px
}

.slider-star_second__slide__quote {
  color: #3c4143;
  font-size: 16px;
  line-height: 28px;
  margin-top: 33px;
  max-width: 272px
}

.slider-star__logo {
  background-color: #38b9a2;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: 0
}

.slider-star__logo>.row,
.slider-star__logo>.row_short {
  display: block;
  height: 702px;
  position: relative
}

.slider-star__logo>.row:before,
.slider-star__logo>.row_short:before {
  background-image: url(/build/i/f4a10dd969186c8545fa.svg);
  bottom: 0;
  content: "";
  display: block;
  height: 505px;
  left: 42px;
  position: absolute;
  width: 488px;
  z-index: 1
}

.slider-star__logo>.row:after,
.slider-star__logo>.row_short:after {
  background-image: radial-gradient(circle 292px at center, #fbf4b9 0, hsla(54, 89%, 85%, 0) 100%);
  bottom: 0;
  content: "";
  display: block;
  height: 584px;
  left: -20px;
  opacity: .8;
  position: absolute;
  width: 618px;
  z-index: 0
}

.slider-star__title {
  display: inline-block;

  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  left: 380px;
  position: relative;
  top: 131px
}

.slider-star__slide__name {

  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px
}

.slider-star__slide__info {
  font-size: 14px;
  line-height: 26px;
  margin-top: 16px
}

.slider-star__slide__quote {
  font-size: 18px;
  line-height: 30px;
  margin-top: 30px
}

.slider-star__slide__video-review {
  bottom: 130px;
  position: absolute
}

.slider-star__pager .slider__item>div>div {
  background-color: #34a994
}

.slider-star__pager .dummy-current .slider__item>div>div,
.slider-star__pager .slick-current .slider__item>div>div {
  background-color: #3f9586
}

.slider-star__slide {
  height: 702px;
  overflow: hidden;
  position: relative
}

.slider-star__slide .row,
.slider-star__slide .row_short {
  padding-left: 487px;
  padding-top: 272px;
  position: relative
}

.slider-star__slider_pager__fio {
  color: #fff;

  font-size: 18px;
  font-style: normal;
  font-weight: 500
}

.slider-star__slider_pager__fio__image {
  border-radius: 0;
  bottom: 0;
  overflow: visible;
  position: absolute;
  right: 0
}

.slider-star__slider_pager__short-info {
  color: #e0e0e0;
  font-size: 14px;
  margin-top: 16px
}

.slider-star__slide__image {
  bottom: 0;
  height: 572px;
  left: 70px;
  position: absolute;
  width: 392px;
  z-index: -1
}

@media screen and (max-width:1320px) {
  .slider-star__pager-wrapper>:last-child {
    left: 355px
  }

  .slider-star,
  .slider-star__slide {
    height: 655px
  }

  .slider-star__slide>.row,
  .slider-star__slide>.row_short {
    padding-left: 512px;
    padding-top: 223px
  }

  .slider-star__logo>.row,
  .slider-star__logo>.row_short {
    height: 655px
  }

  .slider-star__logo>.row:before,
  .slider-star__logo>.row_short:before {
    left: 12px
  }

  .slider-star__logo>.row:after,
  .slider-star__logo>.row_short:after {
    left: -52px
  }

  .slider-star__slide__image {
    left: 43px
  }

  .slider-star__title {
    font-size: 44px;
    left: 249px;
    top: 105px
  }

  .slider-star__slide__video-review {
    bottom: 110px
  }
}

@media screen and (max-width:1023px) {

  .slider-star__logo>.row,
  .slider-star__logo>.row_short {
    height: 655px
  }

  .slider-star__logo>.row:before,
  .slider-star__logo>.row_short:before {
    left: -135px
  }

  .slider-star__logo>.row:after,
  .slider-star__logo>.row_short:after {
    left: -200px
  }

  .slider-star__slide__image {
    left: -100px
  }

  .slider-star__slide>.row,
  .slider-star__slide>.row_short {
    padding-left: 274px;
    padding-top: 180px
  }

  .slider-star__title {
    left: 151px;
    top: 80px
  }
}

@media screen and (max-width:767px) {

  .slider-star__slide__info,
  .slider-star__slide__name,
  .slider-star__slide__quote,
  .slider-star__title {
    display: none
  }

  .slider-star__slide__content {
    color: #3c4143;
    height: 310px;
    position: absolute;
    top: -272px
  }

  .slider-star,
  .slider-star__slide {
    height: 272px
  }

  .slider-star__slide>.row,
  .slider-star__slide>.row_short {
    padding-left: 15px;
    padding-top: 0
  }

  .slider-star__logo>.row,
  .slider-star__logo>.row_short {
    height: 272px
  }

  .slider-star__logo>.row:before,
  .slider-star__logo>.row_short:before {
    height: 265px;
    left: 50%;
    margin-left: -140px;
    width: 280px
  }

  .slider-star__logo>.row:after,
  .slider-star__logo>.row_short:after {
    background-image: radial-gradient(circle 136px at center, #fbf4b9 0, hsla(54, 89%, 85%, 0) 100%);
    height: 295px;
    left: 50%;
    margin-left: -156px;
    width: 312px
  }

  .slider-star__slide__image {
    height: 298px !important;
    left: 50%;
    margin-left: -102px;
    width: 204px !important
  }

  .slider-star__slide__video-review {
    bottom: 100px;
    left: 50%;
    margin-left: -30px;
    padding-left: 0;
    padding-top: 0;
    width: 60px
  }

  .slider-star__slide__video-review .link-with-icon__title {
    display: none
  }

  .slider-star_second {
    display: block
  }
}

.slider-wrapper-video {
  background-color: #f4f7f8;
  padding-bottom: 56px;
  padding-top: 54px
}

.video-slider .slick-list {
  padding-top: 30px !important
}

.video-slider .slick-slide {
  transform: scale(1);
  transition: all .2s ease-out
}

.video-slider .slick-slide.dummy-current,
.video-slider .slick-slide.slick-current {
  transform: scale(1.1459);
  transition: all .2s ease-in
}

.video-slider .slider__item {
  margin-bottom: 13px
}

.employee-works-slider .slick-slide,
.employee-works-slider .slick-slide.dummy-current,
.employee-works-slider .slick-slide.slick-current {
  transform: none
}

.video-slider__slide {
  background-size: cover;
  border-radius: 10px;
  box-shadow: 0 10px 29px rgba(70, 81, 86, .18);
  height: 290px;
  margin: 22px;
  overflow: hidden;
  position: relative;
  width: 390px
}

.video-slider__slide>a:not(.corner_play) {
  display: inline-block;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 0
}

.video-slider__slide_small {
  box-shadow: 0 10px 16px rgba(70, 81, 86, .18);
  margin: 9px
}

.video-slider__navigator {

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-top: 4px;
  text-transform: uppercase
}

.video-slider__navigator>div {
  text-align: center
}

.video-slider__navigator>div>div {
  height: 46px;
  position: relative;
  width: 168px
}

.video-slider__navigator__counter {
  color: #c2c3c5;

  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  position: relative;
  text-align: center;
  top: 6px;
  z-index: 0
}

.video-slider__navigator__counter span:first-child {
  font-size: 20px;
  position: relative;
  top: -1px
}

.video-slider__navigator__counter span:last-child {
  font-size: 14px;
  position: relative;
  top: -2px
}

.video-slider__navigator__button {
  background-color: transparent;
  border-radius: 50%;
  height: 46px;
  position: absolute;
  top: 0;
  transition: background-color .2s ease-out;
  width: 46px;
  z-index: 1
}

.video-slider__navigator__button svg {
  height: 13px;
  transition: fill .2s ease-in;
  width: 17px
}

.video-slider__navigator__button:hover {
  transition: background-color .2s ease-in
}

.video-slider__navigator__button:hover svg path {
  fill: #959ba5;
  transition: fill .2s ease-in
}

.video-slider__navigator__button.prev {
  left: 0;
  transform: rotateY(180deg)
}

.video-slider__navigator__button.next {
  right: 0
}

.video-slider__title {
  color: #333638;

  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 46px;
  margin-top: 53px;
  text-align: center
}

@media screen and (max-width:767px) {

  .video-slider .slick-slide.dummy-current,
  .video-slider .slick-slide.slick-current {
    transform: scale(1)
  }

  .video-slider__slide {
    background-position: 50%;
    height: 216px;
    width: 290px
  }

  .video-slider__title {
    font-size: 30px;
    margin-bottom: 0;
    margin-top: 33px
  }
}

@media screen and (max-width:380px) {
  .video-slider__slide_small {
    height: 190px;
    width: 240px
  }
}

.news-slider__title {
  color: #333638;

  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  text-align: center
}

.news-slider__title>div {
  display: inline-block;
  padding-left: 14px;
  padding-right: 14px;
  position: relative
}

.news-slider__title>div:after,
.news-slider__title>div:before {
  background-image: url(/build/i/05057e7a60195161dca4.svg);
  content: "";
  height: 45px;
  left: 0;
  margin-top: -22px;
  position: absolute;
  top: 50%;
  width: 18px
}

.news-slider__title>div:after {
  left: auto;
  right: 0;
  transform: rotate(180deg);
  transform-origin: center
}

.news-slider__title i {
  display: inline-block;
  transform: translateY(-2px)
}

.news-slider {
  margin-top: 20px;
  max-height: 332px;
  overflow: hidden
}

.news-slider.slick-initialized {
  max-height: none;
  overflow: visible
}

.news-slider .slick-list {
  padding-top: 30px !important
}

.news-slider .slick-slide {
  opacity: .8;
  transform: scale(1);
  transition: all .2s ease-out
}

.news-slider .slick-slide.dummy-current,
.news-slider .slick-slide.slick-current {
  opacity: 1;
  transform: scale(1.1459);
  transition: all .2s ease-in
}

.news-slider .slick-slide.dummy-current .news-slider__slide__content,
.news-slider .slick-slide.slick-current .news-slider__slide__content {
  min-height: 124px
}

.news-slider .slick-slide.dummy-current .news-slider__slide__content:before,
.news-slider .slick-slide.slick-current .news-slider__slide__content:before {
  background-color: #cedee9;
  border-radius: 50%
}

.news-slider .slick-slide.dummy-current .news-slider__slide__title,
.news-slider .slick-slide.slick-current .news-slider__slide__title {
  color: #333638
}

.news-slider .slick-slide.dummy-current .news-slider__slide__button,
.news-slider .slick-slide.slick-current .news-slider__slide__button {
  height: auto;
  opacity: 1;
  transform: scaleY(1);
  transition: opacity .1s ease-in .1s, tramsform .1s ease-in .1s, height .1s ease-in .1s
}

.news-slider .slider__item {
  margin-bottom: 13px
}

.news-slider__slide {
  background-size: cover;
  border-radius: 10px;
  box-shadow: 0 10px 29px rgba(70, 81, 86, .18);
  height: 290px;
  margin: 22px;
  overflow: hidden;
  position: relative;
  width: 390px
}

.news-slider__slide:before {
  border: 2px solid #fff;
  border-radius: 10px;
  content: "";
  height: calc(100% - 14px);
  left: 7px;
  opacity: .75;
  pointer-events: none;
  position: absolute;
  top: 7px;
  width: calc(100% - 14px);
  z-index: 1
}

.news-slider__slide .progressive-image-bg {
  z-index: 0
}

.news-slider__slide .news-slider__slide__button {
  height: 0;
  opacity: 0;
  text-align: center;
  transform: scaleY(0);
  transition: opacity .1s ease-out .1s, tramsform .1s ease-out .1s, height .1s ease-out .1s
}

.news-slider__slide .news-slider__slide__button .button,
.news-slider__slide .news-slider__slide__button .slide-tab__label.active,
.news-slider__slide .news-slider__slide__button .tabs__labels.tabs__labels_buttons .tab__label.active,
.tabs__labels.tabs__labels_buttons .news-slider__slide .news-slider__slide__button .tab__label.active {
  margin-top: 12px
}

.news-slider__slide__content {
  bottom: 0;
  left: 0;
  min-height: 66px;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: 2
}

.news-slider__slide__content:before {
  background-color: #f3f8fa;
  box-shadow: 0 0 1px hsla(0, 0%, 100%, .95);
  content: "";
  height: calc(100% + 100px);
  left: 50%;
  opacity: .95;
  outline: 1px solid transparent;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: calc(100% + 300px);
  z-index: -1
}

.news-slider__slide__title {
  color: #5f6877;
  font-size: 17px;
  line-height: 20px;
  text-align: center
}

.news-slider__navigator,
.news-slider__slide__title {

  font-style: normal;
  font-weight: 500
}

.news-slider__navigator {
  font-size: 14px;
  margin-top: 4px;
  text-transform: uppercase
}

.news-slider__navigator>div {
  text-align: center
}

.news-slider__navigator>div>div {
  height: 46px;
  position: relative;
  width: 168px
}

.news-slider__navigator__counter {
  color: #c2c3c5;

  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  position: relative;
  text-align: center;
  top: 6px;
  z-index: 0
}

.news-slider__navigator__counter span:first-child {
  font-size: 20px;
  position: relative;
  top: -1px
}

.news-slider__navigator__counter span:last-child {
  font-size: 14px;
  position: relative;
  top: -2px
}

.news-slider__navigator__button {
  background-color: transparent;
  border-radius: 50%;
  height: 46px;
  position: absolute;
  top: 0;
  transition: background-color .2s ease-out;
  width: 46px;
  z-index: 1
}

.news-slider__navigator__button svg {
  height: 13px;
  transition: fill .2s ease-in;
  width: 17px
}

.news-slider__navigator__button:hover {
  transition: background-color .2s ease-in
}

.news-slider__navigator__button:hover svg path {
  fill: #959ba5;
  transition: fill .2s ease-in
}

.news-slider__navigator__button.prev {
  left: 0;
  transform: rotateY(180deg)
}

.news-slider__navigator__button.next {
  right: 0
}

@media screen and (max-width:1023px) {
  .news-slider {
    margin-top: 0
  }
}

@media screen and (max-width:767px) {
  .news-slider .slick-list {
    padding-top: 15px !important
  }

  .news-slider .slick-slide .news-slider__slide__content {
    min-height: 150px
  }

  .news-slider .slick-slide .news-slider__slide__content:before {
    background-color: #cedee9;
    border-radius: 50%;
    opacity: 1
  }

  .news-slider .slick-slide.dummy-current,
  .news-slider .slick-slide.slick-current {
    transform: scale(1)
  }

  .news-slider .slick-slide.dummy-current .news-slider__slide__content,
  .news-slider .slick-slide.slick-current .news-slider__slide__content {
    min-height: 150px
  }

  .news-slider .slick-slide.dummy-current .news-slider__slide__content:before,
  .news-slider .slick-slide.slick-current .news-slider__slide__content:before {
    background-color: #cedee9;
    opacity: 1
  }

  .news-slider__slide {
    background-position: 50%;
    height: 367px;
    width: 290px
  }

  .news-slider__slide:before {
    z-index: 3
  }

  .news-slider__slide .progressive-image-bg {
    height: 240px
  }

  .news-slider__slide .news-slider__slide__button {
    height: auto;
    opacity: 1;
    transform: scaleY(1)
  }

  .news-slider__slide .news-slider__slide__button .button,
  .news-slider__slide .news-slider__slide__button .slide-tab__label.active,
  .news-slider__slide .news-slider__slide__button .tabs__labels.tabs__labels_buttons .tab__label.active,
  .tabs__labels.tabs__labels_buttons .news-slider__slide .news-slider__slide__button .tab__label.active {
    font-size: 14px;
    text-transform: uppercase
  }

  .news-slider__slide__title {
    padding-left: 15px;
    padding-right: 15px
  }

  .news-slider__title {
    font-size: 30px;
    margin-bottom: 0
  }
}

.slider-wrapper-cost-of {
  margin-top: 60px
}

.slider-wrapper-cost-of .slick-slide {
  overflow: hidden
}

.slider-cost-of {
  background-color: #f7f9fb;
  height: 495px
}

.attainment-image-slider-wrapper_allon4 .slider-cost-of.attainment-image-slider,
.offer_standard .slider-cost-of.offer__card:before,
.slider-cost-of.appointment__inner,
.slider-cost-of.bg_dots,
.slider-cost-of.cards-with-text:before,
.slider-cost-of.director-quote__right:before,
.slider-cost-of.is-suitable.bg_dots-half:before,
.slider-cost-of.main-page-header__badge:before,
.slider-cost-of.payment-form,
.slider-cost-of.with-image-slider-wrapper_dots,
.slider-cost-of.works-slider__item,
.through-block-short .slider-cost-of.through-block_hold-prices {
  background-size: 0
}

.slider-cost-of .slider__item {
  min-height: 495px
}

.slider-cost-of_single {
  overflow: hidden
}

.slider-cost-of__slide {
  position: relative
}

.attainment-image-slider-wrapper_allon4 .slider-cost-of__slide .attainment-image-slider,
.offer_standard .slider-cost-of__slide .offer__card:before,
.slider-cost-of__slide .appointment__inner,
.slider-cost-of__slide .attainment-image-slider-wrapper_allon4 .attainment-image-slider,
.slider-cost-of__slide .bg_dots,
.slider-cost-of__slide .cards-with-text:before,
.slider-cost-of__slide .director-quote__right:before,
.slider-cost-of__slide .is-suitable.bg_dots-half:before,
.slider-cost-of__slide .main-page-header__badge:before,
.slider-cost-of__slide .offer_standard .offer__card:before,
.slider-cost-of__slide .payment-form,
.slider-cost-of__slide .through-block-short .through-block_hold-prices,
.slider-cost-of__slide .with-image-slider-wrapper_dots,
.slider-cost-of__slide .works-slider__item,
.through-block-short .slider-cost-of__slide .through-block_hold-prices {
  height: 547px;
  position: absolute;
  width: 1220px;
  z-index: 0
}

.slider-cost-of__slide .row,
.slider-cost-of__slide .row_short {
  display: flex;
  position: relative;
  z-index: 1
}

.slider-cost-of__slide .row>:first-child,
.slider-cost-of__slide .row_short>:first-child {
  flex-basis: 288px;
  margin-right: 24px
}

.slider-cost-of__slide .row>:nth-child(2),
.slider-cost-of__slide .row_short>:nth-child(2) {
  flex-basis: 600px;
  margin-right: 24px
}

.slider-cost-of__slide .row>:nth-child(3),
.slider-cost-of__slide .row_short>:nth-child(3) {
  flex-basis: 288px
}

.slider-cost-of__pager-wrapper {
  top: -50px
}

.slider-cost-of__pager-wrapper>:first-child {
  top: 70px
}

.slider-cost-of__pager .slider__item>div>div {
  height: auto;
  padding-bottom: 30px;
  padding-right: 75px;
  padding-top: 30px
}

.slider-cost-of__slider_pager__on-sale:after {
  background-color: #e9d237;
  background-image: url(/build/i/5aeb7ae376f6a70ece1a.svg);
  background-position: 50%;
  border-radius: 0 0 10px 10px;
  content: "";
  display: block;
  height: 38px;
  position: absolute;
  right: 24px;
  top: 0;
  width: 36px
}

.slider-cost-of__slider_pager__info .title {

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 3px
}

.slider-cost-of__slider_pager__prices {
  bottom: 29px;
  font-size: 14px;
  margin-top: 15px
}

.slider-cost-of__slider_pager__prices .old {
  color: #a2abb5;
  font-size: 14px;
  margin-right: 18px;
  text-decoration: line-through
}

.slider-cost-of__slider_pager__prices .new {
  color: #5f6877;
  font-size: 16px
}

.slider-cost-of__slide__image {
  margin-top: 60px;
  position: relative;
  width: 288px;
  z-index: 1
}

.slider-cost-of__slide__image .progressive-image-wrapper {
  z-index: 1
}

.slider-cost-of__slide__image:after {
  background-image: radial-gradient(circle 311px at center, #fff 0, hsla(0, 0%, 100%, 0) 100%);
  background-position: 50%;
  content: "";
  display: block;
  height: 618px;
  left: 50%;
  margin-left: -309px;
  margin-top: -309px;
  position: absolute;
  top: 50%;
  width: 618px;
  z-index: 0
}

.slider-cost-of__slide__description {
  padding-top: 82px;
  position: relative;
  z-index: 1
}

.slider-cost-of__slide__description .rate {
  margin-right: 15px
}

.slider-cost-of__slide__description .button,
.slider-cost-of__slide__description .slide-tab__label.active,
.slider-cost-of__slide__description .tabs__labels.tabs__labels_buttons .tab__label.active,
.tabs__labels.tabs__labels_buttons .slider-cost-of__slide__description .tab__label.active {
  bottom: 20px;
  position: absolute
}

.slider-cost-of__slide__rate {
  color: #898989;
  font-size: 14px;
  line-height: 20px
}

.slider-cost-of__slide__rate__text {
  position: relative;
  top: 3px
}

.slider-cost-of__slide__title {
  color: #3c4143;
  display: inline-block;

  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  margin-top: 20px
}

.slider-cost-of__slide__title__description {
  color: #3c4143;
  font-size: 20px;
  line-height: 28px;
  margin-top: 2px
}

.slider-cost-of__slide__prices {
  color: #5f6877;
  line-height: 26px;
  margin-top: 25px
}

.slider-cost-of__slide__prices .work-info__price__tooltip {
  position: relative;
  top: 1px
}

.slider-cost-of__slide__prices>[data-js=tooltip] {
  display: inline-block
}

.slider-cost-of__slide__prices .old {
  color: #b4bbc8;
  font-size: 16px;
  margin-right: 18px;
  position: relative;
  top: -3px
}

.slider-cost-of__slide__prices .old:after {
  background-color: #b4bbc8;
  content: "";
  display: block;
  height: 2px;
  left: 0;
  position: absolute;
  top: 9px;
  width: 100%
}

.slider-cost-of__slide__prices .new {
  font-size: 26px
}

.slider-cost-of__slide__prices__end {
  background-color: #fdfa9b;
  border-radius: 13px;
  color: #3c4143;
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 23px;
  padding: 6px 14px
}

.slider-cost-of__slide__info {
  padding-top: 90px;
  position: relative;
  z-index: 1
}

.slider-cost-of__slide__info .minus-list,
.slider-cost-of__slide__info .plus-list {
  margin-bottom: 28px
}

.slider-cost-of__slide__info p {
  color: #898989;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 28px
}

.slider-cost-of__slide__info_mobile {
  display: none
}

.slider-cost-of__slide__info__tooltip .prices-slider__item__list {
  display: block
}

.slider-cost-of__slide__info__tooltip .prices-slider__item__list .minus-list>li br,
.slider-cost-of__slide__info__tooltip .prices-slider__item__list .plus-list>li br {
  display: none
}

.slider-cost-of__slide__info__tooltip .prices-slider__item__list .minus-list:last-child>span,
.slider-cost-of__slide__info__tooltip .prices-slider__item__list .plus-list:last-child>span {
  margin-top: 7px
}

.slider-cost-of__slide__info__guard {
  color: #3c4143;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 37px;
  padding-left: 24px;
  position: relative
}

.slider-cost-of__slide__info__guard:before {
  background-image: url(/build/i/477f220b84930966526f.svg);
  content: "";
  display: block;
  height: 16px;
  left: -3px;
  position: absolute;
  top: 1px;
  width: 16px
}

.slider-cost-of__slide__info__link {
  display: inline-block
}

.slider-cost-of__slide__info__link span {

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-transform: uppercase
}

@media screen and (max-width:1359px) {
  .slider-cost-of__slide__image {
    margin-left: 90px;
    width: 230px
  }

  .slider-cost-of__slide__image .progressive-image-wrapper {
    left: -30px
  }

  .slider-cost-of__slide__title__description {
    font-size: 18px
  }

  .slider-cost-of__slide .row>:first-child,
  .slider-cost-of__slide .row_short>:first-child {
    flex-basis: 230px;
    margin-right: 24px
  }

  .slider-cost-of__slide .row>:nth-child(2),
  .slider-cost-of__slide .row_short>:nth-child(2) {
    margin-right: 24px
  }

  .slider-cost-of__slide .row>:nth-child(3),
  .slider-cost-of__slide .row_short>:nth-child(3) {
    flex-basis: 230px
  }

  .slider-cost-of__slide__info {
    display: none;
    margin-top: 5px;
    padding-top: 0
  }

  .slider-cost-of__slide__info_mobile {
    display: block
  }

  .slider-cost-of__slide__description {
    margin-left: 60px
  }
}

@media screen and (max-width:1023px) {

  .slider-cost-of__slide .row>:nth-child(2),
  .slider-cost-of__slide .row_short>:nth-child(2) {
    margin-right: 0
  }

  .slider-cost-of__slide__image {
    margin-left: 0;
    width: 190px
  }

  .slider-cost-of__slide__image .progressive-image-wrapper {
    left: -60px
  }

  .slider-cost-of__slide__description {
    margin-left: 20px
  }

  .service__cost-of-wrapper+.info-block-wrapper+.divider {
    background-color: transparent !important;
    margin-bottom: 40px !important;
    margin-top: 40px !important
  }
}

@media screen and (max-width:767px) {
  .slider-cost-of {
    height: auto
  }

  .attainment-image-slider-wrapper_allon4 .slider-cost-of.attainment-image-slider,
  .offer_standard .slider-cost-of.offer__card:before,
  .slider-cost-of.appointment__inner,
  .slider-cost-of.bg_dots,
  .slider-cost-of.cards-with-text:before,
  .slider-cost-of.director-quote__right:before,
  .slider-cost-of.is-suitable.bg_dots-half:before,
  .slider-cost-of.main-page-header__badge:before,
  .slider-cost-of.payment-form,
  .slider-cost-of.with-image-slider-wrapper_dots,
  .slider-cost-of.works-slider__item,
  .through-block-short .slider-cost-of.through-block_hold-prices {
    background-size: 21px 26px
  }

  .slider-cost-of__slide__image {
    display: none
  }

  .slider-cost-of__slide__description {
    margin-left: auto;
    padding-top: 35px;
    text-align: center
  }

  .slider-cost-of__slide__description .button,
  .slider-cost-of__slide__description .slide-tab__label.active,
  .slider-cost-of__slide__description .tabs__labels.tabs__labels_buttons .tab__label.active,
  .tabs__labels.tabs__labels_buttons .slider-cost-of__slide__description .tab__label.active {
    bottom: 0;
    margin-top: 50px;
    position: relative
  }

  .slider-cost-of__slide__rate .rate {
    display: inline-block;
    margin-right: 0
  }

  .slider-cost-of__slide__rate .rate svg {
    height: 12px;
    width: 12px
  }

  .slider-cost-of__slide__rate__text {
    display: block
  }

  .slider-cost-of__slide__title {
    font-size: 24px;
    margin-top: 30px
  }

  .slider-cost-of__slide__title__description {
    font-size: 16px;
    line-height: 24px;
    margin-top: 6px
  }

  .slider-cost-of__slide__prices {
    margin-top: 25px
  }

  .slider-cost-of__slide__prices__end {
    font-size: 15px;
    padding-bottom: 10px;
    padding-top: 10px
  }

  .slider-cost-of__slide {
    padding-bottom: 38px
  }

  .attainment-image-slider-wrapper_allon4 .slider-cost-of__slide .attainment-image-slider,
  .offer_standard .slider-cost-of__slide .offer__card:before,
  .slider-cost-of__slide .appointment__inner,
  .slider-cost-of__slide .attainment-image-slider-wrapper_allon4 .attainment-image-slider,
  .slider-cost-of__slide .bg_dots,
  .slider-cost-of__slide .cards-with-text:before,
  .slider-cost-of__slide .director-quote__right:before,
  .slider-cost-of__slide .is-suitable.bg_dots-half:before,
  .slider-cost-of__slide .main-page-header__badge:before,
  .slider-cost-of__slide .offer_standard .offer__card:before,
  .slider-cost-of__slide .payment-form,
  .slider-cost-of__slide .through-block-short .through-block_hold-prices,
  .slider-cost-of__slide .with-image-slider-wrapper_dots,
  .slider-cost-of__slide .works-slider__item,
  .through-block-short .slider-cost-of__slide .through-block_hold-prices {
    display: none
  }

  .slider-cost-of__slide .row>:nth-child(2),
  .slider-cost-of__slide .row_short>:nth-child(2) {
    flex-basis: 100%
  }

  .slider-cost-of .slider__item {
    min-height: 448px
  }
}

.mfp-3d-unfold .mfp-content {
  perspective: 2000px
}

.mfp-3d-unfold .mfp-figure,
.mfp-3d-unfold .mfp-with-anim {
  opacity: 0;
  transform: rotateY(-60deg);
  transform-style: preserve-3d;
  transition: all .3s ease-in-out
}

.mfp-3d-unfold.mfp-bg {
  opacity: 0;
  transition: all .5s
}

.mfp-3d-unfold.mfp-ready .mfp-figure,
.mfp-3d-unfold.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: rotateY(0deg)
}

.mfp-3d-unfold.mfp-ready.mfp-bg {
  opacity: .8
}

.mfp-3d-unfold.mfp-removing .mfp-figure,
.mfp-3d-unfold.mfp-removing .mfp-with-anim {
  opacity: 0;
  transform: rotateY(60deg)
}

.mfp-3d-unfold.mfp-removing.mfp-bg {
  opacity: 0
}

.mfp-fade.mfp-bg {
  opacity: 0;
  transition: all .15s ease-out
}

.mfp-fade.mfp-bg.mfp-ready {
  opacity: .8
}

.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0
}

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all .15s ease-out
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0
}

.map,
.map-wrapper {
  position: relative
}

.map-wrapper {
  background-color: #eef2f4;
  height: 590px;
  margin-top: 72px
}

.map-addresses,
.map-wrapper>.row,
.map-wrapper>.row_short {
  position: relative
}

.map-addresses {
  background-color: #eef2f4;
  height: 100%;
  width: 430px;
  z-index: 1
}

.map-addresses>div {
  opacity: 0;
  padding-top: 67px;
  position: absolute;
  top: 0;
  transition: all .09s ease-out;
  visibility: hidden
}

.map-addresses>div.shown {
  opacity: 1;
  transition: all .09s ease-in;
  visibility: visible
}

.map-addresses__name {

  font-size: 26px;
  font-style: normal;
  font-weight: 300;
  height: 91px;
  line-height: 36px;
  position: relative
}

.map-addresses__name>div {
  height: 100%;
  position: relative;
  z-index: 1
}

.map-addresses__name>div>div {
  margin-left: 18px
}

.map-addresses__name span {
  font-weight: 500
}

.map-addresses__name span,
.map-addresses__name:after {
  display: block;

  font-style: normal
}

.map-addresses__name:after {
  color: #feffff;
  content: attr(data-letter);
  font-size: 130px;
  font-weight: 700;
  left: -8px;
  line-height: 90px;
  position: absolute;
  top: 2px;
  z-index: 0
}

.map-addresses__address {
  color: #5f6877;
  font-size: 14px;
  line-height: 24px;
  margin-top: 38px;
  padding-left: 54px;
  position: relative
}

.map-addresses__how-to-get {
  margin-top: 3px;
  padding-left: 54px
}

.map-addresses__how-to-get>.link,
.map-addresses__how-to-get>.slide-tab__label,
.map-addresses__how-to-get>[data-js=tooltip],
.tabs__labels.tabs__labels_buttons .map-addresses__how-to-get>.tab__label {
  font-size: 14px
}

.map-addresses__address__icon {
  display: block;
  height: 20px;
  left: 20px;
  position: absolute;
  top: 2px;
  width: 20px
}

.map-addresses__phone,
.map-addresses__time {
  color: #5f6877;
  font-size: 14px;
  line-height: 26px;
  padding-left: 54px;
  position: relative
}

.map-addresses__phone>div,
.map-addresses__time>div {
  color: #a5a5a5
}

.map-addresses__phone:before,
.map-addresses__time:before {
  content: "";
  display: block;
  height: 20px;
  left: 20px;
  position: absolute;
  top: 2px;
  width: 20px
}

.map-addresses__time {
  margin-top: 18px
}

.map-addresses__time:before {
  background-image: url(/build/i/0ebb573056c31f57e6de.svg)
}

.map-addresses__phone {
  margin-top: 17px
}

.map-addresses__phone:before {
  background-image: url(/build/i/eb975b127cdf6aeda85d.svg)
}

.map-addresses__links {
  margin-top: 49px
}

.map-addresses__links .link,
.map-addresses__links .slide-tab__label,
.map-addresses__links .tabs__labels.tabs__labels_buttons .tab__label,
.map-addresses__links [data-js=tooltip],
.tabs__labels.tabs__labels_buttons .map-addresses__links .tab__label {

  font-style: normal;
  font-weight: 500;
  left: 27px;
  position: relative;
  text-transform: uppercase;
  top: 0
}

.map-addresses__close {
  color: transparent;
  display: none;
  height: 44px;
  padding-right: 0;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 44px;
  z-index: 1
}

.map-addresses__close:hover:before {
  transform: rotate(-180deg) scale(1.2);
  transition: all .2s ease-out
}

.map-addresses__close:before {
  background-image: url(/build/i/015fdc15fd3d400de783.svg);
  background-position: 50%;
  content: "";
  display: block;
  height: 18px;
  left: 50%;
  margin-left: -9px;
  margin-top: -9px;
  position: absolute;
  top: 50%;
  transform: rotate(0deg) scale(1);
  transition: all .2s ease-in;
  width: 18px
}

.map-container {
  background-color: #eef2f4;
  height: 590px;
  overflow: hidden;
  position: absolute !important;
  top: 0;
  width: 100%;
  z-index: 0
}

#affiliates-map {
  height: 590px;
  position: absolute;
  right: 0;
  width: 100%
}

.metro-map {
  margin-top: 100px
}

.metro-map__grid {
  margin-left: -12px;
  margin-right: -12px;
  margin-top: 68px
}

.metro-map__grid>* {
  flex-basis: calc(33.33% - 36px);
  padding-left: 12px;
  padding-right: 12px
}

.metro-map__affiliate {
  margin-top: 20px;
  padding-left: 28px;
  position: relative
}

.metro-map__affiliate>.link,
.metro-map__affiliate>.slide-tab__label,
.metro-map__affiliate>[data-js=tooltip],
.tabs__labels.tabs__labels_buttons .metro-map__affiliate>.tab__label {

  font-style: normal;
  font-weight: 500;
  text-transform: uppercase
}

.metro-map__affiliate__icon {
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px
}

.metro-map__affiliate__address {
  color: #5f6877;
  font-size: 14px;
  line-height: 26px;
  margin-top: 5px
}

.page_251 .metro-map {
  margin-top: 80px !important
}

@media screen and (max-width:1359px) {
  .metro-map__affiliate__address {
    max-width: 235px
  }

  .metro-map__grid>* {
    align-items: baseline;
    max-width: 33.33%
  }

  .metro-map__grid>*>div {
    width: 100%
  }

  .metro-map__grid>:nth-child(2)>svg {
    height: auto;
    margin-left: -30px;
    margin-right: -30px
  }

  .metro-map__grid>:nth-child(3) {
    padding-left: 54px
  }

  .page_251 .contacts-metro-map-wrapper+.pusher {
    margin-top: 60px !important
  }

  .page_251 .metro-map {
    margin-top: 0 !important
  }
}

@media screen and (max-width:1023px) {
  .map-addresses {
    width: 372px
  }

  .metro-map__grid {
    margin-left: -14px;
    margin-right: -14px;
    margin-top: 43px
  }

  .metro-map__grid>* {
    flex-basis: calc(50% - 28px);
    max-width: calc(50% - 28px);
    padding-left: 14px;
    padding-right: 14px
  }

  .metro-map__grid>:nth-child(2) {
    display: none
  }

  .metro-map {
    margin-top: 60px
  }

  .page_251 .metro-map {
    margin-top: 50px !important
  }
}

@media screen and (max-width:767px) {

  .map .h2,
  .map .payment-page h2,
  .payment-page .map h2 {
    margin-left: auto;
    margin-right: auto;
    max-width: 265px
  }

  .map .h2-sub {
    display: none
  }

  .map-wrapper {
    margin-top: 57px
  }

  .map-addresses {
    display: none;
    left: 0;
    position: absolute;
    width: 100%
  }

  .map-addresses>div {
    left: 50%;
    margin-left: -160px;
    padding-left: 15px;
    padding-right: 15px;
    width: 320px
  }

  .map-addresses__close {
    display: block
  }

  .map-container {
    left: 0;
    width: 100%
  }

  #affiliates-map {
    width: 100% !important
  }

  .metro-map {
    margin-top: 40px
  }

  .metro-map .h2,
  .metro-map .payment-page h2,
  .payment-page .metro-map h2 {

    font-style: normal;
    font-weight: 400;
    margin-left: auto;
    margin-right: auto;
    max-width: 80%
  }

  .metro-map .h2-sub {
    display: none
  }

  .metro-map__grid {
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 15px
  }

  .metro-map__grid>* {
    flex-basis: 50%;
    max-width: 50%;
    padding-left: 15px;
    padding-right: 15px
  }

  .metro-map__grid .metro-map__affiliate,
  .metro-map__grid>:nth-child(3) {
    padding-left: 0
  }

  .metro-map__grid .metro-map__affiliate>.link,
  .metro-map__grid .metro-map__affiliate>.slide-tab__label,
  .metro-map__grid .metro-map__affiliate>[data-js=tooltip],
  .metro-map__grid .tabs__labels.tabs__labels_buttons .metro-map__affiliate>.tab__label,
  .tabs__labels.tabs__labels_buttons .metro-map__grid .metro-map__affiliate>.tab__label {
    font-size: 12px
  }

  .metro-map__grid .metro-map__affiliate__icon {
    margin-bottom: 5px;
    position: relative
  }

  .metro-map__grid .metro-map__affiliate__address {
    font-size: 12px;
    line-height: 18px
  }

  .metro-map__grid .metro-map__affiliate__address span {
    display: none
  }

  .page_251 .metro-map {
    margin-top: 30px !important
  }
}

.list-toggle li {
  display: none
}

.list-toggle li.showed {
  display: block
}

.list-toggle [data-js-list-toggle=toggler] {

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: .42px;
  margin-left: 31px;
  text-transform: uppercase
}

.tabs {
  margin-top: 74px
}

.tabs__labels {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.tabs__labels:not(.tabs__labels_buttons) {
  border-bottom: 2px solid #d9e1e1;
  text-align: center
}

.tabs__labels:not(.tabs__labels_buttons) .tab__label {
  border: 2px solid transparent;
  border-bottom-color: #d9e1e1;
  border-radius: 3px;
  bottom: -2px;
  font-size: 18px;
  height: 56px;
  padding-left: 67px;
  padding-right: 67px;
  position: relative;
  transition: all .1s ease-out
}

.tabs__labels:not(.tabs__labels_buttons) .tab__label.active {
  border-color: #d9e1e1 #d9e1e1 #fff;
  transition: all .1s ease-in
}

.tabs__labels:not(.tabs__labels_buttons) .tab__label.active a,
.tabs__labels:not(.tabs__labels_buttons) .tab__label.active a:hover {
  color: #333638
}

.tabs__labels:not(.tabs__labels_buttons) .tab__label.active a:hover>span,
.tabs__labels:not(.tabs__labels_buttons) .tab__label.active a>span {
  border-bottom: none
}

.tabs__labels:not(.tabs__labels_buttons) .tab__label a {
  color: #00beb4
}

.tabs__labels.tabs__labels_buttons .tab__label {
  border-radius: 18px;
  color: #00beb4;
  margin-bottom: 15px;
  margin-right: 11px;
  min-height: 36px;
  padding: 4px 22px
}

.tabs__labels.tabs__labels_buttons .tab__label,
.tabs__labels.tabs__labels_buttons .tab__label.active {

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  line-height: 18px;
  text-transform: none
}

.tabs__labels.tabs__labels_buttons .tab__label.active {
  border: 1px solid #00beb4;
  border-radius: 18px;
  color: #fff;
  letter-spacing: normal;
  pointer-events: none
}

.tabs__labels.tabs__labels_buttons_center {
  align-items: center;
  display: flex;
  justify-content: center
}

.tabs__labels.tabs__labels_buttons_center .tab__label {
  margin-left: 8px;
  margin-right: 8px
}

.tabs__contents {
  position: relative
}

.tab {
  display: none;
  margin-top: 70px
}

.tab.active {
  display: block
}

.tab p {
  line-height: 28px;
  margin-bottom: 19px
}

.tab .with-image-slider>:first-child {
  margin-top: 0;
  padding-top: 0
}

.tab .with-image-slider__text {
  margin-top: 0
}

.tab__imaged-block {
  display: flex;
  flex-wrap: nowrap;
  margin-left: -40px;
  margin-right: -40px
}

.tab__imaged-block>* {
  margin-left: 40px;
  margin-right: 40px
}

.tab__imaged-block .tab__title {
  color: #5f6877;

  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 23px;
  text-transform: uppercase
}

.tab__imaged-block p {
  color: #5f6877
}

@media screen and (max-width:1023px) {
  .tab {
    margin-top: 0
  }

  .tab .with-image-slider {
    text-align: left
  }

  .tab .with-image-slider>:first-child {
    margin-top: 15px;
    padding-top: 18px
  }

  .tab .with-image-slider__text {
    margin-top: 30px
  }

  .tab__imaged-block {
    margin-left: -15px;
    margin-right: -15px
  }

  .tab__imaged-block>* {
    margin-left: 15px;
    margin-right: 15px
  }
}

@media screen and (max-width:767px) {
  .tab__imaged-block {
    flex-wrap: wrap;
    margin-left: 0;
    margin-right: 0
  }

  .tab__imaged-block>* {
    flex-basis: 100%;
    margin-left: 0;
    margin-right: 0
  }
}

.slide-tabs {
  margin-top: 74px
}

.slide-tabs__labels {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.slide-tabs__contents {
  position: relative
}

.slide-tab__label {
  border-radius: 18px;
  color: #00beb4;
  margin-bottom: 15px;
  margin-right: 11px;
  min-height: 36px;
  padding: 4px 22px
}

.slide-tab__label,
.slide-tab__label.active {

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  line-height: 18px;
  text-transform: none
}

.slide-tab__label.active {
  border: 1px solid #00beb4;
  border-radius: 18px;
  color: #fff;
  letter-spacing: normal
}

.slide-tab__label.active:active,
.slide-tab__label.active:hover {
  background: #eef2f4;
  box-shadow: none
}

.slide-tabs__label_active {
  display: inline-block
}

.slide-tabs__contents_active {
  display: block
}

.slide-tab {
  display: none;
  margin-top: 70px
}

.slide-tab.active {
  display: block
}

.slide-tab p {
  line-height: 28px;
  margin-bottom: 19px
}

.slide-tab .with-image-slider>:first-child {
  margin-top: 0;
  padding-top: 0
}

.slide-tab .with-image-slider__text {
  margin-top: 0
}

.slide-tab__imaged-block {
  display: flex;
  flex-wrap: nowrap;
  margin-left: -40px;
  margin-right: -40px
}

.slide-tab__imaged-block>* {
  margin-left: 40px;
  margin-right: 40px
}

.slide-tab__imaged-block .slide-tab__title {
  color: #5f6877;

  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 23px;
  text-transform: uppercase
}

.slide-tab__imaged-block p {
  color: #5f6877
}

@media screen and (max-width:1023px) {
  .slide-tab {
    margin-top: 0
  }

  .slide-tab .with-image-slider {
    text-align: left
  }

  .slide-tab .with-image-slider>:first-child {
    margin-top: 15px;
    padding-top: 18px
  }

  .slide-tab .with-image-slider__text {
    margin-top: 30px
  }

  .slide-tab__imaged-block {
    margin-left: -15px;
    margin-right: -15px
  }

  .slide-tab__imaged-block>* {
    margin-left: 15px;
    margin-right: 15px
  }
}

@media screen and (max-width:767px) {
  .slider-tabs__wrapper {
    padding: 0
  }

  [dir=ltr] .slide-tab__label {
    text-align: left
  }

  [dir=rtl] .slide-tab__label {
    text-align: right
  }

  .slide-tab__label {
    border: none;
    border-bottom: 1px solid #d9e1e1;
    border-radius: 0;
    color: #00beb4;
    height: 52px;
    margin-bottom: 0;
    padding-left: 15px;
    padding-right: 15px
  }

  .slide-tab__label:first-child {
    border-top: 1px solid #d9e1e1
  }

  .slide-tab__label:after {
    background: url(/build/i/f1b60d3a25cd70d2deac.svg) 50%/contain no-repeat;
    content: "";
    display: inline-block;
    float: right;
    height: 10px;
    width: 10px
  }

  .slide-tab__label.active {
    background-color: #fff;
    border-radius: 0;
    color: #00beb4;
    height: 52px;
    padding-left: 15px;
    padding-right: 15px
  }

  .slide-tab__label.active:not(a) {
    background-color: #fff;
    border-left: none;
    border-right: none;
    color: #00beb4
  }

  .slide-tab__label.active:after {
    transform: rotate(-180deg)
  }

  .slide-tab__label.active:active,
  .slide-tab__label.active:focus,
  .slide-tab__label.active:hover,
  .slide-tab__label.active:visited {
    background: #fff;
    box-shadow: none
  }

  .slide-tab__label {
    display: block;
    width: 100%
  }

  .slide-tab__imaged-block {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-right: 0
  }

  .slide-tab__imaged-block div:first-child {
    margin: 0 auto;
    padding-bottom: 30px
  }

  .slide-tab__imaged-block .underline {
    display: block;
    text-align: center
  }

  .slide-tab {
    margin-top: 0
  }

  .slide-tab.active {
    border-bottom: 1px solid #d9e1e1;
    margin-top: 0;
    padding-bottom: 50px;
    padding-top: 20px
  }

  >* {
    flex-basis: 100%;
    margin-left: 0;
    margin-right: 0
  }
}

.calc__body {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 29px rgba(70, 81, 86, .18);
  padding-top: 70px
}

.calc__title {
  color: #3c4143;

  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 49px;
  text-align: center
}

.calc__steps {
  background-color: #f4f7f8;
  height: 54px;
  padding-left: 104px
}

.calc__steps>* {
  color: #989ca6;
  counter-increment: calc-step;
  cursor: pointer;
  display: inline-block;

  font-style: normal;
  font-weight: 500;
  height: 100%;
  line-height: 56px;
  margin-right: 50px
}

.calc__steps>.active {
  color: #d97700;
  pointer-events: none
}

.calc__steps>:before {
  content: counter(calc-step) ". "
}

.calc__option {
  display: none;
  padding: 10px 104px 52px
}

.calc__option.active {
  display: flex
}

.calc__option [data-js-calc-input-trigger=targets]>* {
  display: none
}

.calc__option [data-js-calc-input-trigger=targets]>.active {
  display: block
}

.calc__option-result {
  margin-bottom: 25px
}

.calc__option-result__price {
  display: inline-block;
  font-size: 30px;
  margin-right: 50px;
  position: relative;
  top: 0
}

.calc__option-result__price span {
  font-size: 16px;
  margin-right: 22px;
  position: relative;
  top: -1px
}

.calc__option-result__price span:before {
  background-color: #df8200;
  content: "";
  display: block;
  height: 2px;
  left: 0;
  margin-top: -1px;
  position: absolute;
  top: 50%;
  width: 100%
}

.calc__option-result__offer {
  background-color: #fcffab;
  border-radius: 13px;
  display: inline-block;
  font-size: 14px;
  height: 26px;
  line-height: 26px;
  padding-left: 14px;
  padding-right: 14px;
  position: relative;
  top: -4px
}

.calc__result {
  border-top: 1px solid #d9e1e1;
  padding: 52px 110px
}

.calc__result__price {
  display: inline-block;
  font-size: 30px;
  margin-right: 50px;
  position: relative;
  top: 2px
}

.calc__result__price span {
  font-size: 16px;
  margin-right: 22px;
  position: relative;
  top: -4px
}

.calc__result__price span:before {
  background-color: #df8200;
  content: "";
  display: block;
  height: 2px;
  left: 0;
  margin-top: -1px;
  position: absolute;
  top: 50%;
  width: 100%
}

.calc__result__button {
  cursor: pointer;
  float: right
}

.calc__result__offer {
  background-color: #fcffab;
  border-radius: 13px;
  display: inline-block;
  font-size: 14px;
  height: 26px;
  line-height: 26px;
  padding-left: 14px;
  padding-right: 14px;
  position: relative;
  top: -4px
}

.calc__option__crowns-grid,
.calc__option__systems-grid {
  justify-content: space-around;
  margin-left: -12px;
  margin-right: -12px;
  width: 414px
}

.calc__option__crowns-grid>*,
.calc__option__systems-grid>* {
  border: 1px solid #e0e3e4;
  border-radius: 4px;
  cursor: pointer;
  flex-basis: calc(50% - 24px);
  flex-grow: 0;
  height: 90px;
  margin-bottom: 20px;
  margin-left: 12px;
  margin-right: 12px;
  padding: 22px;
  position: relative
}

.calc__option__crowns-grid>.active,
.calc__option__systems-grid>.active {
  border: 1px solid #05c2bb;
  pointer-events: none
}

.calc__option__crowns-grid>* .radio-wrapper,
.calc__option__systems-grid>* .radio-wrapper {
  position: absolute;
  right: 10px;
  top: 10px
}

.calc__option__crowns-grid>* {
  flex-basis: calc(100% - 24px)
}

.calc__option__counts {
  flex-flow: column;
  flex-wrap: wrap
}

.calc__option__counts>* {
  flex-basis: calc(100% - 24px)
}

.calc__option__counts-grid {
  margin-left: -12px;
  margin-right: -12px
}

.calc__option__counts-grid>* {
  border: 1px solid #e0e3e4;
  border-radius: 4px;
  cursor: pointer;
  flex-basis: calc(12.5% - 24px);
  flex-grow: 0;
  height: 64px;
  margin-bottom: 20px;
  margin-left: 12px;
  margin-right: 12px;
  padding: 22px;
  position: relative
}

.calc__option__counts-grid>.active {
  border: 1px solid #05c2bb;
  pointer-events: none
}

.calc__option__counts-grid>* .radio-wrapper {
  position: absolute;
  right: 10px;
  top: 10px
}

.calc__option__crowns,
.calc__option__systems {
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px
}

.calc__option__crowns>*,
.calc__option__systems>* {
  margin-left: 12px;
  margin-right: 12px
}

.calc__option__crowns>:nth-child(2),
.calc__option__systems>:nth-child(2) {
  width: 392px
}

.calc__option__crowns>:last-child,
.calc__option__systems>:last-child {
  padding-left: 40px;
  width: 590px
}

.calc__option__total {
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px
}

.calc__option__total>* {
  flex-basis: calc(50% - 24px);
  margin-left: 12px;
  margin-right: 12px
}

.calc__option__total>:nth-child(-n+2) {
  flex-basis: calc(100% - 24px);
  width: 100%
}

.calc__option-title {
  flex-basis: calc(100% - 24px) !important;
  margin-bottom: 30px;
  margin-top: 36px
}

.calc__content__title,
.calc__option-title {

  font-size: 18px;
  font-style: normal;
  font-weight: 500
}

.calc__content__title {
  color: #5f6877;
  margin-bottom: 16px
}

.calc__content__title span {

  font-style: normal;
  font-weight: 400;
  margin-left: 20px
}

.calc__content__text,
.calc__content__title span {
  color: #989ca6;
  font-size: 14px;
  line-height: 28px
}

.calc__content__text p {
  margin-bottom: 15px
}

.calc__content__text__title {
  display: block;
  margin-bottom: 5px
}

[data-js=tooltip] {
  cursor: pointer;
  font-size: 20px;
  line-height: 28px
}

[data-js-tooltip=html] {
  display: none
}

.tippy-tooltip {
  text-align: left
}

.tippy-tooltip.light-theme {
  background-color: #fff;
  border: 1px solid #e0e3e4;
  box-shadow: 0 10px 29px rgba(70, 81, 86, .18)
}

[data-js-tooltip=arrow] {
  height: 14px;
  position: absolute;
  width: 30px
}

[data-js-tooltip=arrow]:before {
  left: 1px;
  top: 1px;
  z-index: 1
}

[data-js-tooltip=arrow]:after,
[data-js-tooltip=arrow]:before {
  border-style: solid;
  content: "";
  display: block;
  height: 0;
  position: absolute;
  width: 0
}

[data-js-tooltip=arrow]:after {
  z-index: 0
}

.tippy-arrow {
  position: absolute
}

.tippy-popper[x-placement^=bottom] .tippy-arrow {
  height: 14px;
  top: -14px;
  width: 30px
}

.tippy-popper[x-placement^=bottom] [data-js-tooltip=arrow] {
  left: -7px;
  top: -1px
}

.tippy-popper[x-placement^=bottom] [data-js-tooltip=arrow]:before {
  border-color: transparent transparent #fff;
  border-width: 0 15px 14px
}

.tippy-popper[x-placement^=bottom] [data-js-tooltip=arrow]:after {
  border-color: transparent transparent #e0e3e4;
  border-width: 0 16px 15px
}

.tippy-popper[x-placement^=top] .tippy-arrow {
  bottom: -14px;
  height: 14px;
  width: 30px
}

.tippy-popper[x-placement^=top] [data-js-tooltip=arrow] {
  bottom: 0;
  left: -7px
}

.tippy-popper[x-placement^=top] [data-js-tooltip=arrow]:before {
  border-color: #fff transparent transparent;
  border-width: 14px 15px 0;
  top: 0
}

.tippy-popper[x-placement^=top] [data-js-tooltip=arrow]:after {
  border-color: #e0e3e4 transparent transparent;
  border-width: 15px 16px 0
}

.tippy-tooltip {
  padding: 20px 60px 20px 30px !important;
  position: relative
}

[data-js-tooltip=close] {
  cursor: pointer;
  height: 14px;
  pointer-events: all;
  position: absolute;
  right: 23px;
  top: 23px;
  width: 14px
}

[data-js-tooltip=close]>svg {
  left: 0;
  position: absolute;
  top: 0
}

[data-js-tooltip=close]>svg path {
  transition: fill .2s ease-out
}

[data-js-tooltip=close]:hover>svg path {
  fill: #8e9799;
  transition: fill .2s ease-out
}

.tooltip__title {

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 20px;
  max-width: 260px
}

.tooltip__text {
  color: #989ca6;
  font-size: 14px;
  line-height: 26px
}

.tooltip__li-right {
  float: right;
  padding-left: 20px
}

@media screen and (max-width:767px) {
  .tooltip__title {
    max-width: 100%
  }

  .tippy-tooltip {
    padding-bottom: 20px !important;
    padding-left: 20px !important
  }

  .tippy-popper[data-html] {
    max-width: calc(100% - 10px)
  }

  .tooltip__li-right {
    display: block;
    float: none;
    padding-left: 0
  }
}

@media screen and (max-width:470px) {
  .tooltip__text br {
    display: none
  }

  .tippy-tooltip {
    padding-right: 45px !important
  }
}

.ribbon {
  background-color: #eef3f6;
  color: #333638;
  font-size: 14px;
  height: 50px;
  line-height: 20px
}

.ribbon .row,
.ribbon .row_short {
  position: relative
}

.ribbon__container {
  color: #333638;
  display: block;
  height: 100%;
  line-height: 50px;
  width: 850px
}

.ribbon__container__logo {
  background-image: url(/build/i/15d1dd770ed79b6eff01.png);
  background-size: cover;
  display: inline-block;
  height: 32px;
  margin-right: 24px;
  position: relative;
  top: 7px;
  width: 91px
}

.ribbon__container__text {
  position: relative;
  top: -3px
}

.ribbon__container__text br {
  display: none
}

.ribbon__container__more {
  color: #00beb4;
  float: right;
  font-size: 12px;
  text-transform: uppercase
}

.ribbon__close {
  height: 20px;
  position: absolute;
  right: 70px;
  top: 14px;
  width: 20px
}

.ribbon__close:hover:before {
  transform: rotate(-180deg) scale(1.2);
  transition: all .2s ease-out
}

.ribbon__close:before {
  background-image: url(/build/i/2b66ddc23f976a6fa8b5.svg);
  content: "";
  display: block;
  height: 12px;
  left: 4px;
  position: absolute;
  top: 4px;
  transform: rotate(0deg) scale(1);
  transition: all .2s ease-in;
  width: 12px
}

.ribbon_consultations {
  background-color: #ee8525;
  height: 90px
}

.ribbon_consultations .ribbon__container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  line-height: normal;
  max-width: 100%;
  padding-left: 105px;
  padding-right: 75px;
  position: relative;
  width: 100%
}

.ribbon_consultations .ribbon__container:after,
.ribbon_consultations .ribbon__container:before {
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 0;
  content: "";
  position: absolute
}

.ribbon_consultations .ribbon__container:before {
  background-image: url(/build/i/85e44e0a559707596e78.png);
  height: 69px;
  left: 0;
  width: 77px
}

.ribbon_consultations .ribbon__container:after {
  background-image: url(/build/i/f0f3827ce8269bb1d4b2.png);
  height: 79px;
  right: 0;
  width: 70px
}

.ribbon__text {
  color: #fff;
  font-size: 18px
}

.ribbon__text>:first-child {

  font-style: normal;
  font-weight: 500;
  margin-bottom: 5px
}

.ribbon__text>:nth-child(2)>span {

  font-style: normal;
  font-weight: 700
}

.ribbon__button {
  line-height: 40px;
  max-width: 170px;
  text-align: center;
  width: 100%
}

.ribbon_consultations-large {
  background-color: transparent;
  height: auto
}

.ribbon_consultations-large .ribbon__container {
  align-items: center;
  background-color: #ee8525;
  border-radius: 10px;
  display: flex;
  height: 135px;
  justify-content: space-between;
  margin: 35px auto 10px;
  max-width: 1070px;
  overflow: hidden;
  padding-left: 190px;
  padding-right: 200px;
  position: relative;
  width: 100%
}

.ribbon_consultations-large .ribbon__container:before {
  background-image: url(/build/i/7174329d7e8c6ff3a354.png);
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 159px
}

.ribbon_consultations-large .ribbon__text {
  font-size: 16px;
  line-height: 28px;
  padding-right: 20px
}

.ribbon_consultations-large .ribbon__text>:first-child {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 5px
}

.ribbon_consultations-large .ribbon__button {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%)
}

.ribbon_schedule {
  background-color: #e94f5c;
  background-image: url(/build/i/2cea2ea70ceaa4bca3c4.png);
  background-position: 50%;
  background-repeat: repeat;
  background-size: auto;
  height: auto
}

.ribbon_schedule .ribbon__container {
  background: #e94f5c;
  background: linear-gradient(90deg, rgba(233, 79, 92, 0), #e94f5c 3%, #e94f5c 97%, rgba(233, 79, 92, 0));
  max-width: 850px;
  padding: 13px 40px
}

.ribbon_schedule .ribbon__container:before {
  background-image: url(/build/i/bf3c686e8f361673744c.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  content: "";
  display: block;
  height: 34px;
  margin-right: 13px;
  min-width: 23px
}

.ribbon_schedule .ribbon__text>:first-child {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
  text-align: left
}

@media screen and (max-width:1199px) {
  .ribbon_consultations .ribbon__container {
    padding-left: 90px;
    padding-right: 0
  }

  .ribbon_consultations .ribbon__container:after {
    content: none
  }

  .ribbon__text {
    font-size: 16px;
    padding-right: 15px
  }

  .ribbon_consultations-large .ribbon__container {
    max-width: 100%;
    padding-left: 30px
  }

  .ribbon_consultations-large .ribbon__container:before {
    content: none
  }
}

@media screen and (max-width:1128px) {
  .ribbon__container {
    width: 100%
  }

  .ribbon__container__logo {
    display: none
  }

  .ribbon__container__text {
    top: 0
  }

  .ribbon__container__more {
    float: none;
    margin-left: 30px
  }

  .ribbon__close {
    right: 56px
  }

  .ribbon_consultations .ribbon__container {
    padding-left: 0
  }

  .ribbon_consultations .ribbon__container:before {
    content: none
  }
}

@media screen and (max-width:1023px) {
  .ribbon {
    font-size: 13px
  }

  .ribbon__container__more {
    font-size: 11px;
    margin-left: 20px
  }

  .ribbon__close {
    right: 26px
  }

  .ribbon_consultations {
    height: auto;
    overflow: hidden
  }

  .ribbon_consultations .ribbon__container {
    padding-bottom: 85px;
    padding-top: 14px;
    top: 0
  }

  .ribbon_consultations .ribbon__container:before {
    content: ""
  }

  .ribbon_consultations .ribbon__button {
    bottom: 30px;
    left: 110px;
    position: absolute
  }

  .ribbon__text {
    font-size: 18px;
    line-height: 24px;
    padding-right: 0
  }

  .ribbon__text>:nth-child(2) {
    font-size: 16px
  }

  .ribbon_consultations-large .ribbon__container {
    flex-wrap: wrap;
    height: auto;
    justify-content: center;
    max-width: 100%;
    padding: 20px 30px 25px
  }

  .ribbon_consultations-large .ribbon__container:before {
    content: none
  }

  .ribbon_consultations-large .ribbon__text {
    flex-basis: 100%;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 12px;
    padding-right: 0;
    text-align: center
  }

  .ribbon_consultations-large .ribbon__text>:first-child {
    font-size: 16px;
    line-height: 22px
  }

  .ribbon_consultations-large .ribbon__button {
    position: relative;
    right: auto;
    top: auto;
    transform: none
  }

  .ribbon_schedule {
    background-image: none
  }

  .ribbon_schedule .ribbon__container {
    background: #e94f5c;
    padding: 13px 15px
  }

  .ribbon_schedule .ribbon__container:before {
    margin-right: 23px
  }
}

@media screen and (max-width:767px) {
  .ribbon__container__hidden {
    display: none
  }

  .ribbon__close {
    right: 15px
  }

  .ribbon__container {
    font-size: 12px;
    line-height: 14px;
    top: 11px
  }

  .ribbon__container__text br {
    display: block
  }

  .ribbon__container__more {
    float: right;
    margin-left: 0;
    margin-right: 45px;
    position: relative;
    top: -6px
  }

  .ribbon_consultations-large .ribbon__container {
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px
  }
}

.best-flex .what-is-included__notice {
  margin-bottom: 47px;
  margin-top: 36px
}

.best-flex__button {
  text-align: center
}

.best-flex__title {
  margin-bottom: 52px
}

.best-flex__grid {
  margin-left: -15px;
  margin-right: -15px
}

.best-flex__grid__item {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 43px 3px rgba(0, 0, 0, .08);
  flex-basis: calc(33.33% - 30px);
  margin-bottom: 30px;
  margin-left: 15px;
  margin-right: 15px;
  max-width: calc(33.33% - 30px);
  min-height: 329px;
  position: relative
}

.best-flex__grid__item .progressive-image-wrapper {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  display: block;
  max-width: 100%;
  position: relative;
  z-index: 1
}

.best-flex__grid__item__header {
  align-items: center;
  background-color: #f2f6f8;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: center;
  min-height: 130px;
  padding-left: 25px;
  padding-right: 25px;
  text-align: center
}

.best-flex__grid__item__title {

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 9px
}

.best-flex__grid__item__sub-title {
  font-size: 15px;
  line-height: 20px
}

.best-flex__grid__item__button {
  background-color: #f2f6f8;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  bottom: 0;
  cursor: pointer;
  height: 30px;
  left: 50%;
  margin-left: -30px;
  position: absolute;
  transition: all .1s ease-out;
  width: 60px;
  z-index: 2
}

.best-flex__grid__item__button:before {
  background-image: url(/build/i/c24e225bc1baaefbe9fe.svg);
  content: "";
  display: block;
  height: 8px;
  left: 50%;
  margin-left: -7px;
  margin-top: -4px;
  position: absolute;
  top: 50%;
  width: 14px
}

.best-flex__grid__item__button:hover {
  background-color: #00beb4;
  transition: all .1s ease-in
}

.best-flex__grid__item__button:hover:before {
  background-image: url(/build/i/81a589cd2f3e6e3f3769.svg)
}

.best-flex__grid__item__button.opened {
  display: none
}

.best-flex__grid__item__hidden {
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 25px 20px 3px rgba(0, 0, 0, .08);
  display: none;
  min-height: 200px;
  padding-bottom: 60px;
  padding-left: 40px;
  padding-right: 40px;
  position: absolute;
  width: 100%;
  z-index: 3
}

.best-flex__grid__item__hidden.opened {
  display: block
}

.best-flex__grid__item__hidden.opened .best-flex__grid__item__button:before {
  transform: rotate(180deg)
}

.best-flex__price-grid {
  margin-top: 21px
}

.best-flex__price-grid>div:first-child {

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px
}

.best-flex__price-grid>div:last-child {
  padding-top: 1px;
  text-align: right
}

.best-flex__price-grid>div:last-child .link,
.best-flex__price-grid>div:last-child .slide-tab__label,
.best-flex__price-grid>div:last-child .tabs__labels.tabs__labels_buttons .tab__label,
.best-flex__price-grid>div:last-child [data-js=tooltip],
.tabs__labels.tabs__labels_buttons .best-flex__price-grid>div:last-child .tab__label {

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase
}

.best-flex__price-grid_only-link>div:last-child {
  text-align: center
}

.best-flex_colored-images .best-flex__grid__item__hidden {
  padding-top: 30px
}

@media screen and (max-width:1222px) {
  .best-flex__grid__item__title {
    font-size: 18px
  }

  .best-flex__grid__item__sub-title {
    font-size: 14px
  }

  .best-flex__grid__item__sub-title>br {
    display: none
  }

  .best-flex__grid__item {
    min-height: 350px
  }
}

@media screen and (max-width:1199px) {
  .best-flex__grid__item__hidden {
    padding-left: 20px;
    padding-right: 20px
  }
}

@media screen and (max-width:1023px) {
  .best-flex__grid {
    justify-content: center
  }

  .best-flex__grid__item {
    flex-basis: calc(50% - 30px);
    max-width: calc(50% - 30px);
    min-height: auto
  }
}

@media screen and (max-width:767px) {
  .best-flex__grid__item {
    flex-basis: calc(100% - 30px);
    max-width: calc(100% - 30px)
  }
}

.installment-terms-modal .modal__title {
  color: #3c4143;

  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  text-align: center
}

.installment-terms-modal__grid {
  flex-wrap: nowrap;
  margin-left: -25px;
  margin-right: -25px
}

.installment-terms-modal__grid>* {
  flex-basis: calc(33.33% - 50px);
  margin-left: 25px;
  margin-right: 25px
}

.installment-terms-modal__grid__title {
  color: #333638;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 20px;
  text-align: left
}

.installment-terms-modal__grid__text {
  font-size: 14px;
  line-height: 26px;
  text-align: left
}

.installment-terms-modal__grid__text li {
  white-space: nowrap
}

@media screen and (max-width:980px) {
  .installment-terms-modal .modal__title {
    text-align: left
  }

  .installment-terms-modal__grid {
    flex-wrap: wrap;
    margin-left: 0;
    margin-right: 0
  }

  .installment-terms-modal__grid>* {
    flex-basis: 50%;
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 0
  }

  .installment-terms-modal__grid>:last-child {
    margin-bottom: 0
  }
}

@media screen and (max-width:605px) {
  .installment-terms-modal__grid>* {
    flex-basis: 100%
  }
}

.four-image-slider .slider__item__image-wrapper {
  display: flex;
  justify-content: center;
  position: relative
}

.four-image-slider .slider__item__image {
  margin-left: 5px;
  margin-right: 5px;
  width: auto !important
}

.four-image-slider__navigator {
  width: 100%
}

.four-image-slider__navigator__button {
  background-color: #ecf2f6;
  height: 40px;
  transition: background-color .2s ease-out;
  width: 22px
}

.four-image-slider__navigator__button>svg {
  height: 14px;
  left: 3px;
  position: relative;
  top: -1px;
  width: 14px
}

.four-image-slider__navigator__button>svg path {
  fill: #909091
}

.four-image-slider__navigator__button:hover>svg path {
  fill: #5f6877
}

.four-image-slider__navigator__button.next,
.four-image-slider__navigator__button.prev {
  margin-top: -20px;
  position: absolute;
  top: 50%
}

.four-image-slider__navigator__button.next {
  border-bottom-left-radius: 90px;
  border-top-left-radius: 90px;
  right: 0
}

.four-image-slider__navigator__button.prev {
  border-bottom-right-radius: 90px;
  border-top-right-radius: 90px;
  display: none;
  left: 0
}

.four-image-slider__navigator__button.prev>svg {
  left: -4px;
  transform: rotate(180deg)
}

.four-image-slider__heart {
  bottom: 35px;
  color: #f0f7fa;
  font-size: 14px;
  line-height: 24px;
  position: absolute;
  right: 35px;
  text-shadow: 1px 1px rgba(0, 0, 0, .5), 1px 1px rgba(0, 0, 0, .5), -1px -1px rgba(0, 0, 0, .5), -1px -1px rgba(0, 0, 0, .5), -1px 1px rgba(0, 0, 0, .5), 1px -1px rgba(0, 0, 0, .5), -1px 1px rgba(0, 0, 0, .5), 1px -1px rgba(0, 0, 0, .5)
}

.four-image-slider__heart:before {
  background-image: url(/build/i/04698838f139aae735b3.svg);
  content: "";
  display: inline-block;
  height: 15px;
  margin-right: 5px;
  position: relative;
  top: 2px;
  width: 15px
}

@media screen and (max-width:1024px) {
  .four-image-slider__heart {
    bottom: 24px;
    right: 30px
  }
}

@media screen and (max-width:1023px) {
  .four-image-slider .slider__item__image-wrapper {
    margin-top: 0
  }

  .slider-wrapper-image.four-image-slider-wrapper {
    margin-bottom: 0;
    padding-bottom: 0
  }
}

@media screen and (max-width:480px) {

  .four-image-slider__navigator__button.next,
  .four-image-slider__navigator__button.prev {
    background-color: transparent;
    display: block !important;
    height: 100%;
    margin-top: 0;
    top: 0;
    width: 35px
  }

  .four-image-slider__navigator__button.next>svg,
  .four-image-slider__navigator__button.prev>svg {
    display: none
  }

  .four-image-slider__navigator__button.next {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px
  }

  .four-image-slider__navigator__button.prev {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px
  }
}

.snow i {
  background: #fff;
  border-radius: 50%;
  display: block;
  height: 10px;
  position: absolute;
  width: 10px;
  z-index: 1
}

.snow i:first-child {
  animation: fall-1 11s linear -19s infinite;
  opacity: .9808;
  transform: translate(21.6372vw, -10px) scale(.5486)
}

@keyframes fall-1 {
  36.27% {
    transform: translate(15.6732vw, 36.27vh) scale(.5486)
  }

  to {
    transform: translate(18.6552vw, 100vh) scale(.5486)
  }
}

.snow i:nth-child(2) {
  animation: fall-2 24s linear -6s infinite;
  opacity: .8308;
  transform: translate(17.9398vw, -10px) scale(.1105)
}

@keyframes fall-2 {
  77.67% {
    transform: translate(26.5471vw, 77.67vh) scale(.1105)
  }

  to {
    transform: translate(22.24345vw, 100vh) scale(.1105)
  }
}

.snow i:nth-child(3) {
  animation: fall-3 17s linear -12s infinite;
  opacity: .5692;
  transform: translate(4.6644vw, -10px) scale(.8358)
}

@keyframes fall-3 {
  39.829% {
    transform: translate(8.8767vw, 39.829vh) scale(.8358)
  }

  to {
    transform: translate(6.77055vw, 100vh) scale(.8358)
  }
}

.snow i:nth-child(4) {
  animation: fall-4 14s linear -30s infinite;
  opacity: .9257;
  transform: translate(34.8573vw, -10px) scale(.8169)
}

@keyframes fall-4 {
  75.693% {
    transform: translate(28.1551vw, 75.693vh) scale(.8169)
  }

  to {
    transform: translate(31.5062vw, 100vh) scale(.8169)
  }
}

.snow i:nth-child(5) {
  animation: fall-5 21s linear -15s infinite;
  opacity: .3541;
  transform: translate(63.6206vw, -10px) scale(.637)
}

@keyframes fall-5 {
  32.818% {
    transform: translate(55.4142vw, 32.818vh) scale(.637)
  }

  to {
    transform: translate(59.5174vw, 100vh) scale(.637)
  }
}

.snow i:nth-child(6) {
  animation: fall-6 19s linear -17s infinite;
  opacity: .7826;
  transform: translate(4.9177vw, -10px) scale(.0006)
}

@keyframes fall-6 {
  39.425% {
    transform: translate(4.9333vw, 39.425vh) scale(.0006)
  }

  to {
    transform: translate(4.9255vw, 100vh) scale(.0006)
  }
}

.snow i:nth-child(7) {
  animation: fall-7 11s linear -6s infinite;
  opacity: .1392;
  transform: translate(8.7937vw, -10px) scale(.1004)
}

@keyframes fall-7 {
  66.093% {
    transform: translate(14.5108vw, 66.093vh) scale(.1004)
  }

  to {
    transform: translate(11.65225vw, 100vh) scale(.1004)
  }
}

.snow i:nth-child(8) {
  animation: fall-8 11s linear -4s infinite;
  opacity: .9908;
  transform: translate(33.6659vw, -10px) scale(.0872)
}

@keyframes fall-8 {
  45.287% {
    transform: translate(26.6582vw, 45.287vh) scale(.0872)
  }

  to {
    transform: translate(30.16205vw, 100vh) scale(.0872)
  }
}

.snow i:nth-child(9) {
  animation: fall-9 19s linear -20s infinite;
  opacity: .4912;
  transform: translate(89.9927vw, -10px) scale(.0393)
}

@keyframes fall-9 {
  43.136% {
    transform: translate(94.6675vw, 43.136vh) scale(.0393)
  }

  to {
    transform: translate(92.3301vw, 100vh) scale(.0393)
  }
}

.snow i:nth-child(10) {
  animation: fall-10 25s linear -30s infinite;
  opacity: .0711;
  transform: translate(79.4614vw, -10px) scale(.7007)
}

@keyframes fall-10 {
  31.81% {
    transform: translate(77.4553vw, 31.81vh) scale(.7007)
  }

  to {
    transform: translate(78.45835vw, 100vh) scale(.7007)
  }
}

.snow i:nth-child(11) {
  animation: fall-11 10s linear -15s infinite;
  opacity: .4952;
  transform: translate(68.9841vw, -10px) scale(.437)
}

@keyframes fall-11 {
  31.139% {
    transform: translate(59.3461vw, 31.139vh) scale(.437)
  }

  to {
    transform: translate(64.1651vw, 100vh) scale(.437)
  }
}

.snow i:nth-child(12) {
  animation: fall-12 14s linear -11s infinite;
  opacity: .3749;
  transform: translate(7.8182vw, -10px) scale(.4703)
}

@keyframes fall-12 {
  48.25% {
    transform: translate(12.0315vw, 48.25vh) scale(.4703)
  }

  to {
    transform: translate(9.92485vw, 100vh) scale(.4703)
  }
}

.snow i:nth-child(13) {
  animation: fall-13 14s linear -29s infinite;
  opacity: .7303;
  transform: translate(65.8215vw, -10px) scale(.9165)
}

@keyframes fall-13 {
  72.693% {
    transform: translate(65.5937vw, 72.693vh) scale(.9165)
  }

  to {
    transform: translate(65.7076vw, 100vh) scale(.9165)
  }
}

.snow i:nth-child(14) {
  animation: fall-14 10s linear -2s infinite;
  opacity: .6277;
  transform: translate(44.6579vw, -10px) scale(.8618)
}

@keyframes fall-14 {
  60.696% {
    transform: translate(42.3903vw, 60.696vh) scale(.8618)
  }

  to {
    transform: translate(43.5241vw, 100vh) scale(.8618)
  }
}

.snow i:nth-child(15) {
  animation: fall-15 25s linear -9s infinite;
  opacity: .1404;
  transform: translate(2.0002vw, -10px) scale(.5147)
}

@keyframes fall-15 {
  60.525% {
    transform: translate(-2.7975vw, 60.525vh) scale(.5147)
  }

  to {
    transform: translate(-.39865vw, 100vh) scale(.5147)
  }
}

.snow i:nth-child(16) {
  animation: fall-16 26s linear -21s infinite;
  opacity: .4645;
  transform: translate(98.1654vw, -10px) scale(.0172)
}

@keyframes fall-16 {
  49.26% {
    transform: translate(89.8694vw, 49.26vh) scale(.0172)
  }

  to {
    transform: translate(94.0174vw, 100vh) scale(.0172)
  }
}

.snow i:nth-child(17) {
  animation: fall-17 29s linear -6s infinite;
  opacity: .4922;
  transform: translate(76.9716vw, -10px) scale(.9022)
}

@keyframes fall-17 {
  30.83% {
    transform: translate(72.3539vw, 30.83vh) scale(.9022)
  }

  to {
    transform: translate(74.66275vw, 100vh) scale(.9022)
  }
}

.snow i:nth-child(18) {
  animation: fall-18 10s linear -4s infinite;
  opacity: .0851;
  transform: translate(83.8693vw, -10px) scale(.3644)
}

@keyframes fall-18 {
  68.045% {
    transform: translate(79.9089vw, 68.045vh) scale(.3644)
  }

  to {
    transform: translate(81.8891vw, 100vh) scale(.3644)
  }
}

.snow i:nth-child(19) {
  animation: fall-19 23s linear -22s infinite;
  opacity: .8226;
  transform: translate(3.6783vw, -10px) scale(.8818)
}

@keyframes fall-19 {
  72.639% {
    transform: translate(9.3736vw, 72.639vh) scale(.8818)
  }

  to {
    transform: translate(6.52595vw, 100vh) scale(.8818)
  }
}

.snow i:nth-child(20) {
  animation: fall-20 27s linear -10s infinite;
  opacity: .3576;
  transform: translate(12.8971vw, -10px) scale(.142)
}

@keyframes fall-20 {
  76.312% {
    transform: translate(10.126vw, 76.312vh) scale(.142)
  }

  to {
    transform: translate(11.51155vw, 100vh) scale(.142)
  }
}

.snow i:nth-child(21) {
  animation: fall-21 25s linear -14s infinite;
  opacity: .8806;
  transform: translate(43.4729vw, -10px) scale(.147)
}

@keyframes fall-21 {
  58.559% {
    transform: translate(40.873vw, 58.559vh) scale(.147)
  }

  to {
    transform: translate(42.17295vw, 100vh) scale(.147)
  }
}

.snow i:nth-child(22) {
  animation: fall-22 29s linear -8s infinite;
  opacity: .0269;
  transform: translate(39.8547vw, -10px) scale(.8698)
}

@keyframes fall-22 {
  75.611% {
    transform: translate(36.5009vw, 75.611vh) scale(.8698)
  }

  to {
    transform: translate(38.1778vw, 100vh) scale(.8698)
  }
}

.snow i:nth-child(23) {
  animation: fall-23 22s linear -23s infinite;
  opacity: .356;
  transform: translate(65.6895vw, -10px) scale(.1111)
}

@keyframes fall-23 {
  50.217% {
    transform: translate(71.9881vw, 50.217vh) scale(.1111)
  }

  to {
    transform: translate(68.8388vw, 100vh) scale(.1111)
  }
}

.snow i:nth-child(24) {
  animation: fall-24 29s linear -17s infinite;
  opacity: .9307;
  transform: translate(37.533vw, -10px) scale(.8228)
}

@keyframes fall-24 {
  69.601% {
    transform: translate(45.7481vw, 69.601vh) scale(.8228)
  }

  to {
    transform: translate(41.64055vw, 100vh) scale(.8228)
  }
}

.snow i:nth-child(25) {
  animation: fall-25 21s linear -2s infinite;
  opacity: .667;
  transform: translate(32.0972vw, -10px) scale(.9133)
}

@keyframes fall-25 {
  48.211% {
    transform: translate(26.9456vw, 48.211vh) scale(.9133)
  }

  to {
    transform: translate(29.5214vw, 100vh) scale(.9133)
  }
}

.snow i:nth-child(26) {
  animation: fall-26 16s linear -26s infinite;
  opacity: .7949;
  transform: translate(10.3961vw, -10px) scale(.3602)
}

@keyframes fall-26 {
  63.292% {
    transform: translate(18.1902vw, 63.292vh) scale(.3602)
  }

  to {
    transform: translate(14.29315vw, 100vh) scale(.3602)
  }
}

.snow i:nth-child(27) {
  animation: fall-27 30s linear -8s infinite;
  opacity: .8218;
  transform: translate(76.3459vw, -10px) scale(.065)
}

@keyframes fall-27 {
  39.415% {
    transform: translate(76.8571vw, 39.415vh) scale(.065)
  }

  to {
    transform: translate(76.6015vw, 100vh) scale(.065)
  }
}

.snow i:nth-child(28) {
  animation: fall-28 27s linear -22s infinite;
  opacity: .1798;
  transform: translate(89.1202vw, -10px) scale(.7542)
}

@keyframes fall-28 {
  77.684% {
    transform: translate(90.196vw, 77.684vh) scale(.7542)
  }

  to {
    transform: translate(89.6581vw, 100vh) scale(.7542)
  }
}

.snow i:nth-child(29) {
  animation: fall-29 22s linear -23s infinite;
  opacity: .3145;
  transform: translate(24.8973vw, -10px) scale(.0041)
}

@keyframes fall-29 {
  68.517% {
    transform: translate(32.6932vw, 68.517vh) scale(.0041)
  }

  to {
    transform: translate(28.79525vw, 100vh) scale(.0041)
  }
}

.snow i:nth-child(30) {
  animation: fall-30 18s linear -3s infinite;
  opacity: .8961;
  transform: translate(31.493vw, -10px) scale(.7819)
}

@keyframes fall-30 {
  36.223% {
    transform: translate(27.9825vw, 36.223vh) scale(.7819)
  }

  to {
    transform: translate(29.73775vw, 100vh) scale(.7819)
  }
}

.snow i:nth-child(31) {
  animation: fall-31 11s linear -17s infinite;
  opacity: .427;
  transform: translate(77.4278vw, -10px) scale(.5904)
}

@keyframes fall-31 {
  51.717% {
    transform: translate(75.7975vw, 51.717vh) scale(.5904)
  }

  to {
    transform: translate(76.61265vw, 100vh) scale(.5904)
  }
}

.snow i:nth-child(32) {
  animation: fall-32 20s linear -26s infinite;
  opacity: .8344;
  transform: translate(66.8139vw, -10px) scale(.3866)
}

@keyframes fall-32 {
  72.804% {
    transform: translate(75.2847vw, 72.804vh) scale(.3866)
  }

  to {
    transform: translate(71.0493vw, 100vh) scale(.3866)
  }
}

.snow i:nth-child(33) {
  animation: fall-33 14s linear -7s infinite;
  opacity: .6008;
  transform: translate(63.8575vw, -10px) scale(.0034)
}

@keyframes fall-33 {
  40.227% {
    transform: translate(68.9332vw, 40.227vh) scale(.0034)
  }

  to {
    transform: translate(66.39535vw, 100vh) scale(.0034)
  }
}

.snow i:nth-child(34) {
  animation: fall-34 12s linear -18s infinite;
  opacity: .2193;
  transform: translate(38.392vw, -10px) scale(.3532)
}

@keyframes fall-34 {
  41.335% {
    transform: translate(36.1383vw, 41.335vh) scale(.3532)
  }

  to {
    transform: translate(37.26515vw, 100vh) scale(.3532)
  }
}

.snow i:nth-child(35) {
  animation: fall-35 13s linear -27s infinite;
  opacity: .6787;
  transform: translate(32.9483vw, -10px) scale(.3104)
}

@keyframes fall-35 {
  55.795% {
    transform: translate(38.5603vw, 55.795vh) scale(.3104)
  }

  to {
    transform: translate(35.7543vw, 100vh) scale(.3104)
  }
}

.snow i:nth-child(36) {
  animation: fall-36 17s linear -30s infinite;
  opacity: .8838;
  transform: translate(57.1267vw, -10px) scale(.7767)
}

@keyframes fall-36 {
  42.536% {
    transform: translate(65.5569vw, 42.536vh) scale(.7767)
  }

  to {
    transform: translate(61.3418vw, 100vh) scale(.7767)
  }
}

.snow i:nth-child(37) {
  animation: fall-37 24s linear -20s infinite;
  opacity: .1153;
  transform: translate(77.1396vw, -10px) scale(.1267)
}

@keyframes fall-37 {
  53.903% {
    transform: translate(75.2814vw, 53.903vh) scale(.1267)
  }

  to {
    transform: translate(76.2105vw, 100vh) scale(.1267)
  }
}

.snow i:nth-child(38) {
  animation: fall-38 20s linear -28s infinite;
  opacity: .5879;
  transform: translate(76.9124vw, -10px) scale(.4571)
}

@keyframes fall-38 {
  35.483% {
    transform: translate(85.3211vw, 35.483vh) scale(.4571)
  }

  to {
    transform: translate(81.11675vw, 100vh) scale(.4571)
  }
}

.snow i:nth-child(39) {
  animation: fall-39 27s linear -10s infinite;
  opacity: .1041;
  transform: translate(12.9057vw, -10px) scale(.9313)
}

@keyframes fall-39 {
  46.215% {
    transform: translate(19.002vw, 46.215vh) scale(.9313)
  }

  to {
    transform: translate(15.95385vw, 100vh) scale(.9313)
  }
}

.snow i:nth-child(40) {
  animation: fall-40 16s linear -27s infinite;
  opacity: .0244;
  transform: translate(58.9777vw, -10px) scale(.2942)
}

@keyframes fall-40 {
  62.762% {
    transform: translate(54.2464vw, 62.762vh) scale(.2942)
  }

  to {
    transform: translate(56.61205vw, 100vh) scale(.2942)
  }
}

.snow i:nth-child(41) {
  animation: fall-41 14s linear -30s infinite;
  opacity: .4952;
  transform: translate(92.0797vw, -10px) scale(.4864)
}

@keyframes fall-41 {
  35.602% {
    transform: translate(89.1559vw, 35.602vh) scale(.4864)
  }

  to {
    transform: translate(90.6178vw, 100vh) scale(.4864)
  }
}

.snow i:nth-child(42) {
  animation: fall-42 15s linear -23s infinite;
  opacity: .8362;
  transform: translate(70.3311vw, -10px) scale(.6078)
}

@keyframes fall-42 {
  68.562% {
    transform: translate(77.0109vw, 68.562vh) scale(.6078)
  }

  to {
    transform: translate(73.671vw, 100vh) scale(.6078)
  }
}

.snow i:nth-child(43) {
  animation: fall-43 22s linear -3s infinite;
  opacity: .3269;
  transform: translate(67.8198vw, -10px) scale(.1527)
}

@keyframes fall-43 {
  35.768% {
    transform: translate(70.3149vw, 35.768vh) scale(.1527)
  }

  to {
    transform: translate(69.06735vw, 100vh) scale(.1527)
  }
}

.snow i:nth-child(44) {
  animation: fall-44 20s linear -12s infinite;
  opacity: .0779;
  transform: translate(26.5858vw, -10px) scale(.5446)
}

@keyframes fall-44 {
  54.696% {
    transform: translate(34.3667vw, 54.696vh) scale(.5446)
  }

  to {
    transform: translate(30.47625vw, 100vh) scale(.5446)
  }
}

.snow i:nth-child(45) {
  animation: fall-45 20s linear -18s infinite;
  opacity: .8792;
  transform: translate(13.5292vw, -10px) scale(.959)
}

@keyframes fall-45 {
  63.051% {
    transform: translate(23.1798vw, 63.051vh) scale(.959)
  }

  to {
    transform: translate(18.3545vw, 100vh) scale(.959)
  }
}

.snow i:nth-child(46) {
  animation: fall-46 29s linear -5s infinite;
  opacity: .5045;
  transform: translate(27.1069vw, -10px) scale(.6238)
}

@keyframes fall-46 {
  63.635% {
    transform: translate(21.8712vw, 63.635vh) scale(.6238)
  }

  to {
    transform: translate(24.48905vw, 100vh) scale(.6238)
  }
}

.snow i:nth-child(47) {
  animation: fall-47 18s linear -27s infinite;
  opacity: .58;
  transform: translate(2.6671vw, -10px) scale(.7195)
}

@keyframes fall-47 {
  53.339% {
    transform: translate(9.7097vw, 53.339vh) scale(.7195)
  }

  to {
    transform: translate(6.1884vw, 100vh) scale(.7195)
  }
}

.snow i:nth-child(48) {
  animation: fall-48 30s linear -21s infinite;
  opacity: .2436;
  transform: translate(52.3797vw, -10px) scale(.447)
}

@keyframes fall-48 {
  56.998% {
    transform: translate(57.2749vw, 56.998vh) scale(.447)
  }

  to {
    transform: translate(54.8273vw, 100vh) scale(.447)
  }
}

.snow i:nth-child(49) {
  animation: fall-49 10s linear -23s infinite;
  opacity: .9796;
  transform: translate(38.1344vw, -10px) scale(.2715)
}

@keyframes fall-49 {
  68.108% {
    transform: translate(32.343vw, 68.108vh) scale(.2715)
  }

  to {
    transform: translate(35.2387vw, 100vh) scale(.2715)
  }
}

.snow i:nth-child(50) {
  animation: fall-50 11s linear -22s infinite;
  opacity: .5397;
  transform: translate(87.0072vw, -10px) scale(.0044)
}

@keyframes fall-50 {
  40.815% {
    transform: translate(86.6026vw, 40.815vh) scale(.0044)
  }

  to {
    transform: translate(86.8049vw, 100vh) scale(.0044)
  }
}

.snow i:nth-child(51) {
  animation: fall-51 11s linear -9s infinite;
  opacity: .1775;
  transform: translate(14.2887vw, -10px) scale(.6201)
}

@keyframes fall-51 {
  34.443% {
    transform: translate(7.5795vw, 34.443vh) scale(.6201)
  }

  to {
    transform: translate(10.9341vw, 100vh) scale(.6201)
  }
}

.snow i:nth-child(52) {
  animation: fall-52 11s linear -18s infinite;
  opacity: .5454;
  transform: translate(54.4771vw, -10px) scale(.3068)
}

@keyframes fall-52 {
  78.239% {
    transform: translate(51.4633vw, 78.239vh) scale(.3068)
  }

  to {
    transform: translate(52.9702vw, 100vh) scale(.3068)
  }
}

.snow i:nth-child(53) {
  animation: fall-53 26s linear -24s infinite;
  opacity: .7572;
  transform: translate(27.762vw, -10px) scale(.3845)
}

@keyframes fall-53 {
  64.112% {
    transform: translate(30.1765vw, 64.112vh) scale(.3845)
  }

  to {
    transform: translate(28.96925vw, 100vh) scale(.3845)
  }
}

.snow i:nth-child(54) {
  animation: fall-54 14s linear -4s infinite;
  opacity: .6006;
  transform: translate(95.5701vw, -10px) scale(.2789)
}

@keyframes fall-54 {
  59.723% {
    transform: translate(90.5614vw, 59.723vh) scale(.2789)
  }

  to {
    transform: translate(93.06575vw, 100vh) scale(.2789)
  }
}

.snow i:nth-child(55) {
  animation: fall-55 24s linear -21s infinite;
  opacity: .1615;
  transform: translate(31.8679vw, -10px) scale(.5555)
}

@keyframes fall-55 {
  53.544% {
    transform: translate(26.8874vw, 53.544vh) scale(.5555)
  }

  to {
    transform: translate(29.37765vw, 100vh) scale(.5555)
  }
}

.snow i:nth-child(56) {
  animation: fall-56 25s linear -16s infinite;
  opacity: .2318;
  transform: translate(78.2402vw, -10px) scale(.2189)
}

@keyframes fall-56 {
  65.923% {
    transform: translate(77.9878vw, 65.923vh) scale(.2189)
  }

  to {
    transform: translate(78.114vw, 100vh) scale(.2189)
  }
}

.snow i:nth-child(57) {
  animation: fall-57 24s linear -26s infinite;
  opacity: .4048;
  transform: translate(94.8921vw, -10px) scale(.4891)
}

@keyframes fall-57 {
  78.156% {
    transform: translate(88.3518vw, 78.156vh) scale(.4891)
  }

  to {
    transform: translate(91.62195vw, 100vh) scale(.4891)
  }
}

.snow i:nth-child(58) {
  animation: fall-58 29s linear -28s infinite;
  opacity: .4117;
  transform: translate(9.2177vw, -10px) scale(.7645)
}

@keyframes fall-58 {
  62.73% {
    transform: translate(3.4933vw, 62.73vh) scale(.7645)
  }

  to {
    transform: translate(6.3555vw, 100vh) scale(.7645)
  }
}

.snow i:nth-child(59) {
  animation: fall-59 11s linear -8s infinite;
  opacity: .7636;
  transform: translate(56.1936vw, -10px) scale(.3991)
}

@keyframes fall-59 {
  64.373% {
    transform: translate(64.9966vw, 64.373vh) scale(.3991)
  }

  to {
    transform: translate(60.5951vw, 100vh) scale(.3991)
  }
}

.snow i:nth-child(60) {
  animation: fall-60 29s linear -21s infinite;
  opacity: .3695;
  transform: translate(60.1943vw, -10px) scale(.2165)
}

@keyframes fall-60 {
  70.043% {
    transform: translate(70.1801vw, 70.043vh) scale(.2165)
  }

  to {
    transform: translate(65.1872vw, 100vh) scale(.2165)
  }
}

.snow i:nth-child(61) {
  animation: fall-61 10s linear -22s infinite;
  opacity: .3912;
  transform: translate(27.6047vw, -10px) scale(.6282)
}

@keyframes fall-61 {
  49.707% {
    transform: translate(34.2033vw, 49.707vh) scale(.6282)
  }

  to {
    transform: translate(30.904vw, 100vh) scale(.6282)
  }
}

.snow i:nth-child(62) {
  animation: fall-62 10s linear -20s infinite;
  opacity: .1297;
  transform: translate(93.7326vw, -10px) scale(.7875)
}

@keyframes fall-62 {
  51.616% {
    transform: translate(91.489vw, 51.616vh) scale(.7875)
  }

  to {
    transform: translate(92.6108vw, 100vh) scale(.7875)
  }
}

.snow i:nth-child(63) {
  animation: fall-63 23s linear -24s infinite;
  opacity: .7379;
  transform: translate(89.6409vw, -10px) scale(.0132)
}

@keyframes fall-63 {
  34.846% {
    transform: translate(87.0553vw, 34.846vh) scale(.0132)
  }

  to {
    transform: translate(88.3481vw, 100vh) scale(.0132)
  }
}

.snow i:nth-child(64) {
  animation: fall-64 24s linear -9s infinite;
  opacity: .9226;
  transform: translate(66.9365vw, -10px) scale(.6357)
}

@keyframes fall-64 {
  35.841% {
    transform: translate(57.5548vw, 35.841vh) scale(.6357)
  }

  to {
    transform: translate(62.24565vw, 100vh) scale(.6357)
  }
}

.snow i:nth-child(65) {
  animation: fall-65 19s linear -13s infinite;
  opacity: .3383;
  transform: translate(52.2518vw, -10px) scale(.7328)
}

@keyframes fall-65 {
  75.596% {
    transform: translate(45.1646vw, 75.596vh) scale(.7328)
  }

  to {
    transform: translate(48.7082vw, 100vh) scale(.7328)
  }
}

.snow i:nth-child(66) {
  animation: fall-66 25s linear -20s infinite;
  opacity: .6004;
  transform: translate(68.5245vw, -10px) scale(.0014)
}

@keyframes fall-66 {
  67.188% {
    transform: translate(61.0907vw, 67.188vh) scale(.0014)
  }

  to {
    transform: translate(64.8076vw, 100vh) scale(.0014)
  }
}

.snow i:nth-child(67) {
  animation: fall-67 10s linear -28s infinite;
  opacity: .6656;
  transform: translate(48.5527vw, -10px) scale(.8851)
}

@keyframes fall-67 {
  44.694% {
    transform: translate(51.9551vw, 44.694vh) scale(.8851)
  }

  to {
    transform: translate(50.2539vw, 100vh) scale(.8851)
  }
}

.snow i:nth-child(68) {
  animation: fall-68 22s linear -23s infinite;
  opacity: .5672;
  transform: translate(2.7821vw, -10px) scale(.5528)
}

@keyframes fall-68 {
  43.092% {
    transform: translate(9.7043vw, 43.092vh) scale(.5528)
  }

  to {
    transform: translate(6.2432vw, 100vh) scale(.5528)
  }
}

.snow i:nth-child(69) {
  animation: fall-69 15s linear -9s infinite;
  opacity: .3114;
  transform: translate(14.988vw, -10px) scale(.5227)
}

@keyframes fall-69 {
  50.759% {
    transform: translate(7.6265vw, 50.759vh) scale(.5227)
  }

  to {
    transform: translate(11.30725vw, 100vh) scale(.5227)
  }
}

.snow i:nth-child(70) {
  animation: fall-70 14s linear -24s infinite;
  opacity: .377;
  transform: translate(98.2062vw, -10px) scale(.2448)
}

@keyframes fall-70 {
  47.132% {
    transform: translate(94.2978vw, 47.132vh) scale(.2448)
  }

  to {
    transform: translate(96.252vw, 100vh) scale(.2448)
  }
}

.snow i:nth-child(71) {
  animation: fall-71 30s linear -17s infinite;
  opacity: .286;
  transform: translate(12.9821vw, -10px) scale(.0912)
}

@keyframes fall-71 {
  67.156% {
    transform: translate(8.4469vw, 67.156vh) scale(.0912)
  }

  to {
    transform: translate(10.7145vw, 100vh) scale(.0912)
  }
}

.snow i:nth-child(72) {
  animation: fall-72 21s linear -27s infinite;
  opacity: .6098;
  transform: translate(17.0425vw, -10px) scale(.4266)
}

@keyframes fall-72 {
  54.072% {
    transform: translate(23.6097vw, 54.072vh) scale(.4266)
  }

  to {
    transform: translate(20.3261vw, 100vh) scale(.4266)
  }
}

.snow i:nth-child(73) {
  animation: fall-73 19s linear -30s infinite;
  opacity: .3202;
  transform: translate(55.3043vw, -10px) scale(.3421)
}

@keyframes fall-73 {
  34.559% {
    transform: translate(63.3512vw, 34.559vh) scale(.3421)
  }

  to {
    transform: translate(59.32775vw, 100vh) scale(.3421)
  }
}

.snow i:nth-child(74) {
  animation: fall-74 16s linear -9s infinite;
  opacity: .048;
  transform: translate(22.5309vw, -10px) scale(.484)
}

@keyframes fall-74 {
  71.908% {
    transform: translate(25.4595vw, 71.908vh) scale(.484)
  }

  to {
    transform: translate(23.9952vw, 100vh) scale(.484)
  }
}

.snow i:nth-child(75) {
  animation: fall-75 23s linear -27s infinite;
  opacity: .1411;
  transform: translate(27.5275vw, -10px) scale(.2941)
}

@keyframes fall-75 {
  71.197% {
    transform: translate(25.7332vw, 71.197vh) scale(.2941)
  }

  to {
    transform: translate(26.63035vw, 100vh) scale(.2941)
  }
}

.snow i:nth-child(76) {
  animation: fall-76 16s linear -17s infinite;
  opacity: .1787;
  transform: translate(29.0433vw, -10px) scale(.7808)
}

@keyframes fall-76 {
  68.613% {
    transform: translate(37.9289vw, 68.613vh) scale(.7808)
  }

  to {
    transform: translate(33.4861vw, 100vh) scale(.7808)
  }
}

.snow i:nth-child(77) {
  animation: fall-77 25s linear -23s infinite;
  opacity: .109;
  transform: translate(20.9466vw, -10px) scale(.0145)
}

@keyframes fall-77 {
  52.332% {
    transform: translate(21.7116vw, 52.332vh) scale(.0145)
  }

  to {
    transform: translate(21.3291vw, 100vh) scale(.0145)
  }
}

.snow i:nth-child(78) {
  animation: fall-78 11s linear -24s infinite;
  opacity: .3248;
  transform: translate(17.1456vw, -10px) scale(.4124)
}

@keyframes fall-78 {
  60.6% {
    transform: translate(24.349vw, 60.6vh) scale(.4124)
  }

  to {
    transform: translate(20.7473vw, 100vh) scale(.4124)
  }
}

.snow i:nth-child(79) {
  animation: fall-79 15s linear -21s infinite;
  opacity: .5759;
  transform: translate(50.3852vw, -10px) scale(.9918)
}

@keyframes fall-79 {
  53.118% {
    transform: translate(46.8314vw, 53.118vh) scale(.9918)
  }

  to {
    transform: translate(48.6083vw, 100vh) scale(.9918)
  }
}

.snow i:nth-child(80) {
  animation: fall-80 16s linear -13s infinite;
  opacity: .2468;
  transform: translate(74.9746vw, -10px) scale(.0996)
}

@keyframes fall-80 {
  78.035% {
    transform: translate(76.9732vw, 78.035vh) scale(.0996)
  }

  to {
    transform: translate(75.9739vw, 100vh) scale(.0996)
  }
}

.snow i:nth-child(81) {
  animation: fall-81 29s linear -13s infinite;
  opacity: .7984;
  transform: translate(11.8348vw, -10px) scale(.2547)
}

@keyframes fall-81 {
  31.934% {
    transform: translate(13.8485vw, 31.934vh) scale(.2547)
  }

  to {
    transform: translate(12.84165vw, 100vh) scale(.2547)
  }
}

.snow i:nth-child(82) {
  animation: fall-82 26s linear -16s infinite;
  opacity: .7247;
  transform: translate(67.7714vw, -10px) scale(.877)
}

@keyframes fall-82 {
  67.048% {
    transform: translate(76.959vw, 67.048vh) scale(.877)
  }

  to {
    transform: translate(72.3652vw, 100vh) scale(.877)
  }
}

.snow i:nth-child(83) {
  animation: fall-83 21s linear -18s infinite;
  opacity: .8201;
  transform: translate(7.5098vw, -10px) scale(.3085)
}

@keyframes fall-83 {
  35.47% {
    transform: translate(9.86vw, 35.47vh) scale(.3085)
  }

  to {
    transform: translate(8.6849vw, 100vh) scale(.3085)
  }
}

.snow i:nth-child(84) {
  animation: fall-84 20s linear -20s infinite;
  opacity: .4861;
  transform: translate(3.076vw, -10px) scale(.4554)
}

@keyframes fall-84 {
  58.557% {
    transform: translate(4.5745vw, 58.557vh) scale(.4554)
  }

  to {
    transform: translate(3.82525vw, 100vh) scale(.4554)
  }
}

.snow i:nth-child(85) {
  animation: fall-85 11s linear -1s infinite;
  opacity: .2465;
  transform: translate(35.7431vw, -10px) scale(.9384)
}

@keyframes fall-85 {
  72.799% {
    transform: translate(33.2633vw, 72.799vh) scale(.9384)
  }

  to {
    transform: translate(34.5032vw, 100vh) scale(.9384)
  }
}

.snow i:nth-child(86) {
  animation: fall-86 28s linear -23s infinite;
  opacity: .207;
  transform: translate(90.5441vw, -10px) scale(.9541)
}

@keyframes fall-86 {
  52.135% {
    transform: translate(84.521vw, 52.135vh) scale(.9541)
  }

  to {
    transform: translate(87.53255vw, 100vh) scale(.9541)
  }
}

.snow i:nth-child(87) {
  animation: fall-87 23s linear -29s infinite;
  opacity: .8755;
  transform: translate(32.0942vw, -10px) scale(.7941)
}

@keyframes fall-87 {
  60.958% {
    transform: translate(29.2347vw, 60.958vh) scale(.7941)
  }

  to {
    transform: translate(30.66445vw, 100vh) scale(.7941)
  }
}

.snow i:nth-child(88) {
  animation: fall-88 19s linear -11s infinite;
  opacity: .2146;
  transform: translate(46.8768vw, -10px) scale(.7964)
}

@keyframes fall-88 {
  71.723% {
    transform: translate(40.5434vw, 71.723vh) scale(.7964)
  }

  to {
    transform: translate(43.7101vw, 100vh) scale(.7964)
  }
}

.snow i:nth-child(89) {
  animation: fall-89 17s linear -19s infinite;
  opacity: .5794;
  transform: translate(50.4113vw, -10px) scale(.0202)
}

@keyframes fall-89 {
  55.659% {
    transform: translate(53.8968vw, 55.659vh) scale(.0202)
  }

  to {
    transform: translate(52.15405vw, 100vh) scale(.0202)
  }
}

.snow i:nth-child(90) {
  animation: fall-90 23s linear -26s infinite;
  opacity: .0647;
  transform: translate(41.1025vw, -10px) scale(.2621)
}

@keyframes fall-90 {
  38.346% {
    transform: translate(50.3373vw, 38.346vh) scale(.2621)
  }

  to {
    transform: translate(45.7199vw, 100vh) scale(.2621)
  }
}

.snow i:nth-child(91) {
  animation: fall-91 27s linear -25s infinite;
  opacity: .1821;
  transform: translate(82.1029vw, -10px) scale(.7028)
}

@keyframes fall-91 {
  37.708% {
    transform: translate(72.2787vw, 37.708vh) scale(.7028)
  }

  to {
    transform: translate(77.1908vw, 100vh) scale(.7028)
  }
}

.snow i:nth-child(92) {
  animation: fall-92 10s linear -2s infinite;
  opacity: .8996;
  transform: translate(76.4716vw, -10px) scale(.1763)
}

@keyframes fall-92 {
  61.788% {
    transform: translate(84.8238vw, 61.788vh) scale(.1763)
  }

  to {
    transform: translate(80.6477vw, 100vh) scale(.1763)
  }
}

.snow i:nth-child(93) {
  animation: fall-93 24s linear -18s infinite;
  opacity: .0467;
  transform: translate(2.6514vw, -10px) scale(.3533)
}

@keyframes fall-93 {
  69.896% {
    transform: translate(6.8491vw, 69.896vh) scale(.3533)
  }

  to {
    transform: translate(4.75025vw, 100vh) scale(.3533)
  }
}

.snow i:nth-child(94) {
  animation: fall-94 18s linear -28s infinite;
  opacity: .405;
  transform: translate(32.2812vw, -10px) scale(.8718)
}

@keyframes fall-94 {
  62.161% {
    transform: translate(27.7929vw, 62.161vh) scale(.8718)
  }

  to {
    transform: translate(30.03705vw, 100vh) scale(.8718)
  }
}

.snow i:nth-child(95) {
  animation: fall-95 11s linear -13s infinite;
  opacity: .0979;
  transform: translate(40.5711vw, -10px) scale(.799)
}

@keyframes fall-95 {
  44.719% {
    transform: translate(36.3282vw, 44.719vh) scale(.799)
  }

  to {
    transform: translate(38.44965vw, 100vh) scale(.799)
  }
}

.snow i:nth-child(96) {
  animation: fall-96 15s linear -7s infinite;
  opacity: .9106;
  transform: translate(50.6244vw, -10px) scale(.001)
}

@keyframes fall-96 {
  64.628% {
    transform: translate(44.9515vw, 64.628vh) scale(.001)
  }

  to {
    transform: translate(47.78795vw, 100vh) scale(.001)
  }
}

.snow i:nth-child(97) {
  animation: fall-97 30s linear -21s infinite;
  opacity: .1233;
  transform: translate(73.8916vw, -10px) scale(.4837)
}

@keyframes fall-97 {
  73.507% {
    transform: translate(66.0478vw, 73.507vh) scale(.4837)
  }

  to {
    transform: translate(69.9697vw, 100vh) scale(.4837)
  }
}

.snow i:nth-child(98) {
  animation: fall-98 12s linear -23s infinite;
  opacity: .1192;
  transform: translate(82.3355vw, -10px) scale(.46)
}

@keyframes fall-98 {
  75.212% {
    transform: translate(85.1431vw, 75.212vh) scale(.46)
  }

  to {
    transform: translate(83.7393vw, 100vh) scale(.46)
  }
}

.snow i:nth-child(99) {
  animation: fall-99 14s linear -1s infinite;
  opacity: .2132;
  transform: translate(49.0718vw, -10px) scale(.4019)
}

@keyframes fall-99 {
  43.23% {
    transform: translate(46.9645vw, 43.23vh) scale(.4019)
  }

  to {
    transform: translate(48.01815vw, 100vh) scale(.4019)
  }
}

.snow i:nth-child(100) {
  animation: fall-100 25s linear -14s infinite;
  opacity: .2619;
  transform: translate(41.7563vw, -10px) scale(.5453)
}

@keyframes fall-100 {
  35.011% {
    transform: translate(41.7954vw, 35.011vh) scale(.5453)
  }

  to {
    transform: translate(41.77585vw, 100vh) scale(.5453)
  }
}

.snow i:nth-child(101) {
  animation: fall-101 25s linear -21s infinite;
  opacity: .1315;
  transform: translate(69.3873vw, -10px) scale(.8786)
}

@keyframes fall-101 {
  50.478% {
    transform: translate(70.0922vw, 50.478vh) scale(.8786)
  }

  to {
    transform: translate(69.73975vw, 100vh) scale(.8786)
  }
}

.snow i:nth-child(102) {
  animation: fall-102 20s linear -26s infinite;
  opacity: .9276;
  transform: translate(7.7639vw, -10px) scale(.4706)
}

@keyframes fall-102 {
  38.633% {
    transform: translate(4.436vw, 38.633vh) scale(.4706)
  }

  to {
    transform: translate(6.09995vw, 100vh) scale(.4706)
  }
}

.snow i:nth-child(103) {
  animation: fall-103 23s linear -9s infinite;
  opacity: .5042;
  transform: translate(37.3065vw, -10px) scale(.147)
}

@keyframes fall-103 {
  64.728% {
    transform: translate(36.6526vw, 64.728vh) scale(.147)
  }

  to {
    transform: translate(36.97955vw, 100vh) scale(.147)
  }
}

.snow i:nth-child(104) {
  animation: fall-104 11s linear -19s infinite;
  opacity: .517;
  transform: translate(94.5214vw, -10px) scale(.6237)
}

@keyframes fall-104 {
  65.111% {
    transform: translate(98.6786vw, 65.111vh) scale(.6237)
  }

  to {
    transform: translate(96.6vw, 100vh) scale(.6237)
  }
}

.snow i:nth-child(105) {
  animation: fall-105 13s linear -7s infinite;
  opacity: .0509;
  transform: translate(67.3973vw, -10px) scale(.2501)
}

@keyframes fall-105 {
  76.34% {
    transform: translate(62.974vw, 76.34vh) scale(.2501)
  }

  to {
    transform: translate(65.18565vw, 100vh) scale(.2501)
  }
}

.snow i:nth-child(106) {
  animation: fall-106 17s linear -17s infinite;
  opacity: .2827;
  transform: translate(50.0161vw, -10px) scale(.6496)
}

@keyframes fall-106 {
  35.064% {
    transform: translate(53.1421vw, 35.064vh) scale(.6496)
  }

  to {
    transform: translate(51.5791vw, 100vh) scale(.6496)
  }
}

.snow i:nth-child(107) {
  animation: fall-107 28s linear -22s infinite;
  opacity: .5387;
  transform: translate(72.3495vw, -10px) scale(.5311)
}

@keyframes fall-107 {
  76.197% {
    transform: translate(65.6743vw, 76.197vh) scale(.5311)
  }

  to {
    transform: translate(69.0119vw, 100vh) scale(.5311)
  }
}

.snow i:nth-child(108) {
  animation: fall-108 23s linear -21s infinite;
  opacity: .2048;
  transform: translate(14.6964vw, -10px) scale(.1163)
}

@keyframes fall-108 {
  65.106% {
    transform: translate(4.7895vw, 65.106vh) scale(.1163)
  }

  to {
    transform: translate(9.74295vw, 100vh) scale(.1163)
  }
}

.snow i:nth-child(109) {
  animation: fall-109 17s linear -22s infinite;
  opacity: .1932;
  transform: translate(58.099vw, -10px) scale(.7725)
}

@keyframes fall-109 {
  58.73% {
    transform: translate(63.8377vw, 58.73vh) scale(.7725)
  }

  to {
    transform: translate(60.96835vw, 100vh) scale(.7725)
  }
}

.snow i:nth-child(110) {
  animation: fall-110 19s linear -30s infinite;
  opacity: .0151;
  transform: translate(72.882vw, -10px) scale(.0813)
}

@keyframes fall-110 {
  54.273% {
    transform: translate(72.7946vw, 54.273vh) scale(.0813)
  }

  to {
    transform: translate(72.8383vw, 100vh) scale(.0813)
  }
}

.snow i:nth-child(111) {
  animation: fall-111 27s linear -12s infinite;
  opacity: .4423;
  transform: translate(63.3427vw, -10px) scale(.2093)
}

@keyframes fall-111 {
  67.013% {
    transform: translate(72.8451vw, 67.013vh) scale(.2093)
  }

  to {
    transform: translate(68.0939vw, 100vh) scale(.2093)
  }
}

.snow i:nth-child(112) {
  animation: fall-112 22s linear -27s infinite;
  opacity: .8977;
  transform: translate(7.0022vw, -10px) scale(.9037)
}

@keyframes fall-112 {
  77.63% {
    transform: translate(8.9924vw, 77.63vh) scale(.9037)
  }

  to {
    transform: translate(7.9973vw, 100vh) scale(.9037)
  }
}

.snow i:nth-child(113) {
  animation: fall-113 19s linear -17s infinite;
  opacity: .7836;
  transform: translate(99.668vw, -10px) scale(.4667)
}

@keyframes fall-113 {
  78.618% {
    transform: translate(103.9506vw, 78.618vh) scale(.4667)
  }

  to {
    transform: translate(101.8093vw, 100vh) scale(.4667)
  }
}

.snow i:nth-child(114) {
  animation: fall-114 21s linear -8s infinite;
  opacity: .8703;
  transform: translate(86.9761vw, -10px) scale(.1819)
}

@keyframes fall-114 {
  57.489% {
    transform: translate(82.2345vw, 57.489vh) scale(.1819)
  }

  to {
    transform: translate(84.6053vw, 100vh) scale(.1819)
  }
}

.snow i:nth-child(115) {
  animation: fall-115 11s linear -2s infinite;
  opacity: .1107;
  transform: translate(10.9983vw, -10px) scale(.2951)
}

@keyframes fall-115 {
  78.08% {
    transform: translate(15.3589vw, 78.08vh) scale(.2951)
  }

  to {
    transform: translate(13.1786vw, 100vh) scale(.2951)
  }
}

.snow i:nth-child(116) {
  animation: fall-116 16s linear -11s infinite;
  opacity: .2065;
  transform: translate(93.1014vw, -10px) scale(.9657)
}

@keyframes fall-116 {
  58.05% {
    transform: translate(90.2688vw, 58.05vh) scale(.9657)
  }

  to {
    transform: translate(91.6851vw, 100vh) scale(.9657)
  }
}

.snow i:nth-child(117) {
  animation: fall-117 24s linear -9s infinite;
  opacity: .3942;
  transform: translate(76.1894vw, -10px) scale(.3164)
}

@keyframes fall-117 {
  36.117% {
    transform: translate(79.783vw, 36.117vh) scale(.3164)
  }

  to {
    transform: translate(77.9862vw, 100vh) scale(.3164)
  }
}

.snow i:nth-child(118) {
  animation: fall-118 26s linear -27s infinite;
  opacity: .0741;
  transform: translate(50.7043vw, -10px) scale(.8859)
}

@keyframes fall-118 {
  50.005% {
    transform: translate(44.6649vw, 50.005vh) scale(.8859)
  }

  to {
    transform: translate(47.6846vw, 100vh) scale(.8859)
  }
}

.snow i:nth-child(119) {
  animation: fall-119 19s linear -23s infinite;
  opacity: .6921;
  transform: translate(15.4672vw, -10px) scale(.3751)
}

@keyframes fall-119 {
  60.373% {
    transform: translate(20.9834vw, 60.373vh) scale(.3751)
  }

  to {
    transform: translate(18.2253vw, 100vh) scale(.3751)
  }
}

.snow i:nth-child(120) {
  animation: fall-120 24s linear -25s infinite;
  opacity: .6847;
  transform: translate(11.2559vw, -10px) scale(.4371)
}

@keyframes fall-120 {
  75.433% {
    transform: translate(4.9737vw, 75.433vh) scale(.4371)
  }

  to {
    transform: translate(8.1148vw, 100vh) scale(.4371)
  }
}

.snow i:nth-child(121) {
  animation: fall-121 11s linear -4s infinite;
  opacity: .5105;
  transform: translate(55.3921vw, -10px) scale(.116)
}

@keyframes fall-121 {
  40.409% {
    transform: translate(59.2435vw, 40.409vh) scale(.116)
  }

  to {
    transform: translate(57.3178vw, 100vh) scale(.116)
  }
}

.snow i:nth-child(122) {
  animation: fall-122 23s linear -21s infinite;
  opacity: .4098;
  transform: translate(27.3797vw, -10px) scale(.4193)
}

@keyframes fall-122 {
  65.781% {
    transform: translate(24.6338vw, 65.781vh) scale(.4193)
  }

  to {
    transform: translate(26.00675vw, 100vh) scale(.4193)
  }
}

.snow i:nth-child(123) {
  animation: fall-123 16s linear -14s infinite;
  opacity: .7282;
  transform: translate(10.5842vw, -10px) scale(.9376)
}

@keyframes fall-123 {
  72.195% {
    transform: translate(6.2173vw, 72.195vh) scale(.9376)
  }

  to {
    transform: translate(8.40075vw, 100vh) scale(.9376)
  }
}

.snow i:nth-child(124) {
  animation: fall-124 24s linear -19s infinite;
  opacity: .1604;
  transform: translate(31.7151vw, -10px) scale(.7973)
}

@keyframes fall-124 {
  77.033% {
    transform: translate(24.3605vw, 77.033vh) scale(.7973)
  }

  to {
    transform: translate(28.0378vw, 100vh) scale(.7973)
  }
}

.snow i:nth-child(125) {
  animation: fall-125 27s linear -21s infinite;
  opacity: .0519;
  transform: translate(24.4943vw, -10px) scale(.8191)
}

@keyframes fall-125 {
  58.573% {
    transform: translate(23.8228vw, 58.573vh) scale(.8191)
  }

  to {
    transform: translate(24.15855vw, 100vh) scale(.8191)
  }
}

.snow i:nth-child(126) {
  animation: fall-126 11s linear -8s infinite;
  opacity: .3377;
  transform: translate(9.2777vw, -10px) scale(.5742)
}

@keyframes fall-126 {
  49.974% {
    transform: translate(3.4216vw, 49.974vh) scale(.5742)
  }

  to {
    transform: translate(6.34965vw, 100vh) scale(.5742)
  }
}

.snow i:nth-child(127) {
  animation: fall-127 17s linear -12s infinite;
  opacity: .8787;
  transform: translate(7.6284vw, -10px) scale(.135)
}

@keyframes fall-127 {
  56.648% {
    transform: translate(7.8209vw, 56.648vh) scale(.135)
  }

  to {
    transform: translate(7.72465vw, 100vh) scale(.135)
  }
}

.snow i:nth-child(128) {
  animation: fall-128 16s linear -20s infinite;
  opacity: .9887;
  transform: translate(42.691vw, -10px) scale(.4326)
}

@keyframes fall-128 {
  69.893% {
    transform: translate(35.2389vw, 69.893vh) scale(.4326)
  }

  to {
    transform: translate(38.96495vw, 100vh) scale(.4326)
  }
}

.snow i:nth-child(129) {
  animation: fall-129 21s linear -4s infinite;
  opacity: .1069;
  transform: translate(8.7411vw, -10px) scale(.8121)
}

@keyframes fall-129 {
  69.188% {
    transform: translate(11.4681vw, 69.188vh) scale(.8121)
  }

  to {
    transform: translate(10.1046vw, 100vh) scale(.8121)
  }
}

.snow i:nth-child(130) {
  animation: fall-130 12s linear -17s infinite;
  opacity: .7308;
  transform: translate(83.4783vw, -10px) scale(.1556)
}

@keyframes fall-130 {
  67.163% {
    transform: translate(80.6938vw, 67.163vh) scale(.1556)
  }

  to {
    transform: translate(82.08605vw, 100vh) scale(.1556)
  }
}

.snow i:nth-child(131) {
  animation: fall-131 30s linear -19s infinite;
  opacity: .5149;
  transform: translate(10.6086vw, -10px) scale(.2106)
}

@keyframes fall-131 {
  71.362% {
    transform: translate(2.9685vw, 71.362vh) scale(.2106)
  }

  to {
    transform: translate(6.78855vw, 100vh) scale(.2106)
  }
}

.snow i:nth-child(132) {
  animation: fall-132 14s linear -16s infinite;
  opacity: .6497;
  transform: translate(50.0246vw, -10px) scale(.8966)
}

@keyframes fall-132 {
  33.363% {
    transform: translate(59.4814vw, 33.363vh) scale(.8966)
  }

  to {
    transform: translate(54.753vw, 100vh) scale(.8966)
  }
}

.snow i:nth-child(133) {
  animation: fall-133 14s linear -22s infinite;
  opacity: .254;
  transform: translate(51.7747vw, -10px) scale(.2121)
}

@keyframes fall-133 {
  75.617% {
    transform: translate(52.2768vw, 75.617vh) scale(.2121)
  }

  to {
    transform: translate(52.02575vw, 100vh) scale(.2121)
  }
}

.snow i:nth-child(134) {
  animation: fall-134 14s linear -10s infinite;
  opacity: .3365;
  transform: translate(94.8348vw, -10px) scale(.7118)
}

@keyframes fall-134 {
  64.951% {
    transform: translate(101.7853vw, 64.951vh) scale(.7118)
  }

  to {
    transform: translate(98.31005vw, 100vh) scale(.7118)
  }
}

.snow i:nth-child(135) {
  animation: fall-135 25s linear -4s infinite;
  opacity: .2428;
  transform: translate(47.7554vw, -10px) scale(.4372)
}

@keyframes fall-135 {
  35.812% {
    transform: translate(51.3414vw, 35.812vh) scale(.4372)
  }

  to {
    transform: translate(49.5484vw, 100vh) scale(.4372)
  }
}

.snow i:nth-child(136) {
  animation: fall-136 26s linear -2s infinite;
  opacity: .8068;
  transform: translate(75.7127vw, -10px) scale(.4359)
}

@keyframes fall-136 {
  66.7% {
    transform: translate(76.728vw, 66.7vh) scale(.4359)
  }

  to {
    transform: translate(76.22035vw, 100vh) scale(.4359)
  }
}

.snow i:nth-child(137) {
  animation: fall-137 28s linear -24s infinite;
  opacity: .3639;
  transform: translate(29.8968vw, -10px) scale(.5881)
}

@keyframes fall-137 {
  36.39% {
    transform: translate(29.7759vw, 36.39vh) scale(.5881)
  }

  to {
    transform: translate(29.83635vw, 100vh) scale(.5881)
  }
}

.snow i:nth-child(138) {
  animation: fall-138 12s linear -20s infinite;
  opacity: .4109;
  transform: translate(65.8207vw, -10px) scale(.4573)
}

@keyframes fall-138 {
  74.034% {
    transform: translate(68.148vw, 74.034vh) scale(.4573)
  }

  to {
    transform: translate(66.98435vw, 100vh) scale(.4573)
  }
}

.snow i:nth-child(139) {
  animation: fall-139 18s linear -13s infinite;
  opacity: .1198;
  transform: translate(49.1553vw, -10px) scale(.692)
}

@keyframes fall-139 {
  44.585% {
    transform: translate(52.6533vw, 44.585vh) scale(.692)
  }

  to {
    transform: translate(50.9043vw, 100vh) scale(.692)
  }
}

.snow i:nth-child(140) {
  animation: fall-140 30s linear -6s infinite;
  opacity: .3249;
  transform: translate(4.8767vw, -10px) scale(.7854)
}

@keyframes fall-140 {
  78.536% {
    transform: translate(4.3527vw, 78.536vh) scale(.7854)
  }

  to {
    transform: translate(4.6147vw, 100vh) scale(.7854)
  }
}

.snow i:nth-child(141) {
  animation: fall-141 13s linear -10s infinite;
  opacity: .8859;
  transform: translate(72.9876vw, -10px) scale(.671)
}

@keyframes fall-141 {
  58.703% {
    transform: translate(77.8882vw, 58.703vh) scale(.671)
  }

  to {
    transform: translate(75.4379vw, 100vh) scale(.671)
  }
}

.snow i:nth-child(142) {
  animation: fall-142 15s linear -20s infinite;
  opacity: .0695;
  transform: translate(81.7359vw, -10px) scale(.9787)
}

@keyframes fall-142 {
  51.741% {
    transform: translate(87.3468vw, 51.741vh) scale(.9787)
  }

  to {
    transform: translate(84.54135vw, 100vh) scale(.9787)
  }
}

.snow i:nth-child(143) {
  animation: fall-143 26s linear -6s infinite;
  opacity: .7705;
  transform: translate(17.5116vw, -10px) scale(.4854)
}

@keyframes fall-143 {
  34.364% {
    transform: translate(24.0901vw, 34.364vh) scale(.4854)
  }

  to {
    transform: translate(20.80085vw, 100vh) scale(.4854)
  }
}

.snow i:nth-child(144) {
  animation: fall-144 17s linear -22s infinite;
  opacity: .851;
  transform: translate(81.1078vw, -10px) scale(.4917)
}

@keyframes fall-144 {
  32.923% {
    transform: translate(83.0431vw, 32.923vh) scale(.4917)
  }

  to {
    transform: translate(82.07545vw, 100vh) scale(.4917)
  }
}

.snow i:nth-child(145) {
  animation: fall-145 11s linear -14s infinite;
  opacity: .5876;
  transform: translate(42.3594vw, -10px) scale(.3375)
}

@keyframes fall-145 {
  48.988% {
    transform: translate(42.1537vw, 48.988vh) scale(.3375)
  }

  to {
    transform: translate(42.25655vw, 100vh) scale(.3375)
  }
}

.snow i:nth-child(146) {
  animation: fall-146 24s linear -24s infinite;
  opacity: .2623;
  transform: translate(54.5051vw, -10px) scale(.6359)
}

@keyframes fall-146 {
  60.155% {
    transform: translate(57.7436vw, 60.155vh) scale(.6359)
  }

  to {
    transform: translate(56.12435vw, 100vh) scale(.6359)
  }
}

.snow i:nth-child(147) {
  animation: fall-147 16s linear -9s infinite;
  opacity: .508;
  transform: translate(50.2829vw, -10px) scale(.0742)
}

@keyframes fall-147 {
  54.265% {
    transform: translate(53.1261vw, 54.265vh) scale(.0742)
  }

  to {
    transform: translate(51.7045vw, 100vh) scale(.0742)
  }
}

.snow i:nth-child(148) {
  animation: fall-148 11s linear -26s infinite;
  opacity: .8301;
  transform: translate(87.3026vw, -10px) scale(.3231)
}

@keyframes fall-148 {
  58.849% {
    transform: translate(92.4727vw, 58.849vh) scale(.3231)
  }

  to {
    transform: translate(89.88765vw, 100vh) scale(.3231)
  }
}

.snow i:nth-child(149) {
  animation: fall-149 12s linear -28s infinite;
  opacity: .2715;
  transform: translate(31.6231vw, -10px) scale(.7783)
}

@keyframes fall-149 {
  71.451% {
    transform: translate(25.9577vw, 71.451vh) scale(.7783)
  }

  to {
    transform: translate(28.7904vw, 100vh) scale(.7783)
  }
}

.snow i:nth-child(150) {
  animation: fall-150 17s linear -24s infinite;
  opacity: .0873;
  transform: translate(92.0773vw, -10px) scale(.4323)
}

@keyframes fall-150 {
  47.296% {
    transform: translate(84.0396vw, 47.296vh) scale(.4323)
  }

  to {
    transform: translate(88.05845vw, 100vh) scale(.4323)
  }
}

.snow i:nth-child(151) {
  animation: fall-151 22s linear -13s infinite;
  opacity: .7247;
  transform: translate(21.7756vw, -10px) scale(.5151)
}

@keyframes fall-151 {
  54.575% {
    transform: translate(14.2307vw, 54.575vh) scale(.5151)
  }

  to {
    transform: translate(18.00315vw, 100vh) scale(.5151)
  }
}

.snow i:nth-child(152) {
  animation: fall-152 30s linear -28s infinite;
  opacity: .4651;
  transform: translate(88.9587vw, -10px) scale(.513)
}

@keyframes fall-152 {
  79.364% {
    transform: translate(82.3795vw, 79.364vh) scale(.513)
  }

  to {
    transform: translate(85.6691vw, 100vh) scale(.513)
  }
}

.snow i:nth-child(153) {
  animation: fall-153 16s linear -13s infinite;
  opacity: .9639;
  transform: translate(43.4331vw, -10px) scale(.5851)
}

@keyframes fall-153 {
  44.148% {
    transform: translate(46.6006vw, 44.148vh) scale(.5851)
  }

  to {
    transform: translate(45.01685vw, 100vh) scale(.5851)
  }
}

.snow i:nth-child(154) {
  animation: fall-154 13s linear -27s infinite;
  opacity: .8351;
  transform: translate(47.8167vw, -10px) scale(.278)
}

@keyframes fall-154 {
  56.648% {
    transform: translate(53.6373vw, 56.648vh) scale(.278)
  }

  to {
    transform: translate(50.727vw, 100vh) scale(.278)
  }
}

.snow i:nth-child(155) {
  animation: fall-155 18s linear -25s infinite;
  opacity: .2471;
  transform: translate(58.2344vw, -10px) scale(.2644)
}

@keyframes fall-155 {
  53.172% {
    transform: translate(51.2563vw, 53.172vh) scale(.2644)
  }

  to {
    transform: translate(54.74535vw, 100vh) scale(.2644)
  }
}

.snow i:nth-child(156) {
  animation: fall-156 20s linear -1s infinite;
  opacity: .6371;
  transform: translate(85.4286vw, -10px) scale(.805)
}

@keyframes fall-156 {
  35.227% {
    transform: translate(86.3438vw, 35.227vh) scale(.805)
  }

  to {
    transform: translate(85.8862vw, 100vh) scale(.805)
  }
}

.snow i:nth-child(157) {
  animation: fall-157 30s linear -6s infinite;
  opacity: .7598;
  transform: translate(33.8047vw, -10px) scale(.2325)
}

@keyframes fall-157 {
  53.511% {
    transform: translate(31.6347vw, 53.511vh) scale(.2325)
  }

  to {
    transform: translate(32.7197vw, 100vh) scale(.2325)
  }
}

.snow i:nth-child(158) {
  animation: fall-158 15s linear -29s infinite;
  opacity: .4028;
  transform: translate(51.3566vw, -10px) scale(.088)
}

@keyframes fall-158 {
  47.233% {
    transform: translate(53.9142vw, 47.233vh) scale(.088)
  }

  to {
    transform: translate(52.6354vw, 100vh) scale(.088)
  }
}

.snow i:nth-child(159) {
  animation: fall-159 22s linear -22s infinite;
  opacity: .2134;
  transform: translate(94.2605vw, -10px) scale(.1263)
}

@keyframes fall-159 {
  41.576% {
    transform: translate(89.6101vw, 41.576vh) scale(.1263)
  }

  to {
    transform: translate(91.9353vw, 100vh) scale(.1263)
  }
}

.snow i:nth-child(160) {
  animation: fall-160 14s linear -7s infinite;
  opacity: .0138;
  transform: translate(45.0982vw, -10px) scale(.8542)
}

@keyframes fall-160 {
  38.64% {
    transform: translate(38.4979vw, 38.64vh) scale(.8542)
  }

  to {
    transform: translate(41.79805vw, 100vh) scale(.8542)
  }
}

.snow i:nth-child(161) {
  animation: fall-161 29s linear -20s infinite;
  opacity: .8841;
  transform: translate(22.4632vw, -10px) scale(.171)
}

@keyframes fall-161 {
  31.139% {
    transform: translate(15.1202vw, 31.139vh) scale(.171)
  }

  to {
    transform: translate(18.7917vw, 100vh) scale(.171)
  }
}

.snow i:nth-child(162) {
  animation: fall-162 15s linear -8s infinite;
  opacity: .0024;
  transform: translate(29.0431vw, -10px) scale(.2577)
}

@keyframes fall-162 {
  34.833% {
    transform: translate(21.3179vw, 34.833vh) scale(.2577)
  }

  to {
    transform: translate(25.1805vw, 100vh) scale(.2577)
  }
}

.snow i:nth-child(163) {
  animation: fall-163 21s linear -22s infinite;
  opacity: .2304;
  transform: translate(25.1493vw, -10px) scale(.0506)
}

@keyframes fall-163 {
  34.502% {
    transform: translate(32.8512vw, 34.502vh) scale(.0506)
  }

  to {
    transform: translate(29.00025vw, 100vh) scale(.0506)
  }
}

.snow i:nth-child(164) {
  animation: fall-164 15s linear -28s infinite;
  opacity: .392;
  transform: translate(70.5803vw, -10px) scale(.1643)
}

@keyframes fall-164 {
  69.049% {
    transform: translate(78.4836vw, 69.049vh) scale(.1643)
  }

  to {
    transform: translate(74.53195vw, 100vh) scale(.1643)
  }
}

.snow i:nth-child(165) {
  animation: fall-165 17s linear -24s infinite;
  opacity: .6144;
  transform: translate(77.3173vw, -10px) scale(.1708)
}

@keyframes fall-165 {
  58.688% {
    transform: translate(81.932vw, 58.688vh) scale(.1708)
  }

  to {
    transform: translate(79.62465vw, 100vh) scale(.1708)
  }
}

.snow i:nth-child(166) {
  animation: fall-166 20s linear -29s infinite;
  opacity: .3915;
  transform: translate(29.8888vw, -10px) scale(.1412)
}

@keyframes fall-166 {
  68.304% {
    transform: translate(29.3935vw, 68.304vh) scale(.1412)
  }

  to {
    transform: translate(29.64115vw, 100vh) scale(.1412)
  }
}

.snow i:nth-child(167) {
  animation: fall-167 16s linear -13s infinite;
  opacity: .2798;
  transform: translate(93.4799vw, -10px) scale(.2111)
}

@keyframes fall-167 {
  34.765% {
    transform: translate(93.0954vw, 34.765vh) scale(.2111)
  }

  to {
    transform: translate(93.28765vw, 100vh) scale(.2111)
  }
}

.snow i:nth-child(168) {
  animation: fall-168 22s linear -6s infinite;
  opacity: .8259;
  transform: translate(76.1709vw, -10px) scale(.0075)
}

@keyframes fall-168 {
  62.649% {
    transform: translate(67.0703vw, 62.649vh) scale(.0075)
  }

  to {
    transform: translate(71.6206vw, 100vh) scale(.0075)
  }
}

.snow i:nth-child(169) {
  animation: fall-169 20s linear -7s infinite;
  opacity: .4996;
  transform: translate(64.4471vw, -10px) scale(.6804)
}

@keyframes fall-169 {
  49.387% {
    transform: translate(63.9702vw, 49.387vh) scale(.6804)
  }

  to {
    transform: translate(64.20865vw, 100vh) scale(.6804)
  }
}

.snow i:nth-child(170) {
  animation: fall-170 15s linear -14s infinite;
  opacity: .2417;
  transform: translate(53.719vw, -10px) scale(.9033)
}

@keyframes fall-170 {
  66.236% {
    transform: translate(57.7827vw, 66.236vh) scale(.9033)
  }

  to {
    transform: translate(55.75085vw, 100vh) scale(.9033)
  }
}

.snow i:nth-child(171) {
  animation: fall-171 14s linear -25s infinite;
  opacity: .9978;
  transform: translate(22.0179vw, -10px) scale(.7153)
}

@keyframes fall-171 {
  60.876% {
    transform: translate(28.037vw, 60.876vh) scale(.7153)
  }

  to {
    transform: translate(25.02745vw, 100vh) scale(.7153)
  }
}

.snow i:nth-child(172) {
  animation: fall-172 10s linear -26s infinite;
  opacity: .647;
  transform: translate(30.1381vw, -10px) scale(.325)
}

@keyframes fall-172 {
  54.688% {
    transform: translate(29.0842vw, 54.688vh) scale(.325)
  }

  to {
    transform: translate(29.61115vw, 100vh) scale(.325)
  }
}

.snow i:nth-child(173) {
  animation: fall-173 25s linear -26s infinite;
  opacity: .0212;
  transform: translate(38.5512vw, -10px) scale(.6638)
}

@keyframes fall-173 {
  35.506% {
    transform: translate(47.9686vw, 35.506vh) scale(.6638)
  }

  to {
    transform: translate(43.2599vw, 100vh) scale(.6638)
  }
}

.snow i:nth-child(174) {
  animation: fall-174 11s linear -11s infinite;
  opacity: .8079;
  transform: translate(70.7237vw, -10px) scale(.8706)
}

@keyframes fall-174 {
  43.431% {
    transform: translate(64.5521vw, 43.431vh) scale(.8706)
  }

  to {
    transform: translate(67.6379vw, 100vh) scale(.8706)
  }
}

.snow i:nth-child(175) {
  animation: fall-175 13s linear -20s infinite;
  opacity: .4043;
  transform: translate(72.2061vw, -10px) scale(.1056)
}

@keyframes fall-175 {
  68.598% {
    transform: translate(69.6736vw, 68.598vh) scale(.1056)
  }

  to {
    transform: translate(70.93985vw, 100vh) scale(.1056)
  }
}

.snow i:nth-child(176) {
  animation: fall-176 11s linear -4s infinite;
  opacity: .0616;
  transform: translate(6.7402vw, -10px) scale(.0679)
}

@keyframes fall-176 {
  71.059% {
    transform: translate(-.6206vw, 71.059vh) scale(.0679)
  }

  to {
    transform: translate(3.0598vw, 100vh) scale(.0679)
  }
}

.snow i:nth-child(177) {
  animation: fall-177 19s linear -26s infinite;
  opacity: .2395;
  transform: translate(95.6723vw, -10px) scale(.1885)
}

@keyframes fall-177 {
  55.348% {
    transform: translate(100.7862vw, 55.348vh) scale(.1885)
  }

  to {
    transform: translate(98.22925vw, 100vh) scale(.1885)
  }
}

.snow i:nth-child(178) {
  animation: fall-178 24s linear -11s infinite;
  opacity: .3457;
  transform: translate(63.7906vw, -10px) scale(.264)
}

@keyframes fall-178 {
  46.067% {
    transform: translate(56.6719vw, 46.067vh) scale(.264)
  }

  to {
    transform: translate(60.23125vw, 100vh) scale(.264)
  }
}

.snow i:nth-child(179) {
  animation: fall-179 30s linear -9s infinite;
  opacity: .2739;
  transform: translate(39.8989vw, -10px) scale(.3523)
}

@keyframes fall-179 {
  74.357% {
    transform: translate(32.7566vw, 74.357vh) scale(.3523)
  }

  to {
    transform: translate(36.32775vw, 100vh) scale(.3523)
  }
}

.snow i:nth-child(180) {
  animation: fall-180 27s linear -1s infinite;
  opacity: .8127;
  transform: translate(93.9541vw, -10px) scale(.7705)
}

@keyframes fall-180 {
  75.976% {
    transform: translate(84.7666vw, 75.976vh) scale(.7705)
  }

  to {
    transform: translate(89.36035vw, 100vh) scale(.7705)
  }
}

.snow i:nth-child(181) {
  animation: fall-181 24s linear -23s infinite;
  opacity: .511;
  transform: translate(17.43vw, -10px) scale(.9272)
}

@keyframes fall-181 {
  64.451% {
    transform: translate(21.5333vw, 64.451vh) scale(.9272)
  }

  to {
    transform: translate(19.48165vw, 100vh) scale(.9272)
  }
}

.snow i:nth-child(182) {
  animation: fall-182 29s linear -21s infinite;
  opacity: .1416;
  transform: translate(45.458vw, -10px) scale(.4065)
}

@keyframes fall-182 {
  54.179% {
    transform: translate(42.1224vw, 54.179vh) scale(.4065)
  }

  to {
    transform: translate(43.7902vw, 100vh) scale(.4065)
  }
}

.snow i:nth-child(183) {
  animation: fall-183 18s linear -27s infinite;
  opacity: .9011;
  transform: translate(52.1935vw, -10px) scale(.9598)
}

@keyframes fall-183 {
  60.342% {
    transform: translate(61.5899vw, 60.342vh) scale(.9598)
  }

  to {
    transform: translate(56.8917vw, 100vh) scale(.9598)
  }
}

.snow i:nth-child(184) {
  animation: fall-184 22s linear -3s infinite;
  opacity: .6388;
  transform: translate(93.3926vw, -10px) scale(.8325)
}

@keyframes fall-184 {
  30.158% {
    transform: translate(97.7859vw, 30.158vh) scale(.8325)
  }

  to {
    transform: translate(95.58925vw, 100vh) scale(.8325)
  }
}

.snow i:nth-child(185) {
  animation: fall-185 27s linear -15s infinite;
  opacity: .3121;
  transform: translate(70.4988vw, -10px) scale(.9449)
}

@keyframes fall-185 {
  58.536% {
    transform: translate(73.6631vw, 58.536vh) scale(.9449)
  }

  to {
    transform: translate(72.08095vw, 100vh) scale(.9449)
  }
}

.snow i:nth-child(186) {
  animation: fall-186 13s linear -13s infinite;
  opacity: .7381;
  transform: translate(72.8907vw, -10px) scale(.1886)
}

@keyframes fall-186 {
  44.59% {
    transform: translate(77.3723vw, 44.59vh) scale(.1886)
  }

  to {
    transform: translate(75.1315vw, 100vh) scale(.1886)
  }
}

.snow i:nth-child(187) {
  animation: fall-187 27s linear -29s infinite;
  opacity: .6466;
  transform: translate(78.0263vw, -10px) scale(.7494)
}

@keyframes fall-187 {
  54.056% {
    transform: translate(75.2735vw, 54.056vh) scale(.7494)
  }

  to {
    transform: translate(76.6499vw, 100vh) scale(.7494)
  }
}

.snow i:nth-child(188) {
  animation: fall-188 12s linear -20s infinite;
  opacity: .8179;
  transform: translate(1.665vw, -10px) scale(.0945)
}

@keyframes fall-188 {
  68.46% {
    transform: translate(6.8655vw, 68.46vh) scale(.0945)
  }

  to {
    transform: translate(4.26525vw, 100vh) scale(.0945)
  }
}

.snow i:nth-child(189) {
  animation: fall-189 13s linear -17s infinite;
  opacity: .9975;
  transform: translate(14.0188vw, -10px) scale(.1951)
}

@keyframes fall-189 {
  43.465% {
    transform: translate(12.303vw, 43.465vh) scale(.1951)
  }

  to {
    transform: translate(13.1609vw, 100vh) scale(.1951)
  }
}

.snow i:nth-child(190) {
  animation: fall-190 26s linear -5s infinite;
  opacity: .1458;
  transform: translate(40.1265vw, -10px) scale(.8095)
}

@keyframes fall-190 {
  61.204% {
    transform: translate(43.1873vw, 61.204vh) scale(.8095)
  }

  to {
    transform: translate(41.6569vw, 100vh) scale(.8095)
  }
}

.snow i:nth-child(191) {
  animation: fall-191 18s linear -17s infinite;
  opacity: .0074;
  transform: translate(47.139vw, -10px) scale(.45)
}

@keyframes fall-191 {
  35.338% {
    transform: translate(53.4617vw, 35.338vh) scale(.45)
  }

  to {
    transform: translate(50.30035vw, 100vh) scale(.45)
  }
}

.snow i:nth-child(192) {
  animation: fall-192 10s linear -20s infinite;
  opacity: .7085;
  transform: translate(54.0494vw, -10px) scale(.5202)
}

@keyframes fall-192 {
  45.707% {
    transform: translate(45.1468vw, 45.707vh) scale(.5202)
  }

  to {
    transform: translate(49.5981vw, 100vh) scale(.5202)
  }
}

.snow i:nth-child(193) {
  animation: fall-193 22s linear -17s infinite;
  opacity: .0992;
  transform: translate(41.9506vw, -10px) scale(.7602)
}

@keyframes fall-193 {
  73.561% {
    transform: translate(47.7786vw, 73.561vh) scale(.7602)
  }

  to {
    transform: translate(44.8646vw, 100vh) scale(.7602)
  }
}

.snow i:nth-child(194) {
  animation: fall-194 24s linear -25s infinite;
  opacity: .4729;
  transform: translate(38.0847vw, -10px) scale(.9692)
}

@keyframes fall-194 {
  64.349% {
    transform: translate(31.587vw, 64.349vh) scale(.9692)
  }

  to {
    transform: translate(34.83585vw, 100vh) scale(.9692)
  }
}

.snow i:nth-child(195) {
  animation: fall-195 25s linear -2s infinite;
  opacity: .2186;
  transform: translate(25.9066vw, -10px) scale(.7268)
}

@keyframes fall-195 {
  59.041% {
    transform: translate(26.6129vw, 59.041vh) scale(.7268)
  }

  to {
    transform: translate(26.25975vw, 100vh) scale(.7268)
  }
}

.snow i:nth-child(196) {
  animation: fall-196 24s linear -1s infinite;
  opacity: .8033;
  transform: translate(46.9434vw, -10px) scale(.8303)
}

@keyframes fall-196 {
  77.013% {
    transform: translate(40.2352vw, 77.013vh) scale(.8303)
  }

  to {
    transform: translate(43.5893vw, 100vh) scale(.8303)
  }
}

.snow i:nth-child(197) {
  animation: fall-197 11s linear -17s infinite;
  opacity: .363;
  transform: translate(52.2346vw, -10px) scale(.9878)
}

@keyframes fall-197 {
  36.168% {
    transform: translate(43.382vw, 36.168vh) scale(.9878)
  }

  to {
    transform: translate(47.8083vw, 100vh) scale(.9878)
  }
}

.snow i:nth-child(198) {
  animation: fall-198 26s linear -5s infinite;
  opacity: .4919;
  transform: translate(21.3491vw, -10px) scale(.5617)
}

@keyframes fall-198 {
  49.014% {
    transform: translate(16.3858vw, 49.014vh) scale(.5617)
  }

  to {
    transform: translate(18.86745vw, 100vh) scale(.5617)
  }
}

.snow i:nth-child(199) {
  animation: fall-199 24s linear -17s infinite;
  opacity: .7245;
  transform: translate(38.1958vw, -10px) scale(.798)
}

@keyframes fall-199 {
  77.979% {
    transform: translate(41.2748vw, 77.979vh) scale(.798)
  }

  to {
    transform: translate(39.7353vw, 100vh) scale(.798)
  }
}

.snow i:nth-child(200) {
  animation: fall-200 12s linear -26s infinite;
  opacity: .738;
  transform: translate(27.442vw, -10px) scale(.0395)
}

@keyframes fall-200 {
  54.015% {
    transform: translate(17.9338vw, 54.015vh) scale(.0395)
  }

  to {
    transform: translate(22.6879vw, 100vh) scale(.0395)
  }
}

.snow i:nth-child(201) {
  animation: fall-201 18s linear -9s infinite;
  opacity: .6989;
  transform: translate(92.1191vw, -10px) scale(.6601)
}

@keyframes fall-201 {
  78.567% {
    transform: translate(84.2756vw, 78.567vh) scale(.6601)
  }

  to {
    transform: translate(88.19735vw, 100vh) scale(.6601)
  }
}

.snow i:nth-child(202) {
  animation: fall-202 16s linear -5s infinite;
  opacity: .3546;
  transform: translate(47.7226vw, -10px) scale(.0211)
}

@keyframes fall-202 {
  64.453% {
    transform: translate(46.1441vw, 64.453vh) scale(.0211)
  }

  to {
    transform: translate(46.93335vw, 100vh) scale(.0211)
  }
}

.snow i:nth-child(203) {
  animation: fall-203 19s linear -29s infinite;
  opacity: .3614;
  transform: translate(28.444vw, -10px) scale(.5314)
}

@keyframes fall-203 {
  58.098% {
    transform: translate(37.8255vw, 58.098vh) scale(.5314)
  }

  to {
    transform: translate(33.13475vw, 100vh) scale(.5314)
  }
}

.snow i:nth-child(204) {
  animation: fall-204 24s linear -4s infinite;
  opacity: .4917;
  transform: translate(19.8513vw, -10px) scale(.1072)
}

@keyframes fall-204 {
  79.427% {
    transform: translate(16.0387vw, 79.427vh) scale(.1072)
  }

  to {
    transform: translate(17.945vw, 100vh) scale(.1072)
  }
}

.snow i:nth-child(205) {
  animation: fall-205 28s linear -27s infinite;
  opacity: .5438;
  transform: translate(67.1356vw, -10px) scale(.5154)
}

@keyframes fall-205 {
  59.966% {
    transform: translate(58.9287vw, 59.966vh) scale(.5154)
  }

  to {
    transform: translate(63.03215vw, 100vh) scale(.5154)
  }
}

.snow i:nth-child(206) {
  animation: fall-206 21s linear -17s infinite;
  opacity: .8097;
  transform: translate(17.2315vw, -10px) scale(.6239)
}

@keyframes fall-206 {
  45.232% {
    transform: translate(21.0663vw, 45.232vh) scale(.6239)
  }

  to {
    transform: translate(19.1489vw, 100vh) scale(.6239)
  }
}

.snow i:nth-child(207) {
  animation: fall-207 25s linear -12s infinite;
  opacity: .2172;
  transform: translate(12.0141vw, -10px) scale(.0102)
}

@keyframes fall-207 {
  74.872% {
    transform: translate(21.4317vw, 74.872vh) scale(.0102)
  }

  to {
    transform: translate(16.7229vw, 100vh) scale(.0102)
  }
}

.snow i:nth-child(208) {
  animation: fall-208 29s linear -30s infinite;
  opacity: .6282;
  transform: translate(32.8069vw, -10px) scale(.6322)
}

@keyframes fall-208 {
  58.854% {
    transform: translate(39.0889vw, 58.854vh) scale(.6322)
  }

  to {
    transform: translate(35.9479vw, 100vh) scale(.6322)
  }
}

.snow i:nth-child(209) {
  animation: fall-209 18s linear -15s infinite;
  opacity: .0879;
  transform: translate(80.8924vw, -10px) scale(.1548)
}

@keyframes fall-209 {
  64.502% {
    transform: translate(72.6999vw, 64.502vh) scale(.1548)
  }

  to {
    transform: translate(76.79615vw, 100vh) scale(.1548)
  }
}

.snow i:nth-child(210) {
  animation: fall-210 13s linear -2s infinite;
  opacity: .7061;
  transform: translate(91.8209vw, -10px) scale(.196)
}

@keyframes fall-210 {
  77.416% {
    transform: translate(90.4394vw, 77.416vh) scale(.196)
  }

  to {
    transform: translate(91.13015vw, 100vh) scale(.196)
  }
}

.snow i:nth-child(211) {
  animation: fall-211 22s linear -1s infinite;
  opacity: .5796;
  transform: translate(91.8371vw, -10px) scale(.3842)
}

@keyframes fall-211 {
  76.169% {
    transform: translate(96.4687vw, 76.169vh) scale(.3842)
  }

  to {
    transform: translate(94.1529vw, 100vh) scale(.3842)
  }
}

.snow i:nth-child(212) {
  animation: fall-212 12s linear -26s infinite;
  opacity: .3711;
  transform: translate(23.699vw, -10px) scale(.7027)
}

@keyframes fall-212 {
  71.451% {
    transform: translate(17.9392vw, 71.451vh) scale(.7027)
  }

  to {
    transform: translate(20.8191vw, 100vh) scale(.7027)
  }
}

.snow i:nth-child(213) {
  animation: fall-213 18s linear -20s infinite;
  opacity: .3164;
  transform: translate(54.9247vw, -10px) scale(.8206)
}

@keyframes fall-213 {
  76.584% {
    transform: translate(46.3711vw, 76.584vh) scale(.8206)
  }

  to {
    transform: translate(50.6479vw, 100vh) scale(.8206)
  }
}

.snow i:nth-child(214) {
  animation: fall-214 22s linear -29s infinite;
  opacity: .9715;
  transform: translate(99.7521vw, -10px) scale(.7409)
}

@keyframes fall-214 {
  73.874% {
    transform: translate(90.4116vw, 73.874vh) scale(.7409)
  }

  to {
    transform: translate(95.08185vw, 100vh) scale(.7409)
  }
}

.snow i:nth-child(215) {
  animation: fall-215 16s linear -2s infinite;
  opacity: .0471;
  transform: translate(7.0236vw, -10px) scale(.8062)
}

@keyframes fall-215 {
  60.316% {
    transform: translate(9.356vw, 60.316vh) scale(.8062)
  }

  to {
    transform: translate(8.1898vw, 100vh) scale(.8062)
  }
}

.snow i:nth-child(216) {
  animation: fall-216 23s linear -17s infinite;
  opacity: .6709;
  transform: translate(14.6087vw, -10px) scale(.4447)
}

@keyframes fall-216 {
  56.473% {
    transform: translate(7.2051vw, 56.473vh) scale(.4447)
  }

  to {
    transform: translate(10.9069vw, 100vh) scale(.4447)
  }
}

.snow i:nth-child(217) {
  animation: fall-217 15s linear -18s infinite;
  opacity: .9889;
  transform: translate(36.1291vw, -10px) scale(.4998)
}

@keyframes fall-217 {
  69.524% {
    transform: translate(26.5721vw, 69.524vh) scale(.4998)
  }

  to {
    transform: translate(31.3506vw, 100vh) scale(.4998)
  }
}

.snow i:nth-child(218) {
  animation: fall-218 12s linear -28s infinite;
  opacity: .2711;
  transform: translate(38.2671vw, -10px) scale(.448)
}

@keyframes fall-218 {
  35.813% {
    transform: translate(34.5751vw, 35.813vh) scale(.448)
  }

  to {
    transform: translate(36.4211vw, 100vh) scale(.448)
  }
}

.snow i:nth-child(219) {
  animation: fall-219 30s linear -26s infinite;
  opacity: .5059;
  transform: translate(21.6863vw, -10px) scale(.5031)
}

@keyframes fall-219 {
  64.156% {
    transform: translate(25.6613vw, 64.156vh) scale(.5031)
  }

  to {
    transform: translate(23.6738vw, 100vh) scale(.5031)
  }
}

.snow i:nth-child(220) {
  animation: fall-220 12s linear -2s infinite;
  opacity: .7011;
  transform: translate(95.9969vw, -10px) scale(.954)
}

@keyframes fall-220 {
  49.615% {
    transform: translate(87.7727vw, 49.615vh) scale(.954)
  }

  to {
    transform: translate(91.8848vw, 100vh) scale(.954)
  }
}

.snow i:nth-child(221) {
  animation: fall-221 21s linear -15s infinite;
  opacity: .3747;
  transform: translate(58.7297vw, -10px) scale(.3638)
}

@keyframes fall-221 {
  39.31% {
    transform: translate(65.4981vw, 39.31vh) scale(.3638)
  }

  to {
    transform: translate(62.1139vw, 100vh) scale(.3638)
  }
}

.snow i:nth-child(222) {
  animation: fall-222 19s linear -24s infinite;
  opacity: .9066;
  transform: translate(82.3892vw, -10px) scale(.2741)
}

@keyframes fall-222 {
  31.428% {
    transform: translate(90.2543vw, 31.428vh) scale(.2741)
  }

  to {
    transform: translate(86.32175vw, 100vh) scale(.2741)
  }
}

.snow i:nth-child(223) {
  animation: fall-223 19s linear -7s infinite;
  opacity: .5363;
  transform: translate(78.0602vw, -10px) scale(.1054)
}

@keyframes fall-223 {
  48.135% {
    transform: translate(75.9461vw, 48.135vh) scale(.1054)
  }

  to {
    transform: translate(77.00315vw, 100vh) scale(.1054)
  }
}

.snow i:nth-child(224) {
  animation: fall-224 20s linear -18s infinite;
  opacity: .2375;
  transform: translate(45.601vw, -10px) scale(.6813)
}

@keyframes fall-224 {
  53.18% {
    transform: translate(40.2486vw, 53.18vh) scale(.6813)
  }

  to {
    transform: translate(42.9248vw, 100vh) scale(.6813)
  }
}

.snow i:nth-child(225) {
  animation: fall-225 26s linear -25s infinite;
  opacity: .518;
  transform: translate(75.1602vw, -10px) scale(.7474)
}

@keyframes fall-225 {
  65.289% {
    transform: translate(74.6785vw, 65.289vh) scale(.7474)
  }

  to {
    transform: translate(74.91935vw, 100vh) scale(.7474)
  }
}

.snow i:nth-child(226) {
  animation: fall-226 17s linear -10s infinite;
  opacity: .8426;
  transform: translate(30.3129vw, -10px) scale(.365)
}

@keyframes fall-226 {
  61.148% {
    transform: translate(26.4408vw, 61.148vh) scale(.365)
  }

  to {
    transform: translate(28.37685vw, 100vh) scale(.365)
  }
}

.snow i:nth-child(227) {
  animation: fall-227 24s linear -26s infinite;
  opacity: .5438;
  transform: translate(28.6428vw, -10px) scale(.5081)
}

@keyframes fall-227 {
  38.935% {
    transform: translate(32.8595vw, 38.935vh) scale(.5081)
  }

  to {
    transform: translate(30.75115vw, 100vh) scale(.5081)
  }
}

.snow i:nth-child(228) {
  animation: fall-228 10s linear -13s infinite;
  opacity: .6742;
  transform: translate(75.3315vw, -10px) scale(.5454)
}

@keyframes fall-228 {
  67.475% {
    transform: translate(73.1409vw, 67.475vh) scale(.5454)
  }

  to {
    transform: translate(74.2362vw, 100vh) scale(.5454)
  }
}

.snow i:nth-child(229) {
  animation: fall-229 13s linear -5s infinite;
  opacity: .8293;
  transform: translate(50.3795vw, -10px) scale(.1672)
}

@keyframes fall-229 {
  33.149% {
    transform: translate(56.2248vw, 33.149vh) scale(.1672)
  }

  to {
    transform: translate(53.30215vw, 100vh) scale(.1672)
  }
}

.snow i:nth-child(230) {
  animation: fall-230 23s linear -11s infinite;
  opacity: .1921;
  transform: translate(92.3497vw, -10px) scale(.4313)
}

@keyframes fall-230 {
  75.418% {
    transform: translate(83.7485vw, 75.418vh) scale(.4313)
  }

  to {
    transform: translate(88.0491vw, 100vh) scale(.4313)
  }
}

.snow i:nth-child(231) {
  animation: fall-231 20s linear -5s infinite;
  opacity: .1362;
  transform: translate(97.6848vw, -10px) scale(.4615)
}

@keyframes fall-231 {
  44.721% {
    transform: translate(97.5276vw, 44.721vh) scale(.4615)
  }

  to {
    transform: translate(97.6062vw, 100vh) scale(.4615)
  }
}

.snow i:nth-child(232) {
  animation: fall-232 28s linear -1s infinite;
  opacity: .009;
  transform: translate(24.8444vw, -10px) scale(.8144)
}

@keyframes fall-232 {
  77.88% {
    transform: translate(31.0222vw, 77.88vh) scale(.8144)
  }

  to {
    transform: translate(27.9333vw, 100vh) scale(.8144)
  }
}

.snow i:nth-child(233) {
  animation: fall-233 15s linear -21s infinite;
  opacity: .4887;
  transform: translate(55.243vw, -10px) scale(.7598)
}

@keyframes fall-233 {
  71.598% {
    transform: translate(53.6069vw, 71.598vh) scale(.7598)
  }

  to {
    transform: translate(54.42495vw, 100vh) scale(.7598)
  }
}

.snow i:nth-child(234) {
  animation: fall-234 26s linear -25s infinite;
  opacity: .8347;
  transform: translate(42.9588vw, -10px) scale(.9314)
}

@keyframes fall-234 {
  77.094% {
    transform: translate(38.4012vw, 77.094vh) scale(.9314)
  }

  to {
    transform: translate(40.68vw, 100vh) scale(.9314)
  }
}

.snow i:nth-child(235) {
  animation: fall-235 29s linear -7s infinite;
  opacity: .7863;
  transform: translate(66.7493vw, -10px) scale(.96)
}

@keyframes fall-235 {
  59.561% {
    transform: translate(69.5745vw, 59.561vh) scale(.96)
  }

  to {
    transform: translate(68.1619vw, 100vh) scale(.96)
  }
}

.snow i:nth-child(236) {
  animation: fall-236 12s linear -6s infinite;
  opacity: .0544;
  transform: translate(77.3253vw, -10px) scale(.3512)
}

@keyframes fall-236 {
  48.377% {
    transform: translate(73.8947vw, 48.377vh) scale(.3512)
  }

  to {
    transform: translate(75.61vw, 100vh) scale(.3512)
  }
}

.snow i:nth-child(237) {
  animation: fall-237 15s linear -18s infinite;
  opacity: .1373;
  transform: translate(50.0078vw, -10px) scale(.1277)
}

@keyframes fall-237 {
  32.481% {
    transform: translate(54.7943vw, 32.481vh) scale(.1277)
  }

  to {
    transform: translate(52.40105vw, 100vh) scale(.1277)
  }
}

.snow i:nth-child(238) {
  animation: fall-238 21s linear -8s infinite;
  opacity: .1349;
  transform: translate(92.8405vw, -10px) scale(.7998)
}

@keyframes fall-238 {
  45.011% {
    transform: translate(102.1446vw, 45.011vh) scale(.7998)
  }

  to {
    transform: translate(97.49255vw, 100vh) scale(.7998)
  }
}

.snow i:nth-child(239) {
  animation: fall-239 27s linear -29s infinite;
  opacity: .5166;
  transform: translate(75.7545vw, -10px) scale(.3255)
}

@keyframes fall-239 {
  33.078% {
    transform: translate(73.522vw, 33.078vh) scale(.3255)
  }

  to {
    transform: translate(74.63825vw, 100vh) scale(.3255)
  }
}

.snow i:nth-child(240) {
  animation: fall-240 26s linear -30s infinite;
  opacity: .4494;
  transform: translate(49.8597vw, -10px) scale(.5544)
}

@keyframes fall-240 {
  48.884% {
    transform: translate(49.9815vw, 48.884vh) scale(.5544)
  }

  to {
    transform: translate(49.9206vw, 100vh) scale(.5544)
  }
}

.snow i:nth-child(241) {
  animation: fall-241 18s linear -11s infinite;
  opacity: .4702;
  transform: translate(99.101vw, -10px) scale(.1771)
}

@keyframes fall-241 {
  75.012% {
    transform: translate(98.2501vw, 75.012vh) scale(.1771)
  }

  to {
    transform: translate(98.67555vw, 100vh) scale(.1771)
  }
}

.snow i:nth-child(242) {
  animation: fall-242 15s linear -20s infinite;
  opacity: .8295;
  transform: translate(63.854vw, -10px) scale(.7477)
}

@keyframes fall-242 {
  67.234% {
    transform: translate(71.4281vw, 67.234vh) scale(.7477)
  }

  to {
    transform: translate(67.64105vw, 100vh) scale(.7477)
  }
}

.snow i:nth-child(243) {
  animation: fall-243 16s linear -16s infinite;
  opacity: .0549;
  transform: translate(81.1221vw, -10px) scale(.4897)
}

@keyframes fall-243 {
  72.468% {
    transform: translate(73.8511vw, 72.468vh) scale(.4897)
  }

  to {
    transform: translate(77.4866vw, 100vh) scale(.4897)
  }
}

.snow i:nth-child(244) {
  animation: fall-244 16s linear -21s infinite;
  opacity: .1024;
  transform: translate(39.8116vw, -10px) scale(.9768)
}

@keyframes fall-244 {
  63.596% {
    transform: translate(46.1752vw, 63.596vh) scale(.9768)
  }

  to {
    transform: translate(42.9934vw, 100vh) scale(.9768)
  }
}

.snow i:nth-child(245) {
  animation: fall-245 11s linear -20s infinite;
  opacity: .9162;
  transform: translate(99.7147vw, -10px) scale(.374)
}

@keyframes fall-245 {
  57.927% {
    transform: translate(99.7105vw, 57.927vh) scale(.374)
  }

  to {
    transform: translate(99.7126vw, 100vh) scale(.374)
  }
}

.snow i:nth-child(246) {
  animation: fall-246 14s linear -15s infinite;
  opacity: .5033;
  transform: translate(50.2848vw, -10px) scale(.0809)
}

@keyframes fall-246 {
  55.148% {
    transform: translate(46.2982vw, 55.148vh) scale(.0809)
  }

  to {
    transform: translate(48.2915vw, 100vh) scale(.0809)
  }
}

.snow i:nth-child(247) {
  animation: fall-247 12s linear -8s infinite;
  opacity: .6675;
  transform: translate(52.59vw, -10px) scale(.4254)
}

@keyframes fall-247 {
  67.104% {
    transform: translate(54.4157vw, 67.104vh) scale(.4254)
  }

  to {
    transform: translate(53.50285vw, 100vh) scale(.4254)
  }
}

.snow i:nth-child(248) {
  animation: fall-248 15s linear -13s infinite;
  opacity: .4552;
  transform: translate(.5508vw, -10px) scale(.2114)
}

@keyframes fall-248 {
  53.476% {
    transform: translate(3.8153vw, 53.476vh) scale(.2114)
  }

  to {
    transform: translate(2.18305vw, 100vh) scale(.2114)
  }
}

.snow i:nth-child(249) {
  animation: fall-249 29s linear -20s infinite;
  opacity: .1275;
  transform: translate(30.246vw, -10px) scale(.6536)
}

@keyframes fall-249 {
  54.343% {
    transform: translate(20.4695vw, 54.343vh) scale(.6536)
  }

  to {
    transform: translate(25.35775vw, 100vh) scale(.6536)
  }
}

.snow i:nth-child(250) {
  animation: fall-250 17s linear -11s infinite;
  opacity: .9958;
  transform: translate(15.9534vw, -10px) scale(.6806)
}

@keyframes fall-250 {
  48.958% {
    transform: translate(19.3723vw, 48.958vh) scale(.6806)
  }

  to {
    transform: translate(17.66285vw, 100vh) scale(.6806)
  }
}

.snow i:nth-child(251) {
  animation: fall-251 30s linear -16s infinite;
  opacity: .386;
  transform: translate(87.1506vw, -10px) scale(.9725)
}

@keyframes fall-251 {
  75.703% {
    transform: translate(83.7869vw, 75.703vh) scale(.9725)
  }

  to {
    transform: translate(85.46875vw, 100vh) scale(.9725)
  }
}

.snow i:nth-child(252) {
  animation: fall-252 19s linear -22s infinite;
  opacity: .0144;
  transform: translate(28.9097vw, -10px) scale(.2605)
}

@keyframes fall-252 {
  72.409% {
    transform: translate(38.0535vw, 72.409vh) scale(.2605)
  }

  to {
    transform: translate(33.4816vw, 100vh) scale(.2605)
  }
}

.snow i:nth-child(253) {
  animation: fall-253 16s linear -27s infinite;
  opacity: .0327;
  transform: translate(89.8234vw, -10px) scale(.8171)
}

@keyframes fall-253 {
  58.06% {
    transform: translate(99.0891vw, 58.06vh) scale(.8171)
  }

  to {
    transform: translate(94.45625vw, 100vh) scale(.8171)
  }
}

.snow i:nth-child(254) {
  animation: fall-254 23s linear -26s infinite;
  opacity: .7285;
  transform: translate(86.3138vw, -10px) scale(.3291)
}

@keyframes fall-254 {
  71.476% {
    transform: translate(83.4093vw, 71.476vh) scale(.3291)
  }

  to {
    transform: translate(84.86155vw, 100vh) scale(.3291)
  }
}

.snow i:nth-child(255) {
  animation: fall-255 18s linear -6s infinite;
  opacity: .4492;
  transform: translate(12.8337vw, -10px) scale(.3125)
}

@keyframes fall-255 {
  42.804% {
    transform: translate(20.3599vw, 42.804vh) scale(.3125)
  }

  to {
    transform: translate(16.5968vw, 100vh) scale(.3125)
  }
}

.snow i:nth-child(256) {
  animation: fall-256 30s linear -2s infinite;
  opacity: .671;
  transform: translate(98.5618vw, -10px) scale(.0221)
}

@keyframes fall-256 {
  36.059% {
    transform: translate(104.8597vw, 36.059vh) scale(.0221)
  }

  to {
    transform: translate(101.71075vw, 100vh) scale(.0221)
  }
}

.snow i:nth-child(257) {
  animation: fall-257 23s linear -17s infinite;
  opacity: .5787;
  transform: translate(49.4026vw, -10px) scale(.6245)
}

@keyframes fall-257 {
  39.62% {
    transform: translate(46.2025vw, 39.62vh) scale(.6245)
  }

  to {
    transform: translate(47.80255vw, 100vh) scale(.6245)
  }
}

.snow i:nth-child(258) {
  animation: fall-258 30s linear -24s infinite;
  opacity: .6339;
  transform: translate(15.3716vw, -10px) scale(.6133)
}

@keyframes fall-258 {
  61.741% {
    transform: translate(12.2641vw, 61.741vh) scale(.6133)
  }

  to {
    transform: translate(13.81785vw, 100vh) scale(.6133)
  }
}

.snow i:nth-child(259) {
  animation: fall-259 13s linear -13s infinite;
  opacity: .0921;
  transform: translate(96.2426vw, -10px) scale(.0044)
}

@keyframes fall-259 {
  59.222% {
    transform: translate(93.3442vw, 59.222vh) scale(.0044)
  }

  to {
    transform: translate(94.7934vw, 100vh) scale(.0044)
  }
}

.snow i:nth-child(260) {
  animation: fall-260 18s linear -2s infinite;
  opacity: .9982;
  transform: translate(53.1755vw, -10px) scale(.0951)
}

@keyframes fall-260 {
  76.905% {
    transform: translate(44.6727vw, 76.905vh) scale(.0951)
  }

  to {
    transform: translate(48.9241vw, 100vh) scale(.0951)
  }
}

.snow i:nth-child(261) {
  animation: fall-261 19s linear -24s infinite;
  opacity: .2978;
  transform: translate(79.1777vw, -10px) scale(.0436)
}

@keyframes fall-261 {
  63.472% {
    transform: translate(80.1096vw, 63.472vh) scale(.0436)
  }

  to {
    transform: translate(79.64365vw, 100vh) scale(.0436)
  }
}

.snow i:nth-child(262) {
  animation: fall-262 26s linear -14s infinite;
  opacity: .1336;
  transform: translate(39.9581vw, -10px) scale(.2964)
}

@keyframes fall-262 {
  30.978% {
    transform: translate(35.5892vw, 30.978vh) scale(.2964)
  }

  to {
    transform: translate(37.77365vw, 100vh) scale(.2964)
  }
}

.snow i:nth-child(263) {
  animation: fall-263 18s linear -14s infinite;
  opacity: .3296;
  transform: translate(49.7074vw, -10px) scale(.6343)
}

@keyframes fall-263 {
  54.134% {
    transform: translate(55.9075vw, 54.134vh) scale(.6343)
  }

  to {
    transform: translate(52.80745vw, 100vh) scale(.6343)
  }
}

.snow i:nth-child(264) {
  animation: fall-264 17s linear -25s infinite;
  opacity: .4612;
  transform: translate(99.816vw, -10px) scale(.0985)
}

@keyframes fall-264 {
  66.428% {
    transform: translate(101.3666vw, 66.428vh) scale(.0985)
  }

  to {
    transform: translate(100.5913vw, 100vh) scale(.0985)
  }
}

.snow i:nth-child(265) {
  animation: fall-265 20s linear -27s infinite;
  opacity: .3822;
  transform: translate(16.5497vw, -10px) scale(.4687)
}

@keyframes fall-265 {
  70.708% {
    transform: translate(10.3002vw, 70.708vh) scale(.4687)
  }

  to {
    transform: translate(13.42495vw, 100vh) scale(.4687)
  }
}

.snow i:nth-child(266) {
  animation: fall-266 29s linear -28s infinite;
  opacity: .1586;
  transform: translate(94.9082vw, -10px) scale(.1899)
}

@keyframes fall-266 {
  43.648% {
    transform: translate(98.7503vw, 43.648vh) scale(.1899)
  }

  to {
    transform: translate(96.82925vw, 100vh) scale(.1899)
  }
}

.snow i:nth-child(267) {
  animation: fall-267 27s linear -25s infinite;
  opacity: .3969;
  transform: translate(64.4646vw, -10px) scale(.9062)
}

@keyframes fall-267 {
  36.943% {
    transform: translate(73.8777vw, 36.943vh) scale(.9062)
  }

  to {
    transform: translate(69.17115vw, 100vh) scale(.9062)
  }
}

.snow i:nth-child(268) {
  animation: fall-268 25s linear -4s infinite;
  opacity: .2778;
  transform: translate(63.7412vw, -10px) scale(.9797)
}

@keyframes fall-268 {
  73.356% {
    transform: translate(70.8021vw, 73.356vh) scale(.9797)
  }

  to {
    transform: translate(67.27165vw, 100vh) scale(.9797)
  }
}

.snow i:nth-child(269) {
  animation: fall-269 19s linear -6s infinite;
  opacity: .1127;
  transform: translate(64.4877vw, -10px) scale(.6304)
}

@keyframes fall-269 {
  32.336% {
    transform: translate(72.7771vw, 32.336vh) scale(.6304)
  }

  to {
    transform: translate(68.6324vw, 100vh) scale(.6304)
  }
}

.snow i:nth-child(270) {
  animation: fall-270 29s linear -19s infinite;
  opacity: .7775;
  transform: translate(62.5643vw, -10px) scale(.529)
}

@keyframes fall-270 {
  57.811% {
    transform: translate(68.6091vw, 57.811vh) scale(.529)
  }

  to {
    transform: translate(65.5867vw, 100vh) scale(.529)
  }
}

.snow i:nth-child(271) {
  animation: fall-271 23s linear -23s infinite;
  opacity: .0936;
  transform: translate(86.1196vw, -10px) scale(.8531)
}

@keyframes fall-271 {
  78.259% {
    transform: translate(80.191vw, 78.259vh) scale(.8531)
  }

  to {
    transform: translate(83.1553vw, 100vh) scale(.8531)
  }
}

.snow i:nth-child(272) {
  animation: fall-272 10s linear -24s infinite;
  opacity: .3946;
  transform: translate(39.7292vw, -10px) scale(.7628)
}

@keyframes fall-272 {
  62.169% {
    transform: translate(37.7994vw, 62.169vh) scale(.7628)
  }

  to {
    transform: translate(38.7643vw, 100vh) scale(.7628)
  }
}

.snow i:nth-child(273) {
  animation: fall-273 15s linear -4s infinite;
  opacity: .9978;
  transform: translate(51.7919vw, -10px) scale(.5891)
}

@keyframes fall-273 {
  77.179% {
    transform: translate(53.1935vw, 77.179vh) scale(.5891)
  }

  to {
    transform: translate(52.4927vw, 100vh) scale(.5891)
  }
}

.snow i:nth-child(274) {
  animation: fall-274 11s linear -26s infinite;
  opacity: .8412;
  transform: translate(53.8477vw, -10px) scale(.7566)
}

@keyframes fall-274 {
  57.236% {
    transform: translate(54.9008vw, 57.236vh) scale(.7566)
  }

  to {
    transform: translate(54.37425vw, 100vh) scale(.7566)
  }
}

.snow i:nth-child(275) {
  animation: fall-275 12s linear -22s infinite;
  opacity: .7533;
  transform: translate(96.8683vw, -10px) scale(.5365)
}

@keyframes fall-275 {
  35.426% {
    transform: translate(89.2171vw, 35.426vh) scale(.5365)
  }

  to {
    transform: translate(93.0427vw, 100vh) scale(.5365)
  }
}

.snow i:nth-child(276) {
  animation: fall-276 26s linear -23s infinite;
  opacity: .5261;
  transform: translate(95.7174vw, -10px) scale(.5067)
}

@keyframes fall-276 {
  37.702% {
    transform: translate(87.5545vw, 37.702vh) scale(.5067)
  }

  to {
    transform: translate(91.63595vw, 100vh) scale(.5067)
  }
}

.snow i:nth-child(277) {
  animation: fall-277 23s linear -2s infinite;
  opacity: .7481;
  transform: translate(49.5329vw, -10px) scale(.4418)
}

@keyframes fall-277 {
  34.062% {
    transform: translate(59.115vw, 34.062vh) scale(.4418)
  }

  to {
    transform: translate(54.32395vw, 100vh) scale(.4418)
  }
}

.snow i:nth-child(278) {
  animation: fall-278 20s linear -7s infinite;
  opacity: .2784;
  transform: translate(63.7859vw, -10px) scale(.8457)
}

@keyframes fall-278 {
  30.753% {
    transform: translate(65.698vw, 30.753vh) scale(.8457)
  }

  to {
    transform: translate(64.74195vw, 100vh) scale(.8457)
  }
}

.snow i:nth-child(279) {
  animation: fall-279 11s linear -3s infinite;
  opacity: .9833;
  transform: translate(82.6781vw, -10px) scale(.0148)
}

@keyframes fall-279 {
  35.773% {
    transform: translate(83.4393vw, 35.773vh) scale(.0148)
  }

  to {
    transform: translate(83.0587vw, 100vh) scale(.0148)
  }
}

.snow i:nth-child(280) {
  animation: fall-280 17s linear -28s infinite;
  opacity: .5373;
  transform: translate(81.1525vw, -10px) scale(.1842)
}

@keyframes fall-280 {
  41.487% {
    transform: translate(77.8406vw, 41.487vh) scale(.1842)
  }

  to {
    transform: translate(79.49655vw, 100vh) scale(.1842)
  }
}

.snow i:nth-child(281) {
  animation: fall-281 18s linear -10s infinite;
  opacity: .7027;
  transform: translate(1.2106vw, -10px) scale(.6322)
}

@keyframes fall-281 {
  31.894% {
    transform: translate(-6.1955vw, 31.894vh) scale(.6322)
  }

  to {
    transform: translate(-2.49245vw, 100vh) scale(.6322)
  }
}

.snow i:nth-child(282) {
  animation: fall-282 16s linear -2s infinite;
  opacity: .5537;
  transform: translate(57.532vw, -10px) scale(.3279)
}

@keyframes fall-282 {
  33.967% {
    transform: translate(53.4123vw, 33.967vh) scale(.3279)
  }

  to {
    transform: translate(55.47215vw, 100vh) scale(.3279)
  }
}

.snow i:nth-child(283) {
  animation: fall-283 15s linear -2s infinite;
  opacity: .5319;
  transform: translate(28.2865vw, -10px) scale(.1294)
}

@keyframes fall-283 {
  77.638% {
    transform: translate(19.1305vw, 77.638vh) scale(.1294)
  }

  to {
    transform: translate(23.7085vw, 100vh) scale(.1294)
  }
}

.snow i:nth-child(284) {
  animation: fall-284 13s linear -9s infinite;
  opacity: .3842;
  transform: translate(77.0561vw, -10px) scale(.9765)
}

@keyframes fall-284 {
  59.474% {
    transform: translate(83.0682vw, 59.474vh) scale(.9765)
  }

  to {
    transform: translate(80.06215vw, 100vh) scale(.9765)
  }
}

.snow i:nth-child(285) {
  animation: fall-285 11s linear -27s infinite;
  opacity: .1747;
  transform: translate(37.7462vw, -10px) scale(.2332)
}

@keyframes fall-285 {
  78.514% {
    transform: translate(37.9724vw, 78.514vh) scale(.2332)
  }

  to {
    transform: translate(37.8593vw, 100vh) scale(.2332)
  }
}

.snow i:nth-child(286) {
  animation: fall-286 10s linear -26s infinite;
  opacity: .3548;
  transform: translate(32.4345vw, -10px) scale(.1804)
}

@keyframes fall-286 {
  70.495% {
    transform: translate(40.1205vw, 70.495vh) scale(.1804)
  }

  to {
    transform: translate(36.2775vw, 100vh) scale(.1804)
  }
}

.snow i:nth-child(287) {
  animation: fall-287 22s linear -10s infinite;
  opacity: .8812;
  transform: translate(82.1372vw, -10px) scale(.1884)
}

@keyframes fall-287 {
  62.437% {
    transform: translate(88.7355vw, 62.437vh) scale(.1884)
  }

  to {
    transform: translate(85.43635vw, 100vh) scale(.1884)
  }
}

.snow i:nth-child(288) {
  animation: fall-288 23s linear -29s infinite;
  opacity: .846;
  transform: translate(82.3642vw, -10px) scale(.093)
}

@keyframes fall-288 {
  49.875% {
    transform: translate(88.5257vw, 49.875vh) scale(.093)
  }

  to {
    transform: translate(85.44495vw, 100vh) scale(.093)
  }
}

.snow i:nth-child(289) {
  animation: fall-289 27s linear -28s infinite;
  opacity: .2596;
  transform: translate(43.0645vw, -10px) scale(.838)
}

@keyframes fall-289 {
  78.674% {
    transform: translate(43.3964vw, 78.674vh) scale(.838)
  }

  to {
    transform: translate(43.23045vw, 100vh) scale(.838)
  }
}

.snow i:nth-child(290) {
  animation: fall-290 17s linear -20s infinite;
  opacity: .9365;
  transform: translate(54.6109vw, -10px) scale(.9411)
}

@keyframes fall-290 {
  36.556% {
    transform: translate(57.8845vw, 36.556vh) scale(.9411)
  }

  to {
    transform: translate(56.2477vw, 100vh) scale(.9411)
  }
}

.snow i:nth-child(291) {
  animation: fall-291 22s linear -5s infinite;
  opacity: .5529;
  transform: translate(27.9463vw, -10px) scale(.5231)
}

@keyframes fall-291 {
  58.586% {
    transform: translate(25.9275vw, 58.586vh) scale(.5231)
  }

  to {
    transform: translate(26.9369vw, 100vh) scale(.5231)
  }
}

.snow i:nth-child(292) {
  animation: fall-292 23s linear -24s infinite;
  opacity: .9203;
  transform: translate(52.8752vw, -10px) scale(.0623)
}

@keyframes fall-292 {
  68.082% {
    transform: translate(49.4324vw, 68.082vh) scale(.0623)
  }

  to {
    transform: translate(51.1538vw, 100vh) scale(.0623)
  }
}

.snow i:nth-child(293) {
  animation: fall-293 23s linear -3s infinite;
  opacity: .95;
  transform: translate(81.2773vw, -10px) scale(.5836)
}

@keyframes fall-293 {
  72.77% {
    transform: translate(77.1062vw, 72.77vh) scale(.5836)
  }

  to {
    transform: translate(79.19175vw, 100vh) scale(.5836)
  }
}

.snow i:nth-child(294) {
  animation: fall-294 27s linear -22s infinite;
  opacity: .1728;
  transform: translate(39.8869vw, -10px) scale(.221)
}

@keyframes fall-294 {
  49.836% {
    transform: translate(40.095vw, 49.836vh) scale(.221)
  }

  to {
    transform: translate(39.99095vw, 100vh) scale(.221)
  }
}

.snow i:nth-child(295) {
  animation: fall-295 14s linear -27s infinite;
  opacity: .2145;
  transform: translate(12.8248vw, -10px) scale(.8707)
}

@keyframes fall-295 {
  69.386% {
    transform: translate(3.4974vw, 69.386vh) scale(.8707)
  }

  to {
    transform: translate(8.1611vw, 100vh) scale(.8707)
  }
}

.snow i:nth-child(296) {
  animation: fall-296 29s linear -2s infinite;
  opacity: .2098;
  transform: translate(41.9198vw, -10px) scale(.2434)
}

@keyframes fall-296 {
  64.898% {
    transform: translate(32.2592vw, 64.898vh) scale(.2434)
  }

  to {
    transform: translate(37.0895vw, 100vh) scale(.2434)
  }
}

.snow i:nth-child(297) {
  animation: fall-297 15s linear -16s infinite;
  opacity: .3125;
  transform: translate(19.9029vw, -10px) scale(.2385)
}

@keyframes fall-297 {
  63.364% {
    transform: translate(25.1545vw, 63.364vh) scale(.2385)
  }

  to {
    transform: translate(22.5287vw, 100vh) scale(.2385)
  }
}

.snow i:nth-child(298) {
  animation: fall-298 28s linear -8s infinite;
  opacity: .6613;
  transform: translate(28.9721vw, -10px) scale(.5318)
}

@keyframes fall-298 {
  58.331% {
    transform: translate(19.7565vw, 58.331vh) scale(.5318)
  }

  to {
    transform: translate(24.3643vw, 100vh) scale(.5318)
  }
}

.snow i:nth-child(299) {
  animation: fall-299 30s linear -8s infinite;
  opacity: .8356;
  transform: translate(64.8106vw, -10px) scale(.0033)
}

@keyframes fall-299 {
  42.145% {
    transform: translate(55.9582vw, 42.145vh) scale(.0033)
  }

  to {
    transform: translate(60.3844vw, 100vh) scale(.0033)
  }
}

.snow i:nth-child(300) {
  animation: fall-300 17s linear -29s infinite;
  opacity: .1091;
  transform: translate(4.5867vw, -10px) scale(.484)
}

@keyframes fall-300 {
  78.317% {
    transform: translate(9.7281vw, 78.317vh) scale(.484)
  }

  to {
    transform: translate(7.1574vw, 100vh) scale(.484)
  }
}

.snow i:nth-child(301) {
  animation: fall-301 26s linear -6s infinite;
  opacity: .6215;
  transform: translate(49.09vw, -10px) scale(.9223)
}

@keyframes fall-301 {
  52.769% {
    transform: translate(45.6892vw, 52.769vh) scale(.9223)
  }

  to {
    transform: translate(47.3896vw, 100vh) scale(.9223)
  }
}

.snow i:nth-child(302) {
  animation: fall-302 24s linear -9s infinite;
  opacity: .4039;
  transform: translate(58.5698vw, -10px) scale(.8815)
}

@keyframes fall-302 {
  77.406% {
    transform: translate(65.3475vw, 77.406vh) scale(.8815)
  }

  to {
    transform: translate(61.95865vw, 100vh) scale(.8815)
  }
}

.snow i:nth-child(303) {
  animation: fall-303 17s linear -5s infinite;
  opacity: .5094;
  transform: translate(28.5828vw, -10px) scale(.6033)
}

@keyframes fall-303 {
  38.325% {
    transform: translate(38.5706vw, 38.325vh) scale(.6033)
  }

  to {
    transform: translate(33.5767vw, 100vh) scale(.6033)
  }
}

.snow i:nth-child(304) {
  animation: fall-304 28s linear -19s infinite;
  opacity: .8055;
  transform: translate(61.4793vw, -10px) scale(.1022)
}

@keyframes fall-304 {
  71.987% {
    transform: translate(70.0503vw, 71.987vh) scale(.1022)
  }

  to {
    transform: translate(65.7648vw, 100vh) scale(.1022)
  }
}

.snow i:nth-child(305) {
  animation: fall-305 27s linear -15s infinite;
  opacity: .2462;
  transform: translate(63.4334vw, -10px) scale(.3638)
}

@keyframes fall-305 {
  37.088% {
    transform: translate(72.8239vw, 37.088vh) scale(.3638)
  }

  to {
    transform: translate(68.12865vw, 100vh) scale(.3638)
  }
}

.snow i:nth-child(306) {
  animation: fall-306 15s linear -20s infinite;
  opacity: .4787;
  transform: translate(94.0359vw, -10px) scale(.5352)
}

@keyframes fall-306 {
  44.977% {
    transform: translate(103.4725vw, 44.977vh) scale(.5352)
  }

  to {
    transform: translate(98.7542vw, 100vh) scale(.5352)
  }
}

.snow i:nth-child(307) {
  animation: fall-307 17s linear -12s infinite;
  opacity: .5011;
  transform: translate(23.3394vw, -10px) scale(.0728)
}

@keyframes fall-307 {
  54.099% {
    transform: translate(26.0765vw, 54.099vh) scale(.0728)
  }

  to {
    transform: translate(24.70795vw, 100vh) scale(.0728)
  }
}

.snow i:nth-child(308) {
  animation: fall-308 30s linear -23s infinite;
  opacity: .9236;
  transform: translate(81.1323vw, -10px) scale(.1968)
}

@keyframes fall-308 {
  54.678% {
    transform: translate(75.9682vw, 54.678vh) scale(.1968)
  }

  to {
    transform: translate(78.55025vw, 100vh) scale(.1968)
  }
}

.snow i:nth-child(309) {
  animation: fall-309 25s linear -24s infinite;
  opacity: .0827;
  transform: translate(47.606vw, -10px) scale(.1349)
}

@keyframes fall-309 {
  34.627% {
    transform: translate(45.2656vw, 34.627vh) scale(.1349)
  }

  to {
    transform: translate(46.4358vw, 100vh) scale(.1349)
  }
}

.snow i:nth-child(310) {
  animation: fall-310 28s linear -4s infinite;
  opacity: .312;
  transform: translate(82.9313vw, -10px) scale(.5236)
}

@keyframes fall-310 {
  44.678% {
    transform: translate(87.1982vw, 44.678vh) scale(.5236)
  }

  to {
    transform: translate(85.06475vw, 100vh) scale(.5236)
  }
}

.snow i:nth-child(311) {
  animation: fall-311 13s linear -13s infinite;
  opacity: .4389;
  transform: translate(25.4819vw, -10px) scale(.8636)
}

@keyframes fall-311 {
  58.915% {
    transform: translate(30.7532vw, 58.915vh) scale(.8636)
  }

  to {
    transform: translate(28.11755vw, 100vh) scale(.8636)
  }
}

.snow i:nth-child(312) {
  animation: fall-312 12s linear -28s infinite;
  opacity: .7214;
  transform: translate(55.0476vw, -10px) scale(.0318)
}

@keyframes fall-312 {
  55.221% {
    transform: translate(51.8868vw, 55.221vh) scale(.0318)
  }

  to {
    transform: translate(53.4672vw, 100vh) scale(.0318)
  }
}

.snow i:nth-child(313) {
  animation: fall-313 28s linear -25s infinite;
  opacity: .2826;
  transform: translate(49.0963vw, -10px) scale(.6689)
}

@keyframes fall-313 {
  61.551% {
    transform: translate(56.4797vw, 61.551vh) scale(.6689)
  }

  to {
    transform: translate(52.788vw, 100vh) scale(.6689)
  }
}

.snow i:nth-child(314) {
  animation: fall-314 20s linear -25s infinite;
  opacity: .6517;
  transform: translate(61.9815vw, -10px) scale(.8173)
}

@keyframes fall-314 {
  43.672% {
    transform: translate(59.7705vw, 43.672vh) scale(.8173)
  }

  to {
    transform: translate(60.876vw, 100vh) scale(.8173)
  }
}

.snow i:nth-child(315) {
  animation: fall-315 15s linear -2s infinite;
  opacity: .8669;
  transform: translate(56.7257vw, -10px) scale(.523)
}

@keyframes fall-315 {
  70.974% {
    transform: translate(57.9594vw, 70.974vh) scale(.523)
  }

  to {
    transform: translate(57.34255vw, 100vh) scale(.523)
  }
}

.snow i:nth-child(316) {
  animation: fall-316 28s linear -18s infinite;
  opacity: .5266;
  transform: translate(71.648vw, -10px) scale(.1486)
}

@keyframes fall-316 {
  74.024% {
    transform: translate(77.0657vw, 74.024vh) scale(.1486)
  }

  to {
    transform: translate(74.35685vw, 100vh) scale(.1486)
  }
}

.snow i:nth-child(317) {
  animation: fall-317 14s linear -21s infinite;
  opacity: .183;
  transform: translate(50.6335vw, -10px) scale(.8243)
}

@keyframes fall-317 {
  63.662% {
    transform: translate(57.9276vw, 63.662vh) scale(.8243)
  }

  to {
    transform: translate(54.28055vw, 100vh) scale(.8243)
  }
}

.snow i:nth-child(318) {
  animation: fall-318 16s linear -20s infinite;
  opacity: .2424;
  transform: translate(97.4547vw, -10px) scale(.8919)
}

@keyframes fall-318 {
  76.872% {
    transform: translate(97.3618vw, 76.872vh) scale(.8919)
  }

  to {
    transform: translate(97.40825vw, 100vh) scale(.8919)
  }
}

.snow i:nth-child(319) {
  animation: fall-319 26s linear -30s infinite;
  opacity: .0133;
  transform: translate(89.3465vw, -10px) scale(.4705)
}

@keyframes fall-319 {
  41.496% {
    transform: translate(95.3742vw, 41.496vh) scale(.4705)
  }

  to {
    transform: translate(92.36035vw, 100vh) scale(.4705)
  }
}

.snow i:nth-child(320) {
  animation: fall-320 24s linear -9s infinite;
  opacity: .111;
  transform: translate(.6958vw, -10px) scale(.8298)
}

@keyframes fall-320 {
  75.761% {
    transform: translate(1.2544vw, 75.761vh) scale(.8298)
  }

  to {
    transform: translate(.9751vw, 100vh) scale(.8298)
  }
}

.snow i:nth-child(321) {
  animation: fall-321 27s linear -30s infinite;
  opacity: .5961;
  transform: translate(99.5713vw, -10px) scale(.7565)
}

@keyframes fall-321 {
  41.048% {
    transform: translate(103.4736vw, 41.048vh) scale(.7565)
  }

  to {
    transform: translate(101.52245vw, 100vh) scale(.7565)
  }
}

.snow i:nth-child(322) {
  animation: fall-322 20s linear -24s infinite;
  opacity: .7152;
  transform: translate(28.1072vw, -10px) scale(.5702)
}

@keyframes fall-322 {
  49.479% {
    transform: translate(22.8349vw, 49.479vh) scale(.5702)
  }

  to {
    transform: translate(25.47105vw, 100vh) scale(.5702)
  }
}

.snow i:nth-child(323) {
  animation: fall-323 12s linear -22s infinite;
  opacity: .6266;
  transform: translate(64.7005vw, -10px) scale(.7509)
}

@keyframes fall-323 {
  32.035% {
    transform: translate(69.8788vw, 32.035vh) scale(.7509)
  }

  to {
    transform: translate(67.28965vw, 100vh) scale(.7509)
  }
}

.snow i:nth-child(324) {
  animation: fall-324 25s linear -10s infinite;
  opacity: .6995;
  transform: translate(94.8267vw, -10px) scale(.3524)
}

@keyframes fall-324 {
  64.707% {
    transform: translate(102.163vw, 64.707vh) scale(.3524)
  }

  to {
    transform: translate(98.49485vw, 100vh) scale(.3524)
  }
}

.snow i:nth-child(325) {
  animation: fall-325 10s linear -18s infinite;
  opacity: .7929;
  transform: translate(10.0161vw, -10px) scale(.4501)
}

@keyframes fall-325 {
  52.487% {
    transform: translate(.7436vw, 52.487vh) scale(.4501)
  }

  to {
    transform: translate(5.37985vw, 100vh) scale(.4501)
  }
}

.snow i:nth-child(326) {
  animation: fall-326 20s linear -15s infinite;
  opacity: .1594;
  transform: translate(88.919vw, -10px) scale(.787)
}

@keyframes fall-326 {
  56.005% {
    transform: translate(84.3404vw, 56.005vh) scale(.787)
  }

  to {
    transform: translate(86.6297vw, 100vh) scale(.787)
  }
}

.snow i:nth-child(327) {
  animation: fall-327 14s linear -4s infinite;
  opacity: .1679;
  transform: translate(4.2078vw, -10px) scale(.0286)
}

@keyframes fall-327 {
  64.696% {
    transform: translate(2.3843vw, 64.696vh) scale(.0286)
  }

  to {
    transform: translate(3.29605vw, 100vh) scale(.0286)
  }
}

.snow i:nth-child(328) {
  animation: fall-328 28s linear -1s infinite;
  opacity: .9259;
  transform: translate(9.4587vw, -10px) scale(.7151)
}

@keyframes fall-328 {
  64.3% {
    transform: translate(1.3196vw, 64.3vh) scale(.7151)
  }

  to {
    transform: translate(5.38915vw, 100vh) scale(.7151)
  }
}

.snow i:nth-child(329) {
  animation: fall-329 30s linear -21s infinite;
  opacity: .4529;
  transform: translate(99.9561vw, -10px) scale(.832)
}

@keyframes fall-329 {
  61.55% {
    transform: translate(99.3379vw, 61.55vh) scale(.832)
  }

  to {
    transform: translate(99.647vw, 100vh) scale(.832)
  }
}

.snow i:nth-child(330) {
  animation: fall-330 24s linear -30s infinite;
  opacity: .233;
  transform: translate(16.5798vw, -10px) scale(.343)
}

@keyframes fall-330 {
  65.57% {
    transform: translate(8.786vw, 65.57vh) scale(.343)
  }

  to {
    transform: translate(12.6829vw, 100vh) scale(.343)
  }
}

.snow i:nth-child(331) {
  animation: fall-331 11s linear -11s infinite;
  opacity: .8955;
  transform: translate(53.3118vw, -10px) scale(.9119)
}

@keyframes fall-331 {
  54.672% {
    transform: translate(58.0472vw, 54.672vh) scale(.9119)
  }

  to {
    transform: translate(55.6795vw, 100vh) scale(.9119)
  }
}

.snow i:nth-child(332) {
  animation: fall-332 12s linear -16s infinite;
  opacity: .6396;
  transform: translate(27.4858vw, -10px) scale(.6012)
}

@keyframes fall-332 {
  35.343% {
    transform: translate(21.9421vw, 35.343vh) scale(.6012)
  }

  to {
    transform: translate(24.71395vw, 100vh) scale(.6012)
  }
}

.snow i:nth-child(333) {
  animation: fall-333 16s linear -16s infinite;
  opacity: .5022;
  transform: translate(67.8181vw, -10px) scale(.9523)
}

@keyframes fall-333 {
  69.889% {
    transform: translate(75.5563vw, 69.889vh) scale(.9523)
  }

  to {
    transform: translate(71.6872vw, 100vh) scale(.9523)
  }
}

.snow i:nth-child(334) {
  animation: fall-334 22s linear -20s infinite;
  opacity: .8596;
  transform: translate(25.3395vw, -10px) scale(.6287)
}

@keyframes fall-334 {
  74.51% {
    transform: translate(24.7111vw, 74.51vh) scale(.6287)
  }

  to {
    transform: translate(25.0253vw, 100vh) scale(.6287)
  }
}

.snow i:nth-child(335) {
  animation: fall-335 12s linear -1s infinite;
  opacity: .1385;
  transform: translate(1.2577vw, -10px) scale(.9192)
}

@keyframes fall-335 {
  43.137% {
    transform: translate(-1.4886vw, 43.137vh) scale(.9192)
  }

  to {
    transform: translate(-.11545vw, 100vh) scale(.9192)
  }
}

.snow i:nth-child(336) {
  animation: fall-336 26s linear -20s infinite;
  opacity: .7193;
  transform: translate(60.548vw, -10px) scale(.0351)
}

@keyframes fall-336 {
  45.863% {
    transform: translate(59.8547vw, 45.863vh) scale(.0351)
  }

  to {
    transform: translate(60.20135vw, 100vh) scale(.0351)
  }
}

.snow i:nth-child(337) {
  animation: fall-337 20s linear -7s infinite;
  opacity: .0756;
  transform: translate(61.3725vw, -10px) scale(.1299)
}

@keyframes fall-337 {
  77.483% {
    transform: translate(67.9294vw, 77.483vh) scale(.1299)
  }

  to {
    transform: translate(64.65095vw, 100vh) scale(.1299)
  }
}

.snow i:nth-child(338) {
  animation: fall-338 24s linear -19s infinite;
  opacity: .6373;
  transform: translate(37.3421vw, -10px) scale(.3042)
}

@keyframes fall-338 {
  77.571% {
    transform: translate(31.9474vw, 77.571vh) scale(.3042)
  }

  to {
    transform: translate(34.64475vw, 100vh) scale(.3042)
  }
}

.snow i:nth-child(339) {
  animation: fall-339 18s linear -19s infinite;
  opacity: .7959;
  transform: translate(30.2661vw, -10px) scale(.3316)
}

@keyframes fall-339 {
  32.968% {
    transform: translate(20.8105vw, 32.968vh) scale(.3316)
  }

  to {
    transform: translate(25.5383vw, 100vh) scale(.3316)
  }
}

.snow i:nth-child(340) {
  animation: fall-340 12s linear -15s infinite;
  opacity: .833;
  transform: translate(67.8204vw, -10px) scale(.7482)
}

@keyframes fall-340 {
  55.94% {
    transform: translate(63.44vw, 55.94vh) scale(.7482)
  }

  to {
    transform: translate(65.6302vw, 100vh) scale(.7482)
  }
}

.snow i:nth-child(341) {
  animation: fall-341 19s linear -29s infinite;
  opacity: .7255;
  transform: translate(43.3808vw, -10px) scale(.1965)
}

@keyframes fall-341 {
  37.54% {
    transform: translate(49.9996vw, 37.54vh) scale(.1965)
  }

  to {
    transform: translate(46.6902vw, 100vh) scale(.1965)
  }
}

.snow i:nth-child(342) {
  animation: fall-342 14s linear -4s infinite;
  opacity: .6201;
  transform: translate(5.236vw, -10px) scale(.3131)
}

@keyframes fall-342 {
  78.224% {
    transform: translate(11.3443vw, 78.224vh) scale(.3131)
  }

  to {
    transform: translate(8.29015vw, 100vh) scale(.3131)
  }
}

.snow i:nth-child(343) {
  animation: fall-343 18s linear -12s infinite;
  opacity: .8624;
  transform: translate(34.0077vw, -10px) scale(.7035)
}

@keyframes fall-343 {
  52.056% {
    transform: translate(37.1394vw, 52.056vh) scale(.7035)
  }

  to {
    transform: translate(35.57355vw, 100vh) scale(.7035)
  }
}

.snow i:nth-child(344) {
  animation: fall-344 10s linear -7s infinite;
  opacity: .2323;
  transform: translate(82.9193vw, -10px) scale(.1199)
}

@keyframes fall-344 {
  65.15% {
    transform: translate(84.8995vw, 65.15vh) scale(.1199)
  }

  to {
    transform: translate(83.9094vw, 100vh) scale(.1199)
  }
}

.snow i:nth-child(345) {
  animation: fall-345 25s linear -25s infinite;
  opacity: .6627;
  transform: translate(19.8007vw, -10px) scale(.5353)
}

@keyframes fall-345 {
  76.346% {
    transform: translate(13.4759vw, 76.346vh) scale(.5353)
  }

  to {
    transform: translate(16.6383vw, 100vh) scale(.5353)
  }
}

.snow i:nth-child(346) {
  animation: fall-346 28s linear -25s infinite;
  opacity: .1593;
  transform: translate(33.1476vw, -10px) scale(.9895)
}

@keyframes fall-346 {
  50.756% {
    transform: translate(25.6255vw, 50.756vh) scale(.9895)
  }

  to {
    transform: translate(29.38655vw, 100vh) scale(.9895)
  }
}

.snow i:nth-child(347) {
  animation: fall-347 29s linear -12s infinite;
  opacity: .7944;
  transform: translate(66.2357vw, -10px) scale(.1242)
}

@keyframes fall-347 {
  44.527% {
    transform: translate(75.0259vw, 44.527vh) scale(.1242)
  }

  to {
    transform: translate(70.6308vw, 100vh) scale(.1242)
  }
}

.snow i:nth-child(348) {
  animation: fall-348 21s linear -28s infinite;
  opacity: .8671;
  transform: translate(92.6164vw, -10px) scale(.154)
}

@keyframes fall-348 {
  40.441% {
    transform: translate(87.6127vw, 40.441vh) scale(.154)
  }

  to {
    transform: translate(90.11455vw, 100vh) scale(.154)
  }
}

.snow i:nth-child(349) {
  animation: fall-349 26s linear -16s infinite;
  opacity: .0329;
  transform: translate(39.0601vw, -10px) scale(.97)
}

@keyframes fall-349 {
  56.86% {
    transform: translate(29.14vw, 56.86vh) scale(.97)
  }

  to {
    transform: translate(34.10005vw, 100vh) scale(.97)
  }
}

.snow i:nth-child(350) {
  animation: fall-350 12s linear -9s infinite;
  opacity: .8096;
  transform: translate(6.4559vw, -10px) scale(.847)
}

@keyframes fall-350 {
  53.733% {
    transform: translate(2.1481vw, 53.733vh) scale(.847)
  }

  to {
    transform: translate(4.302vw, 100vh) scale(.847)
  }
}

.snow i:nth-child(351) {
  animation: fall-351 27s linear -11s infinite;
  opacity: .3476;
  transform: translate(76.8729vw, -10px) scale(.139)
}

@keyframes fall-351 {
  36.632% {
    transform: translate(86.6018vw, 36.632vh) scale(.139)
  }

  to {
    transform: translate(81.73735vw, 100vh) scale(.139)
  }
}

.snow i:nth-child(352) {
  animation: fall-352 13s linear -21s infinite;
  opacity: .9126;
  transform: translate(50.339vw, -10px) scale(.333)
}

@keyframes fall-352 {
  47.562% {
    transform: translate(50.7672vw, 47.562vh) scale(.333)
  }

  to {
    transform: translate(50.5531vw, 100vh) scale(.333)
  }
}

.snow i:nth-child(353) {
  animation: fall-353 17s linear -21s infinite;
  opacity: .5471;
  transform: translate(5.9325vw, -10px) scale(.0858)
}

@keyframes fall-353 {
  36.723% {
    transform: translate(-1.6243vw, 36.723vh) scale(.0858)
  }

  to {
    transform: translate(2.1541vw, 100vh) scale(.0858)
  }
}

.snow i:nth-child(354) {
  animation: fall-354 21s linear -19s infinite;
  opacity: .009;
  transform: translate(71.5794vw, -10px) scale(.7502)
}

@keyframes fall-354 {
  58.616% {
    transform: translate(65.1266vw, 58.616vh) scale(.7502)
  }

  to {
    transform: translate(68.353vw, 100vh) scale(.7502)
  }
}

.snow i:nth-child(355) {
  animation: fall-355 15s linear -20s infinite;
  opacity: .0809;
  transform: translate(6.1317vw, -10px) scale(.4402)
}

@keyframes fall-355 {
  67.764% {
    transform: translate(9.3486vw, 67.764vh) scale(.4402)
  }

  to {
    transform: translate(7.74015vw, 100vh) scale(.4402)
  }
}

.snow i:nth-child(356) {
  animation: fall-356 23s linear -30s infinite;
  opacity: .2345;
  transform: translate(62.1706vw, -10px) scale(.8754)
}

@keyframes fall-356 {
  56.088% {
    transform: translate(54.2305vw, 56.088vh) scale(.8754)
  }

  to {
    transform: translate(58.20055vw, 100vh) scale(.8754)
  }
}

.snow i:nth-child(357) {
  animation: fall-357 11s linear -6s infinite;
  opacity: .1957;
  transform: translate(84.9862vw, -10px) scale(.4606)
}

@keyframes fall-357 {
  61.296% {
    transform: translate(84.9831vw, 61.296vh) scale(.4606)
  }

  to {
    transform: translate(84.98465vw, 100vh) scale(.4606)
  }
}

.snow i:nth-child(358) {
  animation: fall-358 22s linear -21s infinite;
  opacity: .414;
  transform: translate(22.5343vw, -10px) scale(.9186)
}

@keyframes fall-358 {
  72.332% {
    transform: translate(30.4464vw, 72.332vh) scale(.9186)
  }

  to {
    transform: translate(26.49035vw, 100vh) scale(.9186)
  }
}

.snow i:nth-child(359) {
  animation: fall-359 28s linear -4s infinite;
  opacity: .7229;
  transform: translate(85.9969vw, -10px) scale(.1839)
}

@keyframes fall-359 {
  34.746% {
    transform: translate(83.2998vw, 34.746vh) scale(.1839)
  }

  to {
    transform: translate(84.64835vw, 100vh) scale(.1839)
  }
}

.snow i:nth-child(360) {
  animation: fall-360 15s linear -6s infinite;
  opacity: .6649;
  transform: translate(73.9597vw, -10px) scale(.7604)
}

@keyframes fall-360 {
  51.985% {
    transform: translate(74.9135vw, 51.985vh) scale(.7604)
  }

  to {
    transform: translate(74.4366vw, 100vh) scale(.7604)
  }
}

.snow i:nth-child(361) {
  animation: fall-361 25s linear -10s infinite;
  opacity: .7945;
  transform: translate(3.6501vw, -10px) scale(.2538)
}

@keyframes fall-361 {
  76.304% {
    transform: translate(-2.2955vw, 76.304vh) scale(.2538)
  }

  to {
    transform: translate(.6773vw, 100vh) scale(.2538)
  }
}

.snow i:nth-child(362) {
  animation: fall-362 19s linear -1s infinite;
  opacity: .0682;
  transform: translate(60.7954vw, -10px) scale(.0124)
}

@keyframes fall-362 {
  60.259% {
    transform: translate(69.3635vw, 60.259vh) scale(.0124)
  }

  to {
    transform: translate(65.07945vw, 100vh) scale(.0124)
  }
}

.snow i:nth-child(363) {
  animation: fall-363 21s linear -17s infinite;
  opacity: .2374;
  transform: translate(8.9049vw, -10px) scale(.3543)
}

@keyframes fall-363 {
  55.83% {
    transform: translate(7.3596vw, 55.83vh) scale(.3543)
  }

  to {
    transform: translate(8.13225vw, 100vh) scale(.3543)
  }
}

.snow i:nth-child(364) {
  animation: fall-364 13s linear -22s infinite;
  opacity: .6522;
  transform: translate(36.3096vw, -10px) scale(.6416)
}

@keyframes fall-364 {
  53.93% {
    transform: translate(46.0509vw, 53.93vh) scale(.6416)
  }

  to {
    transform: translate(41.18025vw, 100vh) scale(.6416)
  }
}

.snow i:nth-child(365) {
  animation: fall-365 20s linear -26s infinite;
  opacity: .3442;
  transform: translate(90.229vw, -10px) scale(.84)
}

@keyframes fall-365 {
  38.732% {
    transform: translate(84.7198vw, 38.732vh) scale(.84)
  }

  to {
    transform: translate(87.4744vw, 100vh) scale(.84)
  }
}

.snow i:nth-child(366) {
  animation: fall-366 17s linear -20s infinite;
  opacity: .1851;
  transform: translate(59.9865vw, -10px) scale(.6549)
}

@keyframes fall-366 {
  35.729% {
    transform: translate(63.5515vw, 35.729vh) scale(.6549)
  }

  to {
    transform: translate(61.769vw, 100vh) scale(.6549)
  }
}

.snow i:nth-child(367) {
  animation: fall-367 30s linear -24s infinite;
  opacity: .4658;
  transform: translate(19.6071vw, -10px) scale(.5428)
}

@keyframes fall-367 {
  76.838% {
    transform: translate(15.1055vw, 76.838vh) scale(.5428)
  }

  to {
    transform: translate(17.3563vw, 100vh) scale(.5428)
  }
}

.snow i:nth-child(368) {
  animation: fall-368 11s linear -16s infinite;
  opacity: .4773;
  transform: translate(62.1323vw, -10px) scale(.5338)
}

@keyframes fall-368 {
  54.835% {
    transform: translate(58.9301vw, 54.835vh) scale(.5338)
  }

  to {
    transform: translate(60.5312vw, 100vh) scale(.5338)
  }
}

.snow i:nth-child(369) {
  animation: fall-369 28s linear -5s infinite;
  opacity: .2177;
  transform: translate(53.1392vw, -10px) scale(.6541)
}

@keyframes fall-369 {
  74.521% {
    transform: translate(44.1975vw, 74.521vh) scale(.6541)
  }

  to {
    transform: translate(48.66835vw, 100vh) scale(.6541)
  }
}

.snow i:nth-child(370) {
  animation: fall-370 15s linear -21s infinite;
  opacity: .7473;
  transform: translate(74.8958vw, -10px) scale(.1136)
}

@keyframes fall-370 {
  70.983% {
    transform: translate(77.1404vw, 70.983vh) scale(.1136)
  }

  to {
    transform: translate(76.0181vw, 100vh) scale(.1136)
  }
}

.snow i:nth-child(371) {
  animation: fall-371 28s linear -26s infinite;
  opacity: .8503;
  transform: translate(24.8304vw, -10px) scale(.3672)
}

@keyframes fall-371 {
  39.453% {
    transform: translate(29.6714vw, 39.453vh) scale(.3672)
  }

  to {
    transform: translate(27.2509vw, 100vh) scale(.3672)
  }
}

.snow i:nth-child(372) {
  animation: fall-372 30s linear -5s infinite;
  opacity: .8033;
  transform: translate(3.2432vw, -10px) scale(.8019)
}

@keyframes fall-372 {
  64.373% {
    transform: translate(7.2646vw, 64.373vh) scale(.8019)
  }

  to {
    transform: translate(5.2539vw, 100vh) scale(.8019)
  }
}

.snow i:nth-child(373) {
  animation: fall-373 12s linear -14s infinite;
  opacity: .3373;
  transform: translate(54.6701vw, -10px) scale(.2943)
}

@keyframes fall-373 {
  37.49% {
    transform: translate(58.272vw, 37.49vh) scale(.2943)
  }

  to {
    transform: translate(56.47105vw, 100vh) scale(.2943)
  }
}

.snow i:nth-child(374) {
  animation: fall-374 10s linear -23s infinite;
  opacity: .9695;
  transform: translate(45.2711vw, -10px) scale(.5454)
}

@keyframes fall-374 {
  49.031% {
    transform: translate(37.1303vw, 49.031vh) scale(.5454)
  }

  to {
    transform: translate(41.2007vw, 100vh) scale(.5454)
  }
}

.snow i:nth-child(375) {
  animation: fall-375 25s linear -5s infinite;
  opacity: .1972;
  transform: translate(76.5395vw, -10px) scale(.296)
}

@keyframes fall-375 {
  55.49% {
    transform: translate(76.3101vw, 55.49vh) scale(.296)
  }

  to {
    transform: translate(76.4248vw, 100vh) scale(.296)
  }
}

.snow i:nth-child(376) {
  animation: fall-376 15s linear -23s infinite;
  opacity: .949;
  transform: translate(32.237vw, -10px) scale(.2369)
}

@keyframes fall-376 {
  51.954% {
    transform: translate(27.8523vw, 51.954vh) scale(.2369)
  }

  to {
    transform: translate(30.04465vw, 100vh) scale(.2369)
  }
}

.snow i:nth-child(377) {
  animation: fall-377 25s linear -23s infinite;
  opacity: .5423;
  transform: translate(22.0335vw, -10px) scale(.552)
}

@keyframes fall-377 {
  59.861% {
    transform: translate(17.4826vw, 59.861vh) scale(.552)
  }

  to {
    transform: translate(19.75805vw, 100vh) scale(.552)
  }
}

.snow i:nth-child(378) {
  animation: fall-378 10s linear -25s infinite;
  opacity: .8227;
  transform: translate(10.1614vw, -10px) scale(.3658)
}

@keyframes fall-378 {
  45.735% {
    transform: translate(15.5274vw, 45.735vh) scale(.3658)
  }

  to {
    transform: translate(12.8444vw, 100vh) scale(.3658)
  }
}

.snow i:nth-child(379) {
  animation: fall-379 13s linear -17s infinite;
  opacity: .7037;
  transform: translate(69.345vw, -10px) scale(.7623)
}

@keyframes fall-379 {
  74.954% {
    transform: translate(73.9779vw, 74.954vh) scale(.7623)
  }

  to {
    transform: translate(71.66145vw, 100vh) scale(.7623)
  }
}

.snow i:nth-child(380) {
  animation: fall-380 11s linear -30s infinite;
  opacity: .4721;
  transform: translate(35.3023vw, -10px) scale(.6088)
}

@keyframes fall-380 {
  33.595% {
    transform: translate(29.615vw, 33.595vh) scale(.6088)
  }

  to {
    transform: translate(32.45865vw, 100vh) scale(.6088)
  }
}

.snow i:nth-child(381) {
  animation: fall-381 26s linear -15s infinite;
  opacity: .0207;
  transform: translate(2.6468vw, -10px) scale(.271)
}

@keyframes fall-381 {
  79.649% {
    transform: translate(11.6954vw, 79.649vh) scale(.271)
  }

  to {
    transform: translate(7.1711vw, 100vh) scale(.271)
  }
}

.snow i:nth-child(382) {
  animation: fall-382 13s linear -11s infinite;
  opacity: .8788;
  transform: translate(49.7687vw, -10px) scale(.8001)
}

@keyframes fall-382 {
  76.535% {
    transform: translate(49.8584vw, 76.535vh) scale(.8001)
  }

  to {
    transform: translate(49.81355vw, 100vh) scale(.8001)
  }
}

.snow i:nth-child(383) {
  animation: fall-383 19s linear -5s infinite;
  opacity: .0941;
  transform: translate(21.3342vw, -10px) scale(.0408)
}

@keyframes fall-383 {
  31.973% {
    transform: translate(22.2691vw, 31.973vh) scale(.0408)
  }

  to {
    transform: translate(21.80165vw, 100vh) scale(.0408)
  }
}

.snow i:nth-child(384) {
  animation: fall-384 18s linear -25s infinite;
  opacity: .8977;
  transform: translate(4.3891vw, -10px) scale(.9333)
}

@keyframes fall-384 {
  34.8% {
    transform: translate(9.867vw, 34.8vh) scale(.9333)
  }

  to {
    transform: translate(7.12805vw, 100vh) scale(.9333)
  }
}

.snow i:nth-child(385) {
  animation: fall-385 24s linear -17s infinite;
  opacity: .2033;
  transform: translate(87.3459vw, -10px) scale(.0494)
}

@keyframes fall-385 {
  73.166% {
    transform: translate(89.7342vw, 73.166vh) scale(.0494)
  }

  to {
    transform: translate(88.54005vw, 100vh) scale(.0494)
  }
}

.snow i:nth-child(386) {
  animation: fall-386 10s linear -6s infinite;
  opacity: .0001;
  transform: translate(58.955vw, -10px) scale(.1244)
}

@keyframes fall-386 {
  45.905% {
    transform: translate(61.5241vw, 45.905vh) scale(.1244)
  }

  to {
    transform: translate(60.23955vw, 100vh) scale(.1244)
  }
}

.snow i:nth-child(387) {
  animation: fall-387 27s linear -29s infinite;
  opacity: .6496;
  transform: translate(13.887vw, -10px) scale(.3287)
}

@keyframes fall-387 {
  65.038% {
    transform: translate(21.4966vw, 65.038vh) scale(.3287)
  }

  to {
    transform: translate(17.6918vw, 100vh) scale(.3287)
  }
}

.snow i:nth-child(388) {
  animation: fall-388 17s linear -3s infinite;
  opacity: .8775;
  transform: translate(74.7351vw, -10px) scale(.0329)
}

@keyframes fall-388 {
  40.204% {
    transform: translate(72.5311vw, 40.204vh) scale(.0329)
  }

  to {
    transform: translate(73.6331vw, 100vh) scale(.0329)
  }
}

.snow i:nth-child(389) {
  animation: fall-389 20s linear -4s infinite;
  opacity: .7318;
  transform: translate(28.3931vw, -10px) scale(.1095)
}

@keyframes fall-389 {
  67.65% {
    transform: translate(34.108vw, 67.65vh) scale(.1095)
  }

  to {
    transform: translate(31.25055vw, 100vh) scale(.1095)
  }
}

.snow i:nth-child(390) {
  animation: fall-390 24s linear -30s infinite;
  opacity: .8122;
  transform: translate(55.0057vw, -10px) scale(.2247)
}

@keyframes fall-390 {
  69.103% {
    transform: translate(51.0483vw, 69.103vh) scale(.2247)
  }

  to {
    transform: translate(53.027vw, 100vh) scale(.2247)
  }
}

.snow i:nth-child(391) {
  animation: fall-391 20s linear -7s infinite;
  opacity: .0026;
  transform: translate(1.2502vw, -10px) scale(.8027)
}

@keyframes fall-391 {
  54.806% {
    transform: translate(7.4562vw, 54.806vh) scale(.8027)
  }

  to {
    transform: translate(4.3532vw, 100vh) scale(.8027)
  }
}

.snow i:nth-child(392) {
  animation: fall-392 14s linear -14s infinite;
  opacity: .6748;
  transform: translate(30.1658vw, -10px) scale(.1457)
}

@keyframes fall-392 {
  44.198% {
    transform: translate(34.2262vw, 44.198vh) scale(.1457)
  }

  to {
    transform: translate(32.196vw, 100vh) scale(.1457)
  }
}

.snow i:nth-child(393) {
  animation: fall-393 11s linear -24s infinite;
  opacity: .7931;
  transform: translate(38.0464vw, -10px) scale(.6999)
}

@keyframes fall-393 {
  53.972% {
    transform: translate(28.7363vw, 53.972vh) scale(.6999)
  }

  to {
    transform: translate(33.39135vw, 100vh) scale(.6999)
  }
}

.snow i:nth-child(394) {
  animation: fall-394 23s linear -28s infinite;
  opacity: .3169;
  transform: translate(28.1226vw, -10px) scale(.3571)
}

@keyframes fall-394 {
  65.295% {
    transform: translate(22.2337vw, 65.295vh) scale(.3571)
  }

  to {
    transform: translate(25.17815vw, 100vh) scale(.3571)
  }
}

.snow i:nth-child(395) {
  animation: fall-395 15s linear -8s infinite;
  opacity: .7218;
  transform: translate(95.9778vw, -10px) scale(.3337)
}

@keyframes fall-395 {
  74.399% {
    transform: translate(92.9544vw, 74.399vh) scale(.3337)
  }

  to {
    transform: translate(94.4661vw, 100vh) scale(.3337)
  }
}

.snow i:nth-child(396) {
  animation: fall-396 24s linear -16s infinite;
  opacity: .1992;
  transform: translate(44.9486vw, -10px) scale(.2536)
}

@keyframes fall-396 {
  77.351% {
    transform: translate(35.2218vw, 77.351vh) scale(.2536)
  }

  to {
    transform: translate(40.0852vw, 100vh) scale(.2536)
  }
}

.snow i:nth-child(397) {
  animation: fall-397 28s linear -15s infinite;
  opacity: .6562;
  transform: translate(56.1911vw, -10px) scale(.6743)
}

@keyframes fall-397 {
  58.843% {
    transform: translate(63.3652vw, 58.843vh) scale(.6743)
  }

  to {
    transform: translate(59.77815vw, 100vh) scale(.6743)
  }
}

.snow i:nth-child(398) {
  animation: fall-398 29s linear -6s infinite;
  opacity: .3302;
  transform: translate(39.0077vw, -10px) scale(.207)
}

@keyframes fall-398 {
  65.328% {
    transform: translate(47.7447vw, 65.328vh) scale(.207)
  }

  to {
    transform: translate(43.3762vw, 100vh) scale(.207)
  }
}

.snow i:nth-child(399) {
  animation: fall-399 23s linear -15s infinite;
  opacity: .6663;
  transform: translate(24.4549vw, -10px) scale(.9977)
}

@keyframes fall-399 {
  44.814% {
    transform: translate(16.6913vw, 44.814vh) scale(.9977)
  }

  to {
    transform: translate(20.5731vw, 100vh) scale(.9977)
  }
}

.snow i:nth-child(400) {
  animation: fall-400 19s linear -5s infinite;
  opacity: .5129;
  transform: translate(1.9512vw, -10px) scale(.8754)
}

@keyframes fall-400 {
  39.957% {
    transform: translate(2.7816vw, 39.957vh) scale(.8754)
  }

  to {
    transform: translate(2.3664vw, 100vh) scale(.8754)
  }
}

.snow i:nth-child(401) {
  animation: fall-401 30s linear -23s infinite;
  opacity: .0886;
  transform: translate(56.8403vw, -10px) scale(.1593)
}

@keyframes fall-401 {
  57.589% {
    transform: translate(59.2081vw, 57.589vh) scale(.1593)
  }

  to {
    transform: translate(58.0242vw, 100vh) scale(.1593)
  }
}

.snow i:nth-child(402) {
  animation: fall-402 19s linear -21s infinite;
  opacity: .8112;
  transform: translate(40.9219vw, -10px) scale(.189)
}

@keyframes fall-402 {
  33.018% {
    transform: translate(39.753vw, 33.018vh) scale(.189)
  }

  to {
    transform: translate(40.33745vw, 100vh) scale(.189)
  }
}

.snow i:nth-child(403) {
  animation: fall-403 11s linear -28s infinite;
  opacity: .9594;
  transform: translate(1.8904vw, -10px) scale(.9828)
}

@keyframes fall-403 {
  47.463% {
    transform: translate(-5.5456vw, 47.463vh) scale(.9828)
  }

  to {
    transform: translate(-1.8276vw, 100vh) scale(.9828)
  }
}

.snow i:nth-child(404) {
  animation: fall-404 15s linear -2s infinite;
  opacity: .9802;
  transform: translate(47.9723vw, -10px) scale(.9098)
}

@keyframes fall-404 {
  45.98% {
    transform: translate(49.925vw, 45.98vh) scale(.9098)
  }

  to {
    transform: translate(48.94865vw, 100vh) scale(.9098)
  }
}

.snow i:nth-child(405) {
  animation: fall-405 17s linear -15s infinite;
  opacity: .9863;
  transform: translate(79.073vw, -10px) scale(.7263)
}

@keyframes fall-405 {
  74.176% {
    transform: translate(77.5316vw, 74.176vh) scale(.7263)
  }

  to {
    transform: translate(78.3023vw, 100vh) scale(.7263)
  }
}

.snow i:nth-child(406) {
  animation: fall-406 19s linear -27s infinite;
  opacity: .2443;
  transform: translate(97.535vw, -10px) scale(.8981)
}

@keyframes fall-406 {
  73.522% {
    transform: translate(89.6469vw, 73.522vh) scale(.8981)
  }

  to {
    transform: translate(93.59095vw, 100vh) scale(.8981)
  }
}

.snow i:nth-child(407) {
  animation: fall-407 29s linear -14s infinite;
  opacity: .6073;
  transform: translate(3.3188vw, -10px) scale(.9665)
}

@keyframes fall-407 {
  56.004% {
    transform: translate(-3.3251vw, 56.004vh) scale(.9665)
  }

  to {
    transform: translate(-.00315vw, 100vh) scale(.9665)
  }
}

.snow i:nth-child(408) {
  animation: fall-408 17s linear -6s infinite;
  opacity: .0298;
  transform: translate(46.9283vw, -10px) scale(.6341)
}

@keyframes fall-408 {
  72.602% {
    transform: translate(38.4163vw, 72.602vh) scale(.6341)
  }

  to {
    transform: translate(42.6723vw, 100vh) scale(.6341)
  }
}

.snow i:nth-child(409) {
  animation: fall-409 17s linear -8s infinite;
  opacity: .1046;
  transform: translate(83.2587vw, -10px) scale(.1227)
}

@keyframes fall-409 {
  61.657% {
    transform: translate(77.9502vw, 61.657vh) scale(.1227)
  }

  to {
    transform: translate(80.60445vw, 100vh) scale(.1227)
  }
}

.snow i:nth-child(410) {
  animation: fall-410 14s linear -10s infinite;
  opacity: .0112;
  transform: translate(62.9215vw, -10px) scale(.7627)
}

@keyframes fall-410 {
  39.022% {
    transform: translate(54.3363vw, 39.022vh) scale(.7627)
  }

  to {
    transform: translate(58.6289vw, 100vh) scale(.7627)
  }
}

.snow i:nth-child(411) {
  animation: fall-411 10s linear -7s infinite;
  opacity: .1215;
  transform: translate(44.0606vw, -10px) scale(.4401)
}

@keyframes fall-411 {
  53.699% {
    transform: translate(45.317vw, 53.699vh) scale(.4401)
  }

  to {
    transform: translate(44.6888vw, 100vh) scale(.4401)
  }
}

.snow i:nth-child(412) {
  animation: fall-412 27s linear -19s infinite;
  opacity: .2237;
  transform: translate(67.734vw, -10px) scale(.3447)
}

@keyframes fall-412 {
  63.707% {
    transform: translate(60.2036vw, 63.707vh) scale(.3447)
  }

  to {
    transform: translate(63.9688vw, 100vh) scale(.3447)
  }
}

.snow i:nth-child(413) {
  animation: fall-413 24s linear -27s infinite;
  opacity: .3253;
  transform: translate(92.2384vw, -10px) scale(.1296)
}

@keyframes fall-413 {
  55.33% {
    transform: translate(86.3024vw, 55.33vh) scale(.1296)
  }

  to {
    transform: translate(89.2704vw, 100vh) scale(.1296)
  }
}

.snow i:nth-child(414) {
  animation: fall-414 24s linear -29s infinite;
  opacity: .541;
  transform: translate(48.3122vw, -10px) scale(.7624)
}

@keyframes fall-414 {
  65.545% {
    transform: translate(49.1266vw, 65.545vh) scale(.7624)
  }

  to {
    transform: translate(48.7194vw, 100vh) scale(.7624)
  }
}

.snow i:nth-child(415) {
  animation: fall-415 14s linear -6s infinite;
  opacity: .0624;
  transform: translate(31.4104vw, -10px) scale(.1614)
}

@keyframes fall-415 {
  34.318% {
    transform: translate(23.6969vw, 34.318vh) scale(.1614)
  }

  to {
    transform: translate(27.55365vw, 100vh) scale(.1614)
  }
}

.snow i:nth-child(416) {
  animation: fall-416 28s linear -13s infinite;
  opacity: .562;
  transform: translate(21.0375vw, -10px) scale(.7979)
}

@keyframes fall-416 {
  33.076% {
    transform: translate(18.9464vw, 33.076vh) scale(.7979)
  }

  to {
    transform: translate(19.99195vw, 100vh) scale(.7979)
  }
}

.snow i:nth-child(417) {
  animation: fall-417 24s linear -18s infinite;
  opacity: .422;
  transform: translate(89.3628vw, -10px) scale(.3407)
}

@keyframes fall-417 {
  77.232% {
    transform: translate(88.0717vw, 77.232vh) scale(.3407)
  }

  to {
    transform: translate(88.71725vw, 100vh) scale(.3407)
  }
}

.snow i:nth-child(418) {
  animation: fall-418 24s linear -18s infinite;
  opacity: .4288;
  transform: translate(32.0195vw, -10px) scale(.5186)
}

@keyframes fall-418 {
  69.029% {
    transform: translate(39.5027vw, 69.029vh) scale(.5186)
  }

  to {
    transform: translate(35.7611vw, 100vh) scale(.5186)
  }
}

.snow i:nth-child(419) {
  animation: fall-419 11s linear -8s infinite;
  opacity: .8438;
  transform: translate(59.9522vw, -10px) scale(.7475)
}

@keyframes fall-419 {
  51.219% {
    transform: translate(69.7046vw, 51.219vh) scale(.7475)
  }

  to {
    transform: translate(64.8284vw, 100vh) scale(.7475)
  }
}

.snow i:nth-child(420) {
  animation: fall-420 24s linear -9s infinite;
  opacity: .9706;
  transform: translate(48.3687vw, -10px) scale(.111)
}

@keyframes fall-420 {
  67.764% {
    transform: translate(53.8972vw, 67.764vh) scale(.111)
  }

  to {
    transform: translate(51.13295vw, 100vh) scale(.111)
  }
}

.snow i:nth-child(421) {
  animation: fall-421 26s linear -22s infinite;
  opacity: .9866;
  transform: translate(95.1946vw, -10px) scale(.2917)
}

@keyframes fall-421 {
  74.482% {
    transform: translate(101.6899vw, 74.482vh) scale(.2917)
  }

  to {
    transform: translate(98.44225vw, 100vh) scale(.2917)
  }
}

.snow i:nth-child(422) {
  animation: fall-422 20s linear -8s infinite;
  opacity: .9549;
  transform: translate(41.5113vw, -10px) scale(.1872)
}

@keyframes fall-422 {
  41.059% {
    transform: translate(40.3843vw, 41.059vh) scale(.1872)
  }

  to {
    transform: translate(40.9478vw, 100vh) scale(.1872)
  }
}

.snow i:nth-child(423) {
  animation: fall-423 25s linear -27s infinite;
  opacity: .2717;
  transform: translate(84.5975vw, -10px) scale(.0632)
}

@keyframes fall-423 {
  74.932% {
    transform: translate(92.1605vw, 74.932vh) scale(.0632)
  }

  to {
    transform: translate(88.379vw, 100vh) scale(.0632)
  }
}

.snow i:nth-child(424) {
  animation: fall-424 25s linear -1s infinite;
  opacity: .8211;
  transform: translate(24.0756vw, -10px) scale(.9314)
}

@keyframes fall-424 {
  73.522% {
    transform: translate(27.2604vw, 73.522vh) scale(.9314)
  }

  to {
    transform: translate(25.668vw, 100vh) scale(.9314)
  }
}

.snow i:nth-child(425) {
  animation: fall-425 26s linear -16s infinite;
  opacity: .55;
  transform: translate(33.1098vw, -10px) scale(.5357)
}

@keyframes fall-425 {
  47.021% {
    transform: translate(33.586vw, 47.021vh) scale(.5357)
  }

  to {
    transform: translate(33.3479vw, 100vh) scale(.5357)
  }
}

.snow i:nth-child(426) {
  animation: fall-426 13s linear -18s infinite;
  opacity: .2444;
  transform: translate(43.6234vw, -10px) scale(.0394)
}

@keyframes fall-426 {
  77.81% {
    transform: translate(42.2301vw, 77.81vh) scale(.0394)
  }

  to {
    transform: translate(42.92675vw, 100vh) scale(.0394)
  }
}

.snow i:nth-child(427) {
  animation: fall-427 12s linear -10s infinite;
  opacity: .3404;
  transform: translate(18.5248vw, -10px) scale(.1139)
}

@keyframes fall-427 {
  44.884% {
    transform: translate(21.9376vw, 44.884vh) scale(.1139)
  }

  to {
    transform: translate(20.2312vw, 100vh) scale(.1139)
  }
}

.snow i:nth-child(428) {
  animation: fall-428 18s linear -29s infinite;
  opacity: .2512;
  transform: translate(96.4484vw, -10px) scale(.8494)
}

@keyframes fall-428 {
  53.403% {
    transform: translate(103.074vw, 53.403vh) scale(.8494)
  }

  to {
    transform: translate(99.7612vw, 100vh) scale(.8494)
  }
}

.snow i:nth-child(429) {
  animation: fall-429 30s linear -30s infinite;
  opacity: .9009;
  transform: translate(28.428vw, -10px) scale(.5238)
}

@keyframes fall-429 {
  75.155% {
    transform: translate(28.6865vw, 75.155vh) scale(.5238)
  }

  to {
    transform: translate(28.55725vw, 100vh) scale(.5238)
  }
}

.snow i:nth-child(430) {
  animation: fall-430 14s linear -2s infinite;
  opacity: .9164;
  transform: translate(35.5843vw, -10px) scale(.7)
}

@keyframes fall-430 {
  77.757% {
    transform: translate(36.0366vw, 77.757vh) scale(.7)
  }

  to {
    transform: translate(35.81045vw, 100vh) scale(.7)
  }
}

.snow i:nth-child(431) {
  animation: fall-431 10s linear -16s infinite;
  opacity: .5378;
  transform: translate(44.74vw, -10px) scale(.3011)
}

@keyframes fall-431 {
  39.981% {
    transform: translate(43.5395vw, 39.981vh) scale(.3011)
  }

  to {
    transform: translate(44.13975vw, 100vh) scale(.3011)
  }
}

.snow i:nth-child(432) {
  animation: fall-432 19s linear -22s infinite;
  opacity: .5829;
  transform: translate(2.1496vw, -10px) scale(.197)
}

@keyframes fall-432 {
  62.184% {
    transform: translate(11.1845vw, 62.184vh) scale(.197)
  }

  to {
    transform: translate(6.66705vw, 100vh) scale(.197)
  }
}

.snow i:nth-child(433) {
  animation: fall-433 10s linear -5s infinite;
  opacity: .0919;
  transform: translate(10.7633vw, -10px) scale(.0459)
}

@keyframes fall-433 {
  65.57% {
    transform: translate(3.764vw, 65.57vh) scale(.0459)
  }

  to {
    transform: translate(7.26365vw, 100vh) scale(.0459)
  }
}

.snow i:nth-child(434) {
  animation: fall-434 28s linear -26s infinite;
  opacity: .8782;
  transform: translate(48.2423vw, -10px) scale(.0412)
}

@keyframes fall-434 {
  48.614% {
    transform: translate(57.7153vw, 48.614vh) scale(.0412)
  }

  to {
    transform: translate(52.9788vw, 100vh) scale(.0412)
  }
}

.snow i:nth-child(435) {
  animation: fall-435 23s linear -3s infinite;
  opacity: .9491;
  transform: translate(64.6918vw, -10px) scale(.839)
}

@keyframes fall-435 {
  75.915% {
    transform: translate(59.325vw, 75.915vh) scale(.839)
  }

  to {
    transform: translate(62.0084vw, 100vh) scale(.839)
  }
}

.snow i:nth-child(436) {
  animation: fall-436 13s linear -22s infinite;
  opacity: .0799;
  transform: translate(1.5277vw, -10px) scale(.5672)
}

@keyframes fall-436 {
  39.618% {
    transform: translate(5.8416vw, 39.618vh) scale(.5672)
  }

  to {
    transform: translate(3.68465vw, 100vh) scale(.5672)
  }
}

.snow i:nth-child(437) {
  animation: fall-437 25s linear -5s infinite;
  opacity: .4719;
  transform: translate(87.911vw, -10px) scale(.4745)
}

@keyframes fall-437 {
  33.641% {
    transform: translate(96.1055vw, 33.641vh) scale(.4745)
  }

  to {
    transform: translate(92.00825vw, 100vh) scale(.4745)
  }
}

.snow i:nth-child(438) {
  animation: fall-438 21s linear -28s infinite;
  opacity: .2624;
  transform: translate(44.3958vw, -10px) scale(.3575)
}

@keyframes fall-438 {
  61.499% {
    transform: translate(52.0679vw, 61.499vh) scale(.3575)
  }

  to {
    transform: translate(48.23185vw, 100vh) scale(.3575)
  }
}

.snow i:nth-child(439) {
  animation: fall-439 21s linear -3s infinite;
  opacity: .7279;
  transform: translate(56.59vw, -10px) scale(.0269)
}

@keyframes fall-439 {
  34.183% {
    transform: translate(58.2032vw, 34.183vh) scale(.0269)
  }

  to {
    transform: translate(57.3966vw, 100vh) scale(.0269)
  }
}

.snow i:nth-child(440) {
  animation: fall-440 20s linear -24s infinite;
  opacity: .3013;
  transform: translate(30.6937vw, -10px) scale(.8095)
}

@keyframes fall-440 {
  38.602% {
    transform: translate(40.024vw, 38.602vh) scale(.8095)
  }

  to {
    transform: translate(35.35885vw, 100vh) scale(.8095)
  }
}

.snow i:nth-child(441) {
  animation: fall-441 24s linear -10s infinite;
  opacity: .2855;
  transform: translate(29.5815vw, -10px) scale(.3888)
}

@keyframes fall-441 {
  50.205% {
    transform: translate(26.8692vw, 50.205vh) scale(.3888)
  }

  to {
    transform: translate(28.22535vw, 100vh) scale(.3888)
  }
}

.snow i:nth-child(442) {
  animation: fall-442 26s linear -30s infinite;
  opacity: .2903;
  transform: translate(9.6971vw, -10px) scale(.2558)
}

@keyframes fall-442 {
  32.065% {
    transform: translate(7.7411vw, 32.065vh) scale(.2558)
  }

  to {
    transform: translate(8.7191vw, 100vh) scale(.2558)
  }
}

.snow i:nth-child(443) {
  animation: fall-443 25s linear -5s infinite;
  opacity: .3142;
  transform: translate(83.094vw, -10px) scale(.9921)
}

@keyframes fall-443 {
  65.342% {
    transform: translate(83.6821vw, 65.342vh) scale(.9921)
  }

  to {
    transform: translate(83.38805vw, 100vh) scale(.9921)
  }
}

.snow i:nth-child(444) {
  animation: fall-444 10s linear -18s infinite;
  opacity: .3115;
  transform: translate(21.2233vw, -10px) scale(.26)
}

@keyframes fall-444 {
  34.73% {
    transform: translate(23.0995vw, 34.73vh) scale(.26)
  }

  to {
    transform: translate(22.1614vw, 100vh) scale(.26)
  }
}

.snow i:nth-child(445) {
  animation: fall-445 22s linear -12s infinite;
  opacity: .7601;
  transform: translate(90.1651vw, -10px) scale(.7511)
}

@keyframes fall-445 {
  59.248% {
    transform: translate(94.6483vw, 59.248vh) scale(.7511)
  }

  to {
    transform: translate(92.4067vw, 100vh) scale(.7511)
  }
}

.snow i:nth-child(446) {
  animation: fall-446 17s linear -1s infinite;
  opacity: .8277;
  transform: translate(72.3697vw, -10px) scale(.2681)
}

@keyframes fall-446 {
  31.04% {
    transform: translate(78.5027vw, 31.04vh) scale(.2681)
  }

  to {
    transform: translate(75.4362vw, 100vh) scale(.2681)
  }
}

.snow i:nth-child(447) {
  animation: fall-447 22s linear -5s infinite;
  opacity: .84;
  transform: translate(48.493vw, -10px) scale(.9338)
}

@keyframes fall-447 {
  77.15% {
    transform: translate(39.6452vw, 77.15vh) scale(.9338)
  }

  to {
    transform: translate(44.0691vw, 100vh) scale(.9338)
  }
}

.snow i:nth-child(448) {
  animation: fall-448 30s linear -1s infinite;
  opacity: .7114;
  transform: translate(93.9133vw, -10px) scale(.9698)
}

@keyframes fall-448 {
  44.667% {
    transform: translate(95.9153vw, 44.667vh) scale(.9698)
  }

  to {
    transform: translate(94.9143vw, 100vh) scale(.9698)
  }
}

.snow i:nth-child(449) {
  animation: fall-449 21s linear -27s infinite;
  opacity: .9696;
  transform: translate(99.968vw, -10px) scale(.3932)
}

@keyframes fall-449 {
  40.448% {
    transform: translate(90.9625vw, 40.448vh) scale(.3932)
  }

  to {
    transform: translate(95.46525vw, 100vh) scale(.3932)
  }
}

.snow i:nth-child(450) {
  animation: fall-450 25s linear -20s infinite;
  opacity: .7145;
  transform: translate(62.4741vw, -10px) scale(.1287)
}

@keyframes fall-450 {
  30.537% {
    transform: translate(70.9603vw, 30.537vh) scale(.1287)
  }

  to {
    transform: translate(66.7172vw, 100vh) scale(.1287)
  }
}

.snow i:nth-child(451) {
  animation: fall-451 17s linear -27s infinite;
  opacity: .4405;
  transform: translate(57.5462vw, -10px) scale(.7422)
}

@keyframes fall-451 {
  65.765% {
    transform: translate(50.1847vw, 65.765vh) scale(.7422)
  }

  to {
    transform: translate(53.86545vw, 100vh) scale(.7422)
  }
}

.snow i:nth-child(452) {
  animation: fall-452 30s linear -20s infinite;
  opacity: .2861;
  transform: translate(49.3662vw, -10px) scale(.9887)
}

@keyframes fall-452 {
  70.912% {
    transform: translate(59.3362vw, 70.912vh) scale(.9887)
  }

  to {
    transform: translate(54.3512vw, 100vh) scale(.9887)
  }
}

.snow i:nth-child(453) {
  animation: fall-453 16s linear -3s infinite;
  opacity: .0433;
  transform: translate(51.7263vw, -10px) scale(.0881)
}

@keyframes fall-453 {
  78.69% {
    transform: translate(42.7399vw, 78.69vh) scale(.0881)
  }

  to {
    transform: translate(47.2331vw, 100vh) scale(.0881)
  }
}

.snow i:nth-child(454) {
  animation: fall-454 22s linear -27s infinite;
  opacity: .4177;
  transform: translate(91.3853vw, -10px) scale(.8988)
}

@keyframes fall-454 {
  72.322% {
    transform: translate(100.2523vw, 72.322vh) scale(.8988)
  }

  to {
    transform: translate(95.8188vw, 100vh) scale(.8988)
  }
}

.snow i:nth-child(455) {
  animation: fall-455 19s linear -17s infinite;
  opacity: .2079;
  transform: translate(63.8397vw, -10px) scale(.0722)
}

@keyframes fall-455 {
  51.346% {
    transform: translate(70.9007vw, 51.346vh) scale(.0722)
  }

  to {
    transform: translate(67.3702vw, 100vh) scale(.0722)
  }
}

.snow i:nth-child(456) {
  animation: fall-456 23s linear -29s infinite;
  opacity: .5754;
  transform: translate(25.1003vw, -10px) scale(.9893)
}

@keyframes fall-456 {
  77.6% {
    transform: translate(32.4994vw, 77.6vh) scale(.9893)
  }

  to {
    transform: translate(28.79985vw, 100vh) scale(.9893)
  }
}

.snow i:nth-child(457) {
  animation: fall-457 15s linear -25s infinite;
  opacity: .6313;
  transform: translate(27.4912vw, -10px) scale(.8487)
}

@keyframes fall-457 {
  32.119% {
    transform: translate(19.6652vw, 32.119vh) scale(.8487)
  }

  to {
    transform: translate(23.5782vw, 100vh) scale(.8487)
  }
}

.snow i:nth-child(458) {
  animation: fall-458 24s linear -20s infinite;
  opacity: .7666;
  transform: translate(88.4853vw, -10px) scale(.2265)
}

@keyframes fall-458 {
  63.128% {
    transform: translate(79.6989vw, 63.128vh) scale(.2265)
  }

  to {
    transform: translate(84.0921vw, 100vh) scale(.2265)
  }
}

.snow i:nth-child(459) {
  animation: fall-459 21s linear -28s infinite;
  opacity: .7683;
  transform: translate(83.8897vw, -10px) scale(.8359)
}

@keyframes fall-459 {
  44.071% {
    transform: translate(89.2129vw, 44.071vh) scale(.8359)
  }

  to {
    transform: translate(86.5513vw, 100vh) scale(.8359)
  }
}

.snow i:nth-child(460) {
  animation: fall-460 16s linear -28s infinite;
  opacity: .1479;
  transform: translate(34.43vw, -10px) scale(.6645)
}

@keyframes fall-460 {
  59.063% {
    transform: translate(27.9639vw, 59.063vh) scale(.6645)
  }

  to {
    transform: translate(31.19695vw, 100vh) scale(.6645)
  }
}

.snow i:nth-child(461) {
  animation: fall-461 10s linear -16s infinite;
  opacity: .8383;
  transform: translate(.1057vw, -10px) scale(.6971)
}

@keyframes fall-461 {
  40.343% {
    transform: translate(2.9539vw, 40.343vh) scale(.6971)
  }

  to {
    transform: translate(1.5298vw, 100vh) scale(.6971)
  }
}

.snow i:nth-child(462) {
  animation: fall-462 11s linear -11s infinite;
  opacity: .1194;
  transform: translate(91.9311vw, -10px) scale(.8752)
}

@keyframes fall-462 {
  72.468% {
    transform: translate(100.4262vw, 72.468vh) scale(.8752)
  }

  to {
    transform: translate(96.17865vw, 100vh) scale(.8752)
  }
}

.snow i:nth-child(463) {
  animation: fall-463 19s linear -3s infinite;
  opacity: .5504;
  transform: translate(64.2198vw, -10px) scale(.0565)
}

@keyframes fall-463 {
  53.734% {
    transform: translate(58.3799vw, 53.734vh) scale(.0565)
  }

  to {
    transform: translate(61.29985vw, 100vh) scale(.0565)
  }
}

.snow i:nth-child(464) {
  animation: fall-464 24s linear -10s infinite;
  opacity: .3251;
  transform: translate(72.5547vw, -10px) scale(.1325)
}

@keyframes fall-464 {
  34.515% {
    transform: translate(72.6941vw, 34.515vh) scale(.1325)
  }

  to {
    transform: translate(72.6244vw, 100vh) scale(.1325)
  }
}

.snow i:nth-child(465) {
  animation: fall-465 19s linear -28s infinite;
  opacity: .9042;
  transform: translate(17.0361vw, -10px) scale(.2304)
}

@keyframes fall-465 {
  34.778% {
    transform: translate(14.8993vw, 34.778vh) scale(.2304)
  }

  to {
    transform: translate(15.9677vw, 100vh) scale(.2304)
  }
}

.snow i:nth-child(466) {
  animation: fall-466 27s linear -15s infinite;
  opacity: .5068;
  transform: translate(83.9456vw, -10px) scale(.7142)
}

@keyframes fall-466 {
  42.033% {
    transform: translate(80.8367vw, 42.033vh) scale(.7142)
  }

  to {
    transform: translate(82.39115vw, 100vh) scale(.7142)
  }
}

.snow i:nth-child(467) {
  animation: fall-467 20s linear -1s infinite;
  opacity: .498;
  transform: translate(70.2086vw, -10px) scale(.4079)
}

@keyframes fall-467 {
  40.802% {
    transform: translate(75.1588vw, 40.802vh) scale(.4079)
  }

  to {
    transform: translate(72.6837vw, 100vh) scale(.4079)
  }
}

.snow i:nth-child(468) {
  animation: fall-468 28s linear -3s infinite;
  opacity: .0206;
  transform: translate(99.9826vw, -10px) scale(.9503)
}

@keyframes fall-468 {
  72.524% {
    transform: translate(105.3089vw, 72.524vh) scale(.9503)
  }

  to {
    transform: translate(102.64575vw, 100vh) scale(.9503)
  }
}

.snow i:nth-child(469) {
  animation: fall-469 17s linear -15s infinite;
  opacity: .8311;
  transform: translate(91.4609vw, -10px) scale(.2024)
}

@keyframes fall-469 {
  42.742% {
    transform: translate(82.9233vw, 42.742vh) scale(.2024)
  }

  to {
    transform: translate(87.1921vw, 100vh) scale(.2024)
  }
}

.snow i:nth-child(470) {
  animation: fall-470 20s linear -27s infinite;
  opacity: .4777;
  transform: translate(34.0783vw, -10px) scale(.5632)
}

@keyframes fall-470 {
  40.868% {
    transform: translate(36.5685vw, 40.868vh) scale(.5632)
  }

  to {
    transform: translate(35.3234vw, 100vh) scale(.5632)
  }
}

.snow i:nth-child(471) {
  animation: fall-471 24s linear -3s infinite;
  opacity: .6695;
  transform: translate(15.8656vw, -10px) scale(.8498)
}

@keyframes fall-471 {
  49.403% {
    transform: translate(10.1838vw, 49.403vh) scale(.8498)
  }

  to {
    transform: translate(13.0247vw, 100vh) scale(.8498)
  }
}

.snow i:nth-child(472) {
  animation: fall-472 22s linear -17s infinite;
  opacity: .9435;
  transform: translate(20.5411vw, -10px) scale(.3725)
}

@keyframes fall-472 {
  59.47% {
    transform: translate(19.6569vw, 59.47vh) scale(.3725)
  }

  to {
    transform: translate(20.099vw, 100vh) scale(.3725)
  }
}

.snow i:nth-child(473) {
  animation: fall-473 14s linear -9s infinite;
  opacity: .5987;
  transform: translate(14.6175vw, -10px) scale(.4466)
}

@keyframes fall-473 {
  65.316% {
    transform: translate(8.4275vw, 65.316vh) scale(.4466)
  }

  to {
    transform: translate(11.5225vw, 100vh) scale(.4466)
  }
}

.snow i:nth-child(474) {
  animation: fall-474 26s linear -21s infinite;
  opacity: .2871;
  transform: translate(36.1649vw, -10px) scale(.6437)
}

@keyframes fall-474 {
  62.064% {
    transform: translate(38.6145vw, 62.064vh) scale(.6437)
  }

  to {
    transform: translate(37.3897vw, 100vh) scale(.6437)
  }
}

.snow i:nth-child(475) {
  animation: fall-475 29s linear -14s infinite;
  opacity: .9548;
  transform: translate(12.875vw, -10px) scale(.6144)
}

@keyframes fall-475 {
  78.408% {
    transform: translate(12.0458vw, 78.408vh) scale(.6144)
  }

  to {
    transform: translate(12.4604vw, 100vh) scale(.6144)
  }
}

.snow i:nth-child(476) {
  animation: fall-476 26s linear -3s infinite;
  opacity: .8184;
  transform: translate(74.159vw, -10px) scale(.3266)
}

@keyframes fall-476 {
  54.076% {
    transform: translate(82.2018vw, 54.076vh) scale(.3266)
  }

  to {
    transform: translate(78.1804vw, 100vh) scale(.3266)
  }
}

.snow i:nth-child(477) {
  animation: fall-477 22s linear -18s infinite;
  opacity: .1507;
  transform: translate(55.6149vw, -10px) scale(.4747)
}

@keyframes fall-477 {
  49.893% {
    transform: translate(56.1348vw, 49.893vh) scale(.4747)
  }

  to {
    transform: translate(55.87485vw, 100vh) scale(.4747)
  }
}

.snow i:nth-child(478) {
  animation: fall-478 18s linear -18s infinite;
  opacity: .9526;
  transform: translate(11.527vw, -10px) scale(.4023)
}

@keyframes fall-478 {
  77.213% {
    transform: translate(3.3744vw, 77.213vh) scale(.4023)
  }

  to {
    transform: translate(7.4507vw, 100vh) scale(.4023)
  }
}

.snow i:nth-child(479) {
  animation: fall-479 17s linear -12s infinite;
  opacity: .9463;
  transform: translate(2.9568vw, -10px) scale(.6891)
}

@keyframes fall-479 {
  34.49% {
    transform: translate(12.8682vw, 34.49vh) scale(.6891)
  }

  to {
    transform: translate(7.9125vw, 100vh) scale(.6891)
  }
}

.snow i:nth-child(480) {
  animation: fall-480 19s linear -19s infinite;
  opacity: .7256;
  transform: translate(93.6125vw, -10px) scale(.6525)
}

@keyframes fall-480 {
  33.08% {
    transform: translate(101.8833vw, 33.08vh) scale(.6525)
  }

  to {
    transform: translate(97.7479vw, 100vh) scale(.6525)
  }
}

.snow i:nth-child(481) {
  animation: fall-481 27s linear -18s infinite;
  opacity: .0997;
  transform: translate(61.6602vw, -10px) scale(.9795)
}

@keyframes fall-481 {
  41.528% {
    transform: translate(60.0456vw, 41.528vh) scale(.9795)
  }

  to {
    transform: translate(60.8529vw, 100vh) scale(.9795)
  }
}

.snow i:nth-child(482) {
  animation: fall-482 20s linear -12s infinite;
  opacity: .5598;
  transform: translate(67.5447vw, -10px) scale(.0031)
}

@keyframes fall-482 {
  58.196% {
    transform: translate(68.0721vw, 58.196vh) scale(.0031)
  }

  to {
    transform: translate(67.8084vw, 100vh) scale(.0031)
  }
}

.snow i:nth-child(483) {
  animation: fall-483 30s linear -24s infinite;
  opacity: .0071;
  transform: translate(82.4247vw, -10px) scale(.0288)
}

@keyframes fall-483 {
  31.17% {
    transform: translate(90.4233vw, 31.17vh) scale(.0288)
  }

  to {
    transform: translate(86.424vw, 100vh) scale(.0288)
  }
}

.snow i:nth-child(484) {
  animation: fall-484 20s linear -21s infinite;
  opacity: .0145;
  transform: translate(3.3653vw, -10px) scale(.1263)
}

@keyframes fall-484 {
  55.385% {
    transform: translate(8.6048vw, 55.385vh) scale(.1263)
  }

  to {
    transform: translate(5.98505vw, 100vh) scale(.1263)
  }
}

.snow i:nth-child(485) {
  animation: fall-485 29s linear -6s infinite;
  opacity: .1638;
  transform: translate(65.6917vw, -10px) scale(.8016)
}

@keyframes fall-485 {
  67.515% {
    transform: translate(71.3053vw, 67.515vh) scale(.8016)
  }

  to {
    transform: translate(68.4985vw, 100vh) scale(.8016)
  }
}

.snow i:nth-child(486) {
  animation: fall-486 13s linear -9s infinite;
  opacity: .7412;
  transform: translate(93.2702vw, -10px) scale(.9822)
}

@keyframes fall-486 {
  62.081% {
    transform: translate(90.3971vw, 62.081vh) scale(.9822)
  }

  to {
    transform: translate(91.83365vw, 100vh) scale(.9822)
  }
}

.snow i:nth-child(487) {
  animation: fall-487 29s linear -26s infinite;
  opacity: .9127;
  transform: translate(26.2019vw, -10px) scale(.0092)
}

@keyframes fall-487 {
  50.646% {
    transform: translate(24.0497vw, 50.646vh) scale(.0092)
  }

  to {
    transform: translate(25.1258vw, 100vh) scale(.0092)
  }
}

.snow i:nth-child(488) {
  animation: fall-488 26s linear -30s infinite;
  opacity: .2745;
  transform: translate(96.0803vw, -10px) scale(.4443)
}

@keyframes fall-488 {
  71.767% {
    transform: translate(102.8088vw, 71.767vh) scale(.4443)
  }

  to {
    transform: translate(99.44455vw, 100vh) scale(.4443)
  }
}

.snow i:nth-child(489) {
  animation: fall-489 21s linear -15s infinite;
  opacity: .153;
  transform: translate(4.8818vw, -10px) scale(.5116)
}

@keyframes fall-489 {
  45.559% {
    transform: translate(11.5543vw, 45.559vh) scale(.5116)
  }

  to {
    transform: translate(8.21805vw, 100vh) scale(.5116)
  }
}

.snow i:nth-child(490) {
  animation: fall-490 14s linear -24s infinite;
  opacity: .9198;
  transform: translate(67.542vw, -10px) scale(.4983)
}

@keyframes fall-490 {
  78.788% {
    transform: translate(61.383vw, 78.788vh) scale(.4983)
  }

  to {
    transform: translate(64.4625vw, 100vh) scale(.4983)
  }
}

.snow i:nth-child(491) {
  animation: fall-491 18s linear -13s infinite;
  opacity: .7743;
  transform: translate(28.9739vw, -10px) scale(.3851)
}

@keyframes fall-491 {
  39.925% {
    transform: translate(30.0833vw, 39.925vh) scale(.3851)
  }

  to {
    transform: translate(29.5286vw, 100vh) scale(.3851)
  }
}

.snow i:nth-child(492) {
  animation: fall-492 11s linear -22s infinite;
  opacity: .3572;
  transform: translate(28.6796vw, -10px) scale(.434)
}

@keyframes fall-492 {
  50.549% {
    transform: translate(24.0712vw, 50.549vh) scale(.434)
  }

  to {
    transform: translate(26.3754vw, 100vh) scale(.434)
  }
}

.snow i:nth-child(493) {
  animation: fall-493 26s linear -24s infinite;
  opacity: .1375;
  transform: translate(24.1943vw, -10px) scale(.5407)
}

@keyframes fall-493 {
  50.659% {
    transform: translate(27.4899vw, 50.659vh) scale(.5407)
  }

  to {
    transform: translate(25.8421vw, 100vh) scale(.5407)
  }
}

.snow i:nth-child(494) {
  animation: fall-494 30s linear -1s infinite;
  opacity: .5145;
  transform: translate(12.2192vw, -10px) scale(.9748)
}

@keyframes fall-494 {
  51.999% {
    transform: translate(15.5241vw, 51.999vh) scale(.9748)
  }

  to {
    transform: translate(13.87165vw, 100vh) scale(.9748)
  }
}

.snow i:nth-child(495) {
  animation: fall-495 27s linear -20s infinite;
  opacity: .6236;
  transform: translate(77.3853vw, -10px) scale(.8742)
}

@keyframes fall-495 {
  43.364% {
    transform: translate(81.2806vw, 43.364vh) scale(.8742)
  }

  to {
    transform: translate(79.33295vw, 100vh) scale(.8742)
  }
}

.snow i:nth-child(496) {
  animation: fall-496 12s linear -6s infinite;
  opacity: .8152;
  transform: translate(41.482vw, -10px) scale(.5722)
}

@keyframes fall-496 {
  78.634% {
    transform: translate(47.8383vw, 78.634vh) scale(.5722)
  }

  to {
    transform: translate(44.66015vw, 100vh) scale(.5722)
  }
}

.snow i:nth-child(497) {
  animation: fall-497 27s linear -13s infinite;
  opacity: .0536;
  transform: translate(72.3475vw, -10px) scale(.6424)
}

@keyframes fall-497 {
  78.022% {
    transform: translate(82.3096vw, 78.022vh) scale(.6424)
  }

  to {
    transform: translate(77.32855vw, 100vh) scale(.6424)
  }
}

.snow i:nth-child(498) {
  animation: fall-498 11s linear -20s infinite;
  opacity: .4371;
  transform: translate(96.2074vw, -10px) scale(.3316)
}

@keyframes fall-498 {
  43.09% {
    transform: translate(104.1944vw, 43.09vh) scale(.3316)
  }

  to {
    transform: translate(100.2009vw, 100vh) scale(.3316)
  }
}

.snow i:nth-child(499) {
  animation: fall-499 14s linear -6s infinite;
  opacity: .8542;
  transform: translate(44.8648vw, -10px) scale(.8494)
}

@keyframes fall-499 {
  52.762% {
    transform: translate(44.3468vw, 52.762vh) scale(.8494)
  }

  to {
    transform: translate(44.6058vw, 100vh) scale(.8494)
  }
}

.snow i:nth-child(500) {
  animation: fall-500 19s linear -12s infinite;
  opacity: .0807;
  transform: translate(38.0008vw, -10px) scale(.9715)
}

@keyframes fall-500 {
  43.818% {
    transform: translate(30.49vw, 43.818vh) scale(.9715)
  }

  to {
    transform: translate(34.2454vw, 100vh) scale(.9715)
  }
}

@media screen and (max-width:767px) {
  .snow i:nth-child(n+120) {
    display: none !important
  }
}

[data-js-link] {
  cursor: pointer
}

.main-page-header {
  background-color: #ecf2f6;
  min-height: 535px;
  overflow: hidden;
  padding-bottom: 20px;
  padding-top: 85px;
  position: relative
}

.main-page-header__grid {
  align-items: flex-start;
  display: flex;
  justify-content: space-between
}

.main-page-header__title {
  flex: 1 1 100%;
  max-width: 460px
}

.main-page-header__badge {
  height: 144px;
  margin-top: 47px;
  padding-bottom: 18px;
  padding-top: 18px;
  position: relative
}

.main-page-header__badge>div {
  display: flex;
  position: relative
}

.main-page-header__badge:before {
  background-color: #44b9b1;
  background-position: -4px 8px;
  content: "";
  display: block;
  height: 144px;
  position: absolute;
  right: -165px;
  top: 0;
  width: 10000px;
  z-index: 0
}

.main-page-header__badge__image {
  margin-right: 20px
}

.main-page-header__badge__text {
  color: #fff;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin-top: 3px;
  max-width: 220px;
  position: relative;
  z-index: 1
}

.main-page-header__badge__button {
  margin-top: 10px;
  padding-left: 30px;
  padding-right: 30px
}

.main-page-header__work-info {
  display: flex;
  margin-top: 24px
}

.main-page-header__work-info div {
  color: #333638;
  font-size: 14px
}

.main-page-header__work-info div span {
  display: inline-block;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 5px
}

.main-page-header__work-info__image {
  margin-right: 26px
}

.page-header__title_with-filter {
  max-width: 740px;
  padding-bottom: 0
}

.page-header__title_with-filter .page-header__title__description {
  max-width: 100%
}

.page-header__title_with-filter .minus-list,
.page-header__title_with-filter .plus-list {
  margin-bottom: -10px
}

.page-header__title_with-filter .filter-links {
  margin-top: 40px
}

.main-page-header__button {
  border-radius: 10px;
  display: block;
  height: 28px;
  margin-bottom: 12px;
  overflow: hidden;
  width: 42px
}

.main-page-header__button i {
  align-items: center;
  border-radius: 40px 40px 40px 40px/10px 10px 10px 10px;
  box-shadow: none;
  display: flex;
  height: 28px;
  justify-content: center;
  padding: 0;
  width: 42px
}

.main-page-header__button i:before {
  border-color: transparent transparent transparent #fff;
  border-style: solid;
  border-width: 6px 0 6px 10px;
  content: "";
  display: block;
  height: 0;
  left: 1px;
  position: relative;
  width: 0;
  z-index: 1
}

.main-page-header__mobile-button {
  align-items: center;
  display: none
}

.main-page-header__mobile-button>span {
  border-bottom: 1px dashed rgba(74, 84, 86, .5);
  color: #4a5456;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  margin-left: 5px;
  transition: border .2s ease-out;
  white-space: nowrap
}

.main-page-header__mobile-button:hover>span {
  border-bottom: 1px dashed transparent;
  transition: border .2s ease-in
}

.main-page-header__play {
  box-shadow: 0 10px 36px 4px rgba(70, 81, 86, .24);
  height: 24px;
  padding-left: 7px;
  padding-right: 7px;
  position: relative;
  top: 5px;
  width: 24px
}

.main-page-header__play:before {
  background-size: 8px 8px;
  height: 8px;
  margin-left: 1px;
  width: 8px
}

.main-page-header__image {
  bottom: -1px;
  left: 51%;
  position: absolute;
  transform: translateX(-50%)
}

.main-page-header__image>.main-page-header__image__img {
  display: block;
  position: relative
}

.main-page-header__info {
  max-width: 390px;
  position: relative;
  z-index: 1
}

.main-page-header__reviews {
  margin-bottom: 50px
}

.main-page-header__reviews__title {
  align-items: center;
  color: #4a5456;
  display: flex;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 20px
}

.main-page-header__reviews__title span {
  display: block;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px
}

.main-page-header__reviews__icon {
  background-image: url(/build/i/446bc331192f875d8bca.svg);
  background-size: contain;
  height: 45px;
  margin-right: 20px;
  width: 51px
}

.main-page-header__reviews__grid {
  align-items: flex-start;
  display: flex;
  margin-left: -23px;
  margin-right: -23px
}

.main-page-header__reviews__grid>* {
  flex: 0 1 33.33%;
  padding-left: 23px;
  padding-right: 23px;
  text-align: center
}

.main-page-header__reviews__grid>*>.progressive-image-wrapper {
  border-radius: 50%;
  box-shadow: 0 4px 13px rgba(0, 0, 0, .17);
  margin-bottom: 10px
}

.main-page-header__reviews__grid>*>span {
  color: #a0abbe;
  display: block;
  font-size: 12px;
  line-height: 13.64px;
  white-space: nowrap
}

.main-page-header__quote {
  color: #4a5456;
  position: relative
}

.main-page-header__quote>div {
  margin-bottom: 22px
}

.main-page-header__quote__first {

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px
}

.main-page-header__quote__first>span {

  font-style: normal;
  font-weight: 400
}

.main-page-header__quote__second {
  color: #a0abbe;
  font-size: 14px;
  line-height: 22px
}

.main-page-header__image__button,
.main-page-header__image__metro {
  display: none
}

@media screen and (max-width:1249px) {
  .main-page-header__image {
    left: auto;
    right: 56px;
    transform: none
  }

  .main-page-header__reviews {
    display: none
  }

  .main-page-header__info {
    bottom: 15px;
    position: absolute;
    right: 90px
  }

  .main-page-header__badge:before {
    right: -378px
  }
}

@media screen and (max-width:1169px) {
  .main-page-header__badge:before {
    right: -345px
  }
}

@media screen and (max-width:1160px) {
  .page-header__title_with-filter .page-header__title__description {
    max-width: 600px
  }

  .main-page-header__badge:before {
    right: -285px
  }
}

@media screen and (max-width:1085px) {
  .main-page-header__badge:before {
    right: -215px
  }
}

@media screen and (max-width:1023px) {
  .main-page-header__image {
    left: auto;
    right: -56px
  }

  .main-page-header__info {
    display: none
  }

  .main-page-header__badge:before {
    right: -335px
  }
}

@media screen and (max-width:1000px) {
  .page-header__title_with-filter h1 {
    display: inline-block;
    max-width: 445px
  }

  .page-header__title_with-filter .page-header__title__description {
    max-width: 445px
  }
}

@media screen and (max-width:970px) {
  .main-page-header__badge:before {
    right: -260px
  }
}

@media screen and (max-width:890px) {
  .main-page-header__badge:before {
    right: -178px
  }
}

@media screen and (max-width:865px) {
  .main-page-header__badge:before {
    right: -150px
  }
}

@media screen and (max-width:795px) {
  .main-page-header__badge:before {
    right: -90px
  }
}

@media screen and (max-width:767px) {
  .page-header__title_with-filter h1 {
    max-width: 100%
  }

  .page-header__title_with-filter .page-header__title__description {
    display: flex;
    justify-content: center;
    max-width: 100%
  }

  .main-page-header {
    min-height: auto;
    padding-bottom: 100px;
    padding-top: 20px;
    position: relative
  }

  .main-page-header__grid {
    flex-wrap: wrap;
    justify-content: center
  }

  .main-page-header__grid>* {
    flex: 0 0 100%
  }

  .main-page-header__title {
    margin-bottom: 18px;
    text-align: center
  }

  .main-page-header__image {
    height: 330px;
    max-width: 400px;
    position: relative;
    right: auto;
    text-align: center
  }

  .main-page-header__image>* {
    flex: 0 1 auto
  }

  .main-page-header__image:before {
    content: none
  }

  .main-page-header__image>.main-page-header__image__img {
    bottom: -42px;
    display: inline-block;
    height: 175px;
    left: 50%;
    margin-left: -87px;
    position: absolute
  }

  .main-page-header__image>.main-page-header__image__img>img {
    height: 100%;
    width: auto
  }

  .main-page-header__image__button {
    border-radius: 30px;
    display: block;
    height: 60px;
    line-height: 62px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 290px;
    width: 100%
  }

  .main-page-header__image__metro {
    display: inline-block;
    margin-bottom: 21px;
    max-width: 290px;
    width: 100%
  }

  .main-page-header__button {
    bottom: 38px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%)
  }

  .main-page-header__button>span {
    margin-left: 12px;
    white-space: nowrap
  }

  .main-page-header__badge {
    margin-top: 22px
  }

  .main-page-header__badge>div:first-child {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 312px
  }

  .main-page-header__badge:before {
    right: -264px
  }

  .main-page-header__badge__text {
    text-align: left
  }

  .main-page-header__badge__image {
    margin-right: 0
  }

  .main-page-header__work-info {
    background-color: #00beb4;
    bottom: 0;
    box-shadow: 0 10px 36px 4px rgba(70, 81, 86, .17);
    height: 100px;
    justify-content: center;
    left: 0;
    padding: 30px 15px;
    position: absolute;
    text-align: left;
    width: 100%;
    z-index: 1
  }

  .main-page-header__work-info div {
    color: #fff;
    font-size: 12px;
    width: 198px
  }

  .main-page-header__work-info div br {
    display: none
  }

  .main-page-header__work-info .main-page-header__work-info__image {
    top: 1px
  }

  .main-page-header__mobile-button {
    display: inline-block
  }
}

.main-advantages {
  border-bottom: 1px solid #ecf2f6;
  margin-bottom: 30px;
  padding-bottom: 28px;
  padding-top: 30px
}

.main-advantages__grid {
  align-items: flex-start;
  display: flex;
  margin-left: -15px;
  margin-right: -15px
}

.main-advantages__grid>* {
  flex: 0 1 100%;
  padding-left: 15px;
  padding-right: 15px
}

.main-advantages__items {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 38px
}

.main-advantages__item {
  color: #a0abbe;
  font-size: 15px;
  line-height: 26px
}

.main-advantages__item>a,
.main-advantages__item>span {
  color: #00ada4;
  display: block;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px
}

.main-advantages__item>a:hover {
  color: #007973
}

@media screen and (max-width:1249px) {
  .main-advantages__items {
    justify-content: space-between;
    padding-left: 28px
  }

  .main-advantages__item:nth-child(4) {
    display: none
  }
}

@media screen and (max-width:1023px) {
  .main-advantages__items {
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
    padding-left: 28px
  }

  .main-advantages__item:nth-child(3) {
    display: none
  }
}

@media screen and (max-width:767px) {
  .main-advantages {
    background-color: #f2f6f8;
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 0
  }

  .main-advantages__grid {
    flex-wrap: wrap
  }

  .main-advantages__grid>* {
    flex-basis: 100%;
    max-width: 100%
  }

  .main-advantages__items {
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-bottom: 20px;
    padding-top: 35px
  }

  .main-advantages__item {
    color: #a5a5a5;
    flex-basis: 100%;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 15px;
    margin-left: 15px;
    margin-right: 0;
    padding-left: 36px;
    position: relative
  }

  .main-advantages__item>a,
  .main-advantages__item>span {
    color: #5f6877;
    font-size: 15px
  }

  .main-advantages__item:nth-child(2),
  .main-advantages__item:nth-child(3) {
    display: block
  }

  .main-advantages__item:before {
    background-image: url(/build/i/add7465a8509bad027bf.svg);
    content: "";
    height: 18px;
    left: 0;
    margin-top: -9px;
    position: absolute;
    top: 50%;
    width: 22px
  }
}

.awards-list {
  border-bottom: 1px solid #ecf2f6;
  padding-bottom: 70px;
  padding-top: 30px
}

.awards-list_border-top {
  border-bottom: 0;
  border-top: 1px solid #d9e1e1
}

.awards-list_all-borders {
  border-bottom: 1px solid #d9e1e1;
  border-top: 1px solid #d9e1e1;
  padding-bottom: 40px
}

.awards-list_no-border {
  border-bottom: 0
}

.awards-list__grid {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  margin-left: -15px;
  margin-right: -15px
}

.awards-list__grid>* {
  flex: 0 1 16.67%;
  padding-left: 15px;
  padding-right: 15px
}

.awards-list__item {
  color: #a0abbe;
  font-size: 13px;
  text-align: center
}

.awards-list__item:not(div):hover {
  color: #5f6877
}

.awards-list__item__icon {
  align-items: center;
  display: flex;
  height: 70px;
  justify-content: center;
  margin-bottom: 10px
}

.awards-list__item__icon .progressive-image-wrapper {
  border-radius: 0
}

.awards-list__item__education {
  height: 52px;
  width: 52px
}

.awards-list__slider {
  display: none
}

@media screen and (max-width:1249px) {
  .awards-list__grid>* {
    flex-basis: 20%
  }

  .awards-list__item:nth-child(5) {
    display: none
  }

  .mobile-flex-swap .awards-list {
    padding-bottom: 40px
  }

  .page_251 .mobile-flex-swap+.pusher {
    margin-top: 30px !important
  }
}

@media screen and (max-width:1023px) {
  .awards-list__grid>* {
    flex-basis: 25%
  }

  .awards-list__item:nth-child(3) {
    display: none
  }
}

@media screen and (max-width:767px) {
  .awards-list__item {
    flex-basis: 33.33%
  }

  .awards-list__item:nth-child(4) {
    display: none
  }

  .mobile-flex-swap+.pusher {
    margin-top: 40px !important
  }
}

@media screen and (max-width:700px) {
  .awards-list__grid {
    display: none
  }

  .mobile-flex-swap .awards-list {
    background-color: #fff;
    border-top: 0;
    max-width: 100%;
    padding-bottom: 0
  }
}

@media screen and (max-width:530px) {
  .awards-list {
    background-color: #f2f6f8;
    padding-bottom: 40px;
    padding-top: 0
  }

  .awards-list__grid {
    display: none
  }

  .awards-list__slider {
    display: block;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 0 !important;
    max-width: calc(100% + 30px) !important
  }
}

.mass-media-list {
  border-bottom: 1px solid #ecf2f6;
  padding-bottom: 75px
}

.mass-media-list .h2-sub {
  font-size: 18px;
  line-height: 32px;
  max-width: 770px
}

.mass-media-list .button,
.mass-media-list .slide-tab__label.active,
.mass-media-list .tabs__labels.tabs__labels_buttons .tab__label.active,
.tabs__labels.tabs__labels_buttons .mass-media-list .tab__label.active {
  border-radius: 22px;
  display: table;
  margin: 50px auto 0
}

.mml__grid {
  align-items: flex-start;
  display: flex;
  justify-content: space-around;
  margin: 60px -15px 0
}

.mml__grid>* {
  flex: 0 1 18.67%;
  padding: 0 15px
}

.mml__item {
  color: #a0abbe;
  font-size: 16px;
  line-height: 22px;
  text-align: center
}

.mml__item:not(div):hover {
  color: #5f6877
}

.mml__item-icon {
  align-items: center;
  display: flex;
  height: 70px;
  justify-content: center;
  margin-bottom: 10px
}

.mml__item-icon .progressive-image-wrapper {
  border-radius: 0
}

.mml__slider {
  display: none
}

@media screen and (max-width:1249px) {
  .mml__grid>* {
    flex-basis: 20%;
    padding: 0 10px
  }

  .mml__item {
    font-size: 13px;
    line-height: 18px
  }

  .mml__item:nth-child(5) {
    display: none
  }

  .mobile-flex-swap .mass-media-list {
    padding-bottom: 40px
  }
}

@media screen and (max-width:1023px) {
  .mml__grid>* {
    flex-basis: 25%
  }
}

@media screen and (max-width:767px) {
  .mass-media-list .h2-sub {
    font-size: 16px;
    line-height: 26px;
    margin-top: 20px
  }

  .mass-media-list .button,
  .mass-media-list .slide-tab__label.active,
  .mass-media-list .tabs__labels.tabs__labels_buttons .tab__label.active,
  .tabs__labels.tabs__labels_buttons .mass-media-list .tab__label.active {
    margin-top: 20px
  }

  .mml__grid {
    margin-top: 30px
  }

  .mml__item {
    flex-basis: 33.33%
  }

  .mml__item:nth-child(2) {
    display: none
  }

  .mml__item-icon {
    height: 50px
  }
}

@media screen and (max-width:700px) {
  .mml__grid {
    display: none
  }

  .mml__slider {
    display: block
  }

  .mml__slider .service-advantages__slider__item {
    padding-top: 0
  }

  .mml__item-icon {
    height: 70px
  }

  .mobile-flex-swap .mass-media-list {
    background-color: #fff;
    border-top: 0;
    max-width: 100%;
    padding-bottom: 0
  }
}

@media screen and (max-width:530px) {
  .mass-media-list {
    padding-bottom: 40px;
    padding-top: 0
  }

  .mass-media-list .service-advantages__item {
    margin-top: 0;
    max-width: calc(100% + 30px)
  }

  .mml__grid {
    display: none
  }

  .mml__slider {
    display: block;
    margin-left: -15px;
    margin-right: -15px
  }
}

.main-services__top {
  margin-bottom: 120px
}

.main-services__top .h2_main {
  margin-bottom: 60px;
  text-align: center
}

.main-services__top .h2_main span {
  display: inline-block;
  font-size: 46px;
  line-height: 46px
}

.main-services__grid {
  display: flex
}

.main-services__charity {
  background-color: #f4a500;
  background-image: url(/build/i/182e45b8075d2776b3ec.png);
  border-radius: 10px;
  cursor: pointer;
  margin-left: 25px;
  overflow: hidden;
  padding: 27px 25px 27px 102px;
  position: relative;
  width: 390px
}

.main-services__charity:hover .main-services__charity__title>span {
  border-bottom: 1px dashed transparent
}

.main-services__charity:before {
  background-image: url(/build/i/63b2f5a10212c1aff5ae.svg);
  background-size: contain;
  content: "";
  height: 34px;
  left: 34px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 34px
}

.main-services__charity:after {
  background-image: url(/build/i/90e78c17267a33b76420.svg);
  background-size: contain;
  content: "";
  height: 200px;
  position: absolute;
  right: -70%;
  top: -30px;
  width: 350px
}

.main-services__charity__title {
  color: #fff;

  font-size: 22px;
  font-style: normal;
  font-weight: 500
}

.main-services__charity__title>span {
  border-bottom: 1px dashed hsla(0, 0%, 100%, .5)
}

.main-services__charity__title.underline>span {
  border-bottom: 1px solid hsla(0, 0%, 100%, .5)
}

.main-services__charity__text {
  color: #f7f4ec;
  font-size: 15px;
  line-height: 22px;
  margin-top: 10px
}

.main-services__charity__text .strong {

  font-style: normal;
  font-weight: 700
}

.main-services__charity_implant {
  background-color: #3cbfb5;
  background-image: url(/build/i/8c530337f3e5858de643.png)
}

.main-services__charity_implant:before {
  background-image: url(/build/i/6af95b4c3a6f34c8a5a0.svg);
  background-size: contain;
  content: "";
  height: 34px;
  left: 34px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 34px
}

.main-services__charity_implant:after {
  display: none
}

.main-services__button {
  font-size: 16px;
  line-height: 44px !important;
  margin-top: 38px;
  padding-left: 36px;
  padding-right: 16px;
  text-transform: none
}

.main-services__button:before {
  background-image: url(/build/i/97222a7e751b4ffacc4c.svg);
  left: 14px
}

.main-services__button br {
  display: none
}

.main-services__list {
  margin-left: -12px;
  margin-right: -12px
}

.main-services__list>* {
  padding-left: 12px;
  padding-right: 12px
}

.main-services__list ul {
  list-style: none;
  padding-left: 0
}

.main-services__list ul>li {
  margin-bottom: 18px
}

.main-services__title {
  color: #333638;
  display: inline-block;

  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 25px
}

.main-services__title>span {
  border-bottom: 1px solid transparent;
  transition: border .2s ease-out
}

.main-services__title:hover>span {
  border-bottom: 1px solid #333638;
  transition: border .2s ease-in
}

.main-services__link {
  color: #999da5
}

.main-services__link>span {
  border-bottom: 1px solid hsla(220, 6%, 62%, .2);
  transition: border .2s ease-out
}

.main-services__link:hover {
  color: #5f6877
}

.main-services__link:hover>span {
  border-bottom: 1px solid transparent;
  transition: border .2s ease-in
}

.main-services__offers {
  flex: 1;
  overflow: hidden;
  position: relative
}

.main-services__offers__navigator {
  left: 0;
  margin-top: -9px;
  position: absolute;
  top: 50%;
  width: 100%
}

.main-services__offers__navigator>div {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 18px;
  position: absolute;
  width: 14px
}

.main-services__offers__navigator>div:hover svg path {
  fill: #5f6877
}

.main-services__offers__navigator>div svg {
  height: auto;
  position: relative;
  width: 100%;
  z-index: 1
}

.main-services__offers__navigator>div:before {
  background-color: #fff;
  border-radius: 50%;
  content: "";
  height: 40px;
  left: -8px;
  position: absolute;
  width: 40px
}

.main-services__offers__navigator>.prev {
  left: 0;
  transform: rotate(-180deg);
  transform-origin: center center
}

.main-services__offers__navigator>.next {
  right: 0
}

.main-services__offers__slider .offer-block {
  align-items: center;
  display: flex !important;
  justify-content: space-between;
  padding-left: 20px
}

.main-services__offers__slider .offer-block>.progressive-image-wrapper {
  height: auto !important;
  left: 0;
  margin-top: 0;
  max-width: 220px;
  min-width: 220px;
  position: relative;
  top: 0
}

.main-services__offers__slider .offer-block>* {
  flex: 1 1 100%
}

.main-services__offers__slider .offer-block>:nth-child(2) {
  float: none;
  max-width: 450px;
  min-width: 320px
}

.main-services__offers__slider .offer-block>:nth-child(3) {
  float: none;
  max-width: 190px;
  min-width: 170px
}

.main-services__offers__slider .offer-block .offer-block__button {
  width: 160px
}

.main-services__offers__slider .offer-block__title {
  font-size: 20px;
  line-height: 26px
}

.main-services__offers__slider .offer-block__price {
  font-size: 26px;
  line-height: 28px
}

@media screen and (max-width:1299px) {
  .main-services__top {
    flex-wrap: wrap;
    margin-bottom: 65px
  }

  .main-services__grid {
    flex-wrap: wrap
  }

  .main-services__offers {
    flex: 1 1 100%;
    max-width: 100%
  }

  .main-services__charity {
    align-items: center;
    background-position: 0;
    background-size: 140px auto;
    display: flex;
    margin: 25px 0 0;
    padding: 15px 25px 15px 102px;
    width: 100%
  }

  .main-services__charity:after {
    right: -250px
  }

  .main-services__charity__title {
    font-size: 20px;
    line-height: 24px;
    width: 165px
  }

  .main-services__charity__text {
    flex: 1;
    margin: 0 0 0 45px
  }

  .main-services__button {
    margin-top: 10px
  }

  .main-services__offers__slider .offer-block {
    border-radius: 10px;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px
  }
}

@media screen and (max-width:1023px) {
  .main-services__button {
    padding-left: 32px;
    padding-right: 16px
  }

  .main-services__button:before {
    left: 10px
  }

  .main-services__offers__slider .offer-block {
    margin-left: 0;
    margin-right: 0;
    padding: 30px 15px 30px 20px
  }

  .main-services__offers__slider .offer-block>.progressive-image-wrapper {
    bottom: 0;
    margin-left: 0;
    margin-top: 0
  }

  .main-services__offers__slider .offer-block>* {
    flex: 1 1 100%
  }

  .main-services__offers__slider .offer-block>:nth-child(2) {
    float: none;
    max-width: 340px;
    min-width: 230px;
    text-align: left
  }

  .main-services__offers__slider .offer-block>:nth-child(3) {
    float: none;
    max-width: 190px;
    min-width: 170px
  }

  .main-services__offers__slider .offer-block .offer-block__content {
    margin-bottom: 0
  }

  .main-services__offers__slider .offer-block .offer-block__title {
    margin-bottom: 10px
  }

  .main-services__list {
    flex-wrap: wrap
  }

  .main-services__list>* {
    flex-basis: 50%;
    margin-bottom: 40px
  }
}

@media screen and (max-width:767px) {
  .main-services__header {
    flex-wrap: wrap;
    justify-content: center
  }

  .main-services__button {
    align-items: center;
    border-radius: 40px;
    display: flex;
    height: 69px;
    justify-content: center;
    max-width: 290px;
    width: 100%
  }

  .main-services__button:before {
    content: none
  }

  .main-services__button br {
    display: block
  }

  .main-services__button>span {
    display: inline-block;
    line-height: normal;
    margin-left: -20px;
    padding-left: 29px
  }

  .main-services__button>span:before {
    background-image: url(/build/i/97222a7e751b4ffacc4c.svg);
    background-size: cover;
    content: "";
    display: block;
    height: 16px;
    left: 0;
    margin-top: -8px;
    position: absolute;
    top: 50%;
    width: 16px;
    z-index: 1
  }

  .main-services__top {
    margin-bottom: 40px
  }

  .main-services__top .h2_main {
    margin-bottom: 45px
  }

  .main-services__top .h2_main span {
    font-size: 32px;
    line-height: 36px
  }

  .main-services__offers__slider .offer-block {
    flex-wrap: wrap;
    justify-content: center
  }

  .main-services__offers__slider .offer-block>* {
    flex-basis: 100%;
    max-width: 100% !important;
    text-align: center
  }

  .main-services__offers__slider .offer-block>.progressive-image-wrapper {
    display: block;
    max-width: 150px !important;
    min-width: 105px
  }

  .main-services__offers__slider .offer-block .offer-block__content {
    margin-bottom: 15px;
    text-align: center
  }

  .main-services__offers__slider .offer-block .offer-block__title {
    font-size: 16px;
    margin-bottom: 0
  }

  .main-services__offers__slider .offer-block .offer-block__price {
    font-size: 18px
  }

  .main-services__offers__slider .offer-block .offer-block__button {
    height: 50px;
    line-height: 50px;
    width: 215px
  }

  .main-services__list {
    margin-left: -15px;
    margin-right: -15px
  }

  .main-services__list>* {
    border-bottom: 1px solid #d9e1e1;
    flex-basis: 100%;
    padding-bottom: 28px;
    text-align: center
  }

  .main-services__list>:first-child li:nth-child(3),
  .main-services__list>:nth-child(2) li:nth-child(2),
  .main-services__list>:nth-child(3) li:nth-child(3) {
    display: none
  }

  .main-services__list>:nth-child(4) {
    border-bottom: 0;
    margin-bottom: 0
  }

  .main-services__list>:nth-child(4) li:nth-child(4) {
    display: none
  }
}

@media screen and (max-width:650px) {
  .main-services__charity__text {
    display: none
  }

  .main-services__charity__title {
    width: auto
  }

  .main-services__charity {
    min-height: 78px
  }
}

@media screen and (max-width:480px) {
  .main-services__top .h2_main {
    font-size: 26px !important;
    line-height: 30px
  }

  .main-services__top .h2_main span {
    font-size: 26px;
    line-height: 30px
  }

  .main-services__charity {
    padding: 20px 25px 20px 78px
  }

  .main-services__charity:before {
    height: 28px;
    left: 28px;
    width: 28px
  }
}

.two-visits .slider__item__image-description {
  margin-left: 20px;
  margin-top: 0
}

.two-visits__title {
  margin-bottom: 67px
}

.two-visits-slider-wrapper {
  position: relative
}

.two-visits-slider-wrapper .image-slider {
  max-width: 600px
}

.two-visits-slider-wrapper .image-slider .slick-list {
  border-radius: 10px;
  overflow: hidden
}

.two-visits-slider__text__title {

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 22px
}

.two-visits-slider__text .title {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 2px
}

.two-visits-slider__text .text {
  font-size: 14px;
  line-height: 24px
}

.two-visits-slider__text .count-list>li {
  margin-bottom: 17px
}

.two-visits-slider {
  align-items: flex-start;
  margin-left: -12px;
  margin-right: -12px;
  position: relative;
  z-index: 1
}

.two-visits-slider b {

  font-style: normal;
  font-weight: 500
}

.two-visits-slider>* {
  flex-basis: calc(50% - 24px);
  margin-bottom: 73px;
  margin-left: 12px;
  margin-right: 12px;
  max-width: calc(50% - 24px)
}

.two-visits-slider>:first-child {
  margin-top: 23px
}

.two-visits-slider .slider__item__image {
  height: auto;
  width: 100%
}

.two-visits-slider .image-slider__navigator {
  top: 265px
}

.two-visits-slider .slider-wrapper-image {
  height: 350px;
  margin-left: auto;
  width: 499px
}

.two-visits-slider:not(.two-visits-slider_left) .two-visits-slider__image {
  text-align: right
}

.two-visits-slider:not(.two-visits-slider_left) .two-visits-slider__image>div {
  margin-left: auto;
  margin-right: 0
}

.two-visits-slider_left>:first-child {
  order: 1;
  padding-left: 2px
}

.two-visits-slider_left>:nth-child(2) {
  order: 0
}

.two-visits-slider_left .image-slider__navigator {
  left: auto;
  right: -61px
}

.two-visits-slider_left .slider-wrapper-image {
  margin-left: 0 !important;
  margin-right: auto
}

.two-visits-slider__image {
  margin-bottom: 15px
}

.two-visits-slider__image>div {
  max-width: 495px
}

.two-visits-slider__image .progressive-image-wrapper {
  max-width: 100%
}

@media screen and (max-width:1363px) {
  .two-visits-slider:not(.two-visits-slider_left)>* {
    margin-bottom: 15px
  }

  .two-visits-slider:not(.two-visits-slider_left)>:first-child {
    padding-right: 52px
  }

  .two-visits-slider:not(.two-visits-slider_left) .two-visits-slider__image>div {
    margin-left: auto;
    margin-right: auto
  }

  .two-visits-slider .slider-wrapper-image {
    width: 100%
  }

  .two-visits-slider_left>:first-child {
    padding-left: 54px
  }

  .two-visits-slider__image-slider {
    margin-top: 40px
  }

  .two-visits-slider-wrapper .image-slider__navigator {
    right: -53px;
    top: 230px
  }

  .two-visits__title {
    margin-bottom: 30px
  }

  .two-visits-slider__image {
    margin-top: 25px
  }

  .two-visits-slider__image>div {
    margin-left: auto;
    margin-right: auto
  }

  .two-visits-slider__image .progressive-image-wrapper {
    max-width: 394px
  }
}

@media screen and (max-width:1359px) {
  .page_251 .two-visits-slider-wrapper:last-child .two-visits-slider>* {
    margin-bottom: 0
  }
}

@media screen and (max-width:1023px) {
  .two-visits-slider .slider-wrapper-image {
    height: auto;
    margin-bottom: 0;
    margin-left: auto !important;
    margin-right: auto !important
  }

  .two-visits-slider:not(.two-visits-slider_left)>:first-child {
    order: 2
  }

  .two-visits-slider:not(.two-visits-slider_left)>:last-child {
    order: 1
  }

  .two-visits-slider>* {
    max-width: none
  }

  .two-visits-slider>:first-child {
    padding-left: 0
  }

  .two-visits-slider:not(.two-visits-slider_left)>:first-child,
  .two-visits-slider>:nth-child(2) {
    padding-right: 0
  }

  .two-visits-slider__title {
    display: block;
    text-align: center
  }

  .two-visits-slider__title br {
    display: none
  }

  .two-visits-slider__text {
    max-width: 100%
  }

  .two-visits-slider-wrapper .image-slider {
    margin-left: auto;
    margin-right: auto;
    max-width: 600px
  }

  .two-visits-slider-wrapper .slider__item__image {
    height: 415px;
    width: auto
  }

  .two-visits-slider-wrapper .image-slider__navigator {
    bottom: 0;
    left: 50%;
    margin-left: -50px;
    top: auto
  }

  .slider__item__image-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 30px
  }

  .slider__item__image-wrapper>* {
    flex: 0 0 100%
  }

  .slider__item__image-wrapper .slider__item__image {
    max-width: 100%;
    order: 2
  }

  .slider__item__image-wrapper .slider__item__image-description {
    display: none
  }

  .two-visits-slider__button {
    margin-top: 40px
  }

  .two-visits-slider__image-slider {
    margin-bottom: 15px;
    margin-top: 0
  }

  .two-visits__title {
    margin-bottom: 0
  }

  .two-visits-slider__image .progressive-image-wrapper {
    display: block;
    margin-left: auto;
    margin-right: auto
  }
}

@media screen and (max-width:767px) {

  .two-visits-slider,
  .two-visits-slider>* {
    margin-left: 0;
    margin-right: 0
  }

  .two-visits-slider>* {
    flex-basis: 100%
  }

  .two-visits-slider>:first-child {
    margin-bottom: 0
  }

  .two-visits-slider>:last-child {
    max-width: 100%
  }

  .two-visits__title {
    margin-bottom: 20px
  }

  .two-visits-slider-wrapper .image-slider {
    max-width: 100%
  }

  .two-visits-slider-wrapper .slider__item__image {
    border-radius: 0;
    height: auto;
    width: 100%
  }

  .two-visits-slider-wrapper .slider__item__image-wrapper {
    margin-top: 0
  }

  .slider-wrapper-image {
    margin-bottom: 20px
  }

  .slider-wrapper-image .slider__item__image {
    order: 1
  }

  .slider-wrapper-image .slider__item__image-description {
    display: none
  }

  .two-visits-slider-wrapper .image-slider .slick-list {
    border-radius: 0
  }

  .two-visits-slider__image-slider {
    margin-bottom: 20px !important
  }

  .two-visits-slider__image-slider .slider-wrapper-image {
    margin-left: -15px !important;
    margin-right: -15px !important;
    padding-bottom: 22px;
    width: 100vw
  }

  .two-visits-slider__image-slider .image-slider__navigator {
    height: 49px;
    right: auto;
    top: auto
  }

  .two-visits-slider__image {
    margin-top: 20px
  }

  .two-visits-slider__text__title {
    margin-bottom: 14px;
    padding-left: 15px;
    padding-right: 15px
  }

  .two-visits-slider__text {
    margin-bottom: 24px;
    margin-left: -15px;
    margin-right: -15px;
    max-width: calc(100% + 30px)
  }

  .two-visits-slider__text .count-list>li {
    margin-bottom: 0
  }

  .two-visits-slider__text .count-list>li:last-child {
    border-bottom: 1px solid #d9e1e1
  }

  .two-visits-slider__text .accordion__tab__title {
    align-items: center;
    border-top: 1px solid #d9e1e1;
    color: #00beb4;
    display: flex;
    font-size: 14px;
    height: 52px;
    line-height: 20px;
    margin-bottom: 0;
    padding-left: 15px;
    padding-right: 35px;
    position: relative
  }

  .two-visits-slider__text .accordion__tab__title:after {
    background-image: url(/build/i/af7f5fc8428807b17015.svg);
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    height: 7px;
    margin-top: -4px;
    position: absolute;
    right: 15px;
    top: 50%;
    width: 10px
  }

  .two-visits-slider__text .accordion__tab__title.active:after {
    transform: rotate(-180deg)
  }

  .two-visits-slider__text .accordion__tab__title:before {
    content: none
  }

  .two-visits-slider__text .accordion__tab__title>span:before {
    content: counter(count-list) ".";
    margin-right: 4px
  }

  .two-visits-slider__text .accordion__tab__content {
    display: none;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px
  }

  .two-visits-slider__text .accordion__tab__content.active {
    display: block
  }
}

@media screen and (max-width:450px) {
  .two-visits-slider>:first-child {
    margin-top: 0;
    padding-top: 0
  }

  .two-visits-slider>.two-visits-slider__image {
    margin-bottom: 20px;
    margin-left: -15px;
    max-width: calc(100% + 30px)
  }

  .two-visits-slider__image .progressive-image-wrapper {
    border-radius: 0;
    max-width: 100%
  }
}

.grid-image-text {
  padding-bottom: 60px
}

.grid-image-text .h2,
.grid-image-text .payment-page h2,
.payment-page .grid-image-text h2 {
  margin-bottom: 44px
}

.grid-image-text__grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px
}

.grid-image-text__grid>div {
  flex-basis: calc(25% - 30px);
  margin-left: 15px;
  margin-right: 15px
}

.grid-image-text__grid__image {
  width: 100% !important
}

.grid-image-text__title {

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-top: 26px
}

.grid-image-text__text {
  font-size: 14px;
  line-height: 22px;
  margin-top: 12px
}

.page_251 .grid-image-text .h2,
.page_251 .grid-image-text .payment-page h2,
.payment-page .page_251 .grid-image-text h2 {
  font-size: 42px;
  line-height: 48px
}

@media screen and (max-width:1359px) {
  .grid-image-text__grid>div {
    flex-basis: calc(50% - 30px);
    margin-bottom: 38px
  }

  .grid-image-text__grid__image {
    max-width: 280px
  }

  .page_251 .grid-image-text .h2,
  .page_251 .grid-image-text .payment-page h2,
  .payment-page .page_251 .grid-image-text h2 {
    font-size: 32px;
    line-height: 38px
  }

  .page_251 .grid-image-text+.pusher {
    margin-top: 0 !important
  }
}

@media screen and (max-width:767px) {
  .grid-image-text__grid>div {
    flex-basis: calc(100% - 30px);
    margin-bottom: 31px;
    text-align: center
  }

  .grid-image-text__grid__image {
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 208px !important
  }

  .grid-image-text__title {
    font-size: 16px;
    line-height: 20px
  }

  .grid-image-text__text {
    font-size: 14px
  }
}

.video-comment__h2 {
  font-size: 42px;
  margin-bottom: 30px;
  text-align: left
}

.video-comment__grid {
  align-items: flex-start;
  display: flex
}

.video-comment__grid>* {
  flex: 1 0 auto
}

.video-comment__grid>:first-child {
  margin-bottom: 15px;
  max-width: 625px
}

.video-comment__grid>:nth-child(2) {
  padding-left: 105px
}

.video-comment__grid .minus-list li,
.video-comment__grid .plus-list li {

  font-style: normal;
  font-weight: 500
}

.video-comment__grid p:not(:last-child) {
  margin-bottom: 20px
}

.video-comment__grid .icon-links_single {
  margin-top: 30px
}

.video-comment__feedback {
  color: #5f6877;

  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 28px
}

.video-comment__grid__after-list-link {

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  position: relative;
  text-transform: uppercase;
  top: 12px
}

.video-comment__image {
  height: 0;
  margin-bottom: -55px;
  margin-top: 45px;
  padding-top: 80.16%;
  position: relative;
  width: 100%
}

.video-comment__image>.progressive-image-bg {
  border-radius: 10px;
  box-shadow: 0 0 29px rgba(70, 81, 86, .15);
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0
}

.video-comment__image>.progressive-image-bg:before {
  background: linear-gradient(180deg, transparent, transparent 60%, rgba(0, 0, 0, .6));
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1
}

.video-comment__image_with-video {
  height: auto;
  margin-left: auto;
  margin-right: 0;
  max-width: 496px;
  padding-top: 0
}

.video-comment__image_with-video video {
  max-width: 100%
}

.video-comment__video {
  border-radius: 10px;
  overflow: hidden;
  position: relative
}

.video-comment__video:before {
  background: linear-gradient(180deg, transparent, transparent 60%, rgba(0, 0, 0, .6));
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1
}

.video-comment__image__desc {
  bottom: 25px;
  color: #fff;
  font-size: 13px;
  left: 0;
  line-height: 20px;
  padding: 0 30px;
  position: absolute;
  width: 100%;
  z-index: 2
}

.video-comment__image__desc .title {
  color: #fdfeff;

  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  margin-top: 13px
}

.video-comment__play {
  display: inline-block;
  height: 85px;
  left: -42px;
  margin-top: -42px;
  position: absolute;
  top: 50%;
  width: 85px;
  z-index: 3
}

.video-comment__play .circle-icon_big {
  align-items: center;
  display: flex;
  height: 85px;
  justify-content: center;
  width: 85px
}

.video-comment_compact .video-comment__h2 {
  margin-bottom: 20px
}

.video-comment_compact .video-comment__image {
  margin-top: 0
}

.video-comment_compact .video-comment__grid p {
  line-height: 26px
}

.video-comment_zygoma .video-comment__h2 {
  margin-top: 45px
}

.video-comment_zygoma .video-comment__feedback {
  max-width: 525px
}

.video-comment_zygoma .video-comment__image__desc {
  bottom: 35px
}

.video-comment_allon4 .video-comment__h2 {
  margin-bottom: 32px
}

.video-comment_allon4 .video-comment__grid .minus-list li,
.video-comment_allon4 .video-comment__grid .plus-list li {

  font-style: normal;
  font-weight: 400;
  margin-bottom: 17px
}

.video-comment_allon4 .video-comment__image {
  margin-bottom: 0
}

@media screen and (max-width:1359px) {
  .video-comment__grid>:first-child {
    margin-bottom: 38px
  }

  .video-comment__grid>:nth-child(2) {
    padding-left: 52px
  }

  .video-comment__image {
    margin-top: 20px
  }

  .video-comment__image__desc .title {
    font-size: 18px;
    margin-top: 10px
  }

  .video-comment_compact+.pusher {
    margin-top: 30px !important
  }

  .video-comment_compact .video-comment__h2 {
    font-size: 32px;
    line-height: 38px
  }

  .video-comment_compact .video-comment__image {
    margin-top: 25px
  }

  .video-comment_allon4 .video-comment__grid>* {
    flex-basis: 50%
  }

  .video-comment__image_with-video {
    max-width: 500px
  }
}

@media screen and (max-width:1199px) {
  .video-comment__grid>:first-child {
    max-width: 495px
  }

  .video-comment_compact+.pusher {
    margin-top: 0 !important
  }

  .video-comment__image_with-video {
    max-width: 366px
  }

  .video-comment__image_with-video .video-comment__image__desc {
    bottom: 20px;
    line-height: normal
  }
}

@media screen and (max-width:1023px) {
  .video-comment__h2 {
    text-align: center
  }

  .video-comment__grid {
    flex-wrap: wrap;
    justify-content: center
  }

  .video-comment__grid>* {
    flex: 0 0 100%
  }

  .video-comment__grid>:first-child {
    max-width: 100%
  }

  .video-comment__grid>:nth-child(2) {
    max-width: 495px;
    padding-left: 42px
  }

  .video-comment__grid .minus-list,
  .video-comment__grid .plus-list {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between
  }

  .video-comment__grid .minus-list>li,
  .video-comment__grid .plus-list>li {
    flex: 1 1 50%;
    padding-right: 15px
  }

  .video-comment__image {
    margin-bottom: 40px;
    margin-top: 0
  }

  .video-comment_compact .video-comment__h2 {
    margin-bottom: 25px
  }

  .video-comment_compact .video-comment__grid>:nth-child(2) {
    max-width: 395px
  }

  .video-comment_compact .video-comment__grid p {
    margin-bottom: 45px;
    margin-left: auto;
    margin-right: auto;
    max-width: 652px;
    text-align: center
  }

  .video-comment_compact .video-comment__image {
    margin-top: 0
  }

  .video-comment_allon4 .video-comment__grid {
    justify-content: center
  }

  .video-comment_allon4 .video-comment__grid>* {
    flex-basis: 100%;
    max-width: 550px
  }

  .video-comment_allon4 .video-comment__grid>:nth-child(2) {
    max-width: 400px;
    padding-left: 0
  }

  .video-comment_allon4 .video-comment__grid .minus-list,
  .video-comment_allon4 .video-comment__grid .plus-list {
    display: block
  }

  .video-comment_allon4 .video-comment__image_with-video {
    margin-left: auto;
    margin-right: auto;
    max-width: 400px
  }

  .video-comment_allon4 .video-comment__play {
    height: 60px;
    left: 50%;
    margin-left: -30px;
    margin-top: -30px;
    width: 60px
  }

  .video-comment_allon4 .video-comment__play .circle-icon_big {
    height: 60px;
    padding-left: 0;
    padding-right: 0;
    width: 60px
  }

  .video-comment_allon4 .video-comment__play .circle-icon_big:before {
    background-size: contain;
    height: 28px;
    margin-left: 0;
    width: 28px
  }

  .video-comment_allon4+.pusher {
    margin-top: 35px !important
  }
}

@media screen and (max-width:767px) {
  .video-comment__h2 {
    font-size: 26px !important;
    line-height: 36px
  }

  .video-comment__grid .minus-list>li,
  .video-comment__grid .plus-list>li {
    flex: 1 1 100%
  }

  .video-comment__image__desc .title {
    font-size: 16px;
    margin-top: 8px
  }

  .video-comment_compact .video-comment__h2 {
    line-height: 32px;
    margin-bottom: 15px
  }

  .video-comment_compact .video-comment__grid>:nth-child(2) {
    max-width: 395px
  }

  .video-comment_compact .video-comment__grid p {
    line-height: 22px;
    margin-bottom: 20px
  }

  .video-comment_compact .video-comment__image {
    margin-top: 0
  }
}

@media screen and (max-width:495px) {

  .video-comment__grid>:first-child,
  .video-comment__h2 {
    margin-bottom: 25px
  }

  .video-comment__grid>:nth-child(2) {
    flex-basis: calc(100% + 30px);
    padding-left: 0
  }

  .video-comment__image {
    margin-bottom: 0;
    overflow: hidden
  }

  .video-comment__image .progressive-image-bg {
    border-radius: 0
  }

  .video-comment__image .progressive-image-bg:before {
    background: linear-gradient(180deg, transparent, transparent 40%, rgba(0, 0, 0, .6))
  }

  .video-comment__play {
    left: auto;
    margin-top: -70px;
    right: -70px;
    top: 0
  }

  .video-comment__play,
  .video-comment__play .circle-icon_big {
    height: 140px;
    width: 140px
  }

  .video-comment__play .circle-icon_big:before {
    margin-left: -55px;
    margin-top: 55px
  }

  .video-comment__image__desc {
    bottom: 18px;
    padding: 0 15px
  }

  .video-comment_compact+.pusher {
    margin-top: 35px !important
  }

  .video-comment_allon4 .video-comment__grid>:nth-child(2) {
    flex-basis: 100%
  }

  .video-comment_allon4 .video-comment__play {
    margin-top: -30px;
    top: 50%
  }

  .video-comment_allon4 .video-comment__play .circle-icon_big:before {
    margin-left: 0;
    margin-top: 0
  }

  .video-comment_allon4 .video-comment__image__desc>:not(.title) {
    display: none
  }
}

.service-works {
  background-color: #f2f6f8;
  background-position: top -2px center;
  padding: 45px 0
}

.service-works_pad {
  padding-bottom: 0
}

.service-works__h3 {
  color: #333638;

  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 12px
}

.service-works__h3__desc {
  color: #5f6877;
  line-height: 20px
}

.service-works__grid {
  justify-content: space-between;
  margin-bottom: 45px;
  margin-top: 40px
}

.service-works__grid,
.service-works__grid__left {
  align-items: center;
  display: flex;
  position: relative
}

.service-works__grid__left {
  overflow: hidden
}

.service-works__grid__left:before {
  background: linear-gradient(90deg, rgba(242, 246, 248, 0), #f2f6f8 93%, #f2f6f8);
  content: "";
  height: 100%;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 242px;
  z-index: 1
}

.service-works__grid__right {
  min-width: 185px;
  text-align: right
}

.service-works__item {
  display: flex;
  margin-right: 20px
}

.service-works__item>div {
  cursor: pointer;
  height: 81px;
  position: relative;
  transition: opacity .2s ease-out;
  width: 121px
}

.service-works__item>div:hover {
  opacity: .8;
  transition: opacity .2s ease-in
}

.service-works__bottom {
  align-items: center;
  display: flex;
  justify-content: space-between
}

.service-works__bottom>div:first-child {
  min-width: 380px
}

.service-works__bottom>div:nth-child(2) {
  max-width: 835px;
  padding-right: 40px
}

.service-works__bottom p {
  line-height: 24px
}

.service-works__bottom__title {
  color: #5f6877;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 3px
}

@media screen and (max-width:1359px) {
  .service-works__bottom>div:nth-child(2) {
    padding-right: 0
  }

  .page_251 .service-works+.pusher {
    margin-top: 70px !important
  }
}

@media screen and (max-width:1023px) {
  .service-works__bottom>div:first-child {
    min-width: 365px
  }
}

@media screen and (max-width:767px) {
  .service-works_pad {
    padding-bottom: 20px
  }

  .service-works__h3 {
    font-size: 20px;
    text-align: center
  }

  .service-works__h3__desc {
    text-align: center
  }

  .service-works__grid {
    padding-bottom: 62px
  }

  .service-works__grid__right {
    position: absolute;
    text-align: center;
    top: 110px
  }

  .service-works__grid {
    justify-content: center
  }

  .service-works__grid__left:before {
    content: none
  }

  .service-works__grid__left>div:nth-child(3),
  .service-works__grid__left>div:nth-child(4) {
    display: none
  }

  .service-works__grid__left>div:nth-child(2) {
    margin-right: 0
  }

  .service-works__bottom {
    justify-content: center
  }

  .service-works__bottom>div:first-child {
    min-width: auto
  }

  .service-works__bottom>div:nth-child(2) {
    display: none
  }

  .page_251 .service-works+.pusher {
    margin-top: 35px !important
  }
}

@media screen and (max-width:567px) {

  .service-works__grid__left>div:nth-child(2),
  .service-works__grid__left>div:nth-child(3),
  .service-works__grid__left>div:nth-child(4) {
    display: none
  }

  .service-works__grid__left>div:first-child {
    margin-right: 0
  }
}

.info-ribbon {
  background-color: #c8f1f3;
  overflow: hidden
}

.info-ribbon__grid {
  align-items: center;
  display: flex
}

.info-ribbon__left {
  align-items: flex-start;
  display: flex;
  margin-right: 100px;
  max-width: 290px;
  min-width: 290px
}

.info-ribbon__icon {
  flex: 0 0 88px;
  height: auto;
  margin-right: 20px
}

.info-ribbon__left__title {
  color: #01b7ad;

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 24px;
  text-transform: uppercase
}

.info-ribbon__left__desc {
  color: #a0abbe;
  font-size: 18px;
  font-weight: 400;
  line-height: 24.15px
}

.info-ribbon__left__desc>span {
  color: #4a5456;
  display: block;

  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 24.15px
}

.info-ribbon__left__desc_mobile {
  display: none
}

.info-ribbon__right {
  align-items: flex-end;
  display: flex;
  flex: 1 1 auto;
  position: relative
}

.info-ribbon__right:before {
  background-image: url('../img/demo/russia-map.png');
  content: "";
  height: 216px;
  left: 0;
  position: absolute;
  top: 0;
  width: 648px;
  z-index: 0
}

.info-ribbon__contacts {
  min-width: 420px;
  padding: 40px 0 38px;
  position: relative
}

.info-ribbon__contacts__title {
  color: #333638;

  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 6px
}

.info-ribbon__contacts__desc {
  color: #5f6877;
  font-size: 20px;
  margin-bottom: 30px
}

.info-ribbon__contacts__phone-info {
  color: #5f6877;
  font-size: 14px;
  margin-bottom: 10px
}

.info-ribbon__contacts__phone {
  color: #3b4041;
  font-size: 26px
}

.info-ribbon__employee {
  flex: 0 0 auto
}

.info-ribbon__employee,
.info-ribbon__employee_bg {
  height: 216px;
  width: 254px
}

.info-ribbon__employee__awards {
  height: 61px;
  position: absolute;
  right: 0;
  top: 33px;
  width: 145px
}

.info-ribbon__employee__about {
  bottom: 35px;
  color: #a0abbe;
  font-size: 14px;
  line-height: 20px;
  position: absolute;
  right: 9px
}

.ir-implantation {
  background-color: #00beb4
}

.ir-implantation__grid {
  align-items: center
}

.ir-implantation__grid>div:first-child {
  max-width: 450px;
  padding-right: 30px
}

.ir-implantation__left {
  height: 170px;
  padding-left: 130px;
  position: relative
}

.ir-implantation__left:after {
  background-color: #fff;
  content: "";
  height: 110px;
  margin-top: -55px;
  position: absolute;
  right: 70px;
  top: 50%;
  width: 1px
}

.ir-implantation__image {
  border-radius: 0;
  bottom: 0;
  left: -25px;
  position: absolute
}

.ir-implantation__left__text {
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  margin-top: 10px
}

.ir-implantation__left__text>div {

  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 6px;
  max-width: 155px
}

.ir-implantation__right {
  align-items: center
}

.ir-implantation__right>div:first-child {
  max-width: 275px
}

.ir-implantation__right__text {
  color: #fff;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase
}

.ir-implantation__right__list {
  align-items: center;
  display: flex;
  justify-content: space-between
}

.ir-implantation__right__icon {
  background-color: #cbf4ed;
  border-radius: 15px;
  height: 80px;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 80px
}

.ir-implantation__right__icon:after {
  background-color: #fff;
  background-image: url(/build/i/add7465a8509bad027bf.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 17px 17px;
  border-radius: 50%;
  bottom: -9px;
  content: "";
  height: 28px;
  position: absolute;
  right: -9px;
  width: 28px
}

.ir-implantation__right__item:hover .ir-implantation__right__icon {
  opacity: .8
}

.ir-implantation__right__title,
.ir-implantation__right__title-mobile {
  color: #fff;
  font-size: 18px;
  line-height: 24px
}

.ir-implantation__right__title-mobile {
  display: none;
  font-size: 13px;
  line-height: 15px;
  text-align: center
}

@media screen and (max-width:1359px) {

  .info-ribbon__employee__about,
  .info-ribbon__employee__awards {
    display: none
  }

  .ir-implantation__grid>div:first-child {
    max-width: 320px
  }

  .ir-implantation__left {
    padding-left: 100px
  }

  .ir-implantation__left:after {
    right: 15px
  }

  .ir-implantation__image {
    left: -45px
  }

  .ir-implantation__right {
    justify-content: space-between
  }

  .ir-implantation__right>div {
    flex-basis: 100%
  }

  .ir-implantation__right>div:first-child {
    max-width: 175px
  }

  .ir-implantation__right__list {
    max-width: 500px
  }

  .ir-implantation__right__text {
    margin-right: 5px
  }

  .ir-implantation__right__text br {
    display: none
  }

  .page_251 .info-ribbon+.pusher {
    margin-top: 60px !important
  }
}

@media screen and (max-width:1199px) {
  .info-ribbon__contacts {
    min-width: 360px
  }

  .info-ribbon__contacts__desc {
    font-size: 18px
  }
}

@media screen and (max-width:1023px) {
  .info-ribbon__grid {
    align-items: center;
    padding-left: 30px
  }

  .info-ribbon__left {
    margin-right: 55px;
    min-width: 280px
  }

  .info-ribbon__left__content {
    padding-top: 5px
  }

  .info-ribbon__left__title {
    margin-bottom: 20px
  }

  .info-ribbon__left__desc {
    font-size: 14px;
    margin-bottom: 0
  }

  .info-ribbon__left__desc>span {

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px
  }

  .info-ribbon__employee {
    display: none
  }

  .info-ribbon__icon {
    flex-basis: 71px
  }

  .info-ribbon__contacts {
    padding-top: 45px
  }

  .info-ribbon__right:before {
    left: -80px
  }

  .ir-implantation {
    padding-top: 35px
  }

  .ir-implantation__grid {
    flex-wrap: wrap;
    justify-content: center
  }

  .ir-implantation__grid>div:first-child {
    padding-right: 0
  }

  .ir-implantation__grid>* {
    flex-basis: 100%
  }

  .ir-implantation__left {
    height: 155px;
    padding-left: 140px
  }

  .ir-implantation__left .ir-implantation__image {
    left: -15px
  }

  .ir-implantation__left:after {
    content: none
  }

  .ir-implantation__right {
    justify-content: center;
    margin-bottom: 10px;
    order: -1;
    padding-bottom: 40px;
    position: relative
  }

  .ir-implantation__right:after {
    background-color: #fff;
    bottom: 0;
    content: "";
    height: 1px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 100%
  }

  .ir-implantation__right>div:first-child {
    max-width: 100%
  }

  .ir-implantation__right__text {
    font-size: 20px;
    margin-bottom: 35px;
    text-align: center
  }

  .page_251 .info-ribbon+.pusher {
    margin-top: 45px !important
  }
}

@media screen and (max-width:767px) {
  .info-ribbon {
    padding-top: 45px
  }

  .info-ribbon__left__desc {
    display: none
  }

  .info-ribbon__left__desc_mobile {
    display: block;
    margin-bottom: 30px
  }

  .info-ribbon__grid {
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 0
  }

  .info-ribbon__left {
    align-items: center;
    flex-basis: 100%;
    justify-content: center;
    margin-right: 0;
    max-width: 100%
  }

  .info-ribbon__left__content {
    max-width: 180px
  }

  .info-ribbon__left__title {
    margin-bottom: 0
  }

  .info-ribbon__right {
    flex-wrap: wrap;
    justify-content: center
  }

  .info-ribbon__right:before {
    background-image: url(/build/i/4d464ac09174eb68f97c.png);
    bottom: 0;
    height: 116px;
    left: 50%;
    top: auto;
    transform: translateX(-50%);
    width: 320px
  }

  .info-ribbon__contacts {
    flex-basis: 100%;
    justify-content: center;
    text-align: center
  }

  .info-ribbon__contacts>* {
    text-align: center
  }

  .info-ribbon__employee {
    display: block
  }

  .info-ribbon__employee,
  .info-ribbon__employee_bg {
    height: 135px;
    width: 164px
  }

  .ir-implantation__right__list {
    flex-wrap: wrap
  }
}

@media screen and (max-width:585px) {
  .ir-implantation__right__text {
    margin-bottom: 25px;
    margin-right: 0
  }

  .ir-implantation__right {
    padding-bottom: 31px
  }

  .ir-implantation__left {
    height: 135px
  }

  .ir-implantation {
    padding-top: 36px
  }

  .ir-implantation__right__list {
    justify-content: space-around
  }

  .ir-implantation__right__icon {
    height: 62px;
    width: 62px
  }

  .ir-implantation__right__icon:after {
    background-size: 14px 12px;
    height: 22px;
    width: 22px
  }

  .ir-implantation__right__icon svg {
    max-height: 50px;
    max-width: 45px
  }

  .ir-implantation__right__title {
    display: none
  }

  .ir-implantation__right__title-mobile {
    display: block;
    font-size: 13px
  }

  .ir-implantation__left__text {
    font-size: 14px;
    line-height: 20px;
    margin-top: -16px
  }

  .ir-implantation__left__text>div {
    font-size: 20px;
    line-height: 24px
  }
}

@media screen and (max-width:414px) {
  .info-ribbon__icon {
    flex-basis: 71px
  }

  .info-ribbon__contacts {
    min-width: auto
  }

  .info-ribbon__contacts__title {
    line-height: normal
  }

  .info-ribbon__contacts__desc {
    font-size: 14px;
    margin-bottom: 18px
  }

  .info-ribbon__left__desc_mobile {
    font-size: 14px;
    margin-bottom: 28px
  }

  .info-ribbon__left__desc_mobile>span {

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px
  }

  .ir-implantation__right:after {
    width: 230px
  }
}

.anchor-links {
  border-bottom: 1px solid #d9e1e1;
  border-top: 1px solid #d9e1e1;
  padding: 30px 0
}

.anchor-links_bordered-bottom {
  border-bottom: 1px solid #d9e1e1;
  border-top: 1px solid transparent
}

.anchor-links_bordered {
  border-bottom: 1px solid #d9e1e1;
  border-top: 1px solid #d9e1e1
}

.anchor-links__grid {
  align-items: center;
  display: flex
}

.anchor-links__text {
  color: #5f6877;
  font-size: 18px;
  line-height: 20px;
  margin-right: 32px;
  min-width: 208px
}

.anchor-links__list {
  align-items: center;
  display: flex;
  justify-content: space-around;
  list-style: none;
  padding-left: 0;
  width: 100%
}

.anchor-links__list>li {
  margin-right: 80px
}

.anchor-links__list>li .link,
.anchor-links__list>li .slide-tab__label,
.anchor-links__list>li .tabs__labels.tabs__labels_buttons .tab__label,
.anchor-links__list>li [data-js=tooltip],
.anchor-links__list>li a,
.tabs__labels.tabs__labels_buttons .anchor-links__list>li .tab__label {

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  padding: 10px 0;
  text-transform: uppercase;
  white-space: nowrap
}

.anchor-links__list>li:last-child {
  margin-right: 0
}

.anchor-links__list>li .link_with-tooltip {
  padding-right: 28px;
  position: relative
}

.anchor-links__list>li .link_with-tooltip:hover {
  color: #00ada4
}

.anchor-links__list>li .link_with-tooltip [data-js=tooltip] {
  padding: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%)
}

.tooltip__text_anchors {
  max-width: 400px;
  padding-right: 20px
}

.tooltip__text_anchors p {
  margin-bottom: 20px
}

.tooltip__text_anchors p:last-child {
  margin-bottom: 0
}

.anchor-links_tall {
  padding-bottom: 50px
}

@media screen and (max-width:1359px) {
  .anchor-links__text {
    margin-right: 2px
  }

  .anchor-links__list>li {
    margin-right: 65px
  }
}

@media screen and (max-width:1024px) {
  .page_251 .anchor-links+.pusher {
    margin-top: 70px !important
  }
}

@media screen and (max-width:1023px) {
  .anchor-links__hide-mobile {
    display: none
  }

  .anchor-links__list {
    flex: 1 1 auto;
    justify-content: space-between
  }

  .anchor-links__list>li {
    margin-right: 0
  }

  .page_251 .anchor-links+.pusher {
    margin-top: 65px !important
  }
}

@media screen and (max-width:767px) {
  .anchor-links__text {
    display: none
  }

  .page_251 .anchor-links+.pusher {
    margin-top: 30px !important
  }

  .anchor-links_tall {
    padding-bottom: 30px
  }
}

@media screen and (max-width:567px) {
  .anchor-links__list>li:nth-child(4) {
    display: none
  }

  .anchor-links__list>li.visible {
    display: block !important
  }
}

@media screen and (max-width:414px) {
  .anchor-links__list>li:nth-child(2) {
    display: none
  }
}

.suitable-for_component .suitable-for__item {
  flex: 0 1 33.33%
}

.suitable-for_component .suitable-for__list {
  margin-left: -15px;
  margin-right: -15px
}

.suitable-for_component .suitable-for__image {
  height: auto;
  margin-bottom: 28px;
  max-width: 150px;
  width: auto
}

.suitable-for_component .suitable-for__desc {
  margin-left: auto;
  margin-right: auto;
  max-width: 210px
}

.suitable-for__grid {
  align-items: center;
  display: flex
}

.suitable-for__grid>div {
  flex: 1 1 auto
}

.suitable-for__grid>div:first-child {
  margin-right: 25px;
  max-width: 500px;
  min-width: 445px
}

.suitable-for__grid>div:nth-child(2) {
  margin-bottom: 20px
}

.suitable-for__h2 {
  font-size: 42px;
  margin-bottom: 40px;
  text-align: left
}

.suitable-for__h2+p {
  line-height: 28px;
  margin-bottom: 20px;
  max-width: 420px
}

.suitable-for__list {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between
}

.suitable-for__item {
  margin-bottom: 15px;
  padding-left: 12px;
  padding-right: 12px;
  text-align: center
}

.suitable-for__image {
  display: inline-block;
  height: 136px;
  position: relative;
  width: 136px
}

.suitable-for__image .progressive-image-bg {
  left: 0;
  top: 0
}

.suitable-for__image .progressive-image-wrapper {
  max-width: 100%
}

.suitable-for__image [data-js=tooltip] {
  bottom: 0;
  position: absolute;
  right: 10px
}

.suitable-for__desc {
  color: #5f6877;
  font-size: 14px
}

.suitable-for_implantation .suitable-for__h2 {
  margin-bottom: 20px
}

.suitable-for_implantation .suitable-for__link {
  display: inline-block;
  margin-top: 40px
}

.suitable-for_implantation .suitable-for__grid {
  align-items: flex-end
}

.suitable-for_implantation .suitable-for__grid>div:nth-child(2),
.suitable-for_implantation .suitable-for__item,
.suitable-for_implantation .suitable-for__list {
  margin-bottom: 0
}

.suitable-for_all-on-4 .suitable-for__h2 {
  margin-bottom: 20px
}

.suitable-for_all-on-4 .suitable-for__h2+p {
  margin-bottom: 0;
  max-width: 100%
}

.suitable-for_all-on-4 .suitable-for__grid {
  align-items: flex-start;
  margin-left: -15px;
  margin-right: -15px
}

.suitable-for_all-on-4 .suitable-for__grid>div {
  padding-left: 15px;
  padding-right: 15px
}

.suitable-for_all-on-4 .suitable-for__grid>div:first-child {
  flex-basis: 41.66%;
  margin-right: 0;
  margin-top: 20px;
  max-width: none;
  min-width: auto
}

.suitable-for_all-on-4 .suitable-for__grid>div:nth-child(2) {
  flex-basis: 58.33%;
  max-width: none;
  min-width: auto
}

.suitable-for_all-on-4 .suitable-for__list {
  align-items: flex-start;
  justify-content: center
}

.suitable-for_all-on-4 .suitable-for__list>* {
  flex-basis: 50%;
  max-width: 50%
}

.suitable-for_all-on-4 .suitable-for__item {
  margin-bottom: 30px
}

.suitable-for_all-on-4 .suitable-for__desc {
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  max-width: 215px
}

.suitable-for_all-on-4 .icon-links_single {
  margin-top: 45px
}

@media screen and (max-width:1359px) {
  .suitable-for__image {
    height: 116px;
    width: 116px
  }

  .suitable-for__desc {
    max-width: 155px
  }

  .suitable-for_component .suitable-for__list {
    align-items: flex-start
  }

  .suitable-for_all-on-4+.pusher {
    margin-top: 40px !important
  }

  .suitable-for_all-on-4 .suitable-for__h2 {
    font-size: 36px
  }

  .suitable-for_all-on-4 .suitable-for__grid {
    margin-left: -12px;
    margin-right: -12px
  }

  .suitable-for_all-on-4 .suitable-for__grid>div {
    padding-left: 12px;
    padding-right: 12px
  }

  .suitable-for_all-on-4 .suitable-for__grid>div:first-child,
  .suitable-for_all-on-4 .suitable-for__grid>div:nth-child(2) {
    flex-basis: 50%
  }
}

@media screen and (max-width:1199px) {
  .suitable-for__list {
    justify-content: center
  }

  .suitable-for__item {
    padding-left: 30px;
    padding-right: 30px
  }

  .suitable-for_component .suitable-for__item {
    flex: 0 1 50%;
    margin-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px
  }

  .suitable-for_implantation .suitable-for__grid {
    align-items: center
  }

  .suitable-for_all-on-4 .suitable-for__item {
    padding-left: 12px;
    padding-right: 12px
  }

  .suitable-for_all-on-4 .suitable-for__image {
    height: 136px;
    width: 136px
  }

  .suitable-for_all-on-4 .suitable-for__desc {
    min-width: 210px
  }

  .suitable-for_all-on-4 .suitable-for__list {
    margin-top: 0
  }

  .suitable-for_all-on-4 .suitable-for__grid {
    flex-wrap: wrap
  }

  .suitable-for_all-on-4 .suitable-for__grid>div:first-child {
    margin-right: 0;
    max-width: 800px;
    min-width: auto
  }

  .suitable-for_all-on-4 .suitable-for__grid>div:first-child p {
    margin-bottom: 15px
  }

  .suitable-for_all-on-4 .suitable-for__grid>div:nth-child(2) {
    justify-content: flex-start;
    margin-left: -30px;
    margin-right: -30px
  }

  .suitable-for_all-on-4 .suitable-for__grid>div .suitable-for__h2+p {
    max-width: 100%
  }
}

@media screen and (max-width:1050px) {
  .suitable-for__h2 {
    margin-bottom: 32px
  }

  .suitable-for__item {
    padding-left: 20px;
    padding-right: 20px
  }

  .suitable-for_all-on-4 .suitable-for__grid>div:nth-child(2) {
    margin-left: -20px;
    margin-right: -20px
  }
}

@media screen and (max-width:930px) {
  .suitable-for__grid {
    flex-wrap: wrap
  }

  .suitable-for__grid>div:first-child {
    max-width: 100%;
    min-width: auto
  }

  .suitable-for__h2+p {
    max-width: 100%
  }

  .suitable-for_component .suitable-for__list {
    align-items: flex-start
  }

  .suitable-for_component .suitable-for__item {
    flex: 0 1 33.33%
  }

  .suitable-for_implantation {
    padding-bottom: 50px;
    position: relative
  }

  .suitable-for_implantation .suitable-for__link {
    bottom: 0;
    left: 50%;
    position: absolute;
    transform: translateX(-50%)
  }

  .suitable-for_implantation .suitable-for__link>span>span {
    display: none
  }

  .suitable-for_all-on-4 .suitable-for__grid {
    justify-content: center;
    padding-top: 50px;
    position: relative
  }

  .suitable-for_all-on-4 .suitable-for__grid>div {
    flex-basis: 100%
  }

  .suitable-for_all-on-4 .suitable-for__grid>div:first-child {
    margin-top: 0;
    max-width: 100%
  }

  .suitable-for_all-on-4 .suitable-for__grid>div:nth-child(2) {
    flex-basis: 100%;
    max-width: 600px;
    order: -1
  }

  .suitable-for_all-on-4 .suitable-for__h2 {
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%
  }

  .suitable-for_all-on-4 .suitable-for__item {
    padding-left: 15px;
    padding-right: 15px
  }

  .suitable-for_all-on-4 .suitable-for__image {
    height: 116px;
    width: 116px
  }

  .suitable-for_all-on-4 .suitable-for__desc {
    min-width: 210px
  }

  .suitable-for_all-on-4 .suitable-for__list {
    margin-top: 30px
  }
}

@media screen and (max-width:767px) {
  .suitable-for__item {
    margin-bottom: 5px
  }

  .suitable-for_component .suitable-for__item {
    flex-basis: 50%;
    margin-bottom: 30px
  }

  .suitable-for_component .suitable-for__image {
    margin-bottom: 18px
  }

  .suitable-for__image {
    height: 97px;
    width: 97px
  }

  .suitable-for__h2 {
    font-size: 26px;
    margin-bottom: 25px;
    text-align: center
  }

  .suitable-for__h2+p {
    font-size: 14px;
    line-height: 22px
  }

  .suitable-for__desc {
    font-size: 13px;
    line-height: 16px
  }

  .suitable-for_implantation .suitable-for__grid>div:first-child {
    margin-right: 0
  }

  .suitable-for_implantation .suitable-for__grid>div:first-child p {
    max-width: 100%;
    text-align: center
  }

  .suitable-for_implantation .suitable-for__list {
    padding-left: 0
  }

  .suitable-for_implantation .suitable-for__item {
    margin-bottom: 30px
  }

  .suitable-for_implantation .suitable-for__desc {
    max-width: 100%
  }

  .suitable-for_all-on-4 .suitable-for__h2 {
    font-size: 26px;
    line-height: normal
  }

  .suitable-for_all-on-4+.pusher {
    margin-top: 45px !important
  }

  .suitable-for_all-on-4 .suitable-for__image {
    height: 67px;
    margin-bottom: 10px;
    width: 67px
  }

  .suitable-for_all-on-4 .suitable-for__grid {
    padding-top: 70px
  }

  .suitable-for_all-on-4 .suitable-for__grid>div .suitable-for__h2 {
    font-size: 26px !important;
    margin-bottom: 10px
  }

  .suitable-for_all-on-4 .suitable-for__grid>div:first-child {
    margin-right: 0
  }

  .suitable-for_all-on-4 .suitable-for__grid>div:first-child p {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 15px
  }

  .suitable-for_all-on-4 .suitable-for__grid>div:nth-child(2) {
    justify-content: center
  }

  .suitable-for_all-on-4 .suitable-for__grid .suitable-for__list {
    margin-top: 0;
    padding-left: 0
  }

  .suitable-for_all-on-4 .suitable-for__grid .suitable-for__list>* {
    margin-bottom: 20px
  }

  .suitable-for_all-on-4 .suitable-for__grid .icon-links_single {
    margin-top: 20px
  }

  .suitable-for_all-on-4 .suitable-for__desc {
    margin-left: auto;
    margin-right: auto;
    min-width: auto
  }
}

@media screen and (max-width:440px) {
  .suitable-for_all-on-4 .suitable-for__list>* {
    flex: 1 0 calc(50% - 30px)
  }

  .suitable-for_all-on-4 .suitable-for__image {
    margin-bottom: 0
  }

  .suitable-for__list>* {
    flex: 1 0 calc(50% - 30px)
  }

  .suitable-for__list {
    padding-left: 40px
  }

  .suitable-for_component .suitable-for__item {
    display: flex;
    flex-basis: 100%;
    margin-bottom: 20px;
    padding-left: 7px;
    padding-right: 7px
  }

  .suitable-for_component .suitable-for__image {
    height: 60px;
    width: 60px
  }

  .suitable-for_component .suitable-for__image .progressive-image-wrapper {
    height: 60px !important;
    width: 60px !important
  }

  .suitable-for_component .suitable-for__desc {
    align-items: center;
    display: flex;
    font-size: 12px;
    height: 60px;
    margin-left: 20px;
    max-width: 130px;
    text-align: left
  }
}

.clinic-approval .h2,
.clinic-approval .payment-page h2,
.payment-page .clinic-approval h2 {
  margin-bottom: 24px;
  text-align: left
}

.clinic-approval p {
  color: #5f6877;
  line-height: 30px
}

.clinic-approval__grid {
  align-items: flex-start;
  display: flex
}

.clinic-approval__grid>div {
  flex: 1 1 auto
}

.clinic-approval__grid>div:first-child {
  margin-right: 50px;
  max-width: 500px;
  min-width: 350px
}

.clinic-approval__grid>div:nth-child(2) {
  max-width: 675px
}

.clinic-approval__image {
  height: 0;
  margin-bottom: -40px;
  padding-top: 70.14%;
  position: relative;
  width: 100%
}

.clinic-approval__image .progressive-image-bg {
  border-radius: 10px;
  left: 0;
  overflow: hidden;
  top: 0
}

.clinic-approval__content {
  margin-bottom: 30px;
  margin-top: 20px;
  padding-top: 60px;
  position: relative
}

.clinic-approval__content:before {
  background-image: url(/build/i/fa2e1d86cac2476aaf1e.png);
  content: "";
  height: 45px;
  left: 0;
  position: absolute;
  top: 0;
  width: 144px
}

.clinic-approval__content p {
  margin-bottom: 20px
}

.clinic-approval__content p:last-child {
  margin-bottom: 0
}

.clinic-approval__content .button,
.clinic-approval__content .slide-tab__label.active,
.clinic-approval__content .tabs__labels.tabs__labels_buttons .tab__label.active,
.tabs__labels.tabs__labels_buttons .clinic-approval__content .tab__label.active {
  margin-top: 20px
}

.clinic-approval_component .clinic-approval__content {
  margin-bottom: 0
}

.clinic-approval_component .clinic-approval__image {
  margin-bottom: 30px
}

.clinic-approval_component .clinic-approval__image-wrapper strong {

  font-style: normal;
  font-weight: 500
}

@media screen and (max-width:1359px) {
  .clinic-approval__content {
    margin-bottom: 50px;
    margin-top: 10px
  }

  .page_251 .clinic-approval__grid>div:nth-child(2) {
    max-width: calc(50% - 50px)
  }
}

@media screen and (max-width:1023px) {
  .page_251 .clinic-approval__grid {
    align-items: center;
    flex-direction: column
  }

  .page_251 .clinic-approval__grid>div:first-child {
    margin-right: 0;
    max-width: 444px;
    width: 100%
  }

  .page_251 .clinic-approval__grid>div:nth-child(2) {
    max-width: 530px;
    text-align: center
  }

  .page_251 .clinic-approval__image {
    margin-bottom: 0
  }

  .page_251 .clinic-approval__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    padding-top: 0
  }

  .page_251 .clinic-approval__content:before {
    margin-bottom: 40px;
    margin-top: 5px;
    position: static;
    transform: translateX(0)
  }
}

@media screen and (max-width:900px) {

  .clinic-approval .h2,
  .clinic-approval .payment-page h2,
  .payment-page .clinic-approval h2 {
    text-align: center
  }

  .clinic-approval__grid {
    flex-wrap: wrap;
    justify-content: center
  }

  .clinic-approval__grid>div:first-child {
    margin-right: 0
  }

  .clinic-approval__image {
    margin-bottom: 0
  }

  .clinic-approval__content {
    margin-top: 25px;
    padding-top: 50px;
    text-align: center
  }

  .clinic-approval__content:before {
    left: 50%;
    transform: translateX(-72px)
  }

  .clinic-approval__content_mar {
    margin-bottom: 0
  }

  .clinic-approval_component .clinic-approval__content {
    margin-bottom: 0;
    text-align: left
  }

  .clinic-approval_component .button,
  .clinic-approval_component .slide-tab__label.active,
  .clinic-approval_component .tabs__labels.tabs__labels_buttons .tab__label.active,
  .tabs__labels.tabs__labels_buttons .clinic-approval_component .tab__label.active {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 236px;
    text-align: center
  }
}

@media screen and (max-width:495px) {
  .clinic-approval__grid>div {
    flex: 0 0 100%
  }

  .clinic-approval__grid>div:first-child {
    flex-basis: calc(100% + 30px)
  }

  .clinic-approval__image .progressive-image-bg {
    border-radius: 0
  }

  .clinic-approval_component .clinic-approval__image-wrapper p {
    font-size: 15px;
    line-height: 24px;
    padding: 0 30px
  }

  .clinic-approval_component .clinic-approval__image {
    margin-bottom: 20px
  }

  .page_251 .clinic-approval__grid {
    flex-direction: row
  }

  .page_251 .clinic-approval__grid>div:first-child {
    max-width: none
  }

  .page_251 .clinic-approval__content:before {
    margin-bottom: 10px;
    margin-top: 10px
  }
}

.best-certificates {
  background-color: #f2f6f8;
  background-position: top -2px center;
  padding: 87px 0 56px
}

.best-certificates__link {

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px
}

.best-certificates__grid {
  align-items: center;
  display: flex
}

.best-certificates__grid>div {
  flex: 1 1 auto
}

.best-certificates__grid>div:first-child {
  margin-right: 20px;
  max-width: 190px
}

.best-certificates__grid>div:nth-child(2) {
  align-items: center;
  display: flex;
  justify-content: space-between
}

@media screen and (max-width:1359px) {
  .best-certificates {
    padding-top: 56px
  }
}

@media screen and (max-width:1199px) {
  .best-certificates__item {
    display: none
  }

  .best-certificates__item:nth-child(-n+6) {
    display: block
  }
}

@media screen and (max-width:1023px) {
  .page_251 .best-certificates+.pusher {
    margin-top: 60px !important
  }
}

@media screen and (max-width:900px) {
  .best-certificates__item:nth-child(-n+6) {
    display: none
  }

  .best-certificates__item:nth-child(-n+5) {
    display: block
  }
}

@media screen and (max-width:800px) {

  .best-certificates__item:nth-child(-n+5),
  .best-certificates__item:nth-child(-n+6) {
    display: none
  }

  .best-certificates__item:nth-child(-n+4) {
    display: block
  }
}

@media screen and (max-width:650px) {
  .best-certificates__grid {
    flex-wrap: wrap;
    justify-content: center
  }

  .best-certificates__grid>div:first-child {
    margin-bottom: 30px;
    margin-right: 0;
    max-width: 100%;
    text-align: center
  }
}

@media screen and (max-width:490px) {
  .best-certificates__item:nth-child(3) {
    display: none
  }
}

.why-smart {
  margin-top: 140px
}

.why-smart_component {
  margin-top: 0
}

.why-smart_component .why-smart__items {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 45px
}

.why-smart_component .why-smart__items>* {
  padding-left: 15px;
  padding-right: 15px
}

.why-smart__items {
  margin-left: -30px;
  margin-right: -30px;
  margin-top: 70px
}

.why-smart__items>* {
  color: #5f6877;
  font-size: 14px;
  line-height: 26px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 90px;
  position: relative;
  text-align: center
}

.why-smart__items>* .why-smart__item__title,
.why-smart__items>*>a {
  display: block;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 25px
}

.why-smart__items>* .why-smart__item__title {
  color: #00ada4
}

.why-smart__items>:before {
  background-position: 50%;
  content: "";
  display: block;
  height: 80px;
  left: 50%;
  margin-left: -40px;
  position: absolute;
  top: 0;
  width: 80px
}

.why-smart__items>:first-child:before {
  background-image: url(/build/i/9fd5202bb3b3cab27f6f.svg)
}

.why-smart__items>:nth-child(2):before {
  background-image: url(/build/i/a54665b413a624c55b9c.svg)
}

.why-smart__items>:nth-child(3):before {
  background-image: url(/build/i/5786653c4a7cd36742cb.svg)
}

.why-smart__items>.champions:before,
.why-smart__items>:nth-child(4):before {
  background-image: url(/build/i/7fa647f30e7602e69969.svg)
}

.why-smart__items>.technology:before {
  background-image: url(/build/i/5786653c4a7cd36742cb.svg)
}

.why-smart__items>.education:before {
  background-image: url(/build/i/c1a439fe078a7343c032.svg)
}

.why-smart__items>.cooperation:before {
  background-image: url(/build/i/a54665b413a624c55b9c.svg)
}

.why-smart__items>.specialists:before {
  background-image: url(/build/i/e2ee0c63b1a5318d5429.svg)
}

.why-smart__items>.without-rent:before {
  background-image: url(/build/i/3550391b279696d219bc.svg)
}

.why-smart__items>.implant-stock:before {
  background-image: url(/build/i/aed4cc443e77e9b82b95.svg)
}

.why-smart__items>.material-warehouse:before {
  background-image: url(/build/i/311a781e52c2189815e3.svg)
}

.why-smart__items>.own-laboratory:before {
  background-image: url(/build/i/bbb450789d67e6892606.svg)
}

.why-smart__items>.hard-safety:before {
  background-image: url(/build/i/31dc3dcf03973d5f30e0.svg);
  height: 65px;
  margin-left: -56px;
  width: 116px
}

.why-smart__items>.disposable-items:before {
  background-image: url(/build/i/c5b39fbf01179bc3df21.svg);
  height: 65px;
  margin-left: -56px;
  width: 116px
}

.why-smart__items>.own-auto:before {
  background-image: url(/build/i/069da875a178ea64dbe8.svg);
  height: 65px;
  margin-left: -56px;
  width: 116px
}

.why-smart__items>.thermometry:before {
  background-image: url(/build/i/25a9f37f26af558a464c.svg);
  height: 65px;
  margin-left: -56px;
  width: 116px
}

.why-smart__items>.bucket:before {
  background-image: url(/build/i/fc1352cc9e23c35a8b66.svg);
  height: 65px;
  margin-left: -33px;
  width: 65px
}

.why-smart__items>.custom-icon:before {
  content: none
}

.why-smart__item__icon {
  height: 80px;
  left: 50%;
  margin-left: -40px;
  position: absolute;
  top: 0;
  width: 80px
}

.why-smart-certificate .why-smart__items>:first-child:before {
  background-image: url(/build/i/282e27131a698753eb1d.svg);
  background-size: 46px 50px
}

.why-smart-certificate .why-smart__items>:nth-child(2):before {
  background-image: url(/build/i/4bfc0f65a1254eb28f4f.svg);
  background-size: 50px 50px
}

.why-smart-certificate .why-smart__items>:nth-child(3):before {
  background-image: url(/build/i/3dc10b3c4928ca2aa224.svg);
  background-size: 60px 60px
}

.why-smart-certificate .why-smart__items>:nth-child(4):before {
  background-image: url(/build/i/a4167ae8e5051063b1d7.svg);
  background-size: 60px 60px
}

@media screen and (max-width:1359px) {
  .why-smart__items {
    margin-left: -15px;
    margin-right: -15px
  }

  .why-smart__items>* {
    padding-left: 15px;
    padding-right: 15px
  }

  .why-smart__items>* .why-smart__item__title,
  .why-smart__items>*>a {
    font-size: 16px;
    margin-bottom: 23px
  }
}

@media screen and (max-width:1100px) {
  .why-smart__items>* br {
    display: none
  }
}

@media screen and (max-width:1023px) {
  .why-smart {
    margin-top: 80px
  }

  .why-smart__items {
    justify-content: center;
    margin-top: 0
  }

  .why-smart__items>* {
    flex: 1 0 50%;
    margin-top: 70px;
    max-width: 400px
  }

  .why-smart_component .why-smart__items {
    margin-top: 0
  }
}

@media screen and (max-width:767px) {
  .why-smart_component {
    margin-top: 0
  }

  .why-smart__items>* {
    margin-top: 35px
  }
}

@media screen and (max-width:600px) {
  .why-smart__items>* {
    flex: 1 0 100%
  }
}

.news-smart__header {
  margin-bottom: 60px;
  text-align: center
}

.news-smart__items {
  margin-left: -17px;
  margin-right: -17px
}

.news-smart__items>* {
  flex: 1 0 33%;
  padding-left: 34px;
  padding-right: 34px
}

.news-smart__items .news-smart__item__image {
  margin-bottom: 20px;
  max-width: 345px;
  overflow: hidden;
  position: relative;
  width: 100% !important
}

.news-smart__items .news-smart__item__image:after {
  background-color: transparent;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: all .1s ease-out;
  width: 100%
}

.news-smart__items .news-smart__item__image:hover:after {
  background-color: #e59700;
  content: "";
  opacity: .1;
  transition: all .1s ease-in
}

.news-smart__items .news-smart__title {
  color: #00ada4;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 20px;
  text-transform: uppercase
}

@media screen and (max-width:1328px) {
  .news-smart__items {
    flex-wrap: wrap
  }

  .news-smart__items>* {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    padding-left: 15px;
    padding-right: 15px
  }

  .news-smart__excerpt br {
    display: none
  }
}

@media screen and (max-width:1023px) {
  .news-smart__items .news-smart__title {
    font-size: 14px;
    margin-bottom: 15px
  }
}

@media screen and (max-width:768px) {
  .news-smart__items {
    justify-content: center
  }

  .news-smart__items>* {
    flex: 1 1 100%;
    margin-bottom: 40px;
    max-width: 345px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center
  }

  .news-smart__items>:last-child {
    margin-bottom: 0
  }
}

@media screen and (max-width:360px) {
  .news-smart__items {
    margin-left: -15px;
    margin-right: -15px
  }

  .news-smart__items>* {
    padding-left: 0;
    padding-right: 0
  }

  .news-smart__items>* .progressive-image-wrapper {
    border-radius: 0
  }

  .news-smart__items .news-smart__title {
    padding-left: 15px;
    padding-right: 15px
  }
}

.affiliate-info__grid {
  align-items: flex-start;
  margin-left: -15px;
  margin-right: -15px
}

.affiliate-info__grid>* {
  padding-left: 15px;
  padding-right: 15px
}

.affiliate-info__contacts {
  max-width: 526px;
  min-width: 305px
}

.affiliate-info__contacts__title {
  color: #333638;

  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
  margin-bottom: 55px;
  text-align: left
}

.affiliate-info__contacts__item {
  background-position: top 5px left 20px;
  color: #5f6877;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 40px;
  padding-left: 104px
}

.affiliate-info__contacts__item>span {
  display: inline-block;
  line-height: 24px;
  position: relative
}

.affiliate-info__contacts__item>span.affiliate-info__metro {
  margin-bottom: 20px;
  padding-left: 28px
}

.affiliate-info__contacts__item>div {
  color: #a5a5a5;
  margin-bottom: 10px
}

.affiliate-info__contacts__item .link,
.affiliate-info__contacts__item .slide-tab__label,
.affiliate-info__contacts__item .tabs__labels.tabs__labels_buttons .tab__label,
.affiliate-info__contacts__item [data-js=tooltip],
.tabs__labels.tabs__labels_buttons .affiliate-info__contacts__item .tab__label {

  font-style: normal;
  font-weight: 500
}

.affiliate-info__contacts__item_address {
  background-image: url(/build/i/efc2a03a41f1ae7c66b6.svg)
}

.affiliate-info__contacts__item_phone {
  background-image: url(/build/i/08b6ad553ed774cb96e0.svg)
}

.affiliate-info__contacts__item_phone>a {
  color: #5f6877;
  font-size: 20px;
  line-height: 24px
}

.affiliate-info__contacts__item_worktime {
  background-image: url(/build/i/a472eed3b4116eb43d59.svg)
}

.affiliate-info__contacts__item_email {
  background-image: url(/build/i/617dc96f8b49c7f05693.svg)
}

.affiliate-info__metro__icon {
  left: 0;
  position: absolute;
  top: 1px
}

.affiliate-info__contacts__link {

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: .42px;
  margin-top: 20px;
  text-transform: uppercase
}

.affiliate-info__contacts__link:before {
  background-image: url(/build/i/006e15827201be15e93b.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  content: "";
  display: block;
  height: 20px;
  margin-bottom: 4px;
  margin-right: 10px;
  width: 20px
}

.affiliate-info__map>div {
  border-radius: 10px;
  height: 600px;
  overflow: hidden;
  width: 100%
}

@media screen and (max-width:1200px) {
  .affiliate-info__map>div {
    height: 500px
  }
}

@media screen and (max-width:1023px) {
  .affiliate-info__grid>* {
    flex: 1 0 100%;
    max-width: none;
    width: 100%
  }

  .affiliate-info__contacts {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap
  }

  .affiliate-info__contacts>* {
    flex: 1 1 50%
  }

  .affiliate-info__contacts>:first-child {
    flex: 1 1 100%
  }

  .affiliate-info__contacts__title {
    margin-bottom: 40px;
    text-align: center
  }
}

@media screen and (max-width:900px) {
  .affiliate-info__contacts__item {
    background-position: top 5px left 15px;
    background-size: 30px 30px;
    font-size: 15px;
    padding-left: 60px
  }

  .affiliate-info__contacts__item>span.affiliate-info__metro {
    margin-bottom: 10px
  }

  .affiliate-info__contacts__item .link,
  .affiliate-info__contacts__item .slide-tab__label,
  .affiliate-info__contacts__item .tabs__labels.tabs__labels_buttons .tab__label,
  .affiliate-info__contacts__item [data-js=tooltip],
  .tabs__labels.tabs__labels_buttons .affiliate-info__contacts__item .tab__label {
    margin-top: 10px
  }

  .affiliate-info__contacts__item>div {
    margin-bottom: 6px
  }
}

@media screen and (max-width:767px) {
  .affiliate-info__contacts>* {
    flex: 1 1 100%
  }

  .affiliate-info__map>div {
    height: 350px
  }
}

.virtual-tour__grid {
  align-items: center;
  margin-left: -15px;
  margin-right: -15px
}

.virtual-tour__grid>* {
  padding-left: 15px;
  padding-right: 15px
}

.virtual-tour__grid>:first-child {
  max-width: 526px
}

.virtual-tour__grid>:last-child {
  padding-left: 100px
}

.virtual-tour__banner {
  border-radius: 10px;
  display: block;
  height: 286px;
  overflow: hidden;
  position: relative
}

.virtual-tour__banner:after {
  background-image: url(/build/i/0ea9503d59485bb78907.svg);
  content: "";
  height: 120px;
  left: 50%;
  margin-left: -60px;
  margin-top: -60px;
  position: absolute;
  top: 50%;
  width: 120px;
  z-index: 2
}

.virtual-tour__banner:before {
  background-color: #000;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity .3s ease;
  width: 100%;
  z-index: 1
}

.virtual-tour__banner:hover:before {
  opacity: .2
}

.virtual-tour__banner__bg {
  left: 0;
  top: 0
}

.virtual-tour__title {

  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
  margin-bottom: 30px;
  text-align: left
}

.virtual-tour__text {
  line-height: 28px;
  margin-bottom: 40px
}

@media screen and (max-width:1200px) {
  .virtual-tour__grid>:last-child {
    padding-left: 40px
  }
}

@media screen and (max-width:900px) {
  .virtual-tour__grid {
    justify-content: center;
    text-align: center
  }

  .virtual-tour__grid>* {
    flex: 1 0 100%
  }

  .virtual-tour__grid>:last-child {
    padding-left: 15px
  }

  .virtual-tour__title {
    text-align: center
  }

  .virtual-tour__banner {
    margin-bottom: 50px
  }
}

@media screen and (max-width:767px) {
  .virtual-tour__title {
    line-height: 35px
  }
}

.text-block {
  margin-bottom: 60px
}

.text-block h3 {
  margin-bottom: 12px
}

.text-block__inner {
  margin: 0 auto;
  max-width: 890px
}

.text-block__inner .h2_secondary {
  line-height: 48px;
  margin-bottom: 50px
}

.text-block__inner p {
  color: #5f6877;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 25px
}

.text-block__inner .minus-list,
.text-block__inner .plus-list {
  margin-bottom: 50px;
  margin-left: 30px;
  margin-top: 50px
}

.text-block__inner .minus-list li .text,
.text-block__inner .plus-list li .text {
  max-width: 100%
}

.text-block__inner .progressive-image-wrapper {
  margin: 0 -167px;
  max-width: 1224px
}

.text-block__inner .progressive-image-wrapper+* {
  margin-top: 25px
}

.page_service .text-block__inner .h2_secondary,
.page_service_section .text-block__inner .h2_secondary {

  font-size: 50px;
  font-style: normal;
  font-weight: 400
}

@media screen and (max-width:1345px) {
  .text-block__inner .progressive-image-wrapper {
    margin-left: calc(50% - 50vw + 56px);
    max-width: calc(100vw - 112px)
  }
}

@media screen and (max-width:1024px) {

  .page_service .text-block__inner .h2_secondary,
  .page_service_section .text-block__inner .h2_secondary {
    font-size: 44px
  }
}

@media screen and (max-width:1023px) {
  .text-block__inner .progressive-image-wrapper {
    margin-left: calc(50% - 50vw + 26px);
    max-width: calc(100vw - 52px)
  }
}

@media screen and (max-width:767px) {
  .text-block {
    margin-bottom: 50px
  }

  .payment-page .text-block__inner h2,
  .text-block__inner .h2,
  .text-block__inner .payment-page h2 {
    line-height: 32px;
    margin-bottom: 35px
  }

  .text-block__inner .minus-list,
  .text-block__inner .plus-list {
    margin-left: 0
  }

  .text-block__inner .progressive-image-wrapper {
    margin-left: calc(50% - 50vw + 15px);
    max-width: calc(100vw - 30px)
  }

  .page_service .text-block__inner .h2_secondary,
  .page_service_section .text-block__inner .h2_secondary {
    margin-bottom: 30px
  }
}

.article__employee {
  align-items: center;
  display: flex
}

.article__employee .article__employee__photo {
  background-color: #f4f7f8;
  border-radius: 50%;
  display: block;
  height: 80px;
  margin-right: 24px;
  overflow: hidden;
  position: relative;
  width: 80px
}

.article__employee .article__employee__photo>div {
  left: 0;
  top: 0
}

.article__employee .author {
  color: #a5a5a5;
  font-size: 14px;
  line-height: 28px
}

.article__employee .about {
  color: #989ca6;
  font-size: 16px;
  line-height: 22px
}

.article_thank-for-attention {
  color: #5f6877;

  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 40px
}

@media screen and (max-width:767px) {
  .article__employee {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 40px;
    text-align: center
  }

  .article__employee .article__employee__photo {
    margin-bottom: 20px;
    margin-right: 0
  }

  .article__employee>:last-child {
    flex: 1 0 100%
  }

  .article_thank-for-attention {
    text-align: center
  }
}

.services-grid-wrapper {
  margin-bottom: 30px;
  margin-top: 30px
}

.services-grid-wrapper h1 {
  margin-bottom: 40px
}

.services-grid {
  flex-wrap: wrap
}

.services-grid>* {
  border-radius: 10px;
  display: block;
  height: 228px;
  max-width: 33.33%;
  overflow: hidden;
  padding: 55px 70px 55px 100px;
  transition: box-shadow .2s ease-out
}

.services-grid>:hover {
  box-shadow: 0 10px 29px rgba(70, 81, 86, .18);
  transition: box-shadow .2s ease-in
}

.services-grid>:hover .service-grid__icon {
  transform: scale(3.8);
  transition: transform .2s ease-in
}

.services-grid>:hover .service-grid__icon path {
  fill: #eef3f6;
  transition: fill .2s ease-in
}

.services-grid>:hover .service-grid__title>span:after {
  background-color: #67cabf;
  transition: background-color .2s ease-in, width .2s ease-in;
  width: 100%
}

.service-grid__title {
  color: #333638;

  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 30px;
  position: relative
}

.service-grid__title>span {
  position: relative;
  white-space: nowrap
}

.service-grid__title>span:after {
  background-color: transparent;
  border-radius: 2px;
  bottom: -2px;
  content: "";
  height: 3px;
  left: 0;
  position: absolute;
  transition: background-color .2s ease-out, width .2s ease-out;
  width: 0
}

.service-grid__icon {
  height: auto;
  left: 50px;
  position: absolute;
  top: 54px;
  transform-origin: top right;
  transition: transform .2s ease-out;
  width: 30px
}

.service-grid__icon path {
  fill: #48bfb2;
  transition: fill .2s ease-out
}

.service-grid__protez .service-grid__icon,
.service-grid__vinirs .service-grid__icon {
  top: 55px
}

.service-grid__desc {
  color: #989ca6;
  font-size: 14px;
  line-height: 28px
}

@media screen and (max-width:1300px) {
  .services-grid>* {
    padding-left: 70px
  }

  .services-grid>:hover .service-grid__icon {
    transform: scale(2.5)
  }

  .service-grid__icon {
    left: 20px
  }
}

@media screen and (max-width:1199px) {
  .services-grid>* {
    flex-basis: 50%;
    max-width: 50%;
    padding-left: 100px
  }

  .services-grid>:hover .service-grid__icon {
    transform: scale(3.8)
  }

  .service-grid__icon {
    left: 50px
  }
}

@media screen and (max-width:900px) {
  .services-grid>* {
    padding-left: 70px
  }

  .services-grid>:hover .service-grid__icon {
    transform: scale(2.5)
  }

  .service-grid__icon {
    left: 20px
  }

  .service-grid__title {
    font-size: 20px;
    margin-bottom: 20px
  }
}

@media screen and (max-width:767px) {
  .services-grid {
    justify-content: center
  }

  .services-grid>* {
    flex-basis: 100%;
    max-width: 500px;
    padding-left: 100px
  }

  .services-grid>:hover .service-grid__icon {
    transform: scale(3.8)
  }

  .service-grid__icon {
    left: 50px
  }
}

@media screen and (max-width:440px) {
  .services-grid>* {
    border-bottom: 1px solid #d9e1e1;
    border-radius: 0;
    height: auto;
    margin-left: -15px;
    margin-right: -15px;
    padding: 80px 15px 30px
  }

  .services-grid>:first-child {
    border-top: 1px solid #d9e1e1
  }

  .services-grid>:hover {
    box-shadow: none
  }

  .services-grid>:hover .service-grid__icon {
    transform: scale(1.15)
  }

  .services-grid>:hover .service-grid__icon path {
    fill: #48bfb2
  }

  .services-grid>:last-child {
    border-bottom: 0
  }

  .service-grid__icon {
    left: 15px;
    top: 36px;
    transform-origin: top left
  }

  .service-grid__protez .service-grid__icon,
  .service-grid__vinirs .service-grid__icon {
    top: 37px
  }

  .service-grid__title {
    font-size: 18px;
    margin-bottom: 15px
  }
}

.offers-slider .slick-list {
  padding-top: 100px
}

.offers-slider .offer {
  display: none
}

.offers-slider .offer:first-child {
  display: block !important
}

.offers-slider__navigator-wrapper {
  margin-top: 20px
}

@media screen and (max-width:1023px) {
  .offers-slider .slick-list {
    padding-top: 0
  }
}

@media screen and (max-width:767px) {
  .offers-slider .slick-list {
    margin-left: -15px;
    margin-right: -15px
  }
}

.main-slider-wrapper {
  overflow: hidden;
  padding-bottom: 180px;
  padding-top: 160px
}

.main-slider-wrapper .h1 {
  display: inline-block;
  font-size: 56px;
  line-height: 72px;
  margin-bottom: 30px;
  max-width: 645px
}

.main-slider-wrapper__header {
  margin-bottom: 200px
}

.main-slider-wrapper__desc {
  color: #5f6877;
  font-size: 30px;
  line-height: 80px
}

.main-slider-wrapper__grid {
  transform: translateX(320px)
}

.main-slider {
  opacity: 0
}

.main-slider.initialized {
  opacity: 1;
  transition: opacity .3s ease-in
}

.main-slider .slick-initialized .slick-slide {
  display: inline
}

.main-slider .slick-list {
  overflow: visible
}

.main-slider .slick-slide {
  display: inline
}

.main-slider .slick-slide:not(.slick-current) {
  cursor: pointer
}

.main-slider .slick-slide.slick-current .main-slider__item {
  transform: translateY(-250px);
  transition: transform .3s ease-in
}

.main-slider .slick-slide.slick-current .main-slider__item .main-slider__item__photo {
  transform: scale(3.25);
  transform-origin: bottom center;
  transition: transform .3s ease-in
}

.main-slider .slick-slide.slick-current .main-slider__item .main-slider__item__photo .progressive-image-bg {
  box-shadow: 0 15px 35px rgba(0, 0, 0, .15);
  transition: box-shadow .2s ease-in .1s
}

.main-slider .slick-slide.slick-current .main-slider__item__play {
  transform: scale(.3077);
  z-index: 2
}

.main-slider .slick-slide.slick-current .main-slider__item__play,
.main-slider .slick-slide.slick-current .main-slider__item__text {
  opacity: 1;
  transition: opacity .3s ease-in .3s, visibility .3s ease-in .3s;
  visibility: visible
}

.main-slider .slick-slide.dummy-current .main-slider__item {
  transform: translateY(-250px);
  transition: transform .3s ease-in
}

.main-slider .slick-slide.dummy-current .main-slider__item .main-slider__item__photo {
  transform: scale(3.25);
  transform-origin: bottom center;
  transition: transform .3s ease-in
}

.main-slider .slick-slide.dummy-current .main-slider__item .main-slider__item__photo .progressive-image-bg {
  box-shadow: 0 15px 35px rgba(0, 0, 0, .15);
  transition: box-shadow .2s ease-in .1s
}

.main-slider .slick-slide.dummy-current .main-slider__item__play {
  transform: scale(.3077)
}

.main-slider .slick-slide.dummy-current .main-slider__item__play,
.main-slider .slick-slide.dummy-current .main-slider__item__text {
  opacity: 1;
  transition: opacity .3s ease-in .3s, visibility .3s ease-in .3s;
  visibility: visible
}

.main-slider .slick-slide.dummy-current.flash-current .main-slider__item {
  transform: translateY(0)
}

.main-slider .slick-slide.dummy-current.flash-current .main-slider__item__photo {
  transform: scale(1)
}

.main-slider .slick-slide.dummy-current.flash-current .main-slider__item__photo .progressive-image-bg {
  box-shadow: none
}

.main-slider .slick-slide.dummy-current.flash-current .main-slider__item__play,
.main-slider .slick-slide.dummy-current.flash-current .main-slider__item__text {
  opacity: 0;
  visibility: hidden
}

.main-slider__item {
  height: 130px;
  margin-left: 55px;
  margin-right: 55px;
  max-width: 130px;
  min-width: 130px;
  width: 130px
}

.main-slider__item,
.main-slider__item__photo {
  position: relative;
  transition: transform .3s ease-out
}

.main-slider__item__photo {
  height: 100%;
  margin-bottom: 20px;
  width: 100%
}

.main-slider__item__photo .progressive-image-bg {
  border-radius: 50%;
  left: 0;
  -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
  overflow: hidden;
  top: 0
}

.main-slider__item__play {
  height: 100px;
  left: -50px;
  margin-top: -50px;
  opacity: 0;
  position: absolute;
  top: 50%;
  visibility: hidden;
  width: 100px
}

.main-slider__item__play .circle-icon_big {
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: center;
  width: 100px
}

.main-slider__item__play .circle-icon_big:before {
  background-size: 32px 32px;
  height: 32px;
  margin-left: 0;
  width: 32px
}

.main-slider__item__text {
  color: #5f6877;
  font-size: 16px;
  left: 50%;
  line-height: 26px;
  margin-left: -100px;
  opacity: 0;
  position: absolute;
  text-align: center;
  visibility: hidden;
  width: 200px
}

.main-slider__navigator-wrapper {
  position: relative
}

.main-slider__navigator-wrapper .video-slider__navigator {
  left: 50%;
  position: absolute;
  top: -80px;
  transform: translateX(-50%)
}

.Ñv-wrapper {
  height: 130%;
  left: -15%;
  position: absolute;
  top: -15%;
  width: 130%
}

.slick-slide:not(.slick-current) .main-slider__item__photo:hover .Ñv-half-clip {
  animation: cv-clip-rotate .4s 1;
  transform: rotate(180deg)
}

.slick-slide:not(.slick-current) .main-slider__item__photo:hover .cv-half-circle {
  border-left-color: #eaeef1;
  border-top-color: #eaeef1
}

.slick-slide:not(.slick-current) .main-slider__item__photo:hover .cv-half-circle.clipped {
  animation: cv-rotate .2s linear 2;
  transform: rotate(135deg)
}

.slick-slide:not(.slick-current) .main-slider__item__photo:hover .cv-half-circle.fixed {
  animation: cv-show-fixed .4s 1;
  border-bottom-color: #eaeef1;
  border-right-color: #eaeef1;
  opacity: 1
}

.Ñv-half-clip {
  overflow: hidden;
  transform-origin: left center;
  width: 50%
}

.cv-half-circle,
.Ñv-half-clip {
  height: 100%;
  position: absolute;
  right: 0
}

.cv-half-circle {
  border: 10px solid transparent;
  border-radius: 50%
}

.cv-half-circle.clipped {
  transform: rotate(-45deg);
  width: 200%
}

.cv-half-circle.fixed {
  opacity: 0;
  transform: rotate(135deg);
  width: 100%
}

@keyframes cv-clip-rotate {
  0% {
    transform: rotate(0deg)
  }

  50% {
    transform: rotate(0deg)
  }

  50.01% {
    transform: rotate(180deg)
  }

  to {
    transform: rotate(180deg)
  }
}

@keyframes cv-rotate {
  0% {
    transform: rotate(-45deg)
  }

  to {
    transform: rotate(135deg)
  }
}

@keyframes cv-show-fixed {
  0% {
    border-bottom-color: transparent;
    border-right-color: transparent;
    opacity: 0
  }

  49.99% {
    border-bottom-color: transparent;
    border-right-color: transparent;
    opacity: 0
  }

  50% {
    border-bottom-color: transparent;
    border-right-color: transparent;
    opacity: 1
  }

  to {
    border-bottom-color: #eaeef1;
    border-right-color: #eaeef1;
    opacity: 1
  }
}

@media screen and (max-width:1359px) {
  .main-slider .slick-slide .main-slider__item {
    margin-left: 40px;
    margin-right: 40px
  }

  .main-slider .slick-slide.slick-current .main-slider__item {
    transform: translateY(-260px)
  }

  .main-slider .slick-slide.slick-current .main-slider__item .main-slider__item__photo {
    transform: scale(2.85)
  }

  .main-slider .slick-slide.dummy-current .main-slider__item {
    transform: translateY(-260px)
  }

  .main-slider .slick-slide.dummy-current .main-slider__item .main-slider__item__photo {
    transform: scale(2.85)
  }

  .main-slider-wrapper .h1 {
    font-size: 44px;
    line-height: 58px;
    max-width: 495px
  }
}

@media screen and (max-width:1199px) {
  .main-slider-wrapper__header {
    margin-bottom: 140px
  }

  .main-slider-wrapper__grid {
    transform: translateX(250px)
  }

  .main-slider .slick-slide.slick-current .main-slider__item {
    transform: translateY(-220px)
  }

  .main-slider .slick-slide.slick-current .main-slider__item .main-slider__item__photo {
    transform: scale(2.55)
  }

  .main-slider .slick-slide.dummy-current .main-slider__item {
    transform: translateY(-220px)
  }

  .main-slider .slick-slide.dummy-current .main-slider__item .main-slider__item__photo {
    transform: scale(2.55)
  }
}

@media screen and (max-width:1023px) {
  .main-slider-wrapper {
    padding-top: 100px
  }

  .main-slider-wrapper__header {
    text-align: center
  }

  .main-slider-wrapper__header .h1 {
    max-width: none
  }

  .main-slider-wrapper__grid {
    transform: translateX(0)
  }

  .main-slider .slick-slide.slick-current .main-slider__item {
    transform: translateY(0)
  }

  .main-slider .slick-slide.slick-current .main-slider__item .main-slider__item__photo {
    transform: scale(1.5)
  }

  .main-slider .slick-slide.slick-current .main-slider__item__play {
    transform: scale(.5)
  }

  .main-slider .slick-slide.dummy-current .main-slider__item {
    transform: translateY(0)
  }

  .main-slider .slick-slide.dummy-current .main-slider__item .main-slider__item__photo {
    transform: scale(1.5)
  }

  .main-slider .slick-slide.dummy-current .main-slider__item__play {
    transform: scale(.5)
  }

  .main-slider__item__photo {
    margin-bottom: 35px
  }

  .main-slider__item__play {
    left: 50%;
    margin-left: -50px;
    margin-top: 0
  }

  .main-slider__navigator-wrapper .video-slider__navigator {
    left: 50%;
    position: absolute;
    top: 100px;
    transform: translateX(-50%)
  }
}

@media screen and (max-width:767px) {
  .main-slider-wrapper {
    padding-bottom: 230px;
    padding-top: 80px
  }

  .main-slider-wrapper .h1 {
    font-size: 30px;
    line-height: 35px
  }

  .main-slider-wrapper__desc {
    font-size: 16px;
    line-height: 36px
  }

  .main-slider-wrapper__header {
    margin-bottom: 100px
  }

  .main-slider .slick-slide .main-slider__item {
    height: 80px;
    margin-left: 30px;
    margin-right: 30px;
    max-width: 80px;
    min-width: 80px
  }

  .main-slider .slick-slide.slick-current .main-slider__item {
    transform: translateY(0)
  }

  .main-slider .slick-slide.slick-current .main-slider__item .main-slider__item__photo {
    transform: scale(1.6)
  }

  .main-slider .slick-slide.slick-current .main-slider__item__play {
    transform: scale(.35);
    transition: opacity .2s ease .2s, visibility .2s ease .2s
  }

  .main-slider .slick-slide.slick-current .main-slider__item__text {
    transition: opacity .2s ease .2s, visibility .2s ease .2s
  }

  .main-slider .slick-slide.dummy-current .main-slider__item {
    transform: translateY(0)
  }

  .main-slider .slick-slide.dummy-current .main-slider__item .main-slider__item__photo {
    transform: scale(1.6)
  }

  .main-slider .slick-slide.dummy-current .main-slider__item__play {
    transform: scale(.35);
    transition: opacity .2s ease .2s, visibility .2s ease .2s
  }

  .main-slider .slick-slide.dummy-current .main-slider__item__text {
    transition: opacity .2s ease .2s, visibility .2s ease .2s
  }

  .main-slider__item__play {
    margin-top: -15px
  }
}

@media screen and (max-width:414px) {
  .main-slider-wrapper {
    padding-top: 50px
  }
}

.prices-wrapper {
  margin-bottom: 50px;
  margin-top: 80px
}

.prices-block {
  margin-bottom: 0;
  margin-left: -50px;
  margin-right: -50px;
  padding: 50px;
  position: relative
}

.prices-block:before {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 11px 79px rgba(70, 81, 86, .14);
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity .1s ease-out;
  width: 100%;
  z-index: -1
}

.prices-block:hover:before {
  opacity: 1;
  transition: opacity .1s ease-in
}

.prices-block-inner {
  display: flex
}

.prices-block-inner>:first-child {
  float: left;
  margin-right: 50px;
  width: 290px
}

.prices-block-inner>:last-child {
  float: right;
  padding: 40px 0;
  width: 72.2%
}

.prices-block__image-wrapper {
  align-items: flex-end;
  background-color: #d1d2d7;
  border-radius: 10px;
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 290px
}

.prices-block__image-wrapper:after {
  background-image: url(/build/i/f5807770af4d0848ba6d.svg);
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 270px;
  left: 10px;
  opacity: .3;
  position: absolute;
  top: -50px;
  width: 270px;
  z-index: 2
}

.prices-block__image__icon {
  height: auto;
  left: 50%;
  margin-left: -30px;
  position: absolute;
  top: 55px;
  width: 60px;
  z-index: 2
}

.prices-block__image__icon circle,
.prices-block__image__icon path {
  fill: #fff
}

.prices-block__image .progressive-image-wrapper {
  display: block
}

.prices-block__title {
  color: #333638;

  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 40px
}

.prices-block__bottom {
  align-items: center
}

.prices-block__bottom>* {
  flex: 0 1 auto
}

.prices-block__bottom .button,
.prices-block__bottom .slide-tab__label.active,
.prices-block__bottom .tabs__labels.tabs__labels_buttons .tab__label.active,
.tabs__labels.tabs__labels_buttons .prices-block__bottom .tab__label.active {
  margin-right: 30px
}

.prices-block__bottom .link,
.prices-block__bottom .slide-tab__label,
.prices-block__bottom .tabs__labels.tabs__labels_buttons .tab__label,
.prices-block__bottom [data-js=tooltip],
.tabs__labels.tabs__labels_buttons .prices-block__bottom .tab__label {
  font-size: 14px
}

.prices-slider,
.prices-slider-wrapper {
  position: relative
}

.prices-slider {
  margin-bottom: 50px;
  padding: 50px 25px
}

.prices-slider:after,
.prices-slider:before {
  background-color: #d8dfe1;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

.prices-slider:after {
  bottom: 0;
  top: auto
}

.prices-slider__item__title {
  color: #5f6877;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 35px;
  text-transform: uppercase
}

.prices-slider__item__content {
  align-items: flex-start
}

.prices-slider__item__content>:first-child {
  flex: 1 1 240px;
  margin-right: 25px;
  max-width: 240px
}

.prices-slider__item__content p {
  color: #898989;
  font-size: 14px;
  line-height: 22px
}

.prices-slider__item__content .minus-list_small:first-child,
.prices-slider__item__content .plus-list_small:first-child {
  padding-right: 20px
}

.prices-slider__item__oldprice {
  color: #a5a5a5;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 10px
}

.prices-slider__item__oldprice>span {
  color: #a5a5a5;
  font-size: 18px;
  line-height: 28px;
  margin-right: 5px;
  text-decoration: line-through
}

.prices-slider__item__price {
  color: #3c4143;
  font-size: 30px;
  line-height: 28px;
  margin-bottom: 15px
}

.prices-slider__item__price__free {
  font-size: 22px
}

.prices-slider__item__price_steps {
  color: #00ada4
}

.prices-slider__item__price_steps .work-info__price__tooltip {
  position: relative;
  top: 1px
}

.prices-slider__item__offer {
  color: #a5a5a5;
  font-size: 14px;
  line-height: 20px
}

.prices-slider__item__list-title {
  color: #898989;
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 12px;
  margin-top: 3px
}

.prices-slider__item__list .minus-list>span,
.prices-slider__item__list .plus-list>span {
  display: inline-block;
  margin-bottom: 5px
}

.prices-slider__item__list .minus-list>li,
.prices-slider__item__list .plus-list>li {
  padding-left: 20px
}

.prices-slider__navigator {
  background-color: #fff;
  padding-left: 45px;
  position: absolute;
  right: 0;
  top: -20px
}

.prices-slider__navigator>div {
  align-items: center
}

.prices-slider__navigator>div>* {
  flex: 0 1 auto
}

.prices-slider__navigator__button {
  background-color: #e8e8e8;
  border-radius: 50%;
  height: 40px;
  transition: background-color .2s ease-out;
  width: 40px;
  z-index: 1
}

.prices-slider__navigator__button svg {
  height: 14px;
  transition: fill .2s ease-in;
  width: 14px
}

.prices-slider__navigator__button:hover {
  transition: background-color .2s ease-in
}

.prices-slider__navigator__button:hover svg path {
  fill: #959ba5;
  transition: fill .2s ease-in
}

.prices-slider__navigator__button.prev {
  transform: rotateY(180deg)
}

.prices-slider__navigator__counter {
  color: #898989;
  line-height: 28px;
  margin-left: 25px;
  margin-right: 25px
}

.prices-table {
  margin-bottom: 50px
}

.prices-table__row {
  align-items: center;
  border-top: 1px solid #d8dfe1;
  color: #5f6877;
  justify-content: space-between;
  line-height: 28px;
  padding: 25px 0 25px 25px
}

.prices-table__row>:first-child {
  flex: 1 1 70%;
  font-size: 16px
}

.prices-table__row>:last-child {
  flex: 0 0 25%;
  font-size: 20px;
  text-align: right
}

.prices-table__row:last-child {
  border-bottom: 1px solid #d8dfe1
}

@media screen and (max-width:1359px) {
  .prices-block-inner {
    justify-content: space-between
  }

  .prices-block-inner:after {
    content: none
  }

  .prices-block-inner>:last-child {
    width: 65%
  }

  .prices-slider__item__list>* {
    flex: 1 0 100%
  }
}

@media screen and (max-width:1023px) {
  .prices-block__title {
    font-size: 30px
  }

  .prices-block-inner>:first-child {
    display: none
  }

  .prices-block-inner>:last-child {
    padding: 0 0 10px;
    width: 100%
  }

  .prices-slider__item__list>* {
    flex: 1 0 50%
  }

  .prices-slider__item__content>:first-child {
    max-width: 230px
  }
}

@media screen and (max-width:900px) {
  .prices-slider__item__list>* {
    flex: 1 0 100%
  }

  .prices-slider__item__list>* br {
    display: none
  }
}

@media screen and (max-width:767px) {

  .payment-page .prices>.row h2,
  .payment-page .prices>.row_short h2,
  .prices>.row .h2,
  .prices>.row .payment-page h2,
  .prices>.row_short .h2,
  .prices>.row_short .payment-page h2 {
    display: none
  }

  .prices-wrapper {
    margin-top: 60px
  }

  .prices-block {
    border-top: 1px solid #d8dfe1;
    margin-bottom: 0;
    padding-bottom: 0;
    text-align: center
  }

  .prices-block:before {
    content: none
  }

  .prices-block__title {
    font-size: 20px
  }

  .prices-slider__item__content {
    justify-content: center
  }

  .prices-slider__item__content>:first-child {
    margin-right: 0
  }

  .prices-slider__item__content>:last-child {
    display: none
  }

  .prices-slider__item__title {
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 25px
  }

  .prices-slider-wrapper {
    background-color: #eef2f4;
    margin-left: -15px;
    margin-right: -15px;
    padding-bottom: 50px
  }

  .prices-slider {
    padding: 35px 15px
  }

  .prices-slider:after,
  .prices-slider:before {
    content: none
  }

  .prices-slider__navigator {
    background-color: transparent;
    bottom: 70px;
    left: 50%;
    padding-left: 0;
    right: auto;
    top: auto;
    transform: translateX(-50%);
    width: 100%
  }

  .prices-block__bottom,
  .prices-slider__navigator>div {
    justify-content: center
  }

  .prices-block__bottom {
    margin-top: -25px
  }

  .prices-block__bottom>:first-child {
    margin-bottom: 40px;
    margin-right: 0
  }

  .prices-block__bottom>:last-child {
    flex: 1 0 100%
  }

  .prices-slider__item__oldprice,
  .prices-slider__item__oldprice>span {
    font-size: 14px
  }

  .prices-slider__item__price {
    font-size: 20px
  }

  .prices-table {
    margin-bottom: 20px;
    padding-top: 20px
  }

  .prices-table__row {
    flex-wrap: wrap;
    padding: 15px
  }

  .prices-table__row>:first-child {
    flex: 1 0 100%;
    font-size: 14px;
    margin-bottom: 5px
  }

  .prices-table__row>:last-child {
    flex: 1 0 100%;
    font-size: 16px;
    text-align: center
  }

  .prices-table__row:first-child {
    border-top: 0
  }

  .prices-table__row:last-child {
    border-bottom: 0
  }

  .prices-slider__item__price_steps .work-info__price__tooltip {
    height: 18px;
    margin-left: 8px;
    top: 2px;
    width: 14px
  }
}

.page-header_search {
  margin-bottom: 65px;
  min-height: 425px
}

.page-header_search .page-header__title {
  padding-bottom: 45px
}

.page-header_search .page-header__title__description {
  margin-top: 36px
}

.page-header_search .header-search__form {
  position: relative;
  z-index: 1
}

.page-header_search .header-search__input {
  border-radius: 24px;
  opacity: 1;
  padding-left: 25px;
  position: relative;
  top: 0
}

.page-header_search .header-search__submit {
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: center;
  margin-top: -15px;
  position: absolute;
  right: 12px;
  top: 50%;
  width: 30px
}

.page-header_search .header-search__submit svg {
  height: 16px;
  width: 16px
}

.page-header_search .header-search__submit:hover path {
  fill: #3c93bd
}

.search-wrapper .search {
  margin: 0 auto;
  max-width: 800px
}

.search-wrapper .search__query,
.search-wrapper .search__results__item {
  margin-bottom: 90px
}

.search-wrapper .search__results__item__title {
  color: #5f6877;

  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 20px;
  text-transform: uppercase
}

.search-wrapper .search__results__item__content {
  align-items: center;
  justify-content: space-between
}

.search-wrapper .search__results__item__content>* {
  flex: 1 1 auto;
  width: 74%
}

.search-wrapper .search__results__item__content>:first-child {
  margin-right: 30px
}

.search-wrapper .search__results__item__content>:last-child {
  flex: 0 0 auto;
  max-width: 142px
}

.search-wrapper .search__results__item__description {
  color: #989ca6;
  font-size: 14px;
  line-height: 26px
}

.search-wrapper .search__results__item__button {
  text-align: center
}

@media screen and (max-width:1359px) {
  .page-header_search {
    padding-bottom: 110px
  }
}

@media screen and (max-width:767px) {
  .page-header_search {
    margin-bottom: 60px;
    padding-bottom: 70px;
    position: relative
  }

  .page-header_search .header-search__form {
    order: 2
  }

  .page-header_search .page-header__bg {
    bottom: 0;
    top: auto
  }

  .search-wrapper .grid-wrap search__results__item,
  .search-wrapper .search__query {
    margin-bottom: 60px
  }

  .search-wrapper .search__results__item__title {
    font-size: 14px
  }

  .search-wrapper .search__results__item__content>* {
    width: 100%
  }

  .search-wrapper .search__results__item__content>:first-child {
    margin-right: 0
  }

  .search-wrapper .search__results__item__description {
    margin-bottom: 20px
  }
}

.lead-write .h2,
.lead-write .payment-page h2,
.payment-page .lead-write h2 {
  margin-bottom: 45px;
  text-align: left
}

.lead-write b {

  font-style: normal;
  font-weight: 500
}

.lead-write__grid {
  margin-left: -12px;
  margin-right: -12px
}

.lead-write__grid>* {
  flex: 1 1 50%;
  max-width: 50%;
  padding-left: 12px;
  padding-right: 12px
}

.lead-write__grid>:first-child {
  padding-right: 40px
}

.lead-write__grid>:last-child {
  margin-top: -20px
}

.lead-write__grid p {
  color: #5f6877;
  line-height: 30px;
  margin-bottom: 25px
}

.lead-write__grid .contact-us__form {
  margin-top: 0
}

.contact-us__form__lead-write__captcha {
  height: 85px;
  position: relative
}

.contact-us__form__lead-write__captcha .g-recaptcha {
  left: -30px;
  position: absolute;
  transform: scale(.8)
}

.contact-us__form__lead-write__captcha .g-recaptcha .form__error {
  bottom: -15px
}

@media screen and (max-width:1199px) {
  .lead-write__grid>:first-child {
    max-width: 45%;
    padding-right: 12px
  }

  .lead-write__grid>:last-child {
    max-width: 55%
  }

  .lead-write__grid .contact-us__form__left-input,
  .lead-write__grid .contact-us__form__right-input {
    width: 225px
  }
}

@media screen and (max-width:1023px) {
  .lead-write__grid>* {
    flex: 1 0 100%
  }

  .lead-write__grid>:first-child {
    margin-bottom: 40px;
    max-width: 100%;
    padding-right: 12px
  }

  .lead-write__grid>:last-child {
    max-width: 100%
  }

  .lead-write__grid .contact-us__form__left-input,
  .lead-write__grid .contact-us__form__right-input {
    width: 48%
  }
}

@media screen and (max-width:767px) {
  .lead-write__grid>:first-child {
    margin-bottom: 30px
  }

  .lead-write__grid .contact-us__form__left-input,
  .lead-write__grid .contact-us__form__right-input {
    width: 100%
  }
}

.error-404 {
  padding-top: 110px;
  text-align: center
}

.error-404__icon {
  margin: 0 auto 60px;
  max-width: 520px
}

.error-404__icon svg {
  -webkit-mask: url(#mask1);
  mask: url(#mask1);
  -webkit-mask-size: cover;
  mask-size: cover
}

.error-404__icon path {
  fill: #e2e7e9
}

.error-404__title {
  margin-bottom: 25px;
  max-width: 100%
}

.error-404__desc {
  color: #a5a5a5;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 45px
}

.service-advantages {
  background-color: #f2f6f8;
  padding: 31px 0
}

.service-advantages__grid {
  align-items: center;
  justify-content: space-between
}

.service-advantages__grid>* {
  flex: 0 1 auto
}

.service-advantages__item {
  max-width: 240px
}

.service-advantages__item:last-child {
  max-width: 420px
}

.service-advantages__item_champions svg {
  margin-right: 15px !important;
  position: relative;
  top: -4px
}

.service-advantages__advantage {
  color: #a5a5a5;
  font-size: 15px;
  line-height: 22px;
  padding-left: 36px;
  position: relative
}

.service-advantages__advantage .title {
  color: #5f6877;

  font-style: normal;
  font-weight: 500
}

.service-advantages__advantage:before {
  background-image: url(/build/i/add7465a8509bad027bf.svg);
  content: "";
  height: 18px;
  left: 0;
  margin-top: -9px;
  position: absolute;
  top: 50%;
  width: 22px
}

.service-advantages_implantation {
  padding: 17px 0
}

.service-advantages_implantation .service-advantages__advantage {
  align-items: center;
  display: flex
}

.service-advantages_implantation .service-advantages__advantage:before {
  content: none
}

.service-advantages_implantation .service-advantages__item {
  position: relative
}

.service-advantages_implantation .service-advantages__item:after {
  background-color: #8b97ab;
  content: "";
  height: 110px;
  margin-top: -55px;
  opacity: .3;
  position: absolute;
  right: 0;
  top: 50%;
  width: 1px
}

.service-advantages_implantation .service-advantages__item:first-child {
  max-width: 380px;
  width: 380px
}

.service-advantages_implantation .service-advantages__item:nth-child(2) {
  max-width: 315px;
  width: 315px
}

.service-advantages_implantation .service-advantages__item:last-child:after {
  content: none
}

.service-advantages_implantation .service-advantages__slider {
  background-color: transparent
}

.service-advantages__advantage_implant {
  padding-left: 20px
}

.service-advantages__advantage_implant .service-advantages__advantage__image {
  margin-right: 34px;
  min-width: 72px;
  transform: translateY(15px)
}

.service-advantages__advantage_implant .service-advantages__advantage__title {
  color: #5f6877;

  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.56px;
  text-transform: uppercase
}

.service-advantages__advantage_nobel {
  padding-left: 0
}

.service-advantages__advantage_nobel .service-advantages__advantage__image {
  margin-right: 26px;
  min-width: 113px
}

.service-advantages__advantage_nobel .service-advantages__advantage__title {
  color: #5f6877;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px
}

.service-advantages_iconed .service-advantages__item {
  align-items: center;
  display: flex
}

.service-advantages_iconed .service-advantages__item_second .service-advantages__icon {
  min-width: 115px
}

.service-advantages_iconed .service-advantages__item_third .service-advantages__icon {
  margin-right: 15px;
  min-width: 53px
}

.service-advantages_iconed .service-advantages__icon {
  margin-right: 12px;
  min-width: 50px
}

.service-advantages_iconed .service-advantages__advantage {
  padding-left: 0
}

.service-advantages_iconed .service-advantages__advantage:before {
  content: none
}

.service-advantages_iconed .service-advantages__advantage .link,
.service-advantages_iconed .service-advantages__advantage .slide-tab__label,
.service-advantages_iconed .service-advantages__advantage .tabs__labels.tabs__labels_buttons .tab__label,
.service-advantages_iconed .service-advantages__advantage [data-js=tooltip],
.tabs__labels.tabs__labels_buttons .service-advantages_iconed .service-advantages__advantage .tab__label {
  color: #a5a5a5;
  display: inline-block;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase
}

.service-advantages_iconed .service-advantages__advantage .link>span,
.service-advantages_iconed .service-advantages__advantage .slide-tab__label>span,
.service-advantages_iconed .service-advantages__advantage .slider-cost-of__slide__prices>[data-js=tooltip]>span,
.service-advantages_iconed .service-advantages__advantage .tabs__labels.tabs__labels_buttons .tab__label>span,
.service-advantages_iconed .service-advantages__advantage [data-js=tooltip]>span,
.tabs__labels.tabs__labels_buttons .service-advantages_iconed .service-advantages__advantage .tab__label>span {
  color: #00ada4;

  font-style: normal;
  font-weight: 500
}

.service-advantages__slider-wrapper {
  position: relative
}

.service-advantages__slider {
  background-color: #fff;
  border-radius: 42px;
  height: 85px;
  overflow: hidden;
  padding-left: 30px;
  padding-right: 60px;
  width: 420px
}

.service-advantages__slider__item {
  color: #8b97ab;
  display: inline-block;
  font-size: 15px;
  line-height: 20.18px;
  min-height: 54px;
  padding: 21px 0
}

.service-advantages__slider__item span {
  color: #00beb4;
  display: inline-block;

  font-style: normal;
  font-weight: 500
}

.service-advantages__slider__item_first {
  background-image: url(/build/i/a6424b7bb69fe6f1ef8c.png);
  background-position: 0;
  background-size: 54px 54px;
  padding-left: 75px
}

.service-advantages__slider__item_forbes {
  background-image: url(/build/i/be422b9ca77c0cbc9e4e.png);
  background-position: 0;
  background-size: 90px 54px;
  padding-left: 113px
}

.service-advantages__slider__item_rbk {
  background-image: url(/build/i/cbd5489f3e8c836150b2.svg);
  background-position: 0;
  background-size: 90px 26px;
  padding-left: 75px
}

.service-advantages__slider__item_best {
  background-image: url(/build/i/461f6f6db6b6b1ff8186.png);
  background-position: 0;
  background-size: 54px 54px;
  padding-left: 75px
}

.service-advantages__slider__item_champions {
  background-image: url(/build/i/eec0f265fe819e7c2b6f.png);
  background-position: 0;
  background-size: 54px 54px;
  padding-left: 75px
}

.service-advantages__slider__item_prodoktorov {
  background-image: url(/build/i/9d4020259540288a9a38.png);
  background-position: 0;
  background-size: 172px 35px;
  padding-left: 113px
}

.service-advantages__slider__item_startsmile {
  background-image: url(/build/i/4dff64931b0da14e2740.png);
  background-position: 0;
  background-size: 174px 32px;
  padding-left: 113px
}

.service-advantages__slider__item_nobel {
  background-image: url(/build/i/18b583fe12c3411f43a9.svg);
  background-position: 0;
  background-size: 112px 58px;
  padding-left: 113px
}

.service-advantages__slider__navigator {
  margin-top: -13px;
  position: absolute;
  right: 33px;
  top: 50%
}

.service-advantages__slider__navigator .next,
.service-advantages__slider__navigator .prev {
  cursor: pointer;
  height: 10px;
  width: 10px
}

.service-advantages__slider__navigator .next svg,
.service-advantages__slider__navigator .prev svg {
  max-width: 10px;
  position: absolute
}

.service-advantages__slider__navigator .next svg path,
.service-advantages__slider__navigator .prev svg path {
  fill: #a5a5a5
}

.service-advantages__slider__navigator .prev {
  margin-bottom: 6px;
  transform: rotate(-90deg)
}

.service-advantages__slider__navigator .next {
  transform: rotate(90deg)
}

@media screen and (max-width:1359px) {
  .service-advantages__item:nth-child(2) {
    display: none
  }

  .service-advantages_iconed .service-advantages__grid {
    flex-wrap: nowrap
  }

  .service-advantages_iconed .service-advantages__item:nth-child(2) {
    display: flex
  }

  .service-advantages_iconed .service-advantages__item.service-advantages__item_third {
    display: none
  }

  .service-advantages_implantation .service-advantages__item:first-child {
    max-width: 50%;
    width: 50%
  }

  .service-advantages__advantage_implant {
    padding-left: 0
  }
}

@media screen and (max-width:1023px) {

  .service-advantages__item:nth-child(3),
  .service-advantages_iconed .service-advantages__item:nth-child(2) {
    display: none
  }

  .service-advantages__advantage_implant .service-advantages__advantage__title {
    font-size: 20px
  }

  .service-advantages_implantation .service-advantages__item:first-child {
    max-width: 300px
  }

  .service-advantages_implantation .service-advantages__item:nth-child(3) {
    display: block;
    max-width: 410px
  }

  .service-advantages_implantation .service-advantages__item:after {
    content: none
  }

  .service-advantages_implantation .service-advantages__advantage__image {
    margin-right: 10px
  }
}

@media screen and (max-width:700px) {
  .service-advantages {
    padding: 35px 0 40px
  }

  .service-advantages__grid {
    justify-content: center;
    margin-left: -15px;
    margin-right: -15px
  }

  .service-advantages__grid>* {
    flex-basis: 100%;
    margin-left: 15px;
    margin-right: 15px
  }

  .service-advantages__grid>:last-child {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%
  }

  .service-advantages__advantage {
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    max-width: 240px
  }

  .service-advantages__item {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%
  }

  .service-advantages__item:nth-child(2),
  .service-advantages__item:nth-child(3) {
    display: block
  }

  .service-advantages__slider-wrapper {
    margin-top: 25px
  }

  .service-advantages__slider {
    border-radius: 0;
    height: auto;
    padding: 27px 30px;
    width: 100%
  }

  .service-advantages__slider__item {
    background-position: top !important;
    padding-bottom: 0;
    padding-left: 0 !important;
    padding-top: 73px;
    text-align: center
  }

  .service-advantages__slider__item.service-advantages__slider__item_rbk {
    background-position: center 15px !important
  }

  .service-advantages__slider__item.service-advantages__slider__item_prodoktorov,
  .service-advantages__slider__item.service-advantages__slider__item_startsmile {
    background-position: center 20px !important
  }

  .service-advantages__slider__item.service-advantages__slider__item_nobel {
    background-position: center 5px !important
  }

  .service-advantages_iconed .service-advantages__grid {
    flex-wrap: wrap
  }

  .service-advantages_iconed .service-advantages__item {
    justify-content: center
  }

  .service-advantages_iconed .service-advantages__item:nth-child(2) {
    display: flex;
    margin-top: 25px
  }

  .service-advantages_iconed .service-advantages__item:last-child {
    margin-top: 38px
  }

  .service-advantages_iconed .service-advantages__advantage {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    max-width: 150px
  }

  .service-advantages__slider__navigator {
    left: 0;
    margin-top: 0;
    right: auto;
    width: 100%
  }

  .service-advantages__slider__navigator .next,
  .service-advantages__slider__navigator .prev {
    height: 20px;
    position: absolute;
    top: -10px;
    width: 20px
  }

  .service-advantages__slider__navigator .next svg,
  .service-advantages__slider__navigator .prev svg {
    left: 5px;
    top: 5px
  }

  .service-advantages__slider__navigator .prev {
    left: 15px;
    margin-bottom: 0;
    transform: rotate(-180deg)
  }

  .service-advantages__slider__navigator .next {
    right: 15px;
    transform: rotate(0)
  }

  .service-advantages_implantation {
    background-color: #fff;
    padding-bottom: 0;
    padding-top: 0
  }

  .service-advantages_implantation .service-advantages__item:first-child {
    background-color: #f2f6f8;
    margin-left: -15px;
    margin-right: -15px;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%
  }

  .service-advantages_implantation .service-advantages__item:nth-child(2) {
    display: none
  }

  .service-advantages_implantation .service-advantages__item:nth-child(3) {
    margin-bottom: 25px;
    max-width: 410px;
    order: -1;
    width: 100%
  }

  .service-advantages_implantation .service-advantages__advantage {
    max-width: 100%
  }

  .service-advantages_implantation .service-advantages__advantage__image {
    margin-right: 8px
  }

  .service-advantages_implantation .service-advantages__advantage_implant {
    margin-left: auto;
    margin-right: auto;
    max-width: 300px
  }
}

@media screen and (max-width:414px) {
  .service-advantages {
    background-color: #fff;
    padding: 15px 0 20px
  }

  .service-advantages_iconed .service-advantages__item:first-child,
  .service-advantages_iconed .service-advantages__item:nth-child(2),
  .service-advantages_iconed .service-advantages__item:nth-child(3) {
    display: none
  }

  .service-advantages_iconed .service-advantages__item:last-child {
    margin-top: 0
  }
}

.work-about {
  margin-bottom: 95px
}

.work-about__grid {
  align-items: flex-start;
  margin-left: -10px;
  margin-right: -10px
}

.work-about__grid>* {
  padding-left: 10px;
  padding-right: 10px
}

.work-about__grid>:first-child {
  flex-basis: 67%
}

.work-about__grid>:first-child>div {
  align-items: flex-start;
  margin-left: -10px;
  margin-right: -10px
}

.work-about__grid>:first-child>div>* {
  padding-left: 10px;
  padding-right: 10px
}

.work-about__grid>:last-child {
  flex-basis: 33%
}

.work-about__image {
  border-radius: 10px;
  height: 0;
  -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
  overflow: hidden;
  padding-top: 66.67%;
  position: relative;
  width: 100%
}

.work-about__image .progressive-image-wrapper {
  left: 0;
  position: absolute;
  top: 0
}

.work-about__image .progressive-image {
  height: 100%;
  width: auto
}

.work-about__image:before {
  background-color: #ff3b3b;
  border-radius: 50%;
  bottom: -58px;
  content: "";
  height: 116px;
  left: -58px;
  position: absolute;
  width: 116px;
  z-index: 1
}

.work-about__image:after {
  background-image: url(./img/icon/denide-circle-white.svg);
  bottom: 10px;
  content: "";
  height: 30px;
  left: 10px;
  position: absolute;
  width: 30px;
  z-index: 2
}

.work-about__images_after .work-about__image:before {
  background-color: #20bc95
}

.work-about__images_after .work-about__image:after {
  background-image: url(./img/icon/denide-circle-white.svg)
}

.work-about__tomography {
  align-items: center;
  display: flex;
  margin-top: 15px
}

.work-about__tomography>i {
  border-radius: 5px;
  display: block;
  height: 40px;
  margin-right: 20px;
  opacity: .3;
  overflow: hidden;
  position: relative;
  transition: opacity .2s ease-out;
  width: 60px
}

.work-about__tomography>i .progressive-image-wrapper {
  left: 0;
  top: 0
}

.work-about__tomography>div {
  color: #00beb4;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-transform: uppercase
}

.work-about__tomography>div.mobile {
  display: none
}

.work-about__tomography>div>span {
  border-bottom: 1px dashed #00beb4;
  transition: border .2s ease-out
}

.work-about__tomography:hover>i {
  opacity: .6;
  transition: opacity .2s ease-in
}

.work-about__tomography:hover>div>span {
  border-bottom: 1px dashed transparent;
  transition: border .2s ease-in
}

.work-about__content {
  margin-top: 40px
}

.work-about__content__title {

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 10px;
  text-transform: uppercase
}

.work-about__content_problem .work-about__content__title {
  color: #fd400d
}

.work-about__content_solution .work-about__content__title {
  color: #20bc95
}

.work-about__content__text {
  color: #989ca6;
  font-size: 16px;
  line-height: 28px;
  padding-right: 20px
}

.work-info {
  padding-left: 35px
}

.work-info__content {
  background-color: #f1f4f8;
  border-radius: 10px;
  overflow: hidden
}

.work-info__video {
  background-color: #00beb4;
  padding: 40px 0;
  text-align: center
}

.tabs__labels.tabs__labels_buttons .work-info__video .tab__label,
.work-info__video .link,
.work-info__video .slide-tab__label,
.work-info__video .tabs__labels.tabs__labels_buttons .tab__label,
.work-info__video [data-js=tooltip] {
  font-size: 14px;
  padding-left: 30px;
  position: relative
}

.tabs__labels.tabs__labels_buttons .work-info__video .tab__label:before,
.work-info__video .link:before,
.work-info__video .slide-tab__label:before,
.work-info__video .tabs__labels.tabs__labels_buttons .tab__label:before,
.work-info__video [data-js=tooltip]:before {
  background-image: url(/build/i/b98abeb0b8c096a390c4.svg);
  content: "";
  height: 17px;
  left: 0;
  margin-top: -8px;
  position: absolute;
  top: 50%;
  width: 14px
}

.work-info__price {
  border-bottom: 1px solid #dfe4eb;
  padding: 10px 0 33px;
  text-align: center
}

.work-info__price__title {
  color: #989ca6;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 10px;
  margin-top: 28px
}

.work-info__price__price {
  color: #5f6877;
  font-size: 30px;
  line-height: 28px;
  text-transform: uppercase
}

.work-info__price__tooltip {
  background-image: url(/build/i/a799d327dceeaf944bbd.svg);
  display: inline-block;
  height: 22px;
  margin-left: 12px;
  transition: background .2s ease-out;
  width: 18px
}

.work-info__price__tooltip:hover {
  background-image: url(/build/i/f281dafa07660268752b.svg);
  transition: background .2s ease-in
}

.work-info__total span {
  color: #5f6877;
  font-size: 18px;
  line-height: 28px;
  margin-left: 15px;
  margin-right: 15px
}

.work-info__order-price {
  padding: 38px 0;
  text-align: center
}

.tabs__labels.tabs__labels_buttons .work-info__order-price .tab__label.active,
.work-info__order-price .button,
.work-info__order-price .slide-tab__label.active,
.work-info__order-price .tabs__labels.tabs__labels_buttons .tab__label.active {
  background-color: transparent;
  margin-bottom: 25px
}

.work-info__order-price>div {
  color: #5f6877;
  font-size: 16px;
  line-height: 24px
}

@media screen and (max-width:1199px) {
  .work-about__content__text {
    padding-right: 5px
  }

  .work-info {
    padding-left: 5px
  }
}

@media screen and (max-width:1023px) {
  .work-about {
    margin-bottom: 0
  }

  .work-about__grid>* {
    flex-basis: 50%
  }

  .work-about__grid>:last-child {
    flex-basis: 100%;
    margin-top: 50px;
    padding: 0
  }

  .work-info {
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 0
  }

  .work-info__content {
    border-radius: 0
  }

  .work-info__price {
    align-items: center;
    display: flex;
    justify-content: center
  }

  .work-info__price>div {
    margin-left: 60px;
    margin-right: 60px
  }

  .work-info__order-price {
    align-items: center;
    display: flex;
    justify-content: center
  }

  .work-info__order-price>* {
    margin-left: 20px;
    margin-right: 20px
  }

  .tabs__labels.tabs__labels_buttons .work-info__order-price .tab__label.active,
  .work-info__order-price .button,
  .work-info__order-price .slide-tab__label.active,
  .work-info__order-price .tabs__labels.tabs__labels_buttons .tab__label.active {
    margin-bottom: 0;
    order: 1
  }

  .work-info__order-price div {
    order: 0;
    text-align: left
  }
}

@media screen and (max-width:767px) {

  .work-about__grid>*,
  .work-about__grid>:first-child>:last-child>div {
    flex-basis: 100%
  }

  .work-about__tomography>div {
    display: none
  }

  .work-about__tomography>div.mobile {
    display: block
  }

  .work-about__content {
    margin-top: 32px
  }

  .work-info__price>div {
    margin-left: 30px;
    margin-right: 30px
  }
}

@media screen and (max-width:570px) {
  .work-about__grid>:first-child>div>* {
    padding-left: 7px;
    padding-right: 7px
  }

  .work-about__images {
    max-width: 50%
  }

  .work-about__image .progressive-image-wrapper {
    height: 177px !important;
    width: 404px !important
  }

  .work-about__image:before {
    bottom: -22px;
    height: 44px;
    left: -22px;
    width: 44px
  }

  .work-about__image:after {
    bottom: 4px;
    height: 12px;
    left: 4px;
    width: 12px
  }

  .work-about__tomography>i {
    display: none
  }

  .work-info__price {
    flex-wrap: wrap
  }

  .work-info__price>* {
    flex-basis: 100%;
    margin-left: 0;
    margin-right: 0
  }

  .work-info__order-price {
    display: block
  }

  .work-info__order-price>* {
    margin-left: 0;
    margin-right: 0
  }

  .tabs__labels.tabs__labels_buttons .work-info__order-price .tab__label.active,
  .work-info__order-price .button,
  .work-info__order-price .slide-tab__label.active,
  .work-info__order-price .tabs__labels.tabs__labels_buttons .tab__label.active {
    margin-bottom: 20px
  }

  .work-info__order-price div {
    text-align: center
  }
}

@media screen and (max-width:400px) {
  .work-about__image .progressive-image-wrapper {
    height: 120px !important;
    width: 275px !important
  }
}

@media screen and (max-width:320px) {
  .work-about__image .progressive-image-wrapper {
    height: 94px !important;
    width: 274px !important
  }
}

.video-holder {
  background-color: #e8f0f3;
  border-radius: 10px;
  min-height: 300px;
  overflow: hidden;
  position: relative
}

.video-holder_with-videos {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0
}

.video-holder__first__container {
  position: relative;
  z-index: 1
}

.video-holder__title {
  color: #333638;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 19px
}

.video-holder__title .strong,
.video-holder__title strong {

  font-style: normal;
  font-weight: 500
}

.video-holder__text {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 18px
}

.video-holder__text i {
  display: inline-block;

  font-style: italic;
  font-weight: 400;
  margin-top: 7px
}

.video-holder__text__big {
  font-size: 16px;
  line-height: 24px
}

.video-holder__gray {
  color: #a7afbb;
  font-size: 13px;
  line-height: 24px
}

.video-holder__first {
  align-items: center;
  background-position: 0 100%;
  display: flex;
  flex-wrap: wrap;
  padding-left: 244px;
  padding-right: 55px;
  position: relative
}

.video-holder__first_koryakin {
  background-image: url(/build/i/bf147a17bfbe588a7e7e.png)
}

.video-holder__first_koryakin .video-holder__first__play {
  margin-top: 0;
  top: 120px
}

.video-holder__first_vasilenko {
  background-image: url(/build/i/d73b8878ef91b582b33a.png);
  background-position: 18px
}

.video-holder__first_vasilenko .video-holder__first__play {
  margin-top: 0;
  top: 130px
}

.video-holder__first_allon4 {
  background-image: url(/build/i/7be9b04213f4029dd227.png)
}

.video-holder__first_surgery-floor {
  background-image: url(/build/i/a424de71b1a611751b5e.jpg)
}

.video-holder__second {
  flex-basis: 424px
}

.video-holder__second>* {
  display: block;
  height: 50%;
  position: relative
}

.video-holder__second>:first-child {
  background-color: #863b47;
  color: #f5d588
}

.video-holder__second>:last-child {
  background-color: #39c9c7;
  color: #333638
}

.tabs__labels.tabs__labels_buttons .video-holder__second>:last-child .tab__label,
.video-holder__second>:last-child .link,
.video-holder__second>:last-child .slide-tab__label,
.video-holder__second>:last-child .tabs__labels.tabs__labels_buttons .tab__label,
.video-holder__second>:last-child [data-js=tooltip] {
  color: #333638
}

.tabs__labels.tabs__labels_buttons .video-holder__second>:last-child .tab__label>span,
.video-holder__second>:last-child .link>span,
.video-holder__second>:last-child .slide-tab__label>span,
.video-holder__second>:last-child .slider-cost-of__slide__prices>[data-js=tooltip]>span,
.video-holder__second>:last-child .tabs__labels.tabs__labels_buttons .tab__label>span,
.video-holder__second>:last-child [data-js=tooltip]>span {
  border-bottom: 1px solid rgba(51, 54, 56, .3);
  transition: border-bottom .2s ease-out
}

.tabs__labels.tabs__labels_buttons .video-holder__second>:last-child .tab__label:hover>span,
.video-holder__second>:last-child .link:hover>span,
.video-holder__second>:last-child .slide-tab__label:hover>span,
.video-holder__second>:last-child .slider-cost-of__slide__prices>:hover[data-js=tooltip]>span,
.video-holder__second>:last-child .tabs__labels.tabs__labels_buttons .tab__label:hover>span,
.video-holder__second>:last-child :hover[data-js=tooltip]>span {
  border-bottom: 1px solid transparent !important;
  transition: border-bottom .2s ease-out
}

.video-holder__second>:last-child:hover {
  background-color: #40f0ee
}

.video-holder__second__text {
  font-size: 15px;
  line-height: 22px;
  padding-left: 102px;
  padding-right: 80px;
  padding-top: 38px
}

.video-holder__second__text .strong,
.video-holder__second__text strong {
  display: inline-block;

  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 9px
}

.video-holder__bg {
  background-position: 100%
}

.video-holder__bg_severe-cases {
  background-image: url(/build/i/d97117560e41c43a1186.png)
}

.video-holder__bg_in-a-day {
  background-image: url(/build/i/eb5e229cc25b89677e9a.png);
  background-position: right -25px center
}

.video-holder__bg_paska {
  background-image: url(/build/i/c0bd37fb53fdfd70c9b8.png)
}

.video-holder__bg_nobel-zygoma {
  background-image: url(/build/i/e12943c7bf634051ebb9.png);
  background-position: 100% 0
}

.video-holder__bg_laboratory,
.video-holder__bg_paska {
  background-color: #a7edf5 !important
}

.video-holder__bg_laboratory:hover,
.video-holder__bg_paska:hover {
  background-color: #42ecff !important
}

.video-holder__bg_vinir {
  background-image: url(/build/i/90f598e41e340408c798.png)
}

.video_holder__icon {
  display: block;
  height: 60px;
  left: 21px;
  margin-top: -30px;
  position: absolute;
  top: 50%;
  width: 60px
}

.video_holder__icon_nobel {
  background-image: url(/build/i/b61027507fe1889d41d4.png)
}

.video_holder__icon_swiss {
  background-image: url(/build/i/6d9c7c7dc99b8dd7154d.png)
}

.video_holder__icon_check {
  background-image: url(/build/i/2a5e844fed917d4a483f.png)
}

.video_holder__icon_search {
  background-image: url(/build/i/ac9fcd4522ebf21f7eed.png)
}

.video_holder__icon_laboratory {
  background-image: url(/build/i/e99a8798b566a4f33eb9.png)
}

.video-holder__first__play {
  display: inline-block;
  height: 80px;
  left: 77px;
  margin-top: -40px;
  position: absolute;
  top: 50%;
  width: 80px
}

.video-holder__first__play .circle-icon_big {
  align-items: center;
  display: flex;
  height: 80px;
  justify-content: center;
  width: 80px
}

.video-holder__first__play .circle-icon_big:before {
  background-size: 32px 32px;
  height: 32px;
  margin-left: 0;
  width: 32px
}

.video-holder__videos {
  align-items: center;
  background-color: #f4f7f8;
  border-radius: 0 0 10px 10px;
  margin-bottom: 50px;
  padding: 16px 38px
}

.video-holder__videos>div:first-child {
  max-width: 265px
}

.video-holder__videos__text {
  color: #333638;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px
}

.video-holder__videos__list.answers-videos__grid__grid {
  margin-left: -7px;
  margin-right: -7px
}

.video-holder__videos__list.answers-videos__grid__grid>a:not(.button):not(.slide-tab__label.active) {
  flex: 0 0 calc(33.33% - 14px);
  margin-bottom: 0;
  margin-left: 7px;
  margin-right: 7px
}

@media screen and (min-width:1170px) {
  .video-holder-wrapper_one-holder .video-holder__second__text {
    padding: 0 100px 0 60px
  }

  .video-holder-wrapper_one-holder .video-holder__first {
    padding-left: 160px
  }
}

@media screen and (min-width:1023px) {
  .video-holder-wrapper_one-holder .video-holder__first {
    padding-left: 120px
  }

  .video-holder-wrapper_one-holder .video-holder__first .video-holder__title {
    font-size: 26px
  }

  .video-holder-wrapper_one-holder .video-holder__second {
    flex-basis: 45%
  }

  .video-holder-wrapper_one-holder .video-holder__second>:first-child {
    align-items: center;
    display: flex;
    height: 100%;
    position: relative
  }

  .video-holder-wrapper_one-holder .video_holder__icon_nobel {
    left: auto;
    margin: initial;
    position: absolute;
    right: 15px;
    top: 15px
  }

  .video-holder-wrapper_one-holder .video-holder__second__text {
    min-height: 133px;
    padding: 0 30px 0 50px
  }

  .video-holder-wrapper_one-holder .video-holder__second__text .strong,
  .video-holder-wrapper_one-holder .video-holder__second__text strong {
    margin-bottom: 19px
  }
}

@media screen and (max-width:1318px) {
  .video-holder-wrapper:not(.video-holder-wrapper__show-text) .video-holder__text {
    display: none
  }

  .video-holder__first_allon4 .video-holder__text {
    display: block
  }

  .video-holder__videos>div:first-child {
    max-width: 160px;
    padding-right: 28px
  }

  .video-holder__videos__list.answers-videos__grid__grid>a:not(.button):not(.slide-tab__label.active):first-child,
  .video-holder__videos__list.answers-videos__grid__grid>a:not(.button):not(.slide-tab__label.active):nth-child(2) {
    max-width: 243px
  }

  .video-holder__videos__list.answers-videos__grid__grid>a:not(.button):not(.slide-tab__label.active):nth-child(3) {
    flex-basis: 34%
  }
}

@media screen and (max-width:1279px) {

  .video-holder-wrapper .row,
  .video-holder-wrapper .row_short {
    padding-left: 0;
    padding-right: 0
  }

  .video-holder {
    border-radius: 0
  }

  .video-holder__second {
    flex-basis: 476px
  }

  .video-holder__bg {
    background-image: none
  }

  .video-holder__second__text {
    padding-right: 30px
  }
}

@media screen and (max-width:1023px) {
  .video-holder__first {
    background-position: center top 7px;
    min-height: 400px;
    padding-left: 26px;
    padding-right: 26px
  }

  .video-holder__first:after {
    background-color: #fff;
    bottom: 0;
    content: "";
    display: block;
    height: 170px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 0
  }

  .video-holder-wrapper__show-text .video-holder__first {
    background-color: #fff;
    background-image: none;
    min-height: 182px
  }

  .video-holder__first_vasilenko {
    background-position: center top 11px
  }

  .video-holder__first__play {
    height: 60px;
    left: 50%;
    margin-left: -30px;
    width: 60px
  }

  .video-holder__first__play .circle-icon_big {
    height: 60px;
    width: 60px
  }

  .video-holder__first__play .circle-icon_big:before {
    background-size: 18px 18px;
    height: 18px;
    width: 18px
  }

  .video-holder__first__container {
    bottom: 30px;
    left: 0;
    padding-left: 26px;
    padding-right: 26px;
    position: absolute;
    text-align: center;
    width: 100%
  }

  .video-holder {
    padding-top: 200px
  }

  .video-holder__second {
    flex-basis: 100%
  }

  .video-holder__second>.video-holder__bg {
    height: auto;
    min-height: 200px;
    width: 100%
  }

  .video-holder__second>.video-holder__bg:first-child {
    position: absolute;
    top: 0
  }

  .video-holder__second__text {
    font-size: 14px;
    min-height: 212px;
    padding-left: 26px;
    padding-right: 26px;
    padding-top: 100px;
    text-align: center
  }

  .video-holder__second__text .strong,
  .video-holder__second__text strong {
    font-size: 16px
  }

  .video_holder__icon {
    left: 50%;
    margin-left: -30px;
    margin-top: 0;
    top: 30px
  }

  .video-holder__title {
    font-size: 16px;
    line-height: 22px
  }

  .video-holder__videos>div:first-child {
    margin-bottom: 15px;
    max-width: 100%;
    padding-right: 0;
    text-align: center
  }

  .video-holder__videos__list.answers-videos__grid__grid {
    flex-basis: 100%;
    margin-left: -26px;
    margin-right: -26px
  }

  .video-holder__videos__list.answers-videos__grid__grid a:not(.button):not(.slide-tab__label.active) {
    flex-basis: calc(50% - 52px) !important;
    margin-bottom: 20px;
    max-width: none !important
  }
}

@media screen and (max-width:767px) {
  .video-holder__first {
    padding-left: 15px;
    padding-right: 15px
  }

  .video-holder-wrapper__show-text .video-holder__first {
    min-height: 212px
  }

  .video-holder__first__container {
    bottom: 15px;
    padding-left: 15px;
    padding-right: 15px
  }

  .video-holder__second__text {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 95px
  }

  .video-holder__second__text .strong,
  .video-holder__second__text strong {
    display: block
  }

  .video-holder__second__text br {
    display: none
  }

  .video_holder__icon {
    top: 20px
  }

  .video-holder__first__play {
    top: 116px
  }

  .video-holder__videos {
    padding: 30px 15px 20px
  }

  .video-holder__videos>div:first-child {
    margin-left: auto;
    margin-right: auto;
    max-width: 200px
  }

  .video-holder__videos__list.answers-videos__grid__grid {
    margin-left: 0;
    margin-right: 0
  }

  .video-holder__videos__list.answers-videos__grid__grid a:not(.button):not(.slide-tab__label.active) {
    flex-basis: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important
  }

  .video-holder__videos__text {
    font-size: 16px;
    line-height: 24px
  }

  .page_54 .video-holder__first {
    display: none
  }
}

@media screen and (max-width:355px) {
  .video-holder-wrapper__show-text .video-holder__first {
    min-height: 248px
  }

  .video-holder-wrapper_one-holder .video-holder__second__text {
    min-height: 240px
  }
}

.contact-ribbon-wrapper {
  margin-top: 20px;
  padding-top: 30px
}

.contact-ribbon {
  background-color: #34ece1;
  border-radius: 50px;
  height: 100px;
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
  width: 100%
}

.contact-ribbon__doctor {
  background-image: url(/build/i/77f382e7935cd606ed01.png);
  bottom: 0;
  display: block;
  height: 131px;
  left: 26px;
  position: absolute;
  width: 153px
}

.contact-ribbon__phone {
  color: #819292;
  font-size: 14px
}

.contact-ribbon__phone__link {
  color: #3b4041;
  display: inline-block;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin-top: 5px
}

.contact-ribbon__phone__link span {
  color: #819292
}

.contact-ribbon__phone__link:hover {
  color: #00ada4
}

.contact-ribbon__left {
  align-items: center;
  display: flex;
  float: left;
  height: 100%;
  margin-left: 205px
}

.contact-ribbon__right {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  float: right;
  height: 100%
}

.contact-ribbon__phone {
  margin-right: 47px
}

.contact-ribbon__title {
  color: #3c4143;

  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 4px
}

.contact-ribbon__text {
  color: #5f6877;
  font-size: 15px;
  line-height: 22px
}

.contact-ribbon-links {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin-left: -40px;
  margin-right: -40px;
  margin-top: 33px
}

.contact-ribbon-links br {
  display: none
}

.contact-ribbon-links>* {
  flex: 0 1 auto;
  margin-left: 40px;
  margin-right: 40px;
  position: relative
}

.contact-ribbon-links .link,
.contact-ribbon-links .slide-tab__label,
.contact-ribbon-links .tabs__labels.tabs__labels_buttons .tab__label,
.contact-ribbon-links [data-js=tooltip],
.tabs__labels.tabs__labels_buttons .contact-ribbon-links .tab__label {
  font-size: 14px;
  text-align: center
}

.contact-ribbon-links__play {
  height: 30px;
  left: -44px;
  padding: 0;
  position: absolute;
  top: -6px;
  width: 30px
}

.contact-ribbon-links__play:before {
  background-size: 10px 10px;
  height: 10px;
  left: 50%;
  margin-left: -5px;
  margin-top: -5px;
  top: 50%;
  width: 10px
}

.contact-ribbon-link_with-icon {
  padding-left: 60px;
  position: relative
}

.contact-ribbon-link_with-icon i {
  display: block;
  height: 50px;
  left: 0;
  margin-top: -25px;
  position: absolute;
  top: 50%;
  width: 50px
}

.contact-ribbon-link_with-icon.underline {
  color: #464a4c
}

.contact-ribbon-link_with-icon.underline>span {
  border-bottom: 1px solid #464a4c
}

.contact-ribbon-custom-wrapper .contact-ribbon-links>* {
  margin-left: 25px;
  margin-right: 25px
}

@media screen and (max-width:1220px) {
  .contact-ribbon__text {
    font-size: 14px;
    line-height: 20px;
    max-width: 230px
  }

  .contact-ribbon-custom-wrapper .contact-ribbon__left {
    margin-left: 160px
  }

  .contact-ribbon-custom-wrapper .contact-ribbon__title {
    font-size: 20px
  }

  .contact-ribbon-custom-wrapper .contact-ribbon-links>a:nth-child(4) {
    display: none
  }

  .pusher.pusher-contact-ribbon {
    margin-top: 40px !important
  }
}

@media screen and (max-width:950px) {
  .contact-ribbon__left {
    margin-left: 165px
  }

  .contact-ribbon-links {
    margin-left: -20px;
    margin-right: -20px
  }

  .contact-ribbon-links>* {
    margin-left: 20px;
    margin-right: 20px
  }
}

@media screen and (max-width:910px) {
  .contact-ribbon-wrapper {
    padding-top: 0
  }

  .contact-ribbon-wrapper>.row,
  .contact-ribbon-wrapper>.row_short {
    padding-left: 0;
    padding-right: 0
  }

  .contact-ribbon {
    border-radius: 0;
    height: auto;
    padding-bottom: 155px;
    padding-top: 41px
  }

  .contact-ribbon__doctor {
    background-image: url(/build/i/0b7251d389da24331059.png);
    left: 50%;
    margin-left: -76px
  }

  .contact-ribbon__left {
    display: block;
    float: none;
    margin-left: 0;
    text-align: center
  }

  .contact-ribbon__text {
    margin-bottom: 21px;
    margin-left: auto;
    margin-right: auto
  }

  .contact-ribbon__right {
    display: block;
    float: none;
    text-align: center
  }

  .contact-ribbon__title {
    margin-bottom: 6px
  }

  .contact-ribbon__phone {
    margin-bottom: 21px;
    margin-right: 0
  }

  .contact-ribbon-links {
    display: block;
    margin-top: 44px;
    padding-bottom: 20px;
    text-align: center
  }

  .contact-ribbon-links br {
    display: block
  }

  .contact-ribbon-links .link,
  .contact-ribbon-links .slide-tab__label,
  .contact-ribbon-links .tabs__labels.tabs__labels_buttons .tab__label,
  .contact-ribbon-links [data-js=tooltip],
  .tabs__labels.tabs__labels_buttons .contact-ribbon-links .tab__label {
    display: inline-block;
    margin-bottom: 27px
  }

  .contact-ribbon-custom-wrapper .contact-ribbon__left {
    margin-left: 0
  }

  .contact-ribbon-custom-wrapper .contact-ribbon__title {
    font-size: 22px
  }
}

@media screen and (max-width:767px) {
  .page_54 .contact-ribbon-custom-wrapper {
    margin-top: -40px
  }

  .contact-ribbon-custom-wrapper .contact-ribbon__left {
    height: auto
  }

  .contact-ribbon-custom-wrapper>.row,
  .contact-ribbon-custom-wrapper>.row_short {
    display: flex;
    flex-wrap: wrap
  }

  .contact-ribbon-custom-wrapper>.row>*,
  .contact-ribbon-custom-wrapper>.row_short>* {
    flex-basis: 100%
  }

  .contact-ribbon-custom-wrapper .contact-ribbon-links {
    display: flex;
    flex-basis: calc(100% + 30px) !important;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-top: 0;
    order: -1;
    padding-bottom: 0
  }

  .contact-ribbon-custom-wrapper .contact-ribbon-links>a {
    display: none;
    padding-bottom: 24px;
    padding-top: 24px
  }

  .contact-ribbon-custom-wrapper .contact-ribbon-links>a:nth-child(-n+3) {
    display: inline-block;
    margin-bottom: 0;
    order: -1
  }

  .contact-ribbon-custom-wrapper .contact-ribbon-links>a:first-child {
    order: 1;
    position: relative
  }

  .contact-ribbon-custom-wrapper .contact-ribbon-links>a:first-child:before {
    background-color: #d9e1e1;
    content: "";
    height: 1px;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 100vw
  }

  .contact-ribbon-custom-wrapper .contact-ribbon-link_with-icon {
    align-items: center;
    display: flex !important;
    flex-basis: 100%;
    font-size: 16px;
    justify-content: center;
    line-height: 22px;
    padding-bottom: 10px !important;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 10px !important;
    text-align: left;
    text-transform: none
  }

  .contact-ribbon-custom-wrapper .contact-ribbon-link_with-icon span {
    border-bottom: 0;
    display: inline;
    text-decoration: underline
  }

  .contact-ribbon-custom-wrapper .contact-ribbon-link_with-icon i {
    height: 65px;
    left: 0;
    margin-right: 15px;
    margin-top: 0;
    min-width: 65px;
    position: relative;
    top: 0;
    width: 65px
  }
}

.offer-block {
  background-position-y: 12px;
  border-radius: 10px;
  height: 180px;
  padding-left: 312px;
  padding-right: 19px;
  position: relative
}

.offer-block>.progressive-image-wrapper {
  border-radius: 0;
  height: 148px !important;
  left: 30px;
  margin-top: -74px;
  position: absolute;
  top: 50%;
  width: 240px !important
}

.offer-block__title {
  color: #5f6877;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 26px
}

.offer-block__price {
  color: #3c4143;

  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px
}

.offer-block__text {
  font-size: 18px;
  line-height: 26px;
  margin-top: 10px
}

.offer-block__text .strong,
.offer-block__text strong {

  font-style: normal;
  font-weight: 500
}

.offer-block__content {
  float: left
}

.offer-block__action,
.offer-block__content {
  align-items: center;
  display: flex;
  height: 100%
}

.offer-block__action {
  float: right;
  justify-content: center;
  min-width: 300px
}

.offer-block__action>div {
  text-align: center
}

.offer-block__action__phone>span {
  color: #819292;
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 3px
}

.offer-block__action__phone_block>span {
  display: block
}

.offer-block__action__phone_block .offer-block__action__phone__link {
  color: #333638
}

.offer-block__action__phone__link {
  color: #3c4143;
  display: inline-block;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 7px
}

.offer-block__action__phone__link:hover {
  color: #00ada4
}

.offer-block__action__phone__link span {
  color: #819292
}

.offer-block__action__end-time {
  color: #5f6877;
  font-size: 14px;
  line-height: 20px;
  margin-top: 9px
}

.offer-block__all-on-4 {
  background-color: #fff1e1
}

.offer-block__tooth-extraction {
  background-color: #fff1e1;
  height: 250px;
  padding-left: 43px;
  position: relative
}

.offer-block__tooth-extraction .offer-block__text,
.offer-block__tooth-extraction .offer-block__title {
  max-width: 704px
}

.offer-block__tooth-extraction .offer-block__price {
  font-size: 30px
}

.offer-block__tooth-extraction .offer-block__text .strong,
.offer-block__tooth-extraction .offer-block__text strong {

  font-style: normal;
  font-weight: 700
}

.offer-block__tooth-extraction .offer-block__text>div {
  font-size: 20px;
  margin-top: 30px;
  padding-left: 30px;
  position: relative
}

.offer-block__tooth-extraction .offer-block__text>div:before {
  background-position: 0;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  display: block;
  height: 18px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 18px
}

.offer-block__tooth-extraction .offer-block__text>div.offer-block__other:before {
  background-image: url(/build/i/2aaec266e2e7d0c28adc.svg)
}

.offer-block__tooth-extraction .offer-block__text>div.offer-block__we:before {
  background-image: url(/build/i/c91d8416873ce143f00c.svg)
}

.offer-block__tooth-extraction .progressive-image-wrapper {
  bottom: 0;
  display: block;
  height: 243px !important;
  left: auto;
  position: absolute;
  right: 0;
  top: auto;
  width: 500px !important
}

.offer-block__abutment {
  background-color: #e7eefe
}

.offer-block__crowns {
  background-color: #f8efa1
}

.offer-block__prosthetics {
  background-color: #acf8de
}

.offer-block__braces,
.offer-block__veneers {
  background-color: #e7eefe
}

.offer-block__implantation {
  background-color: #bdeaff
}

.offer-block-wrapper_compare-prices {
  margin-bottom: 46px;
  margin-top: 36px
}

.offer-block__zygoma {
  background-color: #acf8de
}

.offer-block__zygoma>.progressive-image-wrapper {
  height: auto !important;
  left: 27px;
  margin-top: 0;
  max-width: 100%;
  top: 50%;
  transform: translateY(-50%);
  width: auto !important
}

.offer-block__zygoma .offer-block__text {
  margin-bottom: 10px;
  margin-top: 0;
  max-width: 530px
}

.offer-block__zygoma .offer-block__price span {

  font-size: 20px;
  font-style: normal;
  font-weight: 400
}

.offer-block__zygoma .button,
.offer-block__zygoma .slide-tab__label.active,
.offer-block__zygoma .tabs__labels.tabs__labels_buttons .tab__label.active,
.tabs__labels.tabs__labels_buttons .offer-block__zygoma .tab__label.active {
  max-width: 158px;
  padding-left: 34px;
  padding-right: 34px
}

.offer-block__straumann {
  background-color: #acf8de
}

.offer-block__straumann .progressive-image-wrapper {
  height: auto !important;
  width: auto !important
}

.offer-block__straumann .offer-block__content {
  max-width: calc(100% - 300px);
  width: 100%
}

.offer-block__straumann .offer-block__price>span {
  color: #44c398;
  font-size: 15px
}

.offer-block__straumann .offer-block__text {
  color: #3c4143;
  font-size: 17px;
  line-height: 26px
}

.offer-block__straumann .offer-block__text .strong,
.offer-block__straumann .offer-block__text strong {
  font-size: 20px
}

.offer-block__straumann .offer-block__text br {
  display: none
}

@media screen and (max-width:1330px) {

  .offer-block__text,
  .offer-block__title {
    max-width: 490px
  }

  .offer-block__tooth-extraction .offer-block__text,
  .offer-block__tooth-extraction .offer-block__title {
    max-width: 704px
  }
}

@media screen and (max-width:1279px) {

  .offer-block__tooth-extraction .offer-block__text,
  .offer-block__tooth-extraction .offer-block__title {
    max-width: 560px
  }

  .offer-block__zygoma {
    padding-right: 0
  }

  .offer-block__zygoma .offer-block__content {
    max-width: calc(100% - 300px);
    width: 100%
  }
}

@media screen and (max-width:1250px) {
  .offer-block {
    padding-left: 248px
  }

  .offer-block>.progressive-image-wrapper {
    left: -8px
  }

  .offer-block__tooth-extraction {
    padding-left: 43px
  }

  .offer-block__tooth-extraction>.progressive-image-wrapper {
    left: auto;
    right: 0
  }

  .offer-block__title {
    font-size: 20px
  }

  .offer-block__price {
    font-size: 26px
  }

  .offer-block__zygoma>.progressive-image-wrapper {
    left: 27px
  }
}

@media screen and (max-width:1175px) {

  .offer-block__text,
  .offer-block__title {
    max-width: 450px
  }
}

@media screen and (max-width:1145px) {
  .offer-block {
    border-radius: 0;
    margin-left: -70px;
    margin-right: -70px;
    padding-left: 230px
  }

  .offer-block__tooth-extraction {
    padding-left: 70px
  }

  .offer-block__zygoma>.progressive-image-wrapper {
    width: 240px !important
  }

  .offer-block__zygoma {
    border-radius: 10px;
    margin-left: 0;
    margin-right: 0
  }

  .offer-block__zygoma>.progressive-image-wrapper {
    max-width: 179px
  }
}

@media screen and (max-width:1023px) {
  .offer-block {
    height: auto;
    padding: 36px 26px 126px;
    text-align: center
  }

  .offer-block>.progressive-image-wrapper {
    bottom: 30px;
    height: 74px !important;
    left: 50%;
    margin-left: -60px;
    margin-top: 0;
    top: inherit !important;
    top: auto !important;
    width: 120px !important
  }

  .offer-block__content {
    display: block;
    float: none;
    margin-bottom: 20px
  }

  .offer-block__action {
    display: block;
    float: none;
    min-width: 100%
  }

  .offer-block__title {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 3px
  }

  .offer-block__text,
  .offer-block__title {
    max-width: 100%
  }

  .offer-block__price {
    font-size: 18px;
    line-height: 28px
  }

  .offer-block__button {
    margin-bottom: 5px
  }

  .offer-block__tooth-extraction {
    padding-bottom: 0
  }

  .offer-block__tooth-extraction .offer-block__price {
    font-size: 28px
  }

  .offer-block__tooth-extraction .offer-block__text,
  .offer-block__tooth-extraction .offer-block__title {
    margin: 0 auto
  }

  .offer-block__tooth-extraction .offer-block__text>div,
  .offer-block__tooth-extraction .offer-block__title>div {
    padding-left: 0
  }

  .offer-block__tooth-extraction .offer-block__text>div:before,
  .offer-block__tooth-extraction .offer-block__title>div:before {
    margin: 0 auto 4px;
    position: relative;
    top: auto
  }

  .offer-block__tooth-extraction .progressive-image-wrapper {
    bottom: auto;
    display: block;
    height: 243px !important;
    left: auto;
    margin: 30px auto 0;
    position: relative;
    right: auto;
    width: 500px !important
  }

  .price-table__offer_zygoma {
    margin-top: -30px
  }

  .price-table__offer_zygoma .pusher,
  .price-table__offer_zygoma+.pusher {
    margin-top: 40px !important
  }

  .offer-block__zygoma {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 20px 10px 20px 150px;
    text-align: left
  }

  .offer-block__zygoma .progressive-image-wrapper {
    bottom: auto;
    height: auto !important;
    left: 15px;
    margin-left: 0;
    top: 50% !important;
    transform: translateY(-50%)
  }

  .offer-block__zygoma .offer-block__content {
    margin-bottom: 0;
    max-width: calc(100% - 210px)
  }

  .offer-block__zygoma .offer-block__action {
    min-width: 190px
  }

  .offer-block__zygoma .offer-block__button {
    margin-bottom: 0
  }

  .offer-block__zygoma .offer-block__text {
    margin-left: auto;
    margin-right: auto
  }

  .offer-block__straumann {
    padding-bottom: 40px
  }

  .offer-block__straumann .progressive-image-wrapper {
    display: none
  }

  .offer-block__straumann .offer-block__content {
    max-width: none
  }

  .offer-block__straumann .offer-block__price {
    font-size: 22px;
    line-height: 24px
  }

  .offer-block__straumann .offer-block__price>span {
    font-size: 13px
  }

  .offer-block__straumann .offer-block__text {
    font-size: 16px;
    line-height: 26px
  }
}

@media screen and (max-width:767px) {
  .offer-block {
    margin-left: -15px;
    margin-right: -15px;
    padding-bottom: 36px;
    padding-left: 15px;
    padding-right: 15px
  }

  .offer-block>.progressive-image-wrapper {
    display: none
  }

  .offer-block__tooth-extraction .offer-block__text>div {
    font-size: 16px
  }

  .offer-block__text {
    font-size: 14px;
    line-height: 22px;
    margin-top: 5px
  }

  .offer-block__text br {
    display: none
  }

  .offer-block__zygoma {
    display: block;
    margin-left: 0;
    margin-right: 0;
    padding: 30px;
    text-align: center
  }

  .offer-block__zygoma .progressive-image-wrapper {
    display: block;
    left: 0;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    max-width: 150px;
    position: relative;
    transform: none;
    width: 100% !important
  }

  .offer-block__zygoma .offer-block__content {
    margin-bottom: 15px;
    max-width: 100%
  }

  .offer-block__zygoma .offer-block__text {
    font-size: 16px;
    line-height: 24px;
    padding-left: 7px;
    padding-right: 7px
  }

  .offer-block__zygoma .button,
  .offer-block__zygoma .slide-tab__label.active,
  .offer-block__zygoma .tabs__labels.tabs__labels_buttons .tab__label.active,
  .tabs__labels.tabs__labels_buttons .offer-block__zygoma .tab__label.active {
    height: 50px;
    line-height: 50px;
    max-width: 217px;
    width: 100%
  }
}

@media screen and (max-width:540px) {
  .page_54 .offer-block {
    align-items: center;
    display: flex;
    justify-content: space-between
  }

  .page_54 .offer-block__action {
    max-width: 160px;
    min-width: 160px
  }

  .page_54 .offer-block__content {
    margin-bottom: 0;
    min-width: 35%;
    padding-right: 15px;
    text-align: left
  }

  .page_54 .offer-block__button {
    display: block;
    height: 50px;
    line-height: 52px;
    margin-bottom: 0;
    max-width: 100%;
    width: 100%
  }

  .page_54 .offer-block__price {
    font-size: 20px;
    line-height: 24px
  }

  .page_54 .offer-block__action__end-time,
  .page_54 .offer-block__text {
    display: none
  }

  .page_54 .offer-block__straumann {
    display: block
  }

  .page_54 .offer-block__straumann .offer-block__content {
    margin-bottom: 20px;
    padding-right: 0;
    text-align: center
  }

  .page_54 .offer-block__straumann .offer-block__text {
    display: block
  }

  .page_54 .offer-block__straumann .offer-block__action {
    margin-left: auto;
    margin-right: auto
  }

  .offer-block__tooth-extraction .offer-block__price {
    font-size: 18px
  }

  .offer-block__straumann .offer-block__text br {
    display: block
  }
}

@media screen and (max-width:360px) {
  .offer-block__straumann .offer-block__price {
    font-size: 18px
  }
}

.requisites__title {
  color: #333638;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 22px;
  text-transform: uppercase
}

.requisites__grid {
  display: flex;
  justify-content: space-between
}

.requisites__grid>* {
  flex-basis: 100%
}

.requisites__grid>:first-child {
  max-width: 417px;
  padding-right: 55px
}

.requisites__grid>ul {
  list-style: none;
  padding-left: 0
}

.requisites__grid>ul li {
  color: #5f6877;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 11px
}

.requisites__grid>ul li span {
  color: #819292
}

.requisites__certs {
  cursor: pointer;
  justify-content: space-between
}

.requisites__cert {
  border: 10px solid #fff;
  border-radius: 10px;
  box-shadow: 0 10px 29px rgba(70, 81, 86, .18);
  width: 184px
}

@media screen and (max-width:1305px) {
  .requisites__grid>:first-child {
    max-width: 100%;
    padding-right: 30px
  }

  .requisites__grid>:last-child {
    max-width: 480px
  }

  .requisites__certs>* {
    flex: 0 0 50%;
    margin-bottom: 20px
  }

  .requisites__cert {
    border: 5px solid #fff;
    border-radius: 5px;
    box-shadow: 0 5px 29px rgba(70, 81, 86, .18);
    width: 160px
  }

  .requisites__cert img {
    height: auto;
    width: 150px
  }
}

@media screen and (max-width:1279px) {
  .requisites__grid>:last-child {
    max-width: 350px
  }

  .requisites__certs>* {
    flex: 0 0 50%;
    margin-bottom: 20px
  }

  .requisites__cert {
    border: 5px solid #fff;
    border-radius: 5px;
    box-shadow: 0 5px 29px rgba(70, 81, 86, .18);
    width: 110px
  }

  .requisites__cert img {
    height: auto;
    width: 100px
  }
}

@media screen and (max-width:1023px) {
  .requisites__grid {
    flex-wrap: wrap
  }

  .requisites__grid>* {
    max-width: 100%;
    min-width: 100%
  }

  .requisites__certs {
    margin-top: 30px
  }

  .requisites__certs>* {
    flex: 0 0 25%
  }

  .requisites__cert {
    margin-left: auto;
    margin-right: auto
  }
}

@media screen and (max-width:415px) {
  .requisites__certs>* {
    flex: 0 0 50%
  }

  .requisites__title {
    text-align: center
  }
}

.stroll-clinic-online {
  background-color: #abb0a7;
  background-image: url(/build/i/e3124f56e46dce6cd34b.png);
  background-position: 100%;
  height: 464px;
  margin-bottom: 42px;
  padding-top: 87px;
  position: relative
}

.stroll-clinic-online+.answers-videos {
  margin-top: -42px
}

.stroll-clinic-online__title {
  color: #fff;

  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 32px
}

.stroll-clinic-online__text {
  color: #fff;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px
}

.stroll-clinic-online__cards {
  margin-bottom: -80px;
  margin-top: 50px;
  max-width: 1224px;
  width: 100%
}

.stroll-clinic-online__cards__grid {
  margin-left: -12px;
  margin-right: -12px
}

.stroll-clinic-online__cards__grid>* {
  flex: 0 1 33.33%;
  padding-left: 12px;
  padding-right: 12px
}

.stroll-clinic-online__cards__grid>:first-child .stroll-clinic-online__card {
  background-image: url(/build/i/d27ee9518df3cbb0b909.jpg)
}

.stroll-clinic-online__cards__grid>:nth-child(2) .stroll-clinic-online__card {
  background-image: url(/build/i/00a9851d5929d53cb2c6.jpg)
}

.stroll-clinic-online__cards__grid>:nth-child(3) .stroll-clinic-online__card {
  background-image: url(/build/i/a8a8efa2d5765e729c7a.jpg)
}

.stroll-clinic-online__card {
  background-position: 100% 0;
  border-radius: 10px;
  display: inline-block;
  height: 226px;
  max-width: 392px;
  padding-left: 40px;
  padding-top: 52px;
  position: relative;
  top: 0;
  transition: all .1s ease-in;
  width: 100%
}

.stroll-clinic-online__card:hover {
  top: -5px;
  transition: all .1s ease-in
}

.stroll-clinic-online__card__title {
  color: #fff;

  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 5px
}

.stroll-clinic-online__card__text {
  color: #fff;
  font-size: 16px;
  margin-bottom: 33px
}

@media screen and (max-width:1395px) {
  .stroll-clinic-online {
    background-position: 20px
  }
}

@media screen and (max-width:1023px) {
  .stroll-clinic-online {
    background-image: none;
    padding-top: 60px;
    text-align: center
  }

  .stroll-clinic-online__cards__grid {
    justify-content: center
  }

  .stroll-clinic-online__cards__grid>:first-child .stroll-clinic-online__card {
    background-image: url(/build/i/f7c49325f38fdfcbb978.jpg)
  }

  .stroll-clinic-online__cards__grid>:nth-child(2) .stroll-clinic-online__card {
    background-image: url(/build/i/b020951458916ba86553.jpg)
  }

  .stroll-clinic-online__cards__grid>:nth-child(3) .stroll-clinic-online__card {
    background-image: url(/build/i/a595d2cbd732a0ffd14a.jpg)
  }

  .stroll-clinic-online__card {
    background-position: top;
    background-size: cover;
    height: 276px;
    padding-left: 0;
    padding-top: 105px;
    text-align: center
  }
}

@media screen and (max-width:767px) {
  .stroll-clinic-online {
    height: auto;
    padding-bottom: 60px
  }

  .stroll-clinic-online__cards {
    margin-bottom: 0;
    margin-top: 0
  }

  .stroll-clinic-online__cards__grid>* {
    flex-basis: 50%;
    margin-top: 30px
  }

  .stroll-clinic-online__cards__grid .stroll-clinic-online__card {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 290px
  }
}

@media screen and (max-width:670px) {
  .stroll-clinic-online__title {
    font-size: 30px;
    margin-bottom: 32px
  }

  .stroll-clinic-online__text {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 28px
  }

  .stroll-clinic-online__cards {
    bottom: 0;
    display: block
  }

  .stroll-clinic-online__card:first-child {
    margin-right: 0
  }
}

@media screen and (max-width:500px) {
  .stroll-clinic-online__cards__grid>* {
    flex-basis: 100%;
    margin-top: 30px
  }

  .stroll-clinic-online__cards__grid .stroll-clinic-online__card {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 290px
  }
}

.recommendations {
  background-color: #e3e5e2;
  background-position: 100%;
  height: 440px;
  padding-top: 79px;
  position: relative
}

.employees-videos {
  background-color: #e1e0e6;
  height: 527px
}

.recommendations[data-js-recommendations-bg=alina] {
  background-image: url(/build/i/bc62abddc762985edf6f.jpg)
}

.recommendations[data-js-recommendations-bg=anastasiya] {
  background-image: url(/build/i/91fd997755b3ba3aba99.jpg)
}

.recommendations[data-js-recommendations-bg=irina] {
  background-image: url(/build/i/2b14cff66294d21fdc01.jpg)
}

.recommendations[data-js-recommendations-bg=nikolai] {
  background-image: url(/build/i/5ffba93da870ca984fef.jpg)
}

.recommendations[data-js-recommendations-bg=olga] {
  background-image: url(/build/i/44eb035899186e00109c.jpg)
}

.recommendations[data-js-recommendations-bg=pashutin] {
  background-image: url(/build/i/fb85d250043db9fcf137.jpg)
}

.recommendations[data-js-recommendations-bg=todorenko] {
  background-image: url(/build/i/3f2ab2111fe0b875b45f.jpg)
}

.recommendations[data-js-recommendations-bg=viktor] {
  background-image: url(/build/i/68fd40a480f1bb488dc6.jpg)
}

.recommendations[data-js-recommendations-bg=vladimir] {
  background-image: url(/build/i/6e3c5d81a203183620a1.jpg)
}

.recommendations[data-js-recommendations-bg=koryakin-artyom-sergeevich] {
  background-image: url(/build/i/8c95909493d5c0efd9e8.jpg)
}

.recommendations[data-js-recommendations-bg=danilov-maksim-aleksandrovich] {
  background-image: url(/build/i/e2ee8cb554b0debfbb89.jpg)
}

.recommendations[data-js-recommendations-bg=slanova-alina-vladimirovna] {
  background-image: url(/build/i/216a5781b49c0f3a314d.jpg)
}

.recommendations[data-js-recommendations-bg=paska-evgenij-petrovich] {
  background-image: url(/build/i/4579d4306d14d1961d29.jpg)
}

.recommendations[data-js-recommendations-bg=gromihin-vladimir-aleksandrovich] {
  background-image: url(/build/i/8c9a98128d86c01f3286.jpg)
}

.recommendations[data-js-recommendations-bg=vasilenko-andrej-viktorovich] {
  background-image: url(/build/i/3c8a6992f04253848a1b.jpg)
}

.recommendations[data-js-recommendations-bg=gusak-viktoriya-nikolaevna] {
  background-image: url(/build/i/8c2ff1d81308989805cd.jpg)
}

.recommendations[data-js-recommendations-bg=phaladze-david-davidovich] {
  background-image: url(/build/i/a7deb7eacfa706a5e873.jpg)
}

.recommendations[data-js-recommendations-bg=ibragimova-kristina-sergeevna] {
  background-image: url(/build/i/7a403cf222b67f2bacec.jpg)
}

.recommendations[data-js-recommendations-bg=lavskij-evgenij-vladimirovich] {
  background-image: url(/build/i/70b804898fa1570f2288.jpg)
}

.recommendations__content {
  display: inline-block;
  position: relative
}

.recommendations__title {
  color: #333638;

  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 14px
}

.recommendations__title>span {
  color: #fd9500;

  font-style: normal;
  font-weight: 700
}

.recommendations__text {
  font-size: 16px;
  margin-bottom: 55px
}

.employees-videos .recommendations__text {
  margin-bottom: 34px
}

.recommendations__links>* {

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase
}

.recommendations__links>:first-child {
  margin-right: 40px
}

.employees-videos .recommendations__links {
  margin-bottom: 45px
}

.recommendations__play {
  height: 100px;
  position: absolute;
  right: -140px;
  top: 11px;
  width: 100px
}

.recommendations__play .circle-icon_big {
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: center;
  width: 100px
}

.recommendations__play .circle-icon_big:before {
  background-size: 32px 32px;
  height: 32px;
  margin-left: 0;
  width: 32px
}

.recommendations__persons {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 12px
}

.recommendations__persons>div {
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  top: 0;
  transition: all .1s ease-in;
  width: 11.11%
}

.recommendations__persons>div:hover {
  top: -5px;
  transition: all .1s ease-in
}

.recommendations__persons>div.active .progressive-image-wrapper:before {
  background-color: #ea9500;
  content: "";
  display: block;
  height: 100%;
  opacity: .3;
  position: absolute;
  width: 100%
}

.recommendations__persons .progressive-image-wrapper {
  border-radius: 50%;
  box-shadow: 0 11px 27px rgba(0, 0, 0, .15)
}

.recommendations__persons__name {
  color: #5f6877;
  font-size: 10px;
  margin-top: 10px;
  text-align: center;
  text-shadow: 1px 1px hsla(100, 5%, 89%, .5), 1px 1px hsla(100, 5%, 89%, .5), -1px -1px hsla(100, 5%, 89%, .5), -1px -1px hsla(100, 5%, 89%, .5), -1px 1px hsla(100, 5%, 89%, .5), 1px -1px hsla(100, 5%, 89%, .5), -1px 1px hsla(100, 5%, 89%, .5), 1px -1px hsla(100, 5%, 89%, .5);
  width: 100%
}

@media screen and (max-width:1215px) {
  .recommendations {
    background-position: -20px
  }
}

@media screen and (max-width:1120px) {
  .recommendations {
    background-position: -120px
  }

  .recommendations__persons>* {
    width: 14.28% !important
  }

  .recommendations__persons>:nth-last-child(-n+2) {
    display: none
  }
}

@media screen and (max-width:1023px) {
  .recommendations {
    background-image: none !important
  }

  .recommendations__persons>* {
    width: 16.66% !important
  }

  .recommendations__persons>:nth-last-child(-n+3) {
    display: none
  }
}

@media screen and (max-width:830px) {
  .recommendations__persons>* {
    width: 20% !important
  }

  .recommendations__persons>:nth-last-child(-n+4) {
    display: none
  }
}

@media screen and (max-width:725px) {
  .recommendations {
    background-color: #f4f3f8;
    background-image: url(/build/i/749a203b5d9cb0cb3cfd.png) !important;
    background-position: bottom;
    background-size: 405px;
    height: auto;
    min-height: 560px;
    padding-top: 62px;
    text-align: center
  }

  .recommendations.employees-videos {
    background-image: url(/build/i/5e789c9a24943661a30f.png) !important;
    background-size: 290px;
    height: auto;
    min-height: 595px
  }

  .recommendations.employees-videos .recommendations__play {
    top: 210px
  }

  .recommendations__title {
    font-size: 26px;
    margin-bottom: 14px
  }

  .recommendations__title br {
    display: none
  }

  .recommendations__title>span {
    font-size: 30px
  }

  .recommendations__text {
    font-size: 14px
  }

  .recommendations__persons {
    display: none
  }

  .recommendations__play {
    height: 66px;
    margin-right: -33px;
    right: 50%;
    top: 160px;
    width: 66px
  }

  .recommendations__play .circle-icon_big {
    height: 66px;
    width: 66px
  }

  .recommendations__play .circle-icon_big:before {
    background-size: 24px 26px;
    height: 26px;
    width: 26px
  }
}

@media screen and (max-width:375px) {
  .recommendations {
    background-image: url(/build/i/749a203b5d9cb0cb3cfd.png) !important;
    background-position-x: -8px
  }

  .recommendations.employees-videos {
    background-position-x: center
  }
}

@media screen and (max-width:320px) {
  .recommendations {
    background-position-x: -27px
  }

  .recommendations.employees-videos {
    background-image: url(/build/i/5e789c9a24943661a30f.png) !important
  }
}

.service-prices-table__desktop {
  margin-top: 75px
}

.service-prices-table__row {
  align-items: center;
  border-bottom: 1px solid #d8dfe1;
  display: flex;
  justify-content: space-between;
  min-height: 50px
}

.service-prices-table__row>* {
  color: #5f6877;
  font-size: 13px;
  line-height: 22px;
  min-width: 160px;
  padding-bottom: 6px;
  padding-top: 7px;
  text-align: center
}

.service-prices-table__row>:first-child {
  color: #a5a5a5;
  font-size: 13px;
  line-height: 18px;
  min-width: 210px;
  padding-left: 18px;
  text-align: left;
  width: 210px
}

.service-prices-table__row>.text-red {
  color: #d6322e
}

.service-prices-table__row>.text-grey {
  color: #5f6877
}

.service-prices-table__row>.text-green {
  color: #61a10f
}

.service-prices-table__row_highlighted {
  background-color: #e9f0f2;
  border-bottom: 1px solid transparent;
  border-top: 1px solid #e9f0f2;
  position: relative;
  top: -1px
}

.service-prices-table__row_header {
  background-color: #eaeff1;
  border-bottom: 0
}

.service-prices-table__row_header>* {
  padding-bottom: 17px;
  padding-top: 23px
}

.service-prices-table__row_header>:first-child {
  color: #a5a5a5;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding-left: 30px
}

.service-prices-table__row_header>.with-offer {
  position: relative
}

.service-prices-table__row_header>.with-offer:before {
  background-color: #f9fd98;
  border-radius: 50%;
  color: #677074;
  content: "%";

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  height: 25px;
  left: 50%;
  line-height: 26px;
  margin-left: -13px;
  position: absolute;
  text-align: center;
  top: -12px;
  width: 25px
}

.service-prices-table__header__link>span {
  color: #3c4143;
  display: inline-block;
  font-size: 18px;
  margin-bottom: 6px
}

.service-prices-table__header__link>span:first-child {
  border-bottom: 1px solid #3c4143;

  font-style: normal;
  font-weight: 500;
  transition: border .2s ease-in, color .2s ease-in
}

.service-prices-table__header__link>span:last-child {
  color: #828a92;
  display: inline-block;
  font-size: 12px;
  line-height: 16px
}

.service-prices-table__header__link:hover>span:first-child {
  border-bottom: 1px solid #000 !important;
  color: #000;
  transition: border .2s ease-in, color .2s ease-in
}

span.service-prices-table__header__link>span:first-child {
  border-bottom: 1px solid transparent
}

span.service-prices-table__header__link:hover>span:first-child {
  border-bottom: 1px solid transparent !important;
  color: #3c4143
}

.service-prices-table__plus {
  background-image: url(/build/i/d7fa79439ce00e241bdf.svg)
}

.service-prices-table__minus,
.service-prices-table__plus {
  background-size: contain;
  display: inline-block;
  height: 13px;
  width: 13px
}

.service-prices-table__minus {
  background-image: url(/build/i/818652c1992e8151345c.svg)
}

.service-prices-table__row_ratings .rate>svg {
  height: 11px;
  width: 11px
}

.service-prices-table__offer {
  background-color: #f9fd98;
  border-radius: 13px;
  color: #3c4143;
  display: inline-block;
  font-size: 13px;
  line-height: 20px;
  margin-top: 10px;
  padding: 3px 23px
}

.service-prices-table__row_service>div {
  padding-bottom: 17px;
  padding-top: 16px
}

.service-prices-table__old-price {
  color: #a5a5a5;
  font-size: 13px;
  line-height: 28px;
  text-decoration: line-through
}

.service-prices-table__new-price {
  color: #3c4143;
  font-size: 16px;
  line-height: 28px
}

.service-prices-table__note {
  color: #a5a5a5;

  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  margin-top: 25px;
  padding-left: 18px
}

.service-prices-table__expandable {
  overflow: hidden;
  transition: max-height .2s ease-out
}

.service-prices-table__expandable.shown {
  max-height: 1000px !important;
  transition: max-height .2s ease-in
}

.service-prices-table__toggler {
  display: block;
  font-size: 14px;
  line-height: 18px;
  margin-top: 20px;
  padding-bottom: 17px;
  padding-left: 18px
}

@media screen and (max-width:1359px) {
  .service-prices-table__desktop {
    display: none
  }
}

.service-prices-table__tablet {
  display: none;
  margin-top: 55px
}

@media screen and (max-width:1359px) {
  .service-prices-table__tablet {
    display: block
  }
}

.service-prices-table__mobile {
  display: none;
  margin-top: 45px
}

@media screen and (max-width:767px) {

  .service-prices-table h2 span,
  .service-prices-table__tablet {
    display: none
  }

  .service-prices-table__mobile {
    display: block;
    margin-bottom: 50px
  }

  .service-prices-table__mobile .slider-cost-of__slide {
    background-color: #eef3f6;
    margin-left: -15px;
    margin-right: -15px;
    overflow: hidden;
    position: relative
  }

  .attainment-image-slider-wrapper_allon4 .service-prices-table__mobile .attainment-image-slider,
  .offer_standard .service-prices-table__mobile .offer__card:before,
  .service-prices-table__mobile .appointment__inner,
  .service-prices-table__mobile .attainment-image-slider-wrapper_allon4 .attainment-image-slider,
  .service-prices-table__mobile .bg_dots,
  .service-prices-table__mobile .cards-with-text:before,
  .service-prices-table__mobile .director-quote__right:before,
  .service-prices-table__mobile .is-suitable.bg_dots-half:before,
  .service-prices-table__mobile .main-page-header__badge:before,
  .service-prices-table__mobile .offer_standard .offer__card:before,
  .service-prices-table__mobile .payment-form,
  .service-prices-table__mobile .through-block-short .through-block_hold-prices,
  .service-prices-table__mobile .with-image-slider-wrapper_dots,
  .service-prices-table__mobile .works-slider__item,
  .through-block-short .service-prices-table__mobile .through-block_hold-prices {
    display: block
  }

  .service-prices-table__mobile .slider-cost-of__slide__description {
    flex-basis: 300px !important;
    margin-right: auto !important
  }

  .service-prices-table__mobile .slider-cost-of__slide__description .button,
  .service-prices-table__mobile .slider-cost-of__slide__description .slide-tab__label.active,
  .service-prices-table__mobile .slider-cost-of__slide__description .tabs__labels.tabs__labels_buttons .tab__label.active,
  .tabs__labels.tabs__labels_buttons .service-prices-table__mobile .slider-cost-of__slide__description .tab__label.active {
    margin-top: 35px
  }

  .service-prices-table__mobile .slider-cost-of__slide__prices__end {
    font-size: 13px;
    padding: 6px 10px 5px
  }

  .service-prices-table__tabs__labels-wrapper {
    margin-left: -5px;
    margin-right: -5px
  }

  .service-prices-table__tabs__labels {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 18px
  }

  .service-prices-table__tabs__labels .tab__label {
    margin-left: 5px !important;
    margin-right: 5px !important;
    padding-left: 15px !important;
    padding-right: 15px !important
  }

  .service-prices-table__mobile__link {
    margin-top: 35px;
    text-align: center
  }

  .service-prices-table__mobile__link .link,
  .service-prices-table__mobile__link .slide-tab__label,
  .service-prices-table__mobile__link .tabs__labels.tabs__labels_buttons .tab__label,
  .service-prices-table__mobile__link [data-js=tooltip],
  .tabs__labels.tabs__labels_buttons .service-prices-table__mobile__link .tab__label {
    font-size: 14px
  }
}

.cure-teeth__title {
  color: #333638;

  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 100px;
  text-align: center
}

.cure-teeth-grid {
  margin-bottom: 40px
}

.cure-teeth-grid>div {
  color: #989ca6;
  font-size: 14px;
  line-height: 26px;
  text-align: center
}

.cure-teeth__icon {
  border-radius: 50%;
  display: block;
  height: 170px;
  margin-bottom: 42px;
  width: 170px
}

.cure-teeth__icon_treatment {
  background-image: url(/build/i/0204113c34dc4bc5f664.jpg)
}

.cure-teeth__icon_cleaning {
  background-image: url(/build/i/dbd44873cb64f34cd286.jpg)
}

.cure-teeth__icon_deletion {
  background-image: url(/build/i/a52dc373555fd2d69f4a.jpg)
}

.cure-teeth__icon_whitening {
  background-image: url(/build/i/3749ac1cf3850a39e9a7.jpg)
}

.cure-teeth__icon_pulpitis {
  background-image: url(/build/i/199800b5977938010298.jpg)
}

.cure-teeth__icon_deletion-pain {
  background-image: url(/build/i/eba488a55ffd64e432af.jpg)
}

.cure-teeth__icon_gum-treatment {
  background-image: url(/build/i/d3b9dbe23e686853b76b.jpg)
}

.cure-teeth-grid__title {
  display: inline-block;
  margin-bottom: 20px;
  text-transform: uppercase
}

.cure-teeth-grid__price,
.cure-teeth-grid__title {

  font-style: normal;
  font-weight: 500
}

.cure-teeth-grid__price {
  color: #5f6877;
  font-size: 16px;
  line-height: 24px;
  margin-top: 13px
}

@media screen and (max-width:1287px) {
  .cure-teeth-grid>* {
    flex-basis: 50%;
    margin-bottom: 50px
  }

  .cure-teeth__icon {
    margin-bottom: 15px
  }
}

@media screen and (max-width:1023px) {
  .cure-teeth-grid {
    margin-left: -12px;
    margin-right: -12px
  }

  .cure-teeth-grid>* {
    flex-basis: calc(50% - 36px);
    margin-left: 12px;
    margin-right: 12px
  }
}

@media screen and (max-width:767px) {
  .cure-teeth-grid {
    margin-bottom: 0;
    margin-left: -15px;
    margin-right: -15px
  }

  .cure-teeth-grid>* {
    flex-basis: calc(100% - 30px);
    margin-left: 15px;
    margin-right: 15px
  }

  .cure-teeth-grid br {
    display: none
  }
}

@media screen and (max-width:550px) {
  .cure-teeth__title {
    font-size: 26px;
    margin-bottom: 30px
  }

  .cure-teeth-grid {
    margin-left: -15px;
    margin-right: -15px
  }

  .cure-teeth-grid>* {
    flex-basis: calc(100% - 30px)
  }

  .cure-teeth__icon {
    height: 120px;
    width: 120px
  }
}

.counseling-header {
  min-height: 398px;
  padding-bottom: 27px;
  padding-top: 27px
}

.counseling-header .breadcrumbs {
  max-width: 100%
}

.counseling-header .h1 {
  display: inline-block;
  max-width: 720px
}

.counseling-header-common {
  color: #a5a5a5;
  font-size: 16px;
  line-height: 26px;
  margin-top: 20px
}

.counseling-question {
  margin-top: 35px
}

.counseling-question__person {
  color: #a5a5a5;
  margin-bottom: 14px
}

.counseling-question__person,
.counseling-question__text {
  font-size: 14px;
  line-height: 24px
}

.counseling-answer-ribbon {
  background-color: #e4edef;
  min-height: 112px;
  padding-bottom: 10px;
  padding-top: 16px
}

.counseling-answer-ribbon .button,
.counseling-answer-ribbon .slide-tab__label.active,
.counseling-answer-ribbon .tabs__labels.tabs__labels_buttons .tab__label.active,
.tabs__labels.tabs__labels_buttons .counseling-answer-ribbon .tab__label.active {
  float: right;
  position: relative;
  top: 10px
}

.counseling-answer-ribbon__employee {
  align-items: center;
  display: flex;
  float: left;
  justify-content: flex-start
}

.counseling-answer-ribbon__employee>a {
  float: left;
  padding-right: 24px
}

.counseling-answer-ribbon__employee .progressive-image-wrapper {
  border-radius: 50%
}

.counseling-answer-ribbon__employee__info {
  color: #333638;
  font-size: 16px;
  line-height: 22px;
  position: relative;
  top: -3px
}

.counseling-answer-ribbon__employee__title {
  color: #5f6877;
  font-size: 14px;
  line-height: 28px
}

.counseling-answer__employee {
  background-color: #eef2f4;
  border-radius: 10px;
  color: #5f6877;
  display: block;
  margin-bottom: 24px;
  min-height: 494px;
  overflow: hidden;
  padding: 45px 30px;
  position: relative;
  width: 286px
}

.counseling-answer__employee__specialisations>* {
  display: inline-block;
  margin-bottom: 11px
}

.counseling-answer__employee__activity {
  color: #a5a5a5;
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 16px
}

.counseling-answer__employee__fio {
  color: #3c4143;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 22px
}

.counseling-answer__employee__feature {
  font-size: 14px;
  height: 60px;
  line-height: 20px;
  max-width: 320px
}

.counseling-answer__employee__image {
  bottom: -30px;
  height: 242px !important;
  left: 50% !important;
  margin-left: -139px !important;
  position: absolute;
  right: -125px;
  width: 278px !important
}

.counseling-answer {
  margin-top: 64px
}

.counseling-answer__text {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 14px
}

.counseling-answer__text p {
  margin-bottom: 25px
}

.counseling-answer__grid {
  align-items: flex-start;
  display: flex
}

.counseling-answer__grid>:last-child {
  flex: none
}

.counseling-answer__text {
  flex-basis: auto;
  padding-right: 60px
}

@media screen and (max-width:1215px) {
  .counseling-header {
    padding-bottom: 50px
  }

  .counseling-answer-ribbon {
    padding-bottom: 35px;
    padding-top: 20px;
    text-align: center
  }

  .counseling-answer-ribbon .button,
  .counseling-answer-ribbon .slide-tab__label.active,
  .counseling-answer-ribbon .tabs__labels.tabs__labels_buttons .tab__label.active,
  .tabs__labels.tabs__labels_buttons .counseling-answer-ribbon .tab__label.active {
    float: none;
    margin-bottom: 2px;
    margin-top: 20px;
    top: 0
  }

  .counseling-answer-ribbon__employee {
    float: none;
    padding-top: 45px;
    position: relative
  }

  .counseling-answer-ribbon__employee>a {
    left: 50%;
    margin-left: -52px;
    position: absolute;
    top: -60px
  }

  .counseling-answer-ribbon__employee__info {
    width: 100%
  }
}

@media screen and (max-width:900px) {
  .counseling-answer__grid {
    flex-wrap: wrap
  }

  .counseling-answer__grid>:last-child {
    display: none
  }

  .counseling-answer__text {
    flex-basis: 100%;
    padding-right: 0
  }

  .counseling-answer .booster-block {
    margin-left: auto;
    margin-right: auto
  }
}

@media screen and (max-width:767px) {
  .counseling-answer {
    margin-top: 40px
  }

  .counseling-answer__text {
    font-size: 14px
  }
}

.counselings-list__title {
  margin-bottom: 39px;
  text-align: left
}

.counselings-list__more {
  margin-top: 56px;
  text-align: center
}

.counselings-list__item {
  border: 1px solid #e0e3e4;
  border-radius: 10px;
  margin-bottom: 33px;
  padding: 50px
}

.counselings-list__item__person {
  color: #a5a5a5;
  font-size: 14px;
  line-height: 24px
}

.counselings-list__item__title {
  color: #333638;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 15px
}

.counselings-list__item__text {
  font-size: 14px;
  line-height: 24px
}

.counselings-list__item__links {
  margin-top: 38px
}

.counselings-list__item__links .button,
.counselings-list__item__links .slide-tab__label.active,
.counselings-list__item__links .tabs__labels.tabs__labels_buttons .tab__label.active,
.tabs__labels.tabs__labels_buttons .counselings-list__item__links .tab__label.active {
  float: left;
  margin-right: 38px
}

.counselings-list__item__employee {
  align-items: center;
  display: flex;
  float: left;
  justify-content: flex-start;
  position: relative;
  top: 2px
}

.counselings-list__item__employee>.counselings-list__item__employee__img {
  float: left;
  margin-right: 18px;
  min-width: 40px
}

.counselings-list__item__employee .progressive-image {
  border-radius: 50%
}

.counselings-list__item__employee__info {
  color: #819292;
  font-size: 14px;
  line-height: 24px
}

.counselings-list__item__employee__info .link,
.counselings-list__item__employee__info .slide-tab__label,
.counselings-list__item__employee__info .tabs__labels.tabs__labels_buttons .tab__label,
.counselings-list__item__employee__info [data-js=tooltip],
.tabs__labels.tabs__labels_buttons .counselings-list__item__employee__info .tab__label {
  font-size: 16px
}

.counselings-list__item__show-button {
  min-width: 158px
}

.counselings-list__item__answer {
  display: none
}

.counselings-list__item__answer p {
  font-size: 15px;
  line-height: 24px;
  margin-top: 20px
}

.counselings-list__item__answer p:first-child {
  margin-top: 35px
}

@media screen and (max-width:1023px) {
  .counselings-list__item__links {
    display: flex;
    flex-wrap: wrap
  }

  .counselings-list__item__links>:first-child {
    margin-top: 25px;
    order: 2
  }

  .counselings-list__item__links>:last-child {
    order: 1;
    width: 100%
  }
}

@media screen and (max-width:767px) {
  .counselings-list__title {
    text-align: center
  }

  .counselings-list__content {
    margin-left: -15px;
    margin-right: -15px
  }

  .counselings-list__item {
    border-bottom: 0;
    border-left: 0;
    border-radius: 0;
    border-right: 0;
    margin-bottom: 0;
    padding-left: 15px;
    padding-right: 15px
  }

  .counselings-list__item:last-child {
    border-bottom: 1px solid #e0e3e4
  }

  .counselings-list__item__employee {
    align-items: flex-start
  }

  .counselings-list__item__employee__info .link,
  .counselings-list__item__employee__info .slide-tab__label,
  .counselings-list__item__employee__info .tabs__labels.tabs__labels_buttons .tab__label,
  .counselings-list__item__employee__info [data-js=tooltip],
  .tabs__labels.tabs__labels_buttons .counselings-list__item__employee__info .tab__label {
    font-size: 14px
  }
}

.choice-of-two {
  margin-bottom: -135px;
  position: relative
}

.choice-of-two__gray-bg {
  background-color: #f2f6f8;
  min-height: 577px;
  padding-top: 195px;
  position: relative;
  top: -135px;
  width: 100%;
  z-index: 0
}

.choice-of-two__title {
  margin-bottom: 52px
}

.choice-of-two__grid {
  justify-content: space-between;
  margin-left: -40px;
  margin-right: -40px;
  position: relative;
  z-index: 1
}

.choice-of-two__grid>* {
  background-color: #fff;
  background-position: center 20px;
  border-radius: 10px;
  box-shadow: 0 20px 43px 3px rgba(0, 0, 0, .08);
  flex-basis: calc(50% - 80px);
  margin-left: 40px;
  margin-right: 40px;
  min-height: 375px;
  padding: 150px 55px 55px
}

.choice-of-two__grid>:first-child {
  background-image: url(/build/i/d74ee406d112c699ee5e.jpg)
}

.choice-of-two__grid>:last-child {
  background-image: url(/build/i/83c0b36c52812562c4f5.jpg)
}

.choice-of-two__grid__title {

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 7px;
  text-align: center
}

.choice-of-two__grid__text {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 26px;
  text-align: center
}

.choice-of-two__grid__grid {
  display: flex;
  justify-content: space-between
}

.choice-of-two__grid__list {
  color: #a0a9b7;
  font-size: 15px;
  line-height: 24px;
  list-style: none;
  padding-left: 0
}

.choice-of-two__grid__list li {
  margin-bottom: 11px
}

.choice-of-two__grid__list .link,
.choice-of-two__grid__list .slide-tab__label,
.choice-of-two__grid__list .tabs__labels.tabs__labels_buttons .tab__label,
.choice-of-two__grid__list [data-js=tooltip],
.tabs__labels.tabs__labels_buttons .choice-of-two__grid__list .tab__label {
  color: #a0a9b7
}

.choice-of-two__grid__list__like {
  left: 1px;
  position: relative;
  top: -2px
}

.choice-of-two__buttons {
  margin-bottom: 72px;
  max-width: 640px
}

.choice-of-two__buttons>:first-child {
  float: left
}

.choice-of-two__buttons>:last-child {
  float: right
}

.choice-of-two__buttons__phone {
  font-size: 24px;
  line-height: 24px;
  padding-left: 30px;
  padding-right: 30px;
  text-align: center
}

.choice-of-two__buttons__phone:hover {
  background-color: #fff
}

.choice-of-two__buttons__phone small {
  color: #a5a5a5;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-transform: none
}

.choice-of-two__works-title {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 13px;
  text-align: center
}

.choice-of-two__works-text {
  color: #333638;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center
}

@media screen and (max-width:1280px) {
  .choice-of-two__grid {
    margin-left: -10px;
    margin-right: -10px
  }

  .choice-of-two__grid>* {
    flex-basis: calc(50% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 35px;
    padding-right: 35px
  }

  .choice-of-two__grid__grid>* {
    flex-basis: 50%
  }

  .choice-of-two__grid__grid>:last-child {
    padding-left: 30px
  }
}

@media screen and (max-width:1023px) {
  .choice-of-two__grid {
    padding-bottom: 30px
  }

  .choice-of-two__grid__grid {
    display: block;
    text-align: center
  }

  .choice-of-two__grid__grid>:last-child {
    padding-left: 0
  }

  .choice-of-two__grid__list li {
    margin-bottom: 9px
  }
}

@media screen and (max-width:767px) {
  .choice-of-two {
    margin-bottom: -209px
  }

  .choice-of-two__grid>* {
    margin-bottom: 28px;
    padding-bottom: 30px;
    padding-left: 5px;
    padding-right: 5px
  }

  .choice-of-two__gray-bg {
    top: -209px
  }

  .choice-of-two__buttons {
    margin-bottom: 40px;
    text-align: center
  }

  .choice-of-two__buttons__phone {
    margin-bottom: 19px
  }

  .choice-of-two__works-title {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 9px
  }

  .choice-of-two__works-text {
    font-size: 18px;
    line-height: 26px
  }
}

@media screen and (max-width:700px) {
  .choice-of-two__grid>* {
    flex-basis: calc(100% - 20px)
  }
}

@media screen and (max-width:605px) {

  .choice-of-two__buttons .button,
  .choice-of-two__buttons .slide-tab__label.active,
  .choice-of-two__buttons .tabs__labels.tabs__labels_buttons .tab__label.active,
  .tabs__labels.tabs__labels_buttons .choice-of-two__buttons .tab__label.active {
    float: none !important;
    width: 100%
  }
}

.choice-of-three {
  position: relative
}

.choice-of-three__title {
  margin-bottom: 52px
}

.choice-of-three__grid {
  display: flex;
  justify-content: space-between;
  margin-left: -15px;
  margin-right: -15px;
  position: relative;
  z-index: 1
}

.choice-of-three__grid>* {
  background-color: #fff;
  background-position: center 20px;
  border-radius: 10px;
  box-shadow: 0 20px 43px 3px rgba(0, 0, 0, .08);
  flex: 1 0 50%;
  flex-basis: calc(50% - 30px);
  margin-left: 15px;
  margin-right: 15px;
  min-height: 375px;
  padding: 150px 55px 55px
}

.choice-of-three__grid>:first-child {
  background-image: url(/build/i/d74ee406d112c699ee5e.jpg)
}

.choice-of-three__grid>:nth-child(2) {
  background-image: url(/build/i/83c0b36c52812562c4f5.jpg)
}

.choice-of-three__grid>:nth-child(3) {
  background-image: url(/build/i/759e475129b6ca1c3a92.jpg)
}

.choice-of-three__grid>:nth-child(2),
.choice-of-three__grid>:nth-child(3) {
  flex-basis: calc(25% - 30px);
  max-width: 287px
}

.choice-of-three__grid>:nth-child(2) .choice-of-three__grid__text,
.choice-of-three__grid>:nth-child(2) .choice-of-three__grid__title,
.choice-of-three__grid>:nth-child(3) .choice-of-three__grid__text,
.choice-of-three__grid>:nth-child(3) .choice-of-three__grid__title {
  text-align: left
}

.choice-of-three__grid__title {

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 7px;
  text-align: center
}

.choice-of-three__grid__text {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 26px;
  text-align: center
}

.choice-of-three__grid__grid {
  display: flex;
  justify-content: space-between
}

.choice-of-three__grid__list {
  color: #a0a9b7;
  font-size: 15px;
  line-height: 24px;
  list-style: none;
  padding-left: 0
}

.choice-of-three__grid__list li {
  margin-bottom: 11px
}

.choice-of-three__grid__list .link,
.choice-of-three__grid__list .slide-tab__label,
.choice-of-three__grid__list .tabs__labels.tabs__labels_buttons .tab__label,
.choice-of-three__grid__list [data-js=tooltip],
.tabs__labels.tabs__labels_buttons .choice-of-three__grid__list .tab__label {
  color: #a0a9b7
}

.choice-of-three__grid__list__like {
  left: 1px;
  position: relative;
  top: -2px
}

@media screen and (max-width:1280px) {
  .choice-of-three__grid {
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -10px;
    margin-right: -10px
  }

  .choice-of-three__grid>* {
    flex-basis: calc(50% - 20px);
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 35px;
    padding-right: 35px
  }

  .choice-of-three__grid>:nth-child(2),
  .choice-of-three__grid>:nth-child(3) {
    flex-basis: calc(50% - 20px);
    max-width: 50%
  }

  .choice-of-three__grid__grid>* {
    flex-basis: 50%
  }

  .choice-of-three__grid__grid>:nth-child(2) {
    padding-left: 30px
  }
}

@media screen and (max-width:1023px) {
  .choice-of-three__grid {
    padding-bottom: 30px
  }

  .choice-of-three__grid>:nth-child(2) .choice-of-three__grid__text,
  .choice-of-three__grid>:nth-child(2) .choice-of-three__grid__title,
  .choice-of-three__grid>:nth-child(3) .choice-of-three__grid__text,
  .choice-of-three__grid>:nth-child(3) .choice-of-three__grid__title {
    text-align: center
  }

  .choice-of-three__grid__grid {
    display: block;
    text-align: center
  }

  .choice-of-three__grid__grid>:nth-child(2) {
    padding-left: 0
  }

  .choice-of-three__grid__list li {
    margin-bottom: 9px
  }
}

@media screen and (max-width:767px) {
  .choice-of-three__grid>* {
    flex-basis: 100%;
    margin-bottom: 28px;
    padding-bottom: 30px;
    padding-left: 5px;
    padding-right: 5px
  }

  .choice-of-three__grid>:nth-child(2),
  .choice-of-three__grid>:nth-child(3) {
    flex-basis: 100%;
    max-width: 100%
  }
}

.champions-teaser {
  background-image: url(/build/i/d91082e9fa9c01a9e3f2.png);
  line-height: 24px;
  min-height: 133px;
  padding-left: 106px;
  padding-top: 25px
}

.champions-teaser__link {

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase
}

.champions-teaser__text {
  font-size: 14px
}

@media screen and (max-width:1280px) {
  .champions-teaser__link {
    font-size: 14px
  }

  .champions-teaser__text {
    font-size: 12px;
    line-height: 20px;
    margin-top: 8px
  }

  .champions-teaser__text br {
    display: none
  }
}

@media screen and (max-width:1023px) {
  .champions-teaser__text {
    font-size: 14px;
    line-height: 24px;
    margin-top: 0
  }

  .champions-teaser__text br {
    display: block
  }
}

@media screen and (max-width:767px) {
  .champions-teaser {
    background-position: top;
    margin-bottom: 30px;
    padding-left: 0;
    padding-top: 120px;
    text-align: center
  }

  .champions-teaser__text {
    display: none
  }
}

.modal__prosthetics-mv {
  display: flex;
  flex-wrap: nowrap
}

.modal__prosthetics-mv .modal__title {
  margin-bottom: 11px
}

.prosthetics-mv-image {
  flex: none
}

.prosthetics-mv-text {
  padding-left: 55px;
  text-align: left
}

.prosthetics-mv-text .minus-list,
.prosthetics-mv-text .plus-list {
  margin-bottom: 18px
}

.prosthetics-mv-text__stats {
  color: #898989;
  font-size: 14px;
  font-style: italic;
  line-height: 22px;
  margin-bottom: 23px
}

.prosthetics-mv-text__link {
  display: inline-block;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-top: 17px;
  text-transform: uppercase
}

@media screen and (max-width:768px) {
  .modal__prosthetics-mv {
    flex-wrap: wrap
  }

  .prosthetics-mv-image {
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto
  }

  .prosthetics-mv-text {
    padding-left: 0
  }
}

@media screen and (max-width:767px) {
  .prosthetics-mv-image {
    margin-bottom: 20px;
    width: 100% !important
  }

  .prosthetics-mv-text__stats {
    flex-wrap: wrap;
    justify-content: flex-start
  }

  .prosthetics-mv-text__stats>* {
    flex-basis: 100%
  }

  .prosthetics-mv-text__link {
    display: flex;
    justify-content: center;
    margin-top: 35px
  }
}

.attainment-image-slider-wrapper_allon4 .compare.attainment-image-slider,
.compare.appointment__inner,
.compare.bg_dots,
.compare.cards-with-text:before,
.compare.director-quote__right:before,
.compare.is-suitable.bg_dots-half:before,
.compare.main-page-header__badge:before,
.compare.payment-form,
.compare.with-image-slider-wrapper_dots,
.compare.works-slider__item,
.offer_standard .compare.offer__card:before,
.through-block-short .compare.through-block_hold-prices {
  padding-top: 88px
}

.compare .what-is-included__button {
  margin-bottom: 0;
  margin-top: 40px
}

.compare__grid {
  margin-bottom: 50px;
  margin-left: -15px;
  margin-right: -15px
}

.compare__grid>div {
  padding-left: 15px;
  padding-right: 15px
}

.compare__list {
  margin-top: 65px
}

.compare__header {
  color: #5f6877;

  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 20px;
  text-transform: uppercase
}

.compare_small-headers .compare__header {

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-transform: none
}

.compare__notification {
  background-color: #e8f0f3;
  border-radius: 10px;
  color: #5f6877;
  line-height: 28px;
  padding: 23px 60px 24px 115px
}

.compare__notification>span {

  font-size: 18px;
  font-style: normal;
  font-weight: 500
}

.compare__notification_alert {
  background-image: url(/build/i/0d566945ced24696fc15.svg);
  background-position: center left 60px
}

@media screen and (max-width:767px) {
  .compare__notification {
    border-radius: 0;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 85px;
    padding-right: 30px
  }

  .compare__notification_alert {
    background-position: center left 25px
  }

  .compare__list {
    margin-top: 50px
  }
}

@media screen and (max-width:550px) {
  .compare__notification {
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 68px;
    text-align: center
  }

  .compare__notification>span {
    font-size: 14px
  }

  .compare__notification_alert {
    background-position: top 25px center
  }

  .compare__list {
    margin-top: 35px
  }

  .compare__header {
    font-size: 14px
  }

  .compare__header br {
    display: none
  }
}

.reasons__grid {
  margin: 90px -15px 50px
}

.reasons__grid>div {
  color: #5f6877;
  margin-bottom: 50px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center
}

.reasons__grid>div>.progressive-image-wrapper,
.reasons__image {
  margin-bottom: 28px
}

.reasons__title {

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 5px;
  text-transform: uppercase
}

.reasons__text {
  font-size: 14px;
  line-height: 22px
}

.warranty-block .reasons__grid {
  margin-bottom: 5px;
  margin-top: 50px
}

.warranty-block .reasons__image {
  margin-bottom: 10px
}

.warranty-block__text {
  color: #5f6877;
  line-height: 28px;
  margin-top: 20px;
  text-align: center
}

.warranty-block__buttons {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 40px
}

.warranty-block__buttons>button {
  margin: 0 15px
}

@media screen and (max-width:1023px) {
  .reasons__grid {
    margin-top: 50px
  }

  .reasons__grid>div {
    flex: 1 0 50%
  }

  .reasons__text br {
    display: none
  }
}

@media screen and (max-width:567px) {
  .reasons__grid>div {
    flex: 1 0 100%
  }

  .warranty-block__buttons {
    flex-wrap: wrap
  }

  .warranty-block__buttons>button {
    margin-top: 20px
  }
}

.is-suitable {
  background-color: #f2f6f8;
  background-position: top -4px center
}

.is-suitable.bg_dots-half {
  position: relative
}

.is-suitable.bg_dots-half:before {
  background-position: top -10px left 10px;
  content: "";
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 50%
}

.is-suitable_with-note .is-suitable__grid {
  padding-bottom: 56px;
  padding-top: 56px
}

.is-suitable__employee__about {
  background-color: #fff;
  border-radius: 10px;
  bottom: -34px;
  box-shadow: 0 10px 29px rgba(70, 81, 86, .18);
  max-width: 330px;
  padding: 15px 30px 14px 82px;
  position: absolute;
  right: 15px;
  width: 100%;
  z-index: 2
}

.is-suitable__employee__about>* {
  color: #5f6877;
  font-size: 13px;
  line-height: 18px
}

.is-suitable__employee__about>:first-child {

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 4px
}

.is-suitable__employee__about i {
  display: block;
  left: 12px;
  max-width: 60px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%
}

.is-suitable__employee__about_without-icon {
  padding-left: 19px
}

.is-suitable__employee__about_without-icon i {
  display: none
}

.is-suitable_warranty .is-suitable__grid>div:first-child {
  max-width: 380px
}

.is-suitable__grid {
  margin-left: -15px;
  margin-right: -15px;
  padding: 40px 0;
  position: relative
}

.is-suitable__grid>div {
  padding-left: 15px;
  padding-right: 15px
}

.is-suitable__grid>div:first-child {
  max-width: 340px
}

.is-suitable__grid>div:nth-child(2) {
  min-width: 550px
}

.is-suitable__grid>div:nth-child(3) {
  max-width: 325px
}

.is-suitable__image {
  border-radius: 0;
  bottom: 0;
  display: block;
  position: absolute;
  right: 90px
}

.is-suitable__text {
  color: #5f6877;
  font-size: 18px;
  line-height: 28px
}

.is-suitable__text>div {

  font-size: 24px;
  font-style: normal;
  font-weight: 500
}

@media screen and (max-width:1329px) {
  .is-suitable__grid {
    justify-content: space-between
  }

  .is-suitable__grid>div {
    flex: 0 0 auto
  }

  .is-suitable__grid>div:nth-child(2) {
    min-width: auto
  }

  .is-suitable__grid>div:nth-child(3) {
    display: none
  }

  .is-suitable.bg_dots-half:before {
    width: 35%
  }

  .is-suitable_with-note .is-suitable__grid {
    justify-content: space-between;
    padding-bottom: 50px;
    padding-top: 49px
  }

  .is-suitable_with-note .is-suitable__grid>div {
    flex: 0 0 auto
  }

  .is-suitable_with-note .is-suitable__grid>div:nth-child(2) {
    max-width: 280px;
    min-width: auto
  }

  .is-suitable_with-note .is-suitable__grid>div:nth-child(3) {
    display: block;
    flex-basis: 310px
  }

  .is-suitable_with-note .is-suitable__text {
    font-size: 16px;
    line-height: 28px
  }

  .is-suitable_with-note .is-suitable__text>div {
    font-size: 22px
  }

  .is-suitable_with-note .is-suitable__image {
    bottom: 35px;
    right: 55px
  }

  .is-suitable_with-note .button_with-plus {
    font-size: 14px;
    height: 69px;
    line-height: 20px;
    padding-left: 48px;
    padding-right: 20px;
    text-align: left
  }

  .is-suitable_with-note .button_with-plus__icon-plus {
    left: 19px
  }

  .is-suitable__employee__about {
    bottom: -55px;
    max-width: 290px;
    padding-left: 82px;
    padding-right: 20px
  }

  .is-suitable__employee__about i {
    left: 10px
  }

  .is-suitable__employee__about_without-icon {
    bottom: -20px;
    padding-left: 20px
  }
}

@media screen and (max-width:930px) {
  .is-suitable__grid {
    justify-content: center;
    padding-bottom: 0
  }

  .is-suitable__grid>div {
    flex: 0 0 100%;
    margin-bottom: 20px;
    text-align: center
  }

  .is-suitable__grid>div:nth-child(3) {
    display: block;
    margin-bottom: 0;
    max-width: 265px;
    position: relative
  }

  .is-suitable__image {
    bottom: auto;
    position: relative;
    right: auto
  }

  .is-suitable.bg_dots-half:before {
    width: 100%
  }

  .is-suitable_with-note .is-suitable__grid {
    justify-content: center;
    padding-bottom: 0
  }

  .is-suitable_with-note .is-suitable__grid>div {
    flex: 0 0 auto
  }

  .is-suitable_with-note .is-suitable__grid>div:nth-child(2) {
    max-width: 100%;
    min-width: auto
  }

  .is-suitable_with-note .is-suitable__grid>div:nth-child(3) {
    display: block;
    flex-basis: 100%;
    max-width: 100%
  }

  .is-suitable_with-note .is-suitable__image {
    bottom: 0;
    left: 50%;
    right: auto;
    transform: translateX(-50%)
  }

  .is-suitable_with-note .button_with-plus {
    height: 51px
  }

  .is-suitable__employee__about {
    bottom: -35px;
    left: 50%;
    margin-left: -145px;
    padding-bottom: 11px;
    padding-top: 12px;
    right: auto;
    text-align: left
  }

  .is-suitable__employee__about>* {
    font-size: 12px;
    line-height: 15px
  }
}

@media screen and (max-width:767px) {
  .is-suitable__text {
    font-size: 16px
  }

  .is-suitable__text>div {
    font-size: 18px
  }

  .is-suitable_with-note .is-suitable__grid {
    padding-top: 34px
  }

  .is-suitable_with-note .is-suitable__image {
    bottom: 0;
    left: 50%;
    right: auto;
    transform: translateX(-50%)
  }

  .is-suitable_with-note .button_with-plus {
    font-size: 12px;
    line-height: 16px;
    margin-left: auto;
    margin-right: auto;
    max-width: 290px;
    padding-left: 84px
  }

  .is-suitable_with-note .button_with-plus__icon-plus {
    left: 32px
  }
}

.links-block__h2 {
  margin-bottom: 40px
}

.links-block__text {
  line-height: 30px;
  margin: 0 auto 33px;
  max-width: 1000px;
  text-align: center
}

.links-block__grid {
  margin-left: -10px;
  margin-right: -10px
}

.links-block__grid>* {
  padding-left: 10px;
  padding-right: 10px
}

.links-block__grid>.links-block__item {
  flex-basis: 23%;
  padding-left: 10px;
  padding-right: 10px
}

.links-block__grid>.links-block__item:nth-child(2),
.links-block__grid>.links-block__item:nth-child(3) {
  flex-basis: 27%
}

.links-block__item {
  list-style: none;
  padding-left: 0
}

.links-block__item>li {
  margin-top: 17px
}

.manufacturers-list .links-block__text {
  margin-bottom: 15
}

.manufacturers-list .main-services__title {
  margin-bottom: 10px;
  margin-top: 50px
}

@media screen and (max-width:1023px) {

  .links-block__grid>*,
  .links-block__grid>.links-block__item,
  .links-block__grid>.links-block__item:nth-child(2),
  .links-block__grid>.links-block__item:nth-child(3),
  .links-block__grid>:nth-child(2),
  .links-block__grid>:nth-child(3) {
    flex-basis: 50%
  }

  .manufacturers-list .links-block__grid>div:last-child {
    display: flex;
    flex-basis: 100%;
    margin-left: -10px;
    margin-right: -10px
  }

  .manufacturers-list .links-block__grid>div:last-child>div {
    flex: 1 0 50%;
    padding-left: 10px;
    padding-right: 10px
  }
}

@media screen and (max-width:767px) {
  .links-block__text {
    text-align: left
  }

  .links-block__grid>*,
  .links-block__grid>.links-block__item,
  .links-block__grid>.links-block__item:nth-child(2),
  .links-block__grid>.links-block__item:nth-child(3),
  .links-block__grid>:nth-child(2),
  .links-block__grid>:nth-child(3) {
    flex-basis: 100%
  }

  .manufacturers-list .links-block__grid>* {
    flex-basis: 50%;
    text-align: left
  }

  .manufacturers-list .links-block__grid>:nth-child(2),
  .manufacturers-list .links-block__grid>:nth-child(3) {
    flex-basis: 50%
  }

  .manufacturers-list .main-services__title {
    font-size: 18px;
    margin-bottom: 0;
    margin-top: 20px
  }
}

@media screen and (max-width:500px) {

  .manufacturers-list .links-block__grid>*,
  .manufacturers-list .links-block__grid>:nth-child(2),
  .manufacturers-list .links-block__grid>:nth-child(3) {
    flex-basis: 100%
  }

  .manufacturers-list .links-block__grid>div:last-child {
    flex-basis: 100%;
    flex-wrap: wrap
  }

  .manufacturers-list .links-block__grid>div:last-child>div {
    flex-basis: 100%
  }
}

.approve-list {
  line-height: 28px;
  list-style: none;
  padding-left: 0
}

.approve-list>li {
  color: #a0a9b7;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 6px;
  padding-left: 22px;
  position: relative
}

.approve-list>li:before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxNSI+PHBhdGggZD0ibTIuNSA5LjIgMSAxLjcgNS4yLTkuMWMzLjktNi44LTEuOSA4LjUtNCAxMi42LS41IDEtMS45LjktMi40IDBMLjIgMTAuN0MtLjcgOSAxLjcgNy42IDIuNSA5LjJ6IiBmaWxsPSIjMDBiZWI0Ii8+PC9zdmc+);
  content: "";
  display: block;
  height: 15px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 10px
}

.articles-list__content {
  justify-content: flex-start;
  margin-left: -15px;
  margin-right: -15px
}

.articles-list__item {
  flex: 0 0 33.33%;
  margin-bottom: 10px;
  padding-left: 35px;
  padding-right: 35px
}

.articles-list__item__image {
  margin-bottom: 20px;
  max-width: 100%;
  overflow: hidden;
  position: relative
}

.articles-list__item__image:after {
  background-color: transparent;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: all .1s ease-out;
  width: 100%
}

.articles-list__item__image:hover:after {
  background-color: #e59700;
  content: "";
  opacity: .1;
  transition: all .1s ease-in
}

.articles-list__item__title {
  color: #00ada4;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 20px;
  text-transform: uppercase
}

.articles-list__more {
  margin-top: 20px;
  text-align: center
}

@media screen and (max-width:1328px) {
  .articles-list__content {
    flex-wrap: wrap
  }

  .articles-list__item {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    padding-left: 15px;
    padding-right: 15px
  }
}

@media screen and (max-width:1023px) {
  .articles-list__content .articles-list__item__title {
    font-size: 14px;
    margin-bottom: 15px
  }
}

@media screen and (max-width:768px) {
  .articles-list__item {
    flex: 0 0 calc(50% - 30px);
    max-width: 50%
  }
}

@media screen and (max-width:767px) {
  .articles-list__content {
    justify-content: center
  }

  .articles-list__item {
    flex: 1 1 100%;
    margin-bottom: 40px;
    max-width: 345px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center
  }

  .articles-list__item:last-child {
    margin-bottom: 0
  }
}

@media screen and (max-width:320px) {
  .articles-list__content>* {
    padding-left: 10px;
    padding-right: 10px
  }

  .articles-list__content>* .progressive-image-wrapper {
    border-radius: 0
  }
}

.news-list__content {
  justify-content: flex-start;
  margin-left: -15px;
  margin-right: -15px
}

.news-list__item {
  flex: 0 0 33.33%;
  margin-bottom: 10px;
  padding-left: 35px;
  padding-right: 35px
}

.news-list__item__image {
  margin-bottom: 20px;
  max-width: 100%;
  overflow: hidden;
  position: relative
}

.news-list__item__image:after {
  background-color: transparent;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: all .1s ease-out;
  width: 100%
}

.news-list__item__image:hover:after {
  background-color: #e59700;
  content: "";
  opacity: .1;
  transition: all .1s ease-in
}

.news-list__item__title {
  color: #00ada4;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 20px;
  text-transform: uppercase
}

.news-list__more {
  margin-top: 20px;
  text-align: center
}

@media screen and (max-width:1328px) {
  .news-list__content {
    flex-wrap: wrap
  }

  .news-list__item {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    padding-left: 15px;
    padding-right: 15px
  }
}

@media screen and (max-width:1023px) {
  .news-list__content .news-list__item__title {
    font-size: 14px;
    margin-bottom: 15px
  }
}

@media screen and (max-width:768px) {
  .news-list__item {
    flex: 0 0 calc(50% - 30px);
    max-width: 50%
  }
}

@media screen and (max-width:767px) {
  .news-list__content {
    justify-content: center
  }

  .news-list__item {
    flex: 1 1 100%;
    margin-bottom: 40px;
    max-width: 345px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center
  }

  .news-list__item:last-child {
    margin-bottom: 0
  }
}

@media screen and (max-width:320px) {
  .news-list__content>* {
    padding-left: 10px;
    padding-right: 10px
  }

  .news-list__content>* .progressive-image-wrapper {
    border-radius: 0
  }
}

.video-review {
  background-color: #e3e5e0;
  background-position: 100%;
  background-repeat: no-repeat;
  max-height: 320px;
  padding-bottom: 105px;
  padding-top: 99px
}

.video-review__content {
  max-width: 540px;
  position: relative
}

.video-review__title {
  color: #333638;
  font-size: 40px;
  font-weight: 400;
  margin-bottom: 15px
}

.video-review__title,
.video-review__title span {

  font-style: normal
}

.video-review__title span {
  color: #fd9500;
  font-weight: 700
}

.video-review__subtitle {
  color: #5f6877;
  font-size: 16px
}

.video-review__image {
  display: none
}

@media screen and (max-width:1470px) {
  .video-review {
    background-position: center right -300px
  }
}

@media screen and (max-width:1140px) {
  .video-review {
    background-position: center right -400px
  }
}

@media screen and (max-width:1023px) {
  .video-review {
    background-image: none !important;
    padding-bottom: 75px;
    padding-top: 70px
  }
}

@media screen and (max-width:725px) {
  .video-review {
    background-color: #f5f3f8;
    max-height: 100%;
    max-width: 100%;
    padding-bottom: 0;
    padding-top: 65px
  }

  .video-review__content {
    max-width: 100%;
    text-align: center
  }

  .video-review__title {
    font-size: 30px;
    margin-bottom: 10px
  }

  .video-review__subtitle {
    margin-bottom: 15px
  }

  .video-review__play {
    display: inline-block;
    margin: 0 auto;
    position: relative;
    right: auto;
    top: 0
  }

  .video-review__image {
    display: block;
    margin-top: 20px
  }

  .video-review__image .progressive-image-wrapper {
    border-radius: 0;
    display: block;
    margin: 0 auto
  }
}

@media screen and (max-width:340px) {
  .video-review__subtitle {
    margin: 0 auto 15px
  }

  .video-review__image {
    margin-left: -10px
  }
}

.warranty-list h2 {
  margin-bottom: 65px
}

.warranty-list__grid {
  counter-reset: item;
  margin-left: -15px;
  margin-right: -15px
}

.warranty-list__grid>div {
  padding-left: 15px;
  padding-right: 15px
}

.warranty-list__grid>div:first-child .warranty-list__item:before {
  content: none
}

.warranty-list__grid>div:first-child .warranty-list__image:before {
  padding-right: 3px
}

.warranty-list__item {
  margin-bottom: 45px;
  position: relative;
  text-align: center
}

.warranty-list__item:before {
  background-image: url(/build/i/d7fa79439ce00e241bdf.svg);
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  height: 16px;
  left: -16px;
  position: absolute;
  top: 235px;
  width: 16px
}

.warranty-list__image {
  margin-bottom: 42px;
  position: relative
}

.warranty-list__image:before {
  background-image: url(/build/i/469aca86eb458ff219f0.svg);
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  content: counter(item);
  counter-increment: item;

  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  height: 34px;
  left: 50%;
  margin-left: -14px;
  padding-bottom: 2px;
  position: absolute;
  top: -17px;
  width: 29px;
  z-index: 1
}

.warranty-list__image .progressive-image-wrapper {
  border-radius: 50%
}

.warranty-list__title {
  color: #333638;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28.06px;
  margin-bottom: 18px
}

.warranty-list__title>div {
  font-size: 26px
}

.warranty-list__title>div.upper {
  text-transform: uppercase
}

.warranty-list__text {
  color: #989ca6;
  font-size: 14px;
  line-height: 26px
}

@media screen and (max-width:1023px) {
  .warranty-list__grid>* {
    flex-basis: 50%
  }

  .warranty-list__item:before {
    content: none
  }

  .warranty-list__image {
    margin-bottom: 25px
  }
}

.green-grid {
  background-color: #00beb4;
  color: #fff;
  padding-bottom: 57px;
  padding-top: 53px
}

.green-grid__title {

  font-size: 42px
}

.green-grid__sub-title,
.green-grid__title {
  color: #fff;
  font-style: normal;
  font-weight: 400
}

.green-grid__sub-title {

  font-size: 26px;
  margin-top: 6px
}

.green-grid__grid {
  margin-top: 33px
}

.green-grid__grid>div {
  text-align: center
}

.green-grid__grid__image {
  background-color: #fff;
  border: 10px solid #fff;
  border-radius: 50%;
  height: 170px;
  margin-bottom: 30px;
  overflow: hidden;
  width: 170px
}

.green-grid__grid__title {

  font-style: normal;
  font-weight: 500;
  margin-bottom: 17px;
  text-transform: uppercase
}

.green-grid__grid__text {
  color: #ddf3f1;
  font-size: 14px;
  line-height: 26px;
  max-width: 330px
}

@media screen and (max-width:1200px) {
  .green-grid__grid__text {
    max-width: 290px
  }
}

@media screen and (max-width:1023px) {
  .green-grid {
    padding-bottom: 45px;
    padding-top: 38px
  }

  .green-grid__title {
    font-size: 40px
  }

  .green-grid__sub-title {
    font-size: 24px
  }

  .green-grid__grid__title {
    font-size: 14px
  }

  .green-grid__grid__text {
    font-size: 13px;
    line-height: 20px;
    max-width: 205px
  }
}

@media screen and (max-width:767px) {
  .green-grid {
    padding-bottom: 0
  }

  .green-grid__grid>div {
    flex-basis: calc(100% - 15px);
    margin-bottom: 63px
  }

  .green-grid__grid__text {
    max-width: 300px
  }

  .green-grid__title {

    font-size: 24px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    margin-bottom: 10px
  }

  .green-grid__sub-title {
    font-size: 20px;
    line-height: 24px
  }

  .green-grid__grid__title {
    font-size: 16px;
    line-height: 22px
  }

  .green-grid__grid__text {
    font-size: 14px;
    line-height: 26px
  }
}

.cards-with-text {
  min-height: 562px;
  padding-top: 74px;
  position: relative
}

.cards-with-text:before {
  content: "";
  display: block;
  height: 436px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

.cards-with-text .row,
.cards-with-text .row_short {
  position: relative;
  z-index: 1
}

.cards-with-text__title {

  font-size: 42px;
  font-style: normal;
  font-weight: 400
}

.cards-with-text__sub-title {
  font-size: 16px;
  line-height: 28px
}

.cards-with-text__grid {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 47px
}

.cards-with-text__grid>* {
  flex-basis: calc(50% - 30px)
}

.cards-with-text__card {
  background-color: #f1f7fa;
  border-radius: 10px;
  box-shadow: 0 5px 29px rgba(0, 0, 0, .1);
  margin-left: 15px;
  margin-right: 15px;
  min-height: 270px;
  padding-left: 208px;
  padding-right: 55px;
  padding-top: 65px
}

.cards-with-text__card__title {

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 12px
}

.cards-with-text__card__text {
  line-height: 26px
}

.cards-with-text__card__rest {
  background-image: url(/build/i/236dc806dbc2f9f5e689.jpg)
}

.cards-with-text__card__transfer {
  background-image: url(/build/i/c78c07214de6ead3bd8d.jpg)
}

@media screen and (max-width:1240px) {
  .cards-with-text__grid {
    display: block
  }

  .cards-with-text__card {
    margin-left: auto;
    margin-right: auto;
    width: 770px
  }

  .cards-with-text__card:first-child {
    margin-bottom: 30px
  }
}

@media screen and (max-width:1023px) {
  .cards-with-text__card {
    width: 570px
  }
}

@media screen and (max-width:635px) {
  .cards-with-text__grid {
    margin-left: -30px;
    margin-right: -30px
  }

  .cards-with-text__card {
    border-radius: 0;
    width: 100%
  }
}

@media screen and (max-width:520px) {
  .cards-with-text__grid {
    margin-left: 0;
    margin-right: 0
  }

  .cards-with-text__card {
    border-radius: 10px;
    max-width: 290px;
    padding: 245px 20px 30px;
    width: 100%
  }

  .cards-with-text__card__rest {
    background-image: url(/build/i/b8730507cf974383d52e.jpg)
  }

  .cards-with-text__card__transfer {
    background-image: url(/build/i/f7f76c08e3be0cc8d563.jpg)
  }

  .cards-with-text__card__title {
    font-size: 18px;
    line-height: 26px
  }

  .cards-with-text__card__text {
    font-size: 14px;
    line-height: 22px
  }
}

.director-quote {
  background-color: #f2f6f8;
  overflow: hidden
}

.director-quote__grid {
  margin-left: -12px;
  margin-right: -12px
}

.director-quote__grid>* {
  padding-left: 12px;
  padding-right: 12px
}

.director-quote__image {
  position: relative;
  z-index: 2
}

.director-quote__image:before {
  border: 50px solid #fff;
  border-radius: 50%;
  bottom: -65px;
  content: "";
  height: 454px;
  left: 50%;
  margin-left: -227px;
  opacity: .5;
  position: absolute;
  width: 454px
}

.director-quote__image .progressive-image-wrapper {
  border-radius: 0;
  display: block;
  margin-left: auto;
  margin-right: auto
}

.director-quote__right {
  align-items: flex-end;
  display: flex;
  justify-content: center;
  position: relative
}

.director-quote__right:before {
  background-position: 3px 6px;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 1500px
}

.director-quote__content {
  padding-bottom: 65px;
  padding-top: 60px
}

.director-quote__title {
  color: #333638;

  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 28px;
  padding-top: 55px;
  position: relative
}

.director-quote__title:before {
  background-image: url(/build/i/289614f9869ba4b363d5.svg);
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 36px;
  left: 0;
  position: absolute;
  top: 0;
  width: 36px
}

.director-quote__text {
  color: #5f6877;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 70px
}

.director-quote__author {
  color: #a0abbe;
  font-size: 14px;
  line-height: 22px;
  max-width: 555px;
  padding-right: 120px;
  position: relative
}

.director-quote__author span {
  white-space: nowrap
}

.director-quote__author:after {
  background-image: url(/build/i/9db47a6dc6917eff2183.png);
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 100px;
  margin-top: -50px;
  position: absolute;
  right: 0;
  top: 50%;
  width: 100px
}

.director-quote__author_no-sign {
  padding-right: 0
}

.director-quote__author_no-sign:after {
  content: none
}

@media screen and (max-width:900px) {
  .director-quote__content {
    padding-top: 30px
  }

  .director-quote__image,
  .director-quote__image .progressive-image-wrapper,
  .director-quote__right {
    max-width: 100%
  }
}

@media screen and (max-width:495px) {
  .director-quote__title {
    font-size: 26px
  }

  .director-quote__text {
    margin-bottom: 30px
  }

  .director-quote__image:before {
    border: 40px solid #fff;
    bottom: -25px;
    content: "";
    height: 100%;
    left: 50%;
    margin-left: 0;
    position: absolute;
    transform: translateX(-50%);
    width: 100%
  }
}

.mass-media .h2-sub {
  font-size: 18px;
  line-height: 32px;
  max-width: 770px
}

.mass-media__grid {
  margin-left: -12px;
  margin-right: -12px
}

.mass-media__grid>* {
  margin-top: 45px;
  padding-left: 12px;
  padding-right: 12px
}

.mass-media__item {
  background-color: #fff;
  border: 10px solid #e9ecee;
  border-radius: 10px;
  height: 100%;
  min-height: 320px;
  padding: 25px 40px 35px 50px
}

.mass-media__item p {
  color: #5f6877;
  line-height: 30px;
  margin-bottom: 20px
}

.mass-media__item__icon {
  height: 54px;
  margin-bottom: 9px
}

.mass-media__item__title {
  color: #333638;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 20px
}

@media screen and (max-width:1199px) {
  .mass-media__item {
    padding: 25px 20px 30px 30px
  }

  .mass-media__item__title {
    font-size: 18px
  }
}

@media screen and (max-width:767px) {
  .mass-media .h2-sub {
    font-size: 16px;
    line-height: 26px;
    margin-top: 20px
  }

  .mass-media__grid>* {
    flex-basis: 100%;
    margin-top: 25px
  }

  .mass-media__item {
    height: auto;
    min-height: auto
  }

  .mass-media__item p {
    font-size: 15px;
    line-height: 26px
  }
}

@media screen and (max-width:495px) {
  .mass-media__grid {
    margin-left: -15px;
    margin-right: -15px
  }

  .mass-media__grid>* {
    padding: 0
  }

  .mass-media__item {
    border-radius: 0
  }
}

.dream-treatment__title-link {
  margin-top: 7px;
  text-align: center
}

.dream-treatment__title-link .link,
.dream-treatment__title-link .slide-tab__label,
.dream-treatment__title-link .tabs__labels.tabs__labels_buttons .tab__label,
.dream-treatment__title-link [data-js=tooltip],
.tabs__labels.tabs__labels_buttons .dream-treatment__title-link .tab__label {

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase
}

.dream-treatment__grid {
  margin-top: 64px
}

.dream-treatment__grid>* {
  background-size: 50px 50px;
  padding-left: 80px;
  padding-right: 4%
}

.dream-treatment__grid>:first-child {
  background-image: url(/build/i/f0fc8b8a90b034010d1c.svg)
}

.dream-treatment__grid>:nth-child(2) {
  background-image: url(/build/i/8dda15d5b117aaee69a4.svg)
}

.dream-treatment__grid>:nth-child(3) {
  background-image: url(/build/i/c748b7f1fcc469c3908b.svg)
}

.dream-treatment__grid__title {
  font-weight: 500;
  text-transform: uppercase
}

.dream-treatment__grid__text,
.dream-treatment__grid__title {

  font-style: normal;
  line-height: 28px
}

.dream-treatment__grid__text {
  color: #989ca6;
  font-weight: 400
}

.dream-treatment__item_with-icon {
  background-image: none !important
}

.dream-treatment_component .dream-treatment__grid {
  margin-left: -14px;
  margin-right: -14px
}

.dream-treatment_component .dream-treatment__grid>* {
  padding-left: 14px;
  padding-right: 14px
}

.dream-treatment_component .dream-treatment__grid__title {
  margin-bottom: 5px
}

.dream-treatment_component .dream-treatment__item {
  padding-left: 82px;
  padding-top: 8px;
  position: relative
}

.dream-treatment__grid__icon {
  left: 0;
  position: absolute;
  top: 0
}

@media screen and (max-width:1359px) {
  .dream-treatment_component .dream-treatment__grid {
    margin-top: 50px
  }

  .dream-treatment_component+.pusher {
    margin-top: 70px !important
  }
}

@media screen and (max-width:1080px) {
  .dream-treatment__grid>* {
    padding-left: 0;
    padding-top: 72px
  }

  .dream-treatment_component .dream-treatment__grid>* {
    padding-top: 0
  }

  .dream-treatment_component .dream-treatment__item {
    padding-left: 0;
    padding-top: 72px
  }
}

@media screen and (max-width:1023px) {
  .dream-treatment__grid>* {
    padding-right: 2%
  }

  .dream-treatment__grid__text,
  .dream-treatment__grid__title {
    font-size: 14px
  }

  .page_251 .dream-treatment+.pusher {
    margin-top: 50px !important
  }
}

@media screen and (max-width:767px) {
  .dream-treatment__grid>* {
    background-position: top;
    flex-basis: 100%;
    margin-bottom: 50px;
    padding-right: 0
  }

  .dream-treatment__grid__text,
  .dream-treatment__grid__title {
    font-size: 16px;
    text-align: center
  }

  .dream-treatment__grid__text {
    margin-left: auto;
    margin-right: auto;
    max-width: 320px
  }

  .page_251 .dream-treatment+.pusher {
    margin-top: 20px !important
  }

  .dream-treatment_component .dream-treatment__grid>:last-child {
    margin-bottom: 0
  }

  .dream-treatment_component+.pusher {
    margin-top: 40px !important
  }

  .dream-treatment__grid__icon {
    left: 50%;
    transform: translateX(-50%)
  }
}

.compare-prices-slider-wrapper {
  margin-top: 10px;
  padding: 0 128px;
  position: relative
}

.compare-prices-wrapper_with-slider .h2-sub strong {
  color: #3c4143;

  font-style: normal;
  font-weight: 500
}

.compare-prices-slider__navigation {
  height: 60px;
  left: 0;
  margin-top: -30px;
  pointer-events: none;
  position: absolute;
  top: 50%;
  width: 100%
}

.compare-prices-slider__navigation>div {
  background-color: #00beb4;
  border-radius: 0 30px 30px 0;
  box-shadow: 0 10px 36px 4px rgba(70, 81, 86, .17);
  cursor: pointer;
  height: 60px;
  pointer-events: auto;
  position: absolute;
  width: 65px
}

.compare-prices-slider__navigation>div svg {
  left: 50%;
  margin-left: -9px;
  margin-top: -7px;
  position: absolute;
  top: 50%
}

.compare-prices-slider__navigation>div.slick-disabled {
  opacity: .8;
  pointer-events: none
}

.compare-prices-slider__navigation .prev {
  left: 63px;
  transform: rotate(180deg)
}

.compare-prices-slider__navigation .next {
  right: 63px
}

.compare-prices-slider .slick-list {
  margin-left: -26px;
  margin-right: -26px;
  padding-bottom: 25px;
  padding-top: 25px
}

.compare-prices-slider .slick-slide {
  margin-left: 26px;
  margin-right: 26px
}

.compare-prices-slider .compare-prices__block {
  margin-left: 0;
  margin-right: 0
}

.compare-prices-slider__block .compare-prices__block__title {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  padding: 20px 26px 15px 18px
}

.compare-prices-slider__block .compare-prices__block__title__name {
  margin-left: 0;
  padding-left: 0;
  text-align: left
}

.compare-prices-slider__block .compare-prices__block__title__flag {
  margin-top: 2px;
  position: relative
}

.compare-prices-slider__block .compare-prices__block__table {
  padding-left: 0;
  padding-right: 0
}

.compare-prices-slider__block .compare-prices__best-label {
  font-size: 14px;
  height: auto;
  margin-left: 15px;
  margin-right: 15px
}

.compare-prices-slider__block .compare-prices__block__right_with-i {
  padding-left: 25px;
  padding-right: 0
}

.compare-prices-slider__block .compare-prices__block__right [data-js=tooltip] {
  left: 0;
  right: auto
}

.compare-prices-slider__block .work-info__price__tooltip {
  margin-left: 0
}

.compare-prices-slider__block .compare-prices__block__table>div:last-child {
  align-items: center;
  border-bottom: 1px solid #d8dfe1;
  padding-bottom: 10px;
  padding-top: 9px
}

.compare-prices-slider__block .compare-prices__block__table>div:last-child.compare-prices__block__table-row_without-offer {
  border-bottom: 1px solid transparent
}

.compare-prices-slider__block .compare-prices__block__table>div:last-child .new {
  display: inline-block;
  padding-left: 25px;
  position: relative
}

.compare-prices-slider__block .compare-prices__block__table>div:last-child .new [data-js=tooltip] {
  margin-top: -11px;
  top: 50%
}

.compare-prices-slider__block .compare-prices__block__table>div:last-child .compare-prices__block__list-title {
  margin-bottom: 0
}

.compare-prices-slider__block .compare-prices__block__offer {
  margin-bottom: 20px;
  margin-top: 22px
}

.compare-prices-slider__block .compare-prices__block__offer_cut {
  margin-top: 12px
}

.compare-prices-slider__links__installment {
  color: #464a4c;

  font-style: normal;
  font-weight: 500;
  margin-right: 90px;
  padding-left: 80px;
  position: relative
}

.compare-prices-slider__links__installment>span {
  border-bottom: 1px solid #464a4c;
  transition: border .2s ease-out
}

.compare-prices-slider__links__installment:hover>span {
  border-bottom: 1px solid transparent;
  transition: border .2s ease-in
}

.compare-prices-slider__links__installment:before {
  background-image: url(/build/i/60094153ec8d2e152a64.svg);
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 80px;
  left: 0;
  margin-top: -40px;
  position: absolute;
  top: 50%;
  width: 80px
}

.compare-prices-slider__links__grid {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  margin-top: 30px
}

.compare-prices-slider__links__grid>div:nth-child(2) {
  margin-left: -37px;
  margin-right: -37px
}

.compare-prices-slider__link {
  font-size: 14px;
  padding-left: 37px;
  padding-right: 37px
}

.compare-prices-slider__link>span {
  border-bottom: 1px dashed #00beb4;
  transition: border .2s ease-out
}

.compare-prices-slider__link:hover>span {
  border-bottom: 1px dashed transparent;
  transition: border .2s ease-in
}

@media screen and (max-width:1349px) {
  .compare-prices-slider-wrapper {
    padding-left: 65px;
    padding-right: 65px
  }

  .compare-prices-slider .slick-list {
    margin-left: -23px;
    margin-right: -23px
  }

  .compare-prices-slider .slick-slide {
    margin-left: 23px;
    margin-right: 23px
  }

  .compare-prices-slider__navigation .prev {
    left: 0
  }

  .compare-prices-slider__navigation .next {
    right: 0
  }

  .compare-prices-slider__block .compare-prices__block__table>div:last-child {
    text-align: center
  }

  .compare-prices-slider__block .compare-prices__block__table>div:last-child .new {
    padding-left: 0;
    padding-right: 25px
  }

  .compare-prices-slider__block .compare-prices__block__table>div:last-child .new [data-js=tooltip] {
    left: auto;
    right: 0
  }

  .compare-prices-slider__block .compare-prices__block__table>div:last-child .compare-prices__block__right {
    padding-right: 0
  }

  .compare-prices-slider__block .compare-prices__block__right_with-i {
    display: inline-block;
    padding-left: 0;
    padding-right: 25px
  }

  .compare-prices-slider__block .compare-prices__block__right [data-js=tooltip] {
    left: auto;
    right: 0
  }
}

@media screen and (max-width:1023px) {
  .compare-prices-wrapper_with-slider .offer-block-wrapper_compare-prices {
    margin-bottom: 26px;
    margin-top: 36px
  }

  .compare-prices-slider__links__grid {
    flex-wrap: wrap;
    margin-bottom: 0;
    margin-top: 15px
  }

  .compare-prices-slider__links__grid>div {
    flex-basis: 100%;
    text-align: center
  }

  .compare-prices-slider__links__grid>div:first-child {
    margin-bottom: 40px
  }

  .compare-prices-slider__links__grid>div:nth-child(2) {
    padding: 24px 0;
    position: relative
  }

  .compare-prices-slider__links__grid>div:nth-child(2):after,
  .compare-prices-slider__links__grid>div:nth-child(2):before {
    background-color: #d9e1e1;
    content: "";
    height: 1px;
    left: -26px;
    position: absolute;
    width: calc(100% + 52px)
  }

  .compare-prices-slider__links__grid>div:nth-child(2):before {
    top: 0
  }

  .compare-prices-slider__links__grid>div:nth-child(2):after {
    bottom: 0
  }

  .compare-prices-slider__links__installment {
    margin-right: 0
  }
}

@media screen and (max-width:767px) {
  .compare-prices-slider-wrapper {
    padding-left: 0;
    padding-right: 0
  }

  .compare-prices-slider .slick-list {
    margin-left: 0;
    margin-right: 0;
    overflow: visible
  }

  .compare-prices-slider .slick-slide {
    margin-left: 0;
    margin-right: 15px;
    width: 300px
  }

  .compare-prices-slider__navigation {
    display: none
  }

  .compare-prices-slider__block .compare-prices__block__table>div {
    display: block;
    text-align: center
  }

  .compare-prices-slider__block .compare-prices__block__table>div>span {
    display: block
  }

  .compare-prices-slider__block .compare-prices__block__right_with-i {
    display: inline-block !important
  }

  .compare-prices-slider__block .compare-prices__block__right br {
    display: none
  }

  .compare-prices-slider__block .compare-prices__block__right .old {
    margin-right: 10px
  }

  .compare-prices-slider__block .compare-prices__block__right {
    margin-top: 7px;
    text-align: center
  }

  .compare-prices-slider__block .compare-prices__block__right [data-js=tooltip] {
    top: -2px
  }

  .compare-prices-wrapper_with-slider .h2,
  .compare-prices-wrapper_with-slider .payment-page h2,
  .payment-page .compare-prices-wrapper_with-slider h2 {
    margin-bottom: 20px;
    text-align: left
  }

  .compare-prices-wrapper_with-slider .h2-sub {
    font-size: 13px;
    line-height: 18px;
    margin-top: 20px;
    text-align: left
  }

  .compare-prices-wrapper_with-slider .h2-sub strong {
    display: block;
    font-size: 16px;
    margin-bottom: 5px
  }

  .compare-prices-slider__links__grid>div:nth-child(2) {
    display: flex;
    justify-content: space-between
  }

  .compare-prices-slider__link {
    padding: 0
  }
}

@media screen and (max-width:550px) {
  .compare-prices-slider__link>span>span {
    display: none
  }
}

@media screen and (max-width:440px) {
  .compare-prices-slider .slick-slide {
    width: 215px
  }

  .compare-prices__block__cut-price {
    font-size: 13px
  }
}

.spoiler-wrapper {
  margin-top: 60px;
  position: relative
}

.spoiler-wrapper:after,
.spoiler-wrapper:before {
  clear: both;
  content: "";
  display: table
}

.spoiler__button {
  left: 50%;
  position: absolute;
  top: 0;
  transform: translateX(-50%)
}

.spoiler {
  display: none
}

.spoiler.visible {
  display: block
}

@media screen and (max-width:767px) {
  .spoiler-wrapper {
    margin-bottom: 50px;
    margin-top: 50px
  }

  .spoiler__button {
    font-size: 14px;
    text-align: center;
    width: 100%
  }

  .spoiler-wrapper+.divider {
    display: none
  }
}

.accordion__contents {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 25px
}

.accordion__tab:last-child .accordion__tab__title:not(.accordion__tab__title_no-style) {
  border-bottom: 1px solid #d9e1e1
}

.accordion__tab:last-child .accordion__tab__title:not(.accordion__tab__title_no-style).active {
  border-bottom: 0
}

.accordion__tab:last-child .accordion__tab__content:not(.accordion__tab__content_no-style) {
  border-bottom: 1px solid #d9e1e1
}

.accordion__tab__title:not(.accordion__tab__title_no-style) {
  border-top: 1px solid #d9e1e1;
  color: #00beb4;
  height: 52px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative
}

.accordion__tab__title:not(.accordion__tab__title_no-style):after {
  background-image: url(/build/i/f1b60d3a25cd70d2deac.svg);
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 7px;
  margin-top: -4px;
  position: absolute;
  right: 15px;
  top: 50%;
  width: 10px
}

.accordion__tab__title:not(.accordion__tab__title_no-style).active:after {
  transform: rotate(-180deg)
}

.accordion__tab__content:not(.accordion__tab__content_no-style) {
  border-top: 1px solid #d9e1e1;
  display: none;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px
}

.accordion__tab__content:not(.accordion__tab__content_no-style).active {
  display: block
}

@media screen and (max-width:360px) {
  .accordion__tab__content {
    padding-top: 0
  }
}

.full-width-text .h3 {
  color: #333638;

  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 20px;
  position: relative
}

.full-width-text .h3_with-icon {
  padding-left: 94px
}

.full-width-text .h3_with-icon i {
  display: block;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-55%);
  width: 60px
}

.full-width-text .h3_with-icon i img {
  width: 100%
}

.full-width-text p {
  color: #333638;
  font-size: 16px;
  line-height: 26px;
  padding-right: 75px
}

.full-width-text .row>a,
.full-width-text .row_short>a {
  display: inline-block;
  margin-top: 35px
}

@media screen and (max-width:1199px) {
  .full-width-text p {
    padding-right: 0
  }
}

@media screen and (max-width:767px) {
  .full-width-text .h3 {
    font-size: 20px;
    line-height: 26px
  }

  .full-width-text .h3 br {
    display: none
  }

  .full-width-text .h3_with-icon {
    padding-left: 0
  }

  .full-width-text .h3_with-icon i {
    display: none
  }

  .full-width-text .row>a,
  .full-width-text .row_short>a {
    margin-top: 25px
  }
}

.online-consultation__grid {
  background-color: #f2f6f8;
  border-radius: 10px;
  display: flex;
  padding: 58px 76px
}

.online-consultation__grid>div:first-child {
  max-width: 600px
}

.online-consultation__grid>div:last-child {
  padding-left: 60px;
  padding-top: 30px
}

.online-consultation__title {
  color: #333638;

  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: 52px;
  margin-bottom: 20px
}

.online-consultation__text {
  color: #5f6877;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 15px
}

.online-consultation__notice {
  color: #989ca6;
  font-size: 16px;
  line-height: 28px
}

.online-consultation__icons {
  margin-bottom: 25px
}

.online-consultation__icons i {
  background-size: contain;
  display: inline-block;
  height: 100px;
  width: 100px
}

.online-consultation__icons i:first-child {
  background-image: url(/build/i/028b297af15a716e22cb.png)
}

.online-consultation__icons i:nth-child(2) {
  background-image: url(/build/i/924d226314ce191ae8ef.png)
}

.online-consultation__icons i:nth-child(3) {
  background-image: url(/build/i/580aefbf845eff12e780.png)
}

.online-consultation__link {
  margin-top: 35px;
  text-align: center
}

.online-consultation__grid_with-link>div:last-child {
  padding-top: 0
}

@media screen and (max-width:1200px) {
  .online-consultation__icons i {
    height: 80px;
    width: 80px
  }
}

@media screen and (max-width:1150px) {
  .online-consultation__grid {
    flex-wrap: wrap
  }

  .online-consultation__grid>div {
    flex-basis: 100%
  }

  .online-consultation__grid>div:first-child {
    max-width: 100%
  }

  .online-consultation__grid>div:last-child {
    align-items: center;
    display: flex;
    padding-left: 0;
    padding-top: 30px
  }

  .online-consultation__icons {
    margin-bottom: 0;
    margin-left: 30px;
    order: 2
  }

  .online-consultation__grid_with-link>div:last-child {
    flex-wrap: wrap
  }

  .online-consultation__grid_with-link .online-consultation__link {
    flex-basis: 100%;
    margin-top: 20px;
    order: 3;
    text-align: left
  }
}

@media screen and (max-width:800px) {
  .online-consultation__icons {
    margin-top: 5px
  }

  .online-consultation__icons i {
    height: 60px;
    width: 60px
  }
}

@media screen and (max-width:767px) {
  .online-consultation__title {
    font-size: 36px;
    line-height: 42px
  }
}

@media screen and (max-width:675px) {
  .online-consultation__grid {
    padding: 45px 40px
  }

  .online-consultation__icons {
    margin-top: 4px
  }

  .online-consultation__icons i {
    height: 40px;
    width: 40px
  }
}

@media screen and (max-width:545px) {
  .online-consultation {
    margin-left: -15px;
    margin-right: -15px
  }

  .online-consultation__grid {
    border-radius: 0
  }

  .online-consultation__grid>div:last-child {
    align-items: center;
    display: flex;
    flex-wrap: wrap
  }

  .online-consultation__icons {
    flex-basis: 100%;
    margin-left: 0;
    margin-top: 20px
  }
}

@media screen and (max-width:400px) {
  .online-consultation__title {
    font-size: 30px;
    line-height: 32px
  }

  .online-consultation__text {
    font-size: 16px;
    line-height: 22px
  }

  .online-consultation__notice {
    font-size: 14px;
    line-height: 22px
  }

  .online-consultation__grid {
    padding-left: 15px;
    padding-right: 15px
  }

  .online-consultation__icons {
    text-align: center
  }

  .online-consultation__grid_with-link .online-consultation__link {
    margin-top: 10px;
    text-align: center
  }
}

.hold-anti-crisis-prices__title {
  margin-bottom: 35px
}

.hold-anti-crisis-prices__sub-title {

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 80px;
  max-width: 850px;
  text-align: center
}

.hold-anti-crisis-prices__grid {
  margin-left: -15px;
  margin-right: -15px
}

.hold-anti-crisis-prices__grid>div {
  border: 10px solid #e9ecee;
  border-radius: 10px;
  flex-basis: calc(50% - 30px);
  margin-left: 15px;
  margin-right: 15px;
  min-height: 225px;
  padding: 38px 44px 38px 240px;
  position: relative
}

.hold-anti-crisis-prices__grid>div a {

  font-style: normal;
  font-weight: 500
}

.hold-anti-crisis-prices__grid>div:before {
  background-size: contain;
  bottom: 0;
  content: "";
  display: block;
  height: 214px;
  left: 0;
  position: absolute;
  width: 211px
}

.hold-anti-crisis-prices__grid>div:first-child:before {
  background-image: url(/build/i/39d3e41b4a54501cda7d.png)
}

.hold-anti-crisis-prices__grid>div:last-child:before {
  background-image: url(/build/i/9e6c016069a12e1e2584.png)
}

.hold-anti-crisis-prices__grid__title {
  color: #333638;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 4px
}

.hold-anti-crisis-prices__grid__title span {
  font-size: 16px
}

.hold-anti-crisis-prices__grid__text {
  color: #989ca6;
  margin-bottom: 11px
}

@media screen and (max-width:1120px) {
  .hold-anti-crisis-prices__grid>div {
    padding-left: 175px;
    padding-right: 15px;
    padding-top: 30px
  }

  .hold-anti-crisis-prices__grid>div:before {
    height: 150px;
    width: 150px
  }
}

@media screen and (max-width:1024px) {
  .hold-anti-crisis-prices__grid>div {
    padding-left: 140px
  }

  .hold-anti-crisis-prices__grid>div:before {
    height: 120px;
    width: 120px
  }
}

@media screen and (max-width:1023px) {
  .hold-anti-crisis-prices__grid {
    flex-wrap: wrap;
    margin-left: 0;
    margin-right: 0
  }

  .hold-anti-crisis-prices__grid>div {
    flex-basis: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
    padding-left: 280px
  }

  .hold-anti-crisis-prices__grid>div:before {
    height: 200px;
    width: 200px
  }

  .hold-anti-crisis-prices__grid>div:first-child {
    margin-bottom: 30px
  }

  .hold-anti-crisis-prices__sub-title {
    margin-bottom: 40px
  }
}

@media screen and (max-width:615px) {
  .hold-anti-crisis-prices__sub-title {
    font-size: 16px;
    line-height: 24px
  }

  .hold-anti-crisis-prices__grid {
    margin-left: -15px;
    margin-right: -15px
  }

  .hold-anti-crisis-prices__grid>div {
    border-left: 0;
    border-radius: 0;
    border-right: 0;
    padding-left: 160px
  }

  .hold-anti-crisis-prices__grid>div:before {
    height: 120px;
    width: 120px
  }

  .hold-anti-crisis-prices__grid>div:first-child {
    margin-bottom: 0
  }

  .hold-anti-crisis-prices__grid>div:last-child {
    margin-top: -10px
  }
}

@media screen and (max-width:460px) {
  .hold-anti-crisis-prices__grid>div {
    padding-left: 15px;
    padding-right: 15px
  }

  .hold-anti-crisis-prices__grid>div:before {
    content: none
  }
}

.primary-admission-form .selectric {
  background-color: #eff4f5;
  border: 1px solid #eff4f5
}

.primary-admission-form .selectric-items {
  border-bottom: 1px solid #eff4f5;
  border-left: 1px solid #eff4f5;
  border-right: 1px solid #eff4f5
}

.primary-admission-form .selectric-items li:hover {
  background: #d2d9da
}

.primary-admission-form textarea {
  max-width: 930px
}

.primary-admission-form .input__label {
  align-items: center;
  display: flex;
  top: 50%;
  transform: translateY(-50%)
}

.primary-admission-form__title {

  font-size: 16px;
  font-style: normal;
  font-weight: 500
}

.primary-admission-form__grid {
  align-items: center;
  margin-left: -15px;
  margin-right: -15px;
  max-width: 960px
}

.primary-admission-form__grid>* {
  margin-left: 15px;
  margin-right: 15px;
  max-width: 290px
}

.primary-admission-form__divider {
  background-color: #eff4f5;
  height: 1px;
  width: 100%
}

.primary-admission-form__answers-grid {
  margin-left: -40px;
  margin-right: -40px
}

.primary-admission-form__answers-grid>* {
  flex-basis: calc(40% - 80px);
  margin-left: 40px;
  margin-right: 40px;
  max-width: 430px
}

.primary-admission-form__answers-grid .primary-admission-form__title {
  margin-bottom: 19px;
  margin-top: 39px
}

.primary-admission-form__table b {

  font-style: normal;
  font-weight: 500
}

.primary-admission-form__table>div label {
  align-items: center;
  display: flex;
  height: 34px;
  margin-bottom: 0;
  padding-left: 30px;
  position: relative;
  width: 100%
}

.primary-admission-form__table>div label:before {
  left: 0;
  top: calc(50% - 10px)
}

.primary-admission-form__table>div label:after {
  left: 3px;
  top: calc(50% - 13px)
}

.primary-admission-form__button {
  align-items: center;
  margin-top: 16px
}

.primary-admission-form__button>:first-child {
  flex-basis: 135px;
  margin-right: 24px;
  max-width: 135px
}

.primary-admission-form__button>:last-child {
  color: #bdc7ca;
  flex: 1 1 50%;
  font-size: 12px;
  max-width: 430px
}

@media screen and (max-width:1042px) {
  .primary-admission-form textarea {
    max-width: 873px
  }

  .primary-admission-form__grid>* {
    max-width: 270px
  }
}

@media screen and (max-width:1023px) {
  label[for=primary-admission-form__agree] {
    font-size: 14px
  }

  .primary-admission-form__grid_second>* {
    max-width: 200px
  }

  .primary-admission-form__table>div label {
    font-size: 14px;
    height: auto;
    padding-bottom: 8px;
    padding-right: 30px;
    padding-top: 8px
  }
}

@media screen and (max-width:767px) {
  .primary-admission-form__grid_second {
    margin-left: auto;
    margin-right: auto
  }

  .primary-admission-form__grid_second>* {
    flex-basis: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%
  }
}

@media screen and (max-width:620px) {
  .primary-admission-form__answers-grid {
    margin-left: auto;
    margin-right: auto
  }

  .primary-admission-form__answers-grid>* {
    flex-basis: 100%;
    margin-left: auto;
    margin-right: auto
  }
}

@media screen and (max-width:575px) {
  .primary-admission-form textarea {
    height: 100px !important
  }

  .primary-admission-form .input__wrapper {
    padding: 8px 0
  }

  .primary-admission-form__grid,
  .primary-admission-form__grid>* {
    margin-left: auto;
    margin-right: auto
  }

  .primary-admission-form__grid>* {
    flex-basis: 100%;
    max-width: 100%
  }

  .primary-admission-form__button>:last-child {
    margin-top: 20px
  }

  .primary-admission-form__divider {
    margin-bottom: 25px !important;
    margin-top: 30px !important
  }
}

.payment-page {
  padding-bottom: 100px;
  padding-top: 0
}

.payment-page h2 {
  text-align: left
}

.payment-page .header-services .header__callback {
  display: flex;
  margin-left: 30px
}

.payment-form-title {
  margin-bottom: 50px;
  margin-top: 45px
}

.payment-form-title a {
  display: inline-block;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 10px
}

.payment-form {
  border-radius: 10px;
  box-shadow: 0 10px 29px rgba(70, 81, 86, .18);
  padding: 60px
}

.payment-form__text {
  line-height: 24px;
  margin-bottom: 13px;
  max-width: 640px
}

.payment-form__fio-fields {
  margin-left: -15px;
  margin-right: -15px;
  max-width: 900px
}

.payment-form__fio-fields>* {
  flex-basis: calc(33.33% - 30px);
  margin-left: 15px;
  margin-right: 15px
}

.payment-form__contacts-fields {
  margin-left: -15px;
  margin-right: -15px;
  max-width: 600px
}

.payment-form__contacts-fields>* {
  flex-basis: calc(50% - 30px);
  margin-left: 15px;
  margin-right: 15px
}

.payment-form__button {
  align-items: center;
  margin-top: 16px
}

.payment-form__button>:first-child {
  flex-basis: 200px;
  margin-right: 24px;
  max-width: 200px
}

.payment-form__button>:last-child {
  color: #bdc7ca;
  flex: 1 1 50%;
  font-size: 12px;
  max-width: 430px
}

.payment-form__yandex {
  color: #00beb4;
  font-size: 12px;
  margin-top: 20px;
  position: relative
}

.payment-form__yandex:after {
  background-image: url(/build/i/fa9ed105f8fc38403224.png);
  content: "";
  display: block;
  height: 17px;
  margin-top: 5px;
  width: 265px
}

.payment-result {
  margin-top: 40px
}

.payment-result>div {
  background: inherit;
  font-size: 16px;
  line-height: 30px;
  margin-right: auto;
  max-width: 700px;
  position: relative
}

.payment-result>div:before {
  border-bottom: 2px dotted #ddd;
  bottom: 10px;
  content: "";
  height: 0;
  line-height: 0;
  position: absolute;
  width: 100%
}

.payment-form__sub-title {
  margin-top: 5px
}

.payment-form__sub-title a {
  font-size: 16px
}

.payment-result__left {
  background-color: #fff;
  color: #000;
  display: inline;
  padding-right: 5px;
  position: relative;
  z-index: 1
}

.payment-result__right {
  background-color: #fff;
  bottom: 0;
  padding-left: 5px;
  position: absolute;
  right: 0;
  text-align: right;
  z-index: 2
}

.payment-result__info {
  margin-top: 30px
}

@media screen and (max-width:1024px) {
  .payment-form__contacts-fields {
    max-width: 548px
  }
}

@media screen and (max-width:1023px) {

  .payment-form-wrapper .row,
  .payment-form-wrapper .row_short {
    padding-left: 0;
    padding-right: 0
  }

  .payment-form {
    border-radius: 0;
    padding-left: 26px;
    padding-right: 26px
  }

  .payment-form__contacts-fields {
    max-width: 496px
  }
}

@media screen and (max-width:767px) {
  .payment-result>div {
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
    padding-bottom: 15px
  }

  .payment-result>div:before {
    content: none
  }

  .payment-result__left {
    display: block;
    line-height: 25px;
    padding-right: 0
  }

  .payment-result__right {
    display: block;
    font-size: 14px;
    line-height: 25px;
    padding-left: 0;
    position: relative;
    text-align: left
  }

  .payment-form__contacts-fields,
  .payment-form__fio-fields {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%
  }

  .payment-form__contacts-fields>*,
  .payment-form__fio-fields>* {
    margin-left: auto;
    margin-right: auto
  }

  .payment-page .header__logo {
    left: 0;
    margin-left: 15px;
    top: 20px
  }

  .payment-page .header-services .header__callback,
  .payment-page .header__mobile-menu {
    display: none
  }
}

@media screen and (max-width:499px) {
  .payment-form-title {
    margin-bottom: 40px;
    margin-top: 35px
  }

  .payment-form__button>:last-child {
    margin-top: 15px
  }
}

.grid-info__img>.progressive-image-wrapper {
  border-radius: 50%
}

.grid-info__items {
  justify-content: center;
  margin-left: -58px;
  margin-right: -58px;
  margin-top: 80px
}

.grid-info__items>* {
  box-sizing: content-box;
  color: #5f6877;
  flex-basis: calc(25% - 116px);
  font-size: 14px;
  line-height: 22px;
  margin-left: 58px;
  margin-right: 58px;
  max-width: 200px;
  text-align: center
}

.grid-info__img {
  margin-bottom: 21px
}

.grid-info__title {
  font-weight: 500;
  margin-bottom: 11px;
  text-transform: uppercase
}

.grid-info__text,
.grid-info__title {

  font-style: normal
}

.grid-info__text {
  font-weight: 400
}

@media screen and (max-width:1359px) {
  .grid-info__items {
    margin-left: -15px;
    margin-right: -15px
  }

  .grid-info__items>* {
    box-sizing: border-box;
    flex-basis: calc(25% - 30px);
    margin-left: 15px;
    margin-right: 15px;
    max-width: 230px
  }
}

@media screen and (max-width:1023px) {
  .grid-info__items {
    margin-top: 0
  }

  .grid-info__items>* {
    flex: 1 0 calc(50% - 30px);
    margin-top: 70px;
    max-width: 400px
  }
}

@media screen and (max-width:600px) {
  .grid-info__items>* {
    flex-basis: calc(100% - 30px);
    margin-top: 50px
  }
}

.payment-page .ribbon-banners-wrapper h2,
.ribbon-banners-wrapper .h2,
.ribbon-banners-wrapper .payment-page h2 {
  margin-bottom: 25px
}

.ribbon-banners {
  flex-wrap: nowrap;
  margin-left: -10px;
  margin-right: -10px
}

.ribbon-banners>* {
  border-radius: 10px;
  flex-basis: calc(50% - 20px);
  margin-left: 10px;
  margin-right: 10px;
  min-height: 123px;
  overflow: hidden;
  position: relative
}

.ribbon-banners>.yellow {
  background-color: #f8efa1
}

.ribbon-banners>.orange {
  background-color: #f5dfa7
}

.ribbon-banners>.blue {
  background-color: #acf8de
}

.ribbon-banners>.light-blue {
  background-color: #c4f6f8
}

.ribbon-banners__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  padding: 20px 20px 20px 210px
}

.ribbon-banners__item>.title {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px
}

.ribbon-banners__item>.text,
.ribbon-banners__item>.title {
  color: #333638;

  font-style: normal
}

.ribbon-banners__item>.text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 7px
}

.ribbon-banners__item>.link,
.ribbon-banners__item>.slide-tab__label,
.ribbon-banners__item>[data-js=tooltip],
.tabs__labels.tabs__labels_buttons .ribbon-banners__item>.tab__label {
  display: block;
  font-size: 14px;
  margin-top: 10px
}

.ribbon-banners__item:before {
  background-size: 100% auto;
  content: "";
  display: block;
  height: 92px;
  left: 30px;
  position: absolute;
  width: 145px
}

.ribbon-banners__item_image-percent:before {
  background-image: url(/build/i/b94eed4653b33193ce82.png)
}

.ribbon-banners__item_image-car:before {
  background-image: url('../img/demo/car-transfer-img.png');
}

.ribbon-banners__item_image-sign:before {
  background-image: url('../img/demo/parking-img.png');
}

.ribbon-banners__item_image-piggy-bank:before {
  background-image: url(/build/i/d49a6aa2e3f9cffac2d1.png)
}

.ribbon-banners_margined>* {
  margin-top: 20px
}

@media screen and (max-width:1359px) {
  .page_251 .ribbon-banners-wrapper+.pusher {
    margin-top: 75px !important
  }
}

@media screen and (max-width:1250px) {
  .ribbon-banners>* {
    min-height: 112px
  }

  .ribbon-banners__item {
    padding: 10px 25px 10px 160px
  }

  .ribbon-banners__item>.text {
    font-size: 15px
  }

  .ribbon-banners__item>.title {
    font-size: 16px
  }

  .ribbon-banners__item:before {
    height: 70px;
    left: 20px;
    width: 112px
  }
}

@media screen and (max-width:1023px) {
  .ribbon-banners {
    align-items: center;
    flex-direction: column;
    margin-left: 0;
    margin-right: 0
  }

  .ribbon-banners>* {
    margin-left: 0;
    margin-right: 0;
    max-width: 600px;
    width: 100%
  }

  .ribbon-banners>:not(:last-child) {
    margin-bottom: 20px
  }

  .ribbon-banners__item {
    min-height: inherit;
    padding-left: 210px
  }

  .ribbon-banners__item>.text {
    font-size: 16px
  }

  .ribbon-banners__item:before {
    height: 92px;
    left: 30px;
    width: 145px
  }

  .ribbon-banners__item_image-percent:before {
    left: 55px
  }

  .ribbon-banners_margined>* {
    margin-top: 20px
  }

  .ribbon-banners_margined>:not(:last-child) {
    margin-bottom: 0
  }

  .page_251 .ribbon-banners-wrapper+.pusher {
    margin-top: 60px !important
  }
}

@media screen and (max-width:620px) {

  .ribbon-banners-wrapper_with-padding>.row,
  .ribbon-banners-wrapper_with-padding>.row_short {
    padding-left: 15px;
    padding-right: 15px
  }

  .ribbon-banners>* {
    max-width: 100%;
    min-height: 94px
  }

  .ribbon-banners__item {
    align-items: flex-start;
    background-image: none;
    display: flex;
    flex-direction: column;
    padding-left: 108px;
    padding-right: 15px
  }

  .ribbon-banners__item>.title {
    font-size: 14px;
    line-height: 18px;
    order: 1
  }

  .ribbon-banners__item>.text {
    font-size: 12px;
    line-height: 16px;
    margin-top: 0;
    order: 2
  }

  .ribbon-banners__item>.text br {
    display: none
  }

  .ribbon-banners__item>.link,
  .ribbon-banners__item>.slide-tab__label,
  .ribbon-banners__item>[data-js=tooltip],
  .tabs__labels.tabs__labels_buttons .ribbon-banners__item>.tab__label {
    margin-top: 20px;
    order: 4
  }

  .ribbon-banners__item:before {
    height: 54px;
    left: 0;
    width: 100px
  }

  .ribbon-banners__item_image-percent:before {
    background-image: url(/build/i/b94eed4653b33193ce82.png);
    height: 70px;
    margin-left: 12px;
    width: 100px
  }

  .ribbon-banners__item_image-car>.link,
  .ribbon-banners__item_image-car>.slide-tab__label,
  .ribbon-banners__item_image-car>[data-js=tooltip],
  .tabs__labels.tabs__labels_buttons .ribbon-banners__item_image-car>.tab__label {
    margin-top: 5px
  }

  .ribbon-banners__item_image-car:before {
    left: 8px;
    margin-top: 0;
    width: 90px
  }

  .ribbon-banners__item_image-sign:before {
    background-position: 50%;
    background-size: contain;
    height: 64px;
    width: 110px
  }

  .page_251 .ribbon-banners-wrapper+.pusher {
    margin-top: 30px !important
  }
}

.study_abroad__inner {
  background-color: #f4f7f8;
  border-radius: 7px;
  overflow: hidden;
  padding: 40px 50px 45px;
  position: relative
}

.study_abroad__inner>p {
  color: #4c5562;
  font-size: 18px;
  line-height: 26px;
  margin: 15px 0 30px;
  max-width: 700px;
  position: relative;
  z-index: 1
}

.study_abroad__inner .link,
.study_abroad__inner .slide-tab__label,
.study_abroad__inner .tabs__labels.tabs__labels_buttons .tab__label,
.study_abroad__inner [data-js=tooltip],
.tabs__labels.tabs__labels_buttons .study_abroad__inner .tab__label {
  font-size: 15px;
  font-weight: 500;
  margin-right: 50px;
  text-transform: uppercase
}

.study_abroad__img {
  position: absolute;
  right: 0;
  top: 0
}

.study_abroad__title {
  color: #2d3e50;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  position: relative;
  z-index: 1
}

@media screen and (max-width:1359px) {
  .page_251 .study_abroad+.pusher {
    margin-top: 65px !important
  }
}

@media screen and (max-width:1199px) {
  .study_abroad__img {
    height: 100%;
    opacity: .3
  }
}

@media screen and (max-width:1023px) {
  .page_251 .study_abroad+.pusher {
    margin-top: 50px !important
  }
}

@media screen and (max-width:767px) {
  .study_abroad__inner {
    padding: 40px 30px 45px
  }

  .study_abroad__img {
    display: none
  }
}

@media screen and (max-width:480px) {
  .study_abroad__inner {
    padding: 30px 15px 35px
  }

  .study_abroad__inner>p {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 20px
  }

  .study_abroad__inner .link,
  .study_abroad__inner .slide-tab__label,
  .study_abroad__inner .tabs__labels.tabs__labels_buttons .tab__label,
  .study_abroad__inner [data-js=tooltip],
  .tabs__labels.tabs__labels_buttons .study_abroad__inner .tab__label {
    font-size: 13px;
    margin-right: 25px
  }
}

.microscope__content {
  background-color: #f2e5d6;
  border-radius: 10px;
  padding: 40px 0 45px 40px;
  position: relative
}

.microscope__content .progressive-image-wrapper {
  bottom: 0;
  position: absolute;
  right: 0
}

.microscope__title {
  color: #3c4143;

  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px
}

.microscope__text {
  color: #5f6877;
  font-size: 20px;
  line-height: 28px;
  margin: 30px 0;
  max-width: 750px
}

.microscope__text span {

  font-style: normal;
  font-weight: 500
}

@media screen and (max-width:1150px) {
  .microscope__text {
    max-width: 595px
  }
}

@media screen and (max-width:1023px) {
  .microscope__content {
    padding: 40px 40px 0;
    text-align: center
  }

  .microscope__content .progressive-image-wrapper {
    display: block;
    margin: 30px auto 0;
    position: static
  }

  .microscope__text {
    max-width: 100%
  }
}

@media screen and (max-width:767px) {
  .microscope__content {
    border-radius: 0;
    margin: 0 -15px
  }

  .microscope__title {
    font-size: 24px
  }

  .microscope__text {
    font-size: 18px;
    line-height: 24px;
    margin: 25px 0
  }
}

@media screen and (max-width:480px) {
  .microscope__content {
    padding: 30px 20px 0 25px
  }

  .microscope__content .progressive-image-wrapper {
    width: 100% !important
  }
}

.banner-confidence__grid {
  border-radius: 10px;
  overflow: hidden
}

.banner-confidence__grid>* {
  align-items: center;
  display: flex;
  min-height: 150px
}

.banner-confidence__grid>:nth-child(odd) {
  padding: 20px 22px
}

.banner-confidence__grid>:nth-child(2n) {
  padding: 20px 22px 20px 36px
}

.banner-confidence__item {
  position: relative
}

.banner-confidence__item.red {
  background-color: #863b47
}

.banner-confidence__item.red .title {
  color: #f5d588;
  font-size: 18px;
  line-height: 26px
}

.banner-confidence__item.red .text {
  color: #f5d588;
  font-size: 16px;
  line-height: 26px
}

.banner-confidence__item.red-white {
  background-color: #863b47
}

.banner-confidence__item.red-white .title {
  color: #fff
}

.banner-confidence__item.red-white .text {
  color: #fff;
  max-width: 390px
}

.banner-confidence__item.blue {
  background-color: #54cac8
}

.banner-confidence__item.blue .title {
  color: #333638
}

.banner-confidence__item.blue .text {
  color: #5f6877
}

.banner-confidence__item.grey {
  background-color: #e8f0f3
}

.banner-confidence__item.grey .title {
  color: #333638
}

.banner-confidence__item.grey .text {
  color: #5f6877;
  max-width: 335px
}

.banner-confidence__item.orange {
  background-color: #e58c00
}

.banner-confidence__item.orange .title {
  color: #fff
}

.banner-confidence__item.orange .text {
  color: #fff;
  max-width: 350px
}

.banner-confidence__img {
  margin-right: 20px;
  min-width: 60px;
  width: 60px
}

.banner-confidence__text {
  position: relative;
  z-index: 1
}

.banner-confidence__text .title {

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px
}

.banner-confidence__text .text {
  font-size: 14px;
  line-height: 24px
}

.banner-confidence__bg {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 221px
}

@media screen and (max-width:1380px) {
  .banner-confidence+.pusher {
    margin-top: 65px !important
  }

  .banner-confidence__bg {
    opacity: .6
  }
}

@media screen and (max-width:1023px) {
  .banner-confidence__grid>* {
    padding: 20px 26px !important
  }

  .banner-confidence__item.red .title {
    font-size: 16px;
    line-height: 24px
  }

  .banner-confidence__item.red .text {
    font-size: 14px;
    line-height: 22px
  }

  .banner-confidence__text .title {
    font-size: 16px;
    line-height: 24px
  }

  .banner-confidence__text .text {
    font-size: 14px;
    line-height: 22px
  }
}

@media screen and (max-width:930px) {
  .banner-confidence__item.red .banner-confidence__bg {
    right: -35px
  }

  .banner-confidence__img {
    display: none
  }
}

@media screen and (max-width:730px) {
  .banner-confidence+.pusher {
    margin-top: 25px !important
  }

  .banner-confidence__grid {
    border-radius: 0;
    flex-direction: column;
    margin-left: -15px;
    margin-right: -15px
  }

  .banner-confidence__grid>* {
    padding: 20px 15px !important
  }

  .banner-confidence__img {
    display: block
  }

  .banner-confidence__item {
    position: relative
  }
}

@media screen and (max-width:420px) {
  .banner-confidence__img {
    display: none
  }
}

@media screen and (max-width:359px) {

  .banner-confidence__text .text br,
  .banner-confidence__text .title br {
    display: none
  }
}

.attainment-image-slider {
  border-radius: 8px;
  box-shadow: 0 0 29px rgba(70, 81, 86, .15);
  flex-wrap: nowrap;
  overflow: hidden;
  position: relative
}

.attainment-image-slider>:first-child {
  flex: 1 0 calc(100% - 495px);
  padding: 55px 60px 20px 130px;
  position: relative
}

.attainment-image-slider>:first-child>svg {
  left: 45px;
  position: absolute;
  top: 45px;
  width: 56px
}

.attainment-image-slider>:first-child:before {
  background-image: url(/build/i/cd469867279222393ec9.svg);
  background-repeat: repeat;
  background-size: 21px 26px;
  bottom: 0;
  content: "";
  display: block;
  height: 99%;
  left: 0;
  position: absolute;
  width: 294px;
  z-index: -1
}

.attainment-image-slider>:nth-child(2) {
  flex: 1 0 495px;
  max-width: 495px
}

.attainment-image-slider>:nth-child(2) .slick-list {
  line-height: 0 !important
}

.attainment-image-slider>:nth-child(2) .progressive-image-wrapper {
  border-radius: 0;
  width: 100% !important
}

.attainment-image-slider>:nth-child(2) .image-slider__navigator {
  bottom: 35px;
  top: auto
}

.attainment-image-slider .slider-wrapper-image .progressive-image-wrapper {
  display: block
}

.attainment-image-slider__title {
  color: #333638;
  font-size: 36px;
  line-height: 40px;
  margin-bottom: 15px;
  text-align: left
}

.attainment-image-slider__text>:last-child {
  margin-bottom: 0 !important
}

.attainment-image-slider__text p {
  color: #5f6877;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 20px;
  text-align: left
}

.attainment-image-slider__text a {
  font-size: 14px
}

.attainment-image-slider-wrapper_allon4 .attainment-image-slider {
  background-position: top -4px left 5px
}

.attainment-image-slider-wrapper_allon4 .attainment-image-slider>:first-child {
  padding-left: 53px;
  padding-top: 45px
}

.attainment-image-slider-wrapper_allon4 .attainment-image-slider>:first-child svg {
  display: none
}

.attainment-image-slider-wrapper_allon4 .attainment-image-slider>:first-child:before {
  content: none
}

.attainment-image-slider-wrapper_allon4 .attainment-image-slider .minus-list,
.attainment-image-slider-wrapper_allon4 .attainment-image-slider .plus-list {
  margin-top: 30px
}

.attainment-image-slider-wrapper_allon4 .attainment-image-slider__text .button,
.attainment-image-slider-wrapper_allon4 .attainment-image-slider__text .slide-tab__label.active,
.attainment-image-slider-wrapper_allon4 .attainment-image-slider__text .tabs__labels.tabs__labels_buttons .tab__label.active,
.tabs__labels.tabs__labels_buttons .attainment-image-slider-wrapper_allon4 .attainment-image-slider__text .tab__label.active {
  margin-top: 20px
}

@media screen and (max-width:1380px) {
  .attainment-image-slider-wrapper+.pusher {
    margin-top: 35px !important
  }

  .attainment-image-slider {
    align-items: center;
    box-shadow: none
  }

  .attainment-image-slider>:first-child {
    background-image: none
  }

  .attainment-image-slider>:nth-child(2) .progressive-image-wrapper {
    border-radius: 10px
  }

  .attainment-image-slider__text p {
    margin-bottom: 10px
  }

  .attainment-image-slider-wrapper_allon4 .attainment-image-slider {
    align-items: stretch;
    box-shadow: 0 0 29px rgba(70, 81, 86, .15)
  }

  .attainment-image-slider-wrapper_allon4 .attainment-image-slider>:nth-child(2) {
    align-self: auto
  }

  .attainment-image-slider-wrapper_allon4 .attainment-image-slider>:nth-child(2) .progressive-image-wrapper {
    border-radius: 0
  }

  .attainment-image-slider-wrapper_allon4 .slider-wrapper-image {
    height: 100%
  }

  .attainment-image-slider-wrapper_allon4 .slider-wrapper-image .progressive-image-wrapper {
    height: 100% !important
  }

  .attainment-image-slider-wrapper_allon4 .slider-wrapper-image .progressive-image-wrapper img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%
  }

  .attainment-image-slider-wrapper_allon4+.pusher {
    margin-top: 70px !important
  }
}

@media screen and (max-width:1199px) {
  .attainment-image-slider {
    border-radius: 0;
    box-shadow: none;
    flex-direction: column;
    overflow: visible
  }

  .attainment-image-slider>:first-child {
    flex: 1 0 100%;
    margin-bottom: 10px;
    padding: 0 0 0 80px
  }

  .attainment-image-slider>:first-child>svg {
    left: 0;
    top: 3px
  }

  .attainment-image-slider>:first-child:before {
    bottom: auto;
    height: 364px;
    top: 0;
    width: 302px
  }

  .attainment-image-slider>:nth-child(2) {
    align-self: center;
    flex: 1 0 100%;
    max-width: 555px;
    width: 100%
  }

  .attainment-image-slider>:nth-child(2) .slick-slide {
    padding: 30px
  }

  .attainment-image-slider>:nth-child(2) .image-slider__navigator {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%)
  }

  .attainment-image-slider-wrapper_allon4 .attainment-image-slider__title {
    font-size: 32px;
    line-height: 36px
  }

  .attainment-image-slider-wrapper_allon4 .attainment-image-slider {
    align-items: stretch;
    border-radius: 10px;
    flex-direction: row;
    overflow: hidden
  }

  .attainment-image-slider-wrapper_allon4 .attainment-image-slider>:first-child {
    flex: 0 1 calc(58.33% - 24px);
    margin-bottom: 0;
    margin-right: 24px;
    padding: 28px 0 26px 38px
  }

  .attainment-image-slider-wrapper_allon4 .attainment-image-slider>:nth-child(2) {
    flex: 0 1 41.66%
  }

  .attainment-image-slider-wrapper_allon4 .attainment-image-slider .button,
  .attainment-image-slider-wrapper_allon4 .attainment-image-slider .minus-list,
  .attainment-image-slider-wrapper_allon4 .attainment-image-slider .plus-list,
  .attainment-image-slider-wrapper_allon4 .attainment-image-slider .slide-tab__label.active,
  .attainment-image-slider-wrapper_allon4 .attainment-image-slider .tabs__labels.tabs__labels_buttons .tab__label.active,
  .tabs__labels.tabs__labels_buttons .attainment-image-slider-wrapper_allon4 .attainment-image-slider .tab__label.active {
    margin-top: 18px
  }
}

@media screen and (max-width:1023px) {
  .attainment-image-slider>:nth-child(2) .slider-wrapper-image {
    margin-bottom: 0;
    padding-bottom: 0
  }

  .attainment-image-slider>:nth-child(2) .slider__item__image-wrapper {
    margin-top: 0
  }

  .attainment-image-slider-wrapper_allon4 .attainment-image-slider {
    border-radius: 0;
    box-shadow: none;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -26px;
    margin-right: -26px;
    padding-top: 30px
  }

  .attainment-image-slider-wrapper_allon4 .attainment-image-slider>:first-child,
  .attainment-image-slider-wrapper_allon4 .attainment-image-slider>:nth-child(2) {
    flex-basis: 100%
  }

  .attainment-image-slider-wrapper_allon4 .attainment-image-slider>:nth-child(2) .progressive-image-wrapper {
    border-radius: 10px
  }

  .attainment-image-slider-wrapper_allon4 .attainment-image-slider__title {
    text-align: center
  }
}

@media screen and (max-width:767px) {
  .attainment-image-slider-wrapper+.pusher {
    margin-top: 10px !important
  }

  .attainment-image-slider-wrapper+.pusher+.anchor-links {
    border-top: 1px solid #d9e1e1
  }

  .attainment-image-slider-wrapper .h2,
  .attainment-image-slider-wrapper .payment-page h2,
  .payment-page .attainment-image-slider-wrapper h2 {
    font-size: 26px !important;
    line-height: 32px;
    margin-bottom: 10px
  }

  .attainment-image-slider>:first-child {
    padding: 0
  }

  .attainment-image-slider>:first-child:before,
  .attainment-image-slider>:first-child>svg {
    display: none
  }

  .attainment-image-slider>:nth-child(2) .slider-wrapper-image {
    padding-bottom: 40px
  }

  .attainment-image-slider>:nth-child(2) .slick-slide {
    padding: 15px
  }

  .attainment-image-slider__title {
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 30px;
    text-align: center
  }

  .attainment-image-slider__text>:last-child {
    margin-bottom: 0 !important
  }

  .attainment-image-slider__text p {
    font-size: 14px;
    line-height: 22px;
    text-align: center
  }

  .attainment-image-slider__text a {
    display: block;
    font-size: 14px;
    text-align: center
  }

  .attainment-image-slider-wrapper_allon4 .attainment-image-slider {
    margin-left: -15px;
    margin-right: -15px;
    padding-top: 20px
  }

  .attainment-image-slider-wrapper_allon4 .attainment-image-slider>:first-child {
    margin-right: 0;
    padding-left: 15px;
    padding-right: 15px
  }

  .attainment-image-slider-wrapper_allon4 .attainment-image-slider>:nth-child(2) .progressive-image-wrapper {
    border-radius: 0
  }

  .attainment-image-slider-wrapper_allon4 .attainment-image-slider>:nth-child(2) .slider-wrapper-image {
    padding-bottom: 0
  }

  .attainment-image-slider-wrapper_allon4 .attainment-image-slider .button,
  .attainment-image-slider-wrapper_allon4 .attainment-image-slider .slide-tab__label.active,
  .attainment-image-slider-wrapper_allon4 .attainment-image-slider .tabs__labels.tabs__labels_buttons .tab__label.active,
  .tabs__labels.tabs__labels_buttons .attainment-image-slider-wrapper_allon4 .attainment-image-slider .tab__label.active {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 124px;
    padding-left: 10px;
    padding-right: 10px
  }

  .attainment-image-slider-wrapper_allon4 .attainment-image-slider__title {
    line-height: normal;
    margin-left: -5px;
    margin-right: -5px
  }

  .attainment-image-slider-wrapper_allon4+.pusher {
    margin-top: 42px !important
  }
}

@media screen and (max-width:512px) {
  .attainment-image-slider>:nth-child(2) .slick-slide {
    padding: 15px 0
  }

  .attainment-image-slider>:nth-child(2) .progressive-image-wrapper {
    border-radius: 0
  }
}

.payment-page .price-formula h2,
.price-formula .h2,
.price-formula .payment-page h2 {
  font-size: 42px
}

.price-formula .h2-sub {
  margin-bottom: 35px;
  margin-top: 15px;
  max-width: 870px
}

.price-formula .button_with-plus {
  font-size: 16px;
  letter-spacing: normal;
  margin-bottom: 50px;
  padding-left: 50px;
  padding-right: 30px
}

.price-formula .button_with-plus__icon-plus {
  left: 20px
}

.price-formula__stage {
  align-items: center;
  background-color: #f2f6f8;
  border-radius: 8px;
  margin-bottom: 42px;
  overflow: hidden
}

.price-formula__items {
  background-color: #fff;
  border: 10px solid #f2f6f8;
  flex: 1 0 calc(100% - 285px)
}

.price-formula__items>* {
  flex: 1 0 33%
}

.price-formula__item {
  color: #5f6877;
  padding: 45px 25px;
  position: relative
}

.price-formula__item .progressive-image-wrapper {
  border-radius: 50%;
  margin-bottom: 30px;
  width: 100px
}

.price-formula__item .num {
  font-size: 47px;
  left: 20px;
  position: absolute;
  top: 40px;
  z-index: 1
}

.price-formula__item .num,
.price-formula__item .title {

  font-style: normal;
  font-weight: 500;
  text-transform: uppercase
}

.price-formula__item .title {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px
}

.price-formula__item .text {
  font-size: 13px;
  line-height: 22px
}

.price-formula__total {
  flex: 1 0 285px;
  min-width: 220px;
  padding: 30px;
  text-align: center
}

.price-formula__total>:last-child {
  margin-bottom: 0 !important
}

.price-formula__total .title {
  color: #5f6877;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 10px
}

.price-formula__total .subtitle {
  color: #5f6877;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px
}

.price-formula__total .price-old {
  color: #5f6877;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 10px;
  text-decoration: line-through
}

.price-formula__total .price-new {
  color: #3c4143;

  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 10px
}

.price-formula__total .price-new.two {
  font-size: 20px
}

.price-formula__total .or {
  color: #3c4143;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 5px
}

.price-formula__total .text {
  color: #939ba9;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px
}

.price-formula__total .label-action {
  background-color: #fcfb9b;
  border-radius: 15px;
  color: #3c4143;
  display: inline-block;
  font-size: 13px;
  line-height: 20px;
  min-width: 172px;
  padding: 5px 10px
}

.price-formula_without-prices .price-formula__items {
  padding-left: 20px;
  padding-right: 20px
}

@media screen and (max-width:1380px) {

  .payment-page .price-formula h2,
  .price-formula .h2,
  .price-formula .payment-page h2 {
    line-height: 48px
  }

  .price-formula .h2-sub {
    margin-bottom: 40px;
    margin-top: 20px
  }

  .price-formula .button_with-plus {
    margin-bottom: 40px
  }

  .price-formula+.pusher {
    margin-top: 40px !important
  }

  .price-formula__stage {
    margin-bottom: 30px
  }

  .price-formula__items {
    flex: 1 0 calc(100% - 220px)
  }

  .price-formula__item {
    padding: 30px 10px
  }

  .price-formula__item .title {
    font-size: 13px;
    line-height: 18px
  }

  .price-formula__item .text {
    font-size: 12px;
    line-height: 20px
  }

  .price-formula__item .num {
    left: 10px;
    top: 30px
  }

  .price-formula__total {
    flex: 1 0 140px;
    padding: 30px 20px
  }

  .price-formula__total .title {
    font-size: 18px;
    line-height: 22px
  }

  .price-formula__total .price-new {
    font-size: 22px;
    line-height: 24px
  }

  .price-formula__total .price-new.two {
    font-size: 18px
  }
}

@media screen and (max-width:1024px) {

  .payment-page .price-formula h2,
  .price-formula .h2,
  .price-formula .payment-page h2 {
    font-size: 36px;
    line-height: 44px
  }
}

@media screen and (max-width:1023px) {
  .price-formula+.pusher {
    margin-top: 20px !important
  }

  .price-formula .h2-sub {
    margin-bottom: 30px
  }

  .price-formula__stage {
    flex-direction: column
  }

  .price-formula__items {
    flex: 1 0 100%
  }

  .price-formula__item {
    padding: 30px 10px
  }

  .price-formula__item .num {
    top: 20px
  }

  .price-formula__total {
    flex: 1 0 100%;
    padding: 15px 30px 25px
  }

  .price-formula__total .price-old {
    line-height: 18px
  }

  .price-formula__total .price-wrap {
    align-items: flex-end;
    display: flex;
    justify-content: center;
    margin-bottom: 8px
  }

  .price-formula__total .price-wrap>* {
    margin-bottom: 0;
    margin-left: 6px;
    margin-right: 6px
  }

  .price-formula__total .group-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 20px
  }

  .price-formula__total .group-wrapper .price-new {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 8px
  }

  .price-formula__total .group-wrapper>* {
    margin-left: 20px;
    margin-right: 20px
  }

  .price-formula__total .group-wrapper .group>:last-child {
    margin-bottom: 0
  }

  .price-formula__total .or {
    align-self: flex-start;
    font-size: 14px;
    margin-top: 3px
  }
}

@media screen and (max-width:767px) {

  .payment-page .price-formula h2,
  .price-formula .h2,
  .price-formula .payment-page h2 {
    font-size: 26px !important;
    line-height: 32px
  }

  .price-formula .h2-sub {
    display: none
  }

  .price-formula .button_with-plus {
    font-size: 10px;
    height: 39px;
    line-height: 40px;
    margin-bottom: 30px;
    margin-top: 15px;
    padding-left: 40px;
    padding-right: 20px
  }

  .price-formula .button_with-plus__icon-plus {
    left: 17px
  }

  .price-formula__stage {
    margin-bottom: 30px
  }

  .price-formula__items>* {
    flex: 1 0 100%
  }

  .price-formula__item {
    padding: 20px 10px;
    text-align: center
  }

  .price-formula__item .progressive-image-wrapper {
    margin-bottom: 20px
  }

  .price-formula__item .num {
    left: calc(50% - 40px);
    transform: translateX(-50%)
  }

  .price-formula__item .title {
    margin-bottom: 15px
  }

  .price-formula__total {
    padding: 20px 15px
  }

  .price-formula__total .title {
    font-size: 16px;
    line-height: 20px
  }

  .price-formula__total .text {
    font-size: 14px
  }

  .price-formula__total .price-new {
    font-size: 18px;
    line-height: 22px
  }

  .price-formula__total .price-new.two {
    font-size: 16px
  }

  .price-formula__total .or {
    font-size: 14px;
    line-height: 24px
  }

  .price-formula_without-prices .price-formula__stages {
    margin-top: 30px
  }
}

@media screen and (max-width:512px) {
  .price-formula+.pusher {
    margin-top: 5px !important
  }

  .price-formula .button_with-plus {
    align-items: center;
    border-radius: 0;
    display: flex;
    justify-content: center;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100vw
  }

  .price-formula .button_with-plus__icon-plus {
    margin-right: 8px;
    margin-top: -4px;
    position: static
  }

  .price-formula__total .title {
    font-size: 18px;
    line-height: 22px
  }

  .price-formula__total .text {
    margin-bottom: 10px !important
  }

  .price-formula__total .text br {
    display: none
  }

  .price-formula__total .price-new {
    font-size: 20px !important
  }

  .price-formula__total .price-new.two {
    font-size: 18px !important
  }

  .price-formula__total .group-wrapper {
    flex-direction: column;
    margin-bottom: 0;
    margin-top: 0
  }

  .price-formula__total .group-wrapper>* {
    margin-left: 0;
    margin-right: 0
  }

  .price-formula__total .group-wrapper>.or {
    margin: -8px auto 0
  }
}

.banner-warning {
  align-items: center;
  background-color: #e8f0f3;
  border-radius: 8px;
  flex-wrap: nowrap;
  min-height: 290px;
  overflow: hidden;
  padding: 0 27px 0 80px;
  position: relative
}

.banner-warning:before {
  background-image: url(/build/i/cd469867279222393ec9.svg);
  background-repeat: repeat;
  background-size: 21px 26px;
  bottom: 0;
  content: "";
  display: block;
  height: 99%;
  position: absolute;
  right: 0;
  width: 285px
}

.banner-warning .progressive-image-wrapper {
  align-self: flex-end;
  border-radius: 0;
  margin-left: 120px;
  max-width: 283px;
  min-width: 283px
}

.banner-warning>:first-child {
  margin-bottom: 20px;
  margin-top: 20px;
  position: relative
}

.banner-warning>:first-child:before {
  background-image: url(/build/i/45a4ee629f88dcebb4a8.svg);
  content: "";
  display: block;
  height: 30px;
  left: -51px;
  position: absolute;
  top: 4px;
  width: 30px
}

.banner-warning__title {
  color: #333638;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 10px
}

.banner-warning__text {
  color: #5f6877;
  font-size: 16px;
  line-height: 28px
}

.banner-warning__text strong {

  font-style: normal;
  font-weight: 500
}

.banner-warning__text p:not(:last-child) {
  margin-bottom: 10px
}

.banner-warning__text p.notice {
  font-size: 14px
}

@media screen and (max-width:1380px) {
  .banner-warning-wrapper+.pusher {
    margin-top: 70px !important
  }

  .banner-warning {
    padding: 0 27px 0 80px
  }

  .banner-warning:before {
    width: 250px
  }

  .banner-warning>:first-child {
    margin-bottom: 20px;
    margin-top: 40px
  }

  .banner-warning .progressive-image-wrapper {
    margin-left: 40px;
    max-width: 250px;
    min-width: 250px
  }

  .banner-warning__title {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 5px
  }

  .banner-warning__text {
    line-height: 28px
  }

  .banner-warning__text strong {

    font-style: normal;
    font-weight: 500
  }

  .banner-warning__text p:not(:last-child) {
    margin-bottom: 10px
  }

  .banner-warning__text p.notice {
    font-size: 14px
  }
}

@media screen and (max-width:1023px) {
  .banner-warning-wrapper+.pusher {
    margin-top: 50px !important
  }

  .banner-warning {
    border-radius: 0;
    margin-left: -26px;
    margin-right: -26px;
    padding: 0 0 0 80px
  }

  .banner-warning:before {
    width: 219px
  }

  .banner-warning .progressive-image-wrapper {
    margin-left: 0;
    margin-right: -30px;
    max-width: 250px;
    min-width: 250px
  }

  .banner-warning>:first-child {
    margin-bottom: 35px;
    margin-top: 40px
  }

  .banner-warning>:first-child:before {
    left: -51px;
    top: 4px
  }

  .banner-warning__title {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 10px
  }

  .banner-warning__text {
    font-size: 14px;
    line-height: 24px
  }
}

@media screen and (max-width:767px) {
  .banner-warning-wrapper+.pusher {
    margin-top: 25px !important
  }

  .banner-warning__title {
    font-size: 16px;
    line-height: 26px
  }

  .banner-warning__text {
    font-size: 14px;
    line-height: 24px
  }

  .banner-warning__text p:not(:last-child) {
    margin-bottom: 5px
  }

  .banner-warning {
    flex-direction: column;
    margin-left: -15px;
    margin-right: -15px;
    padding: 20px 15px 0
  }

  .banner-warning:before {
    height: 28%;
    min-height: 112px;
    width: 100%
  }

  .banner-warning .progressive-image-wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 250px;
    min-width: 147px
  }

  .banner-warning>:first-child {
    margin-bottom: 20px;
    margin-top: 0
  }

  .banner-warning>:first-child:before {
    margin-bottom: 15px;
    position: static
  }

  .banner-warning__title {
    margin-bottom: 10px
  }
}

@media screen and (max-width:512px) {
  .banner-warning:before {
    height: 112px
  }

  .banner-warning .progressive-image-wrapper {
    max-width: 147px
  }
}

.banner-plus {
  align-items: center;
  background-color: #e58c00;
  border-radius: 8px;
  max-width: 1064px;
  min-height: 98px;
  padding: 8px 24px;
  width: 100%
}

.banner-plus:before {
  background-image: url(/build/i/9f951c14ea19f8e14255.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  content: "";
  display: block;
  height: 35px;
  margin-left: 20px;
  margin-right: 30px;
  min-width: 32px;
  width: 32px
}

.banner-plus__content {
  max-width: 680px
}

.banner-plus__title {
  color: #fff;

  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px
}

.banner-plus__text {
  color: #fff;
  font-size: 16px;
  line-height: 24px
}

.banner-plus__img {
  margin-left: 60px;
  max-width: 161px
}

@media screen and (max-width:1380px) {
  .banner-plus-wrapper+.pusher {
    margin-top: 70px !important
  }

  .banner-plus {
    padding: 8px 32px 8px 23px
  }

  .banner-plus:before {
    margin-left: 0;
    margin-right: 26px
  }

  .banner-plus__title {
    font-size: 20px
  }

  .banner-plus__text {
    font-size: 14px
  }

  .banner-plus__img {
    margin-left: 40px
  }
}

@media screen and (max-width:1023px) {
  .banner-plus-wrapper+.pusher {
    margin-top: 55px !important
  }

  .banner-plus {
    padding: 15px 24px
  }

  .banner-plus:before {
    display: none
  }

  .banner-plus__content {
    max-width: none
  }

  .banner-plus__title {
    font-size: 17px;
    line-height: 22px;
    margin-bottom: 5px
  }

  .banner-plus__text {
    font-size: 14px;
    line-height: 22px
  }

  .banner-plus__img {
    margin-left: 10px
  }
}

@media screen and (max-width:767px) {
  .banner-plus-wrapper+.pusher {
    margin-top: 30px !important
  }

  .banner-plus {
    border-radius: 0;
    flex-direction: column;
    margin-left: -15px;
    margin-right: -15px;
    width: auto
  }

  .banner-plus__title {
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 10px;
    text-align: center
  }

  .banner-plus__text {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 18px;
    text-align: center
  }

  .banner-plus__img {
    max-width: 100px
  }
}

.payment-page .prices-tabs h2,
.prices-tabs .h2,
.prices-tabs .payment-page h2 {
  font-size: 42px
}

.prices-tabs .h2-sub {
  margin-top: 15px
}

.prices-tabs__tabs {
  margin-top: 40px
}

.prices-tabs__tabs-labels {
  text-align: center
}

.prices-tabs__tabs-labels.tabs__labels {
  border-bottom: none;
  margin-bottom: 38px
}

.prices-tabs__tabs-labels.tabs__labels .tab__label {
  background-color: #fff;
  border: 2px solid #00beb4;
  border-radius: 30px;
  color: #00beb4;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: 60px;
  letter-spacing: .42px;
  line-height: 60px;
  margin-left: 13px;
  margin-right: 13px;
  padding-left: 50px;
  padding-right: 50px;
  text-align: center;
  text-transform: uppercase;
  transition: all .2s ease-in;
  z-index: 3
}

.prices-tabs__tabs-labels.tabs__labels .tab__label:hover {
  background-color: #fff;
  box-shadow: 0 10px 36px 4px rgba(70, 81, 86, .17);
  color: #5f6877;
  transition: all .2s ease-out
}

.prices-tabs__tabs-labels.tabs__labels .tab__label.active {
  background-color: #00beb4;
  border: 2px solid #00beb4;
  border-radius: 30px;
  color: #fff;
  transition: all .2s ease-out
}

.prices-tabs__tabs-contents {
  position: relative
}

.prices-tabs__tabs-contents .tab {
  display: block;
  margin-top: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 1
}

.prices-tabs__tabs-contents .tab.active {
  opacity: 1;
  position: relative;
  z-index: 2
}

.prices-tabs__advantages {
  align-items: center;
  border: 10px solid #f2f6f8;
  border-radius: 8px;
  justify-content: center;
  margin-bottom: 30px;
  min-height: 255px;
  padding-bottom: 44px;
  padding-top: 44px
}

.prices-tabs__advantages>* {
  flex: 1 0 calc(33% - 40px);
  margin-left: 20px;
  margin-right: 20px;
  text-align: center
}

.prices-tabs__advantages>:before {
  background-image: url(/build/i/c1efc79d47a430d1eab8.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  content: "";
  display: block;
  height: 42px;
  margin-bottom: 10px;
  width: 42px
}

.prices-tabs__advantages>* .title {
  color: #333638;
  display: block;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px
}

.prices-tabs__advantages>* .text {
  color: #5f6877;
  display: block;
  font-size: 16px;
  line-height: 24px
}

.prices-tabs__compare-prices {
  margin-left: -24px;
  margin-right: -24px
}

.prices-tabs__compare-prices .compare-prices {
  margin-left: 0;
  margin-right: 0;
  margin-top: 18px
}

.prices-tabs__compare-prices .slick-list {
  padding: 24px 0
}

.prices-tabs__compare-prices .compare-prices__block-installment {
  width: calc(100% - 48px) !important
}

.prices-tabs__compare-prices .slick-dots {
  bottom: 0;
  display: none !important;
  position: absolute;
  width: 100%
}

.prices-tabs__compare-prices .slick-arrow {
  align-items: center;
  background-color: #00beb4;
  border-radius: 50%;
  display: flex;
  height: 50px;
  justify-content: center;
  left: -25px;
  padding-right: 18px;
  position: absolute;
  top: 280px;
  width: 50px;
  z-index: 5
}

.prices-tabs__compare-prices .slick-arrow.slick-disabled {
  opacity: .7
}

.prices-tabs__compare-prices .slick-arrow.next {
  left: auto;
  right: -25px
}

.prices-tabs__compare-prices .slick-arrow.prev {
  transform: rotate(-180deg)
}

.prices-tabs__compare-prices .slick-arrow>svg {
  height: 14px;
  width: 14px
}

.prices-tabs__compare-prices .slick-arrow>svg>path {
  fill: #fff
}

.prices-tabs__tabs_allon4 .compare-price__extraction {
  background-color: #ebf3f5
}

.prices-tabs__tabs_allon4 .compare-prices__block__table>div:last-child {
  align-items: center
}

.prices-tabs__tabs_allon4 .compare-prices__block__table>div:last-child .new {

  font-style: normal;
  font-weight: 400
}

.prices-tabs__tabs_allon4 .compare-prices__block__table>div.compare-prices__block__implants .new {

  font-style: normal;
  font-weight: 500
}

.prices-tabs__tabs_allon4 .compare-prices__block__table [data-js=tooltip] {
  font-size: 13px;
  line-height: 22px;
  position: relative;
  top: 0
}

@media screen and (max-width:1380px) {

  .payment-page .prices-tabs h2,
  .prices-tabs .h2,
  .prices-tabs .payment-page h2 {
    font-size: 38px
  }

  .prices-tabs__advantages>* .title {
    font-size: 16px;
    line-height: 24px
  }

  .prices-tabs__advantages>* .text {
    font-size: 14px;
    line-height: 22px
  }

  .prices-tabs__compare-prices {
    margin-left: -36px;
    margin-right: -36px
  }

  .prices-tabs__compare-prices .compare-prices__block__right {
    margin-top: 5px
  }

  .prices-tabs__compare-prices .compare-prices__block__table>div {
    padding-bottom: 10px;
    padding-top: 10px
  }

  .prices-tabs__compare-prices .compare-price__extraction {
    padding-bottom: 11px;
    padding-top: 12px
  }

  .prices-tabs__compare-prices .slick-list {
    padding-left: 24px;
    padding-right: 24px
  }

  .prices-tabs__compare-prices .compare-prices__block-installment {
    width: calc(100% - 24px) !important
  }

  .page_251 .prices-tabs+.pusher {
    margin-top: 35px !important
  }

  .prices-tabs__tabs_allon4 .compare-prices__block__table>div:last-child:not([data-js=accordion]) {
    text-align: center
  }

  .prices-tabs__tabs_allon4 .slick-slide {
    width: 285px
  }

  .compare-prices__best-label {
    align-items: center;
    font-size: 14px;
    height: auto;
    margin-left: 10px;
    margin-right: 10px;
    min-height: 36px
  }
}

@media screen and (max-width:1023px) {

  .payment-page .prices-tabs h2,
  .prices-tabs .h2,
  .prices-tabs .payment-page h2 {
    margin-left: auto;
    margin-right: auto;
    max-width: 460px
  }

  .prices-tabs .h2-sub {
    margin-left: auto;
    margin-right: auto;
    margin-top: 22px;
    max-width: 768px
  }

  .prices-tabs__tabs {
    margin-top: 40px
  }

  .prices-tabs__tabs-labels.tabs__labels {
    margin-bottom: 25px
  }

  .prices-tabs__tabs-labels.tabs__labels .tab__label {
    border-radius: 24px;
    font-size: 13px;
    height: 48px;
    line-height: 44px;
    padding-left: 40px;
    padding-right: 40px
  }

  .prices-tabs__tabs-labels.tabs__labels .tab__label.active {
    border-radius: 24px
  }

  .prices-tabs__advantages {
    justify-content: space-around;
    min-height: 290px;
    padding-bottom: 54px;
    padding-top: 54px
  }

  .prices-tabs__advantages>* {
    flex: 1 0 calc(33% - 40px)
  }

  .prices-tabs__advantages>* .title {
    font-size: 16px;
    line-height: 22px
  }

  .prices-tabs__advantages>* .text {
    font-size: 14px;
    line-height: 22px
  }

  .prices-tabs__compare-prices {
    margin-left: -24px;
    margin-right: -24px
  }

  .prices-tabs__compare-prices .slick-list {
    padding-left: 15px;
    padding-right: 15px
  }

  .prices-tabs__compare-prices .compare-prices__block-installment {
    margin-left: 24px;
    margin-right: 24px
  }

  .prices-tabs__compare-prices .slick-dots {
    display: flex !important
  }
}

@media screen and (max-width:800px) {
  .prices-tabs__compare-prices {
    margin-left: -26px;
    margin-right: -26px
  }

  .prices-tabs__compare-prices .slick-list {
    padding-left: 15px;
    padding-right: 15px
  }

  .prices-tabs__compare-prices .compare-prices__block-installment {
    margin-left: 15px;
    margin-right: 15px
  }
}

@media screen and (max-width:767px) {

  .payment-page .prices-tabs h2,
  .prices-tabs .h2,
  .prices-tabs .payment-page h2 {
    font-size: 26px !important;
    line-height: 32px
  }

  .prices-tabs .h2-sub {
    display: none
  }

  .prices-tabs__tabs {
    margin-top: 20px
  }

  .prices-tabs__tabs-labels.tabs__labels {
    margin-bottom: 25px
  }

  .prices-tabs__tabs-labels.tabs__labels .tab__label {
    display: block;
    margin-left: auto;
    margin-right: auto
  }

  .prices-tabs__tabs-labels.tabs__labels .tab__label:nth-child(n+2) {
    margin-top: 15px
  }

  .prices-tabs__advantages {
    flex-direction: column;
    margin-bottom: 25px;
    margin-left: -15px;
    margin-right: -15px;
    padding-bottom: 34px;
    padding-top: 34px
  }

  .prices-tabs__advantages>* {
    flex: 1 0 100%;
    margin-left: 15px;
    margin-right: 15px
  }

  .prices-tabs__advantages>:before {
    margin-bottom: 20px
  }

  .prices-tabs__advantages>:not(:first-child) {
    margin-top: 20px
  }

  .prices-tabs__advantages>* .title {
    font-size: 15px;
    line-height: 22px
  }

  .prices-tabs__advantages>* .text {
    font-size: 14px;
    line-height: 22px
  }

  .prices-tabs__compare-prices {
    margin-left: -15px;
    margin-right: -15px
  }

  .prices-tabs__compare-prices .compare-prices {
    padding-bottom: 15px
  }

  .prices-tabs__compare-prices .slick-list {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
    width: calc(100% + 30px)
  }

  .prices-tabs__compare-prices .slick-slide {
    margin-left: 15px;
    margin-right: 15px
  }

  .prices-tabs__compare-prices .compare-prices__block-installment {
    margin-bottom: 50px;
    margin-left: 0;
    margin-right: 0;
    width: 100% !important
  }

  .prices-tabs__compare-prices .slick-arrow {
    height: 40px;
    left: -18px;
    padding-right: 13px;
    width: 40px
  }

  .prices-tabs__compare-prices .slick-arrow.next {
    right: -18px
  }

  .prices-tabs__tabs_allon4 .compare-prices__block__table>div:not([data-js=accordion]),
  .prices-tabs__tabs_allon4 .compare-prices__block__table>div:not([data-js=accordion])>span {
    display: block;
    text-align: center
  }

  .prices-tabs__tabs_allon4 .slick-slide {
    width: auto
  }

  .compare-prices__best-label {
    font-size: 12px;
    padding-left: 10px !important;
    padding-right: 10px !important
  }
}

.mass-media-ribbon.mass-media-ribbon_border {
  border-bottom: 1px solid #d9e1e1;
  border-top: 1px solid #d9e1e1
}

.mass-media-ribbon.mass-media-ribbon_border-top {
  border-top: 1px solid #d9e1e1
}

.mass-media-ribbon.mass-media-ribbon_border-bottom {
  border-bottom: 1px solid #d9e1e1
}

.mass-media-ribbon+.team .team__slider {
  margin-top: 0
}

.mass-media-ribbon__grid {
  padding-bottom: 47px;
  padding-top: 47px
}

.mmr__grid__button {
  flex-basis: 190px
}

.mmr__grid__button .h2,
.mmr__grid__button .payment-page h2,
.payment-page .mmr__grid__button h2 {

  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  margin-bottom: 25px
}

.mmr__grid__button .button,
.mmr__grid__button .slide-tab__label.active,
.mmr__grid__button .tabs__labels.tabs__labels_buttons .tab__label.active,
.tabs__labels.tabs__labels_buttons .mmr__grid__button .tab__label.active {
  text-align: center;
  width: 100%
}

.mmr__grid__list {
  flex-basis: calc(100% - 190px);
  padding-left: 160px;
  position: relative
}

.mass-media-ribbon-slider {
  height: 100%;
  justify-content: flex-end;
  margin-left: -40px;
  margin-right: -40px
}

.mmr__grid__item {
  flex-basis: auto;
  margin-left: 40px;
  margin-right: 40px;
  text-align: center
}

.mmr__grid__item .icon {
  align-items: center;
  height: 54px;
  justify-content: center;
  margin-bottom: 26px
}

.mmr__grid__item .icon .progressive-image-wrapper {
  border-radius: 0
}

.mmr__grid__item .icon .progressive-image-wrapper>.progressive-image {
  max-height: 100%;
  width: auto
}

.mmr__grid__item .text {
  color: #a0abbe;
  font-size: 13px;
  line-height: 16px;
  margin-top: auto;
  transition: all .2s ease-out
}

.mmr__grid__item:hover .text {
  color: #5f6877;
  transition: all .2s ease-in
}

@media screen and (max-width:1280px) {
  .mmr__grid__list {
    padding-left: 60px
  }

  .mass-media-ribbon-slider {
    margin-left: -20px;
    margin-right: -20px
  }

  .mmr__grid__item {
    margin-left: 20px;
    margin-right: 20px
  }

  .mmr__grid__item .icon {
    margin-bottom: 26px
  }

  .mmr__grid__item .text {
    font-size: 12px
  }
}

@media screen and (max-width:1023px) {
  .mass-media-ribbon__grid {
    padding-bottom: 35px;
    padding-top: 35px
  }

  .mmr__grid__button {
    flex-basis: 155px
  }

  .mmr__grid__button .h2,
  .mmr__grid__button .payment-page h2,
  .payment-page .mmr__grid__button h2 {
    font-size: 32px;
    line-height: 44px;
    margin-bottom: 13px
  }

  .mmr__grid__list {
    flex-basis: calc(100% - 155px);
    padding-left: 40px
  }

  .mass-media-ribbon-slider {
    margin-left: -20px;
    margin-right: -20px
  }

  .mmr__grid__item {
    margin-left: 20px;
    margin-right: 20px
  }

  .mmr__grid__item .icon {
    height: 45px;
    margin-bottom: 20px
  }

  .mmr__grid__item:nth-child(n+4) {
    display: none
  }
}

@media screen and (max-width:767px) {
  .mmr__grid__button {
    flex-basis: 190px;
    max-width: 190px
  }

  .mmr__grid__button .h2,
  .mmr__grid__button .payment-page h2,
  .payment-page .mmr__grid__button h2 {
    margin-bottom: 13px
  }

  .mmr__grid__list {
    flex-basis: calc(100% - 250px);
    margin-left: 60px;
    max-width: calc(100% - 250px);
    padding-left: 0;
    width: 100%
  }

  .mass-media-ribbon-slider {
    display: block;
    height: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%
  }

  .mass-media-ribbon-slider>.slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%)
  }

  .mass-media-ribbon-slider>.prev {
    left: 0;
    transform: translateY(-50%) rotate(180deg)
  }

  .mass-media-ribbon-slider>.next {
    right: 0
  }

  .mmr__grid__item {
    margin-left: 0;
    margin-right: 0
  }

  .mmr__grid__item:nth-child(n+2) {
    display: none
  }

  .mmr__grid__item .icon {
    margin-bottom: 15px
  }

  .mmr__grid__item .icon .progressive-image-wrapper .progressive-image {
    margin-left: auto;
    margin-right: auto
  }
}

@media screen and (max-width:600px) {
  .mmr__grid__button {
    margin-left: auto;
    margin-right: auto
  }

  .mmr__grid__button .h2,
  .mmr__grid__button .payment-page h2,
  .payment-page .mmr__grid__button h2 {
    margin-bottom: 20px
  }

  .mmr__grid__list {
    flex-basis: 100%;
    margin-left: 0;
    margin-top: 15px;
    max-width: 100%
  }

  .mmr__grid__item .icon {
    margin-bottom: 10px
  }
}

.charity-modal {
  padding: 50px 0 0;
  text-align: center
}

.charity-modal .mfp-close {
  right: 10px;
  top: 10px
}

.charity-modal .mfp-close:before {
  background-image: url(/build/i/d8a6d26ddbecc46e0b1e.svg);
  background-size: contain;
  height: 16px;
  width: 16px
}

.charity-modal__content {
  background-image: url(/build/i/90e78c17267a33b76420.svg);
  background-position: 50%;
  background-size: 480px auto;
  margin: 0 auto 45px;
  max-width: 845px
}

.charity-modal__icon {
  height: 60px;
  width: 60px
}

.charity-modal__icon>path {
  fill: #dca616
}

.charity-modal__subtitle {
  color: #5f6877;
  font-size: 18px;
  margin: 20px 0
}

.charity-modal__title {
  color: #3c4143;

  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px
}

.charity-modal__title>span {
  background-color: #f4a500;
  color: #fff;
  display: inline-block;
  line-height: 24px;
  padding: 3px 5px 0
}

.charity-modal__text {
  color: #5f6877;
  font-size: 20px;
  line-height: 30px;
  margin: 20px auto 0;
  max-width: 695px
}

.charity-modal__images {
  display: flex;
  justify-content: space-between
}

.charity-modal__images>img {
  border-radius: 0;
  width: calc(33.33% - 4px)
}

@media screen and (max-width:1023px) {
  .charity-modal {
    max-width: calc(100vw - 30px)
  }

  .charity-modal__subtitle {
    font-size: 16px;
    margin: 20px 0
  }

  .charity-modal__title {
    font-size: 22px;
    line-height: 32px
  }

  .charity-modal__text {
    font-size: 16px;
    line-height: 24px
  }

  .charity-modal__content {
    max-width: 560px
  }
}

@media screen and (max-width:767px) {
  .charity-modal__content {
    background-size: 70% auto;
    padding: 0 10px
  }

  .charity-modal {
    border: 10px solid #e9ecee;
    border-radius: 10px;
    padding: 40px 0 0
  }
}

@media screen and (max-width:599px) {
  .charity-modal {
    padding: 30px 0 0
  }

  .charity-modal__icon {
    height: 40px;
    width: 40px
  }

  .charity-modal__subtitle {
    font-size: 14px;
    margin: 10px 0 20px
  }

  .charity-modal__title {
    font-size: 16px;
    line-height: 24px
  }

  .charity-modal__text {
    font-size: 14px;
    line-height: 22px
  }
}

@media screen and (max-width:480px) {
  .charity-modal .mfp-close {
    right: 0;
    top: 0
  }

  .charity-modal__content {
    margin: 0 auto 25px
  }

  .charity-modal__images {
    flex-wrap: wrap;
    justify-content: center
  }

  .charity-modal__images>img {
    margin-top: 7px;
    max-width: 326px;
    width: 100%
  }
}

.charity .h2,
.charity .payment-page h2,
.payment-page .charity h2 {
  color: #333638;

  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin: 20px 0 35px;
  max-width: 690px;
  text-align: left
}

.charity__content {
  background-color: #ebf0f1;
  border-radius: 10px;
  overflow: hidden;
  padding: 15px
}

.charity__inner {
  background-image: url(/build/i/90e78c17267a33b76420.svg);
  background-position: center 33%;
  background-size: 480px auto;
  border: 10px solid rgba(244, 165, 0, .3);
  border-radius: 10px;
  padding: 50px 50px 0
}

.charity__tag {
  background-color: #21c4b3;
  border-radius: 0 20px 20px 0;
  color: #fff;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: 40px;
  line-height: 40px;
  padding-left: 50px;
  position: relative;
  width: 240px
}

.charity__tag:before {
  background-image: url(/build/i/63b2f5a10212c1aff5ae.svg);
  background-size: contain;
  content: "";
  height: 23px;
  left: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 23px
}

.charity__list .charity__mark {
  background-color: #f4a500;
  color: #fff;
  padding: 1px 5px
}

.charity__list>li .text {
  color: #5f6877
}

.charity__img {
  margin-bottom: -35px
}

.charity__img .progressive-image-wrapper {
  width: 100% !important
}

@media screen and (max-width:1280px) {
  .charity__inner {
    padding: 50px 30px 0
  }
}

@media screen and (max-width:1023px) {
  .charity__inner {
    padding: 40px 30px 0
  }

  .charity .h2,
  .charity .payment-page h2,
  .payment-page .charity h2 {
    font-size: 24px;
    line-height: 30px;
    max-width: 600px
  }

  .charity__tag {
    font-size: 14px
  }

  .charity__list>li .text {
    font-size: 14px;
    line-height: 22px
  }

  .charity__img {
    margin: 0 -30px -35px
  }
}

@media screen and (max-width:767px) {

  .charity .h2,
  .charity .payment-page h2,
  .payment-page .charity h2 {
    font-size: 20px !important;
    line-height: 26px;
    margin: 15px 0 20px;
    max-width: 490px
  }

  .charity__inner {
    background-size: 70% auto;
    border: 5px solid rgba(244, 165, 0, .3);
    padding: 25px 20px 0
  }

  .charity__img {
    margin: 0 -20px -35px
  }
}

@media screen and (max-width:480px) {

  .charity .h2,
  .charity .payment-page h2,
  .payment-page .charity h2 {
    font-size: 17px !important;
    line-height: 24px
  }

  .charity__content {
    padding: 9px 8px
  }

  .charity__inner {
    background-size: 90% auto;
    padding: 20px 15px 0
  }

  .charity__tag {
    border-radius: 0 17px 17px 0;
    height: 34px;
    line-height: 34px;
    padding-left: 40px;
    width: 205px
  }

  .charity__tag:before {
    height: 20px;
    left: 10px;
    width: 20px
  }

  .charity__img {
    margin: 0 -15px -18px
  }
}

.seo-table__caption {
  border-right: 1px solid #00beb3
}

.seo-table__caption>th {
  background-color: #00beb3;
  color: #fff;
  display: table-cell;

  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  padding: 15px 25px;
  text-align: left;
  vertical-align: middle
}

.seo-table__row:nth-child(2n) {
  background-color: #fafafc
}

.seo-table__row .prices {
  display: flex;
  font-size: 15px
}

.seo-table__row .prices.percent {

  font-style: normal;
  font-weight: 500;
  padding-left: 28px;
  position: relative
}

.seo-table__row .prices.percent:before {
  background: url(/build/i/64cde44f5e5e8b435c7b.svg) 50% #ff912b no-repeat;
  background-size: 10px 10px;
  border-radius: 50%;
  content: "";
  height: 20px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px
}

.seo-table__row .new-price {
  margin-right: 5px
}

.seo-table__row .old-price {
  color: #a5a5a5;
  text-decoration: line-through
}

.seo-table__row .mark span {
  background-color: #00beb3;
  color: #fff;
  padding: 2px 5px
}

.seo-table__row .light span {
  color: #a5a5a5;
  font-size: 14px
}

.seo-table__row .strong span {
  font-size: 15px;
  line-height: 18px
}

.seo-table__head .seo-table__col {
  color: #3c4143;

  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px
}

.seo-table__col {
  border: 1px solid #d8dfe1;
  color: #5f6877;
  font-size: 14px;
  padding: 20px
}

.seo-table__col:first-child {
  color: #3c4143;

  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px
}

.seo-table_text .h2-sub {
  margin-top: 23px
}

.seo-table_text .h3 {
  color: #5f6877;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-top: 23px
}

.seo-table_text .seo-table__table {
  margin-top: 23px;
  width: 100%
}

.seo-table_price .seo-table__table {
  border-top: 1px solid #d8dfe1;
  max-width: 1224px;
  overflow: hidden;
  width: 100%
}

.seo-table_price .seo-table__col {
  border: none;
  border-bottom: 1px solid #d8dfe1
}

@media screen and (max-width:1199px) {
  .seo-table__caption>th {
    font-size: 14px;
    padding: 13px 15px
  }

  .seo-table__head .seo-table__col {
    font-size: 14px
  }

  .seo-table__col {
    font-size: 13px;
    line-height: 17px;
    padding: 15px
  }

  .prices,
  .seo-table__col:first-child {
    font-size: 14px
  }

  .prices {
    flex-wrap: wrap
  }

  .light span {
    font-size: 13px
  }

  .strong>span {
    font-size: 14px
  }
}

@media screen and (max-width:900px) {
  .seo-table__table {
    display: block;
    overflow-x: auto
  }
}

@media screen and (max-width:767px) {
  .seo-table_price {
    text-align: center
  }

  .seo-table_price .seo-table__row,
  .seo-table_price .seo-table__table,
  .seo-table_price tbody {
    display: block
  }

  .seo-table__col:first-child {
    min-width: 165px;
    width: auto
  }
}

@media screen and (max-width:420px) {
  .seo-table__caption {
    display: none
  }

  .seo-table_text .h3 {
    text-align: center
  }

  .seo-table_text .seo-table__table {
    margin-top: 15px
  }

  .seo-table__table:not(.seo-table__table_expanded) .seo-table__row {
    display: flex;
    flex-wrap: wrap
  }

  .seo-table__table:not(.seo-table__table_expanded) .seo-table__row .prices {
    flex-wrap: nowrap
  }

  .seo-table__table:not(.seo-table__table_expanded) .seo-table__row .prices.percent {
    padding-left: 24px
  }

  .seo-table__table:not(.seo-table__table_expanded) .seo-table__row .prices.percent:before {
    background-size: 8px 8px;
    height: 18px;
    top: -1px;
    transform: none;
    width: 18px
  }

  .seo-table__table:not(.seo-table__table_expanded) .seo-table__row .light span {
    font-size: 14px
  }

  .seo-table__table:not(.seo-table__table_expanded) .seo-table__col {
    border-top: none;
    font-size: 14px;
    padding-left: 20px;
    width: 100%
  }

  .seo-table__table:not(.seo-table__table_expanded) .seo-table__col:first-child {
    background-color: #00beb3;
    border: none;
    color: #fff;
    font-size: 15px;
    line-height: 20px;
    min-width: auto;
    padding: 20px;
    width: 100%
  }

  .seo-table__table:not(.seo-table__table_expanded) .seo-table__col:first-child:before {
    content: none
  }

  .seo-table__table:not(.seo-table__table_expanded) .seo-table__col:before {
    color: #a5a5a5;
    content: attr(data-heading);
    display: block;
    font-size: 13px;
    margin-bottom: 7px;
    position: relative;
    width: 100%
  }
}

.who-suits {
  padding-bottom: 71px;
  padding-top: 60px
}

.who-suits__title {
  text-align: left
}

.who-suits__grid {
  align-items: stretch;
  flex-wrap: nowrap;
  margin-left: -14px;
  margin-right: -14px;
  margin-top: 30px
}

.who-suits__grid__item {
  background-color: #fff;
  border: 14px solid #f2f2f2;
  border-radius: 10px;
  flex: 1 0 calc(25% - 28px) !important;
  margin-left: 14px;
  margin-right: 14px;
  padding-bottom: 30px
}

.who-suits__grid__item .progressive-image-wrapper {
  border-radius: 0;
  height: 170px !important;
  width: 100% !important
}

.who-suits__grid__item .progressive-image-wrapper>img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center
}

.who-suits__grid__item .title {
  color: #333638;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 7px;
  margin-top: 5px;
  padding-left: 16px;
  padding-right: 16px
}

.who-suits__grid__item .text {
  color: #989ca6;
  font-size: 15px;
  line-height: 26px;
  padding-left: 16px;
  padding-right: 16px
}

.who-suits__quote {
  align-items: flex-end;
  background-color: #f2f2f2;
  border-radius: 10px;
  margin-top: 40px;
  min-height: 215px;
  overflow: hidden;
  padding-left: 90px;
  padding-right: 50px
}

.who-suits__quote .progressive-image-wrapper {
  border-radius: 0;
  max-width: 250px
}

.ws__quote__employee {
  position: relative
}

.ws__quote__employee .progressive-image-wrapper {
  display: block;
  margin-left: auto;
  transform: scaleX(-1);
  z-index: 1
}

.ws__quote__employee>div {
  background-color: #fff;
  border-radius: 10px;
  bottom: 36px;
  font-size: 14px;
  line-height: 24px;
  padding: 15px 78px 15px 20px;
  position: absolute;
  right: 150px;
  width: 385px
}

.ws__quote__employee>div a {
  display: block;

  font-style: normal;
  font-weight: 500
}

.ws__quote__employee>div>span {
  color: #a5a5a5
}

.ws__quote__employee>div>span strong {

  font-style: normal;
  font-weight: 500
}

.ws__quote__employee>div:before {
  background-image: url(/build/i/dd7f8f88eab8710bd34b.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  content: "";
  display: block;
  height: 50px;
  position: absolute;
  right: 37px;
  top: -62px;
  width: 100px
}

.ws__quote__quote {
  align-self: center
}

.ws__quote__quote .progressive-image-wrapper {
  display: none
}

.ws__quote__quote blockquote {
  color: #5f6877;

  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: 28px;
  padding-left: 55px;
  position: relative
}

.ws__quote__quote blockquote:before {
  background-image: url(/build/i/289614f9869ba4b363d5.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  content: "";
  display: block;
  height: 24px;
  left: 0;
  position: absolute;
  top: -5px;
  width: 36px
}

@media screen and (max-width:1380px) {
  .who-suits {
    padding-bottom: 33px;
    padding-top: 33px
  }

  .who-suits__title {
    font-size: 46px;
    line-height: 52px
  }

  .who-suits__grid {
    flex-wrap: wrap;
    margin-left: 21px;
    margin-right: 21px;
    margin-top: 55px
  }

  .who-suits__grid__item {
    flex: 1 0 calc(50% - 38px) !important;
    margin-left: 19px;
    margin-right: 19px
  }

  .who-suits__grid__item:not(:nth-child(n+3)) {
    margin-bottom: 38px
  }

  .who-suits__grid__item .progressive-image-wrapper {
    height: 175px !important
  }

  .who-suits__grid__item .title {
    margin-top: 0
  }

  .who-suits__quote {
    padding-left: 20px;
    padding-right: 55px
  }

  .ws__quote__employee {
    margin-right: -40px;
    padding-right: 0
  }

  .ws__quote__employee>div {
    right: 123px
  }

  .ws__quote__employee>div:before {
    right: 63px
  }
}

@media screen and (max-width:1259px) {
  .ws__quote__quote {
    max-width: 43%
  }
}

@media screen and (max-width:1023px) {
  .who-suits__title {
    font-size: 36px !important;
    line-height: 42px !important
  }

  .who-suits__grid {
    margin-left: -12px;
    margin-right: -12px;
    margin-top: 35px
  }

  .who-suits__grid__item {
    flex: 1 0 calc(50% - 24px) !important;
    margin-left: 12px;
    margin-right: 12px;
    padding-bottom: 25px
  }

  .who-suits__grid__item:not(:nth-child(n+3)) {
    margin-bottom: 24px
  }

  .who-suits__grid__item .progressive-image-wrapper {
    height: 170px !important
  }

  .who-suits__grid__item .title {
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 10px;
    margin-top: 5px
  }

  .who-suits__grid__item .text {
    font-size: 14px;
    line-height: 21px
  }

  .who-suits__quote {
    margin-top: 37px;
    padding-left: 22px;
    padding-right: 22px
  }

  .who-suits__quote .progressive-image-wrapper {
    max-width: 200px
  }

  .ws__quote__employee>div {
    bottom: 25px;
    padding: 23px 50px 23px 20px;
    right: 120px;
    width: 337px
  }

  .ws__quote__employee>div:before {
    height: 40px;
    right: 45px;
    top: -45px;
    width: 80px
  }

  .ws__quote__quote {
    max-width: 50%
  }

  .ws__quote__quote blockquote {
    font-size: 15px;
    line-height: 22px;
    padding-left: 55px
  }
}

@media screen and (max-width:940px) {
  .ws__quote__quote {
    max-width: 38%
  }
}

@media screen and (max-width:767px) {
  .who-suits {
    background-image: none;
    padding-bottom: 0
  }

  .who-suits__title {
    font-size: 26px !important;
    line-height: 32px !important
  }

  .who-suits__grid {
    background-image: url(/build/i/cd469867279222393ec9.svg);
    background-repeat: repeat;
    background-size: 21px 26px;
    margin-left: 0;
    margin-right: 0;
    margin-top: 10px;
    padding-bottom: 35px;
    padding-top: 15px
  }

  .who-suits__grid__item {
    flex: 1 0 100% !important;
    margin-left: auto;
    margin-right: auto;
    max-width: 520px;
    padding-bottom: 10px
  }

  .who-suits__grid__item:not(:last-child) {
    margin-bottom: 25px
  }

  .who-suits__grid__item .title {
    cursor: pointer;
    margin-bottom: 0;
    margin-top: 20px;
    padding-right: 35px;
    position: relative
  }

  .who-suits__grid__item .title:after {
    content: "â€º";
    margin-top: -10px;
    position: absolute;
    right: 17px;
    top: 50%;
    transform: rotate(90deg);
    transition: transform .1s ease-in, height .05s ease-in
  }

  .who-suits__grid__item .text {
    height: 0;
    transform: scaleY(0);
    transform-origin: top;
    transition: none
  }

  .who-suits__grid__item .text:before {
    background-color: #f2f2f2;
    content: "";
    display: block;
    height: 1px;
    margin-bottom: 13px;
    margin-top: 13px;
    width: 100%
  }

  .who-suits__grid__item.opened .title:after {
    transform: rotate(270deg);
    transition: transform .2s ease-out
  }

  .who-suits__grid__item.opened .text {
    height: auto;
    transform: scaleY(1);
    transition: transform .2s ease-out
  }

  .who-suits__quote {
    background-color: transparent;
    border-radius: 0;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 0;
    min-height: auto;
    padding-left: 0;
    padding-right: 0
  }

  .who-suits__quote>* {
    flex: 1 0 100%
  }

  .ws__quote__employee {
    background-color: #fff;
    border-bottom: 1px solid #f2f2f2;
    margin-right: 15px;
    order: 2;
    padding: 20px 0 20px 112px;
    position: relative
  }

  .ws__quote__employee .progressive-image-wrapper {
    display: none
  }

  .ws__quote__employee>div {
    background-color: transparent;
    border-radius: 0;
    bottom: auto;
    padding: 0 15px 0 0;
    position: static;
    right: auto;
    width: 100%
  }

  .ws__quote__employee>div:before {
    display: block;
    left: 15px;
    right: auto;
    top: 50%;
    transform: translateY(-50%)
  }

  .ws__quote__quote {
    align-items: center;
    align-self: center;
    background-color: #f2f2f2;
    display: flex;
    margin-bottom: 0;
    max-width: 100%;
    min-height: 200px;
    order: 1;
    padding: 25px 117px 25px 15px;
    position: relative
  }

  .ws__quote__quote .progressive-image-wrapper {
    bottom: 0;
    display: block;
    max-width: 185px;
    position: absolute;
    right: -40px
  }

  .ws__quote__quote blockquote {
    font-size: 15px;
    line-height: 22px;
    padding-left: 0
  }

  .ws__quote__quote blockquote:before {
    margin-bottom: 5px;
    position: static
  }
}

@media screen and (max-width:550px) {
  .who-suits__title br {
    display: none
  }
}

.how-to-get__grid {
  margin-left: -12px;
  margin-right: -12px;
  position: relative;
  z-index: 1
}

.how-to-get__grid b {

  font-style: normal;
  font-weight: 500
}

.how-to-get__grid>* {
  flex-basis: calc(50% - 24px);
  margin-left: 12px;
  margin-right: 12px;
  max-width: calc(50% - 24px)
}

.how-to-get__grid>:first-child {
  margin-top: 15px
}

.how-to-get__grid>:last-child .progressive-image-wrapper {
  width: 100% !important
}

.how-to-get__grid>* [data-js-placeholder-wrapper] {
  display: none
}

.how-to-get__grid>* .how-to-get__button-map {
  background-image: url(/build/i/f7fa25d6803e68439885.jpg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 14px;
  margin-bottom: 25px;
  max-width: 320px;
  padding-right: 60px;
  position: relative;
  width: 100%
}

.how-to-get__grid>* .how-to-get__button-map:after {
  background-image: url(/build/i/34478cf07cefebb5bb9b.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  content: "";
  display: block;
  height: 37px;
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  width: 28px
}

.how-to-get__grid>* .count-list .title {
  margin-bottom: 10px
}

.how-to-get__grid .slider__item__image {
  height: auto;
  width: 100%
}

.how-to-get__grid .tabs__labels {
  margin-bottom: 14px
}

.how-to-get__grid .tabs__labels.tabs__labels_buttons .tab__label.active {
  background-color: #eef2f4;
  border-color: #eef2f4;
  color: #5f6877
}

.how-to-get__grid .tab {
  margin-top: 0
}

.how-to-get__title {
  font-size: 50px;
  margin-bottom: 45px;
  text-align: left
}

.how-to-get__grid__text {
  line-height: 30px;
  max-width: 520px
}

@media screen and (max-width:1363px) {
  .how-to-get__grid>:first-child {
    padding-right: 60px
  }

  .how-to-get__grid>:last-child .image-slider__navigator {
    top: 63%
  }

  .how-to-get__grid>:last-child .slider__item__image-description {
    display: block !important;
    margin-top: 10px
  }

  .how-to-get__title {
    font-size: 38px;
    margin-bottom: 30px
  }
}

@media screen and (max-width:1023px) {
  .how-to-get__grid {
    justify-content: center;
    margin-left: 0;
    margin-right: 0
  }

  .how-to-get__grid>* {
    flex-basis: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%
  }

  .how-to-get__grid>:first-child {
    margin-top: 15px;
    padding-right: 0
  }

  .how-to-get__grid>:first-child>* {
    flex-basis: 100%
  }

  .how-to-get__grid>:last-child {
    display: none !important
  }

  .how-to-get__grid>* .count-list {
    text-align: center
  }

  .how-to-get__grid>* [data-js-placeholder-wrapper] {
    display: block;
    height: auto;
    width: 100%
  }

  .how-to-get__grid>* [data-js-placeholder-wrapper] [data-js-placeholder-items] {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center
  }

  .how-to-get__grid>* [data-js-placeholder-wrapper] [data-js-placeholder-items]>* {
    display: none;
    margin: 0 auto 20px;
    max-width: 600px;
    width: 100% !important
  }

  .how-to-get__grid>* [data-js-placeholder-wrapper] [data-js-placeholder-items]>:first-child {
    display: block
  }

  .how-to-get__grid>* [data-js-placeholder-wrapper] [data-js-placeholder-items]>:last-child {
    margin-bottom: 0
  }

  .how-to-get__grid>* [data-js-placeholder-wrapper] [data-js-placeholder-items].showed>* {
    display: block
  }

  .how-to-get__grid>* [data-js-placeholder-wrapper] [data-js-placeholder-items].showed>* .slider__item__image-description {
    display: block;
    margin-bottom: 0;
    margin-top: 10px
  }

  .how-to-get__grid>* [data-js-placeholder-wrapper] .htg__more {
    display: block;
    font-size: 14px;
    text-align: center
  }

  .how-to-get__grid>* .how-to-get__button-map {
    display: block;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 100%
  }

  .how-to-get__grid .tabs__labels {
    margin-bottom: 20px;
    text-align: center
  }

  .how-to-get__grid .tab {
    margin-top: 0
  }

  .how-to-get__title {
    margin-bottom: 35px;
    text-align: center
  }

  .how-to-get__grid__text {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 35px;
    max-width: 100%;
    width: 100%
  }
}

@media screen and (max-width:767px) {
  .how-to-get__title {
    margin-bottom: 40px;
    text-align: center
  }
}

@media screen and (max-width:600px) {
  .how-to-get__grid>* [data-js-placeholder-wrapper] [data-js-placeholder-items] {
    margin-left: -15px;
    margin-right: -15px
  }

  .how-to-get__grid>* [data-js-placeholder-wrapper] [data-js-placeholder-items] .progressive-image-wrapper {
    border-radius: 0
  }
}

.table-contents {
  background: #e9ebed;
  border-radius: 8px;
  display: block;
  margin: 60px 0;
  padding: 50px 70px 30px;
  position: relative
}

.table-contents b {
  color: #333638;
  display: block;
  font-size: 20px;
  margin-bottom: 25px
}

.table-contents ol {
  column-count: 2;
  counter-reset: myCounter;
  padding: 0
}

.table-contents ol li {
  display: block;
  list-style: none;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 15px;
  position: relative
}

.table-contents ol li:before {
  content: counter(myCounter) ".";
  counter-increment: myCounter;
  display: inline-block;
  left: 0;
  position: absolute;
  text-align: left;
  top: 4px;
  width: 19px
}

.table-contents_anchor .table-contents__link,
.table-contents_anchor ol li {
  color: #00ada4
}

.table-contents_anchor .table-contents__link span {
  border-bottom: 1px dashed rgba(0, 173, 164, .3);
  transition: border-bottom .2s ease-out
}

.table-contents_anchor .table-contents__link:hover {
  color: #007973
}

.table-contents_anchor .table-contents__link:hover span {
  border-bottom: 1px dashed transparent;
  transition: border-bottom .2s ease-out
}

.table-contents__link {
  color: #00ada4;
  line-height: 1.75
}

.table-contents__link span {
  border-bottom: 1px dashed rgba(0, 173, 164, .3);
  transition: border-bottom .2s ease-out
}

.table-contents__link:hover {
  color: #007973
}

.table-contents__link:hover span {
  border-bottom: 1px dashed transparent;
  transition: border-bottom .2s ease-out
}

@media screen and (max-width:839px) {
  .table-contents {
    padding: 40px 60px 30px
  }

  .table-contents b {
    margin-bottom: 20px
  }

  .table-contents ol {
    column-count: auto
  }

  .table-contents ol li {
    margin-bottom: 10px
  }
}

@media screen and (max-width:767px) {
  .table-contents {
    margin: 50px 0;
    padding: 30px 30px 20px
  }
}

@media screen and (max-width:567px) {
  .table-contents {
    margin: 30px 0
  }

  .table-contents b {
    font-size: 18px
  }

  .table-contents ol li {
    margin-bottom: 8px;
    padding-right: 0
  }

  .table-contents ol li:before {
    font-size: 14px;
    top: 3px;
    width: 17px
  }

  .table-contents__link {
    font-size: 14px
  }
}

.link_booking {
  color: #fff;
  font-size: 14px;
  padding-left: 27px;
  position: relative
}

.link_booking>span {
  border-bottom: 1px solid #fff;
  transition: border .2s ease
}

.link_booking:before {
  background-image: url(/build/i/cf375c3f51347861ad7c.svg);
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 0;
  content: "";
  height: 20px;
  left: 0;
  position: absolute;
  width: 20px
}

.link_booking:hover {
  color: #fff
}

.link_booking:hover>span {
  border-bottom: 1px solid transparent
}

.link_booking_large {
  font-size: 16px;
  letter-spacing: .48px;
  padding-left: 54px
}

.link_booking_large:before {
  height: 42px;
  margin-top: -21px;
  top: 50%;
  width: 42px
}

@media screen and (max-width:767px) {
  .link_booking {
    font-size: 13px;
    letter-spacing: .53px;
    padding-left: 20px
  }

  .link_booking:before {
    height: 15px;
    width: 15px
  }
}

.h1_supplement_with-tooltip span.accent {
  border: 0;
  color: #e79000;

  font-size: inherit;
  font-style: normal;
  font-weight: 700
}

.h1_supplement_with-tooltip span.accent>span {
  border: 0
}

.h1_supplement_with-tooltip [data-js=tooltip] .tooltip__icon {
  background-image: url(/build/i/7810f2f5256802e0edd3.svg);
  border-radius: 50%;
  display: inline-block;
  height: 22px;
  position: relative;
  transition: background .2s ease-out;
  width: 22px
}

@media screen and (max-width:767px) {
  .h1_supplement_with-tooltip [data-js=tooltip] .tooltip__icon {
    height: 16px;
    margin-left: 2px;
    width: 16px
  }
}

.tick-list {
  list-style: none;
  padding-left: 0
}

.tick-list>li {
  margin-bottom: 15px;
  padding-left: 31px;
  position: relative
}

.tick-list>li:before {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 21 19.9'%3E%3Ccircle cx='10' cy='10' r='10' fill='%23e89200'/%3E%3Cpath d='M10.5 15c-.2.2-.4.3-.6.3-.2 0-.5-.1-.6-.3l-4.9-4.9c-.5-.5-.5-1.3 0-1.8l.6-.7c.5-.5 1.3-.5 1.8 0l3.1 3.1 8.3-8.3c.5-.5 1.3-.5 1.8 0l.6.6c.5.5.5 1.3 0 1.8L10.5 15z' fill='%23fff'/%3E%3C/svg%3E");
  content: "";
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 20px
}

.tick-list>li .title {
  color: #5f6877;
  font-weight: 500;
  text-transform: uppercase
}

.tick-list>li .text,
.tick-list>li .title {

  font-style: normal
}

.tick-list>li .text {
  color: #989ca6;
  display: block;
  font-weight: 400;
  line-height: 28px;
  text-transform: none
}

.imaged-block_compact .h2,
.imaged-block_compact .payment-page h2,
.payment-page .imaged-block_compact h2 {
  margin-bottom: 55px;
  text-align: center
}

.imaged-block_compact .h2 .link,
.imaged-block_compact .h2 .link span,
.imaged-block_compact .h2 .slide-tab__label,
.imaged-block_compact .h2 .slide-tab__label span,
.imaged-block_compact .h2 .tabs__labels.tabs__labels_buttons .tab__label,
.imaged-block_compact .h2 .tabs__labels.tabs__labels_buttons .tab__label span,
.imaged-block_compact .h2 [data-js=tooltip],
.imaged-block_compact .h2 [data-js=tooltip] span,
.imaged-block_compact .payment-page h2 .link,
.imaged-block_compact .payment-page h2 .link span,
.imaged-block_compact .payment-page h2 .slide-tab__label,
.imaged-block_compact .payment-page h2 .slide-tab__label span,
.imaged-block_compact .payment-page h2 .tabs__labels.tabs__labels_buttons .tab__label,
.imaged-block_compact .payment-page h2 .tabs__labels.tabs__labels_buttons .tab__label span,
.imaged-block_compact .payment-page h2 [data-js=tooltip],
.imaged-block_compact .payment-page h2 [data-js=tooltip] span,
.payment-page .imaged-block_compact h2 .link,
.payment-page .imaged-block_compact h2 .link span,
.payment-page .imaged-block_compact h2 .slide-tab__label,
.payment-page .imaged-block_compact h2 .slide-tab__label span,
.payment-page .imaged-block_compact h2 .tabs__labels.tabs__labels_buttons .tab__label,
.payment-page .imaged-block_compact h2 .tabs__labels.tabs__labels_buttons .tab__label span,
.payment-page .imaged-block_compact h2 [data-js=tooltip],
.payment-page .imaged-block_compact h2 [data-js=tooltip] span,
.tabs__labels.tabs__labels_buttons .imaged-block_compact .h2 .tab__label,
.tabs__labels.tabs__labels_buttons .imaged-block_compact .h2 .tab__label span,
.tabs__labels.tabs__labels_buttons .imaged-block_compact .payment-page h2 .tab__label,
.tabs__labels.tabs__labels_buttons .imaged-block_compact .payment-page h2 .tab__label span,
.tabs__labels.tabs__labels_buttons .payment-page .imaged-block_compact h2 .tab__label,
.tabs__labels.tabs__labels_buttons .payment-page .imaged-block_compact h2 .tab__label span {
  font-family: inherit !important
}

.imaged-block_compact .imaged-block__media {
  border-radius: 0;
  margin-left: auto;
  margin-right: 0;
  max-width: 405px;
  padding-right: 40px
}

.imaged-block_compact .imaged-block__grid_left>:first-child {
  padding-left: 0
}

.imaged-block_compact .imaged-block__text {
  max-width: 483px
}

.imaged-block_compact .imaged-block__text>:first-child {
  margin-top: 0
}

@media screen and (max-width:1023px) {
  .imaged-block_compact .imaged-block__grid_left>:first-child {
    margin-top: 35px;
    order: 2
  }

  .imaged-block_compact .imaged-block__grid_left>:last-child {
    margin-top: 0;
    order: 1
  }

  .imaged-block_compact .imaged-block__media {
    margin-left: auto;
    margin-right: auto;
    min-height: auto !important;
    padding-right: 0
  }

  .imaged-block_compact .imaged-block__media>.progressive-image-wrapper {
    left: auto;
    width: 100% !important
  }

  .imaged-block_compact .imaged-block__text {
    max-width: 100%;
    text-align: center
  }
}

.page-header_implantation {
  border-bottom: 1px solid #d9e1e1;
  position: relative
}

.attainment-image-slider-wrapper_allon4 .page-header_implantation.attainment-image-slider,
.offer_standard .page-header_implantation.offer__card:before,
.page-header_implantation.appointment__inner,
.page-header_implantation.bg_dots,
.page-header_implantation.cards-with-text:before,
.page-header_implantation.director-quote__right:before,
.page-header_implantation.is-suitable.bg_dots-half:before,
.page-header_implantation.main-page-header__badge:before,
.page-header_implantation.payment-form,
.page-header_implantation.with-image-slider-wrapper_dots,
.page-header_implantation.works-slider__item,
.through-block-short .page-header_implantation.through-block_hold-prices {
  background-image: none
}

.attainment-image-slider-wrapper_allon4 .page-header_implantation.attainment-image-slider:before,
.offer_standard .page-header_implantation.offer__card:before,
.page-header_implantation.appointment__inner:before,
.page-header_implantation.bg_dots:before,
.page-header_implantation.cards-with-text:before,
.page-header_implantation.director-quote__right:before,
.page-header_implantation.is-suitable.bg_dots-half:before,
.page-header_implantation.main-page-header__badge:before,
.page-header_implantation.payment-form:before,
.page-header_implantation.with-image-slider-wrapper_dots:before,
.page-header_implantation.works-slider__item:before,
.through-block-short .page-header_implantation.through-block_hold-prices:before {
  background-image: url(/build/i/cd469867279222393ec9.svg);
  background-repeat: repeat;
  background-size: 21px 26px;
  content: "";
  height: 100%;
  left: 50%;
  max-width: 1366px;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: 100%;
  z-index: 0
}

.page-header_implantation .breadcrumbs {
  margin-bottom: 32px
}

.page-header_implantation .page-header__wallpaper-wrapper {
  height: 692px;
  right: -45px;
  top: -180px;
  width: 692px
}

.page-header_implantation .page-header__wallpaper-wrapper .progressive-image-wrapper {
  border-radius: 341px
}

.page-header_implantation .page-header__wallpaper-wrapper:after {
  content: none
}

.page-header_implantation .page-header__title {
  margin-top: 0;
  max-width: 760px
}

.page-header_implantation .h1 {
  display: block
}

.page-header_implantation .h1_supplement__awards {
  align-items: flex-start;
  display: inline-flex;
  justify-content: space-between;
  line-height: 38px;
  margin-left: 14px;
  transform: translateY(-3px);
  width: 140px
}

.page-header_implantation .h1_supplement__awards .progressive-image-wrapper {
  display: block
}

.page-header_implantation .page-header__title__description {
  max-width: 700px
}

.page-header_implantation .page-header__title__description>div {
  color: #5f6877;

  font-size: 22px;
  font-style: normal;
  font-weight: 500
}

.page-header_implantation .page-header__title__description .ordered {
  margin-top: 15px;
  padding-left: 16px
}

.page-header_implantation .page-header__title__description .ordered li {
  color: #a0abbe;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 7px
}

.page-header_implantation .page-header__title__description .ordered li:last-child {
  margin-bottom: 0
}

.page-header__offer-wrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
  max-width: 745px
}

.page-header__offer {
  background-image: linear-gradient(90deg, #d46f00, #ffba00);
  border-radius: 30px;
  box-shadow: 0 10px 36px 4px rgba(70, 81, 86, .17);
  color: #fff;
  max-width: 430px;
  padding: 30px 32px 38px
}

.page-header__offer .tooltip__icon {
  background-image: url(/build/i/6aaa82a413a084b05763.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  display: inline-block;
  height: 22px;
  margin-bottom: -3px;
  margin-left: 5px;
  width: 22px
}

.page-header__offer__main {

  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: .66px
}

.page-header__offer__main>span {

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: .48px
}

.page-header__offer__content>span {
  color: #e7c889;
  font-size: 16px;
  letter-spacing: .48px;
  line-height: 28px
}

.page-header__offer__bottom {
  align-items: center;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  letter-spacing: .42px;
  margin-top: 22px
}

.page-header__offer__achievement {
  color: #5f6877;
  font-size: 14px;
  line-height: 22px;
  max-width: 270px
}

.page-header__offer__achievement>div {
  background-color: #00beb4;
  color: #fff;

  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  height: 60px;
  margin-bottom: 18px;
  width: 128px
}

.button_booking {
  align-items: center;
  border-radius: 60px;
  display: flex;
  height: 60px;
  justify-content: center
}

.button_booking:before {
  background-image: url(/build/i/cf375c3f51347861ad7c.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  content: "";
  display: block;
  height: 20px;
  margin-bottom: 5px;
  margin-right: 7px;
  width: 20px;
  z-index: 1
}

@media screen and (max-width:1400px) {
  .page-header_implantation .page-header__wallpaper-wrapper {
    height: 42vw;
    right: -10vw;
    top: -10vw;
    width: 42vw
  }
}

@media screen and (max-width:1023px) {
  .page-header_implantation .h1_supplement__awards {
    display: flex;
    margin-left: 0;
    margin-top: 10px
  }

  .page-header__offer-wrapper {
    flex-wrap: wrap
  }

  .page-header__offer__achievement {
    align-items: center;
    display: flex;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 24px;
    max-width: 70%;
    order: -1
  }

  .page-header__offer__achievement>div {
    font-size: 25px;
    height: 42px;
    margin-bottom: 0;
    margin-right: 13px;
    min-width: 90px;
    width: 90px
  }
}

@media screen and (max-width:767px) {
  .page-header_implantation {
    border-bottom: 0
  }

  .attainment-image-slider-wrapper_allon4 .page-header_implantation.attainment-image-slider:before,
  .offer_standard .page-header_implantation.offer__card:before,
  .page-header_implantation.appointment__inner:before,
  .page-header_implantation.bg_dots:before,
  .page-header_implantation.cards-with-text:before,
  .page-header_implantation.director-quote__right:before,
  .page-header_implantation.is-suitable.bg_dots-half:before,
  .page-header_implantation.main-page-header__badge:before,
  .page-header_implantation.payment-form:before,
  .page-header_implantation.with-image-slider-wrapper_dots:before,
  .page-header_implantation.works-slider__item:before,
  .through-block-short .page-header_implantation.through-block_hold-prices:before {
    height: 90%
  }

  .page-header_implantation+.scrolls_implantation {
    display: none
  }

  .page-header_implantation .page-header__title {
    text-align: left
  }

  .page-header_implantation .h1_supplement {
    font-size: 24px
  }

  .page-header_implantation .breadcrumbs-holder-wrapper {
    margin-left: 0;
    margin-right: 0;
    text-align: left
  }

  .page-header_implantation .page-header__title__description {
    margin-top: 25px
  }

  .page-header_implantation .page-header__title__description>div {
    font-size: 16px
  }

  .page-header_implantation .page-header__title__description>div .link,
  .page-header_implantation .page-header__title__description>div .slide-tab__label,
  .page-header_implantation .page-header__title__description>div .tabs__labels.tabs__labels_buttons .tab__label,
  .page-header_implantation .page-header__title__description>div [data-js=tooltip],
  .tabs__labels.tabs__labels_buttons .page-header_implantation .page-header__title__description>div .tab__label {
    display: block;
    font-size: 22px;
    margin-bottom: 8px
  }

  .page-header_implantation .page-header__title__description .ordered li {
    font-size: 12px;
    line-height: 16px
  }

  .page-header__offer-wrapper {
    margin-top: 18px
  }

  .page-header__offer {
    padding: 22px 10px 20px 22px
  }

  .page-header__offer .tooltip__icon {
    height: 16px;
    margin-left: 0;
    width: 16px
  }

  .page-header__offer__main {
    font-size: 16px;
    letter-spacing: .62px
  }

  .page-header__offer__main>span {
    font-size: 14px
  }

  .page-header__offer__main>span.large {
    font-size: 20px;
    letter-spacing: .88px
  }

  .page-header__offer__content>span {
    font-size: 12px;
    line-height: 20px
  }

  .page-header__offer__bottom {
    margin-top: 10px
  }

  .page-header__offer__bottom>span {
    display: none
  }

  .page-header__offer__achievement {
    max-width: 100%
  }
}

@media screen and (max-width:414px) {
  .page-header__offer__achievement {
    margin-right: -11px;
    max-width: calc(100% + 13px)
  }

  .page-header__offer__achievement>div {
    margin-right: 10px
  }

  .page-header__offer {
    padding: 22px 10px 20px 15px
  }
}

.employee-quote {
  background-color: #eef3f6;
  padding-bottom: 75px;
  padding-top: 75px
}

.employee-quote__author {
  color: #a0abbe;
  font-size: 13px;
  line-height: 22px;
  padding-right: 10px;
  text-align: right
}

.employee-quote__photo {
  align-items: flex-end;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  height: 170px;
  margin-bottom: 25px;
  overflow: hidden;
  width: 170px
}

.employee-quote__photo .progressive-image-wrapper {
  max-width: 100%
}

.employee-quote__grid {
  margin-left: -15px;
  margin-right: -15px
}

.employee-quote__grid>* {
  flex: 0 1 83.33%;
  max-width: 83.33%;
  padding-left: 15px;
  padding-right: 15px
}

.employee-quote__grid>:first-child {
  flex-basis: 16.66%;
  max-width: 16.66%
}

.employee-quote__header {
  color: #333638;

  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 28px
}

.employee-quote__content p {
  color: #5f6877;
  font-size: 15px;
  line-height: 26px;
  margin-bottom: 15px
}

.employee-quote__content p:last-child {
  margin-bottom: 0
}

.employee-quote__content p strong {

  font-style: normal;
  font-weight: 500
}

@media screen and (max-width:1360px) {
  .employee-quote__grid>* {
    flex-basis: 75%;
    max-width: 75%
  }

  .employee-quote__grid>:first-child {
    flex-basis: 25%;
    max-width: 25%
  }

  .employee-quote__photo {
    margin-left: auto;
    margin-right: 0
  }
}

@media screen and (max-width:1024px) {
  .employee-quote__header {
    font-size: 38px;
    line-height: 44px;
    margin-bottom: 15px
  }
}

@media screen and (max-width:1023px) {
  .employee-quote__grid {
    position: relative
  }

  .employee-quote__grid>*,
  .employee-quote__grid>:first-child {
    flex-basis: 100%;
    max-width: 100%
  }

  .employee-quote__header {
    align-items: center;
    display: flex;
    font-size: 32px;
    line-height: 40px;
    min-height: 120px;
    padding-left: 150px
  }

  .employee-quote__photo {
    height: 120px;
    left: 0;
    position: absolute;
    top: 0;
    width: 120px
  }

  .employee-quote__author__text {
    display: none
  }
}

@media screen and (max-width:767px) {
  .employee-quote {
    padding-bottom: 48px;
    padding-top: 45px
  }

  .employee-quote__photo {
    height: 75px;
    left: 10px;
    width: 75px
  }

  .employee-quote__header {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 18px;
    margin-right: -10px;
    min-height: 75px;
    padding-left: 85px
  }

  .employee-quote__content p {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 10px
  }
}

@media screen and (max-width:500px) {
  .employee-quote__header>span>br {
    display: none
  }
}

.payment-page .what-included-remastered h2,
.what-included-remastered .h2,
.what-included-remastered .payment-page h2 {
  margin-bottom: 75px
}

.wir__grid-row {
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 0 10px 36px 4px rgba(70, 81, 86, .17);
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 50px 68px
}

.wir__grid-row>.stage {
  color: #5f6877;
  flex-basis: 175px;

  font-style: normal;
  font-weight: 400;
  margin-right: 40px;
  min-width: 175px
}

.wir__grid-row>.stage .number {
  background-image: linear-gradient(90deg, #d46f00, #ffba00);
  border-radius: 50%;
  color: #fff;

  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  height: 54px;
  justify-content: center;
  margin-bottom: 17px;
  width: 54px
}

.wir__grid-row>.stage .title {
  font-size: 26px;
  line-height: 32px;
  margin-bottom: 10px
}

.wir__grid-row>.stage .text {
  font-size: 16px;
  line-height: 24px
}

.wir__grid-row>.stage-items {
  flex-grow: 1;
  justify-content: flex-end;
  margin-left: -20px;
  margin-right: -20px
}

.wir__grid-row>.stage-items>* {
  margin-left: 20px;
  margin-right: 20px;
  max-width: 160px;
  min-width: 160px
}

.wir__grid-row>.stage-items>:first-child:after {
  display: none
}

.wir__item {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative
}

.wir__item .tooltip__icon {
  background-image: url(/build/i/45acbcbdd6af2cdea786.svg);
  height: 18px;
  margin-left: 7px;
  transform: translateY(3px);
  width: 18px
}

.wir__item .tooltip__icon,
.wir__item:after {
  background-repeat: no-repeat;
  background-size: contain
}

.wir__item:after {
  background-image: url(/build/i/3e22ceb06e0d9d38b019.svg);
  content: "";
  height: 20px;
  left: -30px;
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  width: 20px
}

.wir__item__photo {
  height: 126px;
  margin-bottom: 7px
}

.wir__item__title {
  color: #5f6877;

  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center
}

.wir__item__title [data-js=tooltip] {
  background-image: url(/build/i/45acbcbdd6af2cdea786.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  display: inline-block;
  height: 18px;
  margin-left: 5px;
  margin-top: 5px;
  position: absolute;
  transform: translateY(-5px);
  transition: all .2s ease-out;
  width: 18px
}

.wir__item__title [data-js=tooltip]:hover {
  opacity: .8
}

.wir__item__note {
  color: #e89200;

  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  left: 20px;
  line-height: 18px;
  max-width: 185px;
  min-width: 185px;
  position: absolute;
  text-align: left;
  top: -38px;
  width: 100%
}

.wir__item__with-arrow:before {
  background-image: url(/build/i/f639069ce8e174f225a2.svg);
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 56px;
  left: 8px;
  position: absolute;
  top: 15px;
  transform: rotate(180deg) scaleX(-1);
  width: 37px;
  z-index: 2
}

.wir__item__total .wir__item__photo {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  text-align: center
}

.wir__item__total .wir__item__title {

  font-style: normal;
  font-weight: 400
}

.wir__item__total:after {
  background-image: url(/build/i/728bb383b79dbd533a5f.svg)
}

.wir__item__price {
  color: #5f6877;
  font-size: 26px;
  font-weight: 500;
  line-height: 28px
}

.wir__item__price,
.wir__item__price .new {

  font-style: normal
}

.wir__item__price .new {
  background-color: #00beb4;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  height: 39px;
  line-height: 39px;
  margin-bottom: 6px;
  width: 112px
}

.wir__item__price .new sup {
  text-decoration: underline;
  top: -.3em
}

.wir__item__price .old {
  position: relative
}

.wir__item__price .old:before {
  background-image: url(/build/i/c8e5acd0262cf8358731.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  content: "";
  display: block;
  height: 6px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%
}

.wir__total {
  align-items: center;
  background-image: linear-gradient(90deg, #d46f00, #ffba00);
  border-radius: 30px;
  box-shadow: 0 10px 36px 4px rgba(70, 81, 86, .17);
  margin-top: 103px;
  max-width: 400px;
  min-height: 167px;
  padding: 20px 20px 20px 105px;
  position: relative
}

.wir__total:before {
  background-image: url(/build/i/6b996131d116026f1249.png);
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 205px;
  left: -8px;
  margin-top: -105px;
  position: absolute;
  top: 50%;
  width: 93px
}

.wir__offer__date {
  color: #333638;
  font-size: 16px;
  left: 105px;
  letter-spacing: .48px;
  position: absolute;
  top: -28px
}

.wir__offer__date,
.wir__offer__title {

  font-style: normal;
  font-weight: 500
}

.wir__offer__title {
  color: #fff;
  font-size: 22px;
  letter-spacing: .66px;
  margin-bottom: 9px
}

.wir__offer__price {
  color: #fff;
  display: flex;

  font-style: normal;
  font-weight: 500;
  margin-bottom: 20px
}

.wir__offer__price .old {
  color: #fff;
  margin-right: 10px;
  text-decoration: line-through
}

.wir__offer__price .new,
.wir__offer__price .old {
  font-size: 26px;
  letter-spacing: .78px
}

.wir__offer__price .new sup {
  text-decoration: underline;
  top: -.2em
}

@media screen and (max-width:1345px) {
  .wir__grid-row>.stage-items {
    margin-left: -15px;
    margin-right: -15px
  }

  .wir__grid-row>.stage-items>* {
    margin-left: 15px;
    margin-right: 15px
  }

  .wir__item:after {
    left: -25px
  }
}

@media screen and (max-width:1220px) {
  .wir__grid-row {
    padding: 40px 30px
  }

  .wir__grid-row>.stage {
    flex-basis: 150px;
    margin-right: 35px;
    min-width: 150px
  }

  .wir__grid-row>.stage .title {
    font-size: 20px;
    line-height: 24px
  }

  .wir__grid-row>.stage .text {
    font-size: 14px;
    line-height: 20px
  }

  .wir__grid-row>.stage-items {
    margin-left: -5px;
    margin-right: -5px
  }

  .wir__grid-row>.stage-items>* {
    margin-left: 5px;
    margin-right: 5px
  }

  .wir__item:after {
    left: -15px
  }

  .wir__item__total .wir__item__photo {
    margin-bottom: 8px
  }

  .wir__item__total .wir__item__title {
    font-size: 14px
  }
}

@media screen and (max-width:1023px) {
  .wir__grid-row>.stage .number {
    margin-bottom: 15px
  }

  .wir__grid-row>.stage .title {
    margin-bottom: 10px
  }

  .wir__grid-row>.stage-items {
    margin-left: -15px;
    margin-right: -15px
  }

  .wir__grid-row>.stage-items>* {
    margin-left: 15px;
    margin-right: 15px;
    max-width: 120px;
    min-width: 120px
  }

  .wir__item .tooltip__icon {
    height: 14px;
    margin-left: 5px;
    width: 14px
  }

  .wir__item:after {
    height: 14px;
    left: -22px;
    top: 50%;
    width: 14px
  }

  .wir__item__photo {
    height: 96px;
    margin-bottom: 7px
  }

  .wir__item__photo>.progressive-image-wrapper,
  .wir__item__photo>.progressive-image-wrapper>.progressive-image {
    height: 100% !important;
    width: auto !important
  }

  .wir__item__title {
    font-size: 12px;
    line-height: 18px
  }

  .wir__item__title [data-js=tooltip] {
    height: 14px;
    margin-top: 7px;
    width: 14px
  }

  .wir__item__price {
    font-size: 18px;
    line-height: 20px
  }

  .wir__item__price .new {
    height: 39px;
    margin-bottom: 6px;
    width: 110px
  }

  .wir__item__price .old {
    display: inline-block;
    position: relative
  }

  .wir__item__price .old:before {
    height: 5px
  }

  .wir__item__total .wir__item__photo {
    margin-bottom: 8px
  }

  .wir__item__total .wir__item__title {
    font-size: 12px;
    line-height: 14px
  }

  .wir__item__total .wir__item__title>br {
    display: none
  }
}

@media screen and (max-width:900px) {

  .payment-page .what-included-remastered h2,
  .what-included-remastered .h2,
  .what-included-remastered .payment-page h2 {
    margin-bottom: 35px
  }

  .wir__grid-row {
    align-items: flex-start;
    border-radius: 0;
    box-shadow: none;
    flex-direction: column;
    margin-bottom: 60px;
    padding: 0
  }

  .wir__grid-row>.stage {
    align-items: center;
    display: flex;
    flex-basis: 100%;
    margin-bottom: 60px;
    margin-right: 0;
    min-width: auto
  }

  .wir__grid-row>.stage .number {
    margin-bottom: 0;
    margin-right: 15px
  }

  .wir__grid-row>.stage-items {
    justify-content: center;
    width: 100%
  }

  .wir__item__note {
    font-size: 11px;
    left: 18px;
    line-height: 14px;
    max-width: 135px;
    min-width: 135px;
    top: -50px
  }

  .wir__item__with-arrow:before {
    height: 40px;
    left: 0;
    top: 10px;
    width: 27px
  }

  .stage-items_with-arrow {
    margin-top: 20px
  }
}

@media screen and (max-width:767px) {

  .payment-page .what-included-remastered h2,
  .what-included-remastered .h2,
  .what-included-remastered .payment-page h2 {
    text-align: left
  }

  .wir__grid-row {
    width: 100%
  }

  .wir__grid-row>.stage-items {
    justify-content: flex-start;
    width: inherit !important;
    width: auto !important
  }

  .wir__total {
    margin-top: 70px;
    max-width: 289px;
    min-height: 125px;
    padding: 20px 15px 20px 73px
  }

  .wir__total:before {
    height: 152px;
    left: -5px;
    margin-top: -74px;
    top: 50%;
    width: 74px
  }

  .wir__offer__date {
    font-size: 12px;
    left: 73px;
    top: -21px
  }

  .wir__offer__title {
    font-size: 16px;
    letter-spacing: .66px;
    margin-bottom: 9px
  }

  .wir__offer__price {
    color: #fff;
    display: flex;

    font-style: normal;
    font-weight: 500;
    margin-bottom: 20px
  }

  .wir__offer__price .old {
    font-size: 20px;
    margin-right: 10px
  }

  .wir__offer__price .new {
    font-size: 20px
  }

  .wir__offer__price .new sup {
    top: -.3em
  }
}

@media screen and (max-width:599px) {
  .wir__grid-row>.stage .number {
    min-width: 54px
  }

  .wir__grid-row>.stage-items {
    flex-wrap: wrap;
    margin-left: 7px;
    margin-right: 7px
  }

  .wir__grid-row>.stage-items>* {
    margin-left: 5px;
    margin-right: 5px;
    max-width: calc(50% - 10px);
    min-width: calc(50% - 10px)
  }

  .wir__grid-row>.stage-items>:nth-child(n+3) {
    margin-top: 35px
  }

  .wir__grid-row>.stage-items>:nth-child(2):before {
    background-image: url(/build/i/3e22ceb06e0d9d38b019.svg);
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    height: 14px;
    position: absolute;
    right: -12px;
    top: 50%;
    transform: translateY(-50%);
    width: 14px
  }

  .wir__item:after {
    left: -12px
  }

  .wir__item__price .new {
    width: 100px
  }
}

.fix-cost {
  margin-left: auto;
  margin-right: auto;
  max-width: 640px;
  padding-top: 42px;
  position: relative
}

.fix-cost__inner {
  background-color: #fdb700;
  border-radius: 30px;
  overflow: hidden;
  padding: 58px 15px 50px;
  position: relative;
  text-align: center
}

.fix-cost__inner:before {
  background-image: url(/build/i/6b996131d116026f1249.png);
  background-repeat: no-repeat;
  background-size: contain;
  bottom: -60px;
  content: "";
  height: 205px;
  left: 50%;
  margin-left: -46px;
  opacity: .15;
  position: absolute;
  width: 93px
}

.fix-cost__button {
  box-shadow: 0 7px 43px rgba(0, 0, 0, .28);
  color: #fff;
  font-size: 64px;
  height: 80px;
  left: 50%;
  margin-left: -40px;
  padding-bottom: 5px;
  top: 0;
  width: 80px;
  z-index: 2
}

.fix-cost__button,
.fix-cost__button:before {
  background-image: linear-gradient(90deg, #d46f00, #ffba00);
  border-radius: 50%;
  position: absolute
}

.fix-cost__button:before {
  animation: pulse 2s infinite;
  content: "";
  height: 100px;
  left: -10px;
  opacity: .1;
  top: -10px;
  width: 100px
}

.fix-cost__title {
  color: #fff;

  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 22px;
  margin-left: auto;
  margin-right: auto;
  max-width: 550px
}

.fix-cost__text {
  color: #fff;
  font-size: 14px;
  letter-spacing: .42px;
  margin-bottom: 32px
}

.fix-cost__text .old {
  text-decoration: line-through
}

@media screen and (max-width:767px) {
  .fix-cost {
    padding-top: 30px
  }

  .fix-cost__inner>.link_booking_large {
    font-size: 15px;
    padding-left: 40px
  }

  .fix-cost__inner>.link_booking_large:before {
    height: 33px;
    width: 33px
  }

  .fix-cost__button {
    font-size: 50px;
    height: 64px;
    margin-left: -32px;
    width: 64px
  }

  .fix-cost__button:before {
    height: 90px;
    left: -12px;
    top: -14px;
    width: 90px
  }

  .fix-cost__title {
    font-size: 20px
  }
}

.price-type-tabs {
  margin-top: 60px
}

.price-type-tabs .tabs__labels_buttons {
  flex-wrap: wrap
}

.price-type-tabs .tabs__labels_buttons .tab__label {
  color: #5f6877
}

.price-type-tabs .tabs__labels_buttons .tab__label:hover {
  color: #00beb4
}

.price-type-tabs .tabs__labels_buttons .tab__label.active {
  background-color: #00beb4 !important;
  border: 1px solid #00beb4 !important;
  color: #fff !important
}

.price-type-tabs .tabs__contents>* {
  margin-top: 30px
}

.ptt__tab {
  background-color: #eef3f6;
  border-radius: 14px;
  overflow: hidden;
  position: relative
}

.ptt__tab:after,
.ptt__tab:before {
  border: 50px solid #fff;
  border-radius: 50%;
  content: "";
  display: block;
  height: 404px;
  opacity: .5;
  position: absolute;
  width: 404px;
  z-index: 1
}

.ptt__tab:before {
  left: -230px;
  top: -230px
}

.ptt__tab:after {
  bottom: -230px;
  right: -230px
}

.ptt__tab .top {
  overflow: hidden;
  padding: 60px 80px 50px
}

.ptt__tab .top>* {
  position: relative;
  z-index: 2
}

.ptt__tab .bottom {
  align-items: center;
  background-color: #ea9500;
  justify-content: space-between;
  padding: 30px 80px
}

.ptt__tab .bottom>* {
  position: relative;
  z-index: 2
}

.ptt__tab__method {
  display: flex;
  justify-content: space-between
}

.ptt__tab__method__conditions {
  margin-right: 85px
}

.ptt__tab__method__conditions>.title {
  color: #333638;

  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 50px
}

.ptt__tab__method__conditions>.conditions {
  display: flex;
  justify-content: space-between;
  margin-left: -25px;
  margin-right: -25px
}

.ptt__tab__method__conditions>.conditions>* {
  align-items: center;
  display: flex;
  flex-basis: calc(100% - 50px);
  margin-left: 25px;
  margin-right: 25px
}

.ptt__tab__method__conditions>.conditions>* .text {
  color: #5f6877;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px
}

.ptt__tab__method__conditions>.conditions>*>.progressive-image-wrapper {
  border-radius: 50%;
  margin-right: 18px;
  min-width: 75px
}

.ptt__tab__method__image {
  min-width: 391px
}

.ptt__tab__method__image>.img {
  border-radius: 6px;
  display: block;
  height: 182px;
  overflow: hidden;
  position: relative
}

.ptt__tab__method__image>.img:before {
  background-image: url(/build/i/97f539b9deb8670a9d85.svg);
  content: "";
  display: block;
  height: 55px;
  left: 90px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 69px;
  z-index: 3
}

.ptt__tab__method__image>.img>* {
  height: 100%;
  overflow: hidden;
  position: absolute
}

.ptt__tab__method__image>.img>:before {
  background-color: #000;
  content: "";
  display: block;
  height: 100%;
  opacity: .35;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1
}

.ptt__tab__method__image>.img>.active:before {
  opacity: 0
}

.ptt__tab__method__image>.img>* .progressive-image-wrapper {
  border-radius: 0 !important
}

.ptt__tab__method__image>.img .before {
  left: 0;
  z-index: 1
}

.ptt__tab__method__image>.img .after {
  right: 0;
  z-index: 2
}

.ptt__tab__method__image>.text {
  color: #a0abbe;
  font-size: 12px;
  line-height: 16px;
  margin-top: 15px
}

.ptt__tab__advantages,
.ptt__tab__method__image>.text {

  font-style: normal;
  font-weight: 400
}

.ptt__tab__advantages {
  align-items: center;
  margin-left: -15px;
  margin-right: -15px
}

.ptt__tab__advantages .title {
  color: #5f6877;
  flex-basis: 100%;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 25px;
  margin-top: -10px;
  padding-left: 15px;
  padding-right: 15px
}

.ptt__tab__advantages>* {
  color: #333638;
  font-size: 15px;
  line-height: 22px
}

.ptt__tab__advantages>:not(.title) {
  align-items: center;
  display: flex;
  padding-left: 15px;
  padding-right: 15px
}

.ptt__tab__advantages>:not(.title):before {
  background-color: #01c8ae;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  border-radius: 50%;
  content: "";
  display: block;
  height: 44px;
  margin-right: 15px;
  min-width: 44px;
  overflow: hidden
}

.ptt__tab__advantages.ptt__tab__advantages_disadvantage .title {
  margin-top: 25px
}

.ptt__tab__advantages .ptt__tab__advantage_clock:before {
  background-image: url(/build/i/a9f18f486a0d8d119c2e.svg)
}

.ptt__tab__advantages .ptt__tab__advantage_shield:before {
  background-image: url(/build/i/11829a3029d74242c36b.svg)
}

.ptt__tab__advantages .ptt__tab__advantage_smile:before {
  background-image: url(/build/i/6a4ab7f58548d27544cf.svg)
}

.ptt__tab__advantages .ptt__tab__advantage_info:before {
  background-image: url(/build/i/17e23c1db05accb71242.svg)
}

.ptt__tab__advantages .ptt__tab__advantage_four:before {
  background-image: url(/build/i/6477dc5cfe4dee63ca82.svg)
}

.ptt__tab__advantages .ptt__tab__advantage_one:before {
  background-image: url(/build/i/c9c75f6daac85c0255a2.svg)
}

.ptt__tab__advantages .ptt__tab__advantage_no-up:before {
  background-image: url(/build/i/58266bbf837391ff9fb6.svg)
}

.ptt__tab__list {
  display: flex;
  justify-content: space-between
}

.ptt__tab__list__title {
  color: #333638;

  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 40px;
  max-width: 590px
}

.ptt__tab__list__list {
  max-width: 505px;
  padding-left: 0
}

.ptt__tab__list__list>* {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding-left: 47px;
  position: relative
}

.ptt__tab__list__list>:last-child {
  margin-bottom: 0
}

.ptt__tab__list__list>:before {
  background-image: url(/build/i/5593f73357bbf0fd9407.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  content: "";
  display: block;
  height: 29px;
  left: 0;
  position: absolute;
  top: 0;
  width: 29px
}

.ptt__tab__list__list>* .title {
  color: #333638;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px
}

.ptt__tab__list__list>* .text {
  color: #989ca6;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px
}

.ptt__tab__list__employee {
  margin-left: 90px;
  min-height: 100%;
  min-width: 391px;
  position: relative
}

.ptt__tab__list__employee>.progressive-image {
  bottom: -50px;
  right: -80px;
  width: 140%
}

.ptt__tab__list__person {
  bottom: 0;
  color: #5f6877;

  font-size: 15px;
  font-style: italic;
  font-weight: 400;
  left: -140px;
  line-height: 22px;
  max-width: 120px;
  position: absolute;
  text-align: right
}

.ptt__tab__list__person>strong {
  font-style: italic
}

.ptt__cost,
.ptt__tab__list__person>strong {

  font-weight: 500
}

.ptt__cost {
  color: #fff;
  font-style: normal;
  max-width: 280px
}

.ptt__cost .title {
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 10px
}

.ptt__cost .text {
  font-size: 15px;
  line-height: 24px
}

.ptt__stock {
  align-items: center;
  border: 1px solid #fff;
  border-radius: 60px;
  color: #fff;

  font-style: normal;
  font-weight: 400;
  justify-content: center;
  margin-left: 15px;
  margin-right: 15px;
  max-width: 380px;
  min-height: 60px;
  padding: 15px 10px;
  position: relative
}

.ptt__stock .action-label {
  background-color: #ea9500;
  font-size: 14px;
  font-weight: 500;
  left: 50%;
  line-height: 14px;
  padding-left: 15px;
  padding-right: 15px;
  position: absolute;
  top: -7px;
  transform: translateX(-50%)
}

.ptt__stock .cost {
  font-size: 16px;
  letter-spacing: .48px;
  line-height: 26px;
  text-align: center
}

.ptt__stock .cost .price-old {

  font-style: normal;
  font-weight: 500;
  text-decoration: line-through
}

.ptt__stock .cost .price-old,
.ptt__stock .cost .price-sub {
  font-size: 20px;
  letter-spacing: .6px;
  white-space: nowrap
}

.ptt__stock .cost .price-sub sup {
  border-bottom: 1px solid;
  font-size: 60%;
  margin-left: 3px;
  top: -6px
}

.ptt__send {
  max-width: 315px
}

@media screen and (max-width:1345px) {
  .price-type-tabs .tabs__contents {
    margin-left: -56px;
    margin-right: -56px
  }

  .ptt__tab {
    border-radius: 0
  }

  .ptt__tab .bottom,
  .ptt__tab .top {
    padding-left: 56px;
    padding-right: 56px
  }
}

@media screen and (max-width:1200px) {
  .price-type-tabs {
    margin-top: 45px
  }

  .ptt__tab .bottom {
    justify-content: center
  }

  .ptt__tab__method__conditions {
    margin-right: 45px
  }

  .ptt__tab__method__conditions>.title {
    margin-bottom: 30px
  }

  .ptt__tab__method__conditions>.conditions {
    margin-left: -10px;
    margin-right: -10px
  }

  .ptt__tab__method__conditions>.conditions>* {
    flex-basis: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px
  }

  .ptt__tab__method__conditions>.conditions>*>.progressive-image-wrapper {
    min-width: 60px
  }

  .ptt__tab__list__employee {
    align-self: flex-end;
    height: 380px;
    min-width: 250px
  }

  .ptt__tab__list__person {
    left: -120px
  }

  .ptt__cost {
    margin-bottom: 30px;
    min-width: 100%;
    text-align: center
  }

  .ptt__cost .title {
    margin-bottom: 0
  }

  .ptt__cost .text,
  .ptt__cost .title {
    display: inline-block
  }

  .ptt__cost .text>br {
    display: none
  }

  .ptt__stock {
    margin-left: 0;
    margin-right: 15px
  }

  .ptt__send {
    margin-left: 15px
  }
}

@media screen and (max-width:1023px) {
  .price-type-tabs {
    margin-top: 30px
  }

  .price-type-tabs .tabs__contents {
    margin-left: -26px;
    margin-right: -26px
  }

  .price-type-tabs .tabs__labels_buttons_center .tab__label,
  .price-type-tabs .tabs__labels_buttons_center .tab__label.active {
    font-size: 14px
  }

  .ptt__tab .bottom,
  .ptt__tab .top {
    padding-left: 26px;
    padding-right: 26px
  }

  .ptt__tab .bottom {
    padding-bottom: 40px
  }

  .ptt__tab__method {
    margin-bottom: 30px
  }

  .ptt__tab__method__conditions {
    margin-right: 40px
  }

  .ptt__tab__method__conditions>.conditions {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0
  }

  .ptt__tab__method__conditions>.conditions>* {
    flex-basis: 100%;
    margin-left: 0;
    margin-right: 0
  }

  .ptt__tab__method__conditions>.conditions>:first-child {
    margin-bottom: 15px
  }

  .ptt__tab__method__conditions>.conditions>*>.progressive-image-wrapper {
    max-width: 60px;
    min-width: 60px;
    width: 60px
  }

  .ptt__tab__method__image {
    min-width: 340px
  }

  .ptt__tab__method__image>.img:before {
    height: 41px;
    left: 52px;
    width: 51px
  }

  .ptt__tab__method__image>.text {
    margin-top: 15px
  }

  .ptt__tab__advantages {
    margin-left: -5px;
    margin-right: -5px
  }

  .ptt__tab__advantages .title {
    margin-top: 0;
    padding-left: 5px;
    padding-right: 5px
  }

  .ptt__tab__advantages>* {
    font-size: 13px;
    line-height: 20px
  }

  .ptt__tab__advantages>:not(.title) {
    padding-left: 5px;
    padding-right: 5px
  }

  .ptt__tab__advantages>:not(.title):before {
    height: 36px;
    margin-right: 10px;
    min-width: 36px
  }

  .ptt__send {
    font-size: 13px;
    max-width: 290px
  }
}

@media screen and (max-width:980px) {

  .ptt__tab__list__list,
  .ptt__tab__list__title {
    max-width: 100%
  }

  .ptt__tab__list__employee {
    display: none
  }
}

@media screen and (max-width:767px) {

  .payment-page .price-type-tabs-wrapper h2,
  .price-type-tabs-wrapper .h2,
  .price-type-tabs-wrapper .payment-page h2 {
    text-align: left
  }

  .price-type-tabs {
    margin-top: 20px
  }

  .price-type-tabs .tabs__contents {
    margin-left: -15px;
    margin-right: -15px
  }

  .price-type-tabs .tabs__contents>* {
    margin-top: 20px
  }

  .price-type-tabs .tabs__labels_buttons_center {
    justify-content: flex-start;
    margin-left: -8px;
    margin-right: -8px
  }

  .price-type-tabs .tabs__labels_buttons_center .tab__label,
  .price-type-tabs .tabs__labels_buttons_center .tab__label.active {
    font-size: 12px;
    padding-left: 12px;
    padding-right: 12px
  }

  .ptt__tab:after,
  .ptt__tab:before {
    display: none
  }

  .ptt__tab .top {
    padding: 30px 15px
  }

  .ptt__tab .bottom {
    align-items: center;
    flex-direction: column;
    padding: 25px 15px 30px
  }

  .ptt__tab__method {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 25px
  }

  .ptt__tab__method__conditions {
    margin-right: 0
  }

  .ptt__tab__method__conditions>.title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px
  }

  .ptt__tab__method__conditions>.conditions {
    margin-bottom: 25px
  }

  .ptt__tab__method__conditions>.conditions>* .text {
    font-size: 13px;
    line-height: 16px
  }

  .ptt__tab__method__image {
    align-self: center;
    max-width: 290px;
    min-width: 290px
  }

  .ptt__tab__method__image>.img {
    height: 136px
  }

  .ptt__tab__method__image>.img:before {
    left: 70px
  }

  .ptt__tab__method__image>.img>* .progressive-image-wrapper {
    border-radius: 0 !important;
    height: 100% !important;
    width: auto !important
  }

  .ptt__tab__method__image>.img>* .progressive-image-wrapper .progressive-image {
    height: 100% !important;
    width: auto !important
  }

  .ptt__tab__method__image>.text {
    margin-top: 10px
  }

  .ptt__tab__advantages {
    margin-left: 0;
    margin-right: 0
  }

  .ptt__tab__advantages .title {
    padding-left: 0;
    padding-right: 0
  }

  .ptt__tab__advantages>* {
    flex-basis: 100%
  }

  .ptt__tab__advantages>:not(.title) {
    padding-left: 0;
    padding-right: 0
  }

  .ptt__tab__advantages>:not(.title):not(:last-child) {
    margin-bottom: 5px
  }

  .ptt__tab__advantages .ptt__tab__advantage_clock:before {
    background-image: url(/build/i/a9f18f486a0d8d119c2e.svg)
  }

  .ptt__tab__advantages .ptt__tab__advantage_shield:before {
    background-image: url(/build/i/11829a3029d74242c36b.svg)
  }

  .ptt__tab__advantages .ptt__tab__advantage_smile:before {
    background-image: url(/build/i/6a4ab7f58548d27544cf.svg)
  }

  .ptt__cost {
    align-items: flex-start;
    align-self: flex-start;
    flex-direction: column;
    margin-bottom: 20px;
    max-width: 100%;
    text-align: left
  }

  .ptt__cost .title {
    margin-bottom: 5px
  }

  .ptt__tab__list__title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px
  }

  .ptt__tab__list__list>* {
    margin-bottom: 15px;
    padding-left: 40px
  }

  .ptt__tab__list__list>:last-child {
    margin-bottom: 0
  }

  .ptt__tab__list__list>:before {
    height: 24px;
    width: 24px
  }

  .ptt__tab__list__list>* .title {
    font-size: 15px;
    line-height: 25px
  }

  .ptt__stock {
    margin-bottom: 25px;
    margin-right: 0;
    max-width: 315px;
    width: 100%
  }

  .ptt__stock .action-label {
    font-size: 12px;
    line-height: 12px;
    top: -6px
  }

  .ptt__stock .cost {
    font-size: 12px;
    letter-spacing: .45px;
    line-height: 20px
  }

  .ptt__stock .cost .price-old,
  .ptt__stock .cost .price-sub {
    font-size: 16px
  }

  .ptt__stock .cost .price-sub sup {
    border-bottom: 1px solid;
    font-size: 60%;
    margin-left: 3px;
    top: -6px
  }

  .ptt__send {
    margin-left: 0;
    max-width: 315px;
    min-height: 60px;
    width: 100%
  }
}

.stars-trust {
  flex-wrap: nowrap;
  margin-left: -12px;
  margin-right: -12px
}

.stars-trust>* {
  border-radius: 10px;
  display: flex;
  flex-basis: calc(50% - 24px);
  flex-direction: column;
  justify-content: center;
  margin-left: 12px;
  margin-right: 12px;
  min-height: 290px;
  overflow: hidden;
  padding: 20px 20px 20px 234px;
  position: relative;
  z-index: 2
}

.stars-trust>:after,
.stars-trust>:before {
  content: "";
  display: block;
  height: 100%;
  width: 100%
}

.stars-trust>:before {
  background-position: 0;
  background-repeat: no-repeat;
  background-size: auto 100%;
  left: 0;
  position: absolute;
  top: 0;
  z-index: -2
}

.stars-trust>:after {
  background-image: linear-gradient(90deg, hsla(0, 0%, 100%, 0) 17%, #444 53%);
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1
}

.stars-trust>*>.title {
  color: #e79000;
  font-size: 20px;
  margin-bottom: 20px
}

.stars-trust>*>.name,
.stars-trust>*>.title {

  font-style: normal;
  font-weight: 700
}

.stars-trust>*>.name {
  color: #fff;
  font-size: 26px;
  margin-bottom: 17px
}

.stars-trust>*>.profession {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px
}

.stars-trust>*>.profession,
.stars-trust__video-button {
  color: #fff;

  font-style: normal
}

.stars-trust__video-button {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  margin-top: 35px
}

.stars-trust__video-button__video {
  border-radius: 10px;
  display: inline-block;
  height: 28px;
  margin-right: 14px;
  overflow: hidden;
  width: 42px
}

.stars-trust__video-button__video i {
  align-items: center;
  border-radius: 40px 40px 40px 40px/10px 10px 10px 10px;
  box-shadow: none;
  display: flex;
  height: 28px;
  justify-content: center;
  padding: 0;
  width: 42px
}

.stars-trust__video-button__video i:before {
  border-color: transparent transparent transparent #fff;
  border-style: solid;
  border-width: 6px 0 6px 10px;
  content: "";
  display: block;
  height: 0;
  left: 1px;
  position: relative;
  width: 0;
  z-index: 1
}

.stars-trust_todorenko:before {
  background-image: url(/build/i/25d9ddae4221b2682989.jpg)
}

.stars-trust_demidov:before {
  background-image: url(/build/i/f1cc11934d467f3f9468.jpg)
}

@media screen and (max-width:1359px) {
  .page_251 .stars-trust-wrapper+.pusher {
    margin-top: 70px !important
  }
}

@media screen and (max-width:1345px) {
  .stars-trust>* {
    min-height: 250px
  }

  .stars-trust>*>.title {
    font-size: 16px
  }

  .stars-trust>*>.name {
    font-size: 22px
  }

  .stars-trust>*>.profession {
    font-size: 14px
  }
}

@media screen and (max-width:1199px) {
  .stars-trust>* {
    padding-left: 208px
  }
}

@media screen and (max-width:1023px) {
  .stars-trust {
    align-items: center;
    flex-direction: column
  }

  .stars-trust>* {
    margin-bottom: 30px;
    max-width: 600px;
    min-height: 290px;
    width: calc(100% - 24px)
  }

  .stars-trust>:last-child {
    margin-bottom: 0
  }

  .stars-trust>*>.title {
    font-size: 20px
  }

  .stars-trust>*>.name {
    font-size: 26px
  }

  .stars-trust>*>.profession {
    font-size: 15px
  }
}

@media screen and (max-width:550px) {
  .stars-trust {
    align-items: stretch;
    flex-direction: row;
    overflow-x: auto
  }

  .stars-trust>* {
    flex-basis: 215px;
    justify-content: flex-start;
    margin-bottom: 0;
    min-height: 328px;
    padding: 140px 20px 20px;
    width: auto
  }

  .stars-trust>:before {
    background-position: 0 0;
    background-size: cover;
    height: 205px;
    left: 0;
    top: 0;
    width: 100%
  }

  .stars-trust>:after {
    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 17%, #444 53%);
    bottom: 0;
    left: 0;
    top: auto
  }

  .stars-trust>*>.title {
    font-size: 16px
  }

  .stars-trust>*>.name {
    font-size: 20px;
    white-space: nowrap
  }

  .stars-trust>*>.profession {
    font-size: 14px;
    margin-bottom: 25px
  }

  .stars-trust__video-button {
    margin-top: auto
  }
}

.four-banners>.h2,
.payment-page .four-banners>h2 {
  margin-bottom: 55px;
  text-align: left
}

.four-banners__banners {
  margin-left: -13px;
  margin-right: -13px
}

.four-banners__banners>* {
  border-radius: 10px;
  display: flex;
  flex-basis: calc(50% - 26px);
  flex-direction: column;
  justify-content: center;
  margin-left: 13px;
  margin-right: 13px;
  min-height: 123px;
  overflow: hidden;
  padding: 10px 20px 10px 211px;
  position: relative
}

.four-banners__banners>:nth-child(n+3) {
  margin-top: 26px
}

.four-banners__banners>:before {
  background-position: 0;
  background-repeat: no-repeat;
  background-size: auto 100%;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 224px
}

.four-banners__banners>.yellow {
  background-color: #f5eea4
}

.four-banners__banners>.yellow-light {
  background-color: #f0ebd1
}

.four-banners__banners>.blue {
  background-color: #a4dff5
}

.four-banners__banners>.aqua {
  background-color: #58ebe3
}

.four-banners__banners>*>.title {
  color: #37393b;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px
}

.four-banners__banners>*>.link,
.four-banners__banners>*>.slide-tab__label,
.four-banners__banners>*>[data-js=tooltip],
.four-banners__banners>.slider-cost-of__slide__prices>[data-js=tooltip],
.tabs__labels.tabs__labels_buttons .four-banners__banners>*>.tab__label {
  font-size: 14px;
  margin-top: 10px
}

.four-banners__banner_sedation:before {
  background-image: url('../img/demo/comfort-and-safe-1.png')
}

.four-banners__banner_safety:before {
  background-image: url('../img/demo/comfort-and-safe-2.png');
  background-position: -3px bottom;
  background-size: 194px auto
}

.four-banners__banner_anesthesia:before {
  background-image: url('../img/demo/comfort-and-safe-3.png')
}

.four-banners__banner_delivery:before {
  background-image: url('../img/demo/comfort-and-safe-4.png');
  background-position: -20px
}

.four-banners__notice {
  align-items: center;
  color: #5f6877;
  display: flex;

  font-style: normal;
  font-weight: 400;
  margin-top: 31px
}

.four-banners__notice:before {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  content: "";
  display: block;
  height: 89px;
  margin-right: 32px;
  min-width: 129px;
  width: 129px
}

.four-banners__notice .title {
  font-size: 20px;
  line-height: 28px
}

.four-banners__notice .text {
  font-size: 15px;
  line-height: 22px;
  margin-top: 5px
}

.four-banners__notice_car:before {
  background-image: url('../img/demo/med-busik.png')
}

@media screen and (max-width:1359px) {
  .four-banners__banners>*>.title {
    font-size: 18px
  }
}

@media screen and (max-width:1199px) {

  .four-banners>.h2,
  .payment-page .four-banners>h2 {
    margin-bottom: 34px
  }

  .four-banners__banners>* {
    min-height: 92px;
    padding-left: 150px
  }

  .four-banners__banners>:nth-child(n+3) {
    margin-top: 20px
  }

  .four-banners__banners>:before {
    background-position: 0 100%
  }

  .four-banners__banners>*>.title {
    font-size: 16px;
    line-height: 22px
  }

  .four-banners__banner_delivery:before {
    background-position: -20px
  }

  .four-banners__banner_safety:before {
    background-size: 144px 76px
  }

  .four-banners__notice {
    margin-top: 25px
  }

  .four-banners__notice:before {
    margin-right: 40px;
    min-width: 92px;
    width: 92px
  }

  .four-banners__notice .title {
    font-size: 16px;
    line-height: 28px
  }

  .four-banners__notice .text {
    font-size: 13px;
    line-height: 20px;
    margin-top: 0
  }
}

@media screen and (max-width:1023px) {

  .four-banners .h2,
  .four-banners .payment-page h2,
  .payment-page .four-banners h2 {
    text-align: center
  }

  .four-banners__banners>*>.title {
    font-size: 14px
  }

  .four-banners__banner_delivery:before {
    background-position: -20px
  }
}

@media screen and (max-width:767px) {

  .four-banners>.h2,
  .payment-page .four-banners>h2 {
    margin-bottom: 20px
  }

  .four-banners__banners {
    justify-content: center;
    margin-left: 0;
    margin-right: 0
  }

  .four-banners__banners>* {
    flex-basis: 100%;
    max-width: 500%;
    min-height: 94px;
    padding-left: 104px
  }

  .four-banners__banners>:before {
    background-position: 100% 100%;
    background-size: cover;
    width: 112px;
    z-index: 0
  }

  .four-banners__banners>:nth-child(n+2) {
    margin-top: 20px
  }

  .four-banners__banners>*>* {
    position: relative;
    z-index: 1
  }

  .four-banners__banners>*>.title br {
    display: none
  }

  .four-banners__notice {
    align-items: flex-start;
    margin-top: 25px
  }

  .four-banners__notice:before {
    height: 45px;
    margin-right: 14px;
    min-width: 66px;
    width: 66px
  }

  .four-banners__notice .title {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 5px
  }

  .four-banners__notice .text {
    font-size: 12px;
    line-height: 18px
  }

  .four-banners__banner_sedation:before {
    background-position: right -20px center
  }

  .four-banners__banner_safety:before {
    background-size: auto 63px
  }

  .four-banners__banner_anesthesia:before {
    background-size: auto 94px
  }
}

.individual-advantages {
  flex-wrap: nowrap;
  margin-left: -48px;
  margin-right: -48px;
  margin-top: 65px
}

.individual-advantages>* {
  flex-basis: calc(50% - 96px);
  margin-left: 48px;
  margin-right: 48px
}

.individual-advantages__video {
  align-self: flex-start;
  max-width: 562px;
  position: relative
}

.individual-advantages__video:after {
  background-image: linear-gradient(0deg, #000004, transparent);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  bottom: 5px;
  content: "";
  display: block;
  height: 154px;
  opacity: .5;
  position: absolute;
  width: 100%
}

.individual-advantages__video__play {
  display: inline-block;
  height: 80px;
  left: 50%;
  margin-left: -40px;
  margin-top: -40px;
  position: absolute;
  top: 50%;
  width: 80px;
  z-index: 2
}

.individual-advantages__video__play .circle-icon_big {
  align-items: center;
  display: flex;
  height: 80px;
  justify-content: center;
  width: 80px
}

.individual-advantages__video__play .circle-icon_big:before {
  background-size: 32px 32px;
  height: 32px;
  margin-left: 0;
  width: 32px
}

.individual-advantages__video__doctor-info {
  bottom: 100px;
  color: #fdfeff;
  font-size: 14px;
  left: 30px;
  position: absolute;
  z-index: 2
}

.individual-advantages__video__title {
  bottom: 25px;
  color: #fdfeff;

  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  left: 30px;
  padding-right: 30px;
  position: absolute;
  z-index: 2
}

.individual-advantages__advantages__advantages {
  margin-bottom: 50px;
  margin-top: 30px
}

.individual-advantages__advantages__advantages>* {
  align-items: center;
  color: #5f6877;
  display: flex;

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  height: 63px;
  line-height: 28px;
  padding-left: 125px;
  position: relative
}

.individual-advantages__advantages__advantages>:not(:last-child) {
  margin-bottom: 30px
}

.individual-advantages__advantages__advantages>*>.icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  display: block;
  height: 84px;
  left: 0;
  position: absolute;
  width: 95px
}

.individual-advantages__advantages__advantages>*>.icon:before {
  content: "";
  height: 41px;
  position: absolute;
  right: 0;
  top: -5px;
  width: 41px
}

.individual-advantages__advantages__advantages>*>.icon.icon_tooth {
  background-image: url(/build/i/c12a3c9d7e7b42172713.png);
  background-size: 69px 84px;
  height: 84px;
  top: 0
}

.individual-advantages__advantages__advantages>*>.icon.icon_medal {
  background-image: url(/build/i/49e88a1398f2abb7c613.png);
  background-size: 93px 63px;
  height: 63px
}

.individual-advantages__advantages__advantages>*>.icon.icon_piggy-bank {
  background-image: url(/build/i/b2dc54b90269799048ef.jpg);
  background-size: 94px 63px;
  height: 63px
}

.individual-advantages__advantages__advantages>*>.icon.icon_smile {
  background-image: url(/build/i/de1355d120f5ca45601e.png);
  background-size: 85px 40px;
  height: 40px
}

.individual-advantages__advantages__advantages>*>.icon.icon_b-shield:before {
  background-image: url(/build/i/285de392694712279f9b.svg)
}

.individual-advantages__advantages__advantages>*>.icon.icon_b-medal:before {
  background-image: url(/build/i/e3e995b74cf716a69a4a.svg)
}

.individual-advantages__advantages__advantages>*>.icon.icon_b-ruble:before {
  background-image: url(/build/i/8c972745bdf1ab3a4440.svg)
}

.individual-advantages__advantages__advantages>*>.icon.icon_b-diamond:before {
  background-image: url(/build/i/2fb0d1ea783eb963b5c0.svg);
  top: -20px
}

.individual-advantages__advantages__send {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative
}

.individual-advantages__advantages__send:before {
  border: 40px solid #00beb4;
  border-radius: 50%;
  content: "";
  display: block;
  height: 311px;
  opacity: .1;
  position: absolute;
  right: -46px;
  width: 311px
}

.individual-advantages__advantages__send>.button_booking {
  width: 320px
}

.individual-advantages__advantages__send__price {
  margin-right: 20px
}

.individual-advantages__advantages__send__price>.price-title {
  color: #a0abbe;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px
}

.individual-advantages__advantages__send__price>.price-wrapper {
  color: #5f6877;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px
}

.individual-advantages__advantages__send__price .price-old {
  font-size: 16px;
  line-height: 28px;
  -webkit-text-decoration: line-through #d46f00;
  text-decoration: line-through #d46f00
}

.individual-advantages__advantages__send__price .price-sub {

  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  white-space: nowrap
}

.individual-advantages__advantages__send__price .price-sub>sup {
  border-bottom: 1px solid rgba(95, 104, 119, .4);
  font-size: 60%;
  margin-left: 3px;
  top: -6px
}

@media screen and (max-width:1400px) {
  .individual-advantages__advantages__advantages {
    margin-bottom: 25px
  }

  .individual-advantages__advantages__advantages>* {

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    height: 53px;
    line-height: 26px;
    padding-left: 99px
  }

  .individual-advantages__advantages__advantages>:not(:last-child) {
    margin-bottom: 20px
  }

  .individual-advantages__advantages__advantages>*>.icon {
    left: 0;
    width: 80px
  }

  .individual-advantages__advantages__advantages>*>.icon:before {
    height: 35px;
    top: -5px;
    width: 35px
  }

  .individual-advantages__advantages__advantages>*>.icon.icon_tooth {
    background-size: 58px 70px;
    height: 70px;
    top: 0
  }

  .individual-advantages__advantages__advantages>*>.icon.icon_medal {
    background-size: 79px 52px;
    height: 52px
  }

  .individual-advantages__advantages__advantages>*>.icon.icon_piggy-bank {
    background-size: 80px 53px;
    height: 53px
  }

  .individual-advantages__advantages__advantages>*>.icon.icon_smile {
    background-size: 72px 33px;
    height: 33px
  }

  .individual-advantages__advantages__advantages>*>.icon.icon_b-shield:before {
    background-image: url(/build/i/285de392694712279f9b.svg)
  }

  .individual-advantages__advantages__advantages>*>.icon.icon_b-medal:before {
    background-image: url(/build/i/e3e995b74cf716a69a4a.svg)
  }

  .individual-advantages__advantages__advantages>*>.icon.icon_b-ruble:before {
    background-image: url(/build/i/8c972745bdf1ab3a4440.svg)
  }

  .individual-advantages__advantages__advantages>*>.icon.icon_b-diamond:before {
    background-image: url(/build/i/2fb0d1ea783eb963b5c0.svg);
    top: -20px
  }

  .individual-advantages__video {
    max-width: 498px
  }

  .individual-advantages__video>.progressive-image-wrapper {
    width: 100% !important
  }
}

@media screen and (max-width:1320px) {
  .individual-advantages__advantages__send {
    align-items: flex-start;
    flex-direction: column
  }

  .individual-advantages__advantages__advantages {
    margin-bottom: 15px
  }

  .individual-advantages__advantages__send__price {
    margin-bottom: 20px
  }

  .individual-advantages__advantages__send__price>.price-title,
  .individual-advantages__advantages__send__price>.price-wrapper {
    display: inline-block
  }
}

@media screen and (max-width:1244px) {
  .individual-advantages {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0
  }

  .individual-advantages>* {
    flex-basis: 100%;
    margin-left: 0;
    margin-right: 0
  }

  .individual-advantages__video {
    margin-left: auto;
    margin-right: auto;
    order: 2
  }

  .individual-advantages__video__doctor-info {
    bottom: 65px
  }

  .individual-advantages__video__title {
    font-size: 16px
  }

  .individual-advantages__advantages {
    margin-bottom: 40px;
    order: 1
  }

  .individual-advantages__advantages__advantages {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px
  }

  .individual-advantages__advantages__advantages>* {
    flex: 1 0 calc(50% - 30px);
    font-size: 15px;
    line-height: 20px;
    margin-left: 15px;
    margin-right: 15px
  }

  .individual-advantages__advantages__send {
    align-items: center;
    justify-content: flex-start
  }

  .individual-advantages__advantages__send:before {
    display: none
  }
}

@media screen and (max-width:767px) {
  .individual-advantages__advantages__advantages {
    display: block;
    margin-left: 0;
    margin-right: 0
  }

  .individual-advantages__advantages__advantages>* {
    margin-left: 0;
    margin-right: 0
  }

  .individual-advantages__advantages__send>.button_booking {
    max-width: 290px;
    width: 100%
  }

  .individual-advantages__advantages__send__price {
    margin-bottom: 30px;
    margin-right: 0;
    text-align: center
  }
}

@media screen and (max-width:600px) {
  .individual-advantages__video:after {
    bottom: 4px
  }

  .individual-advantages__video__doctor-info {
    display: none
  }
}

.approved-clinic {
  background-color: #00beb4;
  border-radius: 14px;
  overflow: hidden;
  padding: 44px 20px 48px;
  position: relative
}

.approved-clinic:before {
  background-image: url(/build/i/eaa4449dfca3aeed739b.png);
  background-position: center 78%;
  background-repeat: no-repeat;
  background-size: 602px auto;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1
}

.approved-clinic>.h2,
.payment-page .approved-clinic>h2 {
  color: #fff;

  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 40px;
  position: relative;
  z-index: 2
}

.approved-clinic__grid {
  justify-content: center;
  margin-left: -15px;
  margin-right: -15px;
  position: relative;
  z-index: 2
}

.approved-clinic__grid>* {
  color: #fff;
  flex: 0 0 auto;

  font-style: normal;
  font-weight: 400;
  margin-left: 15px;
  margin-right: 15px;
  max-width: 300px;
  position: relative
}

.approved-clinic__grid>*>.title {
  align-items: center;
  display: flex;
  font-size: 26px;
  height: 46px;
  margin-bottom: 9px;
  padding-left: 73px
}

.approved-clinic__grid>*>.title span {
  font-size: 36px
}

.approved-clinic__grid>*>.title:before {
  background-image: url(/build/i/5593f73357bbf0fd9407.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  content: "";
  display: block;
  height: 46px;
  left: 0;
  min-width: 46px;
  position: absolute
}

.approved-clinic__grid>*>.text {
  font-size: 16px;
  padding-left: 73px
}

.approved-clinic__notice {
  color: #3c4143;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-top: 35px;
  text-align: center
}

@media screen and (max-width:1359px) {
  .approved-clinic__notice {
    margin-bottom: -40px
  }
}

@media screen and (max-width:1023px) {
  .approved-clinic:before {
    display: none
  }

  .approved-clinic>.h2,
  .payment-page .approved-clinic>h2 {
    margin-bottom: 35px
  }

  .approved-clinic__grid>* {
    flex-basis: calc(50% - 30px)
  }

  .approved-clinic__grid>:last-child {
    flex-basis: 100%;
    margin-top: 30px
  }

  .approved-clinic__notice {
    margin-bottom: -30px
  }
}

@media screen and (max-width:767px) {
  .approved-clinic {
    padding: 35px 25px
  }

  .approved-clinic__grid,
  .approved-clinic__grid>* {
    margin-left: 0;
    margin-right: 0
  }

  .approved-clinic__grid>* {
    flex-basis: 100%;
    max-width: 100%
  }

  .approved-clinic__grid>:not(:first-child) {
    margin-top: 30px
  }

  .approved-clinic__grid>*>.text,
  .approved-clinic__grid>*>.title {
    padding-left: 65px
  }

  .approved-clinic__grid>*>.text>br {
    display: none
  }

  .approved-clinic__notice {
    font-size: 15px;
    line-height: 24px;
    margin-bottom: -10px;
    margin-top: 20px;
    text-align: left
  }
}

@media screen and (max-width:414px) {

  .approved-clinic>.h2,
  .payment-page .approved-clinic>h2 {
    font-size: 24px !important;
    line-height: 30px
  }
}

.attainment-image-slider-wrapper_allon4 .four-time-guarantee-wrapper.attainment-image-slider,
.four-time-guarantee-wrapper.appointment__inner,
.four-time-guarantee-wrapper.bg_dots,
.four-time-guarantee-wrapper.cards-with-text:before,
.four-time-guarantee-wrapper.director-quote__right:before,
.four-time-guarantee-wrapper.is-suitable.bg_dots-half:before,
.four-time-guarantee-wrapper.main-page-header__badge:before,
.four-time-guarantee-wrapper.payment-form,
.four-time-guarantee-wrapper.with-image-slider-wrapper_dots,
.four-time-guarantee-wrapper.works-slider__item,
.offer_standard .four-time-guarantee-wrapper.offer__card:before,
.through-block-short .four-time-guarantee-wrapper.through-block_hold-prices {
  padding-bottom: 45px;
  padding-top: 80px
}

.four-time-guarantee>.h2,
.payment-page .four-time-guarantee>h2 {
  margin-bottom: 30px;
  text-align: left
}

.four-time-guarantee>.notice {
  color: #5f6877;

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 50px
}

.four-time-guarantee__grid {
  margin-left: -30px;
  margin-right: -30px
}

.four-time-guarantee__grid>* {
  flex-basis: calc(25% - 60px);
  margin-left: 30px;
  margin-right: 30px;
  position: relative
}

.four-time-guarantee__grid>:before {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  content: "";
  display: block;
  height: 129px;
  margin-bottom: 35px;
  width: 119px
}

.four-time-guarantee__grid>:last-child {
  background-color: #fff;
  border: 14px solid #fdb700;
  border-radius: 10px;
  padding: 0 20px 20px
}

.four-time-guarantee_lifetime-warranty:before {
  background-image: url('../img/demo/four-time-guarantee-1.png')
}

.four-time-guarantee_15-years:before {
  background-image: url('../img/demo/four-time-guarantee-2.png')
}

.four-time-guarantee_champions:before {
  background-image: url('../img/demo/four-time-guarantee-3.png')
}

.four-time-guarantee_insurance {
  background-image: url(/build/i/45e9c4c7387776424d21.jpg);
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% auto
}

.four-time-guarantee_insurance:before {
  background-image: url('../img/demo/four-time-guarantee-4.png');
  margin-bottom: 19px
}

.four-time-guarantee__title {
  color: #333638;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 20px
}

.four-time-guarantee__text {
  color: #989ca6;
  font-size: 14px
}

.four-time-guarantee__awards,
.four-time-guarantee__text {

  font-style: normal;
  font-weight: 400;
  line-height: 22px
}

.four-time-guarantee__awards {
  color: #5f6877;
  font-size: 16px;
  left: 75px;
  position: absolute;
  top: 20px
}

.four-time-guarantee__awards:before {
  background-image: url(/build/i/75b4b1b454d4203004ce.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  content: "";
  display: block;
  height: 56px;
  margin-bottom: 15px;
  width: 89px
}

.four-time-guarantee__awards .link,
.four-time-guarantee__awards .slide-tab__label,
.four-time-guarantee__awards .tabs__labels.tabs__labels_buttons .tab__label,
.four-time-guarantee__awards [data-js=tooltip],
.tabs__labels.tabs__labels_buttons .four-time-guarantee__awards .tab__label {
  font-size: 16px;
  line-height: 22px
}

@media screen and (max-width:1199px) {

  .attainment-image-slider-wrapper_allon4 .four-time-guarantee-wrapper.attainment-image-slider,
  .four-time-guarantee-wrapper.appointment__inner,
  .four-time-guarantee-wrapper.bg_dots,
  .four-time-guarantee-wrapper.cards-with-text:before,
  .four-time-guarantee-wrapper.director-quote__right:before,
  .four-time-guarantee-wrapper.is-suitable.bg_dots-half:before,
  .four-time-guarantee-wrapper.main-page-header__badge:before,
  .four-time-guarantee-wrapper.payment-form,
  .four-time-guarantee-wrapper.with-image-slider-wrapper_dots,
  .four-time-guarantee-wrapper.works-slider__item,
  .offer_standard .four-time-guarantee-wrapper.offer__card:before,
  .through-block-short .four-time-guarantee-wrapper.through-block_hold-prices {
    padding-bottom: 40px;
    padding-top: 40px
  }

  .four-time-guarantee-wrapper+.divider {
    margin-bottom: 70px !important;
    margin-top: 70px !important
  }

  .four-time-guarantee__grid>* {
    flex-basis: calc(50% - 60px)
  }

  .four-time-guarantee__grid>:nth-child(n+3) {
    margin-top: 40px
  }
}

@media screen and (max-width:767px) {

  .attainment-image-slider-wrapper_allon4 .four-time-guarantee-wrapper.attainment-image-slider,
  .four-time-guarantee-wrapper.appointment__inner,
  .four-time-guarantee-wrapper.bg_dots,
  .four-time-guarantee-wrapper.cards-with-text:before,
  .four-time-guarantee-wrapper.director-quote__right:before,
  .four-time-guarantee-wrapper.is-suitable.bg_dots-half:before,
  .four-time-guarantee-wrapper.main-page-header__badge:before,
  .four-time-guarantee-wrapper.payment-form,
  .four-time-guarantee-wrapper.with-image-slider-wrapper_dots,
  .four-time-guarantee-wrapper.works-slider__item,
  .offer_standard .four-time-guarantee-wrapper.offer__card:before,
  .through-block-short .four-time-guarantee-wrapper.through-block_hold-prices {
    padding-bottom: 30px;
    padding-top: 30px
  }

  .four-time-guarantee-wrapper+.divider {
    margin-bottom: 35px !important;
    margin-top: 45px !important
  }

  .four-time-guarantee>.h2,
  .payment-page .four-time-guarantee>h2 {
    margin-bottom: 20px;
    text-align: center
  }

  .four-time-guarantee>.notice {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 25px
  }

  .four-time-guarantee__grid {
    justify-content: center
  }

  .four-time-guarantee__grid>* {
    flex-basis: calc(100% - 60px)
  }

  .four-time-guarantee__grid>:not(:last-child) {
    border-bottom: 1px solid #d8e1e1;
    flex-basis: 100%;
    margin: 0 0 15px;
    padding-bottom: 14px;
    padding-left: 30px;
    padding-right: 30px
  }

  .four-time-guarantee__grid>:not(:last-child):before {
    height: 70px;
    left: 30px;
    margin-bottom: 0;
    position: absolute;
    width: 60px
  }

  .four-time-guarantee__grid>:not(:last-child) .four-time-guarantee__title {
    font-size: 14px;
    height: 75px;
    line-height: 20px;
    margin-bottom: 0;
    padding-left: 70px;
    padding-right: 56px
  }

  .four-time-guarantee__grid>:not(:last-child) .four-time-guarantee__text {
    margin-top: 10px
  }

  .four-time-guarantee__grid>.four-time-guarantee_champions {
    margin-top: 15px
  }

  .four-time-guarantee__grid>.four-time-guarantee_champions .four-time-guarantee__title {
    margin-top: 10px
  }

  .four-time-guarantee__grid>.four-time-guarantee_insurance {
    margin-top: 0;
    padding: 0
  }

  .four-time-guarantee__grid>.four-time-guarantee_insurance:before {
    margin-left: 15px
  }

  .four-time-guarantee__grid>.four-time-guarantee_insurance .four-time-guarantee__title {
    background-color: #fff;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px
  }

  .four-time-guarantee__grid>.four-time-guarantee_insurance .four-time-guarantee__title:after {
    right: 15px
  }

  .four-time-guarantee__grid>.four-time-guarantee_insurance .four-time-guarantee__text {
    padding: 0 15px 15px
  }

  .four-time-guarantee_insurance {
    background-image: url(/build/i/45e9c4c7387776424d21.jpg);
    max-width: 450px
  }

  .four-time-guarantee__title {
    align-items: center;
    color: #00beb4;
    display: flex;
    font-size: 14px;
    line-height: 20px;
    padding-right: 25px;
    position: relative
  }

  .four-time-guarantee__title.accordion__tab__title:after {
    background-image: url(/build/i/af7f5fc8428807b17015.svg);
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    height: 7px;
    margin-top: -4px;
    position: absolute;
    right: 0;
    top: 50%;
    width: 10px
  }

  .four-time-guarantee__title.accordion__tab__title.active:after {
    transform: rotate(-180deg)
  }

  .four-time-guarantee__awards {
    display: flex;
    font-size: 12px;
    left: 59px;
    line-height: 17px;
    top: -11px
  }

  .four-time-guarantee__awards:before {
    height: 32px;
    margin-bottom: 0;
    margin-right: 8px;
    min-width: 52px;
    width: 52px
  }

  .four-time-guarantee__awards .link,
  .four-time-guarantee__awards .slide-tab__label,
  .four-time-guarantee__awards .tabs__labels.tabs__labels_buttons .tab__label,
  .four-time-guarantee__awards [data-js=tooltip],
  .tabs__labels.tabs__labels_buttons .four-time-guarantee__awards .tab__label {
    font-size: 12px;
    line-height: 17px
  }

  .four-time-guarantee__awards>span {
    max-width: 230px
  }

  .four-time-guarantee__text {
    display: none
  }

  .four-time-guarantee__text.active {
    display: block
  }
}

.attainment-image-slider-wrapper_allon4 .smart-service-wrapper.attainment-image-slider,
.offer_standard .smart-service-wrapper.offer__card:before,
.smart-service-wrapper.appointment__inner,
.smart-service-wrapper.bg_dots,
.smart-service-wrapper.cards-with-text:before,
.smart-service-wrapper.director-quote__right:before,
.smart-service-wrapper.is-suitable.bg_dots-half:before,
.smart-service-wrapper.main-page-header__badge:before,
.smart-service-wrapper.payment-form,
.smart-service-wrapper.with-image-slider-wrapper_dots,
.smart-service-wrapper.works-slider__item,
.through-block-short .smart-service-wrapper.through-block_hold-prices {
  padding: 88px 0
}

.payment-page .smart-service>h2,
.smart-service>.h2 {
  margin-bottom: 20px;
  text-align: left
}

.smart-service>.text {
  color: #5f6877;

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px
}

.smart-service__grid {
  align-items: stretch;
  flex-wrap: nowrap;
  margin-left: -12px;
  margin-right: -12px;
  margin-top: 48px
}

.smart-service__grid>* {
  background-position: center 0;
  display: flex;
  flex-basis: calc(33.33% - 24px);
  flex-direction: column;
  margin-left: 12px;
  margin-right: 12px;
  min-height: 330px
}

.smart-service__grid>:before {
  background-image: url('../img/demo/icon-plus-orange.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  content: "";
  display: block;
  height: 43px;
  margin-bottom: 30px;
  width: 43px
}

.smart-service__grid>*>.progressive-image-wrapper {
  height: 220px !important;
  margin-top: auto;
  max-width: 390px;
  width: 100% !important
}

.smart-service__grid>*>.progressive-image-wrapper>.progressive-image {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%
}

.smart-service__title {
  color: #5f6877;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 10px;
  text-transform: uppercase
}

.smart-service__text,
.smart-service__title {

  font-style: normal
}

.smart-service__text {
  color: #989ca6;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 15px
}

@media screen and (max-width:1359px) {

  .payment-page .smart-service h2 br,
  .smart-service .h2 br,
  .smart-service .payment-page h2 br {
    display: none
  }

  .smart-service>.text {
    font-size: 16px
  }

  .smart-service__grid {
    margin-top: 36px
  }

  .smart-service__grid>:before {
    height: 34px;
    margin-bottom: 24px;
    width: 34px
  }
}

@media screen and (max-width:1023px) {

  .payment-page .smart-service>h2,
  .smart-service>.h2 {
    text-align: center
  }

  .smart-service__grid {
    flex-wrap: wrap
  }

  .smart-service__grid>* {
    flex-basis: calc(50% - 24px);
    max-width: calc(50% - 24px)
  }

  .smart-service__grid>:before {
    margin-bottom: 20px
  }

  .smart-service__grid>:last-child {
    margin-top: 35px
  }
}

@media screen and (max-width:767px) {

  .attainment-image-slider-wrapper_allon4 .smart-service-wrapper.attainment-image-slider,
  .offer_standard .smart-service-wrapper.offer__card:before,
  .smart-service-wrapper.appointment__inner,
  .smart-service-wrapper.bg_dots,
  .smart-service-wrapper.cards-with-text:before,
  .smart-service-wrapper.director-quote__right:before,
  .smart-service-wrapper.is-suitable.bg_dots-half:before,
  .smart-service-wrapper.main-page-header__badge:before,
  .smart-service-wrapper.payment-form,
  .smart-service-wrapper.with-image-slider-wrapper_dots,
  .smart-service-wrapper.works-slider__item,
  .through-block-short .smart-service-wrapper.through-block_hold-prices {
    padding-bottom: 35px;
    padding-top: 35px
  }

  .payment-page .smart-service>h2,
  .smart-service>.h2 {
    margin-bottom: 15px;
    text-align: center
  }

  .smart-service>.text {
    font-size: 14px
  }

  .smart-service__grid {
    align-items: flex-start;
    flex-direction: column;
    margin-top: 0
  }

  .smart-service__grid>* {
    flex-basis: 100%;
    margin-top: 30px;
    max-width: 390px;
    min-height: auto
  }

  .smart-service__grid>:before {
    height: 25px;
    margin-bottom: 10px;
    width: 25px
  }

  .smart-service__grid>*>.progressive-image-wrapper {
    height: 164px !important
  }

  .smart-service__title {
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 4px;
    margin-right: -5px
  }

  .smart-service__text {
    font-size: 13px;
    line-height: 18px
  }
}

.steps-operations>.last {
  margin-bottom: 0
}

.steps-operations__title {
  margin-bottom: 45px
}

.steps-operations__item {
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 75px;
  margin-left: -15px;
  margin-right: -15px
}

.steps-operations__item.last {
  margin-bottom: 0
}

.steps-operations__item>* {
  flex-basis: calc(50% - 30px);
  margin-left: 15px;
  margin-right: 15px
}

.steps-operations__item>:first-child {
  margin-right: auto;
  max-width: 640px
}

.steps-operations__item.align-left>:first-child {
  padding-right: 104px
}

.steps-operations__item.align-right>:first-child {
  order: 2;
  padding-left: 104px
}

.steps-operations__item.align-right>:last-child {
  order: 1
}

.steps-operations__item>.progressive-image-wrapper {
  max-width: 493px
}

.steps-operations__item__title {
  color: #333638;

  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 20px
}

.steps-operations__item__text>p {
  margin-bottom: 25px
}

.steps-operations__item__text>* {
  color: #5f6877;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px
}

.steps-operations__item__text>:last-child {
  margin-bottom: 0
}

@media screen and (max-width:1244px) {
  .steps-operations__item.align-left>:first-child {
    padding-right: 40px
  }

  .steps-operations__item.align-right>:first-child {
    padding-left: 40px
  }

  .steps-operations__item__title {
    font-size: 20px
  }
}

@media screen and (max-width:1023px) {
  .steps-operations__title {
    margin-bottom: 30px
  }

  .steps-operations__item {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 45px;
    margin-left: auto;
    margin-right: auto
  }

  .steps-operations__item>* {
    flex-basis: calc(100% - 30px)
  }

  .steps-operations__item>:first-child {
    margin-left: 15px;
    margin-right: 15px;
    max-width: 640px;
    order: 2
  }

  .steps-operations__item>:last-child {
    order: 1
  }

  .steps-operations__item.align-left>:first-child {
    padding-right: 0
  }

  .steps-operations__item.align-right>:first-child {
    padding-left: 0
  }

  .steps-operations__item>.progressive-image-wrapper {
    margin-bottom: 25px;
    margin-left: 15px;
    margin-right: 15px
  }
}

.delivery-rest-wrapper .h2,
.delivery-rest-wrapper .payment-page h2,
.payment-page .delivery-rest-wrapper h2 {
  margin-bottom: 65px
}

.delivery-rest__advantages {
  flex-wrap: nowrap;
  justify-content: center;
  margin-bottom: 60px;
  margin-left: -15px;
  margin-right: -15px
}

.delivery-rest__advantages>* {
  flex-basis: calc(50% - 30px);
  margin-left: 15px;
  margin-right: 15px;
  max-width: 392px
}

.delivery-rest__advantages>*>.title {
  align-items: center;
  color: #5f6877;
  display: flex;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 25px
}

.delivery-rest__advantages>*>.title:before {
  background-image: url(/build/i/5593f73357bbf0fd9407.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  content: "";
  display: block;
  height: 46px;
  margin-right: 15px;
  min-width: 46px
}

.delivery-rest__advantages>*>.progressive-image-wrapper {
  max-width: 390px !important;
  width: 100% !important
}

.delivery-rest__text {
  color: #5f6877;

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 35px;
  max-width: 515px;
  text-align: center
}

.delivery-rest__text>strong {

  font-style: normal;
  font-weight: 500
}

.delivery-rest__number {
  align-items: center;
  display: flex;
  max-width: 412px
}

.delivery-rest__number>.number {
  background-color: #00beb4;
  color: #fff;

  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  margin-right: 24px;
  padding: 0 15px
}

.delivery-rest__number>.text {
  color: #5f6877;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px
}

.delivery-rest__number>.text>strong {

  font-style: normal;
  font-weight: 500
}

@media screen and (max-width:767px) {

  .delivery-rest-wrapper .h2,
  .delivery-rest-wrapper .payment-page h2,
  .payment-page .delivery-rest-wrapper h2 {
    margin-bottom: 20px
  }

  .delivery-rest__advantages {
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px
  }

  .delivery-rest__advantages>:not(:last-child) {
    margin-bottom: 25px
  }

  .delivery-rest__advantages>* .title {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 15px
  }

  .delivery-rest__advantages>* .title:before {
    height: 35px;
    min-width: 35px;
    width: 35px
  }

  .delivery-rest__text {
    font-size: 18px;
    line-height: 24px
  }
}

@media screen and (max-width:550px) {
  .delivery-rest__text {
    margin-bottom: 20px
  }

  .delivery-rest__number {
    flex-direction: column;
    text-align: center
  }

  .delivery-rest__number>.number {
    margin-bottom: 20px;
    margin-right: 0
  }
}

.fair-prices-video-wrapper {
  padding-bottom: 67px
}

.fair-prices-video-wrapper>div {
  background-color: #f7f6f6;
  position: relative
}

.fair-prices-video-wrapper>div>:not(.progressive-image) {
  position: relative;
  z-index: 2
}

.fair-prices-video-wrapper>div>.progressive-image {
  width: 100%
}

.fair-prices-video {
  padding-top: 100px
}

.fair-prices-video>.h2,
.payment-page .fair-prices-video>h2 {
  margin-bottom: 35px;
  text-align: left
}

.fpv__text {
  margin-bottom: 50px;
  max-width: 570px
}

.fpv__text>* {
  color: #5f6877;

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 15px
}

.fpv__text>:last-child {
  margin-bottom: 0
}

.fpv__percents {

  font-style: normal;
  font-weight: 500;
  margin-bottom: 73px;
  margin-left: -15px;
  margin-right: -15px;
  max-width: 780px
}

.fpv__percents>* {
  align-items: center;
  display: flex;
  flex: 1 0 calc(50% - 30px);
  margin-left: 15px;
  margin-right: 15px
}

.fpv__percents>* .percent {
  color: #ea901c;
  font-size: 46px;
  line-height: 30px;
  margin-right: 20px;
  white-space: nowrap
}

.fpv__percents>* .title {
  color: #333638;
  font-size: 18px;
  line-height: 24px
}

.fpv__video {
  display: block;
  height: 293px;
  margin-bottom: -67px;
  width: 527px
}

.fpv__video .progressive-image-wrapper {
  box-shadow: 0 7px 43px rgba(0, 0, 0, .1)
}

@media screen and (max-width:1470px) {
  .fair-prices-video-wrapper>div>.progressive-image {
    width: 100%
  }
}

@media screen and (max-width:1300px) {
  .fair-prices-video-wrapper>div>.progressive-image {
    background-position-x: -240px
  }
}

@media screen and (max-width:1100px) {
  .fair-prices-video-wrapper>div>.progressive-image {
    background-position-x: -330px
  }

  .fair-prices-video {
    padding-top: 60px
  }

  .fair-prices-video>.h2,
  .payment-page .fair-prices-video>h2 {
    margin-bottom: 25px
  }

  .fpv__text {
    margin-bottom: 30px
  }

  .fpv__percents {
    flex-direction: column;
    margin-bottom: 45px;
    margin-left: 0;
    margin-right: 0
  }

  .fpv__percents>* {
    flex-basis: 100%;
    margin-left: 0;
    margin-right: 0
  }

  .fpv__percents>:not(:last-child) {
    margin-bottom: 15px
  }

  .fpv__percents>* .percent {
    font-size: 36px;
    margin-right: 15px;
    min-width: 94px;
    text-align: right
  }

  .fpv__percents>* .title {
    font-size: 16px;
    line-height: 20px
  }
}

@media screen and (max-width:1023px) {
  .page_251 .fair-prices-video-wrapper+.pusher {
    margin-top: 60px !important
  }
}

@media screen and (max-width:900px) {
  .fair-prices-video-wrapper>div>.progressive-image {
    background-position-x: -400px
  }
}

@media screen and (max-width:767px) {
  .fair-prices-video-wrapper {
    background-color: #f4f7f8;
    background-image: url(/build/i/cd469867279222393ec9.svg);
    background-repeat: repeat;
    background-size: 21px 26px;
    padding-bottom: 0
  }

  .fair-prices-video-wrapper>div {
    background-color: transparent
  }

  .fair-prices-video-wrapper>div>.progressive-image {
    display: none !important
  }

  .fpv__text>* {
    font-size: 16px;
    line-height: 28px
  }

  .fair-prices-video {
    padding-bottom: 50px;
    padding-top: 50px
  }

  .fpv__video {
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto
  }

  .page_251 .fair-prices-video-wrapper+.pusher {
    margin-top: 30px !important
  }
}

@media screen and (max-width:580px) {
  .fair-prices-video {
    padding-bottom: 35px;
    padding-top: 35px
  }

  .fpv__video {
    height: auto;
    max-width: 527px;
    width: 100%
  }

  .fpv__video .progressive-image-wrapper {
    width: 100% !important
  }
}

@media screen and (max-width:460px) {
  .fpv__video {
    height: auto
  }
}

@media screen and (max-width:399px) {
  .fpv__percents>* .percent {
    font-size: 30px;
    margin-right: 10px;
    min-width: 77px
  }

  .fpv__percents>* .title {
    font-size: 14px
  }
}

.service-feedback-wrapper .h2-sub {
  margin-top: 28px
}

.service-feedback {
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 29px rgba(70, 81, 86, .18);
  margin-top: 45px;
  overflow: hidden
}

.service-feedback__image {
  align-self: stretch;
  border-radius: 0 50% 50% 0;
  margin: -100px -125px;
  min-height: calc(100% + 200px);
  min-width: 800px;
  overflow: hidden;
  position: relative;
  right: 125px;
  width: 800px
}

.service-feedback__image>.progressive-image {
  height: calc(100% - 200px) !important;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: calc(100% - 250px) !important
}

.service-feedback__info {
  padding: 30px 75px
}

.service-feedback__info>.title {
  color: #3c4143;

  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 25px
}

.service-feedback__info>.text {
  max-width: 390px
}

.service-feedback__info>.text>* {

  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 25px
}

.service-feedback__info>.text>:last-child {
  margin-bottom: 0
}

.service-feedback__info>.text .tick-list>li .text {
  font-size: 15px;
  line-height: 22px
}

.service-feedback__form .input__wrapper {
  max-width: 390px
}

.service-feedback__form .input__wrapper textarea {
  height: 115px;
  max-height: 115px
}

.service-feedback__form .recaptcha {
  height: 85px;
  position: relative
}

.service-feedback__form .g-recaptcha {
  left: -30px;
  position: absolute;
  transform: scale(.8)
}

.service-feedback__form .g-recaptcha .form__error {
  bottom: -15px
}

.service-feedback__form__button {
  align-items: center;
  display: flex;
  max-width: 385px;
  padding-top: 20px
}

.service-feedback__form__button .button,
.service-feedback__form__button .slide-tab__label.active,
.service-feedback__form__button .tabs__labels.tabs__labels_buttons .tab__label.active,
.tabs__labels.tabs__labels_buttons .service-feedback__form__button .tab__label.active {
  border-radius: 30px;
  height: 60px;
  margin-right: 30px;
  min-width: 206px
}

.service-feedback__form__button>div {
  color: #bdc7ca;

  font-size: 12px;
  font-style: normal;
  font-weight: 400
}

.service-feedback__form__button.service-feedback__form__button_col {
  align-items: flex-start;
  flex-direction: column
}

.service-feedback__form__button.service-feedback__form__button_col .button,
.service-feedback__form__button.service-feedback__form__button_col .slide-tab__label.active,
.service-feedback__form__button.service-feedback__form__button_col .tabs__labels.tabs__labels_buttons .tab__label.active,
.tabs__labels.tabs__labels_buttons .service-feedback__form__button.service-feedback__form__button_col .tab__label.active {
  margin-bottom: 25px;
  margin-right: 0;
  width: 100%
}

@media screen and (max-width:1359px) {
  .service-feedback__info {
    padding: 25px 50px
  }

  .service-feedback__info>.text .tick-list>li {
    margin-bottom: 8px
  }

  .service-feedback__info>.text .tick-list>li .text {
    font-size: 14px;
    line-height: 20px
  }
}

@media screen and (max-width:1180px) {
  .service-feedback__info {
    padding: 30px 20px 30px 30px
  }

  .service-feedback__info>.title {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 20px
  }

  .service-feedback__info>.text>* {
    font-size: 13px
  }

  .service-feedback__info>.text .tick-list>li {
    margin-bottom: 8px
  }

  .service-feedback__info>.text .tick-list>li .text {
    font-size: 13px;
    line-height: 20px
  }
}

@media screen and (max-width:1160px) {
  .service-feedback__image {
    margin-left: -200px
  }
}

@media screen and (max-width:1023px) {
  .service-feedback-wrapper .h2-sub {
    margin-top: 15px
  }

  .service-feedback {
    margin-top: 30px
  }

  .service-feedback__image {
    display: none
  }

  .service-feedback__info {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 45px 30px;
    width: 100%
  }

  .service-feedback__info>.title {
    font-size: 30px;
    margin-bottom: 20px
  }

  .service-feedback__info>.text {
    max-width: 390px
  }

  .service-feedback__form>* {
    margin-left: auto;
    margin-right: auto
  }

  .service-feedback__form .input__wrapper {
    display: block
  }
}

@media screen and (max-width:771px) {
  .service-feedback {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start
  }

  .service-feedback__image {
    border-radius: 0 0 50% 50%;
    display: block;
    margin: 0;
    max-height: 440px !important;
    min-height: 440px;
    min-width: calc(100% + 140px);
    right: -50%;
    transform: translateX(-50%);
    width: calc(100% + 140px)
  }

  .service-feedback__image>.progressive-image {
    height: 100% !important;
    margin: auto;
    right: calc(-50% + 140px);
    top: 0;
    transform: translate(-50%);
    width: calc(100% - 140px) !important
  }

  .service-feedback__info {
    padding: 25px 15px 30px
  }

  .service-feedback__form__button .button,
  .service-feedback__form__button .slide-tab__label.active,
  .service-feedback__form__button .tabs__labels.tabs__labels_buttons .tab__label.active,
  .tabs__labels.tabs__labels_buttons .service-feedback__form__button .tab__label.active {
    height: 49px
  }
}

@media screen and (max-width:767px) {
  .service-feedback-wrapper .h2-sub {
    margin-top: 5px
  }

  .service-feedback {
    margin-top: 25px
  }

  .service-feedback__info>.title {
    font-size: 26px
  }
}

@media screen and (max-width:550px) {
  .service-feedback__image {
    min-height: calc(100vw - 88px)
  }
}

.implantation-reviews .implantation-reviews__h2-sub {
  color: #5f6877;

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 30px;
  text-align: center
}

.implant-return-reviews .implant-return-reviews__h2-sub {
  color: #5f6877;

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 30px
}

.implantation-reviews__slider-wrapper {
  margin-top: 0
}

.implantation-reviews__slider-wrapper .slider-wrapper-image {
  padding-bottom: 0 !important;
  padding-top: 0 !important
}

.implantation-reviews__slider {
  margin-left: auto;
  margin-right: auto;
  max-width: 934px
}

.implantation-reviews__slider .slick-slide {
  padding: 65px
}

.implantation-reviews__slider .slick-slide .slider__item {
  align-items: center;
  border-radius: 14px;
  box-shadow: 0 7px 38px rgba(0, 0, 0, .1);
  display: flex;
  justify-content: center;
  max-width: 804px;
  overflow: hidden
}

.implantation-reviews__slider .slick-slide .slider__item__image-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 0 !important
}

.implantation-reviews__slider .slick-slide .progressive-image-wrapper {
  border-radius: 0
}

.implantation-reviews__slider .image-slider__navigator {
  box-shadow: none;
  height: 0;
  left: 0;
  margin-top: -30px;
  position: absolute;
  top: 50%;
  width: 100%
}

.implantation-reviews__slider .image-slider__navigator .prev {
  left: 4px;
  position: absolute
}

.implantation-reviews__slider .image-slider__navigator .next {
  position: absolute;
  right: 4px
}

.implantation-reviews__slider-mobile {
  display: none;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%
}

.implantation-reviews__slider-mobile .slick-slide {
  padding: 30px 15px
}

.implantation-reviews__slider-mobile .slick-slide .slider__item {
  align-items: center;
  border-radius: 14px;
  box-shadow: 0 7px 38px rgba(0, 0, 0, .1);
  display: flex;
  justify-content: center;
  overflow: hidden
}

.implantation-reviews__slider-mobile .slick-slide .slider__item__image-wrapper {
  align-items: center;
  display: flex;
  justify-content: center
}

.implantation-reviews__slider-mobile .slick-slide .progressive-image-wrapper {
  border-radius: 0
}

.implantation-reviews__slider-mobile .image-slider__navigator {
  box-shadow: none;
  height: 0;
  left: 0;
  margin-top: -30px;
  position: absolute;
  top: 50%;
  width: 100%
}

.implantation-reviews__slider-mobile .image-slider__navigator .prev {
  left: 0;
  position: absolute
}

.implantation-reviews__slider-mobile .image-slider__navigator .next {
  position: absolute;
  right: 0
}

@media screen and (max-width:1023px) {
  .implantation-reviews__slider .slick-slide {
    padding-left: 56px;
    padding-right: 56px
  }

  .implantation-reviews__slider .slider-wrapper-image {
    margin-bottom: 0
  }
}

@media screen and (max-width:767px) {
  .implantation-reviews .implantation-reviews__h2-sub {
    font-size: 16px;
    margin-top: 20px
  }

  .implant-return-reviews .implant-return-reviews__h2-sub {
    font-size: 16px;
    margin-top: 15px
  }

  .implantation-reviews__slider-wrapper {
    margin-top: 25px
  }

  .implantation-reviews__slider {
    display: none
  }

  .implantation-reviews__slider-mobile {
    display: block
  }

  .implantation-reviews__slider-mobile .image-slider__navigator {
    bottom: 10px;
    height: 49px;
    left: 50%;
    margin-left: -50px;
    top: auto;
    width: 100px
  }
}

.slider-case__title {
  color: #333638;

  font-style: normal;
  font-weight: 400;
  margin-bottom: 50px;
  text-align: left
}

.slider-case__pager-wrapper {
  margin-bottom: 25px
}

.slider-case__pager {
  margin-left: -9px
}

.slider-case__pager>:not(:first-child) {
  display: none
}

.slider-case__pager .slick-slide {
  cursor: pointer;
  opacity: .6;
  transition: opacity .2s ease-in
}

.slider-case__pager .slick-slide.slick-current,
.slider-case__pager .slick-slide:hover {
  opacity: 1;
  transition: opacity .2s ease-in
}

.slider-case__pager .slider-case__pager__item {
  padding-left: 9px;
  padding-right: 9px
}

.slider-case__pager .slider-case__pager__item>* {
  max-width: 138px;
  overflow: hidden;
  position: relative
}

.slider-case__items-wrapper {
  background-color: #eef3f6;
  padding: 60px 0 70px
}

.slider-case__items>:not(:first-child) {
  display: none
}

.slider-case__item {
  align-items: stretch;
  display: flex
}

.slider-case__item:after {
  content: "";
  font-size: 0;
  min-height: inherit
}

.slider-case__item__photo-wrapper {
  align-items: stretch;
  display: flex
}

.slider-case__item__photo-wrapper:after {
  content: "";
  font-size: 0;
  min-height: inherit
}

.slider-case__item__photo {
  border-radius: 14px !important;
  height: 410px !important;
  margin-right: 28px;
  min-width: 550px !important
}

.slider-case__item__before-after {
  display: flex;
  flex-direction: column;
  margin-right: 80px;
  max-width: 175px
}

.slider-case__item__before-after:after {
  content: "";
  font-size: 0;
  min-height: inherit
}

.slider-case__item__before-after .progressive-image-wrapper {
  margin-bottom: 23px;
  width: 100% !important
}

.slider-case__item__before-after .progressive-image-wrapper:before {
  background-color: #ff3b3b;
  border-radius: 50%;
  bottom: -22px;
  content: "";
  height: 44px;
  left: -22px;
  position: absolute;
  width: 44px;
  z-index: 1
}

.slider-case__item__before-after .progressive-image-wrapper:after {
  background-image: url(./img/icon/denide-circle-white.svg);
  bottom: 4px;
  content: "";
  height: 12px;
  left: 4px;
  position: absolute;
  width: 12px;
  z-index: 2
}

.slider-case__item__before-after .after .progressive-image-wrapper:before {
  background-color: #20bc95
}

.slider-case__item__before-after .after .progressive-image-wrapper:after {
  background-image: url(./img/icon/denide-circle-white.svg)
}

.slider-case__item__before-after>.link,
.slider-case__item__before-after>.slide-tab__label,
.slider-case__item__before-after>[data-js=tooltip],
.tabs__labels.tabs__labels_buttons .slider-case__item__before-after>.tab__label {
  line-height: 24px;
  margin-bottom: 22px;
  margin-top: auto
}

.slider-case__item__info {
  display: flex;
  flex-direction: column
}

.slider-case__item__info:after {
  content: "";
  font-size: 0;
  min-height: inherit
}

.slider-case__item__info .title {
  color: #333638;
  font-size: 26px;
  font-weight: 500;
  margin-top: 10px
}

.slider-case__item__info .text,
.slider-case__item__info .title {

  font-style: normal;
  margin-bottom: 20px
}

.slider-case__item__info .text {
  color: #5f6877;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px
}

.slider-case__item__info .text>a {
  color: #5f6877
}

.slider-case__item__info .text>a>span {
  border-bottom-color: rgba(95, 104, 119, .5)
}

.slider-case__item__info .text>a:hover {
  color: #007973
}

.slider-case__item__info .employee {

  font-style: normal;
  font-weight: 400;
  margin-bottom: 18px;
  margin-top: auto
}

.slider-case__item__info .employee .progressive-image-wrapper {
  border-radius: 50% !important;
  height: 80px !important;
  margin-right: 15px;
  min-width: 80px !important
}

.slider-case__item__info .employee .name {
  border-bottom: 1px solid;
  color: #00beb4;
  font-size: 14px;
  line-height: 20px;
  transition: all .2s ease-out
}

.slider-case__item__info .employee .info {
  color: #c1c2c4;
  display: block;
  font-size: 13px;
  line-height: 16px
}

.slider-case__item__info .employee:hover .name {
  border-bottom: 1px solid transparent !important;
  color: #007973;
  transition: all .2s ease-out
}

@media screen and (max-width:1439px) {
  .slider-case__pager .slider-case__pager__item>* {
    width: 100% !important
  }

  .slider-case__item__before-after {
    margin-right: 28px
  }
}

@media screen and (max-width:1239px) {
  .slider-case__items-wrapper {
    padding-bottom: 50px;
    padding-top: 45px
  }

  .slider-case__item__photo-wrapper {
    flex-direction: column;
    margin-right: 37px;
    min-width: 430px
  }

  .slider-case__item__photo {
    height: auto !important;
    margin-bottom: 25px;
    margin-right: 0;
    min-width: 100% !important;
    width: 100% !important
  }

  .slider-case__item__before-after {
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    max-width: inherit !important;
    max-width: none !important;
    width: inherit !important;
    width: auto !important
  }

  .slider-case__item__before-after .slider-case__item__b-a__img {
    margin-bottom: 25px;
    margin-left: 15px;
    margin-right: 15px;
    width: calc(50% - 30px) !important
  }

  .slider-case__item__before-after .progressive-image-wrapper {
    margin-bottom: 0
  }

  .slider-case__item__before-after>.link,
  .slider-case__item__before-after>.slide-tab__label,
  .slider-case__item__before-after>[data-js=tooltip],
  .tabs__labels.tabs__labels_buttons .slider-case__item__before-after>.tab__label {
    margin-bottom: 0;
    margin-left: 15px;
    margin-right: 15px;
    width: 100%
  }

  .slider-case__item__info {
    flex-grow: 1;
    margin-left: 37px
  }

  .slider-case__item__info .employee {
    margin-bottom: 0;
    margin-top: 0
  }
}

@media screen and (max-width:1023px) {
  .slider-case__title {
    margin-bottom: 30px
  }

  .slider-case__item__photo-wrapper {
    margin-right: 15px;
    min-width: 350px
  }

  .slider-case__item__info {
    margin-left: 15px
  }
}

@media screen and (max-width:769px) {
  .slider-case__pager-wrapper {
    margin-bottom: 5px
  }

  .slider-case__pager .slider-case__pager__item>* {
    max-width: 103px
  }
}

@media screen and (max-width:767px) {
  .slider-case__title {
    margin-bottom: 20px
  }

  .slider-case__pager {
    margin-right: -15px
  }

  .slider-case__items-wrapper {
    padding-bottom: 35px;
    padding-top: 25px
  }

  .slider-case__item {
    flex-direction: column
  }

  .slider-case__item__photo-wrapper {
    align-self: center;
    margin-right: 0;
    max-width: 450px;
    min-width: auto
  }

  .slider-case__item__photo {
    margin-bottom: 20px
  }

  .slider-case__item__before-after {
    margin-left: -7px;
    margin-right: -7px
  }

  .slider-case__item__before-after .slider-case__item__b-a__img {
    margin-bottom: 20px;
    margin-left: 7px;
    margin-right: 7px;
    width: calc(50% - 14px) !important
  }

  .slider-case__item__before-after>.link,
  .slider-case__item__before-after>.slide-tab__label,
  .slider-case__item__before-after>[data-js=tooltip],
  .tabs__labels.tabs__labels_buttons .slider-case__item__before-after>.tab__label {
    margin-left: 7px;
    margin-right: 7px;
    text-align: center
  }

  .slider-case__item__info {
    margin-left: 0
  }

  .slider-case__item__info .title {
    font-size: 20px;
    margin-bottom: 15px;
    margin-top: 20px
  }

  .slider-case__item__info .text {
    font-size: 14px;
    margin-bottom: 15px
  }
}

@media screen and (max-width:480px) {

  .slider-case__item__before-after>.link,
  .slider-case__item__before-after>.slide-tab__label,
  .slider-case__item__before-after>[data-js=tooltip],
  .tabs__labels.tabs__labels_buttons .slider-case__item__before-after>.tab__label {
    text-align: left
  }
}

.portfolio__slider-wrapper {
  margin-top: 30px
}

.portfolio__slider__thumbnails {
  height: 80px;
  overflow: hidden
}

.portfolio__slider__thumbnails.slick-initialized {
  height: auto;
  overflow: visible
}

.portfolio__slider__thumbnails .slick-track {
  margin-left: 0
}

.portfolio__slider__thumbnails .slick-slide {
  cursor: pointer;
  margin-right: 22px;
  opacity: .7;
  transition: opacity .1s ease
}

.portfolio__slider__thumbnails .slick-slide.slick-current {
  cursor: default;
  opacity: 1
}

.portfolio__slider__thumbnails .progressive-image-wrapper {
  max-width: 100%
}

.portfolio__thumb .progressive-image-wrapper {
  display: block
}

.portfolio__slider {
  height: 262px;
  margin-top: 30px;
  overflow: hidden
}

.portfolio__slider.slick-initialized {
  height: auto;
  overflow: visible
}

.portfolio__slider .slick-list {
  margin-left: -10px;
  margin-right: -10px
}

.portfolio__slider .slick-slide {
  margin-left: 10px;
  margin-right: 10px
}

.portfolio__item__grid {
  display: flex;
  margin-left: -11px;
  margin-right: -11px
}

.portfolio__item__grid>* {
  padding-left: 11px;
  padding-right: 11px
}

.portfolio__item__images {
  display: flex;
  flex: 0 1 calc(100% - 395px);
  margin-left: -10px;
  margin-right: -10px;
  max-width: calc(100% - 395px)
}

.portfolio__item__images>* {
  flex: 0 1 50%;
  max-width: 50%;
  padding-left: 10px;
  padding-right: 10px
}

.portfolio__item__image {
  border-radius: 10px;
  overflow: hidden;
  position: relative
}

.portfolio__item__image:before {
  background-color: #ff3b3b;
  border-radius: 50%;
  bottom: -58px;
  content: "";
  height: 116px;
  left: -58px;
  position: absolute;
  width: 116px;
  z-index: 1
}

.portfolio__item__image:after {
  background-image: url(./img/icon/denide-circle-white.svg);
  bottom: 10px;
  content: "";
  height: 30px;
  left: 10px;
  position: absolute;
  width: 30px;
  z-index: 2
}

.portfolio__item__image .progressive-image-wrapper {
  display: block;
  max-width: 100%
}

.portfolio__item__image.portfolio__item__image_after:before {
  background-color: #20bc95
}

.portfolio__item__image.portfolio__item__image_after:after {
  background-image: url(./img/icon/denide-circle-white.svg)
}

.portfolio__item__description {
  flex: 0 1 405px;
  max-width: 405px;
  padding-left: 45px
}

.portfolio__item__text {
  color: #989ca6;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 15px;
  padding-left: 30px;
  position: relative
}

.portfolio__item__text:last-child {
  margin-bottom: 0
}

.portfolio__item__text:before {
  background-image: url(/build/i/818652c1992e8151345c.svg);
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 16px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 16px
}

.portfolio__item__text.portfolio__item__text_after:before {
  background-image: url(/build/i/d7fa79439ce00e241bdf.svg)
}

.portfolio__item__text>div:first-child {
  color: #5f6877;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 3px;
  text-transform: uppercase
}

@media screen and (max-width:1199px) {
  .portfolio .h2_left {
    font-size: 42px;
    line-height: 52px;
    text-align: center
  }

  .portfolio__slider-wrapper {
    padding: 0 34px
  }

  .portfolio__item__grid {
    flex-wrap: wrap
  }

  .portfolio__item__images {
    flex-basis: calc(100% + 50px);
    margin-left: -25px;
    margin-right: -25px;
    max-width: calc(100% + 50px)
  }

  .portfolio__item__images>* {
    padding-left: 25px;
    padding-right: 25px
  }

  .portfolio__item__description {
    display: flex;
    flex-basis: calc(100% + 50px);
    margin-left: -25px;
    margin-right: -25px;
    margin-top: 42px;
    max-width: calc(100% + 50px);
    padding-left: 11px;
    padding-right: 11px
  }

  .portfolio__item__description>* {
    flex-basis: calc(50% - 50px);
    margin-left: 25px;
    margin-right: 25px;
    max-width: calc(50% - 50px)
  }
}

@media screen and (max-width:900px) {
  .portfolio__slider-wrapper {
    padding: 0
  }

  .portfolio__slider {
    margin-top: 35px
  }

  .portfolio__item__grid {
    margin-left: 0;
    margin-right: 0
  }

  .portfolio__item__grid>* {
    padding: 0
  }

  .portfolio__item__images {
    flex-basis: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
    max-width: calc(100% + 30px)
  }

  .portfolio__item__images>* {
    padding-left: 15px;
    padding-right: 15px
  }

  .portfolio__item__description {
    flex-basis: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 35px;
    max-width: calc(100% + 30px);
    padding-left: 0;
    padding-right: 0
  }

  .portfolio__item__description>* {
    flex-basis: calc(50% - 30px);
    margin-left: 15px;
    margin-right: 15px;
    max-width: calc(50% - 30px)
  }
}

@media screen and (max-width:767px) {

  .payment-page .portfolio h2,
  .portfolio .h2,
  .portfolio .payment-page h2 {
    font-size: 32px;
    line-height: normal
  }

  .portfolio__slider-wrapper {
    margin-top: 22px
  }

  .portfolio__slider__thumbnails .slick-list {
    margin-right: -15px
  }

  .portfolio__slider__thumbnails .slick-slide {
    margin-right: 10px
  }

  .portfolio__slider {
    margin-top: 20px
  }

  .portfolio__item__images {
    flex-basis: calc(100% + 14px);
    margin-left: -7px;
    margin-right: -7px;
    max-width: calc(100% + 14px)
  }

  .portfolio__item__images>* {
    padding-left: 7px;
    padding-right: 7px
  }

  .portfolio__item__image:before {
    bottom: -22px;
    height: 44px;
    left: -22px;
    width: 44px
  }

  .portfolio__item__image:after {
    bottom: 4px;
    height: 12px;
    left: 4px;
    width: 12px
  }

  .portfolio__item__description {
    flex-wrap: wrap;
    margin-top: 30px
  }

  .portfolio__item__description,
  .portfolio__item__description>* {
    flex-basis: 100%;
    margin-left: 0;
    margin-right: 0;
    max-width: 100%
  }

  .portfolio__item__text {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;
    padding-left: 0
  }

  .portfolio__item__text:before {
    content: none
  }

  .portfolio__item__text>div:first-child {
    color: #ff3b3b;
    margin-bottom: 6px
  }

  .portfolio__item__text.portfolio__item__text_after>div:first-child {
    color: #20bc95
  }
}

@media screen and (max-width:440px) {
  .portfolio__slider__thumbnails .slick-slide {
    width: 80px
  }
}

.block-in-frame {
  background-color: #ebf0f1;
  border-radius: 10px;
  overflow: hidden;
  padding: 65px 80px 0;
  position: relative
}

.block-in-frame .h2,
.block-in-frame .payment-page h2,
.payment-page .block-in-frame h2 {
  color: #333638;

  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 25px;
  max-width: 800px;
  text-align: left
}

.block-in-frame:before {
  border: 10px solid #f4a500;
  border-radius: 10px;
  content: "";
  height: calc(100% - 28px);
  left: 14px;
  opacity: .3;
  pointer-events: none;
  position: absolute;
  top: 14px;
  width: calc(100% - 28px);
  z-index: 3
}

.block-in-frame__grid {
  margin-left: -15px;
  margin-right: -15px
}

.block-in-frame__grid>* {
  padding-left: 15px;
  padding-right: 15px
}

.block-in-frame__item {
  height: 100%;
  padding-bottom: 200px;
  position: relative
}

.block-in-frame__text {
  color: #5f6877;

  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 26px;
  padding-left: 30px;
  position: relative
}

.block-in-frame__text:before {
  background-image: url(/build/i/772174daa1502e278b46.svg);
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px
}

.block-in-frame__author {
  color: #5f6877;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-top: 38px;
  padding-left: 30px;
  position: relative;
  z-index: 1
}

.block-in-frame__image {
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 2
}

.block-in-frame__image .progressive-image-wrapper {
  display: block;
  margin-left: auto;
  margin-right: 40px
}

@media screen and (max-width:1359px) {
  .block-in-frame-wrapper+.pusher {
    margin-top: 70px !important
  }

  .block-in-frame__item {
    padding-bottom: 130px
  }

  .block-in-frame__image {
    z-index: 0
  }

  .block-in-frame__image .progressive-image-wrapper {
    margin-right: 0;
    max-width: 150px
  }
}

@media screen and (max-width:1023px) {

  .block-in-frame-wrapper .h2,
  .block-in-frame-wrapper .payment-page h2,
  .payment-page .block-in-frame-wrapper h2 {
    font-size: 24px;
    line-height: 30px
  }

  .block-in-frame {
    padding-left: 50px;
    padding-right: 50px
  }

  .block-in-frame__item {
    padding-bottom: 180px
  }

  .block-in-frame__author {
    font-size: 14px;
    line-height: 22px;
    margin-top: 20px
  }

  .block-in-frame__text {
    font-size: 15px;
    line-height: 24px
  }
}

@media screen and (max-width:767px) {
  .block-in-frame {
    padding: 35px 28px 0
  }

  .block-in-frame .h2,
  .block-in-frame .payment-page h2,
  .payment-page .block-in-frame h2 {
    font-size: 17px !important;
    line-height: 24px
  }

  .block-in-frame:before {
    border: 5px solid #f4a500;
    height: calc(100% - 16px);
    left: 8px;
    opacity: .3;
    top: 8px;
    width: calc(100% - 16px)
  }

  .block-in-frame__grid>* {
    display: none
  }

  .block-in-frame__grid>:first-child {
    display: block
  }

  .block-in-frame__item {
    padding-bottom: 0
  }

  .block-in-frame__text {
    font-size: 14px;
    line-height: 22px
  }

  .block-in-frame__author {
    line-height: 20px;
    text-align: right
  }

  .block-in-frame__image {
    margin-top: 16px;
    position: relative
  }

  .block-in-frame__image .progressive-image-wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 120px
  }
}

.grey-block {
  background-color: #f4f7f8;
  overflow: hidden
}

@media screen and (max-width:1199px) {
  .grey-block .pusher {
    margin-top: 50px !important
  }
}

@media screen and (max-height:767px) {
  .grey-block .pusher {
    margin-top: 40px !important
  }
}

.ba-mini__grid {
  margin-left: -12px;
  margin-right: -12px
}

.ba-mini__grid>* {
  max-width: 33.33%;
  padding-left: 12px;
  padding-right: 12px
}

.ba-mini__item {
  align-items: flex-start;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  position: relative
}

.ba-mini__item:before {
  background-color: #fff;
  content: "";
  height: 100%;
  left: 50%;
  margin-left: -1px;
  position: absolute;
  top: 0;
  width: 2px;
  z-index: 3
}

.ba-mini__item>* {
  flex-basis: 50%;
  overflow: hidden;
  position: relative
}

.ba-mini__item>*>* {
  display: block
}

.ba-mini__item>*>:before {
  background-color: #e59700;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: all .1s ease-out;
  width: 100%;
  z-index: 1
}

.ba-mini__item>*>:hover:before {
  opacity: .1;
  transition: all .1s ease-in
}

.ba-mini__item>*>:after {
  content: "";
  display: block;
  height: 0;
  padding-top: 67.35%
}

.ba-mini__item .progressive-image-wrapper {
  border-radius: 0;
  display: block;
  height: 100% !important;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: auto !important
}

.ba-mini__item .progressive-image-wrapper img {
  height: 100%;
  width: auto
}

.ba-mini__item .after:before,
.ba-mini__item .before:before {
  background-color: #ff3b3b;
  border-radius: 50%;
  bottom: -40px;
  content: "";
  height: 80px;
  left: -40px;
  pointer-events: none;
  position: absolute;
  width: 80px;
  z-index: 2
}

.ba-mini__item .after:after,
.ba-mini__item .before:after {
  background-image: url(./img/icon/denide-circle-white.svg);
  bottom: 7px;
  content: "";
  height: 21px;
  left: 5px;
  pointer-events: none;
  position: absolute;
  width: 21px;
  z-index: 3
}

.ba-mini__item .after:before {
  background-color: #20bc95
}

.ba-mini__item .after:after {
  background-image: url(./img/icon/denide-circle-white.svg)
}

.ba-mini__more {
  display: none
}

@media screen and (max-width:1359px) {

  .ba-mini__item .after:before,
  .ba-mini__item .before:before {
    bottom: -29px;
    height: 58px;
    left: -29px;
    width: 58px
  }

  .ba-mini__item .after:after,
  .ba-mini__item .before:after {
    bottom: 5px;
    height: 16px;
    left: 4px;
    width: 16px
  }

  .before-after-mini+.divider {
    background-color: transparent;
    margin-bottom: 0 !important;
    margin-top: 65px !important
  }
}

@media screen and (max-width:1023px) {
  .ba-mini__grid {
    margin-left: -5px;
    margin-right: -5px
  }

  .ba-mini__grid>* {
    padding-left: 5px;
    padding-right: 5px
  }

  .ba-mini__item {
    border-radius: 5px;
    margin-left: 0;
    margin-right: 0;
    position: relative
  }

  .ba-mini__item>* {
    flex-basis: 50%;
    margin-left: 0;
    margin-right: 0
  }
}

@media screen and (max-width:767px) {
  .before-after-mini+.divider {
    background-color: #d9e1e1;
    margin-bottom: 35px !important;
    margin-top: 35px !important
  }

  .ba-mini__grid {
    justify-content: center
  }

  .ba-mini__grid>* {
    flex-basis: 100%;
    margin-bottom: 14px;
    max-width: 390px
  }

  .ba-mini__grid>:nth-child(3) {
    display: none
  }

  .ba-mini__more {
    display: block;
    font-size: 12px;
    letter-spacing: normal;
    margin-top: 3px;
    text-align: center
  }
}

.types-grid__text {
  font-size: 16px;
  line-height: 24px;
  margin-top: 25px
}

.types-grid__grid {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 10px
}

.types-grid__grid>* {
  margin-top: 40px;
  max-width: 25%;
  padding-left: 15px;
  padding-right: 15px
}

.types-grid__item {
  padding-right: 18px
}

.types-grid__item__image {
  border-radius: 10px;
  margin-top: 20px;
  overflow: hidden;
  position: relative
}

.types-grid__item__image:after {
  content: "";
  display: block;
  height: 0;
  padding-top: 56.3%
}

.types-grid__item__image .progressive-image-wrapper {
  border-radius: 0;
  display: block;
  left: 0;
  max-width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%)
}

.types-grid__item__image_top .progressive-image-wrapper {
  top: 0;
  transform: none
}

.types-grid__item__price {
  background-color: #fdb700;
  bottom: 17px;
  color: #fff;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  left: 0;
  padding: 3px 10px 3px 8px;
  position: absolute;
  z-index: 2
}

.types-grid__item__title {
  padding-left: 23px;
  position: relative
}

.types-grid__item__title:before {
  // background-image: url(/build/i/d7fa79439ce00e241bdf.svg);
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAxNiI+PGxpbmVhckdyYWRpZW50IGlkPSJhIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjQuODUiIHkxPSI4IiB4Mj0iMTEuMTQ5IiB5Mj0iOCI+PHN0b3Agb2Zmc2V0PSIwIiBzdG9wLWNvbG9yPSIjZDQ2ZjAwIi8+PHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjZmZiYTAwIi8+PC9saW5lYXJHcmFkaWVudD48cGF0aCBkPSJNOCAxNmMtMS43IDAtMy4xLTEuNC0zLjEtMy4xVjMuMUM0LjggMS40IDYuMyAwIDggMHMzLjEgMS40IDMuMSAzLjF2OS43YzAgMS44LTEuNCAzLjItMy4xIDMuMnoiIGZpbGw9InVybCgjYSkiLz48bGluZWFyR3JhZGllbnQgaWQ9ImIiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4MT0iLS4wMDEiIHkxPSI4IiB4Mj0iMTYuMDAxIiB5Mj0iOCI+PHN0b3Agb2Zmc2V0PSIwIiBzdG9wLWNvbG9yPSIjZDQ2ZjAwIi8+PHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjZmZiYTAwIi8+PC9saW5lYXJHcmFkaWVudD48cGF0aCBkPSJNMTIuOSAxMS4xSDMuMUMxLjQgMTEuMSAwIDkuNyAwIDhzMS40LTMuMSAzLjEtMy4xaDkuN0MxNC42IDQuOSAxNiA2LjMgMTYgOHMtMS40IDMuMS0zLjEgMy4xeiIgZmlsbD0idXJsKCNiKSIvPjwvc3ZnPg==);
  background-position: 0;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 16px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 16px
}

.types-grid__item__text {
  color: #989ca6;
  font-size: 15px;
  line-height: 22px;
  margin-top: 15px
}

@media screen and (max-width:1359px) {

  .payment-page .types-grid h2,
  .types-grid .h2,
  .types-grid .payment-page h2 {
    font-size: 36px
  }

  .types-grid+.divider {
    margin-bottom: 75px !important;
    margin-top: 75px !important
  }

  .types-grid__grid {
    margin-left: -12px;
    margin-right: -12px;
    margin-top: 0
  }

  .types-grid__grid>* {
    margin-top: 35px;
    padding-left: 12px;
    padding-right: 12px
  }

  .types-grid__item {
    padding-right: 0
  }

  .types-grid__item__title {
    display: block;
    font-size: 14px;
    margin-right: -2px;
    padding-left: 20px
  }

  .types-grid__item__title:before {
    left: -2px;
    top: 0
  }

  .types-grid__item__text {
    font-size: 14px;
    line-height: 20px;
    min-height: 60px
  }
}

@media screen and (max-width:1023px) {
  .types-grid+.divider {
    margin-bottom: 55px !important;
    margin-top: 55px !important
  }

  .types-grid__grid>* {
    flex-basis: 50%;
    max-width: 50%
  }

  .types-grid__item__text {
    min-height: 40px
  }

  .types-grid__item__image {
    max-width: 300px
  }

  .types-grid__text {
    margin-top: 15px
  }
}

@media screen and (max-width:767px) {

  .payment-page .types-grid h2,
  .types-grid .h2,
  .types-grid .payment-page h2 {
    font-size: 26px !important;
    line-height: normal;
    text-align: center
  }

  .types-grid+.divider {
    background-color: transparent;
    margin-bottom: 0 !important;
    margin-top: 35px !important
  }

  .types-grid__text {
    font-size: 14px;
    line-height: 22px
  }

  .types-grid__grid>* {
    flex-basis: 100%;
    margin-top: 30px;
    max-width: 100%
  }
}

.modal__allon4 {
  max-width: 988px;
  text-align: left;
  width: 100%
}

.modal__allon4 .mfp-close {
  right: 0;
  top: 0
}

.modal__allon4 .mfp-close:before {
  background-image: url(/build/i/d8a6d26ddbecc46e0b1e.svg);
  background-position: 50%;
  background-size: contain;
  height: 16px;
  width: 16px
}

.modal__allon4-mv {
  align-items: flex-start;
  display: flex;
  flex-wrap: nowrap
}

.modal__allon4-mv .modal__title {
  margin-bottom: 11px
}

.allon4-mv-image {
  flex: none
}

.allon4-mv-text {
  padding-left: 55px;
  text-align: left
}

.allon4-mv-text .minus-list,
.allon4-mv-text .plus-list {
  margin-bottom: 18px
}

.allon4-mv-text__info {
  color: #898989;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 20px
}

.allon4-mv-text__link {
  display: inline-block;
  margin-top: 17px
}

.allon4-mv-text__link,
.modal__anchors>* {

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase
}

.modal__anchors>* {
  margin-right: 30px
}

.modal__anchors>:last-child {
  margin-right: 0
}

.modal__allon4__header {
  color: #3c4143;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 22px;
  text-align: center
}

.modal__review {
  align-items: flex-start;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 7px 21px 3px rgba(70, 81, 86, .1);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 28px;
  overflow: hidden
}

.modal__review:last-child {
  margin-bottom: 0
}

.modal__review.active .modal__review__image {
  border-bottom-right-radius: 10px
}

.modal__review>* {
  flex: 0 1 auto
}

.modal__review>:first-child {
  flex-basis: 36%;
  max-width: 36%
}

.modal__review>:nth-child(2) {
  flex-basis: calc(64% - 40px);
  max-width: calc(64% - 40px);
  padding-bottom: 30px
}

.modal__review__image {
  overflow: hidden;
  transition: border .3s
}

.modal__review__image .progressive-image-wrapper {
  border-radius: 0;
  display: block;
  max-width: 100%
}

.modal__review__title {
  color: #111f2c;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-top: 30px
}

.modal__review__text {
  color: #7e7e86;
  font-size: 14px;
  line-height: 22px;
  margin-top: 10px;
  padding-right: 40px
}

.modal__review__text .minus-list,
.modal__review__text .plus-list {
  margin-top: 10px
}

.modal__review__text .minus-list>li,
.modal__review__text .plus-list>li {
  line-height: 22px
}

.modal__review__text .minus-list>li:before,
.modal__review__text .plus-list>li:before {
  top: 3px
}

.modal__allon4__text {
  margin-top: 30px
}

.modal__allon4__text .minus-list,
.modal__allon4__text .plus-list {
  color: #898989;
  font-size: 14px;
  line-height: 22px
}

.modal__reviews__anchors {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 25px
}

@media screen and (max-width:1023px) {
  .modal__review__image {
    border-bottom-right-radius: 10px
  }
}

@media screen and (max-width:768px) {
  .modal__allon4-mv {
    flex-wrap: wrap
  }

  .allon4-mv-image {
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto
  }

  .allon4-mv-text {
    padding-left: 0
  }
}

@media screen and (max-width:767px) {
  .allon4-mv-image {
    margin-bottom: 20px;
    width: 100% !important
  }

  .allon4-mv-text__stats {
    flex-wrap: wrap;
    justify-content: flex-start
  }

  .allon4-mv-text__stats>* {
    flex-basis: 100%
  }

  .allon4-mv-text__link {
    display: flex;
    justify-content: center;
    margin-top: 35px
  }

  .modal__anchors {
    display: flex;
    justify-content: space-between
  }

  .modal__anchors>* {
    font-size: 12px;
    margin-right: 0
  }

  .modal__anchors>* .hide-mobile {
    display: none
  }

  .modal__review {
    margin-left: auto;
    margin-right: auto;
    max-width: 312px
  }

  .modal__review>:first-child,
  .modal__review>:nth-child(2) {
    flex-basis: 100%;
    max-width: 100%
  }

  .modal__review>:nth-child(2) {
    padding-left: 15px;
    padding-right: 15px
  }

  .modal__review__title {
    margin-top: 15px
  }

  .modal__review__text {
    margin-top: 6px;
    padding-right: 0
  }

  .modal__review__image {
    border-bottom-right-radius: 0
  }
}

.appointment__inner {
  background-color: #ecdfc9;
  border-radius: 10px;
  box-shadow: 0 10px 29px rgba(70, 81, 86, .18);
  min-height: 200px;
  overflow: hidden;
  padding-left: 55px;
  position: relative
}

.appointment__inner:before {
  background-image: url(/build/i/590a4b189f599c8fcc64.png);
  background-position: top -120px left -50px;
  background-repeat: no-repeat;
  content: "";
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0
}

.appointment__inner>* {
  position: relative;
  z-index: 1
}

.appointment__grid {
  justify-content: space-between
}

.appointment__grid>* {
  flex: 0 1 100%
}

.appointment__grid>:first-child {
  flex-basis: 650px;
  min-width: 650px;
  padding-bottom: 130px;
  padding-top: 50px
}

.appointment__grid>:nth-child(2) {
  flex-basis: calc(100% - 680px);
  position: relative
}

.appointment__image-wrapper {
  border-radius: 50%;
  height: 850px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 850px
}

.appointment__image__inner {
  height: 400px;
  position: relative;
  width: 500px
}

.appointment__image {
  height: 100%;
  top: 50%;
  transform: translateY(-50%);
  width: 100%
}

.appointment__title {
  color: #333638;

  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  margin-bottom: 30px
}

.appointment__form {
  background-color: #eab65f;
  border-radius: 10px;
  box-shadow: 0 10px 29px rgba(70, 81, 86, .18);
  margin-left: 55px;
  margin-top: -100px;
  max-width: 620px;
  padding: 22px 44px 33px 34px;
  position: relative;
  z-index: 2
}

.appointment__form:before {
  background-image: url('../img/demo/icon-check-white.svg'), linear-gradient(90deg, #d46f00, #ffba00);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 26px auto, auto auto;
  box-shadow: 0 7px 43px rgba(0, 0, 0, .28);
  z-index: 1
}

.appointment__form:after,
.appointment__form:before {
  border-radius: 50%;
  content: "";
  height: 61px;
  left: -9px;
  position: absolute;
  top: -11px;
  width: 61px
}

.appointment__form:after {
  animation: pulse 3s infinite;
  background-image: linear-gradient(90deg, #d46f00, #ffba00);
  opacity: 1;
  transform: scale(1.2);
  transform-origin: center;
  z-index: 0
}

.appointment__form .input {
  font-size: 15px;
  letter-spacing: .45px;
  padding-left: 21px
}

.appointment__form .input+label,
.appointment__form label {
  color: #c2c3c5;
  font-size: 15px;
  left: 12px;
  letter-spacing: .45px;
  padding-left: 21px;
  top: 16px
}

.appointment__form .form__error {
  bottom: 0;
  left: 12px;
  padding-left: 21px;
  pointer-events: none
}

.appointment__form .input__wrapper {
  margin-top: 15px;
  padding-bottom: 0;
  padding-top: 0
}

.appointment__form .input__wrapper.focused label {
  font-size: 15px;
  opacity: 0;
  top: 16px
}

.appointment__form .form__policy {
  color: #fff;
  font-size: 10px;
  line-height: 13px;
  margin-top: 10px
}

@keyframes pulse {
  0% {
    opacity: 1;
    transform: scale(1)
  }

  70% {
    opacity: 0;
    transform: scale(1.3)
  }

  to {
    opacity: 0;
    transform: scale(1.3)
  }
}

.appointment__form__grid {
  margin-left: -12px;
  margin-right: -12px
}

.appointment__form__grid>* {
  flex: 0 1 50%;
  padding-left: 12px;
  padding-right: 12px
}

.appointment__form__submit {
  font-size: 12px;
  max-width: 300px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%
}

.appointment__form__title {
  color: #fff;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  padding-left: 35px
}

.appointment__form__title>a {
  color: #fff;
  font-size: 20px;
  margin-left: 20px
}

.appointment__bottom {
  font-size: 14px;
  margin-left: 85px;
  margin-top: 30px
}

.appointment__bottom>* {
  flex: 0 1 auto
}

.appointment__bottom>:first-child {
  margin-right: 90px
}

.appointment_modal {
  margin-top: 38px;
  text-align: left
}

.appointment_modal .appointment__inner {
  padding-left: 45px
}

.appointment_modal .appointment__title {
  align-items: flex-end;
  display: flex;
  font-size: 26px;
  line-height: normal;
  margin-bottom: 8px;
  max-height: 50px
}

.appointment_modal .appointment__grid {
  justify-content: space-between
}

.appointment_modal .appointment__grid>:first-child {
  flex-basis: 505px;
  min-height: 398px;
  min-width: 505px;
  padding-top: 40px
}

.appointment_modal .appointment__grid>:nth-child(2) {
  flex-basis: calc(100% - 555px);
  max-width: 265px
}

.appointment_modal .appointment__image__inner {
  height: calc(100% - 450px);
  width: 265px
}

.appointment_modal .appointment__image {
  background-position: center right -35px
}

.appointment_modal .appointment__form {
  margin-left: 45px;
  margin-top: -140px
}

@media screen and (max-width:1199px) {
  .appointment__grid>:first-child {
    flex-basis: 550px;
    min-width: 550px
  }

  .appointment__grid>:nth-child(2) {
    flex-basis: calc(100% - 550px)
  }

  .appointment__inner {
    padding-left: 45px
  }

  .appointment__form {
    margin-left: 45px
  }
}

@media screen and (max-width:1023px) {
  .appointment__grid>:first-child {
    flex-basis: 500px;
    min-width: 500px
  }

  .appointment__grid>:nth-child(2) {
    flex-basis: calc(100% - 500px)
  }

  .appointment__title {
    font-size: 26px;
    line-height: normal;
    margin-bottom: 25px
  }

  .appointment__content .minus-list>li,
  .appointment__content .plus-list>li {
    font-size: 14px
  }

  .appointment__bottom>:first-child {
    margin-right: 40px
  }

  .appointment__image {
    background-position: center right -40px
  }

  .appointment_modal .appointment__image__inner {
    width: 200px
  }

  .appointment_modal .appointment__form {
    max-width: 560px
  }
}

@media screen and (max-width:900px) {
  .appointment_modal .appointment__grid>:nth-child(2) {
    display: none
  }
}

@media screen and (max-width:767px) {
  .appointment__inner {
    padding: 24px 22px 75px
  }

  .appointment__inner:before {
    content: none
  }

  .appointment__grid>:first-child {
    flex-basis: 100%;
    min-width: auto;
    padding: 0
  }

  .appointment__grid>:nth-child(2) {
    display: none
  }

  .appointment__title {
    margin-bottom: 18px
  }

  .appointment__content .minus-list>li,
  .appointment__content .plus-list>li {
    font-size: 13px;
    line-height: 18px;
    padding-left: 30px
  }

  .appointment__form {
    margin-left: 0;
    margin-top: -15px;
    padding-left: 22px;
    padding-right: 22px;
    padding-top: 50px
  }

  .appointment__form:after,
  .appointment__form:before {
    left: 50%;
    margin-left: -30px;
    top: -31px
  }

  .appointment__form__title {
    font-size: 15px;
    padding-left: 0;
    text-align: center
  }

  .appointment__form__title a {
    display: block;
    margin-left: 0;
    margin-top: 5px
  }

  .appointment__form__grid {
    flex-wrap: wrap;
    justify-content: center
  }

  .appointment__form__grid>* {
    flex-basis: 100%;
    max-width: 300px
  }

  .appointment__bottom {
    justify-content: center;
    margin-left: 0;
    margin-top: 25px
  }

  .appointment__bottom>* {
    text-align: center
  }

  .appointment__bottom>:first-child {
    margin-bottom: 12px;
    margin-right: 0
  }

  .appointment_modal .appointment__inner {
    padding-left: 22px
  }

  .appointment_modal .appointment__grid>:first-child {
    flex-basis: 100%;
    min-height: auto;
    min-width: auto;
    padding: 0
  }

  .appointment_modal .appointment__form {
    margin-left: 0;
    margin-top: -15px
  }
}

.works-slider-wrapper {
  margin-top: 58px;
  padding-bottom: 40px;
  position: relative
}

.works-slider__inner {
  position: relative
}

.works-slider__navigation {
  height: 60px;
  left: 0;
  margin-top: -30px;
  pointer-events: none;
  position: absolute;
  top: 50%;
  width: 100%
}

.works-slider__navigation>* {
  pointer-events: auto
}

.works-slider {
  height: 400px;
  overflow: hidden;
  padding-left: 60px;
  padding-right: 60px
}

.works-slider.slick-initialized {
  height: auto;
  overflow: visible
}

.works-slider .slick-list {
  margin-left: -15px;
  margin-right: -15px
}

.works-slider .slick-slide {
  margin-left: 15px;
  margin-right: 15px
}

.works-slider__item {
  background-color: #eef3f6;
  background-position: top -10px center;
  border-radius: 10px;
  min-height: 400px;
  padding: 36px 35px 32px
}

.works-slider__item__images {
  display: flex;
  margin-left: -10px;
  margin-right: -10px
}

.works-slider__item__images>* {
  flex: 0 1 66.66%;
  max-width: 66.66%;
  padding-left: 10px;
  padding-right: 10px
}

.works-slider__item__images>:nth-child(2) {
  flex-basis: 33.33%;
  max-width: 33.33%
}

.works-slider__item__images .progressive-image-wrapper {
  display: block;
  max-width: 100%
}

.works-slider__item__image {
  border-radius: 10px;
  overflow: hidden;
  position: relative
}

.works-slider__item__results {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between
}

.works-slider__item__results>* {
  border-radius: 5px;
  overflow: hidden;
  position: relative
}

.works-slider__item__results>:before {
  background-color: #e59700;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: all .1s ease-out;
  width: 100%;
  z-index: 1
}

.works-slider__item__results>:hover:before {
  opacity: .1;
  transition: all .1s ease-in
}

.works-slider__item__results .progressive-image-wrapper {
  border-radius: 0
}

.works-slider__item__results .progressive-image-wrapper:before {
  background-color: #ff3b3b;
  border-radius: 50%;
  bottom: -22px;
  content: "";
  height: 44px;
  left: -22px;
  position: absolute;
  width: 44px;
  z-index: 1
}

.works-slider__item__results .progressive-image-wrapper:after {
  background-image: url(./img/icon/denide-circle-white.svg);
  bottom: 4px;
  content: "";
  height: 12px;
  left: 4px;
  position: absolute;
  width: 12px;
  z-index: 2
}

.works-slider__item__results .after .progressive-image-wrapper:before {
  background-color: #20bc95
}

.works-slider__item__results .after .progressive-image-wrapper:after {
  background-image: url(./img/icon/denide-circle-white.svg)
}

.works-slider__item__title {
  color: #5f6877;
  display: block;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: 52px;
  line-height: 26px;
  margin-top: 25px
}

.works-slider__item__info {
  align-items: center;
  display: flex;
  justify-content: space-between
}

.works-slider__item__info>* {
  margin-top: 14px
}

.works-slider__dots {
  bottom: 0;
  height: 10px;
  left: 0;
  position: absolute;
  width: 100%
}

@media screen and (max-width:1359px) {
  .works-slider-wrapper {
    margin-top: 42px
  }

  .works-slider {
    padding-left: 52px;
    padding-right: 52px
  }

  .works-slider .slick-list {
    margin-left: -12px;
    margin-right: -12px
  }

  .works-slider .slick-slide {
    margin-left: 12px;
    margin-right: 12px
  }
}

@media screen and (max-width:1199px) {
  .works-slider {
    height: 300px
  }

  .works-slider__item {
    min-height: 300px;
    padding: 26px 25px 25px
  }

  .works-slider__item .corner_play {
    height: 50px;
    width: 50px
  }

  .works-slider__item .corner_play>* span {
    background-position: 20px 12px;
    background-size: auto 18px;
    height: 50px;
    width: 50px
  }

  .works-slider__item__images {
    margin-left: -7px;
    margin-right: -7px
  }

  .works-slider__item__images>* {
    flex-basis: 65%;
    max-width: 65%;
    padding-left: 7px;
    padding-right: 7px
  }

  .works-slider__item__images>:nth-child(2) {
    flex-basis: 35%;
    max-width: 35%
  }

  .works-slider__item__title {
    font-size: 14px;
    height: 40px;
    line-height: 20px;
    margin-top: 22px
  }

  .works-slider__item__info>* {
    font-size: 13px;
    line-height: 18px
  }
}

@media screen and (max-width:1023px) {

  .payment-page .works-wrapper h2,
  .works-wrapper .h2,
  .works-wrapper .payment-page h2 {
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
    text-align: center
  }
}

@media screen and (max-width:800px) {
  .works-slider-wrapper {
    padding-bottom: 30px
  }

  .works-slider__item {
    padding: 30px 45px
  }

  .works-slider__item__images {
    margin-left: auto;
    margin-right: auto;
    max-width: 480px
  }

  .works-slider__item__title {
    height: auto
  }
}

@media screen and (max-width:767px) {
  .works-slider-wrapper {
    padding-bottom: 56px
  }

  .works-slider__navigation {
    background-color: #01d8cd;
    border-radius: 50%;
    bottom: -25px;
    height: 49px;
    left: 50%;
    margin-left: -50px;
    top: auto;
    width: 100px
  }
}

@media screen and (max-width:567px) {
  .works-slider {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 0;
    padding-right: 0
  }

  .works-slider__item {
    border-radius: 0;
    padding: 23px 30px 46px
  }

  .works-slider__item__images {
    flex-wrap: wrap;
    max-width: 298px
  }

  .works-slider__item__images>*,
  .works-slider__item__images>:nth-child(2) {
    flex-basis: 100%;
    max-width: 100%
  }

  .works-slider__item__results {
    flex-direction: row;
    margin-left: -7px;
    margin-right: -7px;
    margin-top: 20px
  }

  .works-slider__item__results>* {
    flex: 0 1 50%;
    max-width: 50%;
    padding-left: 7px;
    padding-right: 7px
  }

  .works-slider__item__title {
    line-height: 22px
  }
}

.reviews-block__photos {
  margin-left: -12px;
  margin-right: -12px
}

.reviews-block__photos>* {
  flex: 0 1 25%;
  max-width: 25%;
  padding-left: 12px;
  padding-right: 12px
}

.reviews-block__photo {
  border-radius: 10px;
  overflow: hidden
}

.reviews-block__photo .progressive-image-wrapper {
  border-radius: 0;
  display: block;
  max-width: 100%
}

.reviews-slider-wrapper {
  margin-top: 65px;
  padding-bottom: 30px;
  position: relative
}

.reviews-slider {
  overflow: hidden;
  padding-left: 60px;
  padding-right: 60px;
  z-index: 10
}

.reviews-slider.slick-initialized {
  height: auto;
  overflow: visible
}

.reviews-slider .slick-list {
  margin-left: -12px;
  margin-right: -12px;
  padding-bottom: 20px;
  padding-top: 12px
}

.reviews-slider .slick-slide {
  margin-left: 12px;
  margin-right: 12px;
  transition: .1s ease
}

.reviews-slider__dots {
  bottom: 0;
  height: 10px;
  left: 0;
  position: absolute;
  width: 100%
}

.reviews-slider__navigation {
  height: 60px;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 102px;
  width: 100%;
  z-index: 11
}

.reviews-slider__navigation>* {
  cursor: pointer;
  pointer-events: auto
}

.reviews-slider__navigation>.slick-disabled {
  cursor: default;
  opacity: .7
}

.reviews-slider__navigation>.slick-disabled:hover {
  background-color: #00beb4
}

.reviews-slider__item {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 7px 10px 3px rgba(70, 81, 86, .1);
  min-height: 255px;
  overflow: hidden;
  padding: 36px 35px;
  position: relative
}

.reviews-slider__item__top {
  align-items: center;
  display: flex;
  justify-content: space-between
}

.reviews-slider__item__top>* {
  flex: 0 1 auto
}

.reviews-slider__item__stars {
  align-items: center;
  display: flex;
  justify-content: flex-start
}

.reviews-slider__item__stars>* {
  background-image: url('../img/demo/icon-review-star.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  height: 14px;
  margin-right: 8px;
  width: 14px
}

.reviews-slider__item__stars>:last-child {
  margin-right: 0
}

.reviews-slider__item__source {
  color: #bbbcbe;
  font-size: 13px;
  line-height: 14px
}

.reviews-slider__item__source>a {
  color: #bbbcbe;
  text-decoration: underline
}

.reviews-slider__item__source>a:hover {
  text-decoration: none
}

.reviews-slider__item__title {
  color: #111f2c;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-top: 15px
}

.reviews-slider__item__text {
  color: #7e7e86;
  font-size: 14px;
  line-height: 22px;
  margin-top: 15px
}

@media screen and (max-width:1359px) {
  .reviews-slider-wrapper {
    margin-top: 44px
  }

  .reviews-slider {
    padding-left: 52px;
    padding-right: 52px
  }
}

@media screen and (max-width:1023px) {
  .reviews-block__photos {
    margin-left: -7px;
    margin-right: -7px
  }

  .reviews-block__photos>* {
    padding-left: 7px;
    padding-right: 7px
  }
}

@media screen and (max-width:767px) {
  .reviews-block__photos {
    justify-content: center;
    margin-left: -8px;
    margin-right: -8px
  }

  .reviews-block__photos>* {
    flex: 0 1 50%;
    margin-top: 16px;
    max-width: 50%;
    padding-left: 8px;
    padding-right: 8px
  }

  .reviews-block__photo {
    margin-left: auto;
    margin-right: auto;
    max-width: 290px
  }

  .reviews-slider-wrapper {
    padding-bottom: 60px
  }

  .reviews-slider {
    padding-left: 0;
    padding-right: 0
  }

  .reviews-slider .slick-slide {
    height: auto
  }

  .reviews-slider .slick-list {
    height: auto !important;
    overflow: visible !important;
    padding-bottom: 0
  }

  .reviews-slider__navigation {
    background-color: #01d8cd;
    border-radius: 29px;
    bottom: 39px;
    height: 49px;
    left: 50%;
    margin-left: -50px;
    top: auto;
    width: 100px
  }

  .reviews-slider__item {
    padding: 10px 21px 48px
  }

  .reviews-slider__item__top {
    flex-wrap: wrap
  }

  .reviews-slider__item__top>* {
    margin-top: 10px
  }

  .reviews-slider__item__stars {
    margin-right: 20px
  }

  .reviews-slider__item__title {
    font-size: 14px
  }

  .reviews-slider__item__text {
    font-size: 13px;
    line-height: 18px
  }
}

.slick-dots {
  align-items: center;
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0
}

.slick-dots>* {
  background-color: #eef3f6;
  border-radius: 50%;
  height: 10px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 0;
  transition: background-color .1s ease;
  width: 10px
}

.slick-dots>.slick-active {
  background-color: #00beb4
}

[data-js=expanded].active [data-js=expanded-content]:after {
  height: 0
}

[data-js=expanded].active [data-js=expanded-toggle]:after {
  transform: rotate(-180deg)
}

[data-js=expanded-content] {
  height: 94px;
  opacity: 0;
  position: relative;
  transition: height .3s
}

[data-js=expanded-content].expanded {
  height: 87px;
  opacity: 1;
  overflow: hidden;
  position: relative;
  transition: height .3s
}

[data-js=expanded-content].expanded:after {
  background-image: linear-gradient(0deg, #fff, hsla(0, 0%, 100%, 0));
  bottom: 0;
  content: "";
  display: block;
  height: 50px;
  pointer-events: none;
  position: absolute;
  transition: height .3s;
  width: 100%;
  z-index: 2
}

[data-js=expanded-content].expanded+[data-js=expanded-toggle] {
  display: inline-block
}

[data-js=expanded-content].not-expanded {
  opacity: 1
}

[data-js=expanded-toggle] {
  color: #00beb4;
  cursor: pointer;
  display: none;
  font-size: 14px;
  margin-top: 8px;
  padding-right: 21px;
  position: relative
}

[data-js=expanded-toggle]:after {
  background-image: url(/build/i/af7f5fc8428807b17015.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 5px;
  margin-top: -2px;
  position: absolute;
  right: 0;
  top: 50%;
  transition: transform .3s;
  width: 8px
}

.vacancies {
  background-color: #eaf2f5;
  padding: 80px 0 30px
}

.payment-page .vacancies h2,
.vacancies .h2,
.vacancies .payment-page h2 {

  font-style: normal;
  font-weight: 700;
  text-align: left
}

.vacancies__grid {
  display: flex;
  flex-wrap: wrap;
  margin: 30px -14px 0
}

.vacancies__item {
  background: #fff;
  border-radius: 25px;
  margin: 28px 14px 0;
  padding: 45px 40px;
  width: calc(50% - 28px)
}

.vacancies__title {
  color: #333638;

  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px
}

.vacancies__experience {

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin: 45px 0
}

.vacancies__experience span {
  font-weight: 400
}

.vacancies__buttons {
  display: flex
}

.tabs__labels.tabs__labels_buttons .vacancies__buttons .tab__label.active,
.vacancies__buttons .button,
.vacancies__buttons .slide-tab__label.active,
.vacancies__buttons .tabs__labels.tabs__labels_buttons .tab__label.active {
  border-radius: 100px;
  font-size: 16px;
  height: 66px;
  line-height: 64px;
  text-align: center;
  width: 250px
}

.vacancies__buttons .button_orange {
  line-height: 68px;
  margin-left: 15px
}

@media screen and (max-width:1359px) {
  .vacancies {
    padding: 50px 0 30px
  }

  .vacancies__grid {
    margin: 20px -10px 0
  }

  .vacancies__item {
    margin: 20px 10px 0;
    padding: 30px;
    width: calc(50% - 20px)
  }

  .vacancies__title {
    font-size: 26px;
    line-height: 32px
  }

  .vacancies__experience {
    font-size: 15px;
    margin: 35px 0
  }

  .tabs__labels.tabs__labels_buttons .vacancies__buttons .tab__label.active,
  .vacancies__buttons .button,
  .vacancies__buttons .slide-tab__label.active,
  .vacancies__buttons .tabs__labels.tabs__labels_buttons .tab__label.active {
    border-radius: 50px;
    font-size: 12px;
    height: 48px;
    line-height: 46px;
    width: 185px
  }

  .vacancies__buttons .button_orange {
    line-height: 50px;
    margin-left: 10px
  }
}

@media screen and (max-width:1023px) {
  .vacancies__grid {
    margin: 20px -8px 0
  }

  .vacancies__item {
    border-radius: 20px;
    margin: 16px 8px 0;
    padding: 25px 20px;
    width: calc(50% - 16px)
  }

  .vacancies__title {
    font-size: 22px;
    line-height: 28px
  }

  .vacancies__experience {
    font-size: 14px;
    margin: 20px 0
  }

  .tabs__labels.tabs__labels_buttons .vacancies__buttons .tab__label.active,
  .vacancies__buttons .button,
  .vacancies__buttons .slide-tab__label.active,
  .vacancies__buttons .tabs__labels.tabs__labels_buttons .tab__label.active {
    border-radius: 40px;
    font-size: 10px;
    height: 38px;
    line-height: 36px;
    width: 155px
  }

  .vacancies__buttons .button_orange {
    line-height: 40px
  }
}

@media screen and (max-width:767px) {
  .vacancies {
    padding: 35px 0
  }

  .payment-page .vacancies h2,
  .vacancies .h2,
  .vacancies .payment-page h2 {
    text-align: center
  }

  .payment-page .vacancies h2 br,
  .vacancies .h2 br,
  .vacancies .payment-page h2 br {
    display: none
  }

  .vacancies__grid {
    margin: 15px 0 0
  }

  .vacancies__item {
    margin: 20px 0 0;
    padding: 25px;
    text-align: center;
    width: 100%
  }

  .vacancies__title {
    font-size: 20px;
    line-height: 26px
  }

  .vacancies__experience {
    margin: 20px 0
  }

  .vacancies__buttons {
    display: block
  }

  .tabs__labels.tabs__labels_buttons .vacancies__buttons .tab__label.active,
  .vacancies__buttons .button,
  .vacancies__buttons .slide-tab__label.active,
  .vacancies__buttons .tabs__labels.tabs__labels_buttons .tab__label.active {
    border-radius: 60px;
    display: block;
    font-size: 14px;
    height: 52px;
    line-height: 50px;
    margin: 0 auto;
    width: 206px
  }

  .vacancies__buttons .button_orange {
    line-height: 54px;
    margin: 12px auto 0
  }
}

@media screen and (max-width:480px) {
  .vacancies__item {
    border-radius: 12px;
    margin: 15px 0 0;
    padding: 20px 15px
  }

  .vacancies__title {
    font-size: 16px;
    line-height: 22px
  }
}

.resume {
  background-color: #eaf2f5;
  overflow: hidden;
  padding: 50px 0 0
}

.payment-page .resume h2,
.resume .h2,
.resume .payment-page h2 {
  color: #333638;

  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  text-align: left
}

.resume .button_orange {
  border-radius: 100px;
  height: 66px;
  letter-spacing: 2px;
  line-height: 68px;
  margin-top: 40px;
  text-align: center;
  width: 276px
}

.resume__content {
  padding-bottom: 85px;
  position: relative
}

.resume__content p {
  font-size: 14px;
  line-height: 20px
}

.resume__list {
  list-style: none;
  margin: 22px 0 26px;
  max-width: 500px;
  padding: 0
}

.resume__list li {
  line-height: 25px;
  margin-bottom: 25px;
  padding-left: 50px;
  position: relative
}

.resume__list li:before {
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 35px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 35px
}

.resume__list li.tooth:before {
  background-image: url(/build/i/086944c0512e2790bc77.png)
}

.resume__list li.hand:before {
  background-image: url(/build/i/89a15f38f6a544c9d5e4.png)
}

.resume__list li.doctor:before {
  background-image: url(/build/i/4bc3c17c526ae4b18897.png)
}

.resume__img {
  bottom: 0;
  display: flex;
  max-width: 410px;
  position: absolute;
  right: 80px
}

.resume__img:before {
  border: 50px solid #f7f8f9;
  border-radius: 50%;
  content: "";
  display: block;
  height: 460px;
  left: 50%;
  margin-left: -240px;
  margin-top: -215px;
  opacity: .6;
  position: absolute;
  top: 50%;
  width: 460px
}

.resume__note {
  display: none
}

@media screen and (max-width:1359px) {
  .resume {
    padding: 30px 0 0
  }

  .payment-page .resume h2,
  .resume .h2,
  .resume .payment-page h2 {
    font-size: 20px;
    line-height: 26px
  }

  .resume .button_orange {
    border-radius: 60px;
    font-size: 12px;
    height: 48px;
    letter-spacing: 1px;
    line-height: 50px;
    margin-top: 30px;
    width: 200px
  }

  .resume__content {
    padding-bottom: 70px;
    padding-top: 20px
  }

  .resume__img {
    max-width: 325px;
    right: 100px
  }

  .resume__img:before {
    border: 30px solid #f7f8f9;
    height: 400px;
    margin-left: -215px;
    margin-top: -175px;
    opacity: .8;
    width: 400px
  }

  .resume__list {
    margin: 30px 0 25px
  }

  .resume__list li {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
    padding-left: 35px
  }

  .resume__list li:before {
    height: 25px;
    width: 25px
  }
}

@media screen and (max-width:1199px) {
  .resume__img {
    right: 15px
  }
}

@media screen and (max-width:1023px) {
  .resume {
    padding: 0 0 50px
  }

  .payment-page .resume h2,
  .resume .h2,
  .resume .payment-page h2 {
    font-size: 18px;
    line-height: 22px
  }

  .resume .button_orange {
    border-radius: 50px;
    font-size: 10px;
    height: 38px;
    letter-spacing: .8px;
    line-height: 40px;
    margin-top: 25px;
    padding: 0;
    width: 160px
  }

  .resume__content {
    background-color: #fff;
    border-radius: 15px;
    padding: 25px 20px
  }

  .resume__content>p {
    display: none
  }

  .resume__img {
    max-width: 225px;
    right: 20px
  }

  .resume__img:before {
    content: none
  }

  .resume__list {
    margin: 20px 0;
    max-width: 370px
  }

  .resume__list li {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 14px;
    padding-left: 30px
  }

  .resume__list li:before {
    height: 20px;
    width: 20px
  }

  .resume__note {
    background: #fff;
    border-radius: 6px;
    bottom: 20px;
    display: block;
    font-size: 10px;
    left: 27px;
    line-height: 14px;
    padding: 8px 10px;
    position: absolute;
    z-index: 2
  }

  .resume__note span {
    display: block;

    font-style: normal;
    font-weight: 700
  }
}

@media screen and (max-width:767px) {
  .resume {
    padding: 0 0 40px
  }

  .payment-page .resume h2,
  .resume .h2,
  .resume .payment-page h2 {
    font-size: 22px !important;
    line-height: 26px;
    text-align: center
  }

  .resume .button_orange {
    border-radius: 70px;
    display: block;
    font-size: 14px;
    height: 52px;
    letter-spacing: normal;
    line-height: 54px;
    margin: 30px auto 0;
    width: 205px
  }

  .resume__content {
    border-radius: 0;
    margin: 0 -15px;
    padding: 25px 20px 30px
  }

  .resume__content>p,
  .resume__img {
    display: none
  }

  .resume__list {
    margin: 25px 0;
    max-width: 100%
  }

  .resume__list li {
    font-size: 14px;
    line-height: 20px
  }

  .resume__list li:before {
    top: 2px;
    transform: none
  }
}

@media screen and (max-width:480px) {
  .resume__content {
    margin: 0 -15px;
    padding: 25px 15px 30px
  }
}

.gallery-seven__grid {
  display: flex;
  flex-wrap: wrap;
  margin: 80px -12px 0
}

.gallery-seven__grid .progressive-image-wrapper {
  border-radius: 10px;
  display: block;
  width: 100% !important
}

.gallery-seven__top {
  display: flex
}

.gallery-seven__top>div:first-child {
  margin: 12px;
  width: calc(33.33% - 24px)
}

.gallery-seven__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 12px;
  width: calc(66.66% - 24px)
}

.gallery-seven__wrapper>div {
  width: calc(50% - 12px)
}

.gallery-seven__wrapper>div:nth-last-child(-n+3) {
  align-self: flex-end;
  display: flex
}

.gallery-seven__wrapper>div:last-child {
  display: none
}

.gallery-seven__bottom {
  display: flex
}

.gallery-seven__bottom>div {
  margin: 12px;
  width: calc(50% - 24px)
}

.gallery-seven__mobl {
  display: none
}

@media screen and (max-width:1359px) {
  .gallery-seven__grid {
    margin: 70px -8px 0
  }

  .gallery-seven__top>div:first-child {
    margin: 8px;
    width: calc(33.33% - 16px)
  }

  .gallery-seven__wrapper {
    margin: 8px;
    width: calc(66.66% - 16px)
  }

  .gallery-seven__wrapper>div {
    width: calc(50% - 8px)
  }

  .gallery-seven__bottom>div {
    margin: 8px;
    width: calc(50% - 16px)
  }
}

@media screen and (max-width:1023px) {
  .gallery-seven__grid {
    margin: 60px -8px 0
  }
}

@media screen and (max-width:767px) {
  .gallery-seven__grid {
    margin: 40px -5px 0
  }

  .gallery-seven__grid .progressive-image-wrapper {
    border-radius: 5px
  }

  .gallery-seven__top>div:first-child {
    margin: 5px;
    width: calc(33.33% - 10px)
  }

  .gallery-seven__wrapper {
    margin: 5px;
    width: calc(66.66% - 10px)
  }

  .gallery-seven__wrapper>div {
    width: calc(50% - 5px)
  }

  .gallery-seven__bottom>div {
    margin: 5px;
    width: calc(50% - 10px)
  }
}

@media screen and (max-width:635px) {
  .gallery-seven__top {
    margin: 0
  }

  .gallery-seven__top>div:first-child {
    display: none
  }

  .gallery-seven__wrapper {
    margin: 0;
    width: 100%
  }

  .gallery-seven__wrapper>div {
    margin: 5px;
    width: calc(50% - 10px)
  }

  .gallery-seven__wrapper>div:nth-child(-n+3) {
    display: none
  }

  .gallery-seven__wrapper>div:last-child {
    display: flex
  }

  .gallery-seven__bottom {
    flex-wrap: wrap
  }

  .gallery-seven__bottom>div {
    width: 100%
  }

  .gallery-seven__bottom>div:first-child {
    display: none
  }

  .gallery-seven__mobl {
    display: block;
    margin: 5px
  }
}

@media screen and (max-width:480px) {

  .gallery-seven .h2,
  .gallery-seven .payment-page h2,
  .payment-page .gallery-seven h2 {
    font-size: 24px !important
  }
}

.vacancies-quote .director-quote__content {
  padding-bottom: 80px;
  padding-top: 90px
}

.vacancies-quote .director-quote__image,
.vacancies-quote .director-quote__image .progressive-image {
  max-width: 300px
}

.vacancies-quote .director-quote__image:before {
  border: 45px solid #fff;
  bottom: -55px;
  height: 345px;
  margin-left: -175px;
  opacity: .6;
  width: 345px
}

.vacancies-quote .director-quote__text {

  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  margin-bottom: 25px
}

.vacancies-quote p {
  color: #626466;
  font-size: 14px;
  line-height: 18px
}

.tabs__labels.tabs__labels_buttons .vacancies-quote .tab__label.active,
.vacancies-quote .button,
.vacancies-quote .slide-tab__label.active,
.vacancies-quote .tabs__labels.tabs__labels_buttons .tab__label.active {
  border-radius: 100px;
  height: 66px;
  line-height: 66px;
  margin-top: 25px;
  text-align: center;
  width: 256px
}

@media screen and (max-width:1359px) {
  .vacancies-quote .director-quote__text {

    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    margin-bottom: 25px
  }

  .tabs__labels.tabs__labels_buttons .vacancies-quote .tab__label.active,
  .vacancies-quote .button,
  .vacancies-quote .slide-tab__label.active,
  .vacancies-quote .tabs__labels.tabs__labels_buttons .tab__label.active {
    border-radius: 50px;
    font-size: 12px;
    height: 48px;
    line-height: 50px;
    width: 185px
  }
}

@media screen and (max-width:1023px) {
  .vacancies-quote .director-quote__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 315px;
    padding-bottom: 60px;
    padding-top: 60px
  }
}

@media screen and (max-width:900px) {
  .vacancies-quote .director-quote__content {
    display: block;
    min-height: 0;
    min-height: auto;
    padding-bottom: 40px;
    padding-top: 40px
  }

  .vacancies-quote .director-quote__text {
    font-size: 28px;
    line-height: 34px
  }

  .vacancies-quote .director-quote__grid>div {
    flex: 1 1 100%
  }
}

@media screen and (max-width:767px) {
  .vacancies-quote .director-quote__text {
    font-size: 26px;
    line-height: 32px
  }
}

@media screen and (max-width:496px) {
  .vacancies-quote .director-quote__image:before {
    transform: none
  }
}

.gallery-five .progressive-image-wrapper {
  border-radius: 10px;
  display: block;
  width: 100% !important
}

.gallery-five__grid {
  display: flex;
  margin: 50px -12px 0
}

.gallery-five__left {
  margin: 0 12px;
  width: calc(33.33% - 24px)
}

.gallery-five__right {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 12px;
  width: calc(66.66% - 24px)
}

.gallery-five__right>div {
  width: calc(50% - 12px)
}

.gallery-five__right>div:nth-last-child(-n+2) {
  align-self: flex-end;
  display: flex
}

@media screen and (max-width:1359px) {
  .gallery-five__grid {
    margin: 50px -9px 0
  }

  .gallery-five__left {
    margin: 0 9px;
    width: calc(33.33% - 18px)
  }

  .gallery-five__right {
    margin: 0 9px;
    width: calc(66.66% - 18px)
  }

  .gallery-five__right>div {
    width: calc(50% - 9px)
  }
}

@media screen and (max-width:1023px) {
  .gallery-five .progressive-image-wrapper {
    border-radius: 5px
  }

  .gallery-five__grid {
    margin: 40px -7px 0
  }

  .gallery-five__left {
    margin: 0 7px;
    width: calc(33.33% - 14px)
  }

  .gallery-five__right {
    margin: 0 7px;
    width: calc(66.66% - 14px)
  }

  .gallery-five__right>div {
    width: calc(50% - 7px)
  }
}

@media screen and (max-width:767px) {

  .gallery-five__left,
  .gallery-five__right {
    width: calc(50% - 14px)
  }

  .gallery-five__right>div {
    width: 100%
  }

  .gallery-five__right>div:nth-child(odd) {
    display: none
  }
}

@media screen and (max-width:480px) {
  .gallery-five__grid {
    margin: 30px -4px 0
  }

  .gallery-five__left,
  .gallery-five__right {
    margin: 0 4px;
    width: calc(50% - 8px)
  }
}

.vacancies-form {
  text-align: center
}

.vacancies-form .contact-us__form {
  margin: 70px auto 0;
  max-width: 610px
}

.vacancies-form .contact-us__form textarea {
  height: 160px
}

.vacancies-form .contact-us__form__left-input {
  float: left;
  width: 287px
}

.vacancies-form .contact-us__form__right-input {
  float: right;
  width: 287px
}

.vacancies-form .contact-us__form__button {
  align-items: center;
  display: flex;
  margin-top: 16px
}

.vacancies-form .contact-us__form__button>:first-child {
  border-radius: 100px;
  font-size: 16px;
  height: 66px;
  line-height: 68px;
  margin-right: 0;
  max-width: 294px;
  width: 294px
}

.vacancies-form .contact-us__form__button>:last-child {
  color: #bdc7ca;
  flex: 1 1 50%;
  font-size: 12px;
  margin-left: 15px;
  max-width: 230px;
  text-align: left
}

.vacancies-form__subtitle {

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 20px
}

@media screen and (max-width:1359px) {
  .contact-us__form {
    margin: 45px auto 0
  }

  .contact-us__form .contact-us__form__button>:first-child {
    border-radius: 60px;
    font-size: 14px;
    height: 55px;
    line-height: 57px;
    max-width: 242px
  }
}

@media screen and (max-width:1023px) {
  .contact-us__form .contact-us__form {
    margin: 35px auto 0
  }

  .contact-us__form .contact-us__form__full-input,
  .contact-us__form .contact-us__form__left-input,
  .contact-us__form .contact-us__form__right-input {
    padding: 10px 0
  }

  .contact-us__form .input__label {
    top: 25px
  }

  .vacancies-form__subtitle {
    font-size: 16px;
    line-height: 22px
  }
}

@media screen and (max-width:620px) {
  .vacancies-form .contact-us__form {
    margin: 25px auto 0
  }

  .vacancies-form .contact-us__form textarea {
    height: 138px
  }

  .vacancies-form .contact-us__form__left-input,
  .vacancies-form .contact-us__form__right-input {
    padding: 8px 0;
    width: 100%
  }

  .vacancies-form .contact-us__form__full-input {
    padding: 8px 0
  }

  .vacancies-form .contact-us__form__button {
    display: block
  }

  .vacancies-form .contact-us__form__button>:first-child {
    display: block;
    margin: 20px auto 10px
  }

  .vacancies-form .contact-us__form__button>:last-child {
    font-size: 10px;
    line-height: 16px;
    margin: 0 auto;
    text-align: center
  }

  .vacancies-form .input__label {
    top: 25px
  }

  .vacancies-form__subtitle {
    font-size: 14px;
    line-height: 20px
  }
}

.vacancies-modal {
  border: none;
  border-radius: 30px;
  margin: 20px;
  max-width: 1000px;
  padding: 50px 60px;
  text-align: left;
  width: 100%
}

.payment-page .vacancies-modal h2,
.vacancies-modal .h2,
.vacancies-modal .payment-page h2 {
  text-align: left
}

.vacancies-modal .vacancies-modal__text {
  color: #5f6877;
  font-size: 18px;
  line-height: 24px;
  margin: 20px 0 0;
  padding: 0
}

.vacancies-modal .minus-list,
.vacancies-modal .plus-list {
  font-size: 18px;
  line-height: 24px;
  margin: 0 0 10px
}

.vacancies-modal .minus-list li,
.vacancies-modal .plus-list li {
  margin-bottom: 15px;
  padding-left: 25px
}

.vacancies-modal .mfp-close {
  right: 13px;
  top: 7px
}

.vacancies-modal .mfp-close:before {
  background-image: url(/build/i/d8a6d26ddbecc46e0b1e.svg);
  background-size: contain;
  height: 16px;
  width: 16px
}

.tabs__labels.tabs__labels_buttons .vacancies-modal .tab__label.active,
.vacancies-modal .button,
.vacancies-modal .slide-tab__label.active,
.vacancies-modal .tabs__labels.tabs__labels_buttons .tab__label.active {
  border-radius: 100px;
  display: block;
  height: 66px;
  line-height: 68px;
  margin: 30px 0 0;
  text-align: center;
  width: 276px
}

.vacancies-modal__title {
  color: #5f6877;

  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin: 40px 0 12px
}

@media screen and (max-width:1359px) {
  .vacancies-modal {
    border-radius: 20px;
    max-width: 930px;
    padding: 35px 40px
  }

  .payment-page .vacancies-modal h2,
  .vacancies-modal .h2,
  .vacancies-modal .payment-page h2 {
    font-size: 26px;
    line-height: 30px
  }

  .vacancies-modal .vacancies-modal__text {
    font-size: 12px;
    line-height: 16px;
    margin: 10px 0 0
  }

  .vacancies-modal .mfp-close {
    right: 5px;
    top: 4px
  }

  .vacancies-modal .minus-list,
  .vacancies-modal .plus-list {
    font-size: 12px;
    line-height: 18px
  }

  .vacancies-modal .minus-list li,
  .vacancies-modal .plus-list li {
    margin-bottom: 7px;
    padding-left: 17px
  }

  .vacancies-modal .minus-list li:before,
  .vacancies-modal .plus-list li:before {
    height: 10px;
    top: 3px;
    width: 10px
  }

  .tabs__labels.tabs__labels_buttons .vacancies-modal .tab__label.active,
  .vacancies-modal .button,
  .vacancies-modal .slide-tab__label.active,
  .vacancies-modal .tabs__labels.tabs__labels_buttons .tab__label.active {
    border-radius: 60px;
    font-size: 12px;
    height: 40px;
    line-height: 42px;
    margin: 20px 0 0;
    padding: 0;
    width: 172px
  }

  .vacancies-modal__title {
    font-size: 16px;
    line-height: 22px;
    margin: 20px 0 10px
  }
}

@media screen and (max-width:1023px) {
  .vacancies-modal {
    border-radius: 15px;
    max-width: 726px;
    padding: 35px 30px
  }

  .payment-page .vacancies-modal h2,
  .vacancies-modal .h2,
  .vacancies-modal .payment-page h2 {
    font-size: 24px;
    line-height: 28px
  }
}

@media screen and (max-width:767px) {
  .vacancies-modal {
    border-radius: 8px;
    max-width: calc(100% - 32px);
    padding: 30px 15px
  }

  .payment-page .vacancies-modal h2,
  .vacancies-modal .h2,
  .vacancies-modal .payment-page h2 {
    font-size: 18px !important;
    line-height: 24px
  }

  .vacancies-modal .minus-list,
  .vacancies-modal .plus-list {
    font-size: 14px;
    line-height: 20px
  }

  .vacancies-modal .minus-list li,
  .vacancies-modal .plus-list li {
    margin-bottom: 12px;
    padding-left: 17px
  }

  .vacancies-modal .minus-list li:before,
  .vacancies-modal .plus-list li:before {
    height: 10px;
    top: 3px;
    width: 10px
  }

  .tabs__labels.tabs__labels_buttons .vacancies-modal .tab__label.active,
  .vacancies-modal .button,
  .vacancies-modal .slide-tab__label.active,
  .vacancies-modal .tabs__labels.tabs__labels_buttons .tab__label.active {
    border-radius: 80px;
    font-size: 14px;
    height: 52px;
    line-height: 54px;
    margin: 20px auto 0;
    width: 206px
  }

  .vacancies-modal__title {
    margin: 30px 0 10px
  }
}

@media screen and (max-width:480px) {
  .vacancies-modal .mfp-close {
    right: -2px;
    top: -2px
  }
}

.diagnokat {
  padding-bottom: 80px
}

.diagnokat .h2,
.diagnokat .payment-page h2,
.payment-page .diagnokat h2 {
  margin-bottom: 100px
}

.diagnokat__grid {
  background: #f5f7f8;
  border-radius: 10px;
  padding: 70px 25px 125px;
  position: relative
}

.diagnokat__list {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1
}

.diagnokat__list>div {
  max-width: 280px
}

.diagnokat__list .list {
  list-style: none;
  padding-left: 0
}

.diagnokat__list li {
  color: #5f6877;
  font-size: 16px;
  line-height: 27px;
  min-height: 60px;
  position: relative
}

.diagnokat__list li:before {
  background-image: url(/build/i/82b4939a9c94e8053fa4.svg);
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  height: 15px;
  position: absolute;
  top: 8px;
  width: 15px
}

.diagnokat__list li:not(:first-child) {
  margin-top: 20px
}

.diagnokat__list .diagnokat__list-left li {
  padding-right: 32px;
  text-align: right
}

.diagnokat__list .diagnokat__list-left li:before {
  right: 0
}

.diagnokat__list .diagnokat__list-right li {
  padding-left: 32px;
  text-align: left
}

.diagnokat__list .diagnokat__list-right li:before {
  left: 0
}

.diagnokat__image {
  bottom: 29px;
  display: flex;
  left: 50%;
  position: absolute;
  transform: translate(-50%);
  width: 570px
}

.diagnokat__image * {
  width: 100% !important
}

.diagnokat__banner {
  align-items: center;
  background: #3cbfb5;
  border-radius: 10px;
  bottom: -80px;
  display: flex;
  left: 50%;
  min-height: 113px;
  overflow: hidden;
  padding: 21px 47px 20px 25px;
  position: absolute;
  transform: translate(-50%);
  width: 630px
}

.diagnokat__banner .text {
  color: #fff;
  font-size: 16px;
  line-height: 24px
}

.diagnokat__icon {
  background: hsla(0, 0%, 100%, .5);
  border-radius: 50%;
  height: 153px;
  left: -44px;
  min-width: 153px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 153px
}

.diagnokat__icon .image {
  left: 45%;
  position: absolute;
  top: 25%
}

.diagnokat__icon .image * {
  width: 100% !important
}

.diagnokat__icon+.text {
  margin-left: 127px
}

@media screen and (max-width:1280px) {
  .diagnokat {
    padding-bottom: 56px
  }

  .diagnokat .h2,
  .diagnokat .payment-page h2,
  .payment-page .diagnokat h2 {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 72px
  }

  .diagnokat__grid {
    margin-left: -40px;
    margin-right: -40px;
    padding: 59px 15px 96px
  }

  .diagnokat__list>div {
    max-width: 225px
  }

  .diagnokat__list li {
    line-height: 24px
  }

  .diagnokat__list .diagnokat__list-left li {
    padding-right: 25px
  }

  .diagnokat__list .diagnokat__list-right li {
    padding-left: 25px
  }

  .diagnokat__image {
    bottom: 53px;
    width: 488px
  }

  .diagnokat__banner {
    bottom: -56px
  }
}

@media screen and (max-width:1023px) {
  .diagnokat {
    padding-bottom: 46px
  }

  .diagnokat .h2,
  .diagnokat .payment-page h2,
  .payment-page .diagnokat h2 {
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 30px
  }

  .diagnokat__grid {
    margin-left: -20px;
    margin-right: -20px;
    padding: 45px 15px 69px
  }

  .diagnokat__list>div {
    max-width: 200px
  }

  .diagnokat__list li {
    font-size: 14px;
    line-height: 22px
  }

  .diagnokat__list li:before {
    height: 13px;
    width: 13px
  }

  .diagnokat__list li:not(:first-child) {
    margin-top: 7px
  }

  .diagnokat__image {
    bottom: 46px;
    width: 318px
  }

  .diagnokat__banner {
    bottom: -46px;
    min-height: 97px;
    padding: 15px 30px 16px 25px;
    width: 532px
  }

  .diagnokat__banner .text {
    font-size: 14px;
    line-height: 22px
  }

  .diagnokat__icon {
    height: 132px;
    min-width: 132px;
    width: 132px
  }

  .diagnokat__icon .image {
    left: 48%;
    width: 91px
  }

  .diagnokat__icon+.text {
    margin-left: 100px
  }
}

@media screen and (max-width:767px) {
  .diagnokat {
    padding-bottom: 0
  }

  .diagnokat .h2,
  .diagnokat .payment-page h2,
  .payment-page .diagnokat h2 {
    margin-bottom: 29px
  }

  .diagnokat__grid {
    background: none;
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
    padding: 0
  }

  .diagnokat__list {
    flex-direction: column;
    margin-bottom: 9px
  }

  .diagnokat__list>div {
    max-width: 100%
  }

  .diagnokat__list li {
    height: auto;
    line-height: 21px;
    min-height: 0
  }

  .diagnokat__list li:before {
    top: 3px
  }

  .diagnokat__list li:not(:first-child) {
    margin-top: 13px
  }

  .diagnokat__list .diagnokat__list-left li {
    padding-left: 26px;
    padding-right: 0;
    text-align: left
  }

  .diagnokat__list .diagnokat__list-left li:before {
    left: 0
  }

  .diagnokat__list .diagnokat__list-right {
    margin-top: 13px
  }

  .diagnokat__list .diagnokat__list-right li {
    padding-left: 26px
  }

  .diagnokat__image {
    bottom: auto;
    position: relative;
    width: 320px
  }

  .diagnokat__banner {
    bottom: auto;
    margin-top: -5px;
    padding: 19px 18px 15px 23px;
    position: relative;
    width: 100%
  }

  .diagnokat__banner .text {
    line-height: 21px;
    margin-left: 0
  }

  .diagnokat__icon {
    display: none
  }
}

.questionnaire {
  padding-bottom: 90px;
  padding-top: 100px;
  text-align: center
}

.payment-page .questionnaire h2,
.questionnaire .h2,
.questionnaire .payment-page h2 {
  margin-bottom: 23px
}

.questionnaire__small {
  color: #5f6877;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 50px
}

.questionnaire__grid-title {
  color: #5f6877;

  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 55px;
  margin-left: auto;
  margin-right: auto
}

.questionnaire__grid {
  align-items: stretch;
  display: flex;
  justify-content: space-between;
  margin-bottom: 49px;
  padding-left: 30px;
  padding-right: 30px
}

.questionnaire__item {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 36px 10px hsla(0, 0%, 44%, .07);
  padding: 71px 41px 27px;
  position: relative;
  width: 350px
}

.questionnaire__item .icon {
  background: #f0f7fa;
  border-radius: 50%;
  height: 114px;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translate(-50%, -50%);
  width: 114px
}

.questionnaire__item .icon:after {
  height: 35px;
  width: 35px
}

.questionnaire__item .icon:after,
.questionnaire__item .icon:before {
  content: "";
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%)
}

.questionnaire__item .icon:before {
  background: #5fd2c9;
  border-radius: 50%;
  height: 62px;
  width: 62px
}

.questionnaire__item:first-child {
  z-index: 2
}

.questionnaire__item:first-child .icon:after {
  background-image: url(/build/i/68f02e307d890c66abfb.svg);
  background-repeat: no-repeat;
  background-size: contain
}

.questionnaire__item:nth-child(2) {
  z-index: 1
}

.questionnaire__item:nth-child(2) .icon:after {
  background-image: url(/build/i/0be06e7870b800dae801.svg);
  background-repeat: no-repeat;
  background-size: contain
}

.questionnaire__item:nth-child(3) .icon:after {
  background-image: url(/build/i/c0dd6abedad5aa6f55ab.svg);
  background-repeat: no-repeat;
  background-size: contain
}

.questionnaire__item .text {
  color: #5f6877;
  font-size: 16px;
  line-height: 24px;
  text-align: center
}

.questionnaire__button,
.questionnaire__button a {
  display: flex;
  justify-content: center
}

.questionnaire__button a {
  align-items: center;
  line-height: normal;
  margin-bottom: 0;
  width: 319px
}

.questionnaire__accordion .accordion__tab__title {
  border: none;
  height: auto;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0
}

.questionnaire__accordion .accordion__tab__title:after {
  display: none
}

.questionnaire__accordion .accordion__tab__content {
  border: none;
  display: block;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0
}

@media screen and (max-width:1280px) {
  .questionnaire {
    padding-top: 80px
  }

  .questionnaire__small {
    margin-bottom: 34px
  }

  .questionnaire__grid {
    margin-left: -30px;
    margin-right: -30px;
    padding-left: 0;
    padding-right: 0
  }

  .questionnaire__item {
    padding: 65px 32px 23px;
    width: 318px
  }

  .questionnaire__item .icon {
    height: 104px;
    width: 104px
  }

  .questionnaire__item .icon:after {
    height: 32px;
    width: 32px
  }

  .questionnaire__item .icon:before {
    height: 56px;
    width: 56px
  }

  .questionnaire__button a {
    height: 55px
  }
}

@media screen and (max-width:1023px) {
  .questionnaire {
    padding-top: 70px
  }

  .questionnaire__small {
    margin-bottom: 19px
  }

  .questionnaire__grid-title {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 47px
  }

  .questionnaire__grid {
    margin-bottom: 33px;
    margin-left: 0;
    margin-right: 0
  }

  .questionnaire__item {
    padding: 57px 23px 23px;
    width: 30%
  }

  .questionnaire__item .icon {
    height: 80px;
    width: 80px
  }

  .questionnaire__item .icon:after {
    height: 28px;
    width: 28px
  }

  .questionnaire__item .icon:before {
    height: 48px;
    width: 48px
  }

  .questionnaire__item .text {
    font-size: 14px;
    line-height: 20px
  }

  .questionnaire__button a {
    height: 50px;
    width: 292px
  }
}

@media screen and (max-width:767px) {
  .questionnaire__grid-title {
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 43px;
    margin-left: 0
  }

  .questionnaire__grid {
    align-items: center;
    flex-direction: column;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    max-width: 500px
  }

  .questionnaire__item {
    width: 290px
  }

  .questionnaire__item:not(:first-child) {
    margin-top: 32px
  }

  .questionnaire__button a {
    width: 290px
  }

  .questionnaire__accordion .accordion__tab__title {
    margin-left: auto;
    margin-right: auto;
    width: 290px
  }

  .questionnaire__accordion .accordion__tab__title:after {
    background-image: url(/build/i/af7f5fc8428807b17015.svg);
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    height: 8px;
    right: 0;
    top: 13px;
    width: 14px
  }

  .questionnaire__accordion .accordion__tab__title .questionnaire__grid-title {
    border-bottom: 1px dashed;
    color: #00beb4
  }

  .questionnaire__accordion .accordion__tab__title.active .questionnaire__grid-title {
    border-bottom: none;
    color: #343638
  }

  .questionnaire__accordion .accordion__tab__content {
    display: none
  }
}

.payment-page .tg-bot h2,
.tg-bot .h2,
.tg-bot .payment-page h2 {
  margin-bottom: 23px;
  text-align: left
}

.tg-bot__grid {
  align-items: flex-end;
  display: flex;
  justify-content: space-between
}

.tg-bot__link {
  align-items: center;
  display: flex;
  margin-bottom: 27px
}

.tg-bot__link a {
  color: #438bfd;

  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  margin-left: 14px
}

.tg-bot__list {
  border-radius: 10px;
  box-shadow: 0 4px 36px 10px hsla(0, 0%, 44%, .07);
  padding: 42px 65px 44px 56px;
  width: 520px
}

.tg-bot__list ul {
  margin-bottom: 30px
}

.tg-bot__list ul li {
  margin-bottom: 13px
}

.tg-bot__list-title {
  color: #333638;
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 23px
}

.tg-bot__button {
  height: 60px;
  width: 218px
}

.tg-bot__button a {
  align-items: center;
  border-radius: 500px;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%
}

.tg-bot__image {
  margin-right: 45px
}

.tg-bot__image .image {
  width: 515px
}

.tg-bot__image .image * {
  width: 100% !important
}

@media screen and (max-width:1280px) {
  .tg-bot__grid {
    justify-content: normal
  }

  .tg-bot__list {
    padding: 45px 50px 44px 38px;
    width: 490px
  }

  .tg-bot__button {
    height: 55px
  }

  .tg-bot__image {
    margin-left: 60px;
    margin-right: 0
  }

  .tg-bot__image .image {
    width: 440px
  }
}

@media screen and (max-width:1023px) {

  .payment-page .tg-bot h2,
  .tg-bot .h2,
  .tg-bot .payment-page h2 {
    margin-right: -50px
  }

  .tg-bot__link {
    margin-bottom: 35px
  }

  .tg-bot__list {
    padding: 31px 27px 45px 38px;
    width: 425px
  }

  .tg-bot__list ul li {
    font-size: 14px;
    line-height: 20px
  }

  .tg-bot__list-title {
    margin-bottom: 19px
  }

  .tg-bot__button {
    height: 50px
  }

  .tg-bot__image {
    margin-left: -15px
  }

  .tg-bot__image .image {
    width: 342px
  }
}

@media screen and (max-width:767px) {

  .payment-page .tg-bot h2,
  .tg-bot .h2,
  .tg-bot .payment-page h2 {
    margin-left: auto;
    margin-right: auto;
    max-width: 250px;
    text-align: center
  }

  .tg-bot__grid {
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    max-width: 500px
  }

  .tg-bot__info {
    margin-bottom: 25px
  }

  .tg-bot__link {
    justify-content: center;
    margin-bottom: 22px
  }

  .tg-bot__link a {
    font-size: 17px;
    line-height: 18px;
    margin-left: 13px
  }

  .tg-bot__link .progressive-image-wrapper {
    max-width: 25px
  }

  .tg-bot__list {
    padding: 26px 15px 40px 22px;
    width: 100%
  }

  .tg-bot__list ul li {
    margin-bottom: 6px
  }

  .tg-bot__list-title {
    font-size: 18px;
    line-height: 26px
  }

  .tg-bot__button,
  .tg-bot__image {
    margin-left: auto;
    margin-right: auto
  }

  .tg-bot__image .image {
    width: 243px
  }
}

.to-whom-does>* {
  align-items: center;
  color: #6f757e;
  display: flex;

  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 40px
}

.to-whom-does>:before {
  content: "";
  display: block;
  height: 80px;
  margin-right: 48px;
  width: 80px
}

.to-whom-does>:first-child:before {
  background-image: url(/build/i/9fcde0902d3a99338d37.svg)
}

.to-whom-does>:nth-child(2):before {
  background-image: url(/build/i/bbfe726b12948d9c7581.svg)
}

.to-whom-does>:nth-child(3):before {
  background-image: url(/build/i/3a55b02b3ff1400685f1.svg)
}

.text-warning {
  color: #f49b00
}

.corner_play {
  right: -2px;
  z-index: 1
}

.corner_play,
.corner_play>* {
  height: 90px;
  position: absolute;
  top: 0;
  width: 90px
}

.corner_play>* {
  border-radius: 0;
  border-bottom-left-radius: 90px;
  left: 0;
  padding: 0
}

.corner_play>* span {
  background-image: url(/build/i/5622366eb70a7e12dbb4.svg);
  background-position: 36px 20px;
  background-size: 32px 34px;
  height: 90px;
  position: absolute;
  right: 0;
  top: 0;
  width: 90px
}

.double-grid {
  margin-left: -12px;
  margin-right: -12px
}

.double-grid>* {
  flex-basis: calc(50% - 24px);
  margin-left: 12px;
  margin-right: 12px
}

.double-grid_calculate {
  height: 100%
}

.double-grid_calculate>:first-child {
  color: #3c4143;
  font-size: 18px;
  line-height: 28px
}

.double-grid_calculate>:last-child {
  text-align: left
}

@media screen and (max-width:1363px) {
  .double-grid:not(.double-grid_calculate)>* {
    flex-basis: calc(100% - 24px)
  }

  .double-grid_calculate {
    height: 136px
  }

  .double-grid_calculate>:last-child {
    align-items: center;
    display: flex;
    justify-content: center
  }

  .double-grid_calculate>:last-child:after {
    content: "";
    font-size: 0;
    min-height: inherit
  }
}

@media screen and (max-width:767px) {
  .double-grid:not(.double-grid_calculate) {
    margin-left: 0;
    margin-right: 0
  }

  .double-grid:not(.double-grid_calculate)>* {
    flex-basis: 100%;
    margin-left: 0;
    margin-right: 0
  }

  .double-grid.double-grid_calculate {
    height: 200px
  }

  .double-grid-wrapper>.row,
  .double-grid-wrapper>.row_short {
    padding-left: 0;
    padding-right: 0
  }

  .double-grid_calculate {
    padding-top: 40px
  }

  .double-grid_calculate>:first-child {
    margin-bottom: 20px
  }

  .main-slider__item__photo__video-canvas {
    height: 82px !important;
    width: 82px !important
  }

  .main-slider__item__photo__video-canvas video {
    height: 80px !important;
    width: 80px !important
  }
}

.list-toggle {
  margin-bottom: 20px
}

.slick-list {
  overflow: hidden
}

// .slider .slider__item:not(:first-child) {
//   display: none
// }

#vk_comments {
  width: 1014px !important
}

#vk_comments>* {
  width: 100%
}

.semi-bold {

  font-style: normal;
  font-weight: 500
}

.main-slider__item__photo__video-canvas {
  height: 132px;
  left: -1px;
  opacity: 0;
  top: -1px;
  transition: opacity .2s ease-in;
  width: 132px
}

.main-slider__item__photo__video-canvas.shown {
  opacity: 1;
  transition: opacity .2s ease-in
}

.main-slider__item__photo__video-canvas video {
  height: 132px;
  width: 132px
}

.slick-slide:not(.slick-current) [data-js=video] {
  opacity: 0;
  transition: opacity .2s ease-out
}

.page-header_adv {
  min-height: 565px
}

@media screen and (max-width:1023px) {

  [data-js-scroll-target=benefit]+.contact-ribbon-wrapper,
  [data-js-scroll-target=implants]+.contact-ribbon-wrapper {
    margin-top: 30px
  }
}

@media screen and (max-width:767px) {
  .page_857 .service-advantages {
    padding-bottom: 0
  }

  .page_857 .green-grid+.pusher {
    margin-top: 36px !important
  }

  .page_857 .what-is-included-points+.pusher {
    display: none
  }

  .page_857 .contacts-metro-map-wrapper+.divider {
    background-color: #fff;
    margin-bottom: 0 !important;
    margin-top: 36px !important
  }

  .page_857 .compare+.pusher {
    display: none
  }

  .page_857 .contact-ribbon-wrapper {
    margin-top: 0
  }

  .page_857 .contact-ribbon-wrapper+.pusher {
    margin-top: 50px !important
  }

  .page_857 .with-image-slider-wrapper+.divider {
    margin-bottom: 60px !important;
    margin-top: 40px !important
  }

  .page_857 .thank-for-trust+.divider {
    margin-bottom: 50px !important;
    margin-top: 40px !important
  }

  .page_857 .independent-ratings+.divider {
    margin-bottom: 50px !important;
    margin-top: 30px !important
  }

  .page_857 .reviews__guest-book-wrapper+.divider {
    background-color: #fff;
    margin-bottom: 0 !important;
    margin-top: 50px !important
  }

  .page_252 .service-advantages {
    padding: 0
  }

  .page_adv_service_prostheses .imaged-block+.pusher {
    margin-top: 0 !important
  }

  .page_adv_service .warranty+.pusher {
    margin-top: -50px !important
  }

  .page_adv_service .warranty-triple+.pusher {
    margin-top: 50px !important
  }

  .page_adv_service .info-block-wrapper+.divider {
    display: none
  }

  .page_adv_service .links-block {
    margin-top: 50px
  }
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: pointer
}